export const ROOT = '/FormsData'; 
export const GUARD_APPLICATION_FORM = "guard_application_form";
export const CLIENT_INFO_FORM = "client_info_form";
export const SECURITY_GUARD_INTERVIEW = "security_guard_interview";
export const FIREWATCH_LOG_DOC = "firewatch_log_doc";
export const OBSERVATION_REPORT = "observation_report";
export const GUARD_EVALUATION = "guard_evaluation";

export const reportsTypeArray = [
    { key: FIREWATCH_LOG_DOC, title: 'Firewatch Acknowledgements' },
    { key: OBSERVATION_REPORT, title: 'Observation Report' },
    { key: GUARD_EVALUATION, title: 'Guard Evaluation Report' },
]