import React, { useState } from 'react'
import { Alert, Affix } from 'antd';
import { connect } from "react-redux";
import { DevBlock, IconButton } from 'Common/components';
import { updatePageSettings } from 'Store/ep_admin.actions.js'
import ScheduleResults from '../components/ScheduleResults';
import _ from 'lodash'
import { withApollo } from '@apollo/client/react/hoc';
// import { getmodulePermissions } from 'Common/scripts/Security';


class ScheduleHours extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: null, error: null, };
    // this.modulePermissions = getmodulePermissions('hours-schedule-management');
  }

  render(){
    const { error } = this.state;
    const { activeFilter } = this.props;

    if (error) return <Alert message="Error" description={error} type="error" showIcon />

    return (<>
      {this.props.debug && <DevBlock obj={activeFilter} />}
      {(activeFilter && activeFilter.length > 0) && <ScheduleResults 
        tableProps={this.props.tableProps} 
        filter={activeFilter} 
        allowExport={this.props.allowExport} />}
    </>)
  }

}
const mapStateToProps = ({ ep_admin }) => {
  return ({ ep_admin });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withApollo(ScheduleHours));
