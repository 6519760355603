import React, { Component } from 'react'
import { getBase64, mongoToDate } from 'Common/scripts/Functions';
import { defaultDateFormat, defaultDateTimeFormat } from 'configs';
import { Select } from 'antd'
import { connect } from "react-redux";
import { changeTemplate, changePageSize } from '../redux/actions';

import { View, StyleSheet, Image } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import { PDFPage } from 'Common/components/PdfDoc';
import { Text, P, Row, Col, Hr, Heading } from 'Common/components/PdfDoc/components';

const styles = StyleSheet.create({
    blue_section:{
        backgroundColor: "#E9F2FD",
        padding: 10,
        fontSize: 10,
    },
    section: {
        // margin: 10,
        // padding: 10,
        flexGrow: 1,
        fontSize: 10
    },
});


const MyReport = ({ values, settings }) => {

    var commonProps = { theme: settings.template };

    return (<>
        <Text style={{ fontSize: 25, lineHeight:1.2 }} center {...commonProps}>{values.report_type.label} Report</Text>
        <Text center style={{ fontSize: 10 }} {...commonProps}>Report Date: {mongoToDate(values.report_date).format(defaultDateFormat)}</Text>
        <Text center style={{ fontSize: 12 }} {...commonProps}>Customer: {values.client.label}</Text>

        <View style={styles.blue_section}>
            {/* <Text bold style={{ fontSize:14 }}>Project: asd sa dada</Text> */}
            {/* <Heading type="h2" {...commonProps}>Project: {values.project_name}</Heading> */}
            <Text style={{ fontSize:18 }} {...commonProps}>Project: {values.project_name}</Text>
            <Text wrap {...commonProps}>
                <Text bold {...commonProps}>Location:</Text> {values.project_location}
                <Text bold {...commonProps}> Unit#:</Text> {values.unit_num}
            </Text>
            <Hr color="#FFF" style={{ marginVertical: 3 }} {...commonProps} />
            
            <Text {...commonProps}>
                <Text bold {...commonProps}>Incident Type:</Text> {values.incident_type}
                <Text bold {...commonProps}>     Incident Time:</Text> {mongoToDate(values.inciden_time).format(defaultDateTimeFormat)}
            </Text>
            <Text wrap {...commonProps}><Text bold {...commonProps}>Incident Location:</Text> {values.incident_location}</Text>
            <Hr color="#FFF" style={{ marginVertical: 3 }} {...commonProps} />

            <Row {...commonProps}>
                <Col {...commonProps}>
                    <Text bold {...commonProps}>Reported by:</Text>
                    <Text {...commonProps}>{values.reported_by}</Text>
                </Col>
                <Col style={{ width: 40 }} {...commonProps} />
                <Col style={{ width: "100%" }} {...commonProps}>
                    <Text bold {...commonProps}>Reported to:</Text>
                    <Text {...commonProps}>{values.reported_to} | {values.reported_to_phone} | {values.reported_to_email}</Text>
                </Col>
            </Row>
        </View>

        <View style={{ height: 20 }} />
        <View style={styles.section}>
            <Heading type="h3" {...commonProps}>Description:</Heading>
            <View style={{ border: "1px solid #EEE", padding: 10 }} {...commonProps}><Html style={{ fontSize: 12 }}>{values.description}</Html></View>
        </View>

        {values.witness && <>
            <View style={{ height: 20 }} />
            <Heading type="h3" {...commonProps}>Witness: </Heading>
            {values.witness.map((w, i) => {

                return <View key={i}>
                    {i > 0 && <Hr {...commonProps} />}
                    <Row {...commonProps}>
                        <Col style={{ width: 30 }} {...commonProps}><Text bold style={{ fontSize: 28 }} {...commonProps}>{i + 1}</Text></Col>
                        <Col flex="auto" style={{ borderLeft: "2px solid #EEE", paddingLeft: 10 }} {...commonProps}>
                            <Text style={{ fontSize: 11 }} {...commonProps}><Text bold {...commonProps}>Name:</Text> {w.name}</Text>
                            <Text style={{ fontSize: 11 }} {...commonProps}><Text bold {...commonProps}>Phone:</Text> {w.phone}</Text>
                            <Text style={{ fontSize: 11 }} {...commonProps}><Text bold {...commonProps}>Email:</Text> {w.email}</Text>
                            <Text style={{ fontSize: 11 }} bold {...commonProps}>Details:</Text>
                            <Text style={{ fontSize: 11 }} {...commonProps}>{w.details}</Text>
                        </Col>
                        {w.attachment && <Col {...commonProps}>
                            <View style={{ backgroundColor: "#EEE", height: "100px", width: "100px" }} {...commonProps}>
                                <Image source={getBase64(w.attachment[0])} />
                            </View>
                        </Col>}
                    </Row>
                </View>
            })}
        </>}

        <View style={{ height: 20 }} />
        <View style={styles.section}>
            <Heading type="h3" {...commonProps}>Actions Taken:</Heading>
            <View style={{ border: "1px solid #EEE", padding: 10 }}><Text style={{ fontSize: 12 }} {...commonProps}>{values.actions_taken}</Text></View>
        </View>

        <View style={{ height: 20 }} />
        <View style={styles.section}>
            <Heading type="h3" {...commonProps}>Recommendations:</Heading>
            <View style={{ border: "1px solid #EEE", padding: 10 }}><Text style={{ fontSize: 12 }} {...commonProps}>{values.recommendations}</Text></View>
        </View>

        <View style={{ height: 20 }} />
        {values?.extra_attachment?.map((file, i) => {
            return <View style={{ width: "100%", backgroundColor: "#EEE", marginBottom: "5px" }} key={i}>
                <Image source={getBase64(file)} />
            </View>
        })}

    </>)
}

class PreviewReport extends Component {
    // state = { pageSize: "A4", template:"template1" }

    render() {
        const { values, children } = this.props;

        return (<>

            <Select style={{ width: 120, }} 
                label="Template"
                // onChange={(e) => this.setState({ template:e })} 
                onChange={e => this.props.changeTemplate(e)}
                defaultValue="template1"
                options={[
                    { value: 'template1', label: 'Template 1' },
                    { value: 'template2', label: 'Template 2' },
                ]}
            />

            <Select style={{ width: 120, }} 
                // onChange={(e) => this.setState({ pageSize: e })} 
                onChange={e => this.props.changePageSize(e)}
                defaultValue="A4"
                options={[
                    { value: 'A4', label: 'A4' },
                    { value: 'A3', label: 'A3' },
                    { value: 'LEGAL', label: 'LEGAL' },
                    { value: 'LETTER', label: 'LETTER' },
                ]}
            />

            <PDFPage {...this.props.po_settings} headerRight={<View><Text style={{ fontSize: "10px" }}>Report Date: {mongoToDate(values.report_date).format(defaultDateFormat)}</Text></View>}>
                <MyReport values={values} settings={this.props.po_settings} />
            </PDFPage>
        </>)
    }
}

const mapStateToProps = ({ po_settings }) => ({ po_settings });
const mapDispatchToProps = (dispatch, ownProps) => ({
    changeTemplate: (payload) => dispatch(changeTemplate(payload)),
    changePageSize: (payload) => dispatch(changePageSize(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(PreviewReport)




