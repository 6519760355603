/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import { Form, Field } from 'react-final-form'
import { rules, composeValidators, FormField } from 'Common/components/Form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Button, IconButton, Loader, DevBlock, Icon } from 'Common/components'
import { Row, Col, Upload, message, Result } from 'antd'
import compose from 'lodash.flowright';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { Divider } from 'antd';
import moment from 'moment';
import Header from './Header'
import SignaturePad from 'react-signature-canvas'
import axios from 'axios'
import { __error } from 'Common/scripts/consoleHelper';
import { defaultDateFormat } from 'configs';
import { Redirect } from 'react-router-dom';
// import './style.scss'
 
const ADD_DATA = loader('src/Graphql/applicant_form/addGuardApplication.graphql');
const update_DATA = loader('src/Graphql/applicant_form/edit_application.graphql');
const SINGLE = loader('src/Graphql/applicant_form/single.graphql');
const DETAILS = loader('src/Graphql/applicant_form/detail.graphql');
const VIEWED = loader('src/Graphql/invitation/edit.graphql');
const ADDHISTORY = loader('src/Graphql/history/add.graphql');
// const TOTAL = loader('src/Graphql/applicant_form/get_total.graphql');
// const EDITMULTIPLEDUPS = loader('src/Graphql/applicant_form/edit_mutiple_dups.graphql');


const errorDetails = {
  appt_no: "your appartment number is missing!",
  available_start_date: "please provide available start date",
  available_to_work: "please provide your work availability",
  certify: "please check the filed ( I certify that my answers are true and complete to the best of my knowledge. If this application leads to employment, I understand that false or misleading information in my application or interview may result in my release )",
  college_university_techicalschool: "Have you completed a college, university or technical school program? ",
  d_o_b: "please provide your date of birth",
  email_address: "please provide your email address",
  emergency_contact: "Please provide complete details of emergency contact!",
  f_name: "Please provide your first name",
  highest_level_of_education: "please provide your highest level of education",
  home_phone: "please provide your home phone number",
  job_info_source: "please provide job info source",
  l_name: "please provide l name",
  license_expiry: " please provide your license expiry",
  license_no: "please provide your license number",
  mobile_phone: "please provide your mobile number",
  postal_code: "please provide postal code ",
  secondary_school_diploma: "please provide secondary school diploma",
  sin_no: "please provide sin no",
  street: "please provide your street ",
  province: "please select your province",
  city: "please select your city",
  position_applied_for: "please select the position applied for",
  gender: "please select your gender",
  // residential_status: "please provide your residential status",
  
  previously_worked_date: "previously worked date is missing",
  previously_applied_date: "previously applied date in missing",
  crime: "Crime description is missing",
  true_availability: "please confirm that your availability is true",
  availability_changes: "please acknowledge that your availability eill not change in 90 days"
}

const getError = (errors) => {
  let field = false;
  for(let a in errors){
    if (!field){
      field = a;
      return errorDetails[a];
      break;
    }
  }

  // return <div style={{color:"red", textAlign:"center"}}>{errorDetails[field]}</div>;
}


class Applicant_form extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      busy: false, 
      // _id: null, 
      // filePath: [], 
      fileList: [], 
      uploading: false, 
      trimmedDataURL: null, 
      edit: null,
      initial: null,
      loading: false,
      total: null
      // numPages: null, 
    }
    this.onSubmit = this.onSubmit.bind(this);
   this.handleUpload = this.handleUpload.bind(this)
   this.updateAttachment = this.updateAttachment.bind(this)
   this.sendemail = this.sendemail.bind(this)
   this.setEdit = this.setEdit.bind(this)
  }
  setEdit(data){
    this.setState({loading: true})
    const dat={
      f_name: data.personalinfo.f_name,
      m_name: data.personalinfo.m_name,
      l_name: data.personalinfo.l_name  ,
      street: data.personalinfo.address.street,
      province: data.personalinfo.address.province,
      city: data.personalinfo.address.city,
      postal_code: data.personalinfo.address.postal_code,
      appt_no: data.personalinfo.address.appt_no,
      home_phone: data.personalinfo.home_phone,
      mobile_phone: data.personalinfo.mobile_phone,
      email_address: data.personalinfo.email_address,
      d_o_b: moment(data.personalinfo.d_o_b, 'YYYY/MM/DD'),
      gender: data.personalinfo.gender,
      position_applied_for: data.personalinfo.position_applied_for,
      // residential_status: data.personalinfo.residential_status,
      sin_no: data.personalinfo.sin_no,
      license_no: data.personalinfo.license_no,
      license_expiry: moment(data.personalinfo.license_expiry , 'YYYY/MM/DD'),
      currently_licensed: data.personalinfo.currently_licensed == "yes"? true: false,
      previously_worked_date: data.personalinfo.previously_worked_date ?  moment(data.personalinfo.previously_worked_date, 'YYYY/MM/DD') : null,
      worked: data.personalinfo.previously_worked_date ? true : false,
      previously_applied_date: data.personalinfo.previously_applied_date ? moment(data.personalinfo.previously_applied_date, 'YYYY/MM/DD') : null,
      applied: data.personalinfo.previously_applied_date ? true : false,
      current_company_name: data.personalinfo.current_company_name,
      question_current: data.personalinfo.current_company_name ? true: false,
      older_than_18: data.personalinfo.older_than_18 == "yes"? true: false,
      legally_entitled: data.personalinfo.legally_entitled == "yes"? true: false,
      available_to_work: data.availability.available_to_work,
      available_start_date: moment(data.availability.available_start_date, 'YYYY/MM/DD'),
    
      morning_mon: data.availability.part_time_availaibity.mon[0] == "morning"? true: false,
      afternoon_mon: data.availability.part_time_availaibity.mon[1] == "afternoon" ? true : false,
      night_mon: data.availability.part_time_availaibity.mon[2] == "night" ? true : false,
      morning_tue: data.availability.part_time_availaibity.tue[0] == "morning" ? true : false,
      afternoon_tue: data.availability.part_time_availaibity.tue[1] == "afternoon" ? true : false,
      night_tue: data.availability.part_time_availaibity.tue[2] == "night" ? true : false,
      morning_wed: data.availability.part_time_availaibity.wed[0] == "morning" ? true : false,
      afternoon_wed: data.availability.part_time_availaibity.wed[1] == "afternoon" ? true : false,
      night_wed: data.availability.part_time_availaibity.wed[2] == "night" ? true : false,
      morning_thu: data.availability.part_time_availaibity.thu[0] == "morning" ? true : false,
      afternoon_thu: data.availability.part_time_availaibity.thu[1] == "afternoon" ? true : false,
      night_thu: data.availability.part_time_availaibity.thu[2] == "night" ? true : false,
      morning_fri: data.availability.part_time_availaibity.fri[0] == "morning" ? true : false,
      afternoon_fri: data.availability.part_time_availaibity.fri[1] == "afternoon" ? true : false,
      night_fri: data.availability.part_time_availaibity.fri[2] == "night" ? true : false,
      morning_sat: data.availability.part_time_availaibity.sat[0] == "morning" ? true : false,
      afternoon_sat: data.availability.part_time_availaibity.sat[1] == "afternoon" ? true : false,
      night_sat: data.availability.part_time_availaibity.sat[2] == "night" ? true : false,
      morning_sun: data.availability.part_time_availaibity.sun[0] == "morning" ? true : false,
      afternoon_sun: data.availability.part_time_availaibity.sun[1] == "afternoon" ? true : false,
      night_sun: data.availability.part_time_availaibity.sun[2] == "night" ? true : false,

      valid: data.driving_information.valid ? true : false,
      question3 : data.driving_information.make ? true: false,
      make: data.driving_information.make,
      model: data.driving_information.model,
      license_plate_no: data.driving_information.license_plate_no,
      color: data.driving_information.color,
      highest_level_of_education: data.education.highest_level_of_education,
      secondary_school_diploma: data.education.secondary_school_diploma,
      college_university_techicalschool: data.education.college_university_techicalschool,
      training_courses_detail: data.education.training_courses_detail,
      question2: data.education.training_courses_detail ? true : false,
      company_name: data.experience.company_name,
      supervisor_name: data.experience.supervisor_name,
      supervisor_work_number: data.experience.supervisor_work_number,
      hourly_rate: data.experience.hourly_rate,
      work_location: data.experience.work_location,
      work_site_property_name: data.experience.work_site_property_name,
      category: data.experience.category,
      list_duties_performed: data.experience.list_duties_performed,
      work_start_date: moment(data.experience.work_start_date, 'YYYY/MM/DD'),
      work_end_date: moment(data.experience.work_end_date, 'YYYY/MM/DD' ),
      work_end_season: data.experience.work_end_season,
      fullname: data.emergency_contact.map(data => data.fullname),
      number: data.emergency_contact.map(data => data.number),
      relation: data.emergency_contact.map(data => data.relation),
      documents_attached: data.documents_attached,
      signed_document: data.signed_document,
      decleration: data.decleration,
      crime: data.crime,
      question6: data.crime,
      job_info_source: data.job_info_source,
      ref_fullname: data.ref_fullname,
      ref_number: data.ref_number,
      ref_position: data.ref_position,
      ref_company: data.ref_company,
      _ref_fullname: data._ref_fullname,
      _ref_number: data._ref_number,
      _ref_position: data._ref_position,
      _ref_company: data._ref_company,
    }
    this.setState({ initial: dat, loading: false})
  }

  componentDidMount(){
    if (this.props.match.params.id && this.props.match.params.section == 'edit' ){
      this.setState({loading: true})
      this.props.client.mutate({ mutation: DETAILS, variables: { id: this.props.match.params.id } }).then(r => {
        if (r.data.application.error) {
          message.error(r.data.application.message);
          this.setState({ error: r.data.application.message, busy: false })
          return false;
        }
        this.setState({ edit: r.data.application, loading: false})
        this.setEdit(r.data.application)
      }).catch(err => {
        console.log(__error("ERROR: "), err);
        this.setState({ busy: false, error: "Unable to submit your request at the moment!" });
        return false;
      })

     
   }
    if (this.props.match.params.id && this.props.match.params.section == 'invitation') {
      this.setState({ loading: true })
      this.props.client.mutate({ mutation: VIEWED, variables: { input: { _id: this.props.match.params.id, status: "opened"} } }).then(r => {
        if (r.data.editInvitation.error) {
          message.error(r.data.editInvitation.message);
          this.setState({ error: r.data.editInvitation.message, busy: false })
          return false;
        }
        this.setState({  loading: false })
      }).catch(err => {
        console.log(__error("ERROR: "), err);
        this.setState({ busy: false, error: "Unable to update invitation history!" });
        return false;
      })
    }
    
  }
  sigPad = {}
  clear = (event) => {
    event.preventDefault()
    this.sigPad.clear()
    if (this.state.trimmedDataURL !==null){
      this.setState({ trimmedDataURL: null})
    }
  }
  trim = (event) => {
    event.preventDefault()

    const trimmedDataURL = this.sigPad.getTrimmedCanvas().toDataURL('image/png');

    this.setState({ trimmedDataURL })
  }
  
  async onSubmit (values)  {
    const filteredValues = { 

      personalinfo: {
        f_name: values.f_name,
        m_name: values.m_name,
        l_name: values.l_name,

        address: {
          street: values.street,
          province: values.province,
          city: values.city,
          postal_code: values.postal_code,
          appt_no: values.appt_no,
        },
        home_phone: values.home_phone,
        mobile_phone: values.mobile_phone,
        email_address: values.email_address,

        d_o_b: values.d_o_b,
        gender: values.gender,

        position_applied_for: values.position_applied_for,
        // residential_status: values.residential_status,

        sin_no: values.sin_no,

        license_no: values.license_no,
        license_expiry: values.license_expiry,
        currently_licensed: values.currently_licensed === true ?  "yes": "no",

        previously_worked_date: values.previously_worked_date,
        previously_applied_date: values.previously_applied_date,
        current_company_name: values.current_company_name,
        older_than_18: values.older_than_18 ===true ? "yes" : "no" ,
        legally_entitled: values.legally_entitled === true ? "yes" : "no", 
      },

      certify: values.certify === true ? "yes" : "no",
      crime: values.question6 === true ? values.crime : null,
      job_info_source:  values.job_info_source ,

      availability: {
        available_to_work: values.available_to_work,
        available_start_date: values.available_start_date,
        part_time_availaibity: {
          mon: [(values.morning_mon ? "morning" : ""), (values.afternoon_mon ? "afternoon" : ""), (values.night_mon ? "night" : "")],
          tue: [(values.morning_tue ? "morning" : ""), (values.afternoon_tue ? "afternoon" : ""), (values.night_tue ? "night" : "")],
          wed: [(values.morning_wed ? "morning" : ""), (values.afternoon_wed ? "afternoon" : ""), (values.night_wed ? "night" : "")],
          thu: [(values.morning_thu ? "morning" : ""), (values.afternoon_thu ? "afternoon" : ""), (values.night_thu ? "night" : "")],
          fri: [(values.morning_fri ? "morning" : ""), (values.afternoon_fri ? "afternoon" : ""), (values.night_fri ? "night" : "")],
          sat: [(values.morning_sat ? "morning" : ""), (values.afternoon_sat ? "afternoon" : ""), (values.night_sat ? "night" : "")],
          sun: [(values.morning_sun ? "morning" : ""), (values.afternoon_sun ? "afternoon" : ""), (values.night_sun ? "night" : "")],
        }
      },
      driving_information: {
        valid: values.valid === true ? "yes" : "no",
        make: values.make,
        model: values.model,
        license_plate_no: values.license_plate_no,
        color: values.color,
      },
      education: {
        highest_level_of_education: values.highest_level_of_education,
        secondary_school_diploma: values.secondary_school_diploma,
        college_university_techicalschool: values.college_university_techicalschool,
        training_courses_detail: values.training_courses_detail,
      }, 
      experience: !values.experience ? undefined : values.experience.map(({ company_name, supervisor_name, supervisor_work_number, hourly_rate, work_location, work_site_property_name, category, list_duties_performed, work_end_season, work_start_date, work_end_date }) => ({ company_name, supervisor_name, supervisor_work_number, hourly_rate, work_location, work_site_property_name, category, list_duties_performed, work_end_season, work_end_date, work_start_date })),
      
      emergency_contact: !values.emergency_contact ? undefined : values.emergency_contact.map(({ fullname, number, relation }) => ({ fullname, number, relation })),
      // emergency_contact:{
      //   fullname: values.name,
      //   number: values.number,    
      //   relation: values.relation,
      // },
      signed_document: this.state.trimmedDataURL,

      ref_fullname: values.ref_fullname,
      ref_number: values.ref_number,
      ref_position: values.ref_position,
      ref_company: values.ref_company,

      _ref_fullname: values._ref_fullname,
      _ref_number: values._ref_number,
      _ref_position: values._ref_position,
      _ref_company: values._ref_company,
      
      decleration: values.decleration,
      date: values.date,
    }

    this.setState({ error: null, busy:true })
    var appResults =null
    if(this.state.initial){
      filteredValues['_id'] = this.state.edit._id
      
      appResults = await this.props.client.mutate({ mutation: SINGLE, variables: { input:  filteredValues } }).then(r => {
        if (r.data.updateWithHistory.error){
          message.error(r.data.updateWithHistory.message);
          this.setState({ error: r.data.updateWithHistory.message, busy: false })
          return false;
        }
        const _data ={
          rec_id: this.props.match.params.id,
          history: JSON.stringify(filteredValues)
        }

        this.props.client.mutate({ mutation: ADDHISTORY, variables: { input: _data } }).then(r => {
          if (r.data.addHistory.error) {
            message.error(r.data.addHistory.message);
            this.setState({ error: r.data.addHistory.message, busy: false })
            return false;
          }

        }).catch(err => {
          this.setState({ busy: false, error: "Unable to submit your request at the moment!" });
          return false;
        })

         return r.data.updateWithHistory._id;
        
      }).catch(err => {
        this.setState({ busy: false, error: "Unable to submit your request at the moment!" });
        return false;
      })
      
    }
    else{

       appResults = await this.props.client.mutate({ mutation: ADD_DATA, variables: { input: filteredValues,  } }).then(r => {
        if (r.data.addGuardApplication.error) {
          message.error(r.data.addGuardApplication.message);
          this.setState({ error: r.data.addGuardApplication.message, busy: false })
          return false;
        }
        return r.data.addGuardApplication._id;
  
      }).catch(err => {
        console.log("================= ERROR ===================\n", err, "\n====================================");
        this.setState({ busy: false, error: "Unable to submit your request at the moment!" });
        return false;
      })

    }
    if (values.submit_physically == true)
    {

      window.location.href = '/employment_application_request/:id/success'
    }


    if (!appResults || appResults.error || values.submit_physically==true) return;

    // Handel file upload
    const uploadResults = await this.handleUpload(appResults)
      .then(res => {
        if(res.error){
          message.error(res.error.message);
          return false;
        }
        console.log(res, "data returning from handle upload")
        return res;
      }).catch(err => {
        console.log("error", err);
        message.error("Unexpected error in file upload!");
        return false;
      });

    if (!uploadResults || uploadResults.error){
      message.error( "unable to upload");
      this.setState({ busy: false });
      return;
    }


    //// update records in DB
    const updateResults = this.updateAttachment(uploadResults.result, appResults).then(res=>{
      if (res.error) {
        message.error(res.error.message);
        return false;
      }
      return true;
    }).catch(err=>{
      console.log(err)
      message.error("Unable to add attachments to application!");
      return false;
    })

    
    if (!updateResults){
      this.setState({ busy:false })
    }else{
      message.success("Submission successfull!");
      this.sendemail(values);
      if (this.props.match.params.id && this.props.match.params.section == 'invitation') {
        this.setState({ loading: true })

        this.props.client.mutate({ mutation: VIEWED, variables: { input: { _id: this.props.match.params.id, status: "Submitted" } } }).then(r => {
          if (r.data.editInvitation.error) {
            message.error(r.data.editInvitation.message);
            this.setState({ error: r.data.editInvitation.message, busy: false })

            return false;
          }
          this.setState({ loading: false })
          console.log("the invitation history is updated as viewed", r)
         window.location.href = '/employment_application_request/:id/success';
        }).catch(err => {
          console.log("================= ERROR ===================\n", err, "\n====================================");
          this.setState({ busy: false, error: "Unable to update invitation history!" });
          return false;
        })
      }
     else window.location.href = '/employment_application_request/:id/success'; 
     
    }
   
  }
  sendemail(values) {
    if (values.email_address) {
      const filter = {
        action: "Submmission Confirmation",
        mailInfo: {
          email: values.email_address,
          subject: "Confirmation",
          body: "testing the mail",
          name: values.f_name 
        }
      }
      // process.env.REACT_APP_HOST_URL
      axios.post(process.env.REACT_APP_API_URL + '/mail/send', filter)
        .then(({ data }) => {
          console.log(data);
          return data;
        })
        .catch(function (error) {
          console.log(__error("ERROR: "), error);
          return { error: { message: "Request Error!" } };
        });
    };

  }

  async handleUpload(_id) {
    const { fileList } = this.state;
    console.log(this.state.fileList)
    var _files = this.state.fileList
    if (this.state.fileList.length > 6)
    {
      _files = _files.slice(0,6)
    }
    else _files = this.state.fileList

    const formData = new FormData();
    formData.append('action', "job.application.attachments");
    formData.append('_id', _id);
    _files.forEach(file => {
      formData.append('files[]', file);
    });

    return axios.post(process.env.REACT_APP_API_URL + '/upload/job_application/attachments', formData)
      .then(({data}) => data )
      .catch(function (error) {
        console.log(error);
        return { error:{message:"Request Error!"} };
      });
  };

  async updateAttachment(pathArray, _id) {
    const filteredValues = {
      _id,
      documents_attached: {
        title: pathArray.map(e => e.title),
        path: pathArray.map(o => o.file_path)
      },
    }

    return this.props.client.mutate({ mutation: update_DATA, variables: { input: filteredValues } }).then(r => {
      return r.data
    }).catch(err => {
      console.log(__error("ERROR: "), err);
      return { error: { message:"Unable to upload document!"} };
    })

  }




  render() {
    const { busy, fileList, loading } = this.state;
    if (loading) return <Loader loading={true} />

    return (<div style={{ backgroundColor: "#f8f9fa", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", minWidth: "700px" }} className="application-form">
      <div style={{ width: "100%", minWidth: "700px"}}><Header /></div>
      <div style={{ minWidth: "700px", maxWidth: "1000px", }}>
        <div style={{  }}>
          <hr className='line' />
          
          <Form onSubmit={this.onSubmit} initialValues={{ ...this.state.initial }} validate={(__values) => (false)}
            mutators={{ ...arrayMutators }}
          >
            {(formProps) => {
              const { handleSubmit, submitting, reset, values, invalid, dirty, valid, errors } = formProps;

              const _handleSubmit = (e) => {
                e.preventDefault();
                if (errors){
                  let err = getError(errors)
                  if (err) message.error(err)
                  
                  {/* return; */}
                }
                handleSubmit();
              }
              
              return (<>
                <form className="form_component" onSubmit={_handleSubmit}>
                  <div className='card'>
                    <span style={{ fontSize: "28px", color: "#001529" }} > <Icon icon="user-alt" color={"#001529"} style={{marginLeft: "10px"}} /> Personal Information</span>
                    <Row>
                      <Col flex="35%">
                        <FormField id="f_name" label="First name" name="f_name" size="medium" type="text" validate={rules.required} />
                      </Col>
                      <Col flex="35%"><FormField label="Middle name" name="m_name" size="medium" type="text" /></Col>
                      <Col flex="auto"><FormField label="Last name" name="l_name" size="medium" type="text" validate={rules.required} /></Col>
                    </Row>

                    <Row>
                      <Col flex="250px"><FormField name="appt_no" type="text" size="medium" label="Apt / House #" validate={rules.required} /></Col>
                      <Col flex="250px"><FormField name="street" type="text" size="medium" label="Street address" validate={rules.required} /></Col>
                      <Col flex="auto"><FormField name="province" type="select" size="medium" placeholder="SELECT" validate={rules.required} label="Province" data={[
                        { _id: "alberta", title: "Alberta" },
                        { _id: "ontario", title: "Ontario" },
                      ]} /></Col>
                      <Col flex="auto"><FormField name="city" type="select" size="medium" placeholder="SELECT" label="City" validate={rules.required} data={[
                        { _id: "calgary", title: "Calgary" }, { _id: "airdrie", title: "Airdrie" }, { _id: "toronto", title: "Toronto" }
                      ]} /></Col>
                    </Row>
                    <Row>
                      <Col flex="250px"><FormField name="postal_code" size="medium" type="text" label="Postal code" validate={rules.required} /></Col>
                      <Col flex="250px"><FormField name="home_phone" type="text" size="medium" label="Home phone " placeholder="FORMAT: XXXXXXXXXX" validate={rules.required} /></Col>
                      <Col flex="auto"><FormField name="mobile_phone" type="text" size="medium" label="Mobile phone " placeholder="FORMAT: XXXXXXXXXX" validate={rules.required} /></Col>
                    </Row>
                    <Row>
                      <Col flex="200px"><FormField name="email_address" type="email" size="medium" label="Email address" validate={composeValidators(rules.required, rules.isEmail)} /></Col>
                      <Col flex="200px"><FormField name="d_o_b" size="medium" type="date" label="Date of birth" placeholder="YYYY-MM-DD" validate={rules.required} /></Col>
                      <Col flex="200px" ><FormField name="gender" type="select" size="medium" label="Gender" placeholder="SELECT" data={[
                        { _id: "male", title: "Male" }, { _id: "female", title: "Female" }, { _id: "x", title: "X" },
                      ]} /></Col>
                      <Col flex="auto">
                        <FormField name="position_applied_for" type="select" label="Position applied for" size="medium" placeholder="SELECT" data={[
                          { _id: "petrol_guard", title: "Petrol Guard" }, { _id: "security_guard", title: "Security guard" },
                        ]} />
                      </Col>
                    </Row>


                    {/* <Row>
                      <Col flex="250px"><FormField size="medium"  name="residential_status" validate={rules.required} type="select" label="Residential status" placeholder="SELECT" data={[
                        { _id: "pr/citizen", title: "PR/Citizen" }, { _id: "international", title: "International" },
                      ]} /></Col>
                      <Col flex="250px"><FormField name="sin_no" size="medium" type="text" label="SIN card no." validate={rules.required} /></Col>
                    </Row> */}

                    <Row>
                      <Col flex="auto"><FormField name="license_no" type="text" size="medium" label="Security guard license # " validate={rules.required} /></Col>
                      <Col flex="300px"><FormField name="license_expiry" type="date" size="medium" label="License expiry" placeholder={defaultDateFormat} validate={rules.required} /></Col>
                    </Row>

                    <Row className='dark'>
                      <Col><FormField name="question" type="switch" name="currently_licensed" /></Col>
                      <Col flex="auto" style={{ paddingTop: "8px" }}>Do you have a valid Alberta Security License?</Col>
                    </Row>

                    <Row className='simple'>
                      <Col><FormField  type="switch" name="worked" /></Col>
                      <Col flex="auto" style={{ paddingTop: "8px" }}>Have you previously worked with us?</Col>
                      <Col>{values.worked &&
                        <FormField name="previously_worked_date" size="medium" compact type="date" placeholder="YY-MM-DD" validate={rules.required} />
                      }</Col>
                    </Row>

                    <Row className='dark'>
                      <Col><FormField  type="switch" name="applied" /></Col>
                      <Col flex="auto" style={{ paddingTop: "8px" }}>Have you previously applied with us?</Col>
                      <Col>{values.applied &&
                        <FormField name="previously_applied_date" size="medium" compact type="date" placeholder="YY-MM-DD" validate={rules.required} />
                      }</Col>
                    </Row>

                    <Row className='simple'>
                      <Col><FormField name="question_current" type="switch" /></Col>
                      <Col flex="auto" style={{ paddingTop: "8px" }}>Are you currently working for another security company?</Col>
                      <Col>{values.question_current &&
                        <FormField name="current_company_name" size="medium" compact type="text" label="Company name" validate={rules.required} />
                      }</Col>
                    </Row>

                    <Row className='dark'>
                      <Col><FormField  type="switch" name="older_than_18"  /></Col>
                      <Col flex="auto" style={{ paddingTop: "8px" }}>Are you 18 years of age or older?</Col>
                    </Row>

                    <Row className='simple' style={{ flexWrap: "nowrap" }}>
                      <Col><FormField  type="switch" name="legally_entitled" /></Col>
                      <Col flex="auto" style={{ paddingTop: "8px" }}>Are you legally entitled to work in Canada?</Col>
                    </Row>

                    <Row className='simple' align='left' >
                      <Col><FormField name="question6" type="switch" /></Col>

                      <Col flex="auto" style={{ paddingTop: "8px" }}>Have you ever been convicted of a crime?</Col>

                    </Row>
                    {values.question6 &&
                      <FormField align="left" rows={2} name="crime" size="medium" type="textarea" label="Describe your crime" validate={rules.required} />
                    }

                  </div>

                  <div style={{ height: '20px' }} />
                  <hr className='line' />

                  <div className='card'>
                    <span style={{ fontSize: "28px", color: "#001529" }} > <Icon color={"#001529"} icon="clock" style={{ marginLeft: "10px" }} /> Please Indicate Your Availability</span>
                    <Row align="top">
                      <Col flex="50%"><FormField name="available_to_work" size="medium" placeholder="SELECT" type="select" label="Are you available to work?" validate={rules.required} data={[{ _id: "part_time", title: "Part Time" }, { _id: "full_time", title: "Full Time" }, { _id: "temporary", title: "temporary" }]} /></Col>
                      <Col flex="auto"><FormField name="available_start_date" size="medium" placeholder="DD-MM-YYYY" type="date" label="Available start date?" validate={rules.required} /></Col>
                    </Row>

                    <p>
                      <b style={{color: "red"}}>Note:</b> For full-timers  (Availability must be week days, Weekends, Public holidays, Morning, Night & Afternoon Shifts) For part-timers (Fill the chart below)
                    </p>

                    <Row className='dark' align="middle">
                      {['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
                        return <Col flex="12.5%" className="bold" key={index}>{day}</Col>
                      })}
                    </Row>
                    <Row className='simple' align="middle">
                      {['Morning', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                        if (i == 0) return <Col flex="12.5%" className="bold" key={i}>{day}</Col>;
                        return <Col flex="12.5%" key={i}><FormField name={`morning_${day}`} type='switch' /></Col>
                      })}
                    </Row>
                    <Row className='dark' align="middle">
                      {['Afternoon', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                        if (i == 0) return <Col flex="12.5%" className="bold" key={i}>{day}</Col>;
                        return <Col flex="12.5%" key={i}><FormField name={`afternoon_${day}`} type='switch' /></Col>
                      })}
                    </Row>
                    <Row className='simple' align="middle">
                      {['Night', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                        if (i == 0) return <Col flex="12.5% " className="bold" key={i}>{day}</Col>;
                        return <Col flex="12.5%" key={i}><FormField name={`night_${day}`} type='switch' /></Col>
                      })}
                    </Row>
                    {values.morning_mon || values.afternoon_mon || values.night_mon || values.morning_tue || values.afternoon_tue || values.night_tue || values.morning_wed || values.afternoon_wed || values.night_wed || values.morning_thu || values.afternoon_thu || values.night_thu || values.morning_fri || values.afternoon_fri || values.night_fri || values.morning_sat || values.afternoon_sat || values.night_sat || values.morning_sun || values.afternoon_sun || values.night_sun ? <> <Row className='' style={{ flexWrap: "nowrap", alignItems: "center", }}>
                      <Col style={{ paddingTop: "20px",  }}><FormField name="true_availability" type="checkbox" validate={rules.required} /></Col>
                      <Col  flex="auto" >I confirm this availability to be true</Col>
                    </Row>
                    <Row style={{  flexWrap: "nowrap", alignItems: "center", height: "40px" }}>
                        <Col style={{ paddingTop: "20px",  }}><FormField name="availability changes" type="checkbox" validate={rules.required} /></Col>
                        <Col flex="auto"  >I acknowledge if my availability changes with in 90 days (with in the probation period), A-list security have the right to void my contract</Col>
                    </Row>
                    </>
                    
                    : null}

                    <div>
                      <FormField name="available_check" type="checkbox" label="Do you confirm your availability!" validate={rules.required} />
                    </div>



                  </div>

                  <div style={{ height: '20px' }} />
                  <hr className='line' />

                  <div className='card'>
                    <span style={{ fontSize: "28px", color: "#001529" }} > <Icon color={"#001529"} icon="car-side" style={{ marginLeft: "10px", marginRight: "5px" }} />Driving Information</span>

                    <Row className='dark'>
                      <Col flex="100%"><FormField name="valid" label="Do you have a valid driving license?" type='switch' /></Col>
                    </Row>

                    <Row className='simple'>
                      <Col flex="100%"><FormField name="question3" label="Do you own or have regular access to a vehicle to get back and forth to and from work?" type='switch' /></Col>
                    </Row>

                    {values.question3 && <Row>
                      <Col flex="auto" ><FormField name="make" size="medium" type="text" label="Make" /></Col>
                      <Col flex="250px"><FormField name="model" type="text" size="medium" label="Model" /></Col>
                      <Col flex="250px"><FormField name="license_plate_no" type="text" size="medium" label="License plate no." /></Col>
                      <Col flex="250px"><FormField name="color" type="text" label="Color" size="medium" /></Col>

                    </Row>}
                  </div>

                  <div style={{ height: '20px' }} />
                  <hr className='line' />

                  <div className='card' >
                    <span style={{ fontSize: "28px", color: "#001529" }}><Icon color={"#001529"} icon="user-graduate" style={{ marginLeft: "10px", marginRight: "5px" }} />Education</span>
                    <FormField name="highest_level_of_education" label="The highest level of education completed?" size="medium" type="text" validate={rules.required} />
                    <FormField label="Do you have a secondary school diploma?" name="secondary_school_diploma" type="text" size="medium" validate={rules.required} />
                    <FormField label="Have you completed a college, university or technical school program?" name="college_university_techicalschool" size="medium" type="text" validate={rules.required} />

                    <FormField label="Have you completed any training courses relevant to the security industry?" name="question2" size="medium" type='switch' />
                    {values.question2 && <FormField label="Training courses details" name="training_courses_detail" size="medium" type="text" />}

                  </div>

                  <div style={{ height: '20px' }} />
                  <hr className='line' />

                  <div className='card'>

                    <FieldArray name="experience">
                      {({ fields }) => (
                        <div>

                          <Row>
                            <Col flex="auto"><span style={{ fontSize: "28px", color: "#001529" }} ><Icon color={"#001529"} icon="lock" style={{ marginLeft: "10px", marginRight: "5px" }} />Security Experience </span></Col>
                            <Col><Button onClick={() => fields.push({ firstName: '', lastName: '' })}>Add</Button></Col>
                          </Row>
                          {!values.experience && <p style={{marginLeft: "10px"}} >Please add your experience if you have previously worked as Security Guard</p>}

                          {fields.map((name, index) => (
                            <Row key={index} style={{ borderBottom: "1px solid #EEE", marginBottom: "10px" }}>
                              <Col flex="50px" style={{ fontSize: "34px", textAlign: "center" }}>
                                <div>{index + 1}</div>
                                <IconButton icon="trash-alt" onClick={() => fields.remove(index)} />
                              </Col>
                              <Col flex="auto">
                                <Row style={{ flexWrap: "nowrap" }}>
                                  <Col flex="33%"><FormField name={`${name}.company_name`} label="Security company name" size="medium" type="text" /></Col>
                                  <Col flex="33%"><FormField name={`${name}.supervisor_name`} label="Supervisor name" type="text" size="medium" /></Col>
                                  <Col flex="auto"><FormField name={`${name}.supervisor_work_number`} label="Supervisor work number" type="text" size="medium" /></Col>
                                </Row>
                                <Row style={{ flexWrap: "nowrap" }}>
                                  <Col flex="33%"><FormField name={`${name}.hourly_rate`} label="Hourly rate" type="text" size="medium" /></Col>
                                  <Col flex="auto"><FormField name={`${name}.work_location`} size="medium" label="Work location / site address(where security guard duty is/was performed)" type="text" /></Col>
                                </Row>
                                <Row style={{ flexWrap: "nowrap" }}>
                                  <Col flex="33%"><FormField name={`${name}.work_site_property_name`} size="medium" label="Work / site / property name" type="text" /></Col>
                                  <Col flex="auto"><FormField name={`${name}.category`} size="medium" label="Category (condo, truck yard etc)" type="text" /></Col>
                                </Row>

                                {/* <FormField key={index+3} name={`${name}.list_duties_performed`} label="LIST DUTIES PERFORMED" type="text" validate={rules.required} /> */}

                                <Row style={{ flexWrap: "nowrap" }}>
                                  <Col flex="150px">
                                    <div><FormField type="date" name={`${name}.work_start_date`} size="medium" label="Work start date" /></div>
                                    <div><FormField type="date" name={`${name}.work_end_date`} size="medium" label="Work end date" /></div>
                                  </Col>
                                  <Col flex="auto"><FormField name={`${name}.work_end_season`} size="medium" label="Work end reason" type="textarea" /></Col>
                                </Row>
                              </Col>
                            </Row>

                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </div>
                  <div style={{ height: '20px' }} />
                  <hr className='line' />

                  <div className='card'>
                    <span style={{ fontSize: "28px", color: "#001529" }} ><Icon color={"#001529"} icon="address-card" style={{ marginLeft: "10px", marginRight: "5px" }} />References</span>
                    <p style={{marginLeft: "10px"}}>We may contact references for verification </p>
                    <Divider orientation="left">Refrence # 1</Divider>
                    <Row>
                      <Col flex="auto"><FormField name="ref_fullname" label=" Name" size="medium" type="text" /></Col>
                      <Col flex="auto"><FormField name="ref_number" label="Contact number" type="text" size="medium" /></Col>
                      <Col flex="auto"><FormField name="ref_position" label="Position" type="text" size="medium" /></Col>
                      <Col flex="auto"><FormField name="ref_company" label="Company Name" type="text" size="medium" /></Col>

                    </Row>
                    <Divider orientation="left">Refrence # 2</Divider>
                    <Row>
                      <Col flex="auto"><FormField name="_ref_fullname" label=" Name" size="medium" type="text" /></Col>
                      <Col flex="auto"><FormField name="_ref_number" label="Contact number" type="text" size="medium" /></Col>
                      <Col flex="auto"><FormField name="_ref_position" label="Position" type="text" size="medium" /></Col>
                      <Col flex="auto"><FormField name="_ref_company" label="Company Name" type="text" size="medium" /></Col>
                    </Row>
                  </div>

                  <hr className='line' />
                  <div className='card'>
                    <FieldArray name="emergency_contact">
                      {({ fields }) => (
                        <div>
                          <Row>
                            <Col flex="auto"><span style={{ fontSize: "28px", color: "#001529" }} ><Icon color={"#001529"} icon="address-book" style={{ marginLeft: "10px", marginRight: "5px" }} />Emergency Contacts</span></Col>
                            <Col><Button onClick={() => fields.push({ firstName: '', lastName: '' })}>Add</Button></Col>
                          </Row>

                          {fields.map((name, index) => (
                            <Row key={index} style={{ borderBottom: "1px solid #EEE", marginBottom: "2px" }}>
                              <Col flex="50px" style={{ marginTop: "10px", fontSize: "34px", textAlign: "center", display: "flex", flexDirection: "row" }}>
                                <div>{index + 1}</div>
                                <IconButton style={{ marginTop: "15px", marginLeft: "5px" }} icon="trash-alt" onClick={() => fields.remove(index)} />
                              </Col>
                              <Col flex="auto">
                                <Row key={index} style={{ flexWrap: "nowrap" }}>
                                  <Col flex="auto"><FormField name={`${name}.fullname`} label="Emergency contact name" size="medium" type="text" validate={rules.required} /></Col>
                                  <Col flex="auto"><FormField name={`${name}.number`} label="Emergency contact number" type="text" size="medium" validate={rules.required} /></Col>
                                  <Col flex="auto"><FormField name={`${name}.relation`} label="Emergency contact relation" type="text" size="medium" validate={rules.required} /></Col>
                                </Row>

                              </Col>
                            </Row>

                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </div>

                  <Divider plain><Icon icon="file" style={{ marginLeft: "10px", marginRight: "5px" }} /><b>UPLOAD REQUIRED DOCUMENTS</b></Divider>
                  <hr className='line' />

                  <div className='card'>

                   
                    
                    <Row style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                      <Col>Upload the following ducuments <span style={{ color: 'red' }}> (Only .PDF, .JPG, .PNG file formats are allowed)</span>
                        <ul>
                          <li>SIN front</li>
                          <li>Security license front</li>
                          <li>First aid card</li>
                          <li>Government issued photo identification</li>
                          <li>Government issued document showing eligibility to work</li>
                          <li>Resume</li>
                        </ul>
                      </Col>
                      <Col>
                        <Row  style={{ flexWrap: "nowrap" }}>
                          <Col><FormField name="submit_physically" type="checkbox" /></Col>
                          <Col flex="auto" style={{ paddingTop: "10px" }}>I'll provide my documents physically</Col>
                        </Row>
                        <Upload
                          onRemove={(file) => {
                            this.setState(state => {
                              const index = state.fileList.indexOf(file);
                              const newFileList = state.fileList.slice();
                              newFileList.splice(index, 1);
                              return { fileList: newFileList };
                            });
                          }}
                          beforeUpload={(file) => {
                            this.setState(state => ({
                              fileList: [...state.fileList, file],
                            }));
                            return false;
                          }}
                          // fileList={this.state.fileList}
                          accept=".jpg,.png,.pdf"
                          multiple={true}
                         maxCount={6}
                         
                        >
                          <Button style={{ marginLeft: "10px" }} disabled={values.submit_physically || this.state.fileList.length >= 6 ? true : false }>Select File</Button>
                        </Upload>
                      </Col>

                    </Row>

                  </div>

                  <hr className='line' />
                  <div className='card'>
                    <span style={{ fontSize: "28px", color: "#001529" }} > <Icon color={"#001529"} icon="handshake" style={{ marginLeft: "10px", marginRight: "5px" }} />Sign Agreement</span>
                    <Row className='dark' style={{ flexWrap: "nowrap" }}>
                      <Col><FormField name="certify" type="checkbox" validate={rules.required} /></Col>
                      <Col flex="auto" style={{ paddingTop: "8px" }}>I certify that my answers are true and complete to the best of my knowledge. If this application leads to employment, I understand that false or misleading information in my application or interview may result in my release</Col>
                    </Row>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "20px auto" }}>
                      <div style={{ display: "flex", flexDirection: "column", position: "relative" }} >
                        <span className="cyan bold uppercase">Please sign the agreement:</span>
                        <span style={{ border: "1px solid #CCC", borderRadius: "5px" }}><SignaturePad
                          dotSize={1}
                          throttle={50}
                          velocityFilterWeight={0.1}
                          onEnd={this.trim}
                          backgroundColor="#FFF"
                          canvasProps={{ width: 500, height: 200 }}
                          required ref={(ref) => { this.sigPad = ref }} /></span>
                        <IconButton onClick={this.clear} icon="sync-alt" color="green" style={{ position: "absolute", top: '28px', right: "5px" }} size="2x" />
                      </div>
                    </div>

                  </div>

                  <div style={{ display: "flex", justifyContent: "center", marginBottom: "30px" }}>
                    <div className='dark' style={{ width: "80%", minWidth: "400px" }}>
                      <FormField name="job_info_source" size="medium" type="text" label="How did you hear about this job?" validate={rules.required} />
                    </div>
                  </div>


                  {!valid && <p align="center">Please check your form fields, seems like you forgot some information!</p>}

                  {/* {dirty && ShowError(errors)} */}

                  <Button type="primary" block htmlType='submit' size="large"  style={{ marginBottom: "50px" }} loading={busy}>Submit Application</Button>

                </form>

                <p align="center">A-List Security ©2021</p>

            </>)}}
          </Form>

        </div>
      </div>
    </div>)
      
  }
}
const WithApollo = compose(
  graphql(ADD_DATA, {
    props: ({ mutate }) => ({
      addGuardApplication: (args) => mutate({
        variables: { input: { ...args } }
      }),
    })
  }),
)(Applicant_form);


// const FormWrapper = props => {
//   if (props.match.params.section && props.match.params.section=='success'){
//     return (<div style={{ margin: "auto", borderRadius: "10px",display: "flex", flexDirection: "row" , alignItems: "center", justifyContent: "center",padding: "30px"}}>
    
//     <Result
//         status="success"
//         title="Application form is Successfully Submitted!"
//         // extra={<img src="https://sp-ao.shortpixel.ai/client/q_glossy,ret_img/https://alistsecurity.com/wp-content/uploads/2021/03/Website-Logo-Dark-Ver.-2.png"  />}
//       />,
//     </div>)
//   }
//   else return <WithApollo {...props} />
// }
// export default withApollo(FormWrapper)


export default (props) => (<Redirect to="/inputForm/guard_application_form" />);
