import React from 'react'
import { loader } from 'graphql.macro';
import { Alert, message, Row, Col, Button } from 'antd';
import _ from "lodash"
import { rules, FormField, FormComponent, FormFieldGroup } from 'Common/components/Form'
import { Loader, Icon, IconButton, Drawer } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { pair_value_types, publishStatus } from 'configs';
import { sleep, string_to_slug } from 'Common/scripts/Functions';
import { withApollo } from '@apollo/client/react/hoc';

const ADD_VALUE = loader('src/Graphql/value_pairs/addValuePairs.graphql');
const EDIT_VALUE = loader('src/Graphql/value_pairs/editValuePairs.graphql');

const defaultValues = { value_type: pair_value_types[0]._id, values: 0, status: publishStatus[0]._id };

const SimpleForm = ({ onSubmit, initialData, key_value }) => {
    const onFormSubmit = async (values) => {
        const filteredData = {
            title: values.title,
            key: key_value, //'prod_and_services'
            value_key: values.value_key,
            value_type: values.value_type,
            values: values.values,
            status: values.status,
        }
        if (values.value_type == 'string') Object.assign(filteredData, { values: String(values.values) })
        if (values.value_type == 'object' || values.value_type == 'array') Object.assign(filteredData, { values: JSON.stringify(values.values) })
        if (values._id) Object.assign(filteredData, { _id: values._id })

        return onSubmit(filteredData);
    }

    const onReportTypeSubmit = async (values) => {
        const filteredData = {
            title: values.title,
            key: key_value, //'prod_and_services'
            // value_key: values.value_key,
            value_type: "string",
            values: String(values.values),
            status: "published",
        }
        if (values._id) Object.assign(filteredData, { _id: values._id })

        return onSubmit(filteredData);
    }

    const onIncidentTypeSubmit = async (values) => {
        const filteredData = {
            title: values.title,
            key: key_value, //'prod_and_services'
            // value_key: values.value_key,
            value_type: "string",
            values: String(values.values),
            status: "published",
        }
        if (values._id) Object.assign(filteredData, { _id: values._id })

        return onSubmit(filteredData);
    }

    if (key_value == "report_types") return (<>
        <FormComponent onSubmit={onReportTypeSubmit} id='ValuePairsForm' fields={{ ...defaultValues, ...initialData }} debug={true}
            mutators={{
                ...arrayMutators,
                typeChanged: async (newValueArray, state, tools) => {
                    // let _value = newValueArray[0].value;
                    tools.changeValue(state, 'values', () => undefined);
                },

            }}
            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    <FormField label="Title" name="title" type="text" validate={rules.required} />
                    <FormField label="Value" name="values" type="text" validate={rules.required} />

                    <Row style={{ marginTop: '20px', marginRight: '10px' }}>
                        <Col flex="auto"></Col>
                        <Col flex="200px">
                            <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Submit</Button>
                        </Col>
                    </Row>
                </>)
            }}
        />
    </>)

    if (key_value == "incident_types") return (<>
        <FormComponent onSubmit={onIncidentTypeSubmit} id='ValuePairsForm' fields={{ ...defaultValues, ...initialData }} debug={true}
            mutators={{
                ...arrayMutators,
                typeChanged: async (newValueArray, state, tools) => {
                    // let _value = newValueArray[0].value;
                    tools.changeValue(state, 'values', () => undefined);
                },

            }}
            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    <FormField label="Title" name="title" type="text" validate={rules.required} />
                    <FormField label="Value" name="values" type="text" validate={rules.required} />

                    <Row style={{ marginTop: '20px', marginRight: '10px' }}>
                        <Col flex="auto"></Col>
                        <Col flex="200px">
                            <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Submit</Button>
                        </Col>
                    </Row>
                </>)
            }}
        />
    </>)

    return(<>
        <FormComponent onSubmit={onFormSubmit} id='ValuePairsForm' fields={{ ...defaultValues, ...initialData }} debug={true}
            mutators={{
                ...arrayMutators,
                typeChanged: async (newValueArray, state, tools) => {
                    // let _value = newValueArray[0].value;
                    tools.changeValue(state, 'values', () => undefined);
                },

            }}
            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    <FormField label="Title" name="title" type="text" validate={rules.required} />

                    <Row>
                        <Col flex="150px">
                            <FormField onChange={(txt, node) => {
                                node(txt);
                                sleep(50).then(r => form.mutators.typeChanged(txt))
                            }} type="select" name="value_type" label="Value Type" data={pair_value_types} validate={rules.required} />
                        </Col>
                        <Col flex="auto"><FormField type="text" name="value_key" label="Value Key (optional)" /></Col>
                    </Row>


                    {values.value_type == 'string' && <>
                        <FormField label="Value" name="values" type="text" validate={rules.required} />
                    </>}

                    {values.value_type == 'object' && <Row gutter={[12]}>
                        <Col flex="50%"><FormField label="_id" name="values._id" type="text" validate={rules.required} /></Col>
                        <Col flex="auto"><FormField label="title" name="values.title" type="text" validate={rules.required} /></Col>
                    </Row>}

                    {values.value_type == 'array' && <>
                        <FieldArray name="values">
                            {({ fields }) => (<div>
                                {fields.map((name, index) => (
                                    <Row key={index} style={{ borderBottom: "1px solid #EEE", marginBottom: "10px", flexWrap: "nowrap" }} align="bottom">
                                        <Col flex="50px" style={{ fontSize: "34px", textAlign: "center" }}>
                                            <div>{index + 1}</div>
                                        </Col>
                                        <Col flex="33%"><FormField name={`${name}._id`} label="_id" size="medium" type="text" /></Col>
                                        <Col flex="auto"><FormField name={`${name}.title`} label="title" type="text" size="medium" /></Col>
                                        <Col flex="50px"><IconButton icon="trash-alt" onClick={() => fields.remove(index)} style={{ marginBottom: "15px" }} /></Col>
                                    </Row>
                                ))}

                                <Row style={{ marginRight: '10px' }}>
                                    <Col flex="auto" align="right"><IconButton icon="plus" onClick={() => fields.push({ _id: '', title: '' })} /></Col>
                                </Row>

                            </div>)}
                        </FieldArray>

                    </>}

                    <FormField type="select" name="status" label="Status" data={publishStatus} validate={rules.required} />


                    <Row style={{ marginTop: '20px', marginRight: '10px' }}>
                        <Col flex="auto"></Col>
                        <Col flex="200px">
                            <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Submit</Button>
                        </Col>
                    </Row>
                </>)
            }}
        />
    </>)

}

const LeadServiceType = ({ onSubmit, initialData, key_value }) => {
    const onFormSubmit = async (values) => {
        const filteredData = {
            title: values.title,
            key: key_value,
            value_type: "array",
            // value_key: values.value_key,
            values: JSON.stringify(values.values),
            // status: values.status,
        }
        if (values._id) Object.assign(filteredData, { _id: values._id })

        return onSubmit(filteredData);
    }

    return(<>
        <FormComponent onSubmit={onFormSubmit} id='ValuePairsForm' fields={{ ...defaultValues, values: [{  }], ...initialData }} debug={true}
            mutators={{ ...arrayMutators }}
            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    <FormField label="Service Type" placeholder="Static Guard Without Vehicle" name="title" type="text" validate={rules.required} />

                    <FieldArray name="values">
                        {({ fields }) => (<div>
                            {fields.map((name, index) => {
                                {/* console.log("fields: ", fields?.value[index]?.tier) */}
                                return (
                                    <Row key={index} style={{ borderBottom: "1px solid #EEE", marginBottom: "10px", paddingBottom: "10px", flexWrap: "nowrap" }} align="middle">
                                        {/* <Col flex="50px" style={{ fontSize: "34px", textAlign: "center" }}><div>{fields?.value[index]?.tier}</div></Col> */}
                                        <Col flex="auto">
                                            <Row gutter={[15]} align="middle">
                                                <Col flex="100px"><FormField style={{fontSize:"34px", border:"0px solid black"}} label="Tier" placeholder="1" name={`${name}.tier`} min={1} type="number" validate={rules.required} compact /></Col>
                                                <Col flex="200px">
                                                    <FormFieldGroup label={<>Rate range <b>$</b></>} compact style={{ padding: 0, margin: 0, width: "auto" }}>
                                                        <FormField placeholder="25" style={{ width: "90px" }} name={`${name}.range[0]`} min={1} type="number" validate={rules.required} compact />
                                                        <FormField placeholder="27" style={{ width: "90px" }} name={`${name}.range[1]`} min={1} type="number" validate={rules.required} compact />
                                                    </FormFieldGroup>
                                                </Col>
                                                <Col>
                                                    <FormField label={<><small>Commission</small> %</>} placeholder="2.50" style={{ width: "90px" }} name={`${name}.commission`} min={0} type="number" validate={rules.required} compact />
                                                </Col>
                                                <Col flex="auto"><FormField label="Contract" placeholder="yes/no" name={`${name}.contract`} type="select"
                                                    data={[{ title: "With Contract", _id: "yes" }, { title: "Without Contract", _id: "no" }]}
                                                    validate={rules.required} compact /></Col>
                                            </Row>
                                        </Col>
                                        {index > 0 && <Col><IconButton icon="trash-alt" onClick={() => fields.remove(index)} style={{ marginTop: "20px", marginLeft: "10px" }} /></Col>}
                                    </Row>
                                )
                            })}

                            <Row>
                                <Col flex="auto" align="center"><IconButton size="large" icon="plus" onClick={() => fields.push({  })} /></Col>
                            </Row>

                        </div>)}
                    </FieldArray>


                    <Row style={{ marginTop: '20px', marginRight: '10px' }}>
                        <Col flex="auto"></Col>
                        <Col flex="200px">
                            <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Submit</Button>
                        </Col>
                    </Row>
                </>)
            }}
        />
    </>)

}

const ProdAndServicesForm = ({ onSubmit, initialData, key_value }) => {
    const onFormSubmit = async (values) => {
        const filteredData = {
            title: values.title,
            key: key_value, //'prod_and_services'
            value_key: values.value_key,
            value_type: values.value_type,
            values: values.values,
            status: values.status,
        }
        if (values.value_type == 'string') Object.assign(filteredData, { values: String(values.values) })
        if (values.value_type == 'object' || values.value_type == 'array') Object.assign(filteredData, { values: JSON.stringify(values.values) })
        if (values._id) Object.assign(filteredData, { _id: values._id })

        return onSubmit(filteredData);
    }

    return(<>
        <FormComponent onSubmit={onFormSubmit} id='ValuePairsForm' fields={{ ...defaultValues, ...initialData }} debug={true}
            mutators={{
                ...arrayMutators,
                typeChanged: async (newValueArray, state, tools) => {
                    // let _value = newValueArray[0].value;
                    tools.changeValue(state, 'values', () => undefined);
                },

            }}
            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    <Row>
                        <Col span={19}><FormField label="Title" name="title" type="text" validate={rules.required} /></Col>
                        <Col span={5}><FormField label={`Default Rate`} name="values" type="text" validate={rules.required} /></Col>
                        <Col span={19}></Col>
                        <Col span={5}><FormField type="select" name="status" label="Status" data={publishStatus} validate={rules.required} /></Col>
                    </Row>

                    <div style={{ padding:"30px" }}>
                        <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Submit</Button>
                    </div>
                </>)
            }}
        />
    </>)

}

const ShiftTypesForm = ({ onSubmit, initialData, key_value }) => {
    const onFormSubmit = async (values) => {
        const filteredData = {
            title: values.title,
            key: key_value, //'shift_types'
            value_key: values.value_key,
            value_type: values.value_type,
            values: values.values,
            status: values.status,
        }
        if (values.value_type == 'string') Object.assign(filteredData, { values: String(values.values) })
        if (values.value_type == 'object' || values.value_type == 'array') Object.assign(filteredData, { values: JSON.stringify(values.values) })
        if (values._id) Object.assign(filteredData, { _id: values._id })

        return onSubmit(filteredData);
    }

    return(<>
        <FormComponent onSubmit={onFormSubmit} id='ValuePairsForm' fields={{ ...defaultValues, ...initialData }} debug={true}
            mutators={{
                ...arrayMutators,
                typeChanged: async (newValueArray, state, tools) => {
                    // let _value = newValueArray[0].value;
                    tools.changeValue(state, 'values', () => undefined);
                },
                setValue: async (newValueArray, state, tools) => {
                    let val = string_to_slug(newValueArray[0])
                    tools.changeValue(state, 'values', () => val);
                },
            }}
            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    <Row>
                        <Col span={12}><FormField onChange={(e, callback) => {
                            form.mutators.setValue(e.target.value);
                            callback(e.target.value);
                        }} label="Title" name="title" type="text" validate={rules.required} /></Col>
                        <Col span={8}><FormField label={`${values.value_type} Value`} name="values" type="text" validate={rules.required} /></Col>
                        <Col span={19}></Col>
                        <Col span={5}><FormField type="select" name="status" label="Status" data={publishStatus} validate={rules.required} /></Col>
                    </Row>

                    <div style={{ padding:"30px" }}>
                        <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Submit</Button>
                    </div>
                </>)
            }}
        />
    </>)

}

const CompanyAssetsForm = ({ onSubmit, initialData, key_value }) => {
    const onFormSubmit = async (values) => {
        const filteredData = {
            title: values.title,
            key: key_value, //'shift_types'
            value_type: "string",
            values: String(values.values),
            status: values.status,
        }
        if (values._id) Object.assign(filteredData, { _id: values._id })

        return onSubmit(filteredData);
    }

    const filterSlug = (e, onChange) => onChange(string_to_slug(e.target.value));

    return(<>
        <FormComponent onSubmit={onFormSubmit} id='CompanyAssetsForm' fields={{ ...defaultValues, ...initialData }} debug={true}
            mutators={{
                ...arrayMutators,
                typeChanged: async (newValueArray, state, tools) => {
                    // let _value = newValueArray[0].value;
                    tools.changeValue(state, 'values', () => undefined);
                },
                setValue: async (newValueArray, state, tools) => {
                    let val = string_to_slug(newValueArray[0])
                    tools.changeValue(state, 'values', () => val);
                },
            }}
            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    <Row>
                        <Col span={16}><FormField onChange={(e, callback)=>{
                            form.mutators.setValue(e.target.value);
                            callback(e.target.value);
                        }} label="Title" name="title" type="text" validate={rules.required} /></Col>
                        <Col span={8}><FormField onChange={filterSlug} label={`Value`} name="values" type="text" validate={rules.required} /></Col>
                        <Col span={16}></Col>
                        <Col span={8}><FormField type="select" name="status" label="Status" data={publishStatus} validate={rules.required} /></Col>
                    </Row>

                    <div style={{ padding:"30px" }}>
                        <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Submit</Button>
                    </div>
                </>)
            }}
        />
    </>)

}

const EmployeeSkillsForm = ({ onSubmit, initialData, key_value }) => {
    const onFormSubmit = async (values) => {
        const filteredData = {
            title: values.title,
            key: key_value, 
            value_type: "string",
            values: String(values.values),
            status: values.status,
        }
        if (values._id) Object.assign(filteredData, { _id: values._id })

        return onSubmit(filteredData);
    }

    const filterSlug = (e, onChange) => onChange(string_to_slug(e.target.value));

    return(<>
        <FormComponent onSubmit={onFormSubmit} id='EmployeeSkillsForm' fields={{ ...defaultValues, ...initialData }} debug={true}
            mutators={{
                ...arrayMutators,
                typeChanged: async (newValueArray, state, tools) => {
                    // let _value = newValueArray[0].value;
                    tools.changeValue(state, 'values', () => undefined);
                },
                setValue: async (newValueArray, state, tools) => {
                    let val = string_to_slug(newValueArray[0])
                    tools.changeValue(state, 'values', () => val);
                },
            }}
            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    <Row>
                        <Col span={16}><FormField onChange={(e, callback)=>{
                            form.mutators.setValue(e.target.value);
                            callback(e.target.value);
                        }} label="Title" name="title" type="text" validate={rules.required} /></Col>
                        <Col span={8}><FormField onChange={filterSlug} label={`Value`} name="values" type="text" validate={rules.required} /></Col>
                        <Col span={16}></Col>
                        <Col span={8}><FormField type="select" name="status" label="Status" data={publishStatus} validate={rules.required} /></Col>
                    </Row>

                    <div style={{ padding:"30px" }}>
                        <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Submit</Button>
                    </div>
                </>)
            }}
        />
    </>)

}

const NoticeboardForm = ({ onSubmit, initialData, key_value }) => {
    const onFormSubmit = async (values) => {
        const filteredData = {
            title: values.title,
            key: key_value, 
            value_type: "string",
            values: String(values.values),
            status: values.status,
        }
        if (values._id) Object.assign(filteredData, { _id: values._id })

        return onSubmit(filteredData);
    }

    const filterSlug = (e, onChange) => onChange(string_to_slug(e.target.value));

    return(<>
        <FormComponent onSubmit={onFormSubmit} id='NoticeboardForm' fields={{ ...defaultValues, ...initialData }} debug={true}
            mutators={{
                ...arrayMutators,
                typeChanged: async (newValueArray, state, tools) => {
                    // let _value = newValueArray[0].value;
                    tools.changeValue(state, 'values', () => undefined);
                },
                setValue: async (newValueArray, state, tools) => {
                    let val = string_to_slug(newValueArray[0])
                    tools.changeValue(state, 'values', () => val);
                },
            }}
            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    <Row>
                        <Col span={12}><FormField onChange={(e, callback)=>{
                            form.mutators.setValue(e.target.value);
                            callback(e.target.value);
                        }} label="Title" name="title" type="text" validate={rules.required} /></Col>
                        <Col span={12}><FormField onChange={filterSlug} label={`Value`} name="values" type="text" validate={rules.required} /></Col>
                        <Col span={16}></Col>
                        <Col span={8}><FormField type="select" name="status" label="Status" data={publishStatus} validate={rules.required} /></Col>
                    </Row>

                    <div style={{ padding:"30px" }}>
                        <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Submit</Button>
                    </div>
                </>)
            }}
        />
    </>)

}



class ValuePairsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            busy: false,
            error: null,
            client: null,
            pagination: { current: 1, pageSize: 10, }

        };
        this.onSubmit = this.onSubmit.bind(this)
    }

    async onSubmit(filteredData) {

        // const filteredData = {
        //     title: values.title,
        //     key: this.props.key_value, //'prod_and_services'
        //     value_key: values.value_key,
        //     value_type: values.value_type,
        //     values: values.values,
        //     status: values.status,
        // }
        // if (values.value_type == 'string') Object.assign(filteredData, { values: String(values.values) })
        // if (values.value_type == 'object' || values.value_type == 'array') Object.assign(filteredData, { values: JSON.stringify(values.values) })
        // if (values._id) Object.assign(filteredData, { _id: values._id })

        // return console.log("filteredData: ", filteredData)
        this.setState({ busy: true, error:null });

        var result = false;

        if (filteredData._id) {
            // update records
            result = await this.props.client.mutate({ mutation: EDIT_VALUE, variables: { input: filteredData } }).then(r => (r.data.editValuePairs)).catch(err => {
                console.log(__error("ERROR: "), err);
                return { error:{message:"Query Error!"}}
            })
        } else {
            // add new record
            result = await this.props.client.mutate({ mutation: ADD_VALUE, variables: { input: filteredData } }).then(r => (r.data.addValuePairs))
            .catch(err => {
                console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
                return { error:{message:"Query error!"}}
            })
        }

        this.setState({ busy: false, error: result?.error?.message || null });
        if (result.error) return false;

        this.props.onClose();
        return true;

        // return result && !values._id ? 'reset' : false;

    }

    renderForm(){
        let initialData = _.cloneDeep(this.props.initialData || {})
        if (initialData && (initialData.value_type == 'array' || initialData.value_type == 'object'))
            Object.assign(initialData, { values: JSON.parse(initialData.values) })

        if (this.props.key_value == 'prod_and_services') return <ProdAndServicesForm onSubmit={this.onSubmit} initialData={initialData} key_value={this.props.key_value} />
        if (this.props.key_value == 'lead_service_type') return <LeadServiceType onSubmit={this.onSubmit} initialData={initialData} key_value={this.props.key_value} />
        if (this.props.key_value == 'shift_types') return <ShiftTypesForm onSubmit={this.onSubmit} initialData={initialData} key_value={this.props.key_value} />
        if (this.props.key_value == 'company_assets') return <CompanyAssetsForm onSubmit={this.onSubmit} initialData={initialData} key_value={this.props.key_value} />
        if (this.props.key_value == 'employee_skills') return <EmployeeSkillsForm onSubmit={this.onSubmit} initialData={initialData} key_value={this.props.key_value} />
        if (this.props.key_value == 'noticeboard_categories') return <NoticeboardForm onSubmit={this.onSubmit} initialData={initialData} key_value={this.props.key_value} />

        return <SimpleForm onSubmit={this.onSubmit} initialData={initialData} key_value={this.props.key_value} />
    }


    render() {
        const { showDrawer, onClose, initialData } = this.props;
        const { busy, error } = this.state;

        // let _initialData = _.cloneDeep(initialData)
        // if (_initialData && (_initialData.value_type == 'array' || _initialData.value_type == 'object'))
        //     Object.assign(_initialData, { values: JSON.parse(_initialData.values) })


        if (!this.props.key_value) return <Alert message="Error" description={"Invalid value key"} type="error" showIcon />

        return (<>
            <Drawer
                // loading={busy}
                title={initialData && initialData._id ? "Update Value" : "Add New Value"}
                width={700}
                onClose={onClose}
                visible={showDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                extra={<Button onClick={onClose}>Cancel</Button>}
                // footer={<Row><Col flex="auto"></Col><Col>
                //     <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} >Submit </Button>
                // </Col></Row>}
                destroyOnClose={true}
            >
                {showDrawer && <>
                    {error && <Alert message="Error" description={error} type="error" showIcon />}
                    {this.renderForm()}
                </>
                }

            </Drawer>

        </>)


    }

}

export default withApollo(ValuePairsForm);
