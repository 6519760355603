import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pagination as _Pagination } from 'antd';
// import { useHistory, useParams, useLocation } from "react-router-dom";
import { Loader } from './';
import { Pagination } from 'Common/components'


/**
 * DataGrid
 */
export class DataGrid extends Component { // eslint-disable-line react/prefer-stateless-function
  render() {

    const { loading, dataSource, pagination, onChange, onShowSizeChange } = this.props;
    // if (loading) return <><Loader loading={loading} /></>

    if (!dataSource || dataSource.length < 1) return <Loader loading={loading}>No records found.</Loader>

    return (<Loader loading={loading}>
      <div className='dataGrid'>
        {dataSource.map((item, i)=>{
          return <this.props.gridItem {...item} key={i} />
          // return <UserCard key={i} {...item} />
        })}
      </div>

      <div style={{ textAlign: "center", padding: "20px"}}>
        <Pagination {...pagination}
          updateHistory={this.props.updateHistory}
          onChange={onChange}
          pageSizeOptions={['10', '15', '20', '25', '30']}
          defaultPageSize={10}
          showSizeChanger={true}
          onShowSizeChange={onShowSizeChange}
        />
      </div>

    </Loader>)

  }
}

DataGrid.propTypes = {
  loading: PropTypes.bool,
  updateBrowserWith: PropTypes.string, // {ROOT+"/page/${pageNum}"}
  // queryName: PropTypes.string.isRequired,
  // onChange: PropTypes.func.isRequired,
  dataSource: PropTypes.array,
  gridItem: PropTypes.oneOfType([
              PropTypes.func,
              PropTypes.object,
            ]).isRequired,
  // prop: PropTypes.type.isRequired
}


export default DataGrid;
