import React, { useState } from 'react'
import { Button, DataRow, IconButton } from 'Common/components'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Alert, Col, Modal, Row, Tag, message } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper';
import { mongoToDate } from 'Common/scripts/Functions';

const ADD_RECORD = loader('src/Graphql/dors/addDor.graphql');
const EDIT_RECORD = loader('src/Graphql/dors/editDor.graphql');


export const DorForm = withApollo(({ initialValues, onUpdate, onClose, client, open }) => {
    const [error, seteError] = useState(false);

    const onSubmit = async (values) => {
        var input = {
            record_date: mongoToDate(values.record_date),
            note: {
                subject: values.subject,
                note: values.note,
            },
            // locked: values.locked ? true : false,
        };

        var resutls = await client.mutate({ mutation: ADD_RECORD, variables: { input } }).then(r => (r.data.addDor))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request at the moment!" } }
            })


        // if (initialValues && initialValues._id){
        //     input = {
        //         _id: initialValues._id,
        //         record_date: mongoToDate(values.record_date),
        //         locked: values.locked ? true : false,
        //     }
        //     resutls = await client.mutate({ mutation: EDIT_RECORD, variables: { input } }).then(r => (r.data.editDor))
        //         .catch(err => {
        //             console.log(__error("ERROR: "), err);
        //             return { error: { message: "Failed to process the request at the moment!" } }
        //         })
        // }
        // else{
        //     input = {
        //         note: values.note,
        //         subject: values.subject,
        //         record_date: mongoToDate(values.record_date),
        //     }
        //     resutls = await client.mutate({ mutation: ADD_RECORD, variables: { input } }).then(r => (r.data.addDor))
        //         .catch(err => {
        //             console.log(__error("ERROR: "), err);
        //             return { error: { message: "Failed to process the request at the moment!" } }
        //         })
        // }


        if (resutls.error) {
            message.error(resutls.error.message);
            return false;
        }

        onUpdate(resutls);
        return false;
    }

    return (<Modal visible={open} title="DOR" destroyOnClose onCancel={onClose} footer={false}>
        {open && <>
            <FormComponent onSubmit={onSubmit} id='DorsForm' fields={{ record_date:mongoToDate(), ...initialValues}} debug={false}
                mutators={{
                    updateDorDate: (newValueArray, state, tools) => {
                        let record_date = newValueArray[0];
                        tools.changeValue(state, 'record_date', () => record_date);
                    },
                }}

                form_render={formProps => {
                    const { values, form, submitting, invalid, dirty, valid } = formProps;

                    return (<>
                        {error && <Alert message="Error!" description={error} type="error" showIcon />}

                        {(initialValues && initialValues._id) && <>
                            <Row>
                                <Col flex="200px"><FormField label="DOR Date" name="record_date" type="date" validate={rules.required} /></Col>
                                <Col flex="auto"><FormField label="Mark as completed" name="locked" type="switch" /></Col>
                            </Row>
                        </>}

                        {!(initialValues && initialValues._id) && <>
                            <Row align="middle" gutter={[10, 0]}>
                                <Col><FormField returnAs="moment" label="DOR Date" compact name="record_date" type="date" validate={rules.required} /></Col>
                                <Col><div style={{ paddingTop: "20px" }}>
                                    <Button onClick={() => form.mutators.updateDorDate(mongoToDate())} size="small" shape="round">Today</Button>
                                </div></Col>
                                <Col span={24}><FormField name="subject" compact label="Subject" type="text" validate={rules.required} /></Col>
                                <Col span={24}><FormField label="Note" name="note" type="textarea" compact validate={rules.required} /></Col>
                            </Row>
                        </>}

                        <Row style={{ marginTop: '20px', marginRight: '10px' }}>
                            <Col flex="auto"></Col>
                            <Col>
                                <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!valid}>Save</Button>
                            </Col>
                        </Row>

                    </>)
                }}
            />
        </>}

    </Modal>)

});
