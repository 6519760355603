import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client';
import { gql, loader } from 'graphql.macro';
import { Alert, Card, Space } from 'antd';
import { Loader } from 'Common/components';
import { ContentArea } from 'Layout_v1';
import { Link } from 'react-router-dom';
import { ROOT_PATH } from '../../constants';

// const GET_TODOS = loader('src/Graphql/Todos/todos.graphql');
const GET_TODOS = gql`
    query todos($filter:String, $others:String){
        todos(filter:$filter, others:$others){
          _id
          title
        }
    }   
    `


function TodoHistory() { // match.params.id
  const [dataList, set_dataList] = useState(null)

  const [loadTotos, { called, loading, data }] = useLazyQuery(GET_TODOS,
    { variables: { filter: JSON.stringify({ status: "completed" }) } }
  );

  useEffect(() => {
    if (called || loading || data) return;

    loadTotos().then(r => {
      set_dataList(r.data.todos)
    });
    // return () => {};
  }, [])

  if (loading || !data) return <Loader loading={true} />

  if (!dataList || dataList.length<1) return <Alert message="No records found!" showIcon type='error' />

  return (<>
    <Space style={{ width:"100%", marginTop:"10px" }} direction='vertical'>
      {dataList?.map((item, i)=>{
        return <Link to={`${ROOT_PATH}/todo/${item._id}`} key={i}>
          <ContentArea className={`todo-message`} style={{ marginBottom: "5px" }}>{item.title}</ContentArea>
        </Link>
      })}
    </Space>
  </>)

}
export default TodoHistory;

