import React from 'react'
import { loader } from 'graphql.macro';
import { Drawer, Space, Alert, message, Row, Col, Button } from 'antd';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Loader, ClientSearch, Heading, SiteSearch } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
// import { modulePermissions } from '.'
import { withApollo } from '@apollo/client/react/hoc';
// import { verifyRole } from 'Common/scripts/Security';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { ActiveInactiveStatus } from 'configs';

const ADD_RECORD = loader('src/Graphql/trackable_assets/addTrackableAssets.graphql');
const EDIT_RECORD = loader('src/Graphql/trackable_assets/editTrackableAssets.graphql');


class AssetTrackingFormDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, };
        this.onSubmit = this.onSubmit.bind(this)
    }

    async onSubmit(values) {
        this.setState({ error: false })

        var result;

        if (this?.props?.initialValues?._id) result = await this.editRecord(values)
        else result = await this.addRecord(values)
        

        if (!result || result.error) {
            message.error((result && result?.error?.message) || "Invalid response")
            this.setState({ error: (result && result?.error?.message) || "Invalid response" });
            return false;
        }

        message.success("Record Saved");
        if (this.props.onSuccess) return this.props.onSuccess(result)
        this.props.onClose();
    }

    async addRecord(values){
        const input = {
            title: values.title,
            site: { _id: values.site._id, name: values.site.name },
            status: values.status,
            // author: UserReff_Input!
            notes: values.notes,
            // attachments: [AttachmentSchema_Input]
        }

        return this.props.client.mutate({ mutation: ADD_RECORD, variables: { input } }).then(r => (r?.data?.addTrackableAssets))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })

    }
    
    async editRecord(values){
        const input = {
            _id: Number(this.props.initialValues._id),
            title: values.title,
            site: { _id: values.site._id, name: values.site.name },
            status: values.status,
            // author: UserReff_Input!
            notes: values.notes,
            // attachments: [AttachmentSchema_Input]
        }

        return this.props.client.mutate({ mutation: EDIT_RECORD, variables: { input } }).then(r => (r?.data?.editTrackableAssets))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })

    }

    render() {
        const { showDrawer, onClose, initialValues } = this.props;
        const { error } = this.state;


        return (<>
            <Drawer title="Add New Asset"
                width={"600px"}
                placement='right'
                contentWrapperStyle={{ maxWidth:"1200px" }}
                onClose={onClose}
                visible={showDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                extra={<Button onClick={onClose}>Cancel</Button>}
                destroyOnClose={true}
            ><>
                {(initialValues && showDrawer) && <>
                    <FormComponent onSubmit={this.onSubmit} id='ClientForm' fields={initialValues} debug={true}
                            mutators={{
                                ...arrayMutators,
                                // selectFiles: (newValueArray, state, tools) => {
                                //     let file = newValueArray[0];
                                //     let action = newValueArray[1];
                                //     let fileList = state.formState.values.fileList || []
                                //     if (fileList.length >= FILE_LIMIT) return;

                                //     if (action == 'add') fileList.push(file);
                                //     if (action == 'remove') fileList = fileList.filter(o => o.uid != file.uid);

                                //     tools.changeValue(state, 'fileList', () => fileList);
                                // },
                                // removeFiles: (newValueArray, state, tools) => {
                                //     tools.changeValue(state, 'fileList', () => []);
                                // },
                                selectSite: (newValueArray, state, tools) => {
                                    let node = newValueArray[0];
                                    let val = { _id: node.value, name: node.children };
                                    tools.changeValue(state, 'site', () => val);
                                },
                                de_selectSite: (newValueArray, state, tools) => {
                                    tools.changeValue(state, 'site', () => undefined)
                                },
                            }}

                        form_render={formProps => {
                            const { values, form, submitting, invalid, dirty, valid } = formProps;

                            return (<>
                                {error && <Alert message="Error!" description={error} type="error" showIcon />}

                                <Row gutter={[10, 0]}>
                                    <Col span={12}><SiteSearch label="Site" name='site._id' compact allowClear
                                        // disabled={!values?.client?._id} 
                                        // filter={values?.client?._id ? { client_id: Number(values.client._id) } : {}}
                                        // preload={values?.client?._id ? true : false}
                                        onSelect={(txt, node) => form.mutators.selectSite(node)}
                                        onDeselect={(txt, node) => form.mutators.de_selectSite(node)}
                                        validate={rules.required}
                                    />
                                    </Col>
                                    <Col span={12}>
                                        <FormField type="select" name="status" label="Status" data={ActiveInactiveStatus} compact validate={rules.required} />
                                    </Col>
                                    <Col span={24}><FormField label="Asset Name" name="title" type="text" validate={rules.required} compact /></Col>
                                    <Col span={24}>
                                        <FormField type="textarea" name="notes" label="Notes" compact />
                                    </Col>
                                </Row>



                                <Row style={{ marginTop: '20px', marginRight: '10px' }}>
                                    <Col flex="auto"></Col>
                                    <Col flex="200px">
                                        <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!valid}>Add Asset</Button>
                                    </Col>
                                </Row>

                            </>)
                        }}
                    />
                </>}
            </></Drawer>

        </>)

    }

}

export default withApollo(AssetTrackingFormDrawer);