import React, { useState } from 'react'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { Col, Row, Space, Modal, Alert, message, Select } from 'antd';
import { Button } from 'Common/components';
import { guard_status } from 'configs';
import { dateToMongo } from 'Common/scripts/Functions';

const UPDATE_GUARD = loader('src/Graphql/guard/updateGuardStatus.graphql');

const StatusUpdateForm = ({ client, data, showForm, onCloseClick, onUpdate }) => {
    const [new_status, set_new_status] = useState(false)
    
    const onSubmit = async (values) => {
        const input = {
            _id: data._id,
            status: new_status.value,
            status_notes: values.status_notes,
            status_update_date: dateToMongo(values.status_update_date),
        }
        if (values.last_date_of_work) Object.assign(input, { last_date_of_work: dateToMongo(values.last_date_of_work) })

        const results = await client.mutate({ mutation: UPDATE_GUARD, variables: { input } }).then(e => {
            return e.data.updateGuardStatus;
        }).catch(err => {
            console.log(__error("Request Error: "), err);
            return { error: { message: "Request ERROR" } }
        })

        if (results.error){
            message.error(results.error.message);
            return false;
        }

        onUpdate(results);
        onClose();
        // set_new_status(false);
        // onCloseClick()
        return results;

    }

    const onClose = () => {
        set_new_status(false);
        onCloseClick()
    }

    let modalTitle = "Select Action";
    let info = <></>;

    if (new_status){
        modalTitle = `Chnage account status to ${new_status.label}`;
        if (new_status.value == 'active') info = <p style={{ fontWeight: "bold" }}>Please provide the activation reason below</p>;
        if (new_status.value == 'in-active') info = <p style={{ fontWeight: "bold" }}>Please provide the re-activation reason below</p>;
        if (new_status.value == 'resigned') info = <p style={{ fontWeight: "bold" }}>Please provide the resignation details below</p>;
        if (new_status.value == 'terminated') info = <p style={{ fontWeight: "bold" }}>Please provide the termination reason below</p>;
        if (new_status.value == 'suspended') info = <p style={{ fontWeight: "bold" }}>Please provide the suspension details below</p>;
        if (new_status.value == 'on-leave') info = <p style={{ fontWeight: "bold" }}>Please provide the leave details below</p>;
    }

    return (<>
        <Modal title={modalTitle} visible={showForm} footer={false} onCancel={onClose}>
            {!new_status && <>
                <Space direction="vertical" style={{width:"100%"}}>
                    <Select
                        placeholder="Please select your action from the list"
                        // defaultValue="lucy"
                        style={{ width: '100%' }}
                        onChange={(v, vv) => set_new_status(vv)}
                        options={guard_status.map(o => ({ value: o._id, label: o.title, disabled: data.status == o._id }))}
                    />
                    {/* {guard_status.map((item, i)=>{
                        return <Button key={i}>{item.title}</Button>
                    })} */}
                </Space>
            </>}
            
            {new_status && <>
                <FormComponent onSubmit={onSubmit} id='GuardStatusUpdate' fields={{}} debug={false}
                    form_render={formProps => {
                        const { handleSubmit, submitting, values, form, invalid, dirty, valid } = formProps;

                        return (<>
                            {info}

                            {(new_status.value == 'terminated' && data.company_assets) && <div style={{ color:"red", marginBottom:"10px" }}>
                                <div style={{fontWeight:"bold"}}>Please be infomred that this employee holds certain assets for the compnay as following:</div>
                                <div style={{ textTransform: "capitalize" }}>{data.company_assets.toString().replace(",", ", ")}</div>
                            </div>}

                            {new_status.value == 'terminated' && <>
                                <FormField size="medium" name="last_date_of_work" validate={rules.required} type="date" label={`Last date of work`} compact />
                            </>}                            

                            {/* <FormField size="medium" name="status" validate={rules.required} type="select" label="Status" data={guard_status} /> */}
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <FormField size="medium" name="status_notes" validate={rules.required} type="textarea" label="Status Notes" placeholder="Please type in the status update notes" compact />
                                <FormField size="medium" name="status_update_date" validate={rules.required} type="date" label={`${new_status.label} Date`} compact />
                            </Space>

                            <div style={{ height: "20px" }} />
                            <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} loading={submitting} >Proceed</Button>

                        </>)
                    }}
                />
            </>}

        </Modal>

    </>)
}
export default withApollo(StatusUpdateForm);