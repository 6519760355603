import React, { Component } from 'react'
import { H1 } from './headings';
import { Form, Field } from 'react-final-form'
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { Icon } from 'Common/components'
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd'
import { styles } from './styles'
import { parseNewLine } from 'Common/scripts/Functions';

const CHEAT_ON = false;
const fieldKey = "::INPUT_FIELD::";


export const RenderField = ({ field, num, attemptHistory, hide_result_status }) => {
    // console.log("RenderField() ", field)
    // console.log("attemptHistory: ", attemptHistory)

    if (field.type == 'heading') {
        return (<div style={{ display: "flex", justifyContent: "center" }}>
            <h4>{field.title}</h4>
        </div>)
    }
    else if (field.type == 'text') return (<p>{parseNewLine(field.title)}</p>)

    var disabled = false;
    var headingStyle = {}
    if (attemptHistory && hide_result_status !== 'yes' && hide_result_status!=='yes'){
        disabled = (attemptHistory.isCorrect == 'yes') ? true : false;
        headingStyle = { color: disabled ? "green" : "red" }
    }

    let name = `field_${field._id}`; // `q_${num-1}`; // `field_${field._id}`;

    if (field.type == 'radio') {
        // let name = `field_${field._id}`; // `q_${num-1}`; // `field_${field._id}`;

        return (<>
            <H1 title={`${num}) ${field.question}`} style={{ ...headingStyle }} />

            {field.options.map((o, i) => {
                let _id = `opt_${o._id}`;

                return (<div className="form-field field-margins radio" key={i}>
                    <label htmlFor={_id} style={{ fontSize: "16px" }}>
                        <Field name={name} id={_id} disabled={disabled} component="input" type="radio" value={o.text} validate={rules.required} />{' '} {o.text}
                    </label>
                </div>)
            })}

        </>)
    }

    else if (field.type == 'fill-in-the-blanks') {
        // let name = `field_${field._id}`; // `q_${num-1}`; // `field_${field._id}`;

        let heading = ""
        let havePlaceholder = false;

        if (field.question.indexOf(fieldKey)>-1){
            havePlaceholder = true;

            let parts = field.question.split(fieldKey);
            heading = <H1 title={<Row>
                <span>{num}) {parts[0]} </span>
                <FormField className="inline_select" disabled={disabled} data={field.options.map(o => ({ _id: o.text, title: o.text }))} type="select" name={name} compact validate={rules.required} />
                <span> {parts[1]}</span>
            </Row>} style={{ ...headingStyle }} />

        }else{
            heading = <H1 title={`${num}) ${field.question}`} style={{ ...headingStyle }} />
        }

        return (<>
            {heading}

            {!havePlaceholder && field.options.map((o, i) => {
                let _id = `opt_${o._id}`;

                return (<div className="form-field field-margins radio" key={i}>
                    <label htmlFor={_id} style={{ fontSize: "16px" }}>
                        <Field name={name} id={_id} disabled={disabled} component="input" type="radio" value={o.text} validate={rules.required} />{' '} {o.text}
                    </label>
                </div>)
            })}

        </>)
    }

    else if (field.type=='text-input'){
        return <div>
            <H1 title={<span>{num}) {field.title}</span>} />
            <FormField type="text" name={name} compact validate={rules.required} />
        </div>
    }

    else if (field.type =='long-text-input'){
        return <div>
            <H1 title={<span>{num}) {field.title}</span>} />
            <FormField type="textarea" rows={2} name={name} compact validate={rules.required} />
        </div>
    }

    else if (field.type =='link'){
        return <p style={{margin:"10px 0"}}>
            <a href={field.title} target="_blank">{field.title} <Icon icon="external-link-alt" /></a>
        </p>
    }

    else {
        return <div>Invalid Field ({field.type})</div>
    }
}
