import React from 'react'
import { Col, Row } from 'antd'

export const ListHeader = ({ pre, totalCount, title, sub, style, right }) => {
    return (<Row style={{ lineHeight: '22px', color: "#001528", fontSize: "28px", ...style }} gutter={[5]} align="middle">
        {pre && <Col>{pre}</Col>}
        <Col flex="auto">
            <div style={{ }}>{title}</div>
            {(totalCount || totalCount===0) && <div style={{ fontSize: "12px" }}>{(totalCount || '0')} record(s) found</div>}
            {sub && <div style={{ fontSize: "12px" }}>{sub}</div>}
        </Col>
        {right && <Col>{right}</Col>}
    </Row>)
}
