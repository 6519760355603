import React, { useEffect, useState } from 'react'
import { ContentArea } from 'Layout_v1'
import { Breadcrumb, Col, Row, Space, Select, Alert, PageHeader, Popover, Modal } from 'antd'
import { PlusOutlined, HomeOutlined } from '@ant-design/icons';
import { defaultDateFormat, defaultDateTimeFormat, defaultPageSize, uriRoot } from 'configs';
import { DevBlock, Icon, Table, Drawer, Button, ValuePairsSearch } from 'Common/components';
import { loader } from 'graphql.macro';
import { gql, useLazyQuery } from '@apollo/client';
import { __error } from 'Common/scripts/consoleHelper';
import { mongoToDate } from 'Common/scripts/Functions';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { ListHeader } from 'Common/components/Typography';
// import ReportTypes from 'Modules/Settings/value_pairs/report_types';
import ObservationReport_Preview from 'Modules/FormsData/Forms/ObservationReport/preview';
import ObservaionReportForm from 'Modules/FormsData/Forms/ObservationReport'
import FirewatchReportForm_Preview from 'Modules/FormsData/Forms/FirewatchReport/preview';
import FirewatchReportForm from 'Modules/FormsData/Forms/FirewatchReport'
import IncidentReportForm_Preview from 'Modules/FormsData/Forms/IncidentReport/preview';
import IncidentReportForm from 'Modules/FormsData/Forms/IncidentReport'
import GeneralReportForm from 'Modules/FormsData/Forms/GeneralReport'
import GeneralReportForm_Preview from 'Modules/FormsData/Forms/GeneralReport/preview';
import { Link } from 'react-router-dom';
// import { connect } from "react-redux";
// import { updatePageSettings } from 'Store/ep_admin.actions'

const FORM_LIST_DATA = loader('src/Graphql/FormsData/formsDataQuery.graphql');

const SITE_DETAILS = gql`query site($id: ID!){
    site(id: $id){
        _id
        name
        address
        site_type
        # client_id: ID
        # client: Client # read only
        # note: String
    }
}`

const GUARD_DETAILS = gql`query guard($id: ID!){
    guard(id: $id){
        _id
        guard_avatar
        name
        f_name
        m_name
        l_name
    }
}`

const SearchBar = ({ onSubmit, filter }) => {
    return (<>
        <FormComponent onSubmit={onSubmit} id='reportsSearchForm' debug={false} 
            fields={filter}
            form_render={formProps => {
                const { submitting, values, form, invalid, dirty, valid } = formProps;

                return (<div>
                    <Row style={{ flexWrap: "nowrap" }}>
                        <Col flex="150px">
                            <FormField name="filter.status" type="select" size="medium" placeholder="- Status - " allowClear compact data={[
                                // { _id: "", title: "Un-Archived" }, 
                                { _id: "archived", title: "Archived" },
                            ]} />
                        </Col>
                        <Col flex="200px"><FormField name="filter.search.keywords" placeholder={"Keyword search.."} size="medium" type="text" allowClear compact /></Col>
                        <Col style={{ width: "90px" }}><Button type="primary" loading={submitting} htmlType='submit'><Icon icon="search" /></Button></Col>
                        {/* <Col>aaaaaaa</Col> */}
                    </Row>
                </div>)
            }}
        />
    </>)
}

const AddReportForm = ({ visible, onClose, onSuccess, params }) => {
    const [report_types, set_report_types] = useState(null)

    useEffect(() => {
        if (!visible && report_types) set_report_types(null)
        return () => {
            set_report_types(null)
        };
    }, [])

    const onReportTypeSubmit = async (values) => {
        set_report_types(values.report_type);
        return false;
    };
    
    const TypeSelectorForm = () => (<FormComponent onSubmit={onReportTypeSubmit} id='SiteReportTypeSelection' debug={false}
        mutators={{
            onReportTypeChange: (newValueArray, state, tools) => {
                let val = newValueArray[0];
                tools.changeValue(state, 'report_type', () => val)
            },
        }}
        form_render={formProps => {
            const { values, form, invalid, dirty, valid } = formProps;

            return (<>
                <ValuePairsSearch
                    onChange={(__1, __2) => form.mutators.onReportTypeChange(__2.raw)}
                    preload={true} key_value="report_types" label="Select Report Type" name="report_type._id" validate={rules.required} />

                {values?.report_type?._id && <div style={{ padding: "20px" }}>
                    <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} >Start Creating Report</Button>
                </div>}

            </>)
        }}
    />)

    const onCloseClick = () => {
        set_report_types(null);
        onClose();
    }

    let initialValues = {
        guard: { _id: params && params.guard_id },
        site: { _id: params && params.site_id },
    }

    return (<>
        <Drawer visible={visible} placement="right" 
            width={(report_types && report_types.values) ? "100%" : "400px"} 
            closable={true} onClose={onCloseClick} destroyOnClose maskClosable={false} footer={false}
            bodyStyle={{ padding: "0px" }}
            title={"Create Report"}
        >
            {report_types && report_types.values == 'observation_report' && <ObservaionReportForm onSuccess={onSuccess} initialValues={initialValues} onClose={onCloseClick} />}
            {report_types && report_types.values == 'fire_watch' && <FirewatchReportForm onSuccess={onSuccess} initialValues={initialValues} onClose={onCloseClick} />}
            {report_types && report_types.values == 'incident_report' && <IncidentReportForm onSuccess={onSuccess} initialValues={initialValues} onClose={onCloseClick} />}
            {report_types && report_types.values == 'general_report' && <GeneralReportForm onSuccess={onSuccess} initialValues={initialValues} onClose={onCloseClick} />}
            {!report_types && <TypeSelectorForm />}
        </Drawer>
    </>)
}

const ReportPreview = ({ initialData }) => {
    if (!initialData) return <Alert message="Missing data!" showIcon type='error' />

    if (initialData.ref_key == "observation_report") return <ObservationReport_Preview data={initialData} />;
    if (initialData.ref_key == "fire_watch") return <FirewatchReportForm_Preview data={initialData} />;
    if (initialData.ref_key == "incident_report") return <IncidentReportForm_Preview data={initialData} />;
    if (initialData.ref_key == "general_report") return <GeneralReportForm_Preview data={initialData} />;

    return (<>
        <h1>ReportPreview</h1>
        <DevBlock obj={initialData} />
    </>)
}

export default function AllReports({ match, inline }) {
    const [pagination, set_pagination] = useState({ first:defaultPageSize, after:0 })
    const [error, setError] = useState(null)
    const [reportsList, set_reportsList] = useState(null);
    const [busy, set_busy] = useState(true);
    const [showDetails, set_showDetails] = useState(false);
    const [showReportForm, set_showReportForm] = useState(false);

    const [getSite, site_results] = useLazyQuery(
        SITE_DETAILS, { variables: { id: match?.params?.site_id } }
    );
    const [getGuard, guard_results] = useLazyQuery(
        GUARD_DETAILS, { variables: { id: match?.params?.guard_id } }
    );
    const [getReports, reports_results] = useLazyQuery(
        FORM_LIST_DATA, { variables: { 
            first:defaultPageSize,
            after: 0,
            filter: JSON.stringify({}),
            others: JSON.stringify({}),
        } }
    );

    useEffect(() => {
        if (site_results.called || site_results.loading || site_results.data) return;
        if (match?.params?.site_id) getSite();
        if (match?.params?.guard_id) getGuard();
    }, [])

    useEffect(() => {
        if (match?.params?.site_id && !site_results.data) return;
        if (match?.params?.guard_id && !guard_results.data) return;
        if (reportsList || reports_results.called) return;

        fetchReports({});
    }, [site_results, guard_results])

    const fetchReports = async (args) => {
        const { filter } = args;

        set_busy(true);

        let _filter = { ...filter }
        if (site_results?.data?.site?._id) Object.assign(_filter, { "site._id": site_results.data.site._id })
        if (guard_results?.data?.guard?._id) Object.assign(_filter, { "guard._id": guard_results.data.guard._id })

        let resutls = await getReports({
            variables: {
                first: defaultPageSize,
                after: 0,
                filter: JSON.stringify(_filter),
                others: JSON.stringify({ sort:{ _id: -1 }}),
            }
        }).then(r => (r?.data?.formsDataQuery))
            .catch(err=> {
                console.log(__error("ERROR: "), err);
                return { error:{message:"Unable to fetch data!"}}
            })
        set_busy(false)

        if (resutls && resutls.error){
            setError(resutls.error.message);
            return false;
        }

        set_reportsList(resutls);
    }

    const handleTableChange = (vals) => {
        console.log("handleTableChange()", vals)
    }

    const onReportAdd = () => {
        set_showReportForm(false)
        fetchReports({})
    }

    const columns = [
        // { title: 'ID', dataIndex: '_id', width: "100px" },
        {
            title: 'Title', dataIndex: 'title', render: (___, rec) => {
                return (<>
                    <div onClick={() => set_showDetails(rec)} className='a' style={{ fontSize:"16px" }}>{rec.title}</div>
                    <div><b>ID:</b> {rec._id}</div>
                    {rec?.site?.name && <div><b>Site: </b><Link to={`${uriRoot}/sites/${rec?.site?._id}`}>{rec?.site?.name}</Link></div>}
                    {rec?.guard?.name && <div><b>Guard: </b><Link to={`${uriRoot}/guard/${rec?.guard?._id}`}>{rec?.guard?.name}</Link></div>}
                    <span className='hidden-menu'>
                        {/* {rec.ref_key == "observation_report" && <>
                            {verifyRole("archive-report", this.props.ep_admin.user) && <IconButton className="item" onClick={() => this.archiveThis(rec)}
                                color={rec.isArchived == "yes" ? "green" : "red"}
                                icon={{ icon: 'archive' }} />}
                            {verifyRole("delete-report", this.props.ep_admin.user) && <DeleteButton className="item" onClick={() => this.delete(rec?._id)} />}
                        </>} */}
                    </span>
                </>)
            }
        },
        { title: <>Type</>, dataIndex: 'ref_key', render: (__, rec) => (rec?.ref_key.replace("_", " ")) },
        { title: <>Author</>, dataIndex: 'author', render: (__, rec) => (rec?.author?.name) },
        { title: <>Created at <div style={{ fontSize: "10px" }}>{defaultDateTimeFormat}</div></>, dataIndex: 'created_at', width: '150px', render: (___, rec) => mongoToDate(rec.created_at).format(defaultDateTimeFormat) },
    ]

    return (<>
        {/* {!inline &&
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                {site_results?.data?.site?._id && <>
                    <Breadcrumb.Item style={{ color:"#000"}}>Site</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={`${uriRoot}/sites/${site_results?.data?.site?._id}`}>{site_results?.data?.site?.name}</Link></Breadcrumb.Item>
                </>}
                {guard_results?.data?.guard?._id && <>
                    <Breadcrumb.Item style={{ color: "#000" }}>Guard</Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={`${uriRoot}/guard/${guard_results?.data?.guard?._id}`}>
                        {guard_results?.data?.guard?.f_name}{` `}{guard_results?.data?.guard?.m_name}{` `}{guard_results?.data?.guard?.l_name}
                    </Link></Breadcrumb.Item>
                </>}
                <Breadcrumb.Item><b> Reports</b></Breadcrumb.Item>
            </Breadcrumb>
        } */}

        <ContentArea transparent={inline} style={inline ? { padding:"0px", margin:"0px" } : {}}>
            <Row>
                <Col flex="auto"><ListHeader title={"Reports"} sub={<>Total {((reportsList && reportsList.totalCount) || '0')} record(s)</>} /></Col>
                <Col><Button onClick={() => set_showReportForm(true)}>Add Report</Button></Col>
            </Row>
            <div style={{ marginTop: "10px", padding: "10px", border: "1px solid #EEE", borderTopRightRadius: "5px", borderTopLeftRadius: "5px", backgroundColor: "rgba(0, 0, 0, 0.02)" }}>
                <SearchBar filter={{ }} onSubmit={fetchReports} />
            </div>
            <Table loading={busy} size="small"
                columns={columns}
                dataSource={reportsList ? reportsList.edges : null}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </ContentArea>

        <AddReportForm visible={showReportForm !== false} params={match && match.params} onSuccess={onReportAdd} onClose={() => set_showReportForm(false)} />

        {/* <DevBlock obj={showDetails} /> */}

        <Drawer visible={showDetails !== false} placement="top" height="100%" closable={true} onClose={() => set_showDetails(false)} destroyOnClose maskClosable={false} footer={false}
            bodyStyle={{ padding: "0px" }}
            title={showDetails && showDetails?.ref_key?.replace("_", " ")?.toUpperCase()}
        >
            {showDetails && <ReportPreview initialData={showDetails} />}
        </Drawer>
        
    </>)
}
