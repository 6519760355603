// import React from 'react';
import ls from 'local-storage';
import _ from 'lodash';
import { jsonStringify, parseJson, sleep } from './Functions';
import { __error, __warning, __yellow, __success, __green } from './consoleHelper';
// const user = ls('user');
import LocalStorage, { SessionStorage } from './LocalStorage';
import { loadavg } from 'os';
// import { store, persistor } from 'Store';
import { UserRightsByCat } from 'configs'

function getUser(){
  let temp = ls('persist:root');
  if (!temp || !temp.ep_admin) return false;

  temp = parseJson(temp.ep_admin);
  if (!temp || !temp.user) return false;

  return temp.user;

}

export const isSuperAdmin = (_user) => {
  let user = _user || getUser();
  return user.acc_type == 'super-admin';
}

export function verifyRole(_roleString, opt){
  const { user, matchAll } = opt || {};
  if (!_roleString) return false;


  let roleString = _roleString.toLowerCase();
  let roleArray = (roleString.indexOf(",") > -1) ? roleString.split(",") : [roleString]

  var _user = user || getUser();
  if (!_user){
    console.log(__error("User not found for role verification"), roleString)
    return false;
  }

  if (isSuperAdmin(_user)) return true;


  let total_to_match = roleArray.length;
  let total_match = roleArray.filter(o => _user.permissions.indexOf(o) > -1)?.length || 0;

  if (matchAll && total_to_match == total_match) return true;
  if (!matchAll && total_match > 0) return true;

  return false;


  // if (!matchAll && user && user.permissions && user.permissions.indexOf(roleString) > -1){
  //   console.log(__error("Permissions denied!"))
  //   // if (user && user.acc_type == 'super-admin') console.log(`${__success('')} verifyRole( ${roleString} ) ${__green('PASS')}`);
  //   return true;
  // }

  // // if (user && user.acc_type == 'super-admin') console.log(`${__error('')} verifyRole( ${roleString} ) ${__yellow('FAIL')}`);
  // return false;
}

export function verifyRoles(rolesArray, _user){
  if (_.isString(rolesArray)){
    console.log(__error("ERROR: "), "Invalid string provided for array");
    return false;
  }

  var user = _user || getUser();

  let returnVal = {}
  rolesArray.forEach(o => {
    let _isSuperAdmin = isSuperAdmin()
    Object.assign(returnVal, { [o]: _isSuperAdmin || verifyRole(o, user) })
  });
  // let temp = rolesArray.map(o=>{
  //   Object.assign(returnVal, { [o]: verifyRole(o, user) })
  //   // return { [o]: verifyRole(o, user) };
  // })

  return returnVal;
}

export const verifyModule = (_moduleName) => {
  let moduleName = _moduleName.toLowerCase();
  // if (process.env.NODE_ENV == 'development')
    console.log(__warning(`verifyModule(${moduleName}) >> Incomplete function`));
  return false;

  let verified = verifyRole(moduleName);
  // if(!verified){
  //   console.log(`${__warning(' >> ')} verifyModule( ${moduleName} ) ${__yellow('FAIL')}`);
  //   // console.trace(">>>>>");
  // }
  // else console.log(`${__success(' >> ')} verifyModule( ${moduleName} ${__green('PASS')}`);

  // console.log(`verifyModule(${moduleName}): `, verified?'YES':'NO');
  return verified;

  // const user = LocalStorage.getJson('user');
  // if(!user) return false;
  // // console.log("user.permissions: ", user.permissions);
  // if(user && user.type=='super-admin') return true;
  //
  // if(user && user.permissions && user.permissions.indexOf(moduleName) > -1) return true;
  //
  // console.log(__warning(`You are not allowed to access (${moduleName}) => ${user.type}@${user.name}`));
  // // .then(user=>{
  // //   console.log("verifyModule: ", user.type);
  // // });
  // // // return <div>Restricted Area</div>
  // return false;
}

export function getmodulePermissions(module_name) {
  let temp = UserRightsByCat.find(o => o.key == module_name);
  if (!temp){
    // if (process.env.NODE_ENV == 'development')
      console.log(__error('Invalid Module Name: '), module_name);
    return {};
  }
  return verifyRoles(temp.rules.map(o => o.key))
}




export const checkLoggedIn = args => {
  const user = LocalStorage.getJson('user');
  return (user && user._id && user._id>0 && user.TokenData);
}
// export const checkLoggedIn = login => {
//   // console.log("checkLoggedIn: ", login);
//   return (login && login._id && login._id>0 && login.TokenData);
// }


export const exposeSession = () => {
  console.log("exposeSession()");

  const user = LocalStorage.getJson('user');

  console.log("user: ", user)

}