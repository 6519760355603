// import { __error } from 'Common/scripts/consoleHelper';
import { __error } from 'Common/scripts/consoleHelper.js';
import { UPDATE_SETTINGS, DO_LOGIN, DO_LOGOUT, UPDATE_PAGE_SETTINGS, UPDATE_PROFILE, SET_BUSY, SET_ERROR, UPDATE_STATE } from '../ep_admin.actions.js';

const INITIAL_STATE = {
  navCollapsed:false,
  user: null,
  ap_key: null,
  pageSettings:null,
  busy: false,
  error: null,
  REACT_APP_APP_VER: null,
}

const updateSettings = (state, payload) => {
  return { ...state, ...payload }
}

const doLogin = (state, payload) => {
  console.log("doLogin(): ", payload.user);
  
  return { 
    ...state, 
    ...payload,
    // user: { 
    //   ...payload.user,
    // },
    ap_key: process.env.REACT_APP_APP_KEY,
    REACT_APP_APP_VER: process.env.REACT_APP_APP_VER
  }
}

const updateProfile = (state, payload) => {
  // console.log("redux.updateProfile(): ", payload.user);
  
  return { 
    ...state, 
    user: { 
      ...state.user,
      ...payload.user,
    },
    ap_key: process.env.REACT_APP_APP_KEY
  }
}


const doLogout = (state, payload) => {
  return INITIAL_STATE;
  // return { 
  //   ...state, 
  //   user: INITIAL_STATE.user, 
  //   ap_key:null,
  //   REACT_APP_APP_VER:null 
  // }
}

const updatePageSettings = (state, payload) => {
  // console.log("updatePageSettings()  ", payload)
  if (payload.page && payload.values){
    return { 
      ...state,
      pageSettings: { ...state.pageSettings, [`${payload.page}`]: payload.values }
    }
  }
  else{
    console.log(__error("Page sittings data is invalid, should be { page, value }"), payload)
    return { ...state }  
  }
  
  // return {...state}
  // return { ...state, pageSettings:{
  //   // ...state.pageSettings, 
  //   ...payload
  // } }
}

export const ep_admin_reducer = (state = INITIAL_STATE, {type, payload} ) => {
  switch (type) {
    case UPDATE_SETTINGS:
      return updateSettings(state, payload);
      break;
    case DO_LOGIN:
      return doLogin(state, payload);
      break;
    case DO_LOGOUT:
      return doLogout(state, payload);
      break;
    case UPDATE_PROFILE:
      return updateProfile(state, payload);
      break;
    case UPDATE_PAGE_SETTINGS:
      return updatePageSettings(state, payload);
      break;
    case SET_BUSY:
      return { ...state, busy: payload };
      break;
    case SET_ERROR:
      return { ...state, error: payload };
      break;
    case UPDATE_STATE:
      return { ...state, ...payload };
      break;


    default:
      return state;
  }
}

export default { ep_admin:ep_admin_reducer };
