import React from 'react';
import { addModule, addReducers } from '../connector';
import SMSHome from './SMSHome';
import SMSConversation from './SMSConversation';
import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT } from './constants'

export const modulePermissions = getmodulePermissions('sms-management');

if (modulePermissions['sms-management.access-module']) {
  addModule(
    { path: `${ROOT}`, component: SMSHome, icon: 'sms', title: "SMS", exact: true, leftNav: true },
  )
  // addModule(
  //   { path: `${ROOT}/:name/:phone`, component: SMSHome, icon: 'sms', title: "SMS", exact: true, leftNav: false },
  // )
  addModule(
    { path: `${ROOT}/conversation/:phone`, component: SMSConversation, title: "SMS Conversation", exact: true, leftNav: false },
  )
}
