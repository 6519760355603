import { DeleteButton, IconButton } from 'Common/components';
import React, { Component } from 'react'
// import PropTypes from 'prop-types';
// import { __error } from 'Common/scripts/consoleHelper'
import ListComp from './components/list';

const KEY_VALUE = "report_types";

const ReportTypes = props => {
  const columns = ({ onEditClick, onDeleteClick }) => [
    { title: 'ID', dataIndex: '_id', width: 100 },
    {
      title: 'Title', dataIndex: 'title', render: (__, rec) => (<>
        <div>{rec.title} </div>
        <span className='hidden-menu'>
          <IconButton className="item" icon="pen" onClick={() => onEditClick(rec)} />
          <DeleteButton className="item" onConfirm={() => onDeleteClick(rec?._id)} />
        </span>
      </>)
    },
    { title: 'Value', dataIndex: 'values' },
    // { title: 'Type', dataIndex: 'value_type', width: '100px' },
    { title: 'Status', dataIndex: 'status', width: 100 },
  ];

  return <ListComp key_value={KEY_VALUE} title="Report Types" columns={columns} />
}
export default ReportTypes;