import React, { Component } from 'react'
// import PropTypes from 'prop-types';
// import { __error } from 'Common/scripts/consoleHelper'
import ListComp from './components/list';


const KEY_VALUE = "system_settings";


const SystemSettings = props => {
  return <ListComp key_value={KEY_VALUE} title="System Settings" />
}
export default SystemSettings;