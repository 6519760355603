import React, { Component } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { loader } from 'graphql.macro';
import { Loader, Button } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import { Row, Col, message, Result, Divider, Upload, Steps, Alert } from 'antd'
import Header from 'Modules/TestManager/Forms/Components/Header';
import { E404, LayoutFooter } from "Layout_v1";
import ScreenPlay from './ScreenPlay'


const GET_COURSE = loader('src/Graphql/Courses/course.graphql');


class CoursePlay extends Component {
    state = { busy: true, data: null, fatel_error: null, moduleNum: 1 }

    componentDidMount(){
        this.fetchData()
    }
    
    fetchData(){
        const variables = { id: this.props.course._id };
        
        this.setState({ busy: true, fatel_error:null });

        this.props.client.query({
            query: GET_COURSE,
            variables,
            fetchPolicy: "no-cache"
        }).then(r => {
            let response = r.data?.course;

            if (!response) return this.setState({ busy: false, fatel_error: "Invalid response from server" });
            if (response.error) return this.setState({ busy: false, fatel_error: response.error.message });

            this.setState({ busy: false, data: response });

        }).catch(err => {
            console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
            this.setState({ busy: false, fatel_error: "Unable to complete your request at the moment!" });
        })

    }

    moveNext(){
        let total = this.state.data.modules.length;
        let moduleNum = this.state.moduleNum+1;
        if (moduleNum > total){
            // this.setState({ showTest:true })
            this.props.onCourseComplete()
            return;
        }
        this.setState({ moduleNum })
    }
    moveBack(){
        let total = this.state.data.modules.length;
        let moduleNum = this.state.moduleNum-1;
        if (moduleNum < 1) return;
        this.setState({ moduleNum })
    }

    render() {
        const { data, busy, fatel_error, moduleNum } = this.state;
        
        if (busy) return <Loader loading={busy} />
        if (!data?._id) return <Alert message={"No data found!!"} /*description={"error"}*/ type="error" showIcon />

        let total = data ? data.modules.length : 0;
        const hasNext = data ? (moduleNum) < total : false;
        const hasBack = data ? moduleNum > 1 : false;

        const thisModule = data.modules[this.state.moduleNum - 1];

        return (<div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <div style={{ alignItems: "center", alignContent: "center", display: "flex", flexDirection: "column" }}>
                <Row align="middle">
                    <Col><Header /></Col>
                    <Col style={{ fontSize: "24px" }} flex="auto">{data.title}</Col>
                    <Col><div style={{ fontSize: "14px", marginLeft:"50px" }}>{moduleNum}/{data.modules.length}</div></Col>
                </Row>
            </div>
            <div style={{flex:1}}>

                <div style={{ color: "#1E2248", marginBottom: "20px", alignItems: "center", alignContent: "center", display: "flex", flexDirection: "column" }}>
                    <Row style={{ padding: "20px 0", maxWidth: "1000px", width:"100%", border:"0px solid black" }} align="middle">
                        <Col><Button type="default" style={{ borderRadius:"50px" }} onClick={() => this.moveBack()} disabled={!hasBack}>Previous Module</Button></Col>
                        <Col flex="auto" style={{fontSize:"32px", textAlign:"center"}}>{thisModule.title}</Col>
                        <Col><Button type="default" style={{ borderRadius: "50px" }} onClick={() => this.moveNext()} disabled={!hasNext}>Next Module</Button></Col>
                    </Row>
                </div>

                <div style={{ alignItems: "center", alignContent: "center", display: "flex", flexDirection: "column" }}>
                    <div className='shadow' style={{ border: "1px solid #EEE", borderRadius: "10px", width: "100%", maxWidth: "1000px", minHeight: "400px", padding: "20px 40px 10px 40px", alignItems: "center", alignContent: "center", display: "flex", flexDirection: "column" }}>

                        <ScreenPlay screens={thisModule.screens} 
                            module={thisModule._id}
                            hasNextModule={hasNext}
                            hasPrevModule={hasBack}
                            onNextModule={() => this.moveNext()}
                            onBackModule={() => this.moveBack()}
                            >
                            <h6>About</h6>
                            <div style={{ maxWidth: "1000px", width: "100%" }} className="course_screen" dangerouslySetInnerHTML={{ __html: thisModule.body }} />
                        </ScreenPlay>

                    </div>
                </div>

                <div style={{height:"50px"}} />


            </div>
            <LayoutFooter />
        </div>)

    }
}

export default withApollo(CoursePlay)