import React, { useState } from 'react'
import { Popover } from 'antd'
import Icon from './Icon'
import { Link } from 'react-router-dom';

/*****
<ActionMenu menu={[
    { title: "Terminate employee", onClick: () => this.setState({ showTerminationProcess: !this.state.showTerminationProcess }), style: { color: "red" } },
    { title:"Employee has resigned", to:"/" },
]} /> 
 */
export const ActionMenu = ({ 
    contentStyle = { textAlign: "right" }, 
    placement="bottomRight", 
    title = false, 
    trigger="click", 
    menu=[],
    children,
    hideArrow
}) => {
    const [showMenu, set_showMenu] = useState(false);

    let content = menu.map((item, i) => {
        if (item.to) return <li key={i}><Link to={item.to} style={{ ...item.style }}>{item.title}</Link></li>

        return <li key={i}><a href="#" style={{ ...item.style }} onClick={(e) => {
            e.preventDefault();
            set_showMenu(false);
            item.onClick(item);
        }} >{item.title}</a></li>
    })

    return (<>
        <Popover placement={placement} title={title} trigger={trigger} visible={showMenu} onVisibleChange={set_showMenu} content={<div style={contentStyle}>{content}</div>}>
            <a href="#">{children || "Actions"} {!hideArrow && <Icon icon={showMenu ? "caret-up" : "caret-down"} />}</a>
        </Popover>

    </>)
}
