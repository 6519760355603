import React from 'react'
import { loader } from 'graphql.macro';
import { Drawer, Space, Alert, message, Row, Col, Button } from 'antd';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Loader, ClientSearch, Heading } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
// import { modulePermissions } from '.'
import { withApollo } from '@apollo/client/react/hoc';
// import { verifyRole } from 'Common/scripts/Security';

const ADD_CLIENT = loader('src/Graphql/client/addClient.graphql');

const fields = [
    // Client Information
    { name: 'client_name', label: 'Client Name', type: "text" },
    { name: 'client_compnay_name', type: "text", label: 'Company Name' },
    { name: 'client_phone', type: "text", label: 'Phone Number' },
    { name: 'client_email', type: "text", label: 'Email address' },

    // Client Contact Person
    { name: 'contact_person', type: "text", label: 'Contact Title' },
    { name: 'contact_email', type: "text", label: 'Email Address' },
    { name: 'contact_pri_name', type: "text", label: 'Phone Number (Primary)' },
    { name: 'contact_alt_name', type: "text", label: 'Phone Number (Alternate)' },
    { name: 'contact_preff_method', data: [
            { _id: "Phone", title: "Phone" },
            { _id: "Email", title: "Email" },
        ], type: "select", label: 'Preferred Method of Contact'
    },

    // Site Address
    { name: 'site_address_street', type: "text", label: 'Street Address' },
    { name: 'site_address_unit', type: "text", label: 'Unit #' },
    { name: 'site_address_city', type: "text", label: 'City' },
    { name: 'site_address_state', type: "text", label: 'Province/State' },
    { name: 'site_address_zip', type: "text", label: 'Postal/ZIP Code' },
    { name: 'site_address_country', type: "text", label: 'Country' },

    // Emergency Contact Information
    { name: 'emergency_name', type: "text", label: 'Name' },
    { name: 'emergency_phone', type: "text", label: 'Phone Number' },
    { name: 'emergency_email', type: "text", label: 'Email Address' },

    // Billing Address(if different from above)
    { name: 'bil_add_street', type: "text", label: 'Street Address' },
    { name: 'bil_add_city', type: "text", label: 'City' },
    { name: 'bil_add_state', type: "text", label: 'Province/State' },
    { name: 'bil_add_zip', type: "text", label: 'Postal/ZIP Code' },
    { name: 'bil_add_country', type: "text", label: 'Country' },

]


const SectionHeading = ({ children, style }) => <div style={{ fontSize: "25px", color: "#060079", marginTop: "30px", ...style }}>{children}</div>

class ClientFormDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, };
        this.onSubmit = this.onSubmit.bind(this)
    }

    async onSubmit(values) {
        this.setState({ error: false })

        // if (!modulePermissions['client.account-add']) {
        //     this.setState({ error:"Permissions denied!"})
        //     return false;
        // }

        const input = {
            name: values.client_name,
            // contact_person: values.contact_person,
            // phone: values.phone,
            // email: values.email,
            status: 'inactive', // values.status,
            fields: fields.map(o => {
                let value = values[o.name];
                if (o.type == "checkbox") value = (o.value === true) ? "yes" : "no";

                // do not add these fields in fields array
                if (String("client_name,bil_add_street,bil_add_city,bil_add_state,bil_add_zip,bil_add_country").indexOf(o.name) > -1) return null;

                return {
                    name: o.name,
                    label: o.label,
                    value,
                }
            }).filter(o=> (o!=null)),

            billing_name: values.contact_person,
            billing_email: values.contact_email,
            billing_phone: values.client_phone,
            billing_add: values.bil_add_street,
            billing_city: values.bil_add_city,
            billing_state: values.bil_add_state,
            billing_zip: values.bil_add_zip,
            billing_country: values.bil_add_country,
        }

        const result = await this.props.client.mutate({ mutation: ADD_CLIENT, variables: { input } }).then(r => (r?.data?.addClient))
        .catch(err => {
            console.log(__error("ERROR: "), err);
            return { error: { message: "Failed to process the request!" } }
        })


        if (!result || result.error) {
            message.error((result && result?.error?.message) || "Invalid response")
            this.setState({ error: (result && result?.error?.message) || "Invalid response" });
            return false;
        }

        message.success("Client Added");
        if (this.props.onAdd) return this.props.onAdd(result)
        this.props.onClose();

    }

    renderField(field_name, attributes) {
        let theField = fields.find(o => o.name == field_name);
        if (!theField) return <Alert message={`Field (${field_name}) not found`} type='warning' />

        if (theField.type == "checkbox") return <FormField name={theField.name} data={theField.data} size="medium" type="checkbox" validate={theField.validate} {...attributes}>{theField.label}</FormField>
        return <FormField {...theField} size="medium" {...attributes} />
    }


    render() {
        const { showDrawer, onClose, initialValues } = this.props;
        const { error } = this.state;


        return (<>
            <Drawer
                title="Add New Client"
                width={"100%"}
                contentWrapperStyle={{ maxWidth:"1200px" }}
                onClose={onClose}
                visible={showDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                extra={<Button onClick={onClose}>Cancel</Button>}
                // footer={<Row><Col flex="auto"></Col><Col>
                //     <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} >Submit </Button>
                // </Col></Row>}
                destroyOnClose={true}
            ><>
                {initialValues && <>
                    <FormComponent onSubmit={this.onSubmit} id='ClientForm' fields={initialValues} debug={true}
                        form_render={formProps => {
                            const { values, form, submitting, invalid, dirty, valid } = formProps;

                            return (<>
                                {error && <Alert message="Error!" description={error} type="error" showIcon />}

                                <SectionHeading style={{ marginTop: 0 }}>Client Information</SectionHeading>
                                <Row>
                                    <Col lg={6} span={24}>{this.renderField("client_name", { validate: rules.required })}</Col>
                                    <Col lg={6} span={24}>{this.renderField("client_compnay_name", { validate: rules.required })}</Col>
                                    <Col lg={6} span={24}>{this.renderField("client_phone", { validate: false })}</Col>
                                    <Col lg={6} span={24}>{this.renderField("client_email", { validate: false })}</Col>
                                </Row>

                                <SectionHeading>Client Contact Person</SectionHeading>
                                <Row>
                                    <Col md={12} span={24}>{this.renderField("contact_person", { validate: rules.required })}</Col>
                                    <Col md={12} span={24}>{this.renderField("contact_email", { validate: rules.required })}</Col>
                                    <Col md={12} span={24}>{this.renderField("contact_pri_name", { validate: rules.required })}</Col>
                                    <Col md={12} lg={6} span={24}>{this.renderField("contact_alt_name", { validate: rules.required })}</Col>
                                    <Col md={12} lg={6} span={24}>{this.renderField("contact_preff_method", { validate: rules.required })}</Col>
                                </Row>

                                <SectionHeading>Site Address</SectionHeading>
                                <Row>
                                    <Col lg={9} md={12} span={24}>{this.renderField("site_address_street", { validate: rules.required })}</Col>
                                    <Col lg={3} md={12} span={24}>{this.renderField("site_address_unit", { validate: rules.required })}</Col>
                                    <Col lg={3} md={12} span={24}>{this.renderField("site_address_city", { validate: rules.required })}</Col>
                                    <Col lg={3} md={12} span={24}>{this.renderField("site_address_state", { validate: rules.required })}</Col>
                                    <Col lg={3} md={12} span={24}>{this.renderField("site_address_zip", { validate: rules.required })}</Col>
                                    <Col lg={3} md={12} span={24}>{this.renderField("site_address_country", { validate: rules.required })}</Col>
                                </Row>

                                <SectionHeading>Billing Address (if different from above)</SectionHeading>
                                <Row>
                                    <Col lg={12} md={12} span={24}>{this.renderField("bil_add_street")}</Col>
                                    <Col lg={3} md={12} span={24}>{this.renderField("bil_add_city")}</Col>
                                    <Col lg={3} md={12} span={24}>{this.renderField("bil_add_state")}</Col>
                                    <Col lg={3} md={12} span={24}>{this.renderField("bil_add_zip")}</Col>
                                    <Col lg={3} md={12} span={24}>{this.renderField("bil_add_country")}</Col>
                                </Row>

                                <SectionHeading>Emergency Contact Information</SectionHeading>
                                <Row>
                                    <Col lg={8} md={12} span={24}>{this.renderField("emergency_name", { validate: rules.required })}</Col>
                                    <Col lg={8} md={12} span={24}>{this.renderField("emergency_phone", { validate: rules.required })}</Col>
                                    <Col lg={8} md={12} span={24}>{this.renderField("emergency_email", { validate: rules.required })}</Col>
                                </Row>


                                {/* {verifyRole("client.bill") && <>
                                    <Heading>Billing info</Heading>
                                    <FormField label="Billing Name" name="billing_name" type="text" />
                                    <FormField label="Billing Email" name="billing_email" type="text" />
                                    <FormField label="Billing Phone" name="billing_phone" type="text" />
                                    <FormField label="Billing Address" name="billing_add" type="text" />
                                </>} */}

                                <Row style={{ marginTop: '20px', marginRight: '10px' }}>
                                    <Col flex="auto"></Col>
                                    <Col flex="200px">
                                        <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!valid}>Create Client</Button>
                                    </Col>
                                </Row>

                            </>)
                        }}
                    />
                </>}
            </></Drawer>

        </>)

    }

}

export default withApollo(ClientFormDrawer);