import React from 'react';
import { addModule, addReducers } from '../connector';
import { getmodulePermissions, verifyRole } from 'Common/scripts/Security';
import Home from './Home'
import RequestList from './po-requests/containers/request-list';
import reducers from './po-requests/redux/reducer';

import { ROOT, MODULE } from './constants';
import { uriRoot } from 'configs';
// export const modulePermissions = getmodulePermissions('po-requests');

export const modulePermissions = getmodulePermissions('acc');


var subMenuArray = [
    { path: `${uriRoot}${ROOT}/po-requests`, exact: true, title: 'PO Requests', component: RequestList, permissions: ['acc-request-po'] },
]
export const SubMenu = props => subMenuArray.filter(o => {
    if (!o.permissions) return true;

    let passed = o.permissions.filter(oo => modulePermissions[oo] ? true : false)
    if (passed && passed.length == o.permissions.length) return true;
    return false;
});

// verifyRole("acc-access")
if (modulePermissions['acc-access']) {
    addModule(
        {
            path: `${ROOT}`, component: Home, icon: 'building', title: "Accounts", exact: false, leftNav: true,
            subMenu: SubMenu()
        },
    )
    
    addReducers(reducers);
}



// var subMenuArray = [
//     { path: `${ROOT}/result`, exact: true, title: 'PO Requests', component: POList },
// ]
// export const SubMenu = props => subMenuArray;

// if (verifyRole("po-requests")) {
//     addModule(
//         // { menuPath: `${ROOT}`, path: `${ROOT}`, component: POList, icon: 'user-tie', title: "PO Requests", exact: true, leftNav: true },
//         {
//             path: `${ROOT}`, component: POList, icon: 'accounts', title: "Accounts", exact: true, leftNav: true,
//             subMenu: SubMenu()
//         },

//     )
// }
// // addModule(
// //     { path: `/client/:id`, component: Detail, icon: 'desktop', title: "Client Detail", leftNav: false },
// // )