/****** Saga Example */
export const TEST_SAGA = 'TEST_SAGA';
export const TEST_SAGA_SYNC = 'TEST_SAGA_SYNC';
export const testSaga = payload => ({ type: TEST_SAGA, payload });
export const testSagaSync = payload => ({ type: TEST_SAGA_SYNC, payload });



export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const loginUser = payload => ({ type: LOGIN_USER, payload });
export const logoutUser = payload => ({ type: LOGOUT_USER, payload });
