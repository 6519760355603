import React, { Component, useState, useEffect } from 'react'
import { Drawer, Modal, message, Row, Col, Divider, Space, Alert, Popover } from 'antd';
import moment from 'moment';
import { Form, Field } from 'react-final-form'
import { loader } from 'graphql.macro';
import { rules, FormField } from 'Common/components/Form';
import { formData_guardApplication, defaultDateFormat, defaultDateTimeFormat, uriRoot } from 'configs'
import { Icon, Loader, Button, DataRow, Tabs, DevBlock } from 'Common/components';
import { ContentArea } from "Layout_v1";
import { mongoToDate } from 'Common/scripts/Functions';
import Notes, { NotesFromDuplicates, NotesGroup } from 'Modules/Notes';
import { __error } from 'Common/scripts/consoleHelper';
import { Link, useHistory } from "react-router-dom";
import _ from 'lodash';
import { FORM_KEY } from '.';
import { withApollo } from '@apollo/client/react/hoc';
import StatusForm from '../../Components/StatusForm';
import HireGuardProcess from '../../Components/HireGuardProcess';
import { TheField } from '../Components'
import GuardScreeningProcess, { GuardScreeningProcessPreview } from './GuardScreeningProcess';
import { gql } from '@apollo/client';
import { useLazyQuery, useMutation } from '@apollo/client';

const fieldsToList = ['sin_no', 'email_address', 'home_phone', 'mobile_phone', 'license_no'];
const ADD_BLACKLIST = loader('src/Graphql/FormsData_BlackList/addFormsData_BlackList.graphql');
const GET_GUARDS = loader('src/Graphql/guard/guards.graphql');


// const NotesBlock = ({ data }) => {
//     const [activeTab, set_activeTab] = useState('hr')

//     return (<>
//         <div style={{ marginLeft: "15px" }}><Tabs onChange={set_activeTab} tabsArray={[
//             { key: 'hr', title: "HR Notes" },
//             { key: 'general', title: "General Notes" },
//         ]} /></div>
//         <ContentArea style={{ marginTop: "0", padding:"5px" }}>
//             {activeTab == 'general' && <Notes id={data._id} formkey={FORM_KEY} />}
//             {activeTab == 'hr' && <Notes id={data._id} formkey="hr_guard" />}
//         </ContentArea>
//     </>)
// }

const ExEmployeeCheck = ({ data }) => {
    const [exEmployee, set_exEmployee] = useState(null)

    const [guards, { called, loading }] = useLazyQuery(
        GET_GUARDS, // { variables: { filter: JSON.stringify({ ...filter }), others: JSON.stringify({ sort: { order: 1 } }) } }
    );

    useEffect(() => {
        if (called || loading) return;

        // let home_phone = data.fields.find(o => o.name =='home_phone')
        let mobile_phone = data.fields.find(o => o.name =='mobile_phone')
        let email_address = data.fields.find(o => o.name =='email_address')
        let license_no = data.fields.find(o => o.name =='license_no')
        let valid_driving_license = data.fields.find(o => o.name =='valid_driving_license')
        // let vehicle_license_plate_no = data.fields.find(o => o.name =='vehicle_license_plate_no')

        let _filter = {}
        if (mobile_phone && mobile_phone.value) Object.assign(_filter, { phone: mobile_phone.value, whatsapp: mobile_phone.value })
        if (valid_driving_license && valid_driving_license.value) Object.assign(_filter, { driving_license: valid_driving_license.value })
        if (license_no && license_no.value) Object.assign(_filter, { security_license_no: license_no.value })
        if (email_address && email_address.value) Object.assign(_filter, { email: email_address.value })

        if (Object.keys(_filter).length > 0){
            let keys = Object.keys(_filter)

            let filter = keys.map(o => ({ [o]: _filter[o]  }))
            filter = { $or: filter }
            
            guards({ variables: { filter: JSON.stringify(filter) } })
                .then(r=>{
                    let results = r?.data?.guards;
                    if (!results || results.length<1) return false;
    
                    set_exEmployee(results[0]);
                    return results;
                })
                .catch(err=>{
                    console.log(__error("Error: "), err)
                    return { error:{message:"Unable to fetch matching employee!"}}
                })
        }

    }, [])

    // return (<>
    //     <DevBlock obj={data} />
    // </>)


    if (loading) return <div style={{ textAlign:"center" }}><Loader loading={true}>Matching Ex-employee</Loader></div>
    if (!exEmployee) return null;

    return (<>
        <Alert message={<span>This applicant is an ex-employee: <Link to={`${uriRoot}/guard/${exEmployee._id}`} target="_blank">{exEmployee.f_name} {exEmployee.m_name} {exEmployee.l_name}</Link></span>} type='warning' showIcon />
        {/* <DevBlock obj={exEmployee} /> */}
    </>)

}

const FormsDataViewComp = ({ data, client }) => {
    const [busy, setBusy] = useState(false);
    const [showDuplicate, set_showDuplicate] = useState(false);
    const [showAddToBlacklist, set_showAddToBlacklist] = useState(false);
    const [showAddguard, set_showAddguard] = useState(false);
    const [showScreeningForm, set_showScreeningForm] = useState(false);
    const [showSreeningPreview, set_showSreeningPreview] = useState(false);

    let history = useHistory();

    if (!data) return null;

    function getField(_fieldname, fromArray){
        let fields = data.fields;
        return fromArray ? 
            fromArray.find(o => o.name == _fieldname) || {} : 
            fields.find(o => o.name == _fieldname) || {};
    }

    const renderDuplicates = () => {
        if (!data.dupCount || data.dupCount<1) return null;

        return (<ContentArea>
            <p style={{color:"red", fontSize:"16px"}}><b>{data.dupCount} duplicates found</b></p>
            <Row style={{fontWeight:"bold", textAlign:"center"}}>
                <Col flex="auto">Created</Col>
                <Col flex="50%">Updated</Col>
            </Row>
            {data.duplicates.map((dup, i) => {
                return <div key={i} style={{marginBottom:"5px"}}>
                    <Button onClick={() => set_showDuplicate(dup)} type="default" block>
                        <Row>
                            <Col flex="auto">{mongoToDate(dup.created_at).format(defaultDateFormat)}</Col>
                            <Col flex="50%">{mongoToDate(dup.updated_at).format(defaultDateFormat)}</Col>
                        </Row>
                    </Button>
                </div>;
            })}
        </ContentArea>)
    }

    const addToBlacklistSubmit = values => {

        let chk = fieldsToList.filter(o => values[o]);
        if (!chk || chk.length<1) return alert("Please make your selection");

        let fields = {}
        fieldsToList.forEach(field => {
            if (values[field]) Object.assign(fields, { [field]: getField(field).value })    
        });
        // if (values.sin_no) Object.assign(fields, { sin_no: getField('sin_no').value })

        let input = {
            title: `${getField('f_name').value} ${getField('m_name').value} ${getField('l_name').value}`,
            ref_key: data.ref_key,
            fields: JSON.stringify(fields)
        }

        setBusy(true);
        const results = client.mutate({ mutation: ADD_BLACKLIST, variables: { input } }).then(r => {
            return r.data.addFormsData_BlackList;
        }).catch(err => {
            console.log(__error("ERROR"), err);
            return { error: { message:"Failed to process the request!" }}
        });
        setBusy(false);

        if (results.error) return message.error(results.error.message);

        set_showAddToBlacklist(false);
        message.success("Records added to blacklist")
    }

    const blackListButton = args => {
        if (data.isDuplicate=='yes') return <span style={{ backgroundColor:"red", borderRadius:"10px", fontSize:"22px", color:"white", fontWeight:"bold", padding:"2px 10px" }}>Diplicate</span>;

        let blacklist = (data.blacklist && data.blacklist.length > 0) ? data.blacklist : false;
        if (!blacklist) return <Button onClick={() => set_showAddToBlacklist(true)} type="danger" size="small">Add to black list</Button>;

        // count the fields that are not blacklisted yet
        let chklist = fieldsToList.filter(field=>{
            return !blacklist.find(o => o.field == field);
        });

        if (chklist.length < 1) return <span />
        return <Button onClick={() => set_showAddToBlacklist(true)} type="danger" size="small">Add to black list</Button>;
    }

    const onShowGuardClick = args => {
        set_showAddguard(data)
    }

    const onHiringProcessComplete = values => {
        // console.log("onHiringProcessComplete()", values);
        if (!values) return set_showAddguard(false);

        if (values && values.error) return;
        // if (values && values.status) _.update(data, 'status', ()=>('hired'));

        if (!values) history.push(`/admin/guard_details/${values._id}`);
        else set_showAddguard(false);
    }

    const renderAttarhments = () => {
        let attachments = data.attachments;
        if (!attachments || attachments.length<1) return null;

        return <>
            <hr style={{ margin: "50px" }} />
            <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="address-book" /> Document Attachments</div>
            <ol>
                {attachments.map((file, i) => {
                    return <li key={i}><a href={`${process.env.REACT_APP_DATA_URL}/${file.srcUrl}`} target="_blank">{file.title}</a></li>
                })}
            </ol>
        </>
    }

    const experience = getField('experience').value ? JSON.parse(getField('experience').value) : null;
    const refrences = getField('refrences').value ? JSON.parse(getField('refrences').value) : null;
    const emergency_contact = getField('emergency_contact').value ? JSON.parse(getField('emergency_contact').value) : null;
    const availability = getField('availability').value ? JSON.parse(getField('availability').value) : [];

    let blacklist = (data.blacklist && data.blacklist.length > 0) ? data.blacklist : false;
    let screeningComplete = (data?.screening?.length > 0);

    // console.log("data: ", data)

    return (<>
        {/* <DevBlock obj={data} /> */}

        <Row style={{ flexWrap: "nowrap" }}>
            <Col flex="auto" className='form-data'>
                {(data.blacklist && data.blacklist.length>0) && <div style={{margin:"0 10px"}}>
                    <Alert message={<b>Applicant is black listed</b>} type="warning" showIcon style={{borderRadius:"5px"}}
                        // {getField('currently_licensed')}
                        description={<Row gutter={[20]}>
                            {data.blacklist.map((o, i) => {
                                return (<Col key={i}><DataRow col1={getField(o.field).label} col2={o.value} /></Col>)
                            })}
                        </Row>}
                    />
                </div>}

                <ContentArea style={{marginRight:0}}>
                    <Row>
                        <Col flex="auto">
                            <Space>
                                {blackListButton()}
                                {/* <Button size="small" onClick={() => onShowGuardClick(data)} disabled={blacklist != false}>Hire this guard</Button> */}
                                {((data.status !== 'hired' || data.status !== 'rejected')) && 
                                    <Popover content={<>
                                            {/* <Button color="green" block onClick={() => set_showScreeningForm(true)} disabled={blacklist != false}>Start Screening</Button> */}

                                            {data?.screening?.length > 0 && <>
                                                <Space direction='vertical'>
                                                    {data?.screening?.map((item, i) => {
                                                        return <Button size="small" onClick={() => set_showSreeningPreview(item)} key={i}>{mongoToDate(item.created_at).format(defaultDateTimeFormat)}</Button>
                                                    })}
                                                </Space>
                                            </>}
                                        </>} 
                                        // title="Screening"
                                        title={<Button color="green" block onClick={() => set_showScreeningForm(true)} disabled={blacklist != false}>Start Screening</Button>}
                                    >
                                        <Button size="small">Screening {data?.screening?.length > 0 ? `(${data?.screening?.length || 0})` : ''}</Button>
                                    </Popover>
                                }

                                {/* {((data.status !== 'hired' || data.status !== 'rejected')) &&
                                    <Button size="small" onClick={() => set_showScreeningForm(true)} disabled={blacklist != false}>Screening {data?.screening?.length > 0 ? `(${data.screening.length})` : ''}</Button>
                                } */}
                                {data.isDuplicate!=='yes' && 
                                    <Button size="small" onClick={() => onShowGuardClick(data)} disabled={blacklist != false || !screeningComplete}>Hire this guard</Button>
                                }
                            </Space>
                        </Col>
                        <Col style={{ color: '#999' }}>{mongoToDate(data.created_at).format('Do MMM YYYY - HH:mm')}</Col>
                    </Row>
                    
                    <p style={{ width: "100%", fontSize: "30px", textAlign: "center", textTransform: "capitalize", color: "#2F2F2F", fontWeight: "bold" }}>{getField('f_name').value} {getField('m_name').value} {getField('l_name').value}</p>

                    <ExEmployeeCheck data={data} />

                    <div className='preview_card'>
                        <Row gutter={[15]}>
                            <Col flex="50%" style={{borderRight:"1px solid #EEE"}}>
                                <div style={{ fontWeight: "bold", fontSize: "20px", color:'#060079' }}><Icon icon="address-book" /> Contact</div>
                                <TheField {...getField('email_address')} value={getField('email_address').value} size={['25%']} horizontal />
                                <TheField {...getField('home_phone')} value={getField('home_phone').value} size={['25%']} horizontal />
                                <TheField {...getField('mobile_phone')} value={getField('mobile_phone').value} size={['25%']} horizontal />
                                <DataRow col1={'Address: '} col2={<>{getField('appt_no').value} {getField('street').value}, {getField('province').value}, {getField('city').value}, {getField('postal_code').value}</>} vertical />
                            </Col>
                            <Col flex="auto">
                                <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="user-alt" /> Personal Information</div>
                                <TheField {...getField('d_o_b')} value={moment(getField('d_o_b').value).format(defaultDateFormat)} size={['35%']} horizontal />
                                <TheField {...getField('residential_status')} size={['35%']} horizontal />
                                <TheField {...getField('position_applied_for')} size={['35%']} horizontal />
                                <TheField {...getField('sin_no')} size={['35%']} horizontal />
                                <TheField {...getField('license_no')} size={['35%']} horizontal />
                                <TheField {...getField('license_expiry')} size={['35%']} value={moment(getField('license_expiry')).format(defaultDateFormat)} horizontal />
                            </Col>
                        </Row>
                        <hr style={{margin:"50px"}} />

                        <Row gutter={[15]}>
                            <Col flex="50%" style={{ borderRight: "1px solid #EEE" }}>
                                <TheField {...getField('currently_licensed')} size={['30px']}
                                    isCheckbox={true} 
                                    condition={(val) => <Icon icon={val == 'yes' ? "check" : "times"} size={"1x"} color={val == 'yes' ? "green" : "red"} />} />

                                <TheField {...getField('older_than_18')} size={['30px']} 
                                    isCheckbox={true} 
                                    condition={(val) => <Icon icon={val == 'yes' ? "check" : "times"} size={"1x"} color={val == 'yes' ? "green" : "red"} />} />

                                <TheField {...getField('legally_entitled')} size={['30px']}
                                    isCheckbox={true} 
                                    condition={(val) => <Icon icon={val == 'yes' ? "check" : "times"} size={"1x"} color={val == 'yes' ? "green" : "red"} />} />

                                <TheField {...getField('convicted_of_crime')} size={['30px']}
                                    isCheckbox={true} 
                                    condition={(val) => <Icon icon={val == 'yes' ? "check" : "times"} size={"1x"} color={val == 'yes' ? "green" : "red"} />} />
                                {getField('convicted_of_crime').value == 'yes' &&
                                    <TheField {...getField('crime')} />
                                }

                            </Col>
                            <Col flex="auto">
                                <TheField {...getField('worked')} size={['30px']}
                                    isCheckbox={true}
                                    condition={(val) => <Icon icon={val == 'yes' ? "check" : "times"} size={"1x"} color={val == 'yes' ? "green" : "red"} />} />
                                {getField('worked').value == 'yes' &&
                                    <TheField {...getField('previously_worked_date')} size={['auto']}
                                        value={moment(getField('previously_worked_date')).format(defaultDateFormat)} />
                                }

                                <TheField {...getField('applied')} size={['30px']}
                                    isCheckbox={true} 
                                    condition={(val) => <Icon icon={val == 'yes' ? "check" : "times"} size={"1x"} color={val == 'yes' ? "green" : "red"} />} />
                                {getField('applied').value == 'yes' &&
                                    <TheField {...getField('previously_applied_date')} size={['auto']}
                                    value={moment(getField('previously_applied_date')).format(defaultDateFormat)} />
                                }

                                <TheField {...getField('question_current')} size={['30px']}
                                    isCheckbox={true} 
                                    condition={(val) => <Icon icon={val == 'yes' ? "check" : "times"} size={"1x"} color={val == 'yes' ? "green" : "red"} />} />
                                {getField('question_current').value == 'yes' &&
                                    <TheField {...getField('current_company_name')} size={['auto']} />
                                }
                            </Col>
                        </Row>
                        <hr style={{ margin: "50px" }} />

                    
                        <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="clock" /> Availability</div>
                        <Row>
                            <Col flex="50%"><DataRow col1={getField('available_to_work').label} col2={getField('available_to_work').value} inline /></Col>
                            <Col flex="auto"><DataRow col1={getField('available_start_date').label} col2={moment(getField('available_start_date').value).format(defaultDateFormat)} inline /></Col>
                        </Row>

                        <Row align="middle" style={{ color: "#060079" }} className='dark'>
                            {['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
                                return <Col flex="12.5%" align="center" style={{ borderBottom: "1px solid #EEE", paddingTop: "10px", borderRight: "1px solid #EEE", fontWeight: "bold" }} key={index}>{day}</Col>
                            })}
                        </Row>

                        {["Morning", "Afternoon", "Night"].map((daypart, index) => (
                            <Row align="middle" key={index}>
                                <Col flex="12.5%" style={{ height: "40px", paddingTop: "10px" }}>{daypart}</Col>
                                {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                                    return <Col key={i} flex="12.5%" align="center" style={{ height: "40px", padding: "10px", borderBottom: "1px solid #EEE", borderRight: "1px solid #EEE" }}>
                                        <>
                                            {availability[day] && availability[day]?.indexOf(daypart.toLowerCase()) > -1 ?
                                                <Icon icon="check" size={"1x"} color="green" /> :
                                                <Icon icon="times" size={"1x"} color="#EEE" />
                                            }
                                        </>
                                    </Col>
                                })}
                            </Row>
                        ))}

                        <hr style={{ margin: "50px" }} />
                        <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="car-side" /> Driving Information</div>
                        <div className='dark'><TheField {...getField('valid_driving_license')} /></div>
                        <div className='simple'>
                            <TheField {...getField('vehicle_owner')} />
                            {getField('vehicle_owner').value == 'yes' && <>
                                <Row>
                                    <Col flex="auto" ><TheField {...getField('vehicle_make')} /></Col>
                                    <Col flex="250px"><TheField {...getField('vehicle_model')} /></Col>
                                    <Col flex="250px"><TheField {...getField('vehicle_license_plate_no')} /></Col>
                                    <Col flex="250px"><TheField {...getField('vehicle_color')} /></Col>
                                </Row>
                            </>}
                        </div>


                        <hr style={{ margin: "50px" }} />
                        <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="user-graduate" /> Education</div>
                        <div className='dark'><TheField {...getField('highest_level_of_education')} /></div>
                        <div className='simple'><TheField {...getField('secondary_school_diploma')} /></div>
                        <div className='dark'><TheField {...getField('college_university_techicalschool')} /></div>
                        <div className='simple'><TheField {...getField('training_courses_detail')} /></div>


                        <hr style={{ margin: "50px" }} />
                        <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="lock" /> Security Experience</div>
                        {(!experience || experience.length < 1) && <p style={{ marginLeft: "30px" }}>No security experience</p>}
                        {(experience && experience.length > 0) && experience.map((row, i) => {
                            return (<div key={i}>
                                <Row>
                                    <Col flex="auto"><TheField value={row.company_name} label="Security company name" /></Col>
                                    <Col flex="33%"><TheField value={row.supervisor_name} label="Supervisor name" /></Col>
                                    <Col flex="33%"><TheField value={row.supervisor_work_number} label="Supervisor work number" /></Col>
                                </Row>

                                <Row>
                                    <Col flex="150px"><TheField value={row.hourly_rate} label="Hourly rate" /></Col>
                                    <Col flex="auto"><TheField value={row.work_location} label="Work location / site address(where security guard duty is/was performed)" /></Col>
                                </Row>

                                <Row>
                                    <Col flex="50%"><TheField value={row.work_site_property_name} label="Work / site / property name" /></Col>
                                    <Col flex="50%"><TheField value={row.category} label="Category (condo, truck yard etc)" /></Col>
                                </Row>
                                <Row>
                                    <Col flex="50%"><TheField label="Work duration" value={<>{moment(row.work_start_date).format(defaultDateFormat)} <b>till</b> {moment(row.work_end_date).format(defaultDateFormat)}</>} /></Col>
                                    <Col flex="auto"><TheField value={row.work_end_season} label="Work end reason" /></Col>
                                </Row>
                            </div>)
                        })}


                        <hr style={{ margin: "50px" }} />
                        <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="address-card" /> References</div>
                        {(!refrences || refrences.length < 1) && <p style={{ marginLeft: "30px" }}>No References provided</p>}
                        {(refrences && refrences.length > 0) && refrences.map((row, i) => {
                            return (<div key={i}>
                                <Divider orientation="left">Refrence # {i}</Divider>
                                <Row>
                                    <Col flex="250px"><TheField label="Name" value={row.fullname} /></Col>
                                    <Col flex="250px"><TheField label="Contact number" value={row.number} /></Col>
                                    <Col flex="250px"><TheField label="Position" value={row.position} /></Col>
                                    <Col flex="250px"><TheField label="Company Name" value={row.company} /></Col>
                                </Row>
                            </div>)
                        })}


                        <hr style={{ margin: "50px" }} />
                        <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="address-book" /> Emergency Contacts</div>
                        <ol>
                            {(emergency_contact && emergency_contact.length > 0) ? emergency_contact.map((contact, i) => (
                                <li key={i}>
                                    <Icon icon="phone" /> {contact.number} - {contact.fullname} - ({contact.relation})
                                </li>
                            )) : <p >No emergency contacts</p>}
                        </ol>


                        <hr style={{ margin: "50px" }} />
                        <Row style={{ flexWrap:"nowrap" }}>
                            <Col flex="auto">
                                <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="handshake" /> Signature</div>
                                <div className='dark'>
                                    <TheField {...getField('certify')} size={['30px', 'auto']} 
                                        isCheckbox={true} condition={(val) => val == 'yes' ? <Icon icon="check" size={"1x"} color="green" /> : <Icon icon="times" size={"1x"} color="red" />} />
                                </div>
                            </Col>
                            <Col>
                                <img src={getField('signatureDataURL').value} alt="" style={{ margin: "10px auto" }} />
                            </Col>
                        </Row>
                        
                        <TheField {...getField('job_info_source')} />
                        <TheField {...getField('note')} />

                        {renderAttarhments()}

                    </div>

                </ContentArea>
            </Col>

            <Col flex="30%" style={{maxWidth:'350px'}}>
                {renderDuplicates()}

                <ContentArea>
                    <StatusForm 
                        onStatusChange={console.log} 
                        initialValues={{ 
                            _id: data._id,
                            ref_key: "guard_appli", //data.ref_key,
                            status: data ? data.status : 'new' 
                        }} 
                        statusArray={formData_guardApplication} />

                    {/* <Form onSubmit={onStatusChange} initialValues={{ status: data ? data.status : 'new' }} validate={(__values) => (false)}>
                        {({ handleSubmit, submitting, form, values, invalid, dirty, valid }) => (<>
                            <form className="form_component" onSubmit={handleSubmit}>
                                <FormField name="status" type="select" size="medium" placeholder="SELECT" label="Status"
                                    data={formData_guardApplication.filter(o => o._id !== 'new')}
                                />
                                {dirty && <>
                                    <FormField name="note" type="textarea" size="small" placeholder="Please provide the reason of status change" label="Notes" validate={rules.required} />
                                    <div style={{margin:"0 10px", textAlign:"right"}}>
                                        <Button type="primary" size="medium" htmlType='submit' loading={busy} disabled={!dirty || !valid}>Save</Button>
                                    </div>
                                </>}
                            </form>
                        </>)}
                    </Form> */}

                </ContentArea>

                {/* <NotesBlock data={data} /> */}

                <NotesGroup data={data} types={[
                    { key: 'guard_appli', title: "General Notes", permissionsReq: "general.notes" },
                    { key: 'hr_guard_appli', title: "HR Notes", permissionsReq: "hr.notes" },
                ]} />


                {(data.dupCount > 0) && <ContentArea>
                    <hr style={{ margin: "20px" }} />
                    <div style={{ fontWeight: "bold", fontSize: "16px", textAlign: "center" }}>Notes from duplicates</div>
                    <NotesFromDuplicates ids={data.duplicates.map(o => o._id)} formkey={FORM_KEY} />
                </ContentArea>}

            </Col>
        </Row>


        <Drawer title="Duplicate Record" bodyStyle={{ backgroundColor:"#EEE", padding:0 }} placement="top" height="100%" onClose={() => set_showDuplicate(false)} visible={showDuplicate!==false}>
            {showDuplicate && <>
                <FormsDataView data={showDuplicate} />
            </>}
        </Drawer>

        <Modal title={"Guard Screening Process"} width="800px" visible={showScreeningForm} footer={false} onCancel={() => set_showScreeningForm(false)}>
            {showScreeningForm && <GuardScreeningProcess 
                onCancel={() => set_showScreeningForm(false)} 
                onSuccess={(results) => set_showScreeningForm(false)} 
                application={data}
            />}
        </Modal>
        <Modal title={"Guard Screening Process Details"} width="800px" visible={showSreeningPreview !== false} footer={false} onCancel={() => set_showSreeningPreview(false)}>
            {showSreeningPreview && <GuardScreeningProcessPreview 
                onCancel={() => set_showSreeningPreview(false)} 
                data={showSreeningPreview}
            />}
        </Modal>

        <Modal title={<div style={{ textTransform: "capitalize" }}>Black list - {getField('f_name').value} {getField('m_name').value} {getField('l_name').value}</div>} visible={showAddToBlacklist} footer={false} onCancel={() => set_showAddToBlacklist(false)}>
            {showAddToBlacklist && <>
                <Form onSubmit={addToBlacklistSubmit} _initialValues={{ sin_no: true, email_address: true, home_phone: true, mobile_phone: true, license_no:true }}>
                    {({ handleSubmit, submitting, form, values, invalid, dirty, valid }) => (<>
                        <form className="form_component" onSubmit={handleSubmit}>
                            {/* <p style={{ width: "100%", fontSize: "16px", textAlign: "center", color:"red", textTransform: "capitalize", fontWeight: "bold" }}>{getField('f_name').value} {getField('m_name').value} {getField('l_name').value}</p> */}
                            <p>Please select the records from below you want to add to black list</p>
                            {fieldsToList.map((field, i)=>{
                                let relatedData = getField(field);
                                let havVal = relatedData.value;
                                if (!havVal) return null;
                                return <div key={i}>
                                    <FormField disabled={blacklist && blacklist.find(o => o.field == field)} name={field} type="checkbox">
                                        {relatedData.label}: <b>{relatedData.value}</b>
                                    </FormField></div>
                            })}



                            {/* {getField('sin_no').value && <>
                                <div><FormField disabled={blacklist.find(o => o.field == 'sin_no')} name="sin_no" type="checkbox">SIN #: <b>{getField('sin_no').value}</b></FormField></div>
                            </>} */}
                            
                            {/* {getField('email_address').value && <>
                                <div><FormField disabled={blacklist.find(o => o.field == 'email_address')} name="email_address" type="checkbox">Email address: <b>{getField('email_address').value}</b></FormField></div>
                            </>} */}

                            {/* {getField('home_phone').value && <>
                                <div><FormField disabled={blacklist.find(o => o.field == 'home_phone')} name="home_phone" type="checkbox">Home phone #: <b>{getField('home_phone').value}</b></FormField></div>
                            </>} */}

                            {/* {getField('mobile_phone').value && <>
                                <div><FormField disabled={blacklist.find(o => o.field == 'mobile_phone')} name="mobile_phone" type="checkbox">Mobile phone #: <b>{getField('mobile_phone').value}</b></FormField></div>
                            </>} */}

                            {/* {getField('license_no').value && <>
                                <div><FormField disabled={blacklist.find(o => o.field == 'license_no')} name="license_no" type="checkbox">License #: <b>{getField('license_no').value}</b></FormField></div>
                            </>} */}

                            {/* {!(blacklist.find(o => o.field == 'sin_no')) && 
                                <></>
                                {getField('sin_no').value && <div><FormField name="sin_no" type="checkbox">Black list this SIN <b>{getField('sin_no').value}</b></FormField></div>}
                            } */}
                            {/* {getField('email_address').value && <div><FormField name="email_address" type="checkbox">Black list this email <b>{getField('email_address').value}</b></FormField></div>}
                            {getField('home_phone').value && <div><FormField name="home_phone" type="checkbox">Black list this home phone <b>{getField('home_phone').value}</b></FormField></div>}
                            {getField('mobile_phone').value && <div><FormField name="mobile_phone" type="checkbox">Black list this mobile phone <b>{getField('mobile_phone').value}</b></FormField></div>}
                            {getField('license_no').value && <div><FormField name="license_no" type="checkbox">Black list this security license no. <b>{getField('license_no').value}</b></FormField></div>} */}

                            <div style={{height:"20px"}} />

                            <Row>
                                <Col flex="auto"><Button onClick={() => set_showAddToBlacklist(false)} type="default">Cancel</Button></Col>
                                <Col><Button type="primary" size="medium" htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Save </Button></Col>
                            </Row>

                            {/* <Row style={{ alignItems: "center" }}>
                                <Col flex={"auto"}><FormField name="status" type="select" size="medium" placeholder="SELECT" label="Status" data={applicationStatus} /></Col>
                                <Col style={{ marginTop: "20px" }}><Button type="primary" size="medium" htmlType='submit' loading={busy} disabled={!dirty || !valid} >Save </Button></Col>
                            </Row> */}
                        </form>
                    </>)}
                </Form>

            </>}
        </Modal>

        <HireGuardProcess 
            application_id={data && data._id}
            showDrawer={showAddguard != false}
            addGuardData={showAddguard}
            onClose={onHiringProcessComplete}
            // onClose={(res) => set_showAddguard(false)}
        />

    </>)
}

export const FormsDataView = withApollo(FormsDataViewComp);
export default FormsDataView;