import React, { useState, useEffect } from 'react'
import { Popover, Row, Col, Tooltip, Upload, message } from 'antd';
import { Avatar, Button, DataRow, Icon, IconButton, Loader } from 'Common/components';
// import { mongoToDate } from 'Common/scripts/Functions';
import StatusUpdateForm from './StatusUpdateForm';
import { defaultDateFormat } from 'configs';
import moment from 'moment';
import ImgCrop from 'antd-img-crop';
import { __error } from 'Common/scripts/consoleHelper'
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { SMSFormModal } from 'Modules/sms/components/sms_form';
// import { sleep } from 'Common/scripts/Functions';

const UPDATE_AVATAR = loader('src/Graphql/guard/updateAvatar.graphql');


const AvatarEditorComp = ({ data, alt, onUpdate, client }) => {
    const [fileList, setFileList] = useState([]);
    const [src, setSrc] = useState(null);
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        if (data.guard_avatar) setSrc(data.guard_avatar)
    }, [data])

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const getSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };

    const startUpload = async (file) => {
        setFileList([file])
        setBusy(true);

        let fileObj = await getSrcFromFile(file);

        const results = await client.mutate({ mutation: UPDATE_AVATAR, variables: { id: data._id, avatar: fileObj } }).then(e => {
            return e.data.updateAvatar;
        }).catch(err => {
            console.log(__error("Request Error: "), err);
            return { error: { message: "Request ERROR" } }
        })
        setBusy(false);
        if (results.error) {
            message.error(results.error.message);
            return false;
        }

        setSrc(fileObj);
    }


     return(<>
        {/* <ImgCrop rotate>
            <Upload disabled={busy}
                beforeUpload={(file) => {
                    startUpload(file)
                    return false;
                }}
                onRemove={(file) => {
                    setFileList([])
                }}
                showUploadList={false}
                action={false} //"https://www.mocky.io/v2/5cc8019d300000980a055e76"
                // listType="picture-card"
                fileList={fileList}
                defaultFileList={[]}
                // onChange={console.log}
                // onPreview={onPreview}
            >
                <Loader loading={busy}><Avatar src={src} size={100} onEditClick={console.log} alt={alt} /></Loader>
            </Upload>
        </ImgCrop> */}


         <span style={{ position: "relative", display: "inline-block" }}>
            <Loader loading={busy}><Avatar src={src} size={100} alt={alt} /></Loader>
            <ImgCrop rotate>
                <Upload disabled={busy}
                    beforeUpload={(file) => {
                        startUpload(file);
                        return false;
                    }}
                    onRemove={(file) => setFileList([])}
                    showUploadList={false}
                    action={false} //"https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    // listType="picture-card"
                    fileList={fileList}
                    defaultFileList={[]}
                    accept=".jpg,.png,.pdf"
                    // onChange={console.log}
                    // onPreview={onPreview}
                >
                    <IconButton style={{ position: "absolute", top: 0, right: 0 }} icon="pen" />
                </Upload>
            </ImgCrop>
         </span>

    </>)
}
const AvatarEditor = withApollo(AvatarEditorComp);

const UserAvatar = ({ data, onUpdate }) => {
    const [edit, set_edit] = useState(false);
    const [editStatus, set_editStatus] = useState(false);
    // const [fileList, setFileList] = useState([]);
    // const [busy, setBusy] = useState(false);

    let name = `${data.f_name || ""} ${data.m_name || ""} ${data.l_name || ""}`;

    let buttonColor = 'grey';
    if (data.status == 'terminated') buttonColor = 'red';
    if (data.status == 'active') buttonColor = 'green';
    if (data.status == 'in-active' || data.status == 'suspended' || data.status == 'resigned' || data.status == 'on-leave') buttonColor = 'grey';

    return (<>
        <Row style={{ flexWrap: "nowrap" }} gutter={[12]} align="middle">
            <Col>
                <AvatarEditor data={data} onUpdate={onUpdate} alt={name} />
                {/* <ImgCrop rotate>
                    <Upload disabled={busy}
                        beforeUpload={(file) => {
                            setFileList([file]);
                            setBusy(true)
                            return false;
                        }}
                        onRemove={(file) => {
                            setFileList([])
                        }}
                        showUploadList = {false}
                        action={false} //"https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        // listType="picture-card"
                        fileList={fileList}
                        defaultFileList={[]}
                        onChange={console.log}
                        // onPreview={onPreview}
                    >
                        <Loader loading={busy}><Avatar src={data.guard_avatar} size={100} onEditClick={console.log} alt={name} /></Loader>
                    </Upload>
                </ImgCrop> */}
            </Col>
            <Col flex="auto">
                <div style={{ fontSize: "18px", fontWeight: "bold" }}>{name}</div>
                {/* <DataRow col1={<Icon icon="phone" />} col2={data.phone} inline size={['30px']} /> */}
                <DataRow col1={<Icon icon="phone" />} col2={<SMSFormModal phone={data.phone} name={name} />} inline size={['30px']} />
                <DataRow col1={<span style={{ backgroundColor: "green", borderRadius: "50px", color: "white", padding: "1px 2px", fontWeight: "bold" }}>W</span>} col2={data.whatsapp} inline size={['30px']} />
                <div>
                    <Tooltip title={data.status_notes}>
                        <Button onClick={() => set_editStatus(true)} color={buttonColor} shape="round" size="small">{data.status}</Button>
                    </Tooltip>
                    {/* <Popover content={<pre>{data.status_notes}</pre>} title={false}>
                        <Button onClick={() => set_editStatus(true)} color={buttonColor} shape="round" size="small">{data.status}</Button>
                    </Popover> */}
                    {data.status_update_date && <span> {moment(data.status_update_date).format(defaultDateFormat)}</span>}
                </div>
                <div>{data.last_date_of_work && <span>Last working day: {moment(data.last_date_of_work).format(defaultDateFormat)}</span>}</div>
            </Col>
        </Row>
        {/* {(data.status == 'terminated' && data.last_date_of_work) &&
            <DataRow col1='Last working day' col2={moment(data.last_date_of_work).format(defaultDateFormat)} inline size={['142px']} />
        } */}
        {/* <div>{data.last_date_of_work && <span>Last working day: {moment(data.last_date_of_work).format(defaultDateFormat)}</span>}</div> */}



        <StatusUpdateForm data={data} showForm={editStatus} onUpdate={onUpdate} onCloseClick={() => set_editStatus(false)} />
        {/* <AvatarEditor data={data} visible={edit} onCancel={() => set_edit(!edit)} /> */}

    </>)
}
export default UserAvatar;