import React, { useState } from 'react'
import moment from 'moment';
import { Button, DataRow, Heading, IconButton, ClientSearch } from 'Common/components';
import { defaultDateFormat, siteStatusArray, site_types } from 'configs';
import { Alert, Col, Row, Space, Tag, message } from 'antd';
import { verifyRole } from 'Common/scripts/Security';
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { dateToMongo, removeSpecialChar } from 'Common/scripts/Functions';

const EDIT_SITE = loader('src/Graphql/site/editSite.graphql');


const BasicProfileForm = ({ client, data, onCloseClick, onUpdate, initialData }) => {
    const [error, setError] = useState(null);

    const onSubmitSite = async (values) => {
        if (!verifyRole('site.update')) return;
        setError(null);

        if (!values?.client?._id) {
            alert("Please select a client");
            return false;
        }

        const input = {
            _id: data._id,
            name: String(values.name).trim(),
            address: values.address,
            site_type: values.site_type,
            client_id: values?.client?._id,
            note: values.note,
            status: values.status,
        }

        let resutls = await client.mutate({ mutation: EDIT_SITE, variables: { input } }).then(r => (r.data.editSite))
            .catch(err => {
                console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
                return { error: { message: "Failed to process the request!" } }
            })

        if (resutls.error) {
            setError(resutls.error.message);
            return;
        }
        message.success("Site Updated!");
        if (onUpdate) onUpdate(resutls)
        onCloseClick()

    }

    const filterString = (e, onChange) => onChange(removeSpecialChar(e.target.value, { filter:{ trim:true } }));

    return (<>
        <FormComponent onSubmit={onSubmitSite} id='SiteBasicProfileForm' fields={{ ...data, }} debug={false}
            style={{ padding: 0, margin: 0 }}

            mutators={{
                selectClient: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    // let drivers = state.formState.values.drivers || [];
                    // drivers.push({ _id: node.value, name: node.children })
                    let _client = { _id: node.value, name: node.children };

                    tools.changeValue(state, 'client', () => _client);
                },
                de_selectClient: (newValueArray, state, tools) => {
                    // let drivers = state.formState.values.drivers || [];
                    // let drivers_ids = state.formState.values.drivers_ids || [];
                    // drivers = drivers.filter(o => (drivers_ids.includes(o._id)))

                    tools.changeValue(state, 'client', () => undefined)
                },
            }}

            form_render={formProps => {
                const { handleSubmit, submitting, values, form, invalid, dirty, valid } = formProps;

                return (<>
                    <p style={{ fontSize: "22px" }}>Editing site info</p>
                    {error && <Alert message="Error" description={error} type="error" showIcon />}

                    <Row>
                        <Col flex="auto" />
                        <Col flex="120px"><FormField label="Status" name="status" data={siteStatusArray} type="select" validate={rules.required} /></Col>
                    </Row>
                    <FormField label="Site Name" onChange={filterString} name="name" type="text" validate={rules.required} />
                    <FormField label="Site Address" name="address" type="text" validate={rules.required} />

                    <FormField allowClear validate={rules.required} label="Site Type" name="site_type" type="select" placeholder="Site Type" showSearch
                        optionFilterProp="children"
                        data={site_types}
                    />

                    <ClientSearch label="Client" name='client._id'
                        onSelect={(txt, node) => form.mutators.selectClient(node)}
                        onDeselect={(txt, node) => form.mutators.de_selectClient(node)}
                        // defaultValues={values.client ? values.client.map(o => ({ _id: o._id, title: o.name })) : []}
                        defaultValues={!values.client ? null : [{ _id: values.client._id, title: values.client.name }]}
                    />

                    <FormField name="note" size="medium" type="textarea" label="Notes" />

                    <div style={{ height: "20px" }} />
                    <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} loading={submitting} >Update</Button>

                </>)
            }}
        />

    </>)
}

const BasicBillingInfoForm = ({ client, data, onUpdate, initialData }) => {
    const [error, setError] = useState(null);

    const onSubmitSite = async (values) => {
        if (!verifyRole('site.bill')) return;
        setError(null);

        const input = {
            _id: data._id,
            name: values.name,
            address: values.address,
            site_type: values.site_type,
            client_id: values.client._id,
            billing_name: values.billing_name,
            billing_email: values.billing_email,
            billing_phone: values.billing_phone,
            billing_add: values.billing_add,
        }

        let resutls = await client.mutate({ mutation: EDIT_SITE, variables: { input } }).then(r => (r.data.editSite))
            .catch(err => {
                console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
                return { error: { message: "Failed to process the request!" } }
            })

        if (resutls.error) {
            setError(resutls.error.message);
            return;
        }
        message.success("Site Billing info Updated!");
        if (onUpdate) onUpdate(resutls)
    }

    return (<>
        <FormComponent onSubmit={onSubmitSite} id='SiteBasicProfileForm' fields={{ ...data, }} debug={false}
            style={{ padding: 0, margin: 0 }}

            mutators={{
                selectClient: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    // let drivers = state.formState.values.drivers || [];
                    // drivers.push({ _id: node.value, name: node.children })
                    let _client = { _id: node.value, name: node.children };

                    tools.changeValue(state, 'client', () => _client);
                },
                de_selectClient: (newValueArray, state, tools) => {
                    // let drivers = state.formState.values.drivers || [];
                    // let drivers_ids = state.formState.values.drivers_ids || [];
                    // drivers = drivers.filter(o => (drivers_ids.includes(o._id)))

                    tools.changeValue(state, 'client', () => undefined)
                },
            }}

            form_render={formProps => {
                const { handleSubmit, submitting, values, form, invalid, dirty, valid } = formProps;

                return (<>
                    <p style={{ fontSize: "22px" }}>Editing billing info</p>
                    {error && <Alert message="Error" description={error} type="error" showIcon />}

                    <FormField label="Billing Name" name="billing_name" type="text" />
                    <FormField label="Billing Email" name="billing_email" type="text" />
                    <FormField label="Billing Phone" name="billing_phone" type="text" />
                    <FormField label="Billing Address" name="billing_add" type="text" />

                    <div style={{ height: "20px" }} />
                    <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} loading={submitting} >Update</Button>

                </>)
            }}
        />

    </>)
}


const BasicProfile = ({ data, onUpdate, client }) => {
    const [edit, set_edit] = useState(false);
    const [editBilling, set_editBilling] = useState(false);

    const renderReadOnly = () => {
        return (<>
            <Tag color={data.status == 'active' ? '#87d068' : '#F00'}>{data.status}</Tag>
            <DataRow col1={'Address'} col2={data.address} inline size={['70px']} />
            <DataRow col1={'Type'} col2={data.site_type} inline size={['70px']} />
            <DataRow col1={'Client'} col2={data?.client?.name} inline size={['70px']} />
            {data.note && <DataRow col1={'Notes'} col2={data.note} vertical inline size={['70px']} />}
        </>)
    }

    const _onUpdate = (val) => {
        set_editBilling(false);
        onUpdate(val)
    }


    return (<>
        <div style={{ fontSize: "18px", fontWeight: "bold" }}>{data.name}</div>
        <Row align="top" gutter={[25]}>
            <Col flex="auto"><hr /></Col>
            <Col><IconButton onClick={() => set_edit(!edit)} style={{ position: "absolute", top: 0, right: 0 }} icon={edit ? "times" : "pen"} /></Col>
        </Row>

        {edit && <BasicProfileForm client={client} data={data} onUpdate={onUpdate} onCloseClick={() => set_edit(false)} />}
        {!edit && renderReadOnly()}
        
        {(verifyRole("site.bill")) && <>
            <Heading>Billing info <IconButton onClick={() => set_editBilling(!editBilling)} icon={editBilling ? "times" : "pen"} /></Heading>
            {!editBilling && <>
                <DataRow col1={'Billing Name'} col2={data.billing_name} inline size={['120px']} />
                <DataRow col1={'Billing Email'} col2={data.billing_email} inline size={['120px']} />
                <DataRow col1={'Billing Phone'} col2={data.billing_phone} inline size={['120px']} />
                <DataRow col1={'Billing Address'} col2={data.billing_add} inline size={['120px']} />
            </>}
        </>}
        {editBilling && <BasicBillingInfoForm client={client} data={data} onUpdate={_onUpdate} />}
    </>)
}

export default withApollo(BasicProfile);