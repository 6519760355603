import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
import debounce from 'lodash/debounce';
import { gql } from '@apollo/client';
import { FormField } from './Form'
import { __error } from 'Common/scripts/consoleHelper'
import { withApollo } from '@apollo/client/react/hoc';
import _ from 'lodash'

// const SITES = loader('src/Graphql/site/sites.graphql');
const SITES = gql`
    query sites($filter:String, $others:String){
        sites(filter:$filter, others:$others){
            _id
            name
            services{
                _id
                # title
                rate
                contract
                contract_exp_date

                service_type {
                    _id
                    title
                    key
                    values
                }
            }
            error { message }
        }
    }   
    `


const SiteSearchComp = props => {
    const [list, setList] = React.useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = React.useState({});
    const [changer, setChanged] = React.useState("");

    useEffect(() => {
        let changer_val = JSON.stringify([props.preload, props.filter]);
        if (changer_val == changer) return;
        
        setChanged(changer_val)
        _fetchData(props.preload === true ? undefined : props.preload);
        
        // if (props.preload && (!list || list.length < 1)) _fetchData(props.preload === true ? undefined : props.preload)
        //   return () => { effect };
    }, [props.preload, props.filter])

    // var lastFetchId = 0; 

    const _fetchData = async (value) => {
        let filter = { };

        console.log("SiteSearchComp > _fetchData() ", {preload: props.preload, fitler: props.filter})

        if (value === false || !_.isString(value)) {
            setState({ loading: true })
            if (!_.isString(value)) Object.assign(filter, { ...value })
        } else {
            if (!value || value.length < 1) return;
            setState({ kw: value || undefined, loading: true })
            Object.assign(filter, { search: { keywords: value } })
        }

        // lastFetchId += 1;
        // const fetchId = lastFetchId;
        filter = JSON.stringify({ ...filter, ...props.filter }); // JSON.stringify({ search: { keywords: value }, ...props.filter });
        console.log("filter: ", filter)
    

        const results = await props.client.query({ query: SITES, variables: { filter } }).then(e => {
            if (e.error || e.errors) {
                console.log(__error("ERROR "), e);
                return { error: { message: (e.error && e.error.message) || (e.errors && e.errors[0].message) }}
                // message.error((e.error && e.error.message) || (e.errors && e.errors[0].message));
                // setState({ kw: value, loading: false })
                // return;
            }

            return e.data.sites;

            // if (e.data.sites) setList(e.data.sites.map(o=>({ _id:o._id, title:o.name })) )
            // setState({ loading: false })

        }).catch(err => {
            console.log(__error("API Call ERROR: sites : "), err);
            return { error: { message:"Request ERROR" } }
            // message.error("Request ERROR");
            // setState({ loading: false })
        })

        setState({ loading: false })
        if (results.error){
            message.error(results.error.message);
            return;
        }

        setList(results.map(o => ({ 
            _id: o._id, 
            title: o.name, 
            raw: o
        })))


    }
    const fetchData = debounce(_fetchData, 800);


    // const onSelect = (value, node) => {
    //     let raw = _.cloneDeep(list.find(o => o._id == value)?.raw);
    //     delete raw.__typename;
    //     delete raw.error;

    //     if (props.onSelect) props.onSelect(value, node, raw);
    // }


    return (<>
        <FormField 
            compact={props.compact}
            disabled={props.disabled}
            width={props.width}
            allowClear={props.allowClear}
            validate={props.validate}
            // type={props.multi ? 'select-multiple' : "select" }
            type={props.mode == 'multiple' ? 'select-multiple' : 'select'}
            name={props.name}
            label={props.label}
            data={list}
            placeholder="Search sites"
            addNull={props.addNull || false}
            keyMap={props.keyMap} //"value=>text"
            onSelect={props.onSelect}
            // onChange={handelChange}
            // defaultValue={props.defaultValue && props.defaultValue._id}
            inputProps={{
                // onSelect: onSelect, // console.log,
                onDeselect: props.onDeselect || undefined, // console.log,
                loading:state.loading,
                // mode: props.mode || undefined, // tags, multiple
                showSearch: true,
                defaultActiveFirstOption: false,
                showArrow: true,
                notFoundContent: state.loading ? <Spin size="small" /> : null,
                filterOption: false,
                onSearch: fetchData,
                // onChange:handelChange,
            }}
        />
    </>)
}
SiteSearchComp.propTypes = {
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValues: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    preload: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    filter: PropTypes.object,
    compact: PropTypes.bool,
    allowClear: PropTypes.bool,
}

export const SiteSearch = withApollo(SiteSearchComp);
export default SiteSearch;// withApollo(SiteSearch);