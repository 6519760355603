import React, { Component, useState, useEffect } from 'react'
import { loader } from 'graphql.macro';
import { Popover, Alert, message, Row, Col, Breadcrumb, Tooltip, Card } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Loader, Icon, Tabs, DevBlock, InfoPages } from 'Common/components';
import { ContentArea } from "Layout_v1";
import { __error } from 'Common/scripts/consoleHelper'
import { uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import BasicProfile from './BasicProfile';
import SiteServicesDetails from './SiteServicesDetails';

import SiteJds from './SiteJds'
// import SiteFacilities from './SiteFacilities'
// import SiteContacts from './SiteContacts'
import SiteInfo from './SiteInfo'

import { Link } from 'react-router-dom';
import AllReports from 'Modules/FormsData/containers/all_reports';
import { useLazyQuery } from '@apollo/client';
import KnowledgeBaseList from 'Modules/Knowledgebases/components/list'

const DETAIL = loader('src/Graphql/site/site.graphql');
const ALL_PAIRS = loader('src/Graphql/value_pairs/valuePairs.graphql');

// const defaultTabs = [
//     { key: "site-reports", title: "Site Reports" },
//     { key: "site-jds", title: "Site JDs" },
// ]

const SiteKnowledgeBase = ({ filter }) => {
    return (<>
        <KnowledgeBaseList filter={filter} mini />
        {/* <DevBlock obj={filter} /> */}
    </>)
}

const tabsArray = [
    {
        "key": "site_reports",
        "title": "Site Reports"
    },
    {
        "key": "job_duties",
        "title": "Job Duties"
    }
]

const DetailsComp = ({ data, onUpdate, site_id }) => {
    const [activeTab, set_activeTab] = useState("site-reports");
    const [error, setError] = useState(false);
    // const [tabsArray, setTabsArray] = useState([]);

    const [valuePairs, { called, loading, data: valuePairs_data }] = useLazyQuery(
        ALL_PAIRS, { variables: { filter: JSON.stringify({ key: 'site_tabs' }), others: JSON.stringify({}) } }
    );

    // useEffect(() => {
    //     if (called || loading) return;
    //     fetchTabs()
    // }, [])

    // const fetchTabs = async () => {
    //     let resutls = await valuePairs({ 
    //         variables:{
    //             filter: JSON.stringify({ key: 'site_tabs' }), 
    //             others: JSON.stringify({})
    //         }
    //     }).then(r => (r?.data?.valuePairs))
        
    //     if (!resutls || resutls.error) {
    //         setError((resutls && resutls?.error?.message) || "Tabs not loaded")
    //         return;
    //     }

    //     // console.log("resutls:", resutls)
    //     let _tabsArray = tabsArray.slice();
    //     _tabsArray = _tabsArray.concat(resutls.map(o => ({ key: o.value_key, title: o.title })))
    //     setTabsArray(_tabsArray)
    //     set_activeTab(_tabsArray[0].key)
    // }

    return (<>
        <div style={{ minWidth: "1000px" }}>

            {loading && <Loader loading={true} />}

            <Row style={{ flexWrap: "nowrap" }} gutter={[12]}>
                <Col flex="400px">
                    <div className='preview_card' style={{ marginTop:"-7px" }}>
                        <BasicProfile data={data} onUpdate={onUpdate} />
                    </div>
                    <div style={{ height: "12px" }} />

                    {/* <div className='preview_card'>
                        <Heading>Site picture gallery</Heading>
                    </div>
                    <div style={{ height: "12px" }} /> */}

                    <div className='preview_card'>
                        <SiteServicesDetails data={data} onUpdate={onUpdate} />
                    </div>
                </Col>

                <Col flex="auto">
                    <InfoPages 
                        type="tabs" 
                        parent_id={site_id} 
                        category={'site_pages'}
                        componentsList={{ 
                            SiteReports: () => <AllReports inline match={{ params: { site_id }}} />, 
                            SiteKnowledgeBase: () => <SiteKnowledgeBase filter={{ "site._id": site_id }} />
                        }}
                    />
                    <hr />


                    <Tabs loading={loading}
                        tabsArray={tabsArray}
                        activeTab={activeTab}
                        onChange={set_activeTab}
                        size="large"
                    />

                    <div className='preview_card' style={{ marginTop: "-7px" }}>
                        {activeTab == "site_reports" && <><AllReports match={{ params: { site_id } }} inline /></>}
                        {/* {activeTab == "site-jds" && <><SiteJds site_id={site_id} /></>} */}
                        {activeTab == "job_duties" && <><SiteJds site_id={site_id} /></>}
                        
                        {/* {activeTab == "facilities" && <><SiteFacilities site_id={site_id} /></>} */}
                        {/* {activeTab == "contacts" && <><SiteContacts site_id={site_id} /></>} */}
                        {activeTab == "site_info" && <><SiteInfo site_id={site_id} /></>}
                    </div>

                    {/* <div className='preview_card'><Availability data={data} onUpdate={onUpdate} /></div> */}
                    <div style={{ height: "12px" }} />
                    { }
                </Col>
            </Row>
        </div>

    </>)
}

class DetailCompWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, data: null, error: null, showTerminationProcess: false };
        this.onUpdate = this.onUpdate.bind(this)
    }

    componentDidMount(props) {
        if (!this.props.match.params.id) {
            return this.setState({ error: "Missing account ID" })
        }

        this.setState({ loading: true })

        this.setState({ loading: true })
        this.props.client.query({ query: DETAIL, variables: { id: this.props.match.params.id } }).then(r => {
            if (r.data.error) {
                this.setState({ loading: false, error: r.data.error.message });
                return;
            }
            this.setState({ loading: false, data: r.data.site });

        }).catch(err => {
            console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
            this.setState({ busy: false, error: "Unable to process request at the moment!" });
        })

    }

    onUpdate(values) {
        this.setState({ data: values })
    }

    render() {
        const { inline } = this.props;
        const { loading, data, error } = this.state;

        if (loading) return <Loader loading={true} />;
        if (error) return <Alert message="Error" description={error} type="error" showIcon />;
        if (!data) return <p>No data found</p>;

        if (inline) return <DetailsComp site_id={this.props.match.params.id} onUpdate={this.onUpdate} data={data} />

        return (<>
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                {/* <Breadcrumb.Item href="/sites"><span>Sites</span></Breadcrumb.Item> */}
                {/* <Breadcrumb.Item onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }} href="#"><span><Icon icon="user-shield" size="1x" /> Sites</span></Breadcrumb.Item> */}
                <Breadcrumb.Item><Link to={`${uriRoot}/sites`}>Sites</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Site details</Breadcrumb.Item>
            </Breadcrumb>

            <ContentArea transparent>
                <DetailsComp site_id={this.props.match.params.id} onUpdate={this.onUpdate} data={data} />
            </ContentArea>
        </>)

    }
}




export const Detail = withApollo(DetailCompWrapper)
export default Detail

