import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { FormField, FormComponent, rules } from 'Common/components/Form'
import { Popconfirm, Alert, message, Row, Col, Upload, Avatar, Space } from 'antd';
import { Table, Loader, DevBlock, Icon, IconButton, Tabs } from 'Common/components';
import { Button } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import { connect } from "react-redux";
import moment from 'moment'
import axios from 'axios'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { withApollo } from '@apollo/client/react/hoc';
import NoteView from './components/note_view';
import { ContentArea } from 'Layout_v1';
import { sleep } from 'Common/scripts/Functions';
import { verifyRole } from 'Common/scripts/Security';

export * from './NotesFromDuplicates'


const FILE_LIMIT = 6;
const DELETE_NOTE = loader('src/Graphql/notes/deleteNotes.graphql');
const ADD_NOTE = loader('src/Graphql/notes/addNotes.graphql');
const LOAD_NOTES = loader('src/Graphql/notes/notes.graphql');

class BK_NotesComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null,
            error: null,
            busy: false,
            formShow: this.props.type=="document" ? false : true,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onDeletePress = this.onDeletePress.bind(this);

    }

    async onSubmit(values) {
        this.setState({ busy: true });

        const data = {
            rec_id: this.props.id,
            note: values.notes,
            user_id: this.props.ep_admin.user._id,
            user_email: this.props.ep_admin.user.email,
            ref_key: this.props.formkey
        }
        const results = await this.props.client.mutate({ mutation: ADD_NOTE, variables: { input: data } }).then(r => {
            const response = r.data.addNotes;
            if (!response || response.error) return { error: { message: !response ? "Unable to add notes at this time" : response.error.message } };
            return response;
            // else {
            //     const __data = this.state.data ? this.state.data.slice() : [];
            //     __data.unshift(response)
            //     this.setState({ error: response.message, busy: false, data: __data })
            //     message.success("Notes Updated!");
            //     return 'reset'
            // }

        }).catch(err => {
            console.log(__error("ERROR: "), err)
            // this.setState({ busy: false, error: "Unable to submit your request at the moment!" });
            return { error: { message:"Unable to submit your request at the moment!"}};
        })

        if (!results || results.error) {
            message.error(results?.error?.message || "Invalid Response!")
            this.setState({ busy: false, error: results?.error?.message || "Invalid Response!" });
            return results;
        }

        var upload_results = await this.handleUpload(values.fileList, results._id).then(r => {
            return r;
        }).catch(err => {
            console.log(__error("ERROR: ", err));
            return { error: { message: 'Unable to complete upload request' } }
        });

        this.setState({busy:false});

        if (upload_results && upload_results.error) {
            message.error(upload_results.error.message);
            return upload_results;
        }

        const finalResults = (upload_results && upload_results.data) ? upload_results.data : results;

        const __data = this.state.data ? this.state.data.slice() : [];
        __data.unshift(finalResults)

        this.setState({ busy: false, data: __data })
        message.success(`${this.props.type == 'document' ? 'Document' : 'Notes'} Updated!`);

        if (this.props.type == "document") this.setState({ formShow:false });

        return 'reset'

    }

    handleUpload = async (fileList, _id) => {
        if (!fileList || fileList.length < 1) return; // { error: { message: "No file to upload!!" } }
        if (fileList.length > FILE_LIMIT) return { error: { message: `You can upload max of ${FILE_LIMIT} files only!` } }
        if (!_id) return { error: { message: `Missing upload ID` } }

        // message.info("Uploading....")
        const hide = message.loading('Uploading..', 0);

        const formData = new FormData();
        formData.append('action', "notes.attachments");
        formData.append('note_id', _id);
        fileList.forEach(file => {
            formData.append('files[]', file);
        });


        // return axios.post(process.env.REACT_APP_API_URL + '/upload/job_application/attachments', formData)
        const results = await axios.post(process.env.REACT_APP_API_URL + '/note_upload/upload', formData)
            .then(({ data }) => {
                return data;
            })
            .catch(function (error) {
                console.log(JSON.stringify(error, 0, 2));
                // message.error("Upload request fail!")
                return { error: { message: "Upload request fail!" } };
            });

        hide();
        return results;
    };

    componentDidMount(props) {
        this.fetchData()
    }

    fetchData(){
        if (!this?.props?.ep_admin?.user?._id) return;

        this.setState({ loading: true })
        this.props.client.query({ query: LOAD_NOTES, variables: { filter: JSON.stringify({ rec_id: Number(this.props.id), ref_key: this.props.formkey }) } }).then(r => {
            const response = r.data.notes;

            if (!response || response.error) {
                this.setState({ loading: false, error: !response ? "Invalid response" : response.error.message });
                return;
            }
            this.setState({ loading: false, data: response });

        }).catch(err => {
            console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
            this.setState({ loading: false, error: "Unable to process request at the moment!" });
        })
    }

    async onDeletePress(id){
        const { ep_admin } = this.props;
        if (ep_admin.user.acc_type != 'super-admin') return;

        this.setState({ busy:true })
        const results = await this.props.client.mutate({ mutation: DELETE_NOTE, variables: { id } }).then(r => (r?.data?.deleteNotes))
        .catch(err => {
            console.log(__error("ERROR: "), err)
            // this.setState({ busy: false, error: "Unable to submit your request at the moment!" });
            return { error: { message: "Unable to submit your request at the moment!" } };
        })
        
        if (!results || results.error){
            message.error(results ? results.error.message : "Unable to delete record");
            this.setState({ busy: false })
            return false;
        }

        let data = this.state.data.slice()
            data = data.filter(o=>o._id!=id);
        this.setState({ data, busy:false })
    }


    render() {
        const { busy, loading, error } = this.state;
        const { ep_admin } = this.props;

        if (loading) return <Loader loading={true} />
        if (error) return <Alert message="Error" description={error} type="error" showIcon />
        if (!this?.props?.ep_admin?.user?._id) return <Alert message="Error" description={"No user found!"} type="error" showIcon />

        return (<>
            {!this.state.formShow && <Button onClick={() => this.setState({ formShow: !this.state.formShow })} style={{margin:"5px"}} size="small">Add New document</Button>}
            {this.state.formShow && <>
                <div style={{ padding: "10px" }} >
                    {this.props.title && <p className='bold' style={{ marginBottom: 0, marginLeft: "8px" }}>{this.props.title}</p>}

                    <FormComponent onSubmit={this.onSubmit} id='NoteForm' loading={busy || loading} fields={{ }} debug={false}
                        mutators={{
                            ...arrayMutators,
                            selectFiles: (newValueArray, state, tools) => {
                                let file = newValueArray[0];
                                let action = newValueArray[1];
                                let fileList = state.formState.values.fileList || []
                                if (fileList.length >= FILE_LIMIT) return;

                                if (action == 'add') fileList.push(file);
                                if (action == 'remove') fileList = fileList.filter(o => o.uid != file.uid);

                                // tools.changeValue(state, 'file_count', () => fileList.length);
                                tools.changeValue(state, 'fileList', () => fileList);
                                // // tools.changeValue(state, 'tagged_to_users', () => _client);
                            },
                            removeFiles: (newValueArray, state, tools) => {
                                tools.changeValue(state, 'fileList', () => []);
                                // // tools.changeValue(state, 'tagged_to_users', () => _client);
                            },
                        }}

                        form_render={formProps => {
                            const { values, form, invalid, submitting, dirty, valid } = formProps;

                            return (<>
                                {this.props.type != "document" && 
                                    <FormField name="notes" rows={2} compact label="Notes" type="textarea" validate={rules.required} />
                                }
                                {this.props.type =="document" && 
                                    <FormField name="notes" rows={2} compact label="Document Name" type="text" validate={rules.required} />
                                }

                                <Upload
                                    onRemove={(file) => {
                                        form.mutators.selectFiles(file, "remove");
                                    }}
                                    beforeUpload={(file) => {
                                        form.mutators.selectFiles(file, "add");
                                        return false;
                                    }}
                                    // fileList={this.state.fileList}
                                    fileList={values.fileList}
                                    accept=".jpg,.png,.pdf"
                                    multiple={true}
                                    maxCount={6}
                                    // showUploadList={false}
                                    // itemRender={(_originNode, _file, _fileList, _actions)=>{
                                    //     const { download, preview, remove } = _actions;


                                    //     return <Row key={_file.uid}>
                                    //         <Col flex="auto">{_file.name}</Col>
                                    //         <Col><IconButton onClick={() => remove(_file)} icon="trash-alt" /></Col>
                                    //     </Row>

                                    //     return _fileList.map((o,i)=>{
                                    //         return <Row key={i}>
                                    //             <Col flex="auto">{o.name}</Col>
                                    //             <Col><IconButton onClick={() => remove()} icon="trash-alt" /></Col>
                                    //         </Row>
                                    //     })

                                    //     return <>
                                    //         <Row>
                                    //             <Col flex="auto"></Col>
                                    //             <Col></Col>
                                    //         </Row>
                                    //     </>
                                    // }}
                                >

                                    <Button size='small' type="default" style={{ marginLeft: "0px" }} disabled={values?.fileList?.length >= 6 ? true : false}><Icon icon="paperclip" style={{marginRight:'10px'}} /> Select files to attach</Button>
                                </Upload>
                                <FieldArray name="fileList">{({ fields }) => null}</FieldArray>

                                <Col align="right" style={{ paddingTop: '10px' }}><Button type="primary" htmlType='submit' loading={busy || submitting} disabled={!dirty || !valid} >
                                    {this.props.type == "document" && "Add Document"}
                                    {this.props.type != "document" && "Add Your Notes"}
                                    
                                </Button></Col>

                            </>)
                        }}
                    />                
                </div>
                {(this.state.data && this.state.data.length>0) && <hr style={{margin:"20px"}} />}
            </>}

            <Space direction='vertical' style={{width:"100%"}}>
                {this.state.data?.map((item, i) => (
                    <NoteView type={this.props.type} key={i} data={item} onDeletePress={this.onDeletePress} user={ep_admin.user} />
                ))}
            </Space>

        </>)

    }
}

const NotesComp = ({ type, client, ep_admin, id, formkey, title }) => {
    const [state, setState] = useState({ 
        loading: false, 
        error: null, 
        data: null, 
        busy: false, 
        formShow: type == "document" ? false : true
    });
    const [matchData, setMatchData] = useState(null);
    
    useEffect(() => {
        if (JSON.stringify(matchData) == JSON.stringify({ id, formkey })) return;
        setMatchData({ id, formkey })
        fetchData();
    }, [id, formkey])

    const onSubmit = async (values) => {
        setState({ ...state, busy:true, error:null })

        const data = {
            rec_id: id,
            note: values.notes,
            user_id: ep_admin.user._id,
            user_email: ep_admin.user.email,
            ref_key: formkey
        }
        const results = await client.mutate({ mutation: ADD_NOTE, variables: { input: data } }).then(r => {
            const response = r.data.addNotes;
            if (!response || response.error) return { error: { message: !response ? "Unable to add notes at this time" : response.error.message } };
            return response;
        }).catch(err => {
            console.log(__error("ERROR: "), err)
            return { error: { message: "Unable to submit your request at the moment!" } };
        })

        if (!results || results.error) {
            message.error(results?.error?.message || "Invalid Response!")
            setState({ ...state, busy: false, error: results?.error?.message || "Invalid Response!" });
            return results;
        }

        var upload_results = await handleUpload(values.fileList, results._id).catch(err => {
            console.log(__error("ERROR: ", err));
            return { error: { message: 'Unable to complete upload request' } }
        });

        setState({ ...state, busy: false })

        if (upload_results && upload_results.error) {
            message.error(upload_results.error.message);
            return upload_results;
        }

        const finalResults = (upload_results && upload_results.data) ? upload_results.data : results;

        const __data = state.data ? state.data.slice() : [];
        __data.unshift(finalResults)

        setState({ ...state, busy: false, data: __data })
        message.success(`${type == 'document' ? 'Document' : 'Notes'} Updated!`);

        if (type == "document") setState({ ...state, formShow: false });

        fetchData()

        return 'reset'

    }

    const handleUpload = async (fileList, _id) => {
        if (!fileList || fileList.length < 1) return; // { error: { message: "No file to upload!!" } }
        if (fileList.length > FILE_LIMIT) return { error: { message: `You can upload max of ${FILE_LIMIT} files only!` } }
        if (!_id) return { error: { message: `Missing upload ID` } }

        // message.info("Uploading....")
        const hide = message.loading('Uploading..', 0);

        const formData = new FormData();
        formData.append('action', "notes.attachments");
        formData.append('note_id', _id);
        fileList.forEach(file => {
            formData.append('files[]', file);
        });


        // return axios.post(process.env.REACT_APP_API_URL + '/upload/job_application/attachments', formData)
        const results = await axios.post(process.env.REACT_APP_API_URL + '/note_upload/upload', formData)
            .then(({ data }) => (data))
            .catch(function (error) {
                console.log(__error("Error: "), JSON.stringify(error, 0, 2));
                // message.error("Upload request fail!")
                return { error: { message: "Upload request fail!" } };
            });

        hide();
        return results;
    };

    const fetchData = async () => {
        if (!ep_admin?.user?._id) return;

        setState({ ...state, loading: true, error: null, data: null })

        let resutls = await client.query({ query: LOAD_NOTES, variables: { filter: JSON.stringify({ rec_id: Number(id), ref_key: formkey }) } })
            .then(r => (r?.data?.notes))
            .catch(err => {
                console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
                return { error: { message: "Unable to process request at the moment!" }}
            })

        if (!resutls || resutls.error){
            setState({ ...state, loading: false, error: resutls.error || "Unable to process request at the moment!", data: resutls })
        }
        else{
            setState({ ...state, loading: false, error: null, data: resutls })
        }


    }

    const onDeletePress = async(id) => {
        if (ep_admin.user.acc_type != 'super-admin') return;
        setState({ ...state, busy: true, error:null })

        const results = await client.mutate({ mutation: DELETE_NOTE, variables: { id } }).then(r => (r?.data?.deleteNotes))
            .catch(err => {
                console.log(__error("ERROR: "), err)
                return { error: { message: "Unable to submit your request at the moment!" } };
            })

        if (!results || results.error) {
            let err = results ? results.error.message : "Unable to delete record";
            message.error(err);
            setState({ ...state, busy: false, error: err })
            return false;
        }

        let data = state.data.slice()
        data = state.data.filter(o => o._id != id);
        setState({ data, busy: false })
    }

    const { busy, loading, error, formShow, data } = state;
    
    if (!ep_admin?.user?._id) return <Alert message="Error" description={"No user found!"} type="error" showIcon />
    if (loading) return <Loader loading={true} />
    if (error) return <Alert message="Error" description={error} type="error" showIcon />

    return (<>
        {!formShow && <Button onClick={() => setState({ ...state, formShow: !formShow })} style={{ margin: "5px" }} size="small">Add New document</Button>}

        {formShow && <>
            <div style={{ padding: "10px" }} >
                {title && <p className='bold' style={{ marginBottom: 0, marginLeft: "8px" }}>{title}</p>}

                <FormComponent onSubmit={onSubmit} id='NoteForm' loading={busy || loading} fields={{}} debug={false}
                    mutators={{
                        ...arrayMutators,
                        selectFiles: (newValueArray, state, tools) => {
                            let file = newValueArray[0];
                            let action = newValueArray[1];
                            let fileList = state.formState.values.fileList || []
                            if (fileList.length >= FILE_LIMIT) return;

                            if (action == 'add') fileList.push(file);
                            if (action == 'remove') fileList = fileList.filter(o => o.uid != file.uid);

                            // tools.changeValue(state, 'file_count', () => fileList.length);
                            tools.changeValue(state, 'fileList', () => fileList);
                            // // tools.changeValue(state, 'tagged_to_users', () => _client);
                        },
                        removeFiles: (newValueArray, state, tools) => {
                            tools.changeValue(state, 'fileList', () => []);
                            // // tools.changeValue(state, 'tagged_to_users', () => _client);
                        },
                    }}

                    form_render={formProps => {
                        const { values, form, invalid, submitting, dirty, valid } = formProps;

                        return (<>
                            {type != "document" && <FormField name="notes" rows={2} compact label="Notes" type="textarea" validate={rules.required} />}
                            {type == "document" && <FormField name="notes" rows={2} compact label="Document Name" type="text" validate={rules.required} />}

                            <Upload
                                itemRender={(originNode, file, fileList, actions) => {
                                    var { download, preview, remove } = actions;

                                    return <div className='upload-list'>{originNode}</div>
                                }}
                                onRemove={(file) => form.mutators.selectFiles(file, "remove") }
                                beforeUpload={(file) => {
                                    form.mutators.selectFiles(file, "add");
                                    return false;
                                }}
                                fileList={values.fileList}
                                accept=".jpg,.png,.pdf"
                                multiple={true}
                                maxCount={6}
                            >
                                <Button size='small' type="default" style={{ marginLeft: "0px" }} disabled={values?.fileList?.length >= 6 ? true : false}><Icon icon="paperclip" style={{ marginRight: '10px' }} /> Select files to attach</Button>
                            </Upload>
                            <FieldArray name="fileList">{({ fields }) => null}</FieldArray>

                            <Col align="right" style={{ paddingTop: '10px' }}><Button type="primary" htmlType='submit' loading={busy || submitting} disabled={!dirty || !valid} >
                                {type == "document" && "Add Document"}
                                {type != "document" && "Add Your Notes"}
                            </Button></Col>
                        </>)
                    }}
                />
            </div>
            {(data && data.length > 0) && <hr style={{ margin: "20px" }} />}
        </>}

        <Space direction='vertical' style={{ width: "100%" }}>
            {data && data?.map((item, i) => (
                <NoteView type={type} key={i} data={item} onDeletePress={onDeletePress} user={ep_admin.user} />
            ))}
        </Space>

    </>)

}
NotesComp.propTypes = {
    type: PropTypes.string,
    formkey: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    title: PropTypes.string
}
// export const Notes = withApollo(NotesComp)
const mapStateToProps = ({ ep_admin }) => {
    return ({ ep_admin });
}
export const Notes = connect(mapStateToProps)(withApollo(NotesComp));
export default Notes;


export const NotesGroupComp = ({ data, types, ep_admin }) => {
    /* types: { key, title } */
    const [activeTab, set_activeTab] = useState(types[0])

    let filteredTabs = types.filter(o=>{
        if (!o.permissionsReq) return true;
        return verifyRole(o.permissionsReq, { user: ep_admin.user })
    })

    if (!filteredTabs || filteredTabs.length < 0 || !filteredTabs[0]?.key) return null;

    return (<>
        <div style={{ marginLeft: "15px" }}><Tabs onChange={(v) => set_activeTab(types.find(o => o.key == v))} tabsArray={filteredTabs.map(o=>(o))} /></div>
        <ContentArea style={{ marginTop: "0", padding: "5px" }}>
            <Notes id={data._id} formkey={activeTab.key} />
        </ContentArea>
    </>)
}
export const NotesGroup = connect(mapStateToProps)(withApollo(NotesGroupComp));