import React from 'react'
import { connect } from "react-redux";
import ChannelsList from '../../components/channels/ChannelsList';


function ChannelsHistory({ user, th_ch_history_filter }) { // match.params.id
  
  const onDeleteClick = async () => {}
  const onEditClick = async (val) => { }

  return (<>
    <ChannelsList title="Channels History" onDeleteClick={onDeleteClick} onEditClick={onEditClick} permanentFilter={{ status: { $ne: "active" } }} />
  </>)

}
const mapStateToProps = ({ ep_admin: { user, pageSettings } }) => {
  return ({ 
    user,
    th_ch_history_filter: (pageSettings && pageSettings.th_ch_history_filter) || {},
  });
}
export default connect(mapStateToProps, null)(ChannelsHistory)

