import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'; 
import { message, Row, Col, Button, Table as _Table, Popover, Grid, Alert } from 'antd';
import { mongoToDate, dateToCanada, getTimeDiff, dateToMongo } from 'Common/scripts/Functions'
import { loader } from 'graphql.macro';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { SiteSearch, GuardSearch, ValuePairsSearch, Loader, IconButton, DevBlock } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper';
import _ from 'lodash'
import { connect } from "react-redux";
import { withApollo } from '@apollo/client/react/hoc';
import { getmodulePermissions } from 'Common/scripts/Security';
import { defaultDateFormat } from 'configs';
import moment, { isMoment } from 'moment';
import LeadSearch from 'Common/components/LeadSearch';

const modulePermissions = getmodulePermissions('hours-schedule-management');

const ADD_DATA = loader('src/Graphql/HoursSchedules/addHoursSchedule.graphql');
const EDIT = loader('src/Graphql/HoursSchedules/editHoursSchedule.graphql')

function convertDate(dt) {
    // return mongoToDate(moment(dt).format("YYYY-MM-DD HH:mm"), "YYYY-MM-DD HH:mm")
    // let d1 = mongoToDate(dt).format('YYYY-MM-DD HH:mm');
    // d1 = moment(d1, 'YYYY-MM-DD HH:mm')
    // return d1;
    // return moment(moment(mongoToDate(dt)).format("YYYY-MM-DD HH:mm"), "YYYY-MM-DD HH:mm")

    return mongoToDate(dt)
}


function calculateDiff(timeArray){
    // console.log("calculateDiff: ", timeArray);
    if (!timeArray || timeArray.length<1) return ""

    let t1 = timeArray[0];
    if (isMoment(t1) && t1.isValid()) t1 = t1.startOf('minutes').format();
    
    let t2 = timeArray[1];
    if (isMoment(t2) && t2.isValid()) t2 = t2.startOf('minutes').format();

    let diff = getTimeDiff(t1, t2, "split");
    return `(${diff.hrs}:${diff.min})`

    /*
    if ((!timeArray || timeArray.length < 1) || !moment(timeArray[0])?.isValid || !moment(timeArray[1])?.isValid) return "";

    let diff = getTimeDiff(timeArray[0], timeArray[1]);
    return `(${diff.hrs}:${diff.min})`
    */
}

const ScheduleForm = ({ initialValues, ep_admin, client, onClose }) => {
    // const contacts = useSelector(state => state.schedule) 
    const [loading, setLoading] = useState(false)
    // const [initialValues, setInitialValues] = useState(null)

    // const screens = Grid.useBreakpoint();
    // console.log("screens: ", Object.entries(screens))
    
    const onSubmit = async (values) => {
        console.log("onSubmit()", values);

        var service = undefined;
        if (values?.service?._id){
            service = values.site.services.find(o => o._id == values.service._id);
        }

        let scheduled_shift_start = values.scheduled_shift[0].format();
        let scheduled_shift_end = values.scheduled_shift[1].format();
        let check_in = values.performed_shift[0].format();
        let check_out = values.performed_shift[1].format();

        const data = {
            site_id: values.site._id, 
            site_name: values.site.name,

            guard_id: values.guard._id, 
            guard_name: values.guard.name,

            scheduled_shift_start: scheduled_shift_start, // isMoment(values.scheduled_shift[0]) ? dateToMongo(values.scheduled_shift[0].startOf('minutes')) : dateToMongo(convertDate(values.scheduled_shift[0])), // dateToCanada(values.scheduled_shift[0].set({ 'second': '00', 'millisecond': '000' })),
            scheduled_shift_end: scheduled_shift_end, // isMoment(values.scheduled_shift[1]) ? dateToMongo(values.scheduled_shift[1].startOf('minutes')) : dateToMongo(convertDate(values.scheduled_shift[1])), // dateToCanada(values.scheduled_shift[0].set({ 'second': '00', 'millisecond': '000' })),
            // scheduled_shift_end: values.scheduled_shift[1], // dateToCanada(values.scheduled_shift[1].set({ 'second': '00', 'millisecond': '000' })),
            
            check_in: check_in, // isMoment(values.performed_shift[0]) ? dateToMongo(values.performed_shift[0]) : dateToMongo(convertDate(values.performed_shift[0])), // dateToCanada(values.performed_shift[0].set({ 'second': '00', 'millisecond': '000' })),
            check_out: check_out, // isMoment(values.performed_shift[1]) ? dateToMongo(values.performed_shift[1]) : dateToMongo(convertDate(values.performed_shift[1])), // dateToCanada(values.performed_shift[0].set({ 'second': '00', 'millisecond': '000' })),
            // check_out: values.performed_shift[1], // dateToCanada(values.performed_shift[1].set({ 'second': '00', 'millisecond': '000' })),
            
            comments: values.comments || null,
            stat: values.stat === true ? "yes" : "no",
            do_not_charge_client: values.do_not_charge_client === true ? "yes" : "no",
            
            // prod_and_services_id: values.prod_and_services_id,
            lead: !values.lead ? undefined : { _id: values.lead._id, title: values.lead.title },

            service: !service ? undefined : { 
                _id: service._id,
                rate: service.rate,
                contract: service.contract,
                contract_exp_date: service.contract_exp_date,
                service_type: {
                    _id: service.service_type._id,
                    title: service.service_type.title,
                    key: service.service_type.key,
                    values: service.service_type.values
                }
            },
        }
        if (initialValues && initialValues._id) Object.assign(data, { _id: initialValues._id });
        
        // check hours difference
        if (data.scheduled_shift_start != data.check_in && (!data.comments || data.comments.length < 4)){
            alert("Clock-in and shift start time is different, please provide the notes");
            return false;
        }
        if (data.scheduled_shift_end != data.check_out && (!data.comments || data.comments.length < 4)){
            alert("Clock-out and shift end time is different, please provide the notes");
            return false;
        }

        console.log("data: ", data)
        // return false;


        setLoading(true);

        let _response = false;
        //########## Update record
        if (data._id){
            if (!modulePermissions['hours-schedule.update']) return;
            Object.assign(data, { updated_by: ep_admin.user._id });

            _response = await client.mutate({ mutation: EDIT, variables: { input: data } }).then(r => {
                let response = r.data.editHoursSchedule;
                if (response.error) return response;

                message.success("Successfully Edited Schedule!");
                return response;
            }).catch(err => {
                console.log("ERROR: ", JSON.stringify(err, 0, 2));
                // message.error("Failed to Edit Schedule!");
                return { error: { message:"Failed to Edit Schedule!"}};
            })

        }else{
            //########## Add record
            if (!modulePermissions['hours-schedule.add']) return;
            Object.assign(data, { recorded_by: ep_admin.user._id });

            _response = await client.mutate({ mutation: ADD_DATA, variables: { input: data } }).then(r => {
                let response = r.data.addHoursSchedule;
                if (response.error) return response;

                message.success("Successfully Added new Schedule!");
                return response;
            }).catch(err => {
                console.log(__error("ERROR: "), err);
                // message.error("Failed to Add Schedule!");
                return { error: { message:"Failed to Add Schedule!"}};
            })

            document.getElementById('ScheduleForm').dispatchEvent(new Event('reset', { cancelable: true, bubbles:false }))
        }

        setLoading(false);
        
        if (!_response || _response.error){
            message.error(!_response ? "Invalid response" : _response.error.message);
            return false;
        }
        if (onClose) onClose()
        return 'reset';
    }



    return (<div style={{ padding: '0px', margin: '0px', backgroundColor: 'white', borderRadius: '5px' }}>
        <div style={{ fontSize: '22px', fontWeight: 'bold' }}>{(initialValues && initialValues._id) ? 'Edit' : 'Add'} Schedule</div>

        <FormComponent onSubmit={onSubmit} id='ScheduleForm' loading={false} debug={true}
            fields={initialValues}
            mutators={{
                selectGuard: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    let _client = { _id: node.value, name: node.children };
                    tools.changeValue(state, 'guard', () => _client);
                },
                de_selectGuard: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'guard', () => undefined)
                },

                selectSite: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    // let data = { _id: node.value, name: node.children };
                    tools.changeValue(state, 'site', () => node);
                    tools.changeValue(state, 'lead', () => undefined);
                    tools.changeValue(state, 'service', () => undefined);
                },
                de_selectSite: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'site', () => undefined)
                    tools.changeValue(state, 'lead', () => undefined);
                    tools.changeValue(state, 'service', () => undefined);
                },
                
                selectLead: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    let val = { _id: node.value, title: node.children };
                    tools.changeValue(state, 'lead', () => val);
                    tools.changeValue(state, 'service', () => null);
                },
                de_selectLead: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'lead', () => undefined);
                    tools.changeValue(state, 'service', () => null);
                },

                selectService: (newValueArray, state, tools) => {
                    console.log("selectService: ", newValueArray)
                    let node = newValueArray[0];
                    // let val = { _id: node.value, title: node.children };
                    tools.changeValue(state, 'service', () => node);
                    tools.changeValue(state, 'lead', () => null);
                },
                de_selectService: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'lead', () => undefined);
                    tools.changeValue(state, 'service', () => undefined);
                },

                copyScheduleDates: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    if (!node || node.length<2) return;

                    tools.changeValue(state, 'performed_shift', () => node);
                },
            }}
            form_render={formProps => {
                const { values, form, invalid, dirty, valid } = formProps;

                return (<>
                    <p>Today: {moment().startOf('minutes').format("YYYY-MM-DD HH:mm")}</p>

                    <Row gutter={[0, 0]} align="middle">
                        {(values.site && !values.site.services) && <Col span={24}><Col span={24} xl={8}><Alert message='No services assigned to this site' type='warning' showIcon /></Col></Col>}

                        <Col span={24} xl={8}>
                            <SiteSearch
                                filter={{}} //{{ duty_status:'active' }}
                                placeholder="Site Name" label="Site Name" name='site._id'
                                onSelect={(txt, node, raw) => form.mutators.selectSite(raw)}
                                onDeselect={(txt, node) => form.mutators.de_selectSite(node)}
                                defaultValues={values.site ? [{ _id: values.site._id, title: values.site.name }] : []}
                                validate={rules.required}
                            />
                        </Col>
                        <Col span={24} md={11} xl={7}>
                            <LeadSearch 
                                preload={true}
                                filter={!values?.site?._id ? { status: "open" } : { "site._id": Number(values.site._id), status: "open" }}
                                label="Sales Lead" name='lead._id'
                                onSelect={(txt, node) => form.mutators.selectLead(node)}
                                onDeselect={(txt, node) => form.mutators.de_selectLead(node)}
                                defaultValues={values.lead ? [{ _id: values.lead._id, title: values.lead.title }] : []}
                                disabled={(!values?.site || values?.service) ? true : false}
                                validate={values?.service?._id ? false : rules.required}
                            />
                        </Col>
                        <Col><div style={{ fontWeight: "bold", marginTop: "0px" }}>OR</div></Col>
                        <Col span={24} md={11} xl={7}>
                            <FormField label="Services" name="service._id" type="select" allowClear
                                data={values?.site?.services?.map((service, i) => ({ 
                                    ...service, 
                                    title: service.service_type.title,
                                    raw: service 
                                }))}
                                disabled={(values?.lead?._id || !values?.site?._id) ? true : false}
                                validate={values?.lead?._id ? false : rules.required}
                                onSelect={(txt, node, raw) => form.mutators.selectService(raw)}
                                onDeselect={(txt, node, raw) => form.mutators.de_selectService(raw)}
                            />
                        </Col>

                        <Col span={24} />

                        <Col span={24} xl={8}>
                            <GuardSearch
                                filter={{ status: 'active' }}
                                placeholder="Guard Name" name='guard_id'
                                label="Guard Name"
                                onSelect={(txt, node) => form.mutators.selectGuard(node)}
                                onDeselect={(txt, node) => form.mutators.de_selectGuard(node)}
                                defaultValues={values.guard ? [{ _id: values.guard._id, title: values.guard.name }] : []}
                                validate={rules.required}
                            />
                        </Col>
                        <Col span={24} md={11} xl={7}>
                            <FormField returnAs="moment"
                                // label={`Scheduled Shift ${calculateDiff(values.scheduled_shift ? [values.scheduled_shift[0].set('seconds', 0), values.scheduled_shift[1].set('seconds', 0)] : undefined)}`}
                                label={`Scheduled Shift ${calculateDiff(values.scheduled_shift)}`}
                                placeholder={["Schedule starts at", "Schedule end at"]}
                                showTime={true} format={`${defaultDateFormat} HH:mm`}
                                name="scheduled_shift" size="medium" type="date-range" validate={rules.required}
                            />
                            {/* <Row gutter={[10]}>
                                <Col>{(values.scheduled_shift && values.scheduled_shift[0]) && isMoment(values.scheduled_shift[0]) ? values.scheduled_shift[0]?.format() : values.scheduled_shift[0]}</Col>
                                <Col>{(values.scheduled_shift && values.scheduled_shift[1]) && isMoment(values.scheduled_shift[1]) ? values.scheduled_shift[1]?.format() : values.scheduled_shift[1]}</Col>
                            </Row> */}
                        </Col>
                        <Col><IconButton style={{ marginTop: '0px' }} icon="angle-double-right" onClick={() => form.mutators.copyScheduleDates(values.scheduled_shift)} /></Col>
                        <Col span={24} md={11} xl={7}>
                            <FormField returnAs="moment"
                                disabled={!values.scheduled_shift}
                                label={`Performed Shift ${calculateDiff(values.performed_shift)}`}
                                // label={`Scheduled Shift ${calculateDiff(values.scheduled_shift ? [values.scheduled_shift[0].set('seconds', 0), values.scheduled_shift[1].set('seconds', 0)] : undefined)}`}
                                placeholder={["Shift starts at", "Shift end at"]}
                                showTime={true} format={`${defaultDateFormat} HH:mm`}
                                name="performed_shift" size="medium" type="date-range" validate={rules.required}
                            />
                            {/* <Row gutter={[10]}>
                                <Col>{(values.performed_shift && values.performed_shift[0]) && isMoment(values.performed_shift[0]) ? values.performed_shift[0]?.format() : values.performed_shift[0]}</Col>
                                <Col>{(values.performed_shift && values.performed_shift[1]) && isMoment(values.performed_shift[1]) ? values.performed_shift[1]?.format() : values.performed_shift[1]}</Col>
                            </Row> */}
                        </Col>
                    </Row>

                    <Row style={{ margin: "0 10px" }}>
                        <Col><FormField style={{ marginTop: '10px' }} name="stat" compact type="checkbox">Stat holiday</FormField></Col>
                        <Col><FormField name="do_not_charge_client" compact type="checkbox">Do not charge client</FormField></Col>
                    </Row>

                    <FormField name="comments" label="Notes" allowClear size="medium" rows={2} type="textarea" placeholder={"Explain in detail if there is a difference between scheduled & perfomred hours."} style={{ marginTop: '5px' }} />

                    <Col align="right">
                        <Button style={{ marginTop: "18px", marginRight: '10px' }} type="primary" size="medium" loading={loading} htmlType='submit' disabled={!dirty || !valid} >{initialValues ? "Update Schedule" : "Add Schedule"}</Button>
                    </Col>

                </>)
            }}
        />

    </div>)

}
ScheduleForm.propTypes = {
    editNode: PropTypes.object,
}
const mapStateToProps = ({ ep_admin }, { editNode }) => {
    var initialValues;
    if (editNode){
        initialValues = {
            ...editNode,
            // scheduled_shift: editNode ? [mongoToDate(editNode.scheduled_shift_start).startOf('minutes'), mongoToDate(editNode.scheduled_shift_end).startOf('minutes')] : null,
            // scheduled_shift: editNode ? [convertDate(editNode.scheduled_shift_start), convertDate(editNode.scheduled_shift_end)] : null,
            scheduled_shift: editNode ? [convertDate(editNode.scheduled_shift_start), convertDate(editNode.scheduled_shift_end)] : null,
            site: editNode?.site, // ? { name: editNode.site.name, _id: editNode.site._id } : undefined,
            site_id: editNode?.site?._id,
            guard_id: editNode?.guard?._id,
            // performed_shift: editNode ? [mongoToDate(editNode.check_in).startOf('minutes'), mongoToDate(editNode.check_out).startOf('minutes')] : null,
            // performed_shift: editNode ? [convertDate(editNode.check_in), convertDate(editNode.check_out)] : null,
            performed_shift: editNode ? [convertDate(editNode.check_in), convertDate(editNode.check_out)] : null,
            do_not_charge_client: editNode && editNode.do_not_charge_client == 'yes' ? true : false,
            stat: editNode && editNode.stat == 'yes' ? true : false,
        }

        if (editNode?.guard) {
            Object.assign(initialValues, {
                guard: {
                    _id: editNode?.guard?._id,
                    name: editNode?.guard?.name || `${editNode?.guard?.f_name || ""} ${editNode?.guard?.m_name || ""} ${editNode?.guard?.l_name || ""}`
                }
            })
        }
    }


    return ({ ep_admin, initialValues });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export default connect(mapStateToProps)(withApollo(ScheduleForm));
