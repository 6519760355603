import React, { useState } from 'react'
import { getmodulePermissions } from 'Common/scripts/Security';
import ScheduledHours from './ScheduledHours';
import { ListHeader } from 'Common/components/Typography';


// const modulePermissions = getmodulePermissions('hours-schedule-management');

const ScheduledHours_Hiligts = props => {
    const activeFilter = [
        {
            "group_key": "schedule_info",
            "item": {
                "title": "Check-In Date",
                "field": "check_in",
                "type": "date"
            },
            "filter": {
                "option": "farmula",
                "check_in": {
                    "farmula": "previous_90_days"
                }
            }
        },
        { approval_status: { $nin: ['aproved'] } }
    ]

    return (<>
        {(activeFilter && activeFilter.length > 0) && <>
            <div style={{ padding:"10px 0 0 10px" }}><ListHeader title="Schedule Hilights" /></div>
            <ScheduledHours
                // filter={this.state.filter}
            tableProps={{ scroll: { y: 300 }, style: { margin: "10px 10px 0 10px", borderRadius: "5px", overflow: "hidden", border: "1px solid white" } }}
                activeFilter={activeFilter}
            />
        </>
        }
    </>)
}
export default ScheduledHours_Hiligts;