import React from 'react';
import { addModule, addReducers } from '../connector';
import TestFormsList from './TestFormCreator/TestFormsList';
import { TestFormDrawer } from './TestFormCreator/TestForm';
import FormsResultList from './FormsResultList';
import InvitationsList from './InvitationsList';
import DataPreview from './Forms/DataPreview';
import Home from './Home';
import Courses from './Courses/course_list';
import CoursesEditor from './Courses/CoursesEditor';
import { getmodulePermissions } from 'Common/scripts/Security';
import { uriRoot } from 'configs';
import { ROOT } from './constants';


const modulePermissions = getmodulePermissions('course-mg');

var subMenuArray = [
  { path: `${uriRoot}${ROOT}/result`, exact: true, title: 'Results', component: FormsResultList },
  { path: `${uriRoot}${ROOT}/invitations`, exact: true, title: 'Test Invitations', component: InvitationsList },
  { path: `${uriRoot}${ROOT}/view/:id`, component: DataPreview, exact: true, leftNav: false },
  { path: `${uriRoot}${ROOT}/tests`, exact: true, title: 'Tests', component: TestFormsList },
  { path: `${uriRoot}${ROOT}/testForm/`, exact: true, component: TestFormDrawer, leftNav: false },
  // { path: `${ROOT}/forms`, exact: true, title: 'Forms', component: TestFormsList },
  // { path: `${ROOT}/attendance`, component: Attendance, exact: true, leftNav: false },
]

if (modulePermissions["course-mg.add"]) {
  subMenuArray.push({ path: `${uriRoot}${ROOT}/courses`, title: 'Courses', component: Courses, leftNav: true, exact: true});
  subMenuArray.push({ path: `${uriRoot}${ROOT}/courses/id/:id`, title: 'Courses', component: CoursesEditor, leftNav: false, exact: true });

  // addModule(
  //   { path: `/courses`, component: Courses, icon: 'graduation-cap', title: "Courses", exact: 1, leftNav: true },
  // )
  // addModule(
  //   { path: `/courses/id/:id`, component: CoursesEditor, icon: 'graduation-cap', title: "Courses", exact: 1, leftNav: false },
  // )
}


export const SubMenu = props => {
  return subMenuArray;
}

if (modulePermissions["course-mg.access-module"]) {
    // addReducers({ admins: reducers });
    addModule(
      {
        path: `${ROOT}`, component: Home, icon: 'clipboard-list', title: "Test Manager", exact: false, leftNav: true,
        subMenu: SubMenu()
      },
    )

}


// const Temp1 = props => {
//   return <p>Temp1</p>
// }

// addModule(
//   {
//     path: `/test-results`, component: Temp1, icon: 'clipboard-list', title: "Test Manager", exact: 0, leftNav: true,
//     // subMenu: SubMenu()
//   },
// )
