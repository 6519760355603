import React, { Component } from 'react'
import { E404 } from "Layout_v1";
import { formArray } from '../'

export default class InputForm extends Component {
  render() {
    const FormFound = formArray.find(o => o.key == this.props.match.params.formType);

    return FormFound ? <FormFound.component {...this.props} /> : <E404 />;

      // switch (this.props.match.params.formType) {
      //   case GUARD_APPLICATION_FORM:
      //         return <GuardApplicationForm {...this.props} />
      //         break;
      
      //   case CLIENT_INFO_FORM:
      //         return <ClientQueryform {...this.props} />
      //         break;
      
      //   case SECURITY_GUARD_INTERVIEW:
      //         return <SecurityGuardInterview {...this.props} />
      //         break;
      
      //     default:
      //         return <E404 />
      //         break;
      // }

  }
}
