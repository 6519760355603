// https://github.com/FortAwesome/react-fontawesome
// https://www.npmjs.com/package/@fortawesome/react-fontawesome
// https://fontawesome.com/v5/search?q=user

/*************
 * size => xs, lg, 6x
 * fixedWidth
 * inverse
 * listItem
 * rotation={90}
 * flip="horizontal" => horizontal, vertical, both
 * spin
 * pause
 * border
 * pull="left" => left, right
 * <FontAwesomeIcon icon="spinner" size="xs" />
 * <FontAwesomeIcon icon={['fad', 'stroopwafel']} swapOpacity />
 */

import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
// import { fab } from '@fortawesome/free-brands-svg-icons'

import {
    faEllipsisV, faPaperclip, faFlag, faSms, faEye, faEyeSlash, faExternalLinkAlt, faCircle,
    faClipboardList, faFileAlt, faCubes, faFileCsv, faAngleDoubleRight, faChalkboard,
    faCalendarAlt, faCalendarWeek, faGraduationCap, faFilter, faThumbtack,
    faAngleUp, faAngleDown, faChevronLeft, faChevronRight, faShareAlt,
    faSyncAlt, faSmile, faMapMarker, faMapMarkerAlt, faBookmark, 
    faUser, faUserAlt, faUserShield, faUserTie, faUsers, faUsersCog, faUserSlash,
    faCheck, faCheckCircle, faCheckDouble, faSearch, faTimes, faMinus, faPlus, faTrashAlt,
    faPen, faCogs, faCog, faFilePdf, faFileDownload, faPrint, faEnvelope, faDesktop, faSignOutAlt, faArchive, 
    faUpload, faPhone, faEdit, faHistory, faPaperPlane, faClock,
    faCarSide, faUserGraduate, faLock, faAddressCard, faAddressBook, faHandshake, faFile, faBuilding, faClipboard, faBars, 
    faChevronDown, faCaretDown, faCaretUp, faStopwatch, faStarOfLife, faExclamationCircle, faFileWord, faCopy, faEllipsisH, faReply, faBriefcase, 
    faChair, faExclamation, faThumbsDown, faThumbsUp, faTired, faSurprise, faGrinWink, faSadCry, faSadTear, faMehRollingEyes, faMehBlank, faGrinSquintTears, 
    faHeart, faGrinHearts, faFlushed, faAngry, faFrown, faGrimace, faBookReader, faTags, faLockOpen, faImage, faNewspaper,
    faBell, faCommentDots
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { __error } from 'Common/scripts/consoleHelper';

library.add(
    faBookReader, faEllipsisV, faEllipsisH, faPaperclip, faFlag, faSms, faEye, faEyeSlash, faExternalLinkAlt, faCircle,
    faClipboardList, faFileAlt, faCubes, faFileCsv, faChalkboard, faCalendarAlt, faCalendarWeek, faGraduationCap, faFilter, faThumbtack, 
    faAngleUp, faAngleDown, faChevronLeft, faChevronRight, faChevronDown, faAngleDoubleRight, faCaretDown, faCaretUp,
    faReply, faShareAlt, faSyncAlt, faSmile, faMapMarker, faMapMarkerAlt, faBookmark, faSearch,
    faUser, faUserAlt, faUserShield, faUserTie, faUsers, faUsersCog, faUserSlash, faCheck, faCheckCircle, faCheckDouble,
    faTimes, faMinus, faPlus, faTrashAlt, faPen, faCogs, faCog, faFilePdf, faFileDownload, faPrint, faEnvelope, faDesktop, faSignOutAlt, faArchive, 
    faUpload, faPhone, faEdit, faHistory, faPaperPlane, faClock, faCarSide, faUserGraduate, faLock, faLockOpen, faAddressCard, faAddressBook, faHandshake, faFile, faBuilding, faClipboard, faBars,
    faExternalLinkAlt, faStopwatch, faStarOfLife, faExclamationCircle, faExclamation,
    faFile, faFilePdf, faFileWord, faFileAlt, faCopy, faBriefcase, faChair, faTags, faImage, faNewspaper, faBell,
    faThumbsDown, faThumbsUp, faTired, faSurprise, faGrinWink, faSadCry, faSadTear, faMehRollingEyes, faMehBlank, faGrinSquintTears, faGrinHearts, faFlushed, faAngry, faFrown, faGrimace,
    faCommentDots,
)


function verifyIconAvailability(icon_name) {
    let icons = library.definitions.fas;
    return icons[icon_name] ? true : false;
}

/********
 * size{xs | lg | 6x}
 * icon={name}
 * fixedWidth={boolean}
 * inverse={boolean}
 * listItem={boolean}
 * rotation={number}
 * flip={horizontal | vertical | both}
 * spin={boolean} // animation
 * pulse={boolean} // animation
 * border={boolean}
 * pull={left | right}
 * className={string}
 * 
 * <Icon icon="bell" />
 */
export const Icon = React.forwardRef((_props, ref) => {
    // return <FontAwesomeIcon icon={props.icon} />
    // let props = {..._props}
    let props = { ..._props };
    
    // let className = `${!props.skipstyle && "anticon"} ${props.className||""}`;// props.skipstyle ? "" : "anticon" + props.className || "";
    let className = `awsom-icon ${props.anticon && "anticon"} ${props.className||""}`;// props.skipstyle ? "" : "anticon" + props.className || "";
    delete props.skipstyle;
    delete props.className;
    delete props.anticon;

    if (!verifyIconAvailability(props.icon)){
        console.log(__error(`Icon not found: `), props.icon)
        return <span ref={ref} {...props} className={`${className}`}>ICON</span>;
    }
    return <span ref={ref} className={`${className}`}><FontAwesomeIcon {...props} /></span>

    // anticon anticon-appstore ant-menu-item-icon
    // return <FontAwesomeIcon icon="search" color="green" />
});

export default Icon;

