import React, { useState } from 'react'
import { Button, DataRow, IconButton, Icon, GuardSearch, ClientSearch, SiteSearch, DevBlock, DeleteButton, Drawer } from 'Common/components'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Alert, Col, Modal, Row, Tag, message, Upload, Affix } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper';
// import { mongoToDate } from 'Common/scripts/Functions';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import axios from 'axios';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { mongoToDate } from 'Common/scripts/Functions';
import { defaultDateTimeFormat, dorFlagsArray } from 'configs';

const ADD_RECORD = loader('src/Graphql/dors/addDorNote.graphql');
const FILE_LIMIT = 6;


export const DorNotesForm = withApollo(({ dor_id, onUpdate }) => {
    // const [showForm, set_showForm] = useState(false);
    const [error, setError] = useState(false);
    const [busy, setBusy] = useState(false);
    const [addDorNote, add_details] = useMutation(ADD_RECORD);

    const onSubmit = async (values) => {
        // setBusy(true)

        const data = {
            dor_id: dor_id,
            subject: values.subject,
            note: values.note,
            locked: values.locked ? true : false,
            // attachments: [AttachmentSchema_Input]

            guard: values.guard ? { _id: values.guard._id, name: values.guard.name } : undefined,
            client: values.client ? { _id: values.client._id, name: values.client.name } : undefined,
            site: values.site ? { _id: values.site._id, name: values.site.name } : undefined,
            flag: values.flag,
            is_followup: values.is_followup ? true : false,
            // reminder: (values.reminder && values.is_followup) ? mongoToDate(values.reminder) : undefined,
            reminder: (values.reminder && values.is_followup) ? values.reminder : undefined,
        }

        if (values.fileList && values?.fileList?.length > 0){
            var upload_results = await handleUpload(values.fileList, dor_id).catch(err => {
                console.log(__error("ERROR: ", err));
                return { error: { message: 'Unable to complete upload request' } }
            });
            if (!upload_results || upload_results.error){
                let err = (upload_results && upload_results?.error?.message) || 'Unable to upload attachments';
                // message.error(err);
                return { error: { message: err } }
            }

            // let attachments = upload_results.map(o => ({
            //     srcUrl: o.srcUrl,
            //     type: o.type,
            //     title: o.title,
            // }))
            Object.assign(data, { attachments: upload_results.result })
        }

        const results = await addDorNote({ variables: { input: data } }).then(r => (r?.data?.addDorNote))
            .catch(err => {
                console.log(__error("ERROR: "), err)
                return { error: { message: "Unable to submit your request at the moment!" } };
            })

        if (!results || results.error) {
            message.error((results && results?.error?.message) || "Invalid Response!")
            setBusy(false)
            return true;
        }

        setBusy(false)

        const finalResults = (upload_results && upload_results.data) || results;
        onUpdate(finalResults);

        message.success(`Notes added to DOR`);
        // set_showForm(false)
        return 'reset';
    }

    const handleUpload = async (fileList, _id) => {
        if (!fileList || fileList.length < 1) return; // { error: { message: "No file to upload!!" } }
        if (fileList.length > FILE_LIMIT) return { error: { message: `You can upload max of ${FILE_LIMIT} files only!` } }
        if (!_id) return { error: { message: `Missing upload ID` } }

        // message.info("Uploading....")
        const hide = message.loading('Uploading..', 0);

        const formData = new FormData();
        formData.append('action', "dor.attachments");
        formData.append('_id', _id);
        fileList.forEach(file => {
            formData.append('files[]', file);
        });


        const results = await axios.post(process.env.REACT_APP_API_URL + '/upload/dor_upload', formData)
            .then(({ data }) => (data))
            .catch(function (error) {
                console.log(__error("Error: "), JSON.stringify(error, 0, 2));
                // message.error("Upload request fail!")
                return { error: { message: "Upload request fail!" } };
            });

        hide();
        // onUpdate(results)
        return results;
    };

    return (<>
        {/* {!showForm && <Button onClick={() => set_showForm(!showForm)} block>Add Another Note</Button>} */}
        
        <FormComponent onSubmit={onSubmit} id='DorNoteForm' loading={busy} fields={{ flag: "normal" }} debug={false}
            mutators={{
                ...arrayMutators,
                selectFiles: (newValueArray, state, tools) => {
                    let file = newValueArray[0];
                    let action = newValueArray[1];
                    let fileList = state.formState.values.fileList || []
                    if (fileList.length >= FILE_LIMIT) return;

                    if (action == 'add') fileList.push(file);
                    if (action == 'remove') fileList = fileList.filter(o => o.uid != file.uid);

                    tools.changeValue(state, 'fileList', () => fileList);
                },
                removeFiles: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'fileList', () => []);
                },
                selectClient: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    let val = { _id: node.value, name: node.children };
                    tools.changeValue(state, 'client', () => val);
                    tools.changeValue(state, 'site', () => undefined);
                },
                de_selectClient: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'client', () => undefined)
                    tools.changeValue(state, 'site', () => undefined);
                },
                selectSite: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    let val = { _id: node.value, name: node.children };
                    tools.changeValue(state, 'site', () => val);
                },
                de_selectSite: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'site', () => undefined)
                },
                selectGuard: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    let val = { _id: node.value, name: node.children };
                    tools.changeValue(state, 'guard', () => val);
                },
                de_selectGuard: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'guard', () => undefined)
                },


            }}

            form_render={formProps => {
                const { values, form, invalid, submitting, dirty, valid } = formProps;

                return (<>
                    {error && <Alert message={error} showIcon type='error' />}

                    <Row gutter={[20, 10]} className='nowrap'>
                        <Col flex="auto">
                            <Row gutter={[10, 0]}>
                                <Col span={24}><FormField name="subject" compact label="Subject" type="text" validate={rules.required} /></Col>
                                <Col span={8}><ClientSearch label="Client" name='client._id' compact allowClear preload={true}
                                    onSelect={(txt, node) => form.mutators.selectClient(node)}
                                    onDeselect={(txt, node) => form.mutators.de_selectClient(node)}
                                />
                                    {values?.client?._id && <p><i>* notes will be added to client's profile.</i></p>}
                                </Col>
                                <Col span={8}><SiteSearch disabled={!values?.client?._id} label="Site" name='site._id' compact allowClear
                                    filter={values?.client?._id ? { client_id: Number(values.client._id) } : {}}
                                    preload={values?.client?._id ? true : false}
                                    onSelect={(txt, node) => form.mutators.selectSite(node)}
                                    onDeselect={(txt, node) => form.mutators.de_selectSite(node)}
                                    validate={values?.client?._id ? rules.required : undefined}
                                />
                                    {values?.site?._id && <p><i> * notes will be added to site's profile.</i></p>}
                                </Col>
                                <Col span={8}><GuardSearch label="Guard" name="guard._id" compact
                                    onSelect={(txt, node) => form.mutators.selectGuard(node)}
                                    onDeselect={(txt, node) => form.mutators.de_selectGuard(node)}
                                />
                                    {values?.guard?._id && <p><i>* notes will be added to guard's profile.</i></p>}
                                </Col>
                                <Col span={24}>
                                    <FormField name="note" rows={6} compact label="Notes" type="textarea" validate={rules.required} />
                                </Col>
                            </Row>

                            <div style={{ padding: "10px 0px" }}>
                                <Row gutter={[20]} align="middle">
                                    <Col flex="auto" />
                                    <Col><FormField name="locked" compact label="Close DOR" type="switch" /></Col>
                                    <Col flex="200px"><Button type="primary" block htmlType='submit' loading={busy || submitting} disabled={!dirty || !valid}>Save</Button></Col>
                                </Row>
                            </div>

                        </Col>
                        <Col flex="300px">
                            <Row gutter={[10, 15]}>
                                <Col span={20}><FormField data={dorFlagsArray} name="flag" compact label="Flag" type="select" validate={rules.required} /></Col>
                                <Col span={4} style={{ fontSize: "24px", paddingTop: "20px" }}><Icon color={values.flag == 'normal' ? "white" : "red"} icon="flag" /></Col>
                                <Col span={24}><FormField name="is_followup" compact label="Followup Required" type="switch" /></Col>
                                {values.is_followup && <Col span={24}><FormField name="reminder" compact label="Reminder" type="date" showTime format={defaultDateTimeFormat} validate={values.is_followup ? rules.required : undefined} /></Col>}
                                <Col span={24}>
                                    {/* <Upload
                                    onRemove={(file) => form.mutators.selectFiles(file, "remove")}
                                    beforeUpload={(file) => {
                                        form.mutators.selectFiles(file, "add");
                                        return false;
                                    }}
                                    fileList={values.fileList}
                                    accept=".jpg,.png,.pdf"
                                    multiple={true}
                                    maxCount={6}
                                >
                                    <Button size='small' type="default" style={{ marginLeft: "0px" }} disabled={values?.fileList?.length >= 6 ? true : false}><Icon icon="paperclip" style={{ marginRight: '10px' }} /> Select files to attach</Button>
                                </Upload> */}

                                    <div>
                                        <Upload.Dragger
                                            onRemove={(file) => form.mutators.selectFiles(file, "remove")}
                                            beforeUpload={(file) => {
                                                form.mutators.selectFiles(file, "add");
                                                return false;
                                            }}
                                            fileList={values.fileList}
                                            listType="picture"
                                            accept=".jpg,.png,.pdf"
                                            multiple={true}
                                            maxCount={6}
                                            // style={{ padding: "0px", height: "100px", overflow: "hidden" }}
                                            showUploadList={false}
                                            disabled={values?.fileList?.length >= 6 ? true : false}
                                        >
                                            <div style={{ opacity: values?.fileList?.length >= 6 ? 0.5 : 1 }}>
                                                <div style={{ fontSize: "34px", color: values?.fileList?.length >= 6 ? 'gray' : "#41A9FF" }}><InboxOutlined /></div>
                                                <div>Drag file to this area to upload</div>
                                            </div>
                                        </Upload.Dragger>
                                    </div>

                                    {values?.fileList?.map((item, i) => {
                                        console.log("item: ", item)
                                        return <Row key={i} gutter={[10]} className='nowrap' align="middle">
                                            <Col><DeleteButton onClick={() => form.mutators.selectFiles(item, "remove")} size="small" /></Col>
                                            <Col span="auto"><div className='ellipsis' style={{ width: "250px" }}>{item.name}</div></Col>
                                        </Row>
                                    })}

                                    <FieldArray name="fileList">{({ fields }) => null}</FieldArray>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/* <DevBlock obj={values} /> */}
                </>)
            }}
        />

        {/* <Affix offsetTop={-120}>
            <div style={{ position: "absolute", bottom: 40, right: 40, textAlign: 'right' }}>
                <IconButton size="large" type="danger" icon="plus"
                    style={{ boxShadow: '0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)' }}
                    // disabled={!modulePermissions['noticeboard.note.add']}
                    onClick={() => set_showForm(!showForm)}
                />
            </div>
        </Affix> */}

    </>)

});
