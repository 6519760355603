export * from './FormField';
export * from './FormComponent';
export * from './UploadField';
export * from './FormFieldGroup';
export * from './HtmlField';
export * from './DateField';
export * from './DateRangeField';

// function is_url(str) {
//     let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
//     if (regexp.test(str)) {
//         return true;
//     }
//     else {
//         return false;
//     }
// }

function is_url(string) {
    var res = string.match(/(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
};

export const rules = {
    required: value => (((value || value === 0) && (value != null && value != "null")) ? undefined : 'Required'),
    
    isNumber: value => (isNaN(value) ? 'Must be a number' : undefined),
    
    minValue: min => value => {
        return isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
    },
    maxValue: max => value => isNaN(value) || value <= max ? undefined : `Should be less than ${max}`,
    
    minChar: min => value => (value && value.length < min) ? `Should be atleast ${min} charactors` : undefined,

    maxChar: max => value => (value && value.length > max) ? `Should be maximum ${max} charactors` : undefined,
    
    isEqual: val => value => val != value ? `Invalid!` : undefined,

    nospace: value => !value ? undefined : (String(value).match(/\s/g) ? "Space characters are not allowed" : undefined),
    
    is_url: value => !value ? undefined : (!is_url(value) ? "Invalid url" : undefined),

    isEmail: value => !value ? undefined : (String(value).match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g) ? undefined : "Invalid email address"),
    
    fieldName: (value) => { 
        console.log("fieldName: ", value);
        return undefined;
    },
}

/***
 * composeValidators(rules.required, rules.isNumber, rules.minValue(18))
 */
export const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined)

