import React, { Component, useState, useEffect } from 'react'
import { ContentArea } from 'Layout_v1';
import { Link, withRouter, useRouteMatch } from 'react-router-dom';
import { Icon, ActionMenu, Loader } from 'Common/components'
import { Col, Row, Badge, Modal, message, Space } from 'antd'
import { ROOT_PATH } from '../constants';
import { loader } from 'graphql.macro';
import ChannelFormModal from './channels/channel_form';
import TicketFormModal from './tickets/ticket_form';
import TodoFormModal from './todos/todo_form';
import { __error } from 'Common/scripts/consoleHelper';
import { useLazyQuery } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { verifyRole } from 'Common/scripts/Security';
import { connect } from "react-redux";


const GET_CHANNELS = loader('src/Graphql/threads/threadsChannels.graphql');
// const GET_TODOS = loader('src/Graphql/Todos/todos.graphql');
const GET_TODOS = gql`
    query todos($filter:String, $others:String){
        todos(filter:$filter, others:$others){
          _id
          title
        }
    }   
    `
const GET_TICKETS = gql`
    query threadsTickets($filter:String, $others:String){
        threadsTickets(filter:$filter, others:$others){
            _id
            subject
            author { _id name }
            # members { _id name }

            # status
            priority
            channel { _id title }
            
            # closed_by { _id name }
            # closed_at
            # logs

            created_at
            updated_at

            error { message }
        }
    }   
    `

class TicketSubscriptionHandler extends Component {
    componentDidMount() {
        document.body.addEventListener('ticket-event', this.onHandel.bind(this));
    }

    componentWillUnmount() {
        document.body.removeEventListener('ticket-event', this.onHandel.bind(this));
    }

    onHandel(e) {
        this.props.handleEventChange(e)
    }

    render() {
        return null
    }
}

class ChannelSubscriptionHandler extends Component {
    componentDidMount() {
        document.body.addEventListener('channel-event', this.onHandel.bind(this));
    }

    componentWillUnmount() {
        document.body.removeEventListener('channel-event', this.onHandel.bind(this));
    }

    onHandel(e) {
        this.props.handleEventChange(e)
    }

    render() {
        return null
    }
}


const Channels = ({ client, user, openMenu, set_openMenu }) => {
    const [showChannelForm, set_showChannelForm] = useState(false)
    // const [collapsChannels, set_collapsChannels] = useState(false)
    const channel_match = useRouteMatch({
        path: "/admin/threads/channel/:id",
        strict: true,
        sensitive: true
    });

    const [loadChannels, { called, loading, data }] = useLazyQuery(
        GET_CHANNELS, { variables: { filter: JSON.stringify({ status:"active" }) } }
    );

    useEffect(() => {
        if (called || loading || data) return;

        loadChannels();
        // return () => {};
    }, [])

    const handleEventChange = ({ detail }) => {
        // console.log("handleEventChange: ", detail)
        loadChannels();

        // if (detail.mutation == "CHANNEL_CREATED") {
        //     let _threadsList = (threadsList && threadsList.slice()) || [];
        //     _threadsList.push(detail.ticket);
        //     set_threadsList(_threadsList)
        // }
    }

    return (<>
        <ChannelSubscriptionHandler handleEventChange={handleEventChange} />

        <Loader loading={loading}>
            <ContentArea>
                <Row style={{ marginBottom: "5px" }}>
                    <Col flex="auto">
                        <div onClick={() => set_openMenu(openMenu == "channels" ? "" : "channels")}>
                            <Icon icon={openMenu=="channels" ? "angle-up" : "angle-down"} /> <b>Channels</b>
                        </div>
                    </Col>
                    <Col>
                        <Space>
                            {verifyRole("th.ch.history,th.ch.fullacc", {user}) && 
                                <ActionMenu trigger="hover" contentStyle={{}} hideArrow menu={[
                                    { title: "Channels history", to: `${ROOT_PATH}/channels/history` },
                                ]}><Icon icon="ellipsis-h" /></ActionMenu> 
                            }
                            {verifyRole("th.create.ch,th.ch.fullacc", {user}) &&
                                <div className='a' onClick={() => set_showChannelForm(!showChannelForm)}><Icon icon="plus" /></div>
                            }
                        </Space>
                    </Col>
                </Row>

                {openMenu == "channels" && data?.threadsChannels?.map((item, i) => {
                    let _link = `${ROOT_PATH}/channel/${item._id}`;
                    let selected = (channel_match && channel_match.url == _link);
                    
                    return <div className={`threads_menu_item ${selected ? "selected" : ""} ellipsis`} key={i}>
                        <Link to={_link}>
                            <Space>
                                {/* <Icon className="icon" icon="chevron-right" />  */}
                                - 
                                {item.new_msg_cnt > 0 && <span className='new_badge'>{item.new_msg_cnt}</span> }
                                <span className='new_badge'>5</span>
                                {item.title}
                            </Space>
                        </Link>
                    </div>
                })}

                {/* {!collapsChannels && Array(10).fill(0).map((item, i) => {
                    return <div className="threads_menu_item ellipsis" key={i}>
                        <Link to={`${ROOT_PATH}/thread/123`}><Icon className="icon" icon="chevron-right" /> <span className='new_badge'>5</span> This is some long thread name sample testing the menu lenth (5)</Link>
                    </div>
                })}
                */}
            </ContentArea>
        </Loader>

        <ChannelFormModal visible={showChannelForm} onCancel={() => set_showChannelForm(!showChannelForm)} />
    </>)

}

const Tickets = ({ client, user, openMenu, set_openMenu }) => {
    const [showThreadForm, set_showThreadForm] = useState(false)
    // const [collapsThreads, set_collapsThreads] = useState(false)
    const [threadsList, set_threadsList] = useState(null)

    const ticket_match = useRouteMatch({
        path: "/admin/threads/ticket/:id",
        strict: true,
        sensitive: true
    });

    const [loadTickets, { called, loading, data }] = useLazyQuery(
        GET_TICKETS, { variables: { filter: JSON.stringify({ status:"open" }) } }
    );

    useEffect(() => {
        if (called || loading || data) return;

        loadTickets().then(r=>{
            set_threadsList(r.data.threadsTickets)
        });
        // return () => {};
    }, [])

    const handleEventChange = ({ detail }) => {
        // console.log("handleEventChange()", detail.ticket)

        if (detail.mutation == "TICKET_CREATED") {
            let _threadsList = (threadsList && threadsList.slice()) || [];
            _threadsList.push(detail.ticket);
            set_threadsList(_threadsList)
        }

        if (detail.mutation == "TICKET_CLOSED") {
            let _threadsList = (threadsList && threadsList.slice()) || [];
            _threadsList = _threadsList.filter(o => o._id != detail.ticket._id)
            set_threadsList(_threadsList)
        }

        if (detail.mutation == "TICKET_UPDATED" || detail.mutation == "TICKET_ARCHIVED") {
            if (threadsList && detail.ticket._id) {
                let _threadsList = threadsList.map(o => ((o._id == detail.ticket._id) ? detail.ticket : o)).filter(o=>o.status=="open")
                // update ticket
                set_threadsList(_threadsList)
            }
        }

    }


    return (<>
        <Loader loading={loading}>
            <ContentArea>
                <Row style={{ marginBottom: "5px" }} gutter={[10]}>
                    <Col flex="auto">
                        <div onClick={() => set_openMenu(openMenu == "tickets" ? "" : "tickets")}>
                            <Icon icon={openMenu=="tickets" ? "angle-up" : "angle-down"} /> <b>Threads</b>
                        </div>
                    </Col>
                    <Col>
                        <Space>
                            {verifyRole("th.ch.history,th.tik.fullacc", {user}) && <ActionMenu trigger="hover" contentStyle={{}} hideArrow menu={[
                                { title: "Threads history", to: `${ROOT_PATH}/tickets/history` },
                            ]}><Icon icon="ellipsis-h" /></ActionMenu>}

                            {verifyRole("th.create.tk,th.tik.fullacc", {user}) && 
                                <span className='a' onClick={() => set_showThreadForm(!showThreadForm)}><Icon icon="plus" /></span>
                            }
                        </Space>
                    </Col>
                </Row>

                {openMenu=="tickets" && threadsList && threadsList?.map((item, i) => {
                    let priority_style = {};
                    if (item.priority == "normal") priority_style = { backgroundColor:"#EEE", color:"white" }
                    if (item.priority == "high") priority_style = { backgroundColor: "#red", color:"white" }

                    let _link = `${ROOT_PATH}/ticket/${item._id}`;
                    let selected = (ticket_match && ticket_match.url == _link);

                    return <div className={`threads_menu_item ellipsis ${selected ? "selected" :""}`} key={i}>
                        <Link to={_link}>
                            <span className='priority_badge' style={{ ...priority_style }}><Icon icon="exclamation" /></span> {item.subject}
                        </Link>
                    </div>
                })}
            </ContentArea>
        </Loader>

        <TicketFormModal visible={showThreadForm} onCancel={() => set_showThreadForm(!showThreadForm)} />

        <TicketSubscriptionHandler handleEventChange={handleEventChange} />
    </>)
}

const ToDo = ({ client, user, openMenu, set_openMenu }) => {
    const [showTodoForm, set_showTodoForm] = useState(false)
    const [dataList, set_dataList] = useState(null)

    const url_match = useRouteMatch({
        path: "/admin/threads/todo/:id",
        strict: true,
        sensitive: true
    });

    const [loadTotos, { called, loading, data }] = useLazyQuery(GET_TODOS, 
        { variables: { filter: JSON.stringify({ status: "open" }) } }
    );

    useEffect(() => {
        if (called || loading || data) return;

        loadTotos().then(r => {
            set_dataList(r.data.todos)
        });
        // return () => {};
    }, [])


    return (<>
        <ContentArea>
            <Row style={{ marginBottom: "5px" }}>
                <Col flex="auto"><div onClick={() => set_openMenu(openMenu == "todos" ? "" : "todos")}><Icon icon={openMenu=="todos" ? "angle-up" : "angle-down"} /> <b>ToDo List</b></div></Col>
                <Col><Space>
                    {(verifyRole("th.todo.history,th.todo.fullacc", {user})) && 
                        <ActionMenu trigger="hover" contentStyle={{}} hideArrow menu={[
                            { title: "Todo history", to: `${ROOT_PATH}/todo/history` },
                        ]}><Icon icon="ellipsis-h" /></ActionMenu> 
                    }
                    {(verifyRole("th.create.todo,th.todo.fullacc", {user})) &&
                        <span className='a' onClick={() => set_showTodoForm(!showTodoForm)}><Icon icon="plus" /></span>
                    }
                </Space></Col>
            </Row>

            {openMenu == "todos" && dataList && dataList?.map((item, i) => {
                let _link = `${ROOT_PATH}/todo/${item._id}`;
                let selected = (url_match && url_match.url == _link);

                return <div className={`threads_menu_item ellipsis ${selected ? "selected" : ""}`} key={i}>
                    <Link to={_link}>{item.title}</Link>
                </div>
            })}

            {/* {openMenu=="todos" && Array(10).fill(0).map((item, i) => {
                return <div className="threads_menu_item ellipsis" key={i}>
                    <Link to={`${ROOT_PATH}/todo/123`}>
                        {`- `}
                        <span className='new_badge'>5</span> This is some long thread name sample testing the menu lenth (5)</Link>
                </div>
            })} */}

        </ContentArea>

        <TodoFormModal visible={showTodoForm} onCancel={() => set_showTodoForm(!showTodoForm)} />
    </>)
}

const ThreadsLeftMenu = ({ client, user }) => {
    const [openMenu, set_openMenu] = useState("tickets")

    return (<div style={{ width:"300px" }}>
        <Tickets client={client} user={user} set_openMenu={set_openMenu} openMenu={openMenu} />
        <Channels client={client} user={user} set_openMenu={set_openMenu} openMenu={openMenu} />
        <ToDo client={client} user={user} set_openMenu={set_openMenu} openMenu={openMenu} />
        {/* {process.env.NODE_ENV=="development" && 
            <ToDo client={client} user={user} set_openMenu={set_openMenu} openMenu={openMenu} />
        } */}
    </div>)
}

const WithApllo =  withApollo(ThreadsLeftMenu);
const mapStateToProps = ({ ep_admin: { user } }) => {
    return ({ user });
}
export default connect(mapStateToProps, null)(WithApllo)
