import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Breadcrumb, Popconfirm, Alert, message, Row, Col, Select, InputNumber, Table as _Table } from 'antd';
import { ContentArea } from "Layout_v1";
import { Table, Loader, Icon, Button, IconButton, CustomIcon, DevBlock, BackButton } from 'Common/components';
import { HomeOutlined } from '@ant-design/icons';
import { modulePermissions } from '.';
import { defaultDateFormat, defaultPageSize, uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import moment from 'moment';
import { mongoToDate, removeSpecialChar, getTimeDiff, min2Hrs } from 'Common/scripts/Functions'
import { __error } from 'Common/scripts/consoleHelper';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import { verifyRole } from 'Common/scripts/Security';


const DATA = loader('src/Graphql/leads/lead.graphql');

const LeadHours = ({ resultData }) => {
    // const [showPaygrid, setShowPaygrid] = useState(false);
    // const [showInvoice, setShowInvoice] = useState(false);
    // const [showDailyBreakdown, setShowDailyBreakdown] = useState(false);


    const calculate_results = () => {
        // const [resultData, setResultData] = useState(false);
        if (!resultData) return null;

        let unverified = resultData.filter(o => o.approval_status == 'new')
        let aproved = resultData.filter(o => o.approval_status == 'aproved')
        let do_not_charge_client = resultData.filter(o => o.do_not_charge_client == 'yes');
        let abnorma_flags = resultData.filter(o => o.flag != 'normal');

        return (<div style={{ fontWeight: "bold", fontSize: '14px' }}>
            {(unverified && unverified.length > 0) &&
                <div style={{ color: 'red' }}>Pending verifications = {unverified.length}</div>
            }
            {(do_not_charge_client && do_not_charge_client.length > 0) &&
                <div>Do not charge client = {do_not_charge_client.length}</div>
            }
            {(aproved && aproved.length > 0) &&
                <div style={{ color: "green" }}>Aproved = {aproved.length}</div>
            }
            {(abnorma_flags && abnorma_flags.length > 0) &&
                <div style={{ color: "red" }}>Abnormal Flags = {abnorma_flags.length}</div>
            }
        </div>)
    }

    const calculateHoursTotal = (_data, rawData = false) => {
        let total_workedHrs = 0;
        let total_assignedHrs = 0;
        let total_decimalHrs = 0;

        _data.forEach((rec) => {
            total_assignedHrs += getTimeDiff(rec.scheduled_shift_start, rec.scheduled_shift_end, 'minutes');
            total_workedHrs += getTimeDiff(rec.check_in, rec.check_out, 'minutes')
            total_decimalHrs += getTimeDiff(rec.check_in, rec.check_out, 'minutes')
        });

        if (!rawData) {
            return {
                total_assignedHrs: min2Hrs(total_assignedHrs),
                total_workedHrs: min2Hrs(total_workedHrs),
                total_decimalHrs: min2Hrs(total_decimalHrs)
            }
        }

        return {
            total_assignedHrs,
            total_workedHrs,
            total_decimalHrs
        }

    } 

    const columns = [
        {
            title: 'Site Name', key: "1", dataIndex: 'site.name', render: (__, rec) => {
                return (<>
                    <div>{rec?.site_name}</div>
                    {rec?.lead?._id && <><hr />Lead: {rec.lead.title}</>}
                </>)
            }
        },
        { title: 'Guard Name', key: "2", dataIndex: 'guard_name' },
        {
            title: 'Shift Schedule (HH:mm)', key: "3", align: 'center', width:"150px", render: (__, rec) => {
                let d1 = mongoToDate(rec.scheduled_shift_start);
                let d2 = mongoToDate(rec.scheduled_shift_end);

                let diff = getTimeDiff(rec.scheduled_shift_start, rec.scheduled_shift_end, "split");

                return <>
                    <span style={{ fontSize: '12px' }}>{d1.format(`${defaultDateFormat} HH:mm`)} <br />{d2.format(`${defaultDateFormat} HH:mm`)}</span>
                    <br />
                    {`${diff.hrs}:${diff.min}`}
                </>
            }
        },

        {
            title: 'Performed In/Out (HH:mm)', key: "4", align: 'center', width: "150px", render: (__, rec) => {
                let d1 = mongoToDate(rec.check_in);
                let d2 = mongoToDate(rec.check_out);
                let diff = getTimeDiff(rec.check_in, rec.check_out, "split");

                let schedule_diff = getTimeDiff(rec.scheduled_shift_start, rec.scheduled_shift_end, "split");
                let isDiff = `${diff.hrs}:${diff.min}` != `${schedule_diff.hrs}:${schedule_diff.min}`;

                return <>
                    <span style={{ fontSize: '12px' }}>{d1.format(`${defaultDateFormat} HH:mm`)} <br />{d2.format(`${defaultDateFormat} HH:mm`)}</span>
                    <br />
                    <span style={{ fontWeight: isDiff ? "bold" : "normal" }}>{`${diff.hrs}:${diff.min}`}</span>
                </>

            }
        },

        {
            title: 'Decimal hours', key: "8", align: 'center', width: "90px", render: (__, rec) => {
                let difference = getTimeDiff(rec.check_in, rec?.check_out, 'split');
                return Number(difference.decimalHrs).toFixed(2);
            }
        },

        {
            title: 'Verified', key: "_approved", align: 'center', width: "80px", render: (__, rec) => {
                console.log("rec: ", rec)
                return <Icon icon="check" color={(rec.approval_status == 'new') ? 'red' : 'green'} size="2x" />;
            }
        },

        {
            title: 'Product/Services', dataIndex: 'prod_and_services', render: (__, rec) => {
                if (!rec.prod_and_services || rec.prod_and_services == 'NULL') return null;
                return <>
                    {rec?.prod_and_services?.title} {rec?.prod_and_services?.values && rec?.prod_and_services?.values != '1' ? ` @ ${rec?.prod_and_services?.values}` : ""}
                    <div>
                        {rec.stat == 'yes' && <CustomIcon title="Stat holiday">SH</CustomIcon>}
                        {rec.do_not_charge_client == 'yes' && <CustomIcon title="Do not charge client"><Icon icon="user-slash" /></CustomIcon>}
                    </div>
                </>
            }
        },

    ];



    return(<>
        <Table 
            title={() => <Row>
                <Col flex="auto">{calculate_results()}</Col>
                {/* <Col>
                    <Popover title="Export results" placement="bottomRight"
                        content={<Space direction='vertical'>
                            {(resultData && verifyRole('hours-schedule-export.paygrid')) && <>
                                <Button type="dashed" block onClick={() => setShowPaygrid(true)} disabled={!resultData}>Export PayGrid Data</Button></>}
                            {(resultData && verifyRole('hours-schedule-export.invoice')) && <>
                                <Button type="dashed" block onClick={() => setShowInvoice(true)} disabled={!resultData}>Export Invoice</Button></>}
                            <Button onClick={() => setShowDailyBreakdown(true)} type="dashed" block disabled={!resultData}>Show Daily Breakdown</Button>
                        </Space>}
                    >
                        <Button type="primary" disabled={!resultData || resultData.length < 1}>Export</Button>
                    </Popover>
                </Col> */}
            </Row>}
            columns={columns}
            dataSource={resultData ? resultData : null}
            pagination={false}
            expandable={{
                expandedRowRender: record => <div style={{ margin: 0 }}>
                    <div>ID: {record._id}</div>
                    <p><b>Comments: </b>{record.comments}</p>
                </div>,
            }}

            rowClassName={(record => {
                let flagClass = !record.flag ? 'flag_normal' : `flag_${record.flag}`;
                return `${flagClass} ${record.approval_status == 'new' ? 'hilighted-table-row' : ""}`;
            })}

            rowKey={obj => obj._id}
            summary={pageData => {
                let totals = calculateHoursTotal(pageData);

                return (<>
                    <_Table.Summary.Row style={{ fontWeight: "bold", backgroundColor: "#EEE" }}>
                        <_Table.Summary.Cell colSpan={3}>Total</_Table.Summary.Cell>
                        <_Table.Summary.Cell colSpan={1} align="center">{`${totals.total_assignedHrs.hrs}:${totals.total_assignedHrs.min}`}</_Table.Summary.Cell>
                        <_Table.Summary.Cell colSpan={1} align="center">{`${totals.total_workedHrs.hrs}:${totals.total_workedHrs.min}`}</_Table.Summary.Cell>
                        <_Table.Summary.Cell colSpan={1} align="center">{totals.total_decimalHrs.decimalHrs.toFixed(2)}</_Table.Summary.Cell>
                    </_Table.Summary.Row>
                </>);
            }}

            // {...tableProps}
            // scroll={{ y:300 }}
        />
    </>)
}


class LeadsDetailsComp extends Component {
    constructor(props) {
        super(props);
        this.state = { busy: true, error: null, data: null }
        this.fetchData = this.fetchData.bind(this)
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = async () => {
        this.setState({ busy: true })

        const results = await this.props.client.query({ query: DATA, variables: { id: this.props.match.params.id } })
            .then(r => (r.data.lead)).catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message:"Query Error"}}
            })

        this.setState({ 
            busy: false, 
            error: results?.error?.message || false, 
            data: results.error ? null : results 
        });

    }

    render() {
        const { busy, error, data } = this.state;

        if(busy) return <Loader loading={true} />
        if (error) return <Alert message="Error" description={error} type="error" showIcon />

        return (<>
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item href={`${uriRoot}/leads`}>Leads</Breadcrumb.Item>
                <Breadcrumb.Item><b>{data.title}</b></Breadcrumb.Item>
            </Breadcrumb>
            
            <Row align="middle">
                <Col><BackButton /></Col>
                <Col flex="auto" />
                <Col style={{marginRight:"10px"}}>{mongoToDate(data.created_at).format(`${defaultDateFormat} HH:mm`)}</Col>
            </Row>
            <ContentArea style={{marginTop:0}}>

                <Row gutter={[10]}>
                    <Col span={12}><label>Create by:</label> {data.author.name}</Col>
                    <Col span={12}><label>Sales Agent:</label> {data.agent.name}</Col>
                    
                    <Col span={12}><label>Client:</label> {data.client.name}</Col>
                    <Col span={12}><label>Site:</label> {data.site.name}</Col>
                </Row>
                <p><label>Service:</label> {data.service_name} ({data.status})</p>

                {data.description && <p><label>Description</label><br />{data.description}</p>}


                <hr style={{margin:"20px 50px"}} />
                {data.hours && <LeadHours resultData={data.hours} />}
                {/* <hr style={{margin:"20px 50px"}} /> */}

                {/* <SalesCalculations data={data} /> */}
                {/* <DevBlock obj={data} /> */}
            </ContentArea>

        </>)
    }
}

export const LeadsDetails = withApollo(LeadsDetailsComp)
export default LeadsDetails;