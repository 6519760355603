import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper'
import { ContentArea } from "Layout_v1";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Popconfirm, Alert, message, Row, Col, Input, Breadcrumb } from 'antd';
import { Table, Loader, Icon, IconButton, Button, DeleteButton } from 'Common/components';
import { useSubscription, gql } from "@apollo/client";

import ValuePairsForm from './value_pair_form';
import { uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import { ListHeader } from 'Common/components/Typography';

const ALL_PAIRS = loader('src/Graphql/value_pairs/valuePairs.graphql');
const DELETE_VALUE = loader('src/Graphql/value_pairs/deleteValuePairs.graphql');
const QUERY_SUBSCRIPTION = loader('src/Graphql/value_pairs/subscription.graphql');


class ListComp extends Component {
    state = {
        showDrawer: false,
        initialData: null,
        loading: false,
        busy: false,
        data: null,
        filter: { key: this.props.key_value }
    }
    constructor(props){
        super(props);
        this.onEditClick = this.onEditClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    componentWillReceiveProps(nextProps){
        if (!nextProps?.subscriptionData) return;
        if (JSON.stringify(nextProps?.subscriptionData || {}) != JSON.stringify(this.props?.subscriptionData || {})){
            this.onSubscriptionReceived(nextProps.subscriptionData);
        }
    }

    toggeleDrawer(initialData = null) {
        this.setState({ showDrawer: !this.state.showDrawer, initialData })
    }

    fetchData = (args = {}) => {
        const variables = {
            filter: JSON.stringify(args.filter || this.state.filter ),
        }

        this.setState({ loading: true })

        this.props.client.query({
            query: ALL_PAIRS,
            variables,
            fetchPolicy: "no-cache",
        }).then(r => {
            const response = r.data.valuePairs;

            if (response.error) {
                this.setState({ loading: false, error: response.error.message });
                return;
            }

            this.setState({ loading: false, data: response, });

        }).catch(err => {
            console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
            this.setState({ loading: false, error: "Unable to process your request at the moment!" });
        })
    }

    onEditClick(rec) {
        this.toggeleDrawer(rec)
    }

    onDeleteClick(_id) {
        this.setState({ busy: true, error: null });

        this.props.client.mutate({ mutation: DELETE_VALUE, variables: { id: _id } }).then(r => {
            const response = r.data.deleteValuePairs;

            if (response.error) {
                this.setState({ busy: false, error: response.error.message });
                return;
            }
            message.success("Record removed");

            this.setState({ busy: false });

        }).catch(err => {
            console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
            message.error("Failed to process the request!");
        })

    }

    onSubscriptionReceived({ node, mutation }) {
        let _data = this.state.data ? this.state.data.slice() : [];

        if (mutation == "DELETED") _data = _data.filter(o => o._id != node._id);

        else if (mutation == "UPDATED") {
            _data = _data.map(o => {
                if (o._id == node._id) return { ...node }
                return o;
            })
        }

        else if (mutation == "CREATED") _data.unshift(node);

        else {
            console.log(__error("Invalid Subscription received: ", node));
            return;
        }

        this.setState({ data: _data })
    }

    componentDidMount() {
        this.fetchData();
    }

    columns = [
        { title: 'ID', dataIndex: '_id', width: 100 },
        {
            title: 'Title', dataIndex: 'title', render: (__, rec) => (<>
                <div>{rec.title} </div>
                <span className='hidden-menu'>
                    <IconButton className="item" icon="pen" onClick={() => this.onEditClick(rec)} />
                    <DeleteButton className="item" onConfirm={() => this.onDeleteClick(rec?._id)} />
                </span>
            </>)
        },
        { title: 'Type', dataIndex: 'value_type', width: '100px' },
        { title: 'Status', dataIndex: 'status', width: '100px' },
        { title: 'Value', dataIndex: 'values', width: '500px' },
        { title: 'Value Key', dataIndex: 'value_key', width: '200px' },
    ];


    render() {
        const { showDrawer, initialData, data, loading, busy } = this.state;

        return (<>
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                {/* <Breadcrumb.Item href="/templates"><span>Templates</span></Breadcrumb.Item> */}
                <Breadcrumb.Item>{this.props.title} ({this.props.key_value})</Breadcrumb.Item>
            </Breadcrumb>

            <ValuePairsForm key_value={this.props.key_value} showDrawer={showDrawer} initialData={initialData} onClose={() => this.toggeleDrawer()} />

            <ContentArea>
                <Row>
                    <Col flex="auto"><ListHeader title={this.props.title} totalCount={(data && data.length) || '0'} /></Col>
                    <Col><Button onClick={() => this.toggeleDrawer()} icon={<Icon icon='plus' />}>Add Value</Button></Col>
                </Row>

                <Table loading={loading}
                    // title={() => <div>Total records ({data ? data.length : '0'})</div>}
                    columns={this?.props?.columns ? this.props.columns({ onEditClick: this.onEditClick, onDeleteClick: this.onDeleteClick }) : this.columns}
                    dataSource={data ? data : []}
                    pagination={false}
                    rowClassName={(record => {
                        return record.status == 'hidden' ? 'show-menu-on-hover disabled-table-row' : "show-menu-on-hover";
                        // return record.status == 'disabled-table-row disabled' ? 'disabled-table-row disabled-table-row' : "";
                    })}

                // onChange={this.handleTableChange}
                />

            </ContentArea>
        </>)
    }
}
export const List = withApollo(ListComp)


function Subscriber(props) {
    let filter = { key: props.key_value };

    const { data, loading, error } = useSubscription(
        QUERY_SUBSCRIPTION,
        {
            // subscription: QUERY,
            variables: { filter: JSON.stringify(filter) },
            // fetchPolicy: "network-only" // cache-first, cache-only, cache-and-network, network-only, no-cache, standby
            onSubscriptionData: ({ client, subscriptionData }) => {
                const { data, error, loading, variables } = subscriptionData
                // if (subCallback) subCallback(subscriptionData)
            },
            shouldResubscribe: false, // Determines if your subscription should be unsubscribed and subscribed again
        }
    );

    return <List {...props} key_value={props.key_value} subscriptionData={data?.valuePairsUpdated} />;

}

export default Subscriber;