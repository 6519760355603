import React from 'react';
import { addModule, addReducers } from '../connector';
import Users from './Home';
import MyProfile from './MyProfile';
// import {Icon} from 'common/components'
// import { verifyRole } from 'Common/scripts/Security';
import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT, MODULE } from './constants'

export const modulePermissions = getmodulePermissions('user-management');

if (modulePermissions['user.module-access']) {
  addModule(
    { path: `${ROOT}`, component: Users, icon: 'users', title:"Users", exact:1, leftNav:true },
  )
}

addModule(
  { path: `/profile`, component: MyProfile, exact: true, leftNav: false },
)



