import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form'
import _ from 'lodash'
import { Checkbox as _Checkbox, Input as _Input, InputNumber as _InputNumber, Switch, Alert, Select as _Select, Upload, Modal, message, Radio as _Radio, Tooltip, Space } from 'antd';
import ImgCrop from 'antd-img-crop';
import { Row, Col } from 'antd';
// import { getBase64 } from 'Common/scripts/Functions';
// import { __warning, __error, __success, __hilight } from 'Common/scripts/consoleHelper';
import { Button, Icon } from '../';
import UploadField from './UploadField';
import FileField from './FileField';
import HtmlField from './HtmlField';
import DateField from './DateField';
import {DateRangeField} from './DateRangeField';
import { TimeField, TimeRangeField, TimePicker } from './TimeField'
import InputMask from "react-input-mask"; 
import { RenderError } from './RenderError';
import { rules } from '.';

// const { Search } = _Input;

export const Label = props => <label className="form-field-label">{props.children}</label>




/******** Field Usage*****************
 * 
 * Simple Default Input
    <Field name="firstName" component="input" placeholder="First Name" />

 * An Arbitrary Reusable Input Component
    <Field name="interests" component={InterestPicker} />

 * Render Function
    <Field
          name="bio"
          render={({ input, meta }) => (
            <div>
              <label>Bio</label>
              <textarea {...input} />
              {meta.touched && meta.error && <span>{meta.error}</span>}
            </div>
          )}
        />

 * Render Function as Children
    <Field name="phone">
          {({ input, meta }) => (
            <div>
              <label>Phone</label>
              <input type="text" {...input} placeholder="Phone" />
              {meta.touched && meta.error && <span>{meta.error}</span>}
            </div>
          )}
        </Field>

 */


export const SearchField = props => {
    return <_Input.Search {...props} />;
}
SearchField.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    enterButton: PropTypes.string,
    size: PropTypes.string, // large | default | small
    placeholder: PropTypes.any,
    suffix: PropTypes.oneOfType([
        PropTypes.string, PropTypes.object
    ]),
    addonAfter: PropTypes.oneOfType([
        PropTypes.string, PropTypes.object
    ]),
    addonBefore: PropTypes.oneOfType([
        PropTypes.string, PropTypes.object
    ]),
}

/*****
 <FormField
    name="username"
    label="User name"
    type="text"
    placeholder="User name"
    validate={composeValidators(rules.required, rules.minChar(6))} />
    prefix={Node | String}
    addonAfter={Node | String}
    addonBefore={Node | String}
 */
export const FormField = props => {

    if (!props.type) return <Alert message="Undefined field type" type="warning" showIcon />
    if (!props.name) return <Alert message="Undefined field name" type="warning" showIcon />
    
    else if (
            props.type == 'text'
            || props.type == 'password'
            || props.type == 'email'
            // || props.type == 'number'
        ) return TextField(props);
    
    else if (props.type == 'mask') return MaskedField(props);
    else if (props.type == 'phone') return PhoneField(props);

    else if (props.type == 'textarea') return TextareaField(props);
        
    else if (props.type == 'number') return NumberField(props);
        
    else if (props.type == 'switch') return SwitchField(props);
    else if (props.type == 'checkbox') return CheckboxField(props);

    else if (props.type == 'select') return SelectField(props);

    else if (props.type == 'select-multiple') return SelectFieldMultiple(props);
        
    else if (props.type == 'upload') return UploadField(props);
    else if (props.type == 'file') return FileField(props);
    
    else if (props.type == 'html') return HtmlField(props);
    
    else if (props.type == 'date') return DateField(props);

    else if (props.type == 'date-range') return DateRangeField(props);
    
    else if (props.type == 'time') return TimeField(props);
    
    else if (props.type == 'timerange') return TimeRangeField(props);

    else if (props.type == 'radio') return RadioField(props);
    else if (props.type == 'single-radio') return SingleRadioField(props);


    // else if (props.type == 'time') return TimeField(props);
    // else if (props.type == 'time&time') return TimeAndDateField(props);
    
    else return <Alert message={`Undfined input type (${props.type})`} type="warning" showIcon />

}
export default FormField;
FormField.propTypes = {
    width: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.any,
    placeholder: PropTypes.any,
    required: PropTypes.bool,
    // labelB: PropTypes.any,
    prefix: PropTypes.oneOfType([
                PropTypes.string, PropTypes.object
            ]),
    addonAfter: PropTypes.oneOfType([
                PropTypes.string, PropTypes.object
            ]),
    addonBefore: PropTypes.oneOfType([
                PropTypes.string, PropTypes.object
            ]),
}



export const RadioField = props => {
    const propTypes = {
        horizontal: PropTypes.bool,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
        inputProps: PropTypes.object,
    }

    if (!props.data) return <p>No data available</p>

    const __Radio = ({ input, children }) =>
        // input should contain checked value to indicate
        // if the input is checked
        (<label className={`${props.horizontal ? 'isHorizontal' : ''}`}><input type="radio" {...input} /><span style={{ marginLeft: '10px' }}>{children}</span></label>)
        // console.log(input) || (
        //     <label className={`${props.horizontal ? 'isHorizontal' : ''}`}><input type="radio" {...input} /><span style={{marginLeft:'10px'}}>{children}</span></label>
        // );

    return (<>
        <div className={`form-field ${!props.compact && "field-margins"} radio ${props.className}`} style={props.wrapperStyle}>
            {props.label && <label>{props.label}</label>}
            <Row gutter={props.size || [10]}>
                {props.data.map((o, i) => {
                    return (<Col key={i} span={o.span}>
                        <Field name={props.name} type="radio" value={o.value || o.label} component={__Radio} key={i} validate={props.validate}>
                            {o.label && <>{o.label}</>}
                        </Field>

                    </Col>)
                })}
            </Row>
            {/* <Space style={{ width: "100%" }} direction={props.direction || 'vertical'} wrap size={props.size || 20}>
                {props.data.map((o, i)=>{
                    return(
                        <Field name={props.name} type="radio" value={o.value || o.label} component={__Radio} key={i}>
                            {o.label && <>{o.label}</>}
                        </Field>
                    )
                })}
            </Space> */}
        </div>
    </>)
}


export const SingleRadioField = props => {
    const propTypes = {
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        validate: PropTypes.object,
        checked: PropTypes.bool,
    }

    let isChecked = undefined;
    if (props.checked === true) isChecked = true;
    if (props.checked === false) isChecked = false;

    const __Radio = ({ input, children }) => {
        // console.log("input: ", input)
        return (<label><input type="radio" 
            {...input} // checked={isChecked} 
            // checked={true}
            /><span style={{ marginLeft: children ? '10px' : '0px' }}>{children}</span></label>)
    }

    return (<>
        <div className={`form-field ${!props.compact && "field-margins"} radio ${props.className}`} style={props.wrapperStyle}>
            <span style={props.style}>
                <Field 
                    // checked={true}
                    id={props.id} 
                    name={props.name} 
                    validate={props.validate} 
                    type="radio" 
                    value={props.value} 
                    component={__Radio}>
                    {props.label}
                </Field>
                {/* <label htmlFor={props.id}><Field id={props.id} name={props.name} validate={props.validate} type="radio" value={props.value} component={__Radio}>
                    {props.label !== null && <span style={props.label_style}>{props.label}</span>}
                </Field></label> */}
            </span>
        </div>
    </>)
}



/*******
 * name={string}
 * placeholder={string}
 * type={string} // The type of input, see: MDN(use Input.TextArea instead of type="textarea")
 * size={large | middle | small}
 * prefix={string | ReactNode}
 * suffix={string | ReactNode}
 * validate={composeValidators(rules.required, rules.minChar(6))} />
 */
export const TextField = props => {
    const propTypes = {
        name: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
        inputProps: PropTypes.object,
    }

    let _props = { ...props }
    delete _props.inputProps;
    delete _props.data;
    delete _props.label;
    delete _props.width;
    delete _props.style;
    delete _props.onChange;
    delete _props.compact;
    delete _props.validate;
    delete _props.wrapperStyle;

    let style = { width: "100%", ...props.style }
    if (props.width) style = Object.assign(style, { width: props.width })

    let _inputProps = { ...props.inputProps };
    if (props.disabled) _inputProps = Object.assign(_inputProps, { disabled:props.disabled })

    // if (props.name == 'sin' || props.name == 'phone'){
    //     console.log(`(${props.name}) props.validate : `)
    //     props.validate.forEach(element => {
    //         console.log("element: ", element)
    //     });
    // }

    return (
        <Field {...props}>
            {({ input, meta }) => { 
                if (props.onChange) {
                    _inputProps.onChange = (e) => props.onChange(e, input.onChange);
                }

                let isRequired = props.validate ? props?.validate?.toString().includes("Required") : false;

                // {props.validate ? <span style={{fontSize:"8px", marginTop:"-10px", position:"relative", display:"inline-block"}}><Icon icon="star-of-life" color="red" /></span> : ''}
                let className = `form-field ${isRequired && 'validate'} ${((meta.error && meta.touched) || "") && 'has-errors'} ${!props.compact && "field-margins"} ${props.className || ""}`;

                return (
                    <div className={`${className} input`} style={props.wrapperStyle}>
                        {props.label && <label>{props.label} </label>}
                        <div style={{ position:"relative"}}>
                            <_Input {...input} {..._inputProps} {..._props} style={{ ...style }} 
                                // size={props.size || "default"} // placeholder={props.placeholder} // prefix={props.prefix} // suffix={props.suffix}
                            />
                            <RenderError meta={meta} showIcon />
                        </div>
                    </div>
                )
            }}
        </Field>
    )
}

export const TextareaField = props => {
    const propTypes = {
        name: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
    }

    let _props = { ...props }
    delete _props.inputProps;
    delete _props.data;
    delete _props.label;
    delete _props.width;
    delete _props.style;
    delete _props.onChange;
    delete _props.compact;
    delete _props.validate;
    delete _props.wrapperStyle;

    let style = { width: "100%", ...props.style }
    if (props.width) style = Object.assign(style, { width: props.width })

    let _inputProps = { ...props.inputProps };
    if (props.disabled) _inputProps = Object.assign(_inputProps, { disabled: props.disabled })

    
    return (
        <Field {...props}>
            {({ input, meta }) => {
                let className = `form-field ${props.validate && 'validate'} ${(meta.error && meta.touched) && 'has-errors'} ${!props.compact && "field-margins"} ${props.className}`;
                
                return (<div className={`${className} input`} style={props.wrapperStyle}>
                    {props.label && <label>{props.label}</label>}
                    <div style={{ position: "relative" }}>
                        <_Input.TextArea {...input} {..._inputProps}
                            {..._props}
                            rows={props.rows || 4}
                            style={{ ...style }}
                            // size={props.size || "default"}
                            // placeholder={props.placeholder}
                            // prefix={props.prefix}
                            // suffix={props.suffix}
                        />
                        <RenderError meta={meta} showIcon />
                    </div>
                </div>)
            }}
        </Field>
    )
}

export const NumberField = props => {
    const propTypes = {
        name: PropTypes.string.isRequired,
        min: PropTypes.number,
        max: PropTypes.number,
        disabled: PropTypes.bool,
    }

    let _props = { ...props }
    delete _props.inputProps;   delete _props.data; delete _props.label;    delete _props.width;    delete _props.style;    delete _props.type; delete _props.compact;  delete _props.validate;

    let style = { width: "100%", ...props.style }
    if (props.width) style = Object.assign(style, { width: props.width })

    let _inputProps = { ...props.inputProps };
    if (props.disabled) _inputProps = Object.assign(_inputProps, { disabled: props.disabled })

    return (
        <Field {...props}>
            {({ input, meta }) => {
                if (props.onChange) _inputProps.onChange = (e) => props.onChange(e, input.onChange);
                let className = `form-field ${props.validate && 'validate'} ${(meta.error && meta.touched) && 'has-errors'} ${!props.compact && "field-margins"} ${props.className}`;

                return (
                    <div className={`${className} input-number`} style={props.wrapperStyle}>
                        {props.label && <label>{props.label}<br /></label>}
                        <div style={{ position: "relative" }}>
                            <_InputNumber {...input} {..._inputProps} {..._props} style={{ ...style }} /* size={props.size || "default"} // prefix={props.prefix} // suffix={props.suffix} */ />
                            <RenderError meta={meta} showIcon />
                        </div>
                    </div>
                )
            }}
        </Field>
    )
}

export const SwitchField = props => {
    let _inputProps = { ...props.inputProps };
    if (props.disabled) _inputProps = Object.assign(_inputProps, { disabled: props.disabled })

    let _props = { ...props };
    delete _props.inputProps;
    delete _props.data;
    delete _props.label;
    delete _props.width;
    delete _props.style;
    delete _props.type;
    delete _props.compact;
    delete _props.validate;

    return (
        <Field name={props.name} validate={props.validate}>
            {({ input, meta }) => { 
                let _input = { ...input}
                delete _input.checked;
                delete _input.value;
                
                return (
                    <div className={`form-field ${!props.compact && "field-margins"} switch ${props.className}`} style={props.style}>
                        {/* <input {...input} type={props.type} placeholder={props.placeholder} /> */}
                        <span>
                            <Switch {..._input} {..._inputProps} {..._props}
                                checkedChildren={props.checkedText || <Icon icon="check" />}
                                unCheckedChildren={props.uncheckedText || <Icon icon="times" />}
                                defaultChecked={input.value ? true : props.defaultChecked}
                            />
                            {props.label && <label style={{ padding:"0px", marginLeft:"5px", display:"inline-block", border:"0px solid black" }}>{props.label}</label>}
                            <RenderError meta={meta} />
                        </span>
                    </div>
                )
            }}
        </Field>
    )
}

export const CheckboxField = props => {
    let _inputProps = { ...props.inputProps };
    if (props.disabled) _inputProps = Object.assign(_inputProps, { disabled: props.disabled })

    let _props = { ...props };
    delete _props.inputProps;
    delete _props.data;
    delete _props.label;
    delete _props.width;
    delete _props.style;
    delete _props.type;
    delete _props.compact;
    delete _props.validate;

    return (
        <Field name={props.name} type="checkbox" validate={props.validate}>
            {({ input, meta }) => { 
                let _input = { ...input}
                delete _input.checked;
                delete _input.value;

                {/* if (props.onChange) {
                    _props.onChange = (e) => {
                        props.onChange(e);
                        input.onChange(e)
                    };
                } */}


                return (
                    <div className={`form-field ${!props.compact && "field-margins"} checkbox ${props.className}`}>
                        <Row style={{flexWrap:"nowrap"}}>
                            <Col style={{paddingRight:"5px"}}><Checkbox 
                                {..._input} 
                                {..._inputProps} 
                                {..._props} 
                                defaultChecked={input.checked ? true : props.defaultChecked} 
                                onChange={(e=>{
                                    let response = e.target.checked;
                                    _input.onChange(response)
                                    if (props.onChange) props.onChange(response)
                                    return response;
                                })}
                            /></Col>
                            {props.label && <Col flex="auto"><label>{props.label}</label></Col>}
                        </Row>
                        <RenderError meta={meta} />
                    </div>
                )
            }}
        </Field>
    )
}

/*********
 **** Searchable Field -------------------
    <FormField validate={props.validate}
        type="select" name="parent_cat_id" label="Parent Category" data={data}
        placeholder="Search category"
        keyMap="_id=>title"
        fieldProps={{
            showSearch:true,
            defaultActiveFirstOption: false,
            showArrow:false,
            notFoundContent : fetching ? <Spin size="small" /> : null,
            filterOption : false,
            onSearch : fetchUser,
            onChange : onChange,
        }}
    />
 */
export const SelectFieldMultiple = props => {
    const propTypes = {
        name: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
        addNull: PropTypes.bool,
        label: PropTypes.string,
    }

    let _inputProps = { ...props.inputProps };
    if (props.disabled) _inputProps = Object.assign(_inputProps, { disabled: props.disabled })

    const keyMap = props.keyMap ? props.keyMap.split("=>") : ['_id', 'title']


    const onSelect = (value, node) => {
        if (!props.data) return;

        let _node = props?.data?.find(o => o._id == value);
        let raw = _node ? _.cloneDeep(_node?.raw) : false;

        if (raw) {
            delete raw.__typename;
            delete raw.error;
        }

        if (props.onSelect) props.onSelect(value, node, raw);
        return value;
    }


    return (
        <Field name={props.name} validate={props.validate}>
            {({ input, meta }) => {
                
                let value = (!input.value || input.value == null || input.value == "null") ? undefined : input.value;
                // if (input.value && input.value!=undefined && _.isString(input.value) && input.value.indexOf(",") > -1 && multiple) value = input.value.split(",");
                // else if (input.value && input.value != undefined ) value = String(input.value);

                let defaultVal = props.data && props?.data?.find(o => o.default==true)
                {/* searchArrayByObjVal(props.data, 'default', true) : undefined; */}
                defaultVal = (defaultVal && defaultVal._id) || undefined; 

                let list_data = props.data ? [...props.data] : [];
                if (props.addNull) list_data.unshift({ _id: null, title: " " });

                let className = `form-field ${props.validate && 'validate'} ${(meta.error && meta.touched) && 'has-errors'} ${!props.compact && "field-margins"} ${props.className}`;
                
                return (
                    <div className={`${className}`} style={props.wrapperStyle}>
                        {props.label && <label>{props.label}</label>}
                        <div style={{ position: "relative" }}>
                            <_Select 
                                loading={props.loading}
                                {..._inputProps}
                                {...input}
                                onSelect={onSelect}
                                value={value}
                                defaultValue={defaultVal}
                                placeholder={props.placeholder}
                                style={{ width: props.width || '100%' }}
                            > 
                                {list_data.map((item, i) => {
                                    if (!item) return null;
                                    let _key = i;
                                    let _value = "";
                                    let _label = "";

                                    if (_.isString(item)){
                                        _value = String(item);
                                        _label = String(item);
                                    }else{
                                        _value = String(item[keyMap[0]]);
                                        _label = String(item[keyMap[1]]);
                                    }
                                    _key = `${_value}_${i}`;

                                    {/* if (_.isString(item))
                                        return <_Select.Option key={String(item)+i}>{String(item)}</_Select.Option>
                                    return <_Select.Option key={i} value={String(item[keyMap[0]])}>{String(item[keyMap[1]])}</_Select.Option> */}

                                    return <_Select.Option key={_key} value={_value}>{_label}</_Select.Option>
                                })}
                            </_Select>
                            <RenderError meta={meta} showIcon />
                        </div>
                        {/* <_Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode">
                            {list_data.map((item, i) => {
                                if (!item) return null;
                                if (_.isString(item))
                                    return <_Select.Option key={String(item)}>{String(item)}</_Select.Option>
                                return <_Select.Option key={i} value={String(item[keyMap[0]])}>{String(item[keyMap[1]])}</_Select.Option>
                            })}
                        </_Select> */}
                        {/* {meta.error && meta.touched && <div className="field-error">{meta.error}</div>} */}
                    </div>
                )
            }}
        </Field>
    )
}

export const SelectField = props => {
    const propTypes = {
        name: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
        addNull: PropTypes.bool,
        label: PropTypes.string,
    }

    let _props = { ...props };
    delete _props.inputProps;
    delete _props.keyMap;
    delete _props.data;
    delete _props.addNull;
    delete _props.label;
    delete _props.width;
    delete _props.compact;
    delete _props.validate;
    delete _props.itemRender;
    delete _props.onSelect;

    let _inputProps = { ...props.inputProps };
    if (props.disabled===true) _inputProps = Object.assign(_inputProps, { disabled: true })

    
    var keyMap = props.keyMap || '_id=>title';

    if (_.isFunction(keyMap)) keyMap = (val) => props.keyMap(val);
    else if (_.isString(keyMap)) {
        let temp = keyMap.split("=>");
        keyMap = (val) => [val[temp[0]], val[temp[1]]];
    }

    const onSelect = (value, node) => {
        if (!props.data) return;

        let _node = props?.data?.find(o => o._id == value);
        let raw = _node ? _.cloneDeep(_node?.raw) : false;
        if (raw){
            delete raw.__typename;
            delete raw.error;
        }

        if (props.onSelect) props.onSelect(value, node, raw);
    }

    return (
        <Field name={props.name} validate={props.validate}>
            {({ input, meta }) => {
                if (props.onChange) {
                    _props.onChange = (e) => props.onChange(e, input.onChange);
                }

                let value;
                if (input.value && _.isArray(input.value)) value = input.value;
                else if (input.value) value = String(input.value);

                let defaultVal = props.data && props?.data?.find(o=> o.default==true) 
                {/* searchArrayByObjVal(props.data, 'default', true) : null; */}
                // defaultVal = (defaultVal && defaultVal._id) || undefined;
                if (!_props.defaultValue && defaultVal && defaultVal._id) {
                    _props.defaultValue = defaultVal;
                }

                let list_data = props.data ? [...props.data] : [];
                if (props.addNull) list_data.unshift({ _id: null, title: " " });

                let hasErrors = (meta.error && meta.touched && !props.disabled);

                let className = `form-field ${props.validate && 'validate'} ${hasErrors && 'has-errors'} ${!props.compact && "field-margins"} ${props.className}`;

                return (
                    <div className={`${className} select`}>
                        {props.label && <label>{props.label}</label>}
                        <div style={{ position: "relative" }}>
                            <_Select
                                {..._inputProps}
                                {...input}
                                {..._props}
                                value={value}
                                onSelect={onSelect}
                                style={{ width: props.width || '100%' }}
                            >
                                {list_data.map((item, i) => {
                                    if (!item) return null;
                                    let optProps = { key: i };
                                    const optStyle = { ...item.style }
                                    if (props.coloredItems && item.itemBgColor) {
                                        Object.assign(optStyle, { backgroundColor: item.itemBgColor || "#FFFFF" });
                                    }

                                    if (item.divider) {
                                        Object.assign(optProps, { label: item.divider, disabled: true, className: 'dd-divider' });
                                    }
                                    else {
                                        if (_.isString(item)) Object.assign(optProps, { label: item });
                                        else{
                                            let temp = keyMap(item);
                                            Object.assign(optProps, { label: temp[1], value: temp[0] });
                                            {/* Object.assign(optProps, { label: item[keyMap[1]], value: item[keyMap[0]] }); */}
                                        }

                                        if (item.disabled) Object.assign(optProps, { disabled: item.disabled });
                                        if (item.className) Object.assign(optProps, { className: item.className });
                                    }

                                    return <_Select.Option style={optStyle} {...optProps}>{props.itemRender ? props.itemRender(item) : optProps.label}</_Select.Option>
                                    {/* return <_Select.Option style={optStyle} {...optProps}>{optProps.label}</_Select.Option> */}

                                })}

                                {/* {list_data.map((item, i) => {
                                    if (!item) return null;
                                    if (_.isString(item))
                                        return <_Select.Option key={String(item)}>{String(item)}</_Select.Option>
                                    return <_Select.Option key={i} value={String(item[keyMap[0]])}>{String(item[keyMap[1]])}</_Select.Option>
                                })} */}
                            </_Select>
                            {hasErrors && <RenderError meta={meta} showIcon />}
                        </div>
                        {/* {meta.error && meta.touched && <div className="field-error">{meta.error}</div>} */}
                    </div>
                )
            }}
        </Field>
    )
}

export const UploadImageCrop = props => {
    // https://github.com/nanxiaobei/antd-img-crop

    const propTypes = {
        limit: PropTypes.number,
        aspect: PropTypes.number,  // {width/height}
        listType: PropTypes.string, // text, picture, picture-card
        label: PropTypes.string,
        buttonLabel: PropTypes.string,
        grid: PropTypes.bool,
        zoom: PropTypes.bool,
        rotate: PropTypes.bool,
    }

    const uploadButton = <Button>{props.buttonLabel || <><Icon icon="upload" /> Upload</>}</Button>;

    const [fileList, setFileList] = React.useState([
        // {
        //     uid: '-1',
        //     name: 'image-image-image-image-image-image-image-image-image-image.png',
        //     status: 'done',
        //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
    ]);

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    return (
        <div className={`form-field ${!props.compact && "field-margins"} upload-image-crop`}>
            {props.label && <label>{props.label}<br /></label>}

            <ImgCrop rotate aspect={props.aspect || (1/1)}>
                <Upload
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType={props.listType || "picture-card"}
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                >
                    {fileList.length < (props.limit || 1) && uploadButton}
                </Upload>
            </ImgCrop>
        </div>
    )
}

// export const CheckboxField = props => { }

export const Select = props => {
    let _props = { ...props}
    const keyMap = _props.keyMap ? _props.keyMap.split("=>") : ['_id', 'title']

    if (props.width) _props.style = Object.assign({..._props.style}, { width: props.width || '100%'})

    var data = props.data;
    if (props.addNull && data[0]._id) data.unshift({ _id: "", title: "" });

    delete _props.keyMap;
    delete _props.data;
    delete _props.addNull;
    delete _props.width;
    delete _props.label;
    delete _props.compact;
    delete _props.validate;

    return (<div className="simple-field">
        {props.label && <Label>{props.label}</Label>}
        <_Select {..._props}>
            {data.map((item, i) => {
                if (!item) return null;
                let optProps = { key: i };
                const optStyle = {}
                if(props.coloredItems && item.itemBgColor){
                    Object.assign(optStyle, { backgroundColor: item.itemBgColor || "#FFFFF" });
                }

                if (item.divider){
                    Object.assign(optProps, { label: item.divider, disabled: true, className:'dd-divider' });
                }
                else{
                    if (_.isString(item)) Object.assign(optProps, { label: item });
                    else Object.assign(optProps, { label: item[keyMap[1]], value: item[keyMap[0]] });
                    
                    if(item.disabled) Object.assign(optProps, { disabled: item.disabled });
                    if (item.className) Object.assign(optProps, { className: item.className });
                }

                return <_Select.Option style={optStyle} {...optProps}>{optProps.label}
                    {/* {`${optProps.label}`} */}
                    {/* <div><Icon icon="circle" color="#FF0000" style={{ marginRight: "10px" }}/>{`${optProps.label}`}</div> */}
                    </_Select.Option>


                // if (_.isString(item)) return <_Select.Option key={i} disabled={item.disabled}>{`${label}`}</_Select.Option>
                // return <_Select.Option key={i} value={`${val}`} >{`${label}`}</_Select.Option>
            })}
        </_Select>
        </div>
    )
}
Select.propTypes = {
    coloredItems: PropTypes.bool,
}


export const Input = props => {
    let _props = { ...props }
    
    if (props.width) _props.style = Object.assign({..._props.style}, { width: props.width || '100%'})
    
    delete _props.addNull;
    delete _props.width;
    delete _props.type;
    delete _props.label;
    delete _props.compact;
    delete _props.validate;

    return (<div className="simple-field">
        {props.label && <Label>{props.label}</Label>}
        {(props.type && props.type == 'number') && <_InputNumber {..._props} />}
        {(!props.type || props.type != 'number') && <_Input {..._props} />}
        </div>)
    
}

export const Checkbox = props => {
    return (<_Checkbox {...props} />)
}



export const MaskedField = props => {
    /* USAGE
    <FormField
        // phone
        // placeholder="+1 XXX XXX XXXX"
        mask="+\1 999-999-9999"
        label="Mobile/Phone Number"
        name="phone"
        type="mask"
        validate={composeValidators(
            rules.required,
            rules.minChar(12, (val) => val.replace(/_/, "")),
            rules.maxChar(12, (val) => val.replace(/_/, "")),
        )}
    />
    */
    let _props = { ...props }
    delete _props.data;
    delete _props.label;
    delete _props.width;
    delete _props.style;
    delete _props.onChange;
    delete _props.compact;
    delete _props.validate;
    delete _props.wrapperStyle;
    delete _props.wrapperClass;
    delete _props.phone;
    delete _props.type;
    delete _props.mask;
    delete _props.mask;
    

    let style = { width: "100%", ...props.style }
    if (props.width) style = Object.assign(style, { width: props.width })

    // let _inputProps = { ...props.inputProps };
    // if (props.disabled) _inputProps = Object.assign(_inputProps, { disabled:props.disabled })

    function handlePaste(e) {
        var clipboardData, pastedData;

        // Stop data actually being pasted into div
        e.stopPropagation();
        e.preventDefault();

        // Get pasted data via clipboard API
        clipboardData = e.clipboardData || window.clipboardData;
        pastedData = clipboardData.getData('text/plain');
        // trim and remove +1 from +1 825-254-9595
        pastedData = String(pastedData).trim().replace(/ |-/g, "").replace(/^\+[0-9]/, '');  //.replace(/-/g, ""); //.replace(/^\+[0-9]/, '');

        // if more than 10 char, then remove first char
        if (pastedData.length > 10) pastedData = String(pastedData).trim().replace(/^[0-9]/, '');

        window.document.execCommand('insertText', false, pastedData);
    }

    return (<Field
        validate={props.validate}
        name={props.name}
        parse={value =>
            value
                .replace(/\)/g, "")
                .replace(/\(/g, "")
                .replace(/-/g, "")
                .replace(/ /g, "")
        }
        render={({ input, meta }) => {
            // if (props.onChange) {
            //     _props.onChange = (e) => props.onChange(e, input.onChange);
            // }

            let className = `form-field ${props.validate && 'validate'} ${(meta.error && meta.touched) && 'has-errors'} ${!props.compact && "field-margins"} ${props.className}`;

            return (<div style={props.wrapperStyle || {}} className={`${className} input ${props.wrapperClass}`}>
                {props.label && <label>{props.label}</label>}

                <InputMask
                    disabled={props.disabled}
                    mask={props.mask || "+1 999-999-9999"} //"+\92 (999) 999-99-99"
                    {...input}
                    onPaste={handlePaste}
                    onFocus={(e) => console.log("onFocus")}
                    onBlur={(e) => console.log("onBlur")}
                    // onChange={(e)=>{
                    //     let val = e.target.value;
                    //         val = val.replace(/\)/g, "").replace(/\(/g, "").replace(/-/g, "").replace(/ /g, "").replace(/_/g, "")
                    //     console.log(val);
                    //     return e
                    // }}
                    style={{ ...style }}
                >
                    {InputProps => {
                        return <_Input {...InputProps} placeholder={props.placeholder} disabled={props.disabled} />;
                    }}
                </InputMask>

                {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
            </div>)
        }}
    />)

}
MaskedField.propTypes = {
    phone: PropTypes.bool, 
    placeholder: PropTypes.string, 
    type: PropTypes.string.isRequired, 
    name: PropTypes.string.isRequired,
    mask: PropTypes.string,
    label: PropTypes.string,
    validate: PropTypes.func,
}
MaskedField.defaultProps = {
    phone: true,
    // mask: "+\1 999-999-9999",
    placeholder:"+1 XXX XXX XXXX",
}


export const PhoneField = props => {
    return <MaskedField
        {...props}
        mask="+\1 999-999-9999"
        type="mask"
        // validate={composeValidators(
        //     rules.required,
        //     rules.minChar(12, (val) => val.replace(/_/, "")),
        //     rules.maxChar(12, (val) => val.replace(/_/, "")),
        // )}
    />
}