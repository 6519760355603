import React from 'react'
import { Row, Col } from 'antd';

export const DataRow = ({ col1, col2, inline, size, vertical, style }) => {
    let className = 'data-row';
    if (inline) className += ' inline'

    return (<>
        <Row className={className} style={style}>
            <Col className='col1' flex={vertical ? "100%" : size ? size[0] : ''}>{col1}</Col>
            <Col className='col2' flex={size ? size[1] : ''}>{col2}</Col>
        </Row> 
    </>)
}
