import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Breadcrumb, Popconfirm, Alert, message, Row, Col, Input, Tabs } from 'antd';
import { ContentArea } from "Layout_v1";
import { Table, Loader, Icon, Button, IconButton, DeleteButton } from 'Common/components';
import { PlusOutlined, HomeOutlined } from '@ant-design/icons';
import AddForm from './components/LeadForm';
import { modulePermissions } from '.';
import { defaultDateFormat, defaultPageSize, uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import { ListHeader } from 'Common/components/Typography';
import moment from 'moment';
import { removeSpecialChar } from 'Common/scripts/Functions'
import { __error } from 'Common/scripts/consoleHelper';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';

const LIST_DATA = loader('src/Graphql/leads/leadsQuery.graphql');
const DELETE = loader('src/Graphql/leads/deleteLeads.graphql');

class LeadsComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {},
            loading: false,
            busy: false,
            data: null,
            error: null,
            visible: false,
            pagination: { current: 1, pageSize: defaultPageSize },
            
            showDrawer:false,
            initialData: null,
            activeTab:"open"
        }
        this.fetchData = this.fetchData.bind(this)
        this.onListUpdate = this.onListUpdate.bind(this)
        this.doSearch = this.doSearch.bind(this)
    }

    componentDidMount(){
        this.fetchData()
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ current: pagination.current, pageSize: pagination.pageSize });
    };

    fetchData = async (args = {}) => {
        const variables = {
            first: args.pageSize || this.state.pagination.pageSize, // number of rec to fetch
            after: (args.pageSize || this.state.pagination.pageSize) * ((args.current || this.state.pagination.current) - 1),
            filter: JSON.stringify({ ...this.state.filter, ...args.filter, status: this.state.activeTab }),
            others: JSON.stringify({ sort: { name: 0 } })
        }

        this.setState({ loading: true })

        this.props.client.query({ query: LIST_DATA, variables }).then(r => {
            const response = r.data.leadsQuery;

            if (response.error) {
                this.setState({ loading: false, error: response.error.message });
                return;
            }

            this.setState({
                loading: false,
                data: response,
                pagination: {
                    ...this.state.pagination,
                    current: args.current || 1,
                    pageSize: args.pageSize || this.state.pagination.pageSize,
                    total: response.totalCount
                }
            });

        }).catch(err => {
            console.log("ERROR: ", err);
            this.setState({ busy: false, error: "Query Error" });
        })
    }

    async delete(_id){
        if (!modulePermissions['leads.delete']) return;
        
        this.onListUpdate('remove', { _id });

        const results = await this.props.client.mutate({ mutation: DELETE, variables: { id: _id } }).then(r => (r.data.deleteLeads))
        .catch(err => {
            console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
            return { error: { message:"Query Error!" } }
        })

        if (results.error){
            message.error(results.error.message);
            return false;
        }

    }

    renderTitleColumn = (text, rec) => {
        //to={`/admin/client_details/${row?._id}`}
        return (<div className='show-menu-on-hover'>
            <div className='hidden-menu'>
                {modulePermissions['leads.edit'] && <IconButton icon="pen" onClick={() => this.toggeleDrawer(rec)} />}
                {modulePermissions['leads.delete'] && <DeleteButton onClick={() => this.delete(rec?._id)} />}
            </div>
            <div><Link to={`${uriRoot}/leads/id/${rec._id}`}>{rec.title}</Link></div>
        </div>)
    }

    columns = [
        // { title: 'ID', dataIndex: '_id', width: "100px" },
        { title: 'Title', render: this.renderTitleColumn, width:"300px" },
        { title: 'Created by', dataIndex: 'author.name', width: "150px", render: (__, rec) => rec.author.title },
        { title: 'Agent', dataIndex: 'agent_id', width: "150px" },
        { title: 'Client', dataIndex: 'client', width: "200px", render: (__, rec) => (rec.client.name) },
        { title: 'Site', dataIndex: 'site', width: "200px", render: (__, rec) => (rec.site.name) },
        { title: 'Rate', dataIndex: 'rate', width: "60px", render: (__, rec) => (rec.rate), align:"center" },
        { title: 'Status', dataIndex: 'status', width: "80px" },
        { title: 'Service Name', dataIndex: 'service_type', width: "200px", render: (__, rec) => rec?.service_type?.title },
        { title: 'Created at', dataIndex: 'created_at', width: "150px", render: (__, rec) => (moment(rec.created_at).format(`${defaultDateFormat} HH:mm`)) },
        // {
        //     title: '', render: (___, rec) => {
        //         return <div>
        //             {modulePermissions['leads.edit'] && <IconButton icon="pen" onClick={() => this.toggeleDrawer(rec)} />}
        //             {modulePermissions['leads.delete'] && <DeleteButton onClick={() => this.delete(rec?._id)} />}
        //         </div>
        //     },
        //     width: '100px', align:"center"
        // },

    ];

    toggeleDrawer(initialData=null){
        this.setState({ showDrawer: !this.state.showDrawer, initialData })
    }

    onListUpdate(action, node){
        // let data = this.state.data.slice();
        // let { totalCount } = this.state.data;
        let edges = this.state.data.edges.slice();
        let totalCount = this.state.data.totalCount

        
        if (action =='add'){
            totalCount++;
            edges.push(node);
        }

        if (action =='update'){
            edges = edges.map(o=>o._id==node._id ? node : o);
        }

        if (action =='remove'){
            totalCount--;
            edges = edges.filter(o => o._id != node._id);
        }

        this.setState({ data: { totalCount, edges }})

    }

    doSearch(value){
        // const fetchData = debounce(_fetchData, 800);
        let filter = {}
        if (value) filter = { search: { keywords: removeSpecialChar(value) } }

        this.setState({ filter }, ()=>{
            this.fetchData()
        })

    }

    onTabClicked(activeTab){
        this.setState({ activeTab }, () => this.fetchData());
    }

    

    render() {
        const { loading, error, data, pagination, showDrawer, initialData, activeTab } = this.state;

        if (error) return <Alert message="Error" description={error} type="error" showIcon />
        const _doSearch = debounce(this.doSearch, 800);
        
        return (<>
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item><b> Leads</b></Breadcrumb.Item>
            </Breadcrumb>
                
            <div style={{padding:"0 20px"}}>
                <Tabs onChange={(v) => this.onTabClicked(v)} tabBarStyle={{ marginBottom: "0" }} defaultActiveKey="open" size="large" type="card">
                    <Tabs.TabPane tab="Open" key="open" />
                    <Tabs.TabPane tab="Closed" key="closed" />
                    <Tabs.TabPane tab="Canceled" key="canceled" />
                </Tabs>
            </div>

            <ContentArea style={{marginTop:0}}>
                <Row gutter={[10]}>
                    <Col flex={"auto"}>
                        <ListHeader totalCount={(data && data.totalCount) || '0'} title={`${activeTab.charAt(0).toUpperCase() + activeTab.slice(1) } Leads`} />
                    </Col>
                    <Col><Input placeholder="keyword serch" suffix={<Icon icon="search" color="#DDD" />} onChange={(e) => _doSearch(e.target.value)} allowClear /></Col>
                    {modulePermissions['leads.add'] && <Col><Button onClick={() => this.toggeleDrawer()}>Create Lead</Button></Col>}
                </Row>

                <Table loading={loading}
                    // title={() => <b>Total {((data && data.totalCount) || '0')} record(s) found </b>}
                    columns={this.columns}
                    dataSource={data ? data.edges : null}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                />
            </ContentArea>

            <AddForm 
                onListUpdate={this.onListUpdate}
                showDrawer={showDrawer} 
                fields={initialData} 
                onClose={() => this.toggeleDrawer()} 
            />

        </>)
    }
}

export const Leads = withApollo(LeadsComp)
export default Leads;