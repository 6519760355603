export const filterGroups = [
    {
        title: "Schedule Info", key: "schedule_info", categories: ['schedule'],
        filters: [
            { title: "Guard", field: 'guard', type: "guard" },
            { title: "Site", field: 'site', type: "site" },

            { title: "Check-In Date", field: 'check_in', type: 'date' },
            { title: "Check-Out Date", field: 'check_out', type: 'date' },

            { title: "Shift Start Date", field: 'scheduled_shift_start', type: 'date' },
            { title: "Shift End Date", field: 'scheduled_shift_end', type: 'date' },
            
            { title: "Verification", field: 'approval_status', type: 'approval_status' },
            
            // { title: "Gender", field: 'approval_status', type: "dropdown.approval_status" },

            // stat
            // do_not_charge_client

            // recorded_by: ID
            // updated_by: ID
            // approved_by: ID

            // flag

            // prod_and_services

            // { title: "Number of orders", field: 'last_order_date', type: "number" },

        ]
    },
    // {
    //     title: "Order Info", key: "order_info",
    //     filters: [
    //         { title: "Order total", field: 'grand_total', type: "number" },
    //         { title: "Order item name", field: 'item', type: "dd_product.title" },
    //         { title: "Order item barcode", field: 'barcode', type: "dd_product.barcode" },
    //     ]
    // },
]

export const dateFarmulaOptions = [
    { title: "Today", description: "Today from midnight until the current time", _id: "today" },
    // { title: <ddLabel title="Today" description="Today from midnight until the current time" />, _id:"today" },
    { title: "Yesterday", description: "The previous 24 hour day", _id: "yesterday" },
    { title: "Tomorrow", description: "The next 24 hours day", _id: "tomorrow" },
    { title: "This week", description: "The current calendar week", _id: "current_week" },
    { title: "This week so far", description: "The current calendar week up to now", _id: "current_week_so_far" },
    { title: "Last week", description: "The previous calendar week", _id: "previous_week" },
    { title: "Next week", description: "The next calendar week", _id: "next_week" },
    
    { title: "First Salary Half - this month", description: "From 24th prev month to 9th this month", _id: "first_salary_half" },
    { title: "Second Salary Half - this month", description: "From 10th to 23rd this month", _id: "second_salary_half" },
    
    { title: "First Salary Half - previous month", description: "From 24th 2 months before to 9th of prev month", _id: "first_salary_half_prev_mo" },
    { title: "Second Salary Half - previous month", description: "From 10th to 23rd of prev month", _id: "second_salary_half_prev_mo" },
    
    { title: "This month", description: "The current calendar month", _id: "current_month" },
    // { title: "This month so far", description: "The current month up to now", _id: "current_month_so_far" },
    { title: "Last month", description: "The previoous calendar month", _id: "previous_month" },
    { title: "Next month", description: "The next calendar month", _id: "next_month" },
    { title: "This quarter", description: "The current quarter", _id: "current_quarter" },
    // { title: "This quarter so far", description: "The current quarter up to now", _id: "current_quarter_so_far" },
    // { title: "This fiscal quarter", description: "The current fiscal quarter", _id: "current_fiscal_quarter" },
    // { title: "This fiscal quarter so far", description: "The current fiscal quarter up to now", _id: "current_fiscal_quarter_so_far" },
    { title: "Last quarter", description: "The previous full quarter", _id: "previous_quarter" },
    // { title: "Last fiscal quarter", description: "The previous full fiscal quarter", _id: "previous_fiscal_quarter" },
    // { title: "Next quarter", description: "The next full quarter", _id: "next_quarter" },
    // { title: "Next fiscal quarter", description: "The next full fiscal quarter", _id: "next_fiscal_quarter" },

    { title: "This year", description: "The current calendar year", _id: "current_year" },
    // { title: "This fiscal year", description: "The current fiscal year", _id: "current_fiscal_year" },
    // { title: "This year so far", description: "The current calendar year up to now", _id: "current_year_so_far" },
    // { title: "This fiscal year so far", description: "The current fiscal year up to now", _id: "current_fiscal_year_so_far" },
    { title: "Last year", description: "The previous calendar year", _id: "previous_year" },
    // { title: "Last fiscal year", description: "The previous fiscal year", _id: "previous_fiscal_year" },
    // { title: "Next year", description: "The next calendar year", _id: "next_year" },
    // { title: "Next fiscal year", description: "The next fiscal year", _id: "next_fiscal_year" },
    
    { title: "Last 7 days", description: "The previous 7 days before today", _id: "previous_7_days" },
    { title: "Last 14 days", description: "The previous 14 days before today", _id: "previous_14_days" },
    { title: "Last 30 days", description: "The previous 30 days before today", _id: "previous_30_days" },
    { title: "Last 90 days", description: "The previous 90 days before today", _id: "previous_90_days" },
    { title: "Last 180 days", description: "The previous 180 days before today", _id: "previous_180_days" },
    { title: "Last 365 days", description: "The previous 365 days before today", _id: "previous_365_days" },
]
