import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col, Alert, Divider, Tag, Space } from 'antd';
import { loader } from 'graphql.macro';
import { DevBlock, Drawer, Loader } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

const GET_RECORD = loader('src/Graphql/Knowledgebases/knowledgebase.graphql');


const ArticlePreview = (props) => {
    console.log("ArticlePreview()", props)
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState(false);

    const [knowledgebase, { called, data}] = useLazyQuery( // { called, loading, data }
        GET_RECORD, { 
            // variables: { filter: JSON.stringify({ _id: Number(props.fields._id) }) }
        }
    );

    useEffect(() => {
        if (called || loading || data || !props?.id || props?.id=="null") return;

        setLoading(true)

        knowledgebase({ variables: { filter: JSON.stringify({ _id: Number(props.id) }) } }).then(r => {
            let resutls = r?.data?.knowledgebase;
            setFields(resutls);
            setLoading(false)
        })

    }, [props?._id])

    if (loading) return <Loader loading={true} />
    if (!fields || error) return <Alert message="Error" description={error || "No records found!"} type="error" showIcon />

    return (<>
        <Row gutter={[10]}>
            <Col flex="auto"><div style={{ fontWeight: "bold", fontSize: "18px" }}>{fields.title}</div></Col>
            <Col><label className='label'>Author: </label>{fields.author.name}</Col>
        </Row>
        <div dangerouslySetInnerHTML={{ __html: fields.body }} />

        <Divider>Tags</Divider>
        <Space>
            {fields?.tags?.map((item, i) => {
                return <Tag key={i}><a href={`/admin/knowledgebase/search/?tag=${item}`}>{item}</a></Tag>
            })}
        </Space>

    </>)

}

export const ArticlePreviewWrapper = props => {
    return (
        <Drawer width={"100%"} destroyOnClose maskClosable={false} placement="right"
            onClose={props.onClose}
            visible={props.visible}
            bodyStyle={{ }}
            // footer={<>
            //     <span></span>
            //     <Button loading={busy} type="primary" onClick={() => {
            //         document.getElementById('knowledgebase_form').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
            //     }}>Save</Button>
            // </>}
            title={`Article Details`}
        ><>
                {props.visible && <ArticlePreview {...props} />}
        </></Drawer>
    )
}
ArticlePreviewWrapper.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.number,    
        PropTypes.string,    
        PropTypes.bool,    
    ]).isRequired,
    visible: PropTypes.bool.isRequired,
    callback: PropTypes.func,
}

export default ArticlePreviewWrapper;