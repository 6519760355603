import React, { Component } from 'react'

const AppHome = props => {
    return (
        <div style={{ backgroundColor:"#001529", flex:1, alignContent:"center", alignItems:"center", justifyContent:"center", display:"flex" }}>
            <img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-light.png`} />
      </div>
    )
}

export default AppHome;