/* eslint-disable react/no-typos, no-unused-vars */
import update from 'immutability-helper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { __error, __hilight, __yellow } from './consoleHelper';
// import { findIndexByObjVal } from './Functions';


/******* Usage ******
*params
_subscriptionType:
  * type = String
  * values = array, object, simple-array
_subscribeToMore:
  * type: Function
_document:
  * type: graphQL Query doc
_variables:
  * type: query variables object
_subscriptionName:
  * type: string
  * name of the subsscription channel
_queryName:
  * type: String
  * name of the query to update on sub-event
_typename:
  * type: String
  * __typename value
debug:
  * type: boolean


const { loading, events, loadMoreRows, subscribeToMore } = nextProps;
if(!this.subscription && !this.state.events){
  this.subscription = new SubscriptionHandler({
    _subscribeToMore: subscribeToMore,
    _document: QUERY_SUBSCRIPTION,
    _variables: {},
    _subscriptionName: "eventsUpdated",
    _subscriptionType: "simple-array",
    _queryName: "events",
    _typename: "Events",
    debug:true
  });
}
****************/
export class SubscriptionHandler{
  constructor(props){
    this.props = props;
    this.subscription = null;
    this.init();

    this.updateQuery = this.updateQuery.bind(this);
    this.updateArray = this.updateArray.bind(this);
    this.updateObject = this.updateObject.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if (this.props.debug) console.log(__hilight("SubscriptionHandler() : "), nextProps);
  }

  verifyProps() {
    if (this.props.debug) console.log("verifyProps()", this.props);
    
    const { _subscribeToMore, _document, _variables, _subscriptionName, _queryName, _typename } = this.props;
    let error = false;
    if(!_subscribeToMore) error = "Missing subscribeToMore";
    if(!_document) error = "Missing query document";
    if(!_variables) error = "Missing variables";
    if(!_subscriptionName) error = "Missing _subscriptionName";
    if(!_queryName) error = "Missing _queryName";
    if(!_typename) error = "Missing __typename";

    if(error){
      console.log(__error(error));
      return false;
    }

    return true;
  }

  updateArray(prev, { subscriptionData: { data } }) {
    if (this.props.debug) console.log("updateArray()");

    const { _subscriptionName, _queryName, _typename  } = this.props;
    // console.log("SubscriptionHandler.updateArray()");
    // console.log("prev: ", prev);
    if(this.props.debug) console.log("SubscriptionHandler > updateArray: ", data);

    if (!prev[_queryName]) {
      console.log(__error("Subscription Error: Prev query not found :: "), _queryName, " prev: ", prev);
      return;
    }

    /// validate query type/
    if(_.isArray(prev[_queryName])){
      console.log(__error("Query format is an array, must be an Object {totalCount, *edges, pageInfo}"));
      return;
    }

    let newData = prev;
    const prevQueryData = { ...prev[_queryName] }; // _.isArray(prev[_queryName]) ? [...prev[_queryName]] : { ...prev[_queryName] };

    const pre_total = prevQueryData.totalCount || 0;
    if (pre_total<1){
      Object.assign(prevQueryData, { totalCount : 0, edges: [] })
    }

    // var new_node = {node:data[_subscriptionName].node, __typename: _typename};
    var new_node = data[_subscriptionName].node;
    // var old_nodes = prevQueryData.edges;
    const index = prevQueryData.edges ? prevQueryData.edges.findIndex(item => item._id == new_node._id) : -1;
    // const index = old_nodes.findIndex(item => item._id == new_node._id);

    if(!data[_subscriptionName]) return prev;

    if (this.props.debug) console.log(__hilight(`Subscription ${data[_subscriptionName].mutation} received`));

    if(data[_subscriptionName].mutation=="CREATED")
    {
      // if (this.props.debug) console.log(__hilight("Subscription CREATED received"));

      let obj = {};
          obj[_queryName] = {
            totalCount : { $set: (pre_total+1) },
            edges: { $unshift: [new_node] }
          };

      newData = update({ 
        ...prev,
        [`${_queryName}`]: prevQueryData,
      }, obj);
      // newData = update(prev, obj);
    }

    if(data[_subscriptionName].mutation=="DELETED")
    {
      // if (this.props.debug) console.log(__hilight("Subscription DELETE received"));

      if (index < 0) return prev;

      let obj = {};
          obj[_queryName] = {
              totalCount: { $set: (pre_total-1) },
              edges: { $splice: [[index, 1]] }
            }
      newData = update({
        ...prev,
        [`${_queryName}`]: prevQueryData,
      }, obj);

      // newData = update(prev, obj);
    }

    if(data[_subscriptionName].mutation=="UPDATED")
    {
      // if (this.props.debug) console.log(__hilight("Subscription UPDATE received"));
      if (index < 0) return prev;

      // replace node value/
      // newData = prev[_queryName].edges.splice(index, 1, new_node);
      // newData = prevQueryData.edges.slice().splice(index, 1, new_node);
      let newEdges = prevQueryData.edges.slice().splice(index, 1, new_node);
      // update totalCount/
      let obj = {};
          obj[_queryName] = {
            totalCount: { $set: pre_total },
            edges: { $set: newEdges }
          }

      newData = update({
        ...prev,
        [`${_queryName}`]: prevQueryData,
      }, obj);

      // newData = update(prev, obj);
    }

    if (this.props.debug) console.log(newData);
    return newData;
  }

  updateSimpleArray(prev, { subscriptionData: { data } }) {
        if (this.props.debug) console.log("updateSimpleArray()");
        
        const { _subscriptionName, _queryName, _typename, _subscriptionType  } = this.props;
        if (this.props.debug) console.log("SubscriptionHandler > updateSimpleArray: ", data);
        
        var old_nodes = prev[_queryName] ? prev[_queryName].slice() : false;

        if (!old_nodes){
          console.log(__error("Subscription Error: Prev query not found :: "), `${_queryName} prev: `, prev);
          return;
        }

        let newData = prev;
        var new_node = data[_subscriptionName].node;
        // var old_nodes = [...prev[_queryName]];
        // if (!old_nodes) {
        //   console.log(__error(`Query (${_queryName}) results not available`));
        //   return;
        // }
        const index = old_nodes.findIndex(item => item._id == new_node._id);
        
        /// validate query type/
        if(_subscriptionType=='simple-array'){ }
        if (!_.isArray(old_nodes)){
          console.log(__error("Query format is not an Array"));
          return;
        }


        if(!data[_subscriptionName]) return prev;

        if(data[_subscriptionName].mutation=="CREATED")
        {
          if (this.props.debug) console.log(__hilight("Subscription CREATED received"));

          let obj = { };
              obj[_queryName] = { $unshift: [new_node] };

          newData = update(prev, obj);
        }

        if(data[_subscriptionName].mutation=="DELETED")
        {
          if (this.props.debug) console.log(__hilight("Subscription DELETE received"));
          if(index<0){
            console.log(__error('Matching index not found'));
            return prev;
          }

          if (index < 0) return prev;

          let obj = {};
              obj[_queryName] = { $splice: [[index, 1]] }

          newData = update(prev, obj);
          if (this.props.debug) console.log("newData: ", newData)
        }

        if(data[_subscriptionName].mutation=="UPDATED")
        {
          if (this.props.debug) console.log(__yellow("Subscription UPDATE received: "), { index });
          if(index<0){
            console.log(__error('Matching index not found'));
            return prev;
          }
          old_nodes.splice(index, 1, new_node);
          newData = old_nodes;
          // newData = prev[_queryName].splice(index, 1, new_node);
        }

        if (this.props.debug) console.log({newData});
        return newData;
  }

  updateObject(prev, { subscriptionData: { data } }) {
        if (this.props.debug) console.log("updateObject()");

        const { _subscriptionName, _queryName, _typename } = this.props;
        if (this.props.debug) console.log("SubscriptionHandler > updateObject: ", data);

        if (!prev[_queryName]) {
          console.log(__error("Subscription Error: Prev query not found :: "), _queryName, " prev: ", prev);
          return;
        }

        if(!data[_subscriptionName]) {
          console.log(__error(`No subscription data found in ${_subscriptionName}`));
          return prev
        };

        let newData = prev;
        const mutation = data[_subscriptionName].mutation;
        if (this.props.debug) console.log(__hilight(`Subscription ${mutation} received`));
        const old_nodes = prev[_queryName];
        const new_node = {...data[_subscriptionName].node, __typename: _typename};

        // verify _id match/
        if(old_nodes._id != new_node._id){
            console.log(__error(`Subscription _id did not match: ${old_nodes._id} == ${new_node._id}`));
            return false;
        }

        if(mutation=="CREATED"){
          console.log(__error("Single node subscription should not have mutation=CREATED, check subscription publish method."));
        }

        if(mutation=="DELETED"){
              newData = {};
        }

        if(mutation=="UPDATED"){
          let obj = {};
              obj[_queryName] = new_node
          return obj;
        }

        if (this.props.debug) console.log(newData);
        return newData;
  }

  // updateQuery(prev, { subscriptionData: {data} }){
  updateQuery(prev, subInfo) {
        if (this.props.debug) console.log("updateQuery(): \n prev:  ", prev, "\n subInfo:  ", subInfo);
    
        const { _subscriptionType  } = this.props;

        if(!subInfo.subscriptionData.data[this.props._subscriptionName]){
          console.log(__error(`Subscription Data missing for ${this.props._subscriptionName}`));
          return false;
        }

        if(_subscriptionType && _subscriptionType=='object'){
          return this.updateObject(prev, subInfo);
        }
        else if (_subscriptionType == 'simple-array') {
          return this.updateSimpleArray(prev, subInfo);
        }
        else {
          return this.updateArray(prev, subInfo);
        }

  }

  init(){
    const { _subscribeToMore, _document, _variables, _queryName, _onUpdateReceive, _subscriptionName  } = this.props;

    if(!this.verifyProps()) return;

    // Subscribe or re-subscribe
    if (_.isNull(this.subscription))
    {
          if (this.props.debug) console.log(__hilight(`Subscribing to ${_queryName}`));

          // subscribeToMore({
          //   document: _document,
          //   variables: _variables,
          //   updateQuery: (prev, { subscriptionData }) => {
          //     if (!subscriptionData.data) return prev;
              
          //     const newFeedItem = subscriptionData.data[_subscriptionName];
          //     const subscriptionData = subInfo.subscriptionData.data[_subscriptionName];

          //     return Object.assign({}, prev, {
          //       post: {
          //         comments: [newFeedItem, ...prev.post.comments]
          //       }
          //     });
          //   }
          // })

          this.subscription = _subscribeToMore({
            document: _document,
            variables: _variables,
            fetchPolicy: "no-cache",
            updateQuery: (prev, subInfo) => {
              const subscriptionData = subInfo.subscriptionData.data[_subscriptionName];
              const updatedData = this.updateQuery(prev, subInfo);
              if (_onUpdateReceive) _onUpdateReceive(subscriptionData, updatedData);
              // return { noticeboard_notes: updatedData }
              return updatedData;
            },
            onError: (err) => console.log(__error(JSON.stringify(err, 0, 2))),
          });
    }

    return this.subscription;
  }//init

  unsubscribe(){
    this.subscription();
  }

}

SubscriptionHandler.propTypes = {
  _onUpdateReceive:PropTypes.func,
  _subscribeToMore:PropTypes.func.isRequired,
  _document: PropTypes.object.isRequired,
  _variables: PropTypes.object.isRequired,
  _subscriptionName: PropTypes.string.isRequired,
  _queryName: PropTypes.string.isRequired,
  _typename: PropTypes.string.isRequired,
  _subscriptionType: PropTypes.string.isRequired, //  array, object, simple-array
  debug: PropTypes.boolean,
};


export default SubscriptionHandler;
