import React from 'react';
import { addModule, addReducers } from '../connector';
import Clients from './client_list';
import ClientDetails from './client_details'
import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT, MODULE } from './constants';

export const modulePermissions = getmodulePermissions('client-management');

// addReducers({ admins: reducers });
if (modulePermissions['client.account-view']){
    addModule(
        { menuPath: `${ROOT}`, path: `${ROOT}`, component: Clients, icon: 'user-tie', title: "Clients", exact: true, leftNav: true },
    )
    addModule(
        { path: `${ROOT}/:id`, component: ClientDetails, title: "Client Details", leftNav: false },
    )
}
// addModule(
//     { path: `/client/:id`, component: Detail, icon: 'desktop', title: "Client Detail", leftNav: false },
// )