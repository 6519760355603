import React from 'react'
import PropTypes from 'prop-types';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Loader, Button, UsersSearch, ChannelSearch, Icon, IconButton } from 'Common/components';
import { loader } from 'graphql.macro';
import { Drawer, Space, Alert, message, Row, Col, Modal, Upload } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';
import { __error } from 'Common/scripts/consoleHelper';

const ADD_ITEM = loader('src/Graphql/Todos/addTodoItem.graphql');
const UPDATE_ITEM = loader('src/Graphql/Todos/updateTodo.graphql');

const defaultValues = { }


const TodoItemForm = ({ onSuccess, onCancel, client, initialValues, todo }) => {
    async function updateRecords(input){
        let _input = {...input}
        delete _input.message;

        return client.mutate({ mutation: UPDATE_ITEM, variables: { input: _input } }).then(r => (r?.data?.updateTodo))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })
    }
    
    async function addRecords(input){
        return client.mutate({ mutation: ADD_ITEM, variables: { input } }).then(r => (r?.data?.addTodoItem))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })
    }
    
    const onTodoItemSubmit = async (values) => {
        const input = {
            parent_id: Number(todo._id),
            body: values.body,
            assigned_to: values.assigned_to,
        }
        if (initialValues && initialValues._id) Object.assign(input, { _id: Number(initialValues._id) })

        let results;
        if (input._id) results = await updateRecords(input)
        else results = await addRecords(input);

        if (results.error){
            message.error(results.error.message);
            return false;
        }

        onSuccess();
        return "reset"
    }

    if (!todo || !todo._id) return <Alert message="Missing parent ID" showIcon type='error' />


    return (<>
        <FormComponent onSubmit={onTodoItemSubmit} id='TodoItemForm' fields={{ ...defaultValues, ...initialValues }} debug={true} 
            mutators={{
                selectUser: (newValueArray, state, tools) => {
                    let node = newValueArray[0];

                    let _dataArray = state.formState.values.assigned_to || [];
                    _dataArray.push({ _id: node._id, name: node.name })

                    tools.changeValue(state, 'assigned_to', () => _dataArray);
                },
                de_selectUser: (newValueArray, state, tools) => {
                    let _dataArray = state.formState.values.assigned_to || [];
                    let _ids = state.formState.values.assigned_to_ids || [];

                    _dataArray = _dataArray.filter(o => (_ids.includes(o._id)))

                    tools.changeValue(state, 'assigned_to', () => _dataArray)
                },

            }}
            form_render={formProps => {
                const { values, submitting, form, invalid, dirty, valid } = formProps;

                return (<>
                    <FormField placeholder="to do item" name="body" type="text" validate={rules.required} />

                    <UsersSearch mode="multiple" label="Assigned To" name='assigned_to_ids'
                        filter={{ status: "enabled" }}
                        preload disableSearch
                        // disabled={!values.channel || values.channel.length<1}
                        onSelect={(___, opt, raw) => form.mutators.selectUser(raw)}
                        onDeselect={(txt, node) => form.mutators.de_selectUser(node)}
                        defaultValues={values.assigned_to ? values.assigned_to.map(o => ({ ...o, title: o.name })) : []}
                    />

                    <Row gutter={[10]}>
                        <Col flex="auto" />
                        <Col><Button onClick={onCancel} color="white">Cancel</Button></Col>
                        <Col><Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >{values._id ? "Update" : "Create"} Todo</Button></Col>
                    </Row>                    

                </>)
            }}
        />

    </>)
}
const WithApollo = withApollo(TodoItemForm)

export default function TodoItemFormModal(props) {
    const { initialValues, visible, onCancel, onSuccess, todo } = props;

    const handel_onSuccess = (values) => {
        if (onSuccess) onSuccess()
        else onCancel()
    }

    let members_ids = initialValues && initialValues?.members?.map(o=>(o._id));

    return (<>
        <Modal title="Todo" visible={visible} width="700px" onCancel={onCancel} footer={false} closable centered destroyOnClose>
            {visible && <WithApollo {...props} initialValues={{ ...initialValues, members_ids }} onSuccess={handel_onSuccess} />}
        </Modal>
    </>)
}
TodoItemFormModal.propTypes = {
    todo: PropTypes.object.isRequired,
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    initialValues: PropTypes.object,
}

