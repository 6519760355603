import React from 'react';
import { addModule, addReducers } from '../connector';
import Adressbook from './address_list';
// import Detail from './Detail'
import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT, MODULE } from './constants'

export const modulePermissions = getmodulePermissions('addressbook-mg');

// addReducers({ admins: reducers });
if (modulePermissions['addressbook.access']){
    addModule(
        { menuPath: `${ROOT}`, path: `${ROOT}`, component: Adressbook, icon: 'address-book', title: "Adressbook", exact: true, leftNav: true },
    )
}
// addModule(
//     { path: `/client/:id`, component: Detail, icon: 'desktop', title: "Client Detail", leftNav: false },
// )