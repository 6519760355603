import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Alert, message, Row, Col, Modal, Space } from 'antd';
import { Table, Button, IconButton, Avatar, Drawer } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import moment from 'moment'
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { useSubscription, gql } from "@apollo/client";
import GuardForm from '../components/GuardForm';
import { mongoToDate, getWindowDimensions } from 'Common/scripts/Functions';
import { uriRoot, defaultPageSize, defaultDateFormat } from 'configs';
import { modulePermissions } from '..'
import SearchBar from '../components/SearchBar'
import { withApollo } from '@apollo/client/react/hoc';
import { ListHeader } from 'Common/components/Typography';
import Sms_form, { SMSFormModal } from 'Modules/sms/components/sms_form';
import GuardDetails from './Detail';
import { verifyRole } from 'Common/scripts/Security';
import ExportGuardsAsCSV from '../components/ExportGuardsAsCSV'

const styles = {
    active: { color:'green' },
}

const LIST_DATA = loader('src/Graphql/guard/guardQuery.graphql');
const DELETE = loader('src/Graphql/guard/deleteGuard.graphql');
const QUERY_SUBSCRIPTION = loader('src/Graphql/guard/subscription.graphql');


function Subscriber({ QUERY, callback, filter }) {
    let variables = {};

    if (filter) Object.assign(variables, { ...filter });

    const { data, loading, error } = useSubscription(
        QUERY_SUBSCRIPTION,
        {
            // subscription: QUERY,
            variables: { filter: JSON.stringify(variables) },
            // fetchPolicy: "network-only" // cache-first, cache-only, cache-and-network, network-only, no-cache, standby
            onSubscriptionData: ({ client, subscriptionData }) => {
                const { data, error, loading, variables } = subscriptionData
                console.log({ subscriptionData })
                if (callback) callback(subscriptionData)
            },
            shouldResubscribe: false, // Determines if your subscription should be unsubscribed and subscribed again
        }
    );

    if (loading) {
        console.log("Receiving subscription on ....", variables);
        return null;
    }

    if (data) {
        console.log('Subscription received: ', data);
        // const { mutation, node } = data.formsDatasUpdated;
        // if (callback) callback({ mutation, node })
    }

    return null;

}

class GuardsListComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false, busy: false, data: null, error: null, visible: false,
            pagination: { 
                current: 1, 
                pageSize: defaultPageSize, 
                total:0 ,
            },
            showDrawer: false, initialData: null,
            showSMSForm: false,
            showGuardDetails: false,
            show_exportWin: false,
        }
        // this.showDrawer = this.showDrawer.bind(this)
        // this.onClose = this.onClose.bind(this)
        this.fetchData = this.fetchData.bind(this)
        this.edit = this.edit.bind(this)
        this.onSubscriptionReceived = this.onSubscriptionReceived.bind(this)
        this.dimensions = getWindowDimensions()

    }
    
    handleTableChange = (pagination, filters, sorter) => {
        // console.log("handleTableChange(): ", pagination);
        // this.fetchData({ current: pagination.current, pageSize: pagination.pageSize });
        this.fetchData(pagination);
    };

    fetchData = async (args = {}) => {
        const filter = args.filter ? JSON.stringify({ ...args.filter, ...this.props?.permanent_filter }) : JSON.stringify({ ...this.props?.filter, ...this.props?.permanent_filter });
        const others = JSON.stringify({});
        let startFrom = (args.pageSize * (args.current-1)) || 0;
        let totalRecToShow = args.pageSize || defaultPageSize; //(args.pageSize * args.current) || defaultPageSize;

        const variables = { first: totalRecToShow, after: startFrom, filter, others }
        // return console.log("variables: ", variables);        

        this.setState({loading:true})

        this.props.client.query({ query: LIST_DATA, variables,
            fetchPolicy: "no-cache",
        }).then(r => {
            if (r.data.error) {
                this.setState({ loading: false, error: r.data.error.message });
                return;
            }

            this.setState({
                loading: false,
                data: r.data.guardQuery,
                pagination: {
                    ...this.state.pagination,
                    current: args.current || 1,
                    pageSize: args.pageSize || this.state.pagination.pageSize,
                    total: r.data.guardQuery.totalCount
                }
            });

        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            this.setState({ busy: false, error: "Unable to Process your request at the moment!" });
        })


    }

    edit(rec) {
        if (!modulePermissions['guard.view']) return;

        const filtered = {
            covid_vaccinated: rec.covid_vaccinated === "yes" ? true : false,
            driving_license: rec.driving_license,
            status: rec.status,
            email: rec.email,
            name: rec.name,
            f_name: rec.f_name,
            m_name: rec.m_name,
            l_name: rec.l_name,

            phone: rec.phone,
            ppe: rec.ppe === "yes" ? true : false,
            security_license_expiry: moment(mongoToDate(rec.security_license_expiry), 'YYYY/MM/DD'),
            sin: rec.sin,
            skills: rec.skills,
            vehicle: rec.vehicle === "yes" ? true : false,
            whatsapp: rec.whatsapp,
            _id: rec._id,
            morning_mon: rec.guard_availability?.mon[0] === "morning" ? true : false,
            afternoon_mon: rec.guard_availability?.mon[1] === "afternoon" ? true : false,
            night_mon: rec.guard_availability?.mon[2] === "night" ? true : false,
            morning_tue: rec.guard_availability?.tue[0] === "morning" ? true : false,
            afternoon_tue: rec.guard_availability?.tue[1] ==="afternoon" ? true : false,
            night_tue: rec.guard_availability?.tue[2] === "night" ? true : false,
            morning_wed: rec.guard_availability?.wed[0] === "morning" ? true : false,
            afternoon_wed: rec.guard_availability?.wed[1] === "afternoon" ? true : false,
            night_wed: rec.guard_availability?.wed[2] === "night" ? true : false,
            morning_thu: rec.guard_availability?.thu[0] === "morning" ? true : false,
            afternoon_thu: rec.guard_availability?.thu[1] === "afternoon" ? true : false,
            night_thu: rec.guard_availability?.thu[2] === "night" ? true : false,
            morning_fri: rec.guard_availability?.fri[0] === "morning" ? true : false,
            afternoon_fri: rec.guard_availability?.fri[1] === "afternoon" ? true : false,
            night_fri: rec.guard_availability?.fri[2] === "night" ? true : false,
            morning_sat: rec.guard_availability?.sat[0] === "morning" ? true : false,
            afternoon_sat: rec.guard_availability?.sat[1] === "afternoon" ? true : false,
            night_sat: rec.guard_availability?.sat[2] === "night" ? true : false,
            morning_sun: rec.guard_availability?.sun[0] === "morning" ? true : false,
            afternoon_sun: rec.guard_availability?.sun[1] === "afternoon" ? true : false,
            night_sun: rec.guard_availability?.sun[2] === "night" ? true : false,
            emergency_contact: !rec.emergency_contact ? undefined : rec.emergency_contact.map(data => ({ "ename": data.ename,"ephone": data.ephone}) )  
            
        }
        const res ="0"
        this.toggeleDrawer(res,filtered)
    }

    delete(_id) {
        if (!modulePermissions['guard.delete']) return;

        this.props.client.mutate({ mutation: DELETE, variables: { id: _id } }).then(r => {
            if (r.data.error) {
                this.setState({ loading: false, error: r.data.error.message });
                return;
            }
            message.success("Successfully Removed site");
            const __data = this.state.data.edges ? this.state.data.edges.slice() : [];
            const index = __data.findIndex(data => data._id === r.data.deleteGuard._id)
            __data.splice(index, 1);
            const __totalCount = this.state.data.totalCount - 1;
            this.setState({ busy: false, data: { edges: __data, totalCount: __totalCount } })

        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            message.error("Failed to process the request!");
        })

    }

    toggeleDrawer(res,initialData = null) {     
        this.setState({ showDrawer: !this.state.showDrawer, initialData })
    }

    columns = [
        // { title: 'ID', dataIndex: '_id', fixed: 'left', key: 'id', width: 100 },
        {
            title: 'Guard Name', width: 250, fixed: 'left', key: 'name', render: (text, row) => {
                let name = ` ${row.f_name}` + (row.m_name ? `, ${row.m_name}` : "") + (row.l_name ? `, ${row.l_name}` : "");
                return (<Row gutter={5} align="middle" style={{ flexWrap: "nowrap" }}>
                    <Col><div onClick={() => this.toggleGuardDetails(row)} className='a'><Avatar src={row.guard_avatar} size={34} alt={name} /></div></Col>
                    <Col flex="auto"><Link to={`${uriRoot}/guard/${row._id}`}>{name}</Link></Col>
                </Row>)
                // return (<Row gutter={5} align="middle" style={{ flexWrap: "nowrap" }}>
                //     <Col><Avatar src={row.guard_avatar} size={34} alt={name} /></Col>
                //     <Col flex="auto">
                //         <Link to={`/admin/guard_details/${row._id}`}>{name}</Link>
                //     </Col>
                // </Row>)
            } 
        },
        { title: 'Security Lc. no', width: 120, dataIndex: 'security_license_no', render: (__, rec) => (<>
            {rec.security_license_no}
            <br />
            <small>{(rec.security_license_no && rec.security_license_expiry) && mongoToDate(rec.security_license_expiry).format(defaultDateFormat)}</small>
        </>) },
        // { title: 'SIN', width: 110, key: '22', dataIndex:'sin' },
        { title: 'Status', width: 110, key: '2', render: (__, rec) => <span style={styles[String(rec?.status).toLowerCase()]}>{rec?.status}</span>, align:'center' },
        { title: 'Vehicle', width: 80, key: '3', dataIndex:'vehicle', align:'center' },
        { title: 'Driving Lc.', width: 150, key: '4', dataIndex:'driving_license' },
        { title: 'COVID Vac.', width: 100, key: '5', dataIndex:'covid_vaccinated', align:'center' },
        { title: 'Email', width: 300, key: '6', dataIndex:'email' },
        { title: 'Phone', width: 200, key: '7', dataIndex: 'phone', render: (__, rec)=>{
            let name = String(`${rec.f_name || ""} ${rec.m_name || ""} ${rec.l_name || ""}`).trim();
            return (<>
                <SMSFormModal phone={rec.phone} name={name} />
                {/* <div><IconButton onClick={() => this.setState({ showSMSForm: { phone: rec.phone, name } })} icon="sms" /> {rec.phone}</div> */}
            </>)
        } },
        { title: 'PPE', width: 50, key: '9', dataIndex:'ppe' },
        { title: 'Skills', width: 200, key: '10', dataIndex:'skills' },       
    ];

    onSubscriptionReceived({ data, error, loading, variables }) {
        const { mutation, node } = data.guardsUpdated;
        let _data = this.state.data.edges ? this.state.data.edges.slice() : [];

        if (mutation == "DELETED") _data = _data.filter(o => o._id != node._id );
        else if (mutation == "UPDATED") _data = _data.map(o => (o._id == node._id) ? { ...node } : o)
        else if (mutation == "CREATED") _data.push(node);
        else {
            console.log(__error("Invalid Subscription received: ", data));
            return;
        }

        this.setState({ data: { ...this.state.data, edges: _data } })
    }

    toggleGuardDetails(val=false){
        this.setState({ showGuardDetails: val })
    }

    doExportCSV(){
        this.setState({ show_exportWin:true })
    }

    
    render() {
        const { user } = this.props;
        const { loading, error, data, pagination, showDrawer, initialData, show_exportWin } = this.state;
        if (error) return <Alert message="Error" description={error} type="error" showIcon />

        return (<>
            <Subscriber QUERY={QUERY_SUBSCRIPTION} callback={this.onSubscriptionReceived} variables={{}} filter={this.props.filter} />

            <Row align="middle">
                <Col flex="auto">
                    <ListHeader totalCount={(data && data.totalCount) || '0'} title={<>Guards</>} />
                </Col>
                <Col>
                    <Space>
                        {verifyRole("guard.export", { user }) && <Button onClick={()=>this.doExportCSV()} color="lightgrey" size="small">Export CSV</Button>}
                        {verifyRole('guard.add', { user }) && <Button onClick={() => this.toggeleDrawer()}>Add Guard</Button>}
                    </Space>
                </Col>
            </Row>

            <div style={{ marginTop: "10px", padding: "0 10px 0 10px", border: "1px solid #EEE", borderTopRightRadius: "5px", borderTopLeftRadius: "5px", backgroundColor: "rgba(0, 0, 0, 0.02)" }}>
                <SearchBar fetchData={(args) => this.fetchData(args)} filter_config={this.props.filter_config} />
            </div>

            <Table loading={loading}
                // title={() => <b>Total {((data && data.totalCount) || '0')} record(s) found </b>}
                columns={this.columns}
                dataSource={data ? data.edges : null}
                pagination={pagination}
                onChange={this.handleTableChange}
                scroll={{ x: 1500, y: this.dimensions.height - 350 }}
                expandable={{
                    expandedRowRender: record => <div style={{ margin: 0 }}>
                        <p>Assets: {record.company_assets.toString()}</p>
                    </div>,
                    // rowExpandable: record => record.comments && record.comments.length > 0,
                }}
                // rowClassName={(record => record.approval_status == 'new' ? 'hilighted-table-row' : "")}
            />

            <GuardForm
                showDrawer={showDrawer}
                initialData={initialData}
                onClose={(res) => this.toggeleDrawer(res)}
            />

            <Modal title="Send SMS" visible={this.state.showSMSForm != false} footer={false} onCancel={() => this.setState({ showSMSForm: false })}>
                {this.state.showSMSForm && <>
                    <Sms_form {...this.state.showSMSForm} onComplete={() => this.setState({ showSMSForm: false })} />
                </>}
            </Modal>

            <Modal title="Export As CSV" visible={show_exportWin} footer={false} 
                closable={false}
                centered={true}
                // onCancel={() => this.setState({ showSMSForm: false })}
                >
                {show_exportWin && <><ExportGuardsAsCSV onCancel={() => this.setState({ show_exportWin: false })} /> </>}
            </Modal>

            <Drawer autoFocus={true} destroyOnClose={true} maskClosable={false} placement='top' transparent
                footer={false}
                title={'Guard Details'}
                height={'100%'}
                onClose={()=>this.toggleGuardDetails()}
                visible={this.state.showGuardDetails}
                bodyStyle={{ paddingBottom: 80 }}
                // extra={<Button size="small" onClick={onClose}>Cancel</Button>}
            >
                {this.state.showGuardDetails && <GuardDetails inline match={{ params: { id: this.state.showGuardDetails._id } }} onClose={()=>this.toggleGuardDetails()} />}
            </Drawer>


        </>)

    }
}
export const GuardsList = withApollo(GuardsListComp)

const mapStateToProps = ({ ep_admin }) => {
    return { 
        filter: ep_admin?.pageSettings?.guard_searchBar,
        user: ep_admin.user,
    }
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export default connect(mapStateToProps)(GuardsList)

