import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { __blue, __error } from 'Common/scripts/consoleHelper'
import { withApollo } from '@apollo/client/react/hoc';
import { Alert, Col, Row, message } from 'antd';
import { Button, Loader, IconButton, Heading } from 'Common/components';
import { ListHeader } from 'Common/components/Typography';
import { gql } from '@apollo/client';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import Notes from 'Modules/Notes';

const SITE_DATA = gql`
    query site($id: ID!){
        site(id:$id){
            _id
            name
            site_jds
            error { message }
        }
    }   
    `
const SAVE_JD = gql`
    mutation saveSiteJds($site_id: ID!, $site_jds:String!){
        saveSiteJds(site_id:$site_id, site_jds:$site_jds){
            _id
            name
            site_jds
            error { message }
        }
    }   
    `


const JDEditForm = ({ data, onUpdate, client, site_id }) => {
    const [error, setError] = useState(null);

    const onSubmit = async(values) => {
        setError(null);

        const variables = {
            site_id,
            site_jds: values.site_jds,
        }

        const resutls = await client.mutate({ mutation: SAVE_JD, variables }).then(r => (r.data.saveSiteJds))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })

        if (resutls.error) return setError(resutls.error.message);
        if (onUpdate) return onUpdate(resutls);
    }

    return (<>
        <FormComponent onSubmit={onSubmit} id='SiteReportForm' fields={{ ...data }} debug={false}
            mutators={{
                setBody: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'site_jds', () => newValueArray[0])
                },
            }}
            form_render={formProps => {
                const { values, form, invalid, dirty, valid } = formProps;

                return (<>
                    {error && <Alert message="Error" description={error} type="error" showIcon />}

                    <FormField name="site_jds" type="html" validate={rules.required} compact
                        data={(values && values.site_jds) ? values.site_jds : null}
                        onUpdate={(val) => formProps.form.mutators.setBody(val)}
                    />

                    <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} >Save</Button>

                </>)
            }}
        />

    </>)
}

const SiteInfo = ({ site_id, client }) => {
    const [busy, setBusy] = useState(false)
    const [data, set_data] = useState(null)
    const [error, set_error] = useState(null)
    const [mode, set_mode] = useState('preview');

    useEffect(() => {
        fetchData();
    }, [site_id])

    const fetchData = async (args = {}) => {
        
        setBusy(true)
        const resutls = await client.query({ query: SITE_DATA, variables: { id: Number(site_id) } }).then(r => (r.data.site))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message:"Query Error" }}
            })
        setBusy(false);

        if (!resutls || resutls.error){
            set_error(resutls ? resutls.error.message : "Empty response")
            return;
        }

        set_data(resutls);
    }

    const toggleMode = () => set_mode(mode == "edit" ? "preview" : "edit");
    const onUpdate = (node) => {
        set_data(node);
        toggleMode();
    }

    if (busy) return <Loader loading={true} />
    if (!data) return <Alert message={"No data found!"} showIcon type='error' />

    return(<>
        {error && <Alert message={error} showIcon type='error' />}

        <Row gutter={[10]} className='nowrap'>
            <Col flex="auto">
                <ListHeader title={<span>Site JDs <IconButton onClick={() => toggleMode()} icon={mode == "edit" ? "times" : "pen"} /></span>} sub={data.name} />
                <hr />
                {mode != "edit" && <>
                    {(data && data.site_jds) && <div style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: data.site_jds }} />}
                </>}
                {mode == "edit" && <>
                    <JDEditForm data={data} onUpdate={onUpdate} client={client} site_id={site_id} />
                </>}
            </Col>
            <Col flex="300px" style={{ borderLeft: "1px solid #EEE" }}>
                <Heading>Attachments</Heading>
                <Notes id={data._id} formkey="site_jds_attachment" type="document" />
            </Col>
        </Row>
    </>)
}
SiteInfo.propTypes = {
    site_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
}

export default withApollo(SiteInfo)