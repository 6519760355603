import React, { useState } from 'react'
import { Col, Input, Row, Space, message } from 'antd'
import { Button, Loader } from 'Common/components'
import { sleep, timestamp } from 'Common/scripts/Functions';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import TestForm from 'Modules/TestManager/Forms';
import { __error } from 'Common/scripts/consoleHelper';


const CHK_PWD = loader('src/Graphql/TestResults/checkTestPassword.graphql');


export default function ProtectedTestForm() {
    const [state, setState] = useState({});
    const [busy, setBusy] = useState(false);
    const [invitationId, set_invitationId] = useState(null);
    const [form, setForm] = useState(null);

    const [checkTestPassword, { called, data }] = useLazyQuery( // { called, loading, data }
        CHK_PWD, {});

    const onSubmit = async () => {
        console.log("onSubmit()")
        setBusy(true);
        
        let results = await checkTestPassword({ variables: { password: state.password, lc_number: state.lc_number } })
            .then(r => (r.data.checkTestPassword))
            .catch(err=>{
                console.log(__error("Error: "), err)
                return { error:{message:"Invalid request!"}}
            })

        setBusy(false);
        if (!results || results.error){
            message.error((results && results.error.message) || "Invalid request, please check your credentials!")
            return false;
        }

        setForm(results)

        // let _invitationId = Buffer.from(timestamp() + "," + results._id).toString('base64')
        // console.log("_invitationId: ", _invitationId)
        // set_invitationId(_invitationId);
    }

    if (invitationId) return <TestForm match={{ params:{ invitationId } }} />
    if (form) return <TestForm form={form} />

    console.log({ invitationId, form })

    
    return (
        <div style={{ backgroundColor: "#001529", flex: 1, alignContent: "center", alignItems: "center", justifyContent: "center", display: "flex" }}>
            <div align="center">
                <Row gutter={[10]} align='middle'>
                    <Col><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-light.png`} /></Col>
                    <Col>
                        <Loader loading={busy}>
                            <Space direction='vertical' style={{ width:"100%" }}>
                                <Input onChange={({ target }) => setState({ ...state, lc_number: target.value }) } type='text' name='lc_number' placeholder='Enter License Number'></Input>
                                <Input onChange={({ target }) => setState({ ...state, password: target.value })}type='text' name='password' placeholder='Enter Your Password'></Input>
                                {(state.lc_number && state.password) && <Button onClick={()=>onSubmit()}>Submit</Button>}
                            </Space>
                        </Loader>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
