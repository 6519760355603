import React from 'react';
import { addModule, addReducers } from '../connector';
import DorsList from './dor_list';
import DorsDetails from './dor_details'
// import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT, MODULE } from './constants';

// export const modulePermissions = getmodulePermissions('dor-management');
// addReducers({ admins: reducers });

addModule(
    { menuPath: `${ROOT}`, path: `${ROOT}`, component: DorsList, icon: 'user-tie', title: "Dors", exact: true, leftNav: true },
)
addModule(
    { path: `${ROOT}/:id`, component: DorsDetails, title: "Dors Details", leftNav: false },
)


// if (modulePermissions['dors.view']){
//     addModule(
//         { menuPath: `${ROOT}`, path: `${ROOT}`, component: DorsList, icon: 'user-tie', title: "Dors", exact: true, leftNav: true },
//     )
//     addModule(
//         { path: `${ROOT}/:id`, component: DorsDetails, title: "Dors Details", leftNav: false },
//     )
// }

