/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from "antd";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { ContentArea } from "Layout_v1";
import { Button, Icon } from 'Common/components';
import { connect } from "react-redux";
import List from './components/list'
import { modulePermissions } from '.'
import { uriRoot } from 'configs';
import { useLocation } from 'react-router-dom';


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * KnowledgebaseSearchResults
 */
const KnowledgebaseSearchResults = props => {
  let query = useQuery();

  let tag = query.get("tag")

  return (<>
    <Breadcrumb style={{ padding: "10px" }}>
      <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
      <Breadcrumb.Item href='/admin/knowledgebase'><Icon icon="book-reader" size="1x" /> Knowledge</Breadcrumb.Item>
      <Breadcrumb.Item>Knowledge Filtered Resutls</Breadcrumb.Item>
    </Breadcrumb>

    <ContentArea>
      <List filter={{
        tags: { $in:[tag] }
      }} />
    </ContentArea>
  </>)

}
// KnowledgebaseSearchResults.propTypes = {
//   ep_admin: PropTypes.object.isRequired,
// }
export default KnowledgebaseSearchResults;
