import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import { ContentArea } from "Layout_v1";
import { Icon } from 'Common/components';
import { connect } from "react-redux";
import { HomeOutlined } from '@ant-design/icons';
import { uriRoot } from 'configs';
import SMSForm from './components/sms_form'
import SMSList from './components/sms_list'


class SMSHome extends Component {
    render() {
        return (<>
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item><Icon icon="user-shield" size="1x" /><b> SMS (+18252549595)</b></Breadcrumb.Item>
            </Breadcrumb>

            <ContentArea transparent style={{padding:"0"}}>
                <SMSForm />
                <SMSList />
            </ContentArea>
        </>)
    }
}


const mapStateToProps = ({ ep_admin }) => {
    return { filter: ep_admin?.pageSettings?.guard_searchBar }
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export default connect(mapStateToProps)(SMSHome)

