import { INIT_FORM_DATA, UPDATE_FORM_DATA, FLUSH_DATA, UPDATE_MODULE, REMOVE_MODULE } from '../constants';

export const initForm = (payload) => {
    return {
        type: INIT_FORM_DATA,
        payload
    }
}
export const updateForm = (payload) => {
    return {
        type: UPDATE_FORM_DATA,
        payload
    }
}

export const flushData = (payload) => {
    return {
        type: FLUSH_DATA,
        payload
    }
}

export const updateModule = (payload) => {
    return {
        type: UPDATE_MODULE,
        payload
    }
}

export const removeModule = (payload) => {
    return {
        type: REMOVE_MODULE,
        payload
    }
}
