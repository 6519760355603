import React, { useState } from 'react'
import { ContentArea } from "Layout_v1";
import { Breadcrumb, message, Row, Col, Drawer, Space } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'Common/components';
import FormsDataList from '../Components/FormsDataList';
import { Link } from 'react-router-dom';
import FormDataBlacklist from '../Components/FormDataBlacklist';
import SendApplicationForm from '../Components/SendApplicationForm';
import { CLIENT_INFO_FORM, GUARD_APPLICATION_FORM, SECURITY_GUARD_INTERVIEW } from '../constants'; 

const GuardApplicationList = props => {
  const [showblacklist, set_showblacklist] = useState(false);
  const [showSendForm, set_showSendForm] = useState(false);
  const [activeTab, set_activeTab] = useState('active');

  let dataFilter = activeTab;
  if (activeTab == 'active') dataFilter = { status: { $nin: ['hired', 'rejected', 'perspective', 'processing'] } }
  if (activeTab == 'hired') dataFilter = { status: 'hired' }
  if (activeTab == 'rejected') dataFilter = { status: 'rejected' }
  if (activeTab == 'perspective') dataFilter = { status: 'perspective' }
  if (activeTab == 'processing') dataFilter = { status: 'processing' }

  // Object.assign(dataFilter, { ref_key:GUARD_APPLICATION_FORM })

  return (<>
    <Row align="middle">
      <Col flex="auto">
        <Breadcrumb style={{ padding: "10px" }}>
          <Breadcrumb.Item to={'/'}><HomeOutlined /></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/admin/FormsData">Form Data</Link></Breadcrumb.Item>
          <Breadcrumb.Item>Guard Applications</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col style={{ marginRight:"10px", fontWeight:"bold" }}>
        <Space>
          <Button color='grey' onClick={() => set_showSendForm(true)}>Send Application Form</Button>
          <Button color='grey' onClick={() => set_showblacklist(true)}>View black list</Button>
        </Space>
      </Col>
    </Row>
    
    <div style={{ margin: "15px 15px 0 15px" }}><Tabs 
      tabsArray={[
        { key: "active", title:"Active" },
        { key: "hired", title:"Hired" },
        { key: "rejected", title:"Rejected" },
        { key: "perspective", title:"Perspective" },
        { key: "processing", title:"Processing" },
      ]} 
      onChange={set_activeTab}
    size="large" /></div>

    <ContentArea style={{marginTop:0}}>
      <FormsDataList permanent_filter={dataFilter} title="Guard Applications" ref_key={GUARD_APPLICATION_FORM} note_ref_key="guard_appli" />
    </ContentArea>


    <Drawer title="Black list" placement="right" width={'700px'} onClose={() => set_showblacklist(false)} visible={showblacklist}>
      {showblacklist && <>
        <FormDataBlacklist ref_key={GUARD_APPLICATION_FORM} />
      </>}
    </Drawer>

    <SendApplicationForm type={GUARD_APPLICATION_FORM} visible={showSendForm} onCancel={() => set_showSendForm(false)} />

  </>)
}

export default GuardApplicationList;
