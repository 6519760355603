import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Form } from 'react-final-form'
import { loader } from 'graphql.macro';
import { rules, FormField } from 'Common/components/Form';
import { withApollo } from '@apollo/client/react/hoc';
import { Button } from 'Common/components';
import { Alert, message } from 'antd';
import { __error } from 'Common/scripts/consoleHelper';

const CHANGE_STATUS = loader('src/Graphql/FormsData/editFormsDataStatus.graphql');

const StatusFormComp = ({ onStatusChange, initialValues, statusArray, client }) => {
    const [successNotes, set_successNotes] = useState(null);

    const onSubmit = async values => {
        const input = { 
            _id: values._id, 
            status: values.status,
            note: values.note,
            ref_key: values.ref_key,
            send_email_update: values.send_email_update ? true : false,
        };

        const updated = await client.mutate({ mutation: CHANGE_STATUS, variables: { input } }).then(r => {
            return r.data.editFormsDataStatus;
        }).catch(err => {
            console.log(__error("ERROR"), err);
            return { error: { message:"Failed to process the request!" }};
        });

        if (updated.error) {
            message.error(updated.error.message);
            return false;
        }

        if (onStatusChange) onStatusChange(updated);
        set_successNotes(`${values.status}`)
        return true;
    }

    if (!initialValues._id) return <Alert message="Missing ID" />
    if (!initialValues.ref_key) return <Alert message="Missing ref_key" />

    if (successNotes) return <Alert message={successNotes} type="success" showIcon />

    return (<>
        {/* <p>{initialValues.ref_key}</p> */}
        <Form onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, submitting, form, values, invalid, dirty, valid }) => (<>
                <form className="form_component" onSubmit={handleSubmit}>
                    <FormField name="status" type="select" size="medium" placeholder="SELECT" label="Status"
                        data={statusArray.filter(o => o._id !== 'new')}
                    />

                    {dirty && <>
                        {(initialValues.ref_key == 'guard_appli' && values.status=='rejected') && <>
                            <FormField name="send_email_update" type="checkbox" label="">Send Email Notification</FormField>
                        </>}
                        <FormField name="note" type="textarea" size="small" placeholder="Please provide the reason of status change" label="Notes" validate={rules.required} />
                        <div style={{ margin: "0 10px", textAlign: "right" }}>
                            <Button type="primary" size="medium" htmlType='submit' loading={submitting} disabled={!dirty || !valid}>Save</Button>
                        </div>
                    </>}
                </form>
            </>)}
        </Form>


    </>)
}
StatusFormComp.propTypes = {
    statusArray: PropTypes.array.isRequired,
    onStatusChange: PropTypes.func,
    initialValues: PropTypes.object,
}


export const StatusForm = withApollo(StatusFormComp);
export default StatusForm;