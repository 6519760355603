import React from 'react'
import { loader } from 'graphql.macro';
import { Drawer, Alert, message, Row, Col, Button, Modal } from 'antd';
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Icon, IconButton, Loader } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import { modulePermissions } from '.'
import { withApollo } from '@apollo/client/react/hoc';
import { VALUE_KEY } from './constants';

const ADD_RECORD = loader('src/Graphql/addressbooks/addAddressbookItem.graphql');
const EDIT_RECORD = loader('src/Graphql/addressbooks/editAddressbookItem.graphql');

class AddressBooksFormDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            busy: false,
            addressbook: null,
            pagination: { current: 1, pageSize: 10, }

        };
        this.onSubmit = this.onSubmit.bind(this)
    }

    async onSubmit(values) {
        const filteredData = { name: values.name, phones: values.phones.map(o => o.num), }

        // update records
        if (this.props.initialData && this.props.initialData._id) {
            if (!modulePermissions['addressbook.edit']) return;

            Object.assign(filteredData, { _id: this.props.initialData._id });

            const result = await this.props.client.mutate({ mutation: EDIT_RECORD, variables: { input: filteredData } }).then(r => {
                return r.data.editAddressbookItem;
            }).catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message:"Failed to process the request!"}}
            })

            if (!result || result.error){
                // this.setState({ error: result ? result.error.message : "Invalid response" });
                message.error(result ? result.error.message : "Invalid response");
                return false;
            }else{
                this.setState({ error: null });
                message.success("Record Updated");
                this.props.onUpdate(result);
                this.props.onClose();
                return true;
            }

        }else{
            // add new record
            if (!modulePermissions['addressbook.add']) return;

            Object.assign(filteredData, { category: VALUE_KEY })
            
            const result = await this.props.client.mutate({ mutation: ADD_RECORD, variables: { input: filteredData } }).then(r => {
                return r.data.addAddressbookItem;
            }).catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })

            if (!result || result.error) {
                // this.setState({ error: result ? result.error.message : "Invalid response" });
                message.error(result ? result.error.message : "Invalid response")
                return false;
            } else {
                this.setState({ error: null });
                message.success("Record Added");
                this.props.onAdd(result);
                this.props.onClose();
            }

        }

        return true;
    }

    render() {
        const { showDrawer, onClose, initialData } = this.props;
        // if (this.state.error) return <Alert message="Error" description={this.state.error} type="error" showIcon />

        return (<>
            <Modal
                title={initialData ? "Edit Contact" : "Add New Contact"}
                width={550}
                onCancel={onClose}
                visible={showDrawer}
                bodyStyle={{ paddingBottom: 30 }}
                extra={<Button onClick={onClose}>Cancel</Button>}
                footer={false}
            ><>
                {this.props.loading && <Loader style={{ margin: "auto" }} loading={true} />}
                {this.state.error && <Alert message="Error" description={this.state.error} type="error" showIcon />}

                {!this.props.loading && 
                    <FormComponent onSubmit={this.onSubmit} id='AddresBookForm'  debug={true}
                        fields={{ 
                            // phones:[{num:""}], 
                            ...initialData, 
                            phones: initialData?.phones ? initialData.phones.map(o => ({ num: o })) : [{ num: "" }]
                        }}
                        mutators={{ ...arrayMutators }}
                        form_render={formProps => {
                            const { values, form, invalid, dirty, valid, submitting } = formProps;

                            return (<>
                                <FormField label="Name" name="name" type="text" validate={rules.required} />

                                <FieldArray name="phones">
                                    {({ fields }) => (
                                        <div>
                                            {fields.map((name, index) => (
                                                <Row key={index} style={{ borderBottom: "0px solid #EEE", marginBottom: "0px" }} align="middle">
                                                    <Col flex="auto">
                                                        <FormField
                                                            disabled={submitting}
                                                            // placeholder="+1 XXX XXX XXXX"
                                                            mask="+\1 999-999-9999"
                                                            label="Mobile/Phone Number"
                                                            name={`${name}.num`}
                                                            type="mask"
                                                            validate={composeValidators(
                                                                rules.required,
                                                                rules.minChar(12, (val) => val.replace(/_/, "")),
                                                                rules.maxChar(12, (val) => val.replace(/_/, "")),
                                                            )}
                                                        />
                                                    </Col>
                                                    {(fields.length>1) && <Col style={{paddingTop:"18px"}}>
                                                        <IconButton icon="trash-alt" onClick={() => fields.remove(index)} />
                                                    </Col>}                                                    
                                                </Row>
                                            ))}

                                            <div style={{ margin: "20px", textAlign:"center" }}>
                                                <IconButton icon="plus" onClick={()=>fields.push({ num: "" })} />
                                            </div>

                                        </div>
                                    )}
                                </FieldArray>
                                
                                <Row style={{marginTop:'20px', marginRight:'10px'}}>
                                    <Col flex="auto"></Col>
                                    <Col flex="200px">
                                        <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >{values._id ? "Update" : "Create"}</Button>
                                    </Col>
                                </Row>
                            </>)
                        }}
                    />
                }
                </></Modal>

        </>)

    }

}

export default withApollo(AddressBooksFormDrawer);