import React, { useState } from 'react'
import { Alert, message, Modal } from 'antd';
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { Button } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import axios from 'axios';
import { CLIENT_INFO_FORM, GUARD_APPLICATION_FORM, SECURITY_GUARD_INTERVIEW } from '../constants';

const SendApplicationForm = ({ visible, onCancel, type }) => {
    // const [busy, setBusy] = useState(false)

    if (!type) return <Alert message="Missing application type" type='error' showIcon />

    var title = ""
    var message = ""
    var subject = ""
    if (type == CLIENT_INFO_FORM) {
        title = "Send Client Information Form"
        message = "A-List Security Group Inc. is requesting you to please provide your basic infomration, so we can process your services request";// "Please follow the link given to provide the Client Information"
        subject = "Provide your basic Information to A-List Security Group Inc."
    }
    else if (type == GUARD_APPLICATION_FORM) {
        title = "Send Guard Application Form"
        message = "We are pleased to invite you to apply for the position of Security Guard at A-List Security Group Inc.. We believe that your skills and experience make you an excellent fit for this role." // "Please follow the link given to apply for job at A-List Security Group."
        subject = "Apply for job at A-List Security Group Inc."
    }
    else{
        alert("Missing form refferenfce")
        return null;
    }


    const onSubmit = async (values) => {
        // console.log("onSubmit()", values);

        const filter = {
            action: type,
            mailInfo: {
                email: values.email,
                subject: subject,
                body: values.message,
                name: values.name,
                url: `${process.env.REACT_APP_HOST_URL}/inputForm/${type}` // "http://localhost:8083/inputForm/guard_application_form"
            }
        }

        const resutls = await axios.post(process.env.REACT_APP_API_URL + '/mail/send', filter)
            .then(({ error, message, success }) => {
                if (error) message.error(message);
                else onCancel();
                return success;
            })
            .catch(function (error) {
                console.log(__error("ERROR: "), error);
                return { error: { message: "Request Error!" } };
            });

        return true;
    }

    console.log("title: ", title)

    return (<>
        <Modal title={title} visible={visible} footer={false} onCancel={onCancel}>
            {visible && 
                <FormComponent onSubmit={onSubmit} id='SendApplicationForm' debug={false} fields={{ message }} moveOnError
                    form_render={formProps => {
                        const { values, form, invalid, dirty, valid, submitting } = formProps;

                        return (<>
                            <FormField id="name" label="Receiver name" name="name" size="medium" type="text" validate={rules.required} />
                            <FormField id="email" label="Email" name="email" size="medium" type="email" validate={rules.required} />
                            <FormField id="message" label="Message" name="message" size="medium" type="textarea" validate={rules.required} />

                            <div style={{padding:"20px"}}>
                                <Button type="primary" block htmlType='submit' disabled={!dirty || !valid} size="large" loading={submitting}>Send Application</Button>
                            </div>
                        </>)
                    }}
                />
            }
        </Modal>
    </>)
}
export default SendApplicationForm;