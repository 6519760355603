import React, { Component, useState, useEffect } from 'react'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Button, Icon, SiteSearch, GuardSearch } from 'Common/components'
import { message, Row, Col } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { testStatus } from 'configs'
import { connect } from "react-redux";
import { updatePageSettings } from 'Store/ep_admin.actions.js'
// import { GUARD_APPLICATION_FORM, CLIENT_INFO_FORM } from '../constants'
// import { formArray } from '..';

// const ref_keys = formArray.map(o => ({ _id: o.key, title: o.menuTitle }));


const InvitationSearchBar = props => {
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);

    // const filter = props?.ep_admin?.pageSettings?.invitation_searchBar;

    useEffect(() => {
        onSubmitSearch({ ...props?.ep_admin?.pageSettings?.invitation_searchBar })
    }, [])


    const onSubmitSearch = async (values, redux) => {
        // console.log("onSubmitSearch: ", values); return;
        const filter = { ...values, ...props.defaultFilter }

        // const filter = { ...props.defaultFilter, ...values }
        // if (values.status && props.defaultFilter.status) Object.assign(filter, {
        //     $and: [
        //         props.defaultFilter.status,
        //         values.status
        //     ]
        // })

        setBusy(true);

        await props.fetchData({ filter }).then(r => {
            setBusy(false);
            return r;
        });

        props.updatePageSettings({
            page: 'invitation_searchBar',
            values: {
                ...values
            }
        })

        return false;
    }

    // let defaultValues = { 
    //     // ref_key: ref_keys[0]._id,
    //     ...props.ep_admin?.pageSettings?.invitation_searchBar
    // };




    return (<>

        <FormComponent onSubmit={onSubmitSearch} id='InvitationSearch' loading={busy} debug={false}
            fields={{ ...props.ep_admin?.pageSettings?.invitation_searchBar }}
            form_render={formProps => {
                const { values, form, invalid, dirty, valid } = formProps;

                return (<Row align="top">
                    <Col><FormField label="Keyword" name="search.keywords" placeholder={"Keyword search.."} size="medium" type="text" allowClear compact /></Col>
                    <Col><Button style={{ marginTop: "19px" }} type="primary" htmlType='submit'><Icon icon="search" /></Button></Col>
                </Row>)
            }}
        />

    </>)


}
const mapStateToProps = ({ ep_admin }) => {
    // console.log("ep_admin: ", ep_admin.pageSettings.invitation_searchBar)
    return ({ ep_admin });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvitationSearchBar)

