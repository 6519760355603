import React, { useState } from 'react'

export const ListItem = (props) => {
    const { title, onClick } = props;

    return <div className='g_list_item' onClick={onClick}>{title}</div>
}
export const List = ({ title, items, onClick, onSearch }) => {

    return (<div className='general_list'>
        <div className='title'>{title}</div>
        <div>{items.map((o, i) => {
            return <ListItem {...o} onClick={()=>onClick(o)} key={i} />
        })}</div>

    </div>)
}

