import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Alert, Space } from 'antd';
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper'
import { connect } from "react-redux";
import moment from 'moment'
import axios from 'axios'
import { withApollo } from '@apollo/client/react/hoc';
import { DevBlock, Loader } from 'Common/components';
import NoteView from './components/note_view';

const LOAD_NOTES = loader('src/Graphql/notes/notes.graphql');

class NotesFromDuplicatesComp extends Component {
    state = { loading: false, error: null, data: null };

    componentDidMount(props) {
        const { ids, formkey } = this.props;

        if (!ids || ids.length < 1) return this.setState({ error: "Missing duplicate ids" });
        if (!formkey) return this.setState({ error: "Missing Form Key" }); 

        this.setState({ loading: true })

        let filter = {
            rec_id: { $in: ids.map(o=>Number(o)) },
            ref_key: formkey
        }

        this.props.client.query({ query: LOAD_NOTES, variables: { filter: JSON.stringify(filter) } }).then(r => {
            const response = r.data.notes;

            if (!response || response.error) {
                this.setState({ loading: false, error: !response ? "Invalid response" : response.error.message });
                return;
            }
            this.setState({ loading: false, data: response });

        }).catch(err => {
            console.log(__error("ERROR: "), err);
            this.setState({ loading: false, error: "Unable to process request at the moment!" });
        })

    }

    render() {
        const { error, data, loading } = this.state;

        if (error) return <Alert message={error} type="error" showIcon />
        if (loading) return <Loader loading={true} />

        return (<>
            <Space direction='vertical' style={{width:"100%"}}>
                {data?.map((item, i) => (
                    <NoteView key={i} data={item} user={this.props.ep_admin.user} />
                ))}
            </Space>
        </>)
    }
}
NotesFromDuplicatesComp.propTypes = {
    ids: PropTypes.array.isRequired,
    formkey: PropTypes.string.isRequired,
}

const mapStateToProps = ({ ep_admin }) => {
    return ({ ep_admin });
}
export const NotesFromDuplicates = connect(mapStateToProps)(withApollo(NotesFromDuplicatesComp));
