import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import { FormField } from './Form'
import { __error } from 'Common/scripts/consoleHelper'
import { withApollo } from '@apollo/client/react/hoc';

const TESTS = loader('src/Graphql/TestForms/testForms.graphql');


const TestFormSearchComp = props => {
    const [list, setList] = React.useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = React.useState({});

    React.useEffect(() => {
        if (props.preload){
            _fetchData("none");
        }
        //   return () => {
        //     effect
        //   };
    }, [props.preload])

    var lastFetchId = 0; 
    const _fetchData = async (value) => {
        if (!value || value.length < 1) return;
        lastFetchId += 1;
        const fetchId = lastFetchId;
    
        if (value!='none') setState({ kw: null, loading: true })
        else setState({ kw: value, loading: true })
        
        let filter = value == 'none' ? "" : JSON.stringify({ search:{keywords: value} });


        props.client.query({ query: TESTS, variables: { filter } }).then(e => {
            if (e.error || e.errors) {
                console.log(__error("ERROR "), e);
                message.error((e.error && e.error.message) || (e.errors && e.errors[0].message));
                setState({ kw: value, loading: false })
                return;
            }

            if (e.data.testForms) setList(e.data.testForms.map(o=>({ _id:o._id, title:o.title, raw:o })) )
            setState({ loading: false })

        }).catch(err => {
            console.log(__error("API Call ERROR: Test Forms : "), err);
            message.error("Request ERROR");
            setState({ loading: false })
        })


    }
    const fetchData = debounce(_fetchData, 800);


    return (
        <>
            <FormField 
                allowClear
                validate={props.validate}
                size={props.size || "medium"}
                // type={props.multi ? 'select-multiple' : "select" }
                type={props.mode == 'multiple' ? 'select-multiple' : 'select'}
                name={props.name}
                label={props.label}
                data={list}
                placeholder="Search Tests"
                addNull={props.addNull || false}
                onSelect={props.onSelect}
                // keyMap="value=>text"
                // onChange={handelChange}
                // defaultValue={props.defaultValue && props.defaultValue._id}
                inputProps={{
                    // onSelect: props.onSelect || undefined, // console.log,
                    onDeselect: props.onDeselect || undefined, // console.log,
                    loading:state.loading,
                    mode: props.mode || undefined, // tags, multiple
                    showSearch: true,
                    defaultActiveFirstOption: false,
                    showArrow: true,
                    notFoundContent: state.loading ? <Spin size="small" /> : null,
                    filterOption: false,
                    onSearch: fetchData,
                    // onChange:handelChange,
                }}
            />
        </>
    )
}
TestFormSearchComp.propTypes = {
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValues: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
}

export const TestFormSearch = withApollo(TestFormSearchComp)
export default TestFormSearch;