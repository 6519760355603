import React, { Component, useState, useEffect } from 'react'
import { loader } from 'graphql.macro';
import { Popover, Alert, message, Row, Col, Breadcrumb, Tooltip, Space } from 'antd';
import { PlusOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Loader, DevBlock, Icon, DataRow, Avatar, ActionMenu, IconButton } from 'Common/components';
import { ContentArea } from "Layout_v1";
import { __error, __yellow } from 'Common/scripts/consoleHelper'
import Notes from 'Modules/Notes';
import { mongoToDate } from 'Common/scripts/Functions';
import { defaultDateFormat, uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import { verifyRole } from 'Common/scripts/Security';

import BasicProfile from './components/BasicProfile';
import ContactPerson from './components/ContactPerson';
import SiteAddress from './components/SiteAddress';
import EmergencyContacts from './components/EmergencyContacts';
import BillingInfo from './components/BillingInfo';
import { ListHeader } from 'Common/components/Typography';
import { Link } from 'react-router-dom';

const DETAIL = loader('src/Graphql/client/client.graphql');
const STATUS_UPDATE = loader('src/Graphql/guard/editGuard.graphql');


const DetailsComp = ({ initialData, _id }) => {
    const [data, setData] = useState(false)

    useEffect(() => {
        if (!initialData || data) return;
        setData(initialData)
    }, [initialData])

    const onUpdate = (values) => setData(values)

    if (!data) return <Alert type='error' message="No data found!" showIcon />

    return (<>
        <div style={{ minWidth: "1000px" }}>
            {/* <Row gutter={[15]}>
                <Col flex="auto" />
                <Col><span>Created  {mongoToDate(data.created_at).format(defaultDateFormat)}</span></Col>
            </Row> */}

            <Row style={{ flexWrap: "nowrap" }} gutter={[12]}>
                <Col flex="500px">
                    <Space direction='vertical' style={{ width:"100%" }}>
                        <div className='preview_card'>
                            <BasicProfile data={data} onUpdate={onUpdate} />
                        </div>

                        <div className='preview_card'>
                            <ContactPerson data={data} onUpdate={onUpdate} />
                            <SiteAddress data={data} onUpdate={onUpdate} />
                            {verifyRole('client.sensitive-data') && <>
                                <BillingInfo data={data} onUpdate={onUpdate} />
                            </>}
                            <EmergencyContacts data={data} onUpdate={onUpdate} />
                        </div>
                    </Space>


                    {verifyRole('guard.sensitive-data') && <>
                        <div style={{ height: "12px" }} />
                        <div className='preview_card'>
                            <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="file-alt" /> Documents</div>
                            <Notes type="document" id={_id} formkey="client_documents" />
                        </div>
                    </>}

                </Col>
                <Col flex="auto">
                    <div className='preview_card'>
                        <ListHeader title="Sites" sub={`${initialData?.sites?.length || 0} site(s) registered under this client`} />

                        <Space style={{ width:"100%" }} direction='vertical'>
                            <div style={{ height:"20px" }} />
                            {initialData?.sites?.map((site, i) => {
                                return (<div key={i}>
                                    {/* <DevBlock obj={site} /> */}
                                    <div><Link to={`/admin/sites/${site._id}`}><b>{site.name}</b></Link></div>
                                    <div>{site.address}</div>
                                    <hr />
                                </div>)
                            })}
                        </Space>
                    
                    </div>


                    <div style={{ height: "12px" }} />
                    <div className='preview_card'><Notes id={_id} formkey="client_notes" /></div>
                </Col>
            </Row>
        </div>

        <DevBlock obj={data} />
    </>)
}

class DetailCompWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, data: null, error: null, showTerminationProcess: false };
        this.onSubmit = this.onSubmit.bind(this)
    }

    onSubmit(values) {
        this.props.client.mutate({ mutation: STATUS_UPDATE, variables: { input: { _id: this.props.match.params.id, status: values.Status } } }).then(e => {
            if (e.data.error) {
                // this.setState({ loading: false, error: r.data.error.message });
                return;
            }
            message.success("Status is changed")
        }).catch(err => {
            console.log("ERROR: ", err);
        })
    }

    componentDidMount(props) {
        if (!this.props.match.params.id) {
            return this.setState({ error: "Missing account ID" })
        }

        this.getClient();
    }

    async getClient() {
        console.log(__yellow("getClient()"), this.props.match.params.id)

        this.setState({ loading: true })

        let results = await this.props.client.query({ query: DETAIL, variables: { id: this.props.match.params.id } })
            .then(r => (r?.data?.client))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message:"Unable to process request at the moment!"}}
            })

        if (!results || results.error){
            this.setState({ loading: false, error: (results && results.error.message) || "Client not found!" })
            return false;
        }

        this.setState({ loading: false, data:results })            
    }

    render() {
        const { inline } = this.props;
        const { loading, data, error } = this.state;

        if (loading) return <Loader loading={true} />;
        if (error) return <Alert message="Error" description={error} type="error" showIcon />;
        if (!data) return <p>No data found</p>;

        if (inline) return <DetailsComp _id={this.props.match.params.id} initialData={data} />

        return (<>
            <Row align="middle">
                <Col flex="auto">
                    <Breadcrumb style={{ padding: "10px" }}>
                        <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                        {/* <Breadcrumb.Item href="/guards"><span>Guards</span></Breadcrumb.Item> */}
                        <Breadcrumb.Item onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }} href="#"><span><Icon icon="user-tie" size="1x" /> Clients</span></Breadcrumb.Item>
                        <Breadcrumb.Item>Client profile</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col>
                    <span>Created  {mongoToDate(data.created_at).format(defaultDateFormat)}</span>
                </Col>
            </Row>

            <ContentArea transparent>
                <DetailsComp _id={this.props.match.params.id} initialData={data} />
            </ContentArea>


        </>)

    }
}




export const ClientDetail = withApollo(DetailCompWrapper)
export default ClientDetail;

