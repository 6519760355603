export const UserRightsByCat = [
    {
        _id: 100, title: 'Client Management', key: 'client-management',
        rules: [
            { title: 'View Client Account', key: 'client.account-view' },
            { title: 'Add Client Account', key: 'client.account-add' },
            { title: 'Edit Client Account', key: 'client.account-edit' },
            { title: 'Delete Client Account', key: 'client.account-delete' },
            
            { title: 'Access Client Billing info', key: 'client.bill' },
        ]
    },

    {
        _id: 200, title: 'User Management', key: 'user-management',
        rules: [
            { title: 'Access User Module', key: 'user.module-access' },
            { title: 'View User Module', key: 'user.view' },
            { title: 'Edit Personal Account', key: 'user.personal-edit' },
            { title: 'Add User Account', key: 'user.create' },
            { title: 'Update User Account', key: 'user.update' },
            { title: 'Delete User Account', key: 'user.delete' },
        ]
    },

    {
        _id: 300, title: 'Account Role Management', key: 'account-role-management',
        rules: [
            { title: 'Update Account roles', key: 'account-role.update' },
        ]
    },

    {
        _id: 400, title: 'Sites Management', key: 'sites-management',
        rules: [
            { title: 'View Site', key: 'site.view' },
            { title: 'Add Site', key: 'site.add' },
            { title: 'Update Site', key: 'site.update' },
            { title: 'Delete Site', key: 'site.delete' },
            { title: 'Manage site services', key: 'site.manage-services' },

            { title: 'Access Site Billing info', key: 'site.bill' },

            { title: 'Site Reports: Manager', key: 'site-report.manage' },
            { title: 'Site Reports: Delete', key: 'site-report.delete' },
            { title: 'Manage site Docs', key: 'site-docs.manage' },
        ]
    },

    {
        _id: 500, title: 'Guard Account Management', key: 'guard-account-management',
        rules: [
            { title: 'View Guards', key: 'guard.view' },
            { title: 'Add Guard', key: 'guard.add' },
            { title: 'Update Guard', key: 'guard.update' },
            { title: 'Delete Guard', key: 'guard.delete' },
            { title: 'Export Guards', key: 'guard.export' },

            { title: '* Show Sensitive Data', key: 'guard.sensitive-data' },

        ]
    },

    {
        _id: 600, title: 'Hours Schedule Management', key: 'hours-schedule-management',
        rules: [
            { title: 'View Hours Schedule', key: 'hours-schedule.view' },
            { title: 'View Hours Activity Logs', key: 'hours-schedule.view-activity' },
            { title: 'Add Hours Schedule', key: 'hours-schedule.add' },
            { title: 'Update Hours Schedule', key: 'hours-schedule.update' },
            { title: 'Delete Hours Schedule', key: 'hours-schedule.delete' },
            { title: 'Approve Hours Schedule', key: 'hours-schedule.approve' },
            { title: 'Export Paygrid Reports', key: 'hours-schedule-export.paygrid' },
            { title: 'Export Invoice', key: 'hours-schedule-export.invoice' },
        ]
    },

    {
        _id: 700, title: 'Form Data Management', key: 'form-data-management',
        rules: [
            { title: 'Access Form Data', key: 'form-data.access' },

            { heading: 'Guard Application' },
            { title: 'View Guard Application', key: 'form-data.guard.applicaton-view' },
            { title: 'Delete Guard Application', key: 'form-data.guard.applicaton-delete' },
            { title: 'Update Guard Application', key: 'form-data.guard.applicaton-update' },

            { heading: 'Client' },
            { title: 'View Client Information', key: 'form-data.client.information-view' },
            { title: 'Delete Client Information', key: 'form-data.client.information-delete' },
            { title: 'Update Client Information', key: 'form-data.client.information-update' },

            { heading: 'Security Guard Interviews' },
            { title: 'View Security Guard Interviews', key: 'form-data.guard_interview.information-view' },
            { title: 'Delete Security Guard Interviews', key: 'form-data.guard_interview.information-delete' },
            { title: 'Update Security Guard Interviews', key: 'form-data.guard_interview.information-update' },

            { heading: 'BlackList' },
            { title: 'Manage BlackList', key: 'blacklist-manage' },

            { heading:'Reports' },
            { title: 'Delete report', key: 'delete-report' },
            { title: 'Archive report', key: 'archive-report' },
        ]
    },

    {
        _id: 800, title: 'System Settings', key: 'system-settings',
        rules: [
            { title: 'Manage Value Pairs', key: 'system-settings.manage-value-pairs' },
            { title: 'Manage Compnay Assets', key: 'system-settings.manage-company-assets' },
            { title: 'Manage Products & services', key: 'system-settings.manage-products_services' },
        ]
    },

    {
        _id: 900, title: 'Noticeboard', key: 'noticeboard',
        rules: [
            { title: 'View Noticeboard', key: 'noticeboard.view' },
            { title: 'Delete Notes From Noticeboard', key: 'noticeboard.note.delete' },
            { title: 'Add Notes To Noticeboard', key: 'noticeboard.note.add' },
            { title: 'Edit Personal Notes On Noticeboard', key: 'noticeboard.note.edit-personal-notes' },
            { title: 'Edit All Notes On Noticeboard', key: 'noticeboard.note.edit-all-notes' },
        ]
    },

    {
        _id: 1000, title: 'Extra', key: 'extra',
        rules: [
            { title: 'View Old Guard Applications', key: 'extra.old-guard-application' },
            { title: 'View Old Client Form', key: 'extra.old-client-forms' }
        ]
    },

    {
        _id: 1100, title: 'SMS', key: 'sms-management',
        rules: [
            { title: 'Access SMS manager', key: 'sms-management.access-module' },
            { title: 'Delete SMS', key: 'sms-management.delete' }
        ]
    },


    {
        _id: 1200, title: 'Course', key: 'course-mg',
        rules: [
            { title: 'Access Course Manager', key: 'course-mg.access-module' },
            { title: 'Add Course', key: 'course-mg.add' },
            { title: 'Edit Course', key: 'course-mg.edit' },
            { title: 'Delete Course', key: 'course-mg.delete' },
        ]
    },

    {
        _id: 1300, title: 'Addressbook', key: 'addressbook-mg',
        rules: [
            { title: 'Addressbook Manager', key: 'addressbook.access' },
            { title: 'Add Contact', key: 'addressbook.add' },
            { title: 'Edit Contact', key: 'addressbook.edit' },
            { title: 'Delete Contact', key: 'addressbook.delete' },
        ]
    },

    {
        _id: 1400, title: 'Leads', key: 'leds-mg',
        rules: [
            { title: 'Access Leads list', key: 'leads.list' },
            { title: 'Add Leads list', key: 'leads.add' },
            { title: 'Edit Leads list', key: 'leads.edit' },
            { title: 'Access Leads Details', key: 'leads.details' },
            { title: 'Delte Leads list', key: 'leads.delete' },
            { title: 'Access Sales Page', key: 'sales-stats' },
        ]
    },

    {
        _id: 1500, title: 'System Updates', key: 'system-updates',
        rules: [
            { title: 'Run updates', key: 'run-updates' },
        ]
    },


    {
        _id: 1600, title: 'Accounts', key: 'acc',
        rules: [
            { title: 'Access Accoutns Module', key: 'acc-access' },
            { title: 'Request Payorder', key: 'acc-request-po' },
        ]
    },

    {
        _id: 1700, title: 'Threads', key: 'threads',
        rules: [
            { title: 'Access Threads Module', key: 'threads.access' },
            // Channels
            { title: 'Create Channels', key: 'th.create.ch' },
            { title: 'Access Channel History', key: 'th.ch.history' },
            { title: 'Full Access of Channels', key: 'th.ch.fullacc' },
            // Tickets
            { title: 'Create Tickets', key: 'th.create.tk' },
            { title: 'Full Access of Tickets', key: 'th.tik.fullacc' },
            { title: 'Access Ticket History', key: 'th.tik.history' },
            { title: 'Show Discarded Messages', key: 'th.tik.sho.discarded' },
            { title: 'Delete Ticket', key: 'th.tik.del' },
            { title: 'Delete Message', key: 'th.tik.del.msg' },
            // Todo
            { title: 'Create Todo list', key: 'th.create.todo' },
            { title: 'Access Todo History', key: 'th.todo.history' },
            { title: 'Access Shared Todo', key: 'th.acc.todo.shared' },
            { title: 'Full Access of Todo', key: 'th.todo.fullacc' },
        ]
    },

    {
        _id: 1800, title: 'ZKT Attendance System', key: 'zkt_att',
        rules: [
            { title: 'Access ZKT Attendance Module', key: 'zkt_att.access' },
            // Channels
            { title: 'Fetch users', key: 'zkt_att.users' },
            { title: 'Create User', key: 'zkt_att.user.create' },
            { title: 'Manage User', key: 'zkt_att.user.manage' },
            { title: 'View Attendance', key: 'zkt_att.att.view' },
            { title: 'Flush Attendance', key: 'zkt_att.att.flush' },
        ]
    },

    {
        _id: 1900, title: 'Knowledgebases', key: 'knowledgebases',
        rules: [
            { title: 'Access Knowledgebases Module', key: 'knowledgebases.access' },
            { title: 'Add Record', key: 'knowledgebases.add' },
            { title: 'Edit Record', key: 'knowledgebases.edit' },
            { title: 'Delete Record', key: 'knowledgebases.delete' },
            { title: 'View Edit History', key: 'knowledgebases.edithistory' },
        ]
    },

    {
        _id: 2000, title: 'Notes', key: 'notes',
        rules: [
            { title: 'Access HR Notes', key: 'hr.notes' },
            { title: 'Access General Notes', key: 'general.notes' },
        ]
    },

    {
        _id: 2100, title: 'Tags', key: 'tags',
        rules: [
            { title: 'Access Tags', key: 'access.tags' },
            { title: 'Manage Tags', key: 'manage.tags' },
        ]
    },

    {
        _id: 2200, title: 'Dors', key: 'dors',
        rules: [
            { title: 'Access Dors', key: 'access.dors' },
            { title: 'Access Personal Records', key: 'access.personal.dors' },
            { title: 'Access All Records', key: 'access.all.dors' },
            { title: 'Allow Delete', key: 'allow.delete.dors' },
        ]
    },

    {
        _id: 2300, title: 'Page Info', key: 'page_info',
        rules: [
            { title: 'Manage Pages', key: 'pi.manage.page' },
            { title: 'Manage Fields', key: 'pi.manage.fields' },
            { title: 'Edit Fields Only', key: 'pi.edit.fields' },
        ]
    },

    {
        _id: 2400, title: 'Asset Tracking', key: 'asset_tracking',
        rules: [
            { title: 'Manage Asset Tracks', key: 'at.manage' },
            { title: 'Add', key: 'at.add' },
            { title: 'Edit', key: 'at.edit' },
            { title: 'Delete records', key: 'at.delete' },
        ]
    },


];
