/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from "antd";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { ContentArea } from "Layout_v1";
import { Button, Icon } from 'Common/components';
import { connect } from "react-redux";
import List from './components/list'
import { modulePermissions } from '.'
import { uriRoot } from 'configs';


/**
 * Knowledgebase
 */
const Knowledgebase = props => {
  return (<>
    <Breadcrumb style={{ padding: "10px" }}>
      <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
      <Breadcrumb.Item><Icon icon="book-reader" size="1x" /> Knowledge Article</Breadcrumb.Item>
    </Breadcrumb>

    <ContentArea>
      {modulePermissions['knowledgebases.access'] && <List /> }
    </ContentArea>
  </>)

}
Knowledgebase.propTypes = {
  ep_admin: PropTypes.object.isRequired,
}

const mapStateToProps = ({ ep_admin }) => {
  return ({ ep_admin });
}
//   const mapDispatchToProps = (dispatch, ownProps) => ({
//     doLogin: (payload) => dispatch(doLogin(payload)),
//   })
export default connect(mapStateToProps)(Knowledgebase);
