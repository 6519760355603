import React from 'react'
// import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';
// import { AppstoreOutlined, ContainerOutlined, DesktopOutlined, MailOutlined,
//     MenuFoldOutlined, MenuUnfoldOutlined, PieChartOutlined } from '@ant-design/icons';
import { Icon } from 'Common/components';
import { Link } from 'react-router-dom';
import { navItems } from 'Modules';
import { connect } from "react-redux";
import { updateSettings } from 'Store/ep_admin.actions';
const { Sider } = Layout;
const { SubMenu } = Menu;

const RenderFullMenu = ({ menu }) => {
    if (!menu) return <div>Empty Menu</div>
    // var patt = new RegExp(`.*${window.location.pathname}*.`, 'i'); // patt.constructor; // >> /.*pro,*./i
    let activeMenuItem = ""; //findIndexByObjVal(menu, 'path', patt);
    // setting up menu selection array
    menu.forEach((element, i) => {
        if (!element.exact && element.exact < 1) {
            // let patt = new RegExp(`.*${element.menuPath || element.path}*.`, 'i');
            // let result = String(window.location.pathname).search(patt);
            // if (result > -1) activeMenuItem += `,${i}`;
            
            let patt = new RegExp(`^${element.menuPath || element.path}`, 'i');
            let result = String(window.location.pathname).search(patt) > -1; // String(window.location.pathname).search(patt);
            if (result) activeMenuItem += `,${i}`;
        }
    });


    return (
        // <Menu theme="dark" mode="inline" defaultSelectedKeys={[`${activeMenuItem}`]}>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={activeMenuItem.split(",")}>
            {menu.map((item, i) => {
                if (!item.leftNav) return null;

                if (!item.subMenu) {
                    let itemProps = { title: item.title }
                    if (item.icon) itemProps = Object.assign(itemProps, { icon: <Icon anticon icon={item.icon} /> })

                    return <Menu.Item {...itemProps} key={i}>
                        {(item.menuPath || item.path) && <Link to={item.menuPath || item.path}>{item.title}</Link>}
                        {/* sub menu header */}
                        <span>{!item.path && item.title}</span>
                    </Menu.Item>
                }

                // Items with sub menu
                else {
                    let menuProps = { title: item.title }
                    if (item.icon) menuProps = Object.assign(menuProps, { icon: <Icon anticon icon={item.icon} /> })

                    return (
                        <SubMenu {...menuProps} key={i}>
                            {item.subMenu.map((item, ii) => {
                                if (item.leftNav===false) return null;

                                let itemProps = { title: item.title }
                                if (item.icon) itemProps = Object.assign(itemProps, { icon: <Icon anticon icon={item.icon} /> })

                                return <Menu.Item {...itemProps} key={`${i}_${ii}`}>
                                    {(item.menuPath || item.path) && <Link to={item.menuPath || item.path}>{item.title}</Link>}
                                    <span>{!item.path && item.title}</span>
                                </Menu.Item>
                            })}
                        </SubMenu>
                    )
                }

            })}
        </Menu>
    )
}

const _SideMenu = props => {
    const renderMenu = menu => {
        return <RenderFullMenu menu={menu || navItems} />
    }

    return (
        <Sider 
            trigger={undefined} //_trigger={<p onClick={() => props.updateSettings({ navCollapsed: !props.ep_admin.navCollapsed })}>trigger button</p>} 
            collapsible
            // breakpoint="lg"
            // onBreakpoint={broken => {
            //     console.log("onBreakpoint()", broken);
            //     if (!broken) return;
            //     // props.setCollapsedWidth(broken ? 0 : undefined)
            //     // props.setNavCollaps(broken);
            // }}
            collapsedWidth={props.collapsedWidth}
            defaultCollapsed={props.ep_admin.navCollapsed}
            onCollapse={(current_state) => {
                // console.log("onCollapse()", current_state);
                props.updateSettings({ navCollapsed: !props.ep_admin.navCollapsed })
                // props.setNavCollaps(!props.collapsed);
            }} // required for footer collaps button/
            collapsed={props.ep_admin.navCollapsed}
            className="site-sidebar"
            >
            
            <div className="sidebar-logo"><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-light.png`} /></div>
            {/* {!props.ep_admin.navCollapsed && <div style={{color:'white', textAlign:"center", fontWeight:'bold', fontSize:'1.5em'}}>A-List Portal</div>} */}
            {renderMenu(props.menu)}

        </Sider>
    )
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateSettings: (payload) => dispatch(updateSettings(payload)),
})
const mapStateToProps = ({ ep_admin }) => {
    // console.log("ep_admin: ", ep_admin)
    return {ep_admin};
    // return ({ user: (state.user && state.user.user) || {} });
}

export const SideMenu = connect(mapStateToProps, mapDispatchToProps)(_SideMenu);
export default SideMenu;
