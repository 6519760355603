import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import { FormField } from './Form'
import { __error } from 'Common/scripts/consoleHelper'
import _ from 'lodash'
import { withApollo } from '@apollo/client/react/hoc';

const CLIENTS = loader('src/Graphql/client/clients.graphql');


const ClientSearchComp = props => {
    const [list, setList] = React.useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = React.useState({});
    const [changer, setChanged] = React.useState("");

    useEffect(() => {
        let changer_val = JSON.stringify([props.preload, props.filter]);
        if (changer_val == changer) return;

        setChanged(changer_val)
        _fetchData(props.preload === true ? undefined : props.preload);
    }, [props.preload, props.filter])



    var lastFetchId = 0; 
    const _fetchData = async (value) => {
        // if ((!value || value.length < 1) && !props.filter) return;
        // if ((!value || value.length < 1)) return;

        let filter = { status:"active" };

        if (value === false || !_.isString(value)) {
            setState({ loading: true })
            if (!_.isString(value)) Object.assign(filter, { ...value })
        } else {
            if (!value || value.length < 1) return;
            setState({ kw: value || undefined, loading: true })
            Object.assign(filter, { search: { keywords: value } })
        }

        lastFetchId += 1;
        const fetchId = lastFetchId;
        filter = JSON.stringify({ ...filter, ...props.filter }); // JSON.stringify({ search: { keywords: value }, ...props.filter });

        props.client.query({ query: CLIENTS, variables: { filter } }).then(e => {
            if (e.error || e.errors) {
                console.log(__error("ERROR "), e);
                message.error((e.error && e.error.message) || (e.errors && e.errors[0].message));
                setState({ kw: value, loading: false })
                return;
            }

            if (e.data.clients) setList(e.data.clients.map(o=>({ _id:o._id, title:o.name, raw:o })) )
            setState({ loading: false })

        }).catch(err => {
            console.log(__error("API Call ERROR: clients : "), err);
            message.error("Request ERROR");
            setState({ loading: false })
        })


    }
    const fetchData = debounce(_fetchData, 800);


    return (<>
        <FormField validate={props.validate}
            // type={props.multi ? 'select-multiple' : "select" }
            allowClear={props.allowClear}
            compact={props.compact}
            type={props.mode == 'multiple' ? 'select-multiple' : 'select'}
            name={props.name}
            label={props.label}
            data={list}
            placeholder="Search clients"
            addNull={props.addNull || false}
            onSelect={props.onSelect}
            // keyMap="value=>text"
            onChange={(_val, _calback) => {
                let raw = list.find(o => o._id == _val);
                if (props.onChange) props.onChange(_val, raw);
                _calback(_val);
            }}
            // defaultValue={props.defaultValue && props.defaultValue._id}
            inputProps={{
                // onSelect: props.onSelect || undefined, // console.log,
                onDeselect: props.onDeselect || undefined, // console.log,
                loading:state.loading,
                // mode: props.mode || undefined, // tags, multiple
                showSearch: true,
                defaultActiveFirstOption: false,
                showArrow: true,
                notFoundContent: state.loading ? <Spin size="small" /> : null,
                filterOption: false,
                onSearch: fetchData,
                // onChange:handelChange,
            }}
        />
    </>)
}
ClientSearchComp.propTypes = {
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValues: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    compact: PropTypes.bool,
    preload: PropTypes.bool,
    allowClear: PropTypes.bool,
}

export const ClientSearch = withApollo(ClientSearchComp)
export default ClientSearch;