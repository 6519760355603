import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Field } from 'react-final-form'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, Modifier, RichUtils, AtomicBlockUtils } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
// https://draftjs.org/docs/api-reference-data-conversion/
// https://jpuri.github.io/react-draft-wysiwyg/#/demo
// import debounce from 'lodash/debounce';
import { RenderError } from './RenderError';
import Loader from '../Loader';
import { UploadOutlined } from '@ant-design/icons';
import { getBase64, sleep } from 'Common/scripts/Functions';
import { Alert, Col, Image, Input, Modal, Row, Upload } from 'antd';
import Button, { IconButton } from '../Button';
import DevBlock from '../DevBlock';
import { __yellow } from 'Common/scripts/consoleHelper';
import Resizer from "react-image-file-resizer";


import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const defaultImgAttributes = { width: 200, height: 200, maxWidth: 1000, maxHeight: 1000, minWidth: 100, minHeight: 100 }

class CustomOption extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        editorState: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            fileList: [],
            imgSrc: null,
            imgAttributes: defaultImgAttributes,
        }
        this.onRemove = this.onRemove.bind(this);
        this.beforeUpload = this.beforeUpload.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    resizeImage = async (file) => {
        const { imgAttributes, fileList } = this.state;

        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file, // Is the file of the image which will resized.
                imgAttributes.width, // Is the maxWidth of the resized new image.
                imgAttributes.height, // Is the maxHeight of the resized new image.
                'JPEG', // Is the compressFormat of the resized new image.
                60, // Is the quality of the resized new image.
                0, // Is the degree of clockwise rotation to apply to uploaded image.
                (uri) => {
                    console.log(uri)
                    resolve(uri);
                }, // Is the callBack function of the resized new image URI.
                'file', // Is the output type of the resized new image.
                imgAttributes.minWidth, // Is the minWidth of the resized new image.
                imgAttributes.minHeight, // Is the minHeight of the resized new image.
            );
        });
    }

    async addImage(_base64Image) {
        const { editorState, onChange, setResizeEntityKey, setResizeModalVisible, onResize } = this.props;
        const { imgAttributes, fileList } = this.state;

        let resized = await this.resizeImage(fileList[0])
        let base64Image = await getBase64(resized)

        const contentState_1 = editorState.getCurrentContent();
        const contentStateWithEntity_1 = contentState_1.createEntity('IMAGE', 'IMMUTABLE', { src: base64Image });
        const entityKey = contentStateWithEntity_1.getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorState,
            entityKey,
            ' '
        );
        onChange(newEditorState);

        if (setResizeEntityKey) setResizeEntityKey(entityKey);
        if (setResizeModalVisible) setResizeModalVisible(true);
        if (onResize) onResize(entityKey)

        this.closeModal();
    }

    onRemove(file) {
        const { fileList } = this.state;

        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);

        this.setState({ fileList: newFileList })
    }

    async beforeUpload(file) {
        let fileList = [file]
            
        let imgSrc = await getBase64(file)
        this.setState({ fileList, imgSrc })
    }

    closeModal(){
        this.setState({ showModal: false, fileList: [], imgSrc: null, imgAttributes: defaultImgAttributes });
    }

    render() {
        const { showModal, fileList, imgSrc, imgAttributes } = this.state;

        const uploadProps = {
            onRemove: this.onRemove,
            beforeUpload: this.beforeUpload,
            fileList,
        };

        return (<>
            <IconButton onClick={() => this.setState({ showModal: !showModal })} icon={"image"} />

            <Modal visible={showModal} footer={false} title={"Image upload"} onCancel={this.closeModal} destroyOnClose>
                {!imgSrc && <><Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload></>}

                {imgSrc && <>
                    <div align="center"><Image src={imgSrc} width={"auto"} height={"auto"} style={{ maxHeight:"200px" }} alt="new image" /></div>
                    <Alert message="Images will be resized by proportion" type='info' />
                    <Row>
                        <Col flex="auto" />
                        <Col flex="100px"><label>Max Width</label><Input defaultValue={imgAttributes.width} type='number' name='width' onChange={(e) => this.setState({ imgAttributes: { ...imgAttributes, width:e.target.value } })} /></Col>
                        <Col flex="100px"><label>Max Height</label><Input defaultValue={imgAttributes.height} type='number' name='height' onChange={(e) => this.setState({ imgAttributes: { ...imgAttributes, height: e.target.value } })} /></Col>
                        <Col flex="auto" />
                    </Row>
                    <div align="center" style={{ padding:"20px" }}><Button onClick={() => this.addImage(imgSrc)}>Add this Image</Button></div>
                    
                </>}
            </Modal>
        </>)
    }
}

const defaultToolbar = {
    // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
    // image:{
    //     alt: { present: true, mandatory: true },
    // },
    // colorPicker: { component: ColorPic },

    // inline: {
    //     inDropdown: false,
    //     className: undefined,
    //     component: undefined,
    //     dropdownClassName: undefined,
    //     options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
    //     bold: { icon: bold, className: undefined },
    //     italic: { icon: italic, className: undefined },
    //     underline: { icon: underline, className: undefined },
    //     strikethrough: { icon: strikethrough, className: undefined },
    //     monospace: { icon: monospace, className: undefined },
    //     superscript: { icon: superscript, className: undefined },
    //     subscript: { icon: subscript, className: undefined },
    // },

    // blockType: {
    //     inDropdown: true,
    //     options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
    //     className: undefined,
    //     component: undefined,
    //     dropdownClassName: undefined,
    // },

    // fontSize: {
    //     icon: fontSize,
    //     options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    //     className: undefined,
    //     component: undefined,
    //     dropdownClassName: undefined,
    // },

    // fontFamily: {
    //     options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
    //     className: undefined,
    //     component: undefined,
    //     dropdownClassName: undefined,
    // },

    // list: {
    //     inDropdown: false,
    //     className: undefined,
    //     component: undefined,
    //     dropdownClassName: undefined,
    //     options: ['unordered', 'ordered', 'indent', 'outdent'],
    //     unordered: { icon: unordered, className: undefined },
    //     ordered: { icon: ordered, className: undefined },
    //     indent: { icon: indent, className: undefined },
    //     outdent: { icon: outdent, className: undefined },
    // },

    // textAlign: {
    //     inDropdown: false,
    //     className: undefined,
    //     component: undefined,
    //     dropdownClassName: undefined,
    //     options: ['left', 'center', 'right', 'justify'],
    //     left: { icon: left, className: undefined },
    //     center: { icon: center, className: undefined },
    //     right: { icon: right, className: undefined },
    //     justify: { icon: justify, className: undefined },
    // },

    // colorPicker: {
    //     icon: color,
    //     className: undefined,
    //     component: undefined,
    //     popupClassName: undefined,
    //     colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
    //         'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
    //         'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
    //         'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
    //         'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
    //         'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
    // },

    // link: {
    //     inDropdown: false,
    //     className: undefined,
    //     component: undefined,
    //     popupClassName: undefined,
    //     dropdownClassName: undefined,
    //     showOpenOptionOnHover: true,
    //     defaultTargetOption: '_self',
    //     options: ['link', 'unlink'],
    //     link: { icon: link, className: undefined },
    //     unlink: { icon: unlink, className: undefined },
    //     linkCallback: undefined
    // },

    // emoji: {
    //     icon: emoji,
    //     className: undefined,
    //     component: undefined,
    //     popupClassName: undefined,
    //     emojis: [
    //         '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
    //         '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
    //         '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
    //         '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
    //         '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
    //         '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
    //         '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
    //         '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
    //         '✅', '❎', '💯',
    //     ],
    // },

    // embedded: {
    //     icon: embedded,
    //     className: undefined,
    //     component: undefined,
    //     popupClassName: undefined,
    //     embedCallback: undefined,
    //     defaultSize: {
    //         height: 'auto',
    //         width: 'auto',
    //     },
    // },

    // image: {
    //     icon: image,
    //     className: undefined,
    //     component: undefined,
    //     popupClassName: undefined,
    //     urlEnabled: true,
    //     uploadEnabled: true,
    //     alignmentEnabled: true,
    //     uploadCallback: undefined,
    //     previewImage: false,
    //     inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    //     alt: { present: false, mandatory: false },
    //     defaultSize: {
    //         height: 'auto',
    //         width: 'auto',
    //     },
    // },

    // remove: { icon: eraser, className: undefined, component: undefined },

    // history: {
    //     inDropdown: false,
    //     className: undefined,
    //     component: undefined,
    //     dropdownClassName: undefined,
    //     options: ['undo', 'redo'],
    //     undo: { icon: undo, className: undefined },
    //     redo: { icon: redo, className: undefined },
    // },
}

export const HtmlField = props => {
    const [initilized, setInitilized] = useState(false);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        // sleep(1000).then(r => setMounted(true))
        setMounted(true);
        return () => {
            setMounted(false);
        };
    }, [props])

    const getInitialState = initialHTMLMarkup => {
        if (!initialHTMLMarkup || initialHTMLMarkup.length < 7) return EditorState.createEmpty();

        // const sampleMarkup = '<b>Bold text</b>, <i>Italic text</i><br/ ><br />' + '<a href="http://www.facebook.com">Example link</a>';
        const __blocksFromHtml = htmlToDraft(initialHTMLMarkup);
        const { contentBlocks, entityMap } = __blocksFromHtml;
        const __contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const __editorState = EditorState.createWithContent(__contentState);
        return __editorState;
    }

    const [editorState, setEditorState] = useState(getInitialState(props.data)); //EditorState.createEmpty()

    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            setEditorState(newState);
            return 'handled';
        }

        return 'not-handled';
    };


    return (
        <Field {...props}>
            {({ input, meta }) => {

                if (!initilized && mounted) {
                    setEditorState(getInitialState(input.value))
                    setInitilized(true)
                }

                const onChange = (new_editorState) => {
                    setEditorState(new_editorState);

                    let raw_contents = convertToRaw(new_editorState.getCurrentContent());
                    let htmlContents = draftToHtml(raw_contents);
                    input.onChange(htmlContents)
                }

                const handlePastedFiles = async (files) => {
                    const base64Image = await getBase64(files[0]);

                    const contentState = editorState.getCurrentContent();
                    const contentStateWithEntity = contentState.createEntity(
                        'IMAGE',
                        'IMMUTABLE',
                        { src: base64Image },)
                    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

                    onChange(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '))
                }

                if (!initilized) return <Loader loading={true}>Initilizing...</Loader>

                return (
                    <div className={`form-field ${meta.error && meta.touched ? 'has-errors' : ''} ${!props.compact && "field-margins"} html`}>
                        {props.label && <label>{props.label}</label>}

                        <Editor
                            handleKeyCommand={handleKeyCommand}
                            toolbar={defaultToolbar}
                            toolbarCustomButtons={props.showGallery && [<CustomOption
                                editorState={editorState}
                                onChange={onChange}
                            />]}
                            handlePastedFiles={handlePastedFiles}  

                            wrapperStyle={props.wrapperStyle || {}}
                            editorStyle={props.editorStyle || { minHeight:"500px" }}
                            // editorStyle={{ height:"600px"}}
                            toolbarStyle={props.toolbarStyle || {}}

                            wrapperClassName="field-html"
                            editorClassName="field-html-editor"
                            toolbarClassName="field-html-toolbar"

                            editorState={editorState}
                            onEditorStateChange={onChange}
                        />

                        <RenderError meta={meta} showIcon />
                        {/* {meta.error && meta.touched && <div className="field-error">{meta.error}</div>} */}

                    </div>
                )
            }}
        </Field>
    )

}
HtmlField.propTypes = {
    showGallery: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.any,
    wrapperStyle: PropTypes.object,
    editorStyle: PropTypes.object,
    toolbarStyle: PropTypes.object,
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ])
}

export default HtmlField;
