import _ from 'lodash';
import moment from 'moment';
import { dateToMongo, mongoToDate } from 'Common/scripts/Functions';
import { __blue, __error, __info } from 'Common/scripts/consoleHelper';

export const breakdownValues = (f) => {
    if (!f.item) return {};

    let { field, type, title } = f.item;
    let option = f.filter.option;
    let query = eval(`f.filter.${field}`);
    let value = query[option];

    return { field, type, title, option, query, value }
}

function isEmpty(val) {
    if ((!val && val !== false) || (_.isArray(val) && val.length == 0) || val == 'null' || val == 'undefined') return true;
    return false;
}

function makeDate(_date, action) {
    var today = moment();

    if (!action) {
        let d1 = mongoToDate(_date);
        return new Date(dateToMongo(d1))
    }

    if (action == 'today') return new Date(dateToMongo(today))

    if (action == 'start-of-day') {
        let d1 = mongoToDate(_date).startOf('day')
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-day') {
        let d1 = mongoToDate(_date).endOf('day')
        return new Date(dateToMongo(d1));
    }

    if (action == 'start-of-today') {
        let d1 = today.startOf('day')
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-today') {
        let d1 = today.endOf('day')
        return new Date(dateToMongo(d1));
    }

    if (action == 'start-of-yesterday') {
        let d1 = today.subtract(1, 'days').startOf('day')
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-yesterday') {
        let d1 = today.subtract(1, 'days').endOf('day')
        return new Date(dateToMongo(d1));
    }

    if (action == 'start-of-tomorrow') {
        let d1 = today.add(1, 'day').startOf('day');
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-tomorrow') {
        let d1 = today.add(1, 'day').endOf('day');
        return new Date(dateToMongo(d1));
    }

    // Weeks
    if (action == 'start-of-current_week') {
        let d1 = today.startOf('week');
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-current_week') {
        let d1 = today.endOf('week');
        return new Date(dateToMongo(d1));
    }

    if (action == 'start-of-previous_week') {
        let d1 = today.subtract(1, 'weeks').startOf('week');
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-previous_week') {
        let d1 = today.subtract(1, 'weeks').endOf('week');
        return new Date(dateToMongo(d1));
    }

    if (action == 'start-of-next_week') {
        let d1 = today.add(1, 'weeks').startOf('week');
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-next_week') {
        let d1 = today.add(1, 'weeks').endOf('week');
        return new Date(dateToMongo(d1));
    }

    // Months
    if (action == 'start-of-current_month') {
        let d1 = today.startOf('month');
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-current_month') {
        let d1 = today.endOf('month');
        return new Date(dateToMongo(d1));
    }

    if (action == 'start-of-next_month') {
        let d1 = today.add(1, 'months').startOf('month');
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-next_month') {
        let d1 = today.add(1, 'months').endOf('month');
        return new Date(dateToMongo(d1));
    }

    if (action == 'start-of-previous_month') {
        let d1 = today.subtract(1, 'months').startOf('month');
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-previous_month') {
        let d1 = today.subtract(1, 'months').endOf('month');
        return new Date(dateToMongo(d1));
    }

    // Quarter
    if (action == 'start-of-current_quarter') {
        let currentQuarterNum = today.quarter();
        let d1 = today.quarter(currentQuarterNum).startOf('quarter');
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-current_quarter') {
        let currentQuarterNum = today.quarter();
        let d1 = today.quarter(currentQuarterNum).endOf('quarter');
        return new Date(dateToMongo(d1));
    }

    if (action == 'start-of-previous_quarter') {
        let currentQuarterNum = today.quarter();
        let d1 = today.quarter(currentQuarterNum).subtract(1, 'quarters').startOf('quarter');
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-previous_quarter') {
        let currentQuarterNum = today.quarter();
        let d1 = today.quarter(currentQuarterNum).subtract(1, 'quarters').endOf('quarter');
        return new Date(dateToMongo(d1));
    }

    if (action == 'start-of-next_quarter') {
        let currentQuarterNum = today.quarter();
        let d1 = today.quarter(currentQuarterNum).add(1, 'quarters').startOf('quarter');
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-next_quarter') {
        let currentQuarterNum = today.quarter();
        let d1 = today.quarter(currentQuarterNum).add(1, 'quarters').endOf('quarter');
        return new Date(dateToMongo(d1));
    }

    // Years
    if (action == 'start-of-current_year') {
        let d1 = today.startOf('year');
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-current_year') {
        let d1 = today.endOf('year');
        return new Date(dateToMongo(d1));
    }

    if (action == 'start-of-previous_year') {
        let d1 = today.subtract(1, 'years').startOf('year');
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-previous_year') {
        let d1 = today.subtract(1, 'years').endOf('year');
        return new Date(dateToMongo(d1));
    }

    if (action == 'start-of-next_year') {
        let d1 = today.add(1, 'years').startOf('year');
        return new Date(dateToMongo(d1));
    }
    if (action == 'end-of-next_year') {
        let d1 = today.add(1, 'years').endOf('year');
        return new Date(dateToMongo(d1));
    }

    // days
    if (action == 'start-of-previous_days') {
        let d1 = today.subtract(_date, 'days').startOf('day');
        return new Date(dateToMongo(d1));
    }
    if (action == 'emd-of-previous_days') {
        let d1 = today.subtract(_date, 'days').endOf('end');
        return new Date(dateToMongo(d1));
    }

}

const parseDateField = (f) => {
    // let { field, type, title } = f.item;
    // let option = f.filter.option;
    // let query = eval(`f.filter.${field}`);
    // let value = query[option];

    let { field, type, title, option, query, value } = breakdownValues(f);


    if (option == 'eq') {
        return {
            [field]: {
                $gt: makeDate(value, 'start-of-day'),
                $lt: makeDate(value, 'end-of-day'),
            }
        }
    }
    if (option == 'lt') {
        return {
            [field]: {
                $lt: makeDate(value, 'start-of-day'),
            }
        }
    }
    if (option == 'gt') {
        return {
            [field]: {
                $gt: makeDate(value, 'end-of-day'),
            }
        }
    }
    if (option == 'between') {
        return {
            [field]: {
                $gt: makeDate(value[0], 'start-of-day'),
                $lt: makeDate(value[1], 'end-of-day'),
            }
        }
    }
    if (option == 'farmula') {
        if (value == 'today') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-today'),
                $lte: makeDate(undefined, 'end-of-today'),
            }
        }
        if (value == 'yesterday') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-yesterday'),
                $lte: makeDate(undefined, 'end-of-yesterday'),
            }
        }
        if (value == 'tomorrow') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-tomorrow'),
                $lte: makeDate(undefined, 'end-of-tomorrow'),
            }
        }

        if (value == 'current_week') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-current_week'),
                $lte: makeDate(undefined, 'end-of-current_week'),
            }
        }
        if (value == 'current_week_so_far') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-current_week'),
                $lte: makeDate(undefined, 'today'),
            }
        }
        if (value == 'previous_week') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-previous_week'),
                $lte: makeDate(undefined, 'end-of-previous_week'),
            }
        }
        if (value == 'next_week') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-next_week'),
                $lte: makeDate(undefined, 'end-of-next_week'),
            }
        }

        if (value == 'current_month') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-current_month'),
                $lte: makeDate(undefined, 'end-of-current_month'),
            }
        }
        if (value == 'current_month_so_far') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-current_month'),
                $lte: makeDate(undefined, 'today'),
            }
        }
        if (value == 'previous_month') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-previous_month'),
                $lte: makeDate(undefined, 'end-of-previous_month'),
            }
        }
        if (value == 'next_month') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-next_month'),
                $lte: makeDate(undefined, 'end-of-next_month'),
            }
        }

        if (value == 'current_quarter') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-current_quarter'),
                $lte: makeDate(undefined, 'end-of-current_quarter'),
            }
        }
        if (value == 'current_quarter_so_far') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-current_quarter'),
                $lte: makeDate(undefined, 'today'),
            }
        }
        if (value == 'current_fiscal_quarter') {
            console.log(__error("current_fiscal_quarter no configured"))
            return {
                // [field]: {
                //     $gte: makeDate(undefined, 'start-of-current_quarter'),
                //     $lte: makeDate(undefined, 'end-of-current_quarter'),
                // }
            }
        }
        if (value == 'current_fiscal_quarter_so_far') {
            console.log(__error("current_fiscal_quarter_so_far no configured"))
            // return {
            //     [field]: {
            //         $gte: makeDate(undefined, 'start-of-current_quarter'),
            //         $lte: makeDate(undefined, 'today'),
            //     }
            // }
        }
        if (value == 'previous_quarter') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-previous_quarter'),
                $lte: makeDate(undefined, 'end-of-previous_quarter'),
            }
        }
        if (value == 'previous_fiscal_quarter') {
            console.log(__error("previous_fiscal_quarter no configured"))
            // return {
            //     [field]: {
            //         $gte: makeDate(undefined, 'start-of-previous_fiscal_quarter'),
            //         $lte: makeDate(undefined, 'end-of-previous_fiscal_quarter'),
            //     }
            // }
        }
        if (value == 'next_quarter') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-next_quarter'),
                $lte: makeDate(undefined, 'end-of-next_quarter'),
            }
        }
        if (value == 'next_fiscal_quarter') {
            console.log(__error("next_fiscal_quarter no configured"))
            // return {
            //     [field]: {
            //         $gte: makeDate(undefined, 'start-of-next_quarter'),
            //         $lte: makeDate(undefined, 'end-of-next_quarter'),
            //     }
            // }
        }

        if (value == 'current_year') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-current_year'),
                $lte: makeDate(undefined, 'end-of-current_year'),
            }
        }
        if (value == 'current_fiscal_year') {
            console.log(__error("current_fiscal_year no configured"))
            // return {
            //     [field]: {
            //         $gte: makeDate(undefined, 'start-of-current_fiscal_year'),
            //         $lte: makeDate(undefined, 'end-of-current_fiscal_year'),
            //     }
            // }
        }
        if (value == 'current_year_so_far') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-current_year'),
                $lte: makeDate(undefined, 'today'),
            }
        }
        if (value == 'current_fiscal_year_so_far') {
            console.log(__error("current_fiscal_year_so_far no configured"))
            return {}
        }
        if (value == 'previous_year') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-previous_year'),
                $lte: makeDate(undefined, 'end-of-previous_year'),
            }
        }
        if (value == 'previous_fiscal_year') {
            console.log(__error("previous_fiscal_year no configured"))
            return {}
        }
        if (value == 'next_year') return {
            [field]: {
                $gte: makeDate(undefined, 'start-of-next_year'),
                $lte: makeDate(undefined, 'end-of-next_year'),
            }
        }
        if (value == 'next_fiscal_year') {
            console.log(__error("next_fiscal_year no configured"))
            return {}
        }

        if (value == 'previous_7_days') return {
            [field]: {
                $gte: makeDate(7, 'start-of-previous_days'),
                $lte: makeDate(7, 'start-of-previous_days'),
            }
        }
        if (value == 'previous_14_days') return {
            [field]: {
                $gte: makeDate(14, 'start-of-previous_days'),
                $lte: makeDate(14, 'start-of-previous_days'),
            }
        }
        if (value == 'previous_30_days') return {
            [field]: {
                $gte: makeDate(30, 'start-of-previous_days'),
                $lte: makeDate(30, 'start-of-previous_days'),
            }
        }
        if (value == 'previous_90_days') return {
            [field]: {
                $gte: makeDate(90, 'start-of-previous_days'),
                $lte: makeDate(90, 'start-of-previous_days'),
            }
        }
        if (value == 'previous_180_days') return {
            [field]: {
                $gte: makeDate(180, 'start-of-previous_days'),
                $lte: makeDate(180, 'start-of-previous_days'),
            }
        }
        if (value == 'previous_365_days') return {
            [field]: {
                $gte: makeDate(356, 'start-of-previous_days'),
                $lte: makeDate(356, 'start-of-previous_days'),
            }
        }
    }

    // return { [field]: { option, query, item_type, field_option, value } }
    return { [field]: { UNDEFIEND_FITLER: f } }

}



export const filterParser = filterObj => {
    if (!filterObj) {
        console.log(__info("Empty Filter"), filterObj)
        return {}
    }
    var filter = _.cloneDeep(filterObj); // { ...values };

    if (!filter || !_.isArray(filter)) return {}

    let parsedFilter = {}
    for (let a = 0; a < filter.length; a++) {
        let f = filter[a];
        if (!f.group_key){
            Object.assign(parsedFilter, f)
            // parsedFilter.push(f);
            continue;
        }


        /*
        {
            item: {
                title: String,
                "field": "orders.grand_total",
                "type": "number"
            },
            group_key: "schedule_info",
            filter: {
                option: "eq",
                orders: {
                    grand_total: {
                        eq: 12
                    }
                }
            }
        }
        */
        // let { field, type, title } = f.item;
        // let option = f.filter.option;
        // let query = eval(`f.filter.${field}`);
        // let value = query[option];

        let { field, type, title, option, query, value } = breakdownValues(f);


        if (type == 'date') {
            Object.assign(parsedFilter, parseDateField(f));
            continue;
        }

        if (type == 'schedule.address.zone') {
            Object.assign(parsedFilter, { [field]: { PENDING: f } })
            continue;
        }

        if (type == 'schedule.order.product') {
            Object.assign(parsedFilter, { [field]: { PENDING: f } })
            continue;
        }

        if (type == 'schedule.notify.product') {
            Object.assign(parsedFilter, { [field]: { PENDING: f } })
            continue;
        }

        if (type == 'schedule.address') {
            Object.assign(parsedFilter, { [field]: { PENDING: f } })
            continue;
        }


        switch (option) {
            case 'in':
                Object.assign(parsedFilter, {
                    [field]: { ["$in"]: value }
                })
                break;

            case 'nin':
                Object.assign(parsedFilter, {
                    [field]: { ["$nin"]: value }
                })
                break;

            case 'eq':
                Object.assign(parsedFilter, {
                    [field]: { ["$eq"]: value }
                })
                break;

            case 'lt':
                Object.assign(parsedFilter, {
                    [field]: { ["$lt"]: value }
                })
                break;

            case 'lte':
                Object.assign(parsedFilter, {
                    [field]: { ["$lte"]: value }
                })
                break;

            case 'gt':
                Object.assign(parsedFilter, {
                    [field]: { ["$gt"]: value }
                })
                break;

            case 'gte':
                Object.assign(parsedFilter, {
                    [field]: { ["$gte"]: value }
                })
                break;

            case 'ne':
                Object.assign(parsedFilter, {
                    [field]: { ["$ne"]: value }
                })
                break;

            case 'between':
                Object.assign(parsedFilter, {
                    [field]: { $gt: value[0], $lt: value[1] }
                })
                break;

            case 'contains':
                Object.assign(parsedFilter, {
                    [field]: { $regex: value.toString().replaceAll(",", "|"), $options: "i" }
                })
                break;

            case 'not_contains':
                Object.assign(parsedFilter, {
                    [field]: { $not: { $regex: value.toString().replaceAll(",", "|"), $options: "i" } }
                })
                break;

            case 'exists':
                Object.assign(parsedFilter, { [field]: { $exists: true } });
                break;

            case 'not_exists':
                Object.assign(parsedFilter, { [field]: { $exists: false } });
                break;

            default:
                console.log(__error(`Invalid filter: `), f)
                Object.assign(parsedFilter, { [field]: { UNDEFIEND_FITLER: f } })
                break;
        }


    }

    // console.log("\n\n\n\n\n parsedFilter: ", JSON.stringify(parsedFilter, 0, 10), "\n\n\n\n\n")
    return parsedFilter;

}
