import React, { useState } from 'react'
// import { connect } from "react-redux";
import TicketFormModal from '../../components/tickets/ticket_form';
import TicketList from '../../components/tickets/TicketList'

function TicketHistory() { // match.params.id
  const [{ editNode, showThreadForm }, set_showThreadForm] = useState({})

  const onDeleteClick = async () => {}
  const onEditClick = async (val) => {
    set_showThreadForm({ editNode:val, showThreadForm:true })
  }

  return (<>
    <TicketList title="Threads History" onDeleteClick={onDeleteClick} onEditClick={onEditClick} permanentFilter={{ status:{ $ne:"open" } }} />
    <TicketFormModal visible={showThreadForm} initialValues={editNode} onCancel={() => set_showThreadForm({})} />
  </>)

}
// const mapStateToProps = ({ ep_admin: { user, pageSettings } }) => {
//   return ({ 
//     user,
//     threads_history_filter: (pageSettings && pageSettings.threads_history_filter) || {},
//   });
// }
export default TicketHistory;  //connect(mapStateToProps, null)(TicketHistory)

