import React, { Component, useEffect, useState } from 'react'
import { filterParser } from 'Common/FilteredViews/function'
import { __error } from 'Common/scripts/consoleHelper'
import { Button, IconButton, Icon, Table, CustomIcon } from 'Common/components'
import { Col, Row, Table as _Table, message, Tooltip, Popconfirm, Popover, Space, Modal, Drawer, Alert } from 'antd'
import { connect } from "react-redux";
import { loader } from 'graphql.macro';
import { mongoToDate, getTimeDiff, min2Hrs } from 'Common/scripts/Functions'
// import moment from 'moment'
import { withApollo } from '@apollo/client/react/hoc'
import { defaultDateFormat } from 'configs';
import { verifyRole } from 'Common/scripts/Security';
import ScheduleForm from './ScheduleForm'
import PaygridPreview from './PaygridPreview';
import InvoicePreview from './InvoicePreview';
import DailyBreakdownView from './DailyBreakdownView'
import BreakdownInvoicePreview from './BreakdownInvoicePreview'
import ApprovalButton from './ApprovalButton'
import FlagButton from './FlagButton'
import { useSubscription, gql } from "@apollo/client";
import moment from 'moment'


const stat_holiday_icon = <CustomIcon title="Stat holiday">SH</CustomIcon>
const do_not_charge_client_icon = <CustomIcon title="Do not charge client"><Icon icon="user-slash" /></CustomIcon>

const DELETE = loader('src/Graphql/HoursSchedules/deleteHoursSchedule.graphql')
const SEARCH_DATA = loader('src/Graphql/HoursSchedules/hoursSchedules_by_view.graphql');
const QUERY_SUBSCRIPTION = loader('src/Graphql/HoursSchedules/subscription.graphql');


function Subscriber({ QUERY, callback, filter }) {
    let variables = {};
    if (filter) Object.assign(variables, { ...filter });

    const { data, loading, error } = useSubscription(QUERY_SUBSCRIPTION, {
        // subscription: QUERY,
        variables: { filter: JSON.stringify(variables) },
        // fetchPolicy: "network-only" // cache-first, cache-only, cache-and-network, network-only, no-cache, standby
        onSubscriptionData: ({ client, subscriptionData }) => {
            const { data, error, loading, variables } = subscriptionData
            if (callback) callback(subscriptionData)
        },
        shouldResubscribe: false, // Determines if your subscription should be unsubscribed and subscribed again
    }
    );

    if (loading) return null;
    return null;
}


const RenderResultsComp = ({ filter, client, ep_admin, allowExport, tableProps }) => {
    const [loading, setLoading] = useState(false);
    const [resultData, setResultData] = useState(false);
    const [error, setError] = useState(false);
    const [activityLogs, setActivityLogs] = useState(false);
    const [showScheduleForm, setShowScheduleForm] = useState(false);
    const [showPaygrid, setShowPaygrid] = useState(false);
    const [showInvoice, setShowInvoice] = useState(false);
    const [showBreakdownInvoice, setShowBreakdownInvoice] = useState(false);
    const [showDailyBreakdown, setShowDailyBreakdown] = useState(false);

    const [thisFilter, set_thisFilter] = useState(null);
    

    useEffect(() => {
        if (!filter || filter.length<1) return;

        if (JSON.stringify(filter) != JSON.stringify(thisFilter)){
            set_thisFilter(filter);
            fetchSearchData(filter);
        }
    }, [filter])

    const fetchSearchData = async (_filter) => {
        // console.log("fetchSearchData()", _filter[0].filter.check_in.eq.format())
        const variables = {
            filter: JSON.stringify(_filter || {}),
            others: JSON.stringify({ sort: { scheduled_shift_start: 1 } })
        }

        setLoading(true);
        setResultData(null);

        const results = await client.query({ query: SEARCH_DATA, variables }).then(r => {
            return r.data.hoursSchedules_by_view;
        }).catch(err => {
            console.log(__error("ERROR: "), err);
            return { error: { message:"Unable to fetch schedules!" }}
        })

        setLoading(false)
        if (!results) return setResultData([])
        if (results.error) return setError(results.error.message)

        setResultData(results)

    }

    const calculate_results = () => {
        if (!resultData) return null;

        let unverified = resultData.filter(o => o.approval_status == 'new')
        let aproved = resultData.filter(o => o.approval_status == 'aproved')
        let do_not_charge_client = resultData.filter(o => o.do_not_charge_client == 'yes');
        let abnorma_flags = resultData.filter(o => o.flag != 'normal');

        return (<div style={{ fontWeight: "bold", fontSize: '14px' }}>
            {(unverified && unverified.length>0) &&
                <div style={{ color: 'red' }}>Pending verifications = {unverified.length}</div>
            }
            {(do_not_charge_client && do_not_charge_client.length > 0) &&
                <div>Do not charge client = {do_not_charge_client.length}</div>
            }
            {(aproved && aproved.length > 0) &&
                <div style={{color:"green"}}>Aproved = {aproved.length}</div>
            }
            {(abnorma_flags && abnorma_flags.length > 0) &&
                <div style={{ color: "red" }}>Abnormal Flags = {abnorma_flags.length}</div>
            }
        </div>)
    }

    const calculateHoursTotal = (_data, rawData = false) => {
        let total_workedHrs = 0;
        let total_assignedHrs = 0;
        let total_decimalHrs = 0;

        _data.forEach((rec) => {
            total_assignedHrs += getTimeDiff(rec.scheduled_shift_start, rec.scheduled_shift_end, 'minutes');
            total_workedHrs += getTimeDiff(rec.check_in, rec.check_out, 'minutes')
            total_decimalHrs += getTimeDiff(rec.check_in, rec.check_out, 'minutes')
        });

        if (!rawData) {
            return {
                total_assignedHrs: min2Hrs(total_assignedHrs),
                total_workedHrs: min2Hrs(total_workedHrs),
                total_decimalHrs: min2Hrs(total_decimalHrs)
            }
        }

        return {
            total_assignedHrs,
            total_workedHrs,
            total_decimalHrs
        }

    } 

    const edit_rec = async(row_data) => {
        if (!verifyRole('hours-schedule.update')) return;
        setShowScheduleForm(row_data);
    }

    const delete_rec = async (_id) => {
        if (!verifyRole('hours-schedule.delete')) return;
        
        setLoading(true)
        const results = await client.mutate({ mutation: DELETE, variables: { id: _id } }).then(r => {
            return r.data.deleteHoursSchedule;
        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 5));
            return { error: { message:"Unable to delete at the moment!"}}
        })
        setLoading(false);

        if (results.error){
            return setError(results.error.message)
        }
        message.success("Successfully Deleted")
    }

    const onSubscriptionReceived = ({ data, error, loading, variables }) => {
        const { mutation, node } = data.hoursSchedulesUpdated;

        let _data = resultData ? resultData.slice() : [];

        if (mutation == "DELETED") _data = _data.filter(o => o._id != node._id);
        else if (mutation == "UPDATED") _data = _data.map(o => (o._id == node._id) ? { ...node } : o)
        else if (mutation == "CREATED") _data.push(node);
        else {
            console.log(__error("Invalid Subscription received: ", data));
            return;
        }

        setResultData(_data)
    }

    if (error) return <Alert message={error} type="error" showIcon />

    const columns = [
        { title: 'Site Name', key: "1", dataIndex: 'site.name', render: (__, rec) => {
            return (<>
                {verifyRole('hours-schedule.update') ? <div onClick={() => edit_rec(rec)} className="a">{rec?.site_name}</div> : rec?.site_name}
                {rec?.lead?._id && <div>Lead: {rec.lead.title}</div>}
                {rec?.service?.service_type && <div>Service: {rec.service.service_type.title}</div>}
                {rec.do_not_charge_client == 'yes' && <>{do_not_charge_client_icon}</>}
                {rec.stat == 'yes' && <>{stat_holiday_icon}</>}
            </>)
        } },
        { title: 'Guard Name', key: "2", dataIndex: 'status', render: (__, rec) => {
            let gName = "";
            if (rec?.guard?.f_name) gName += rec?.guard?.f_name;
            if (rec?.guard?.m_name) gName += gName.length > 0 ? ` ${rec?.guard?.m_name}` : rec?.guard?.m_name;
            if (rec?.guard?.l_name) gName += gName.length > 0 ? ` ${rec?.guard?.l_name}` : rec?.guard?.l_name;

            if (gName.length < 2) gName = <><b>Missing Guard</b><br />{rec.guard_name}</>
            return gName;
        }},
        { title: 'Shift Schedule (HH:mm)', key: "3", align: 'center', render: (__, rec) => {
            let d1 = mongoToDate(rec.scheduled_shift_start);
            let d2 = mongoToDate(rec.scheduled_shift_end);
            let diff = getTimeDiff(rec.scheduled_shift_start, rec.scheduled_shift_end, "split");

            return <>
                <span style={{ fontSize: '12px' }}>{d1.format(`${defaultDateFormat} HH:mm`)} <br />{d2.format(`${defaultDateFormat} HH:mm`)}</span>
                <br />
                {`${diff.hrs}:${diff.min}`}
            </>
        }},

        { title: 'Performed In/Out (HH:mm)', key: "4", align: 'center', render: (__, rec) => {
            let d1 = mongoToDate(rec.check_in);
            let d2 = mongoToDate(rec.check_out);
            let diff = getTimeDiff(rec.check_in, rec.check_out, "split");

            let schedule_diff = getTimeDiff(rec.scheduled_shift_start, rec.scheduled_shift_end, "split");
            let isDiff = `${diff.hrs}:${diff.min}` != `${schedule_diff.hrs}:${schedule_diff.min}`;
            
            return (<>
                <div style={{ fontSize: '12px' }}>
                    <div>{d1.format(`${defaultDateFormat} HH:mm`)}</div>
                    <div>{d2.format(`${defaultDateFormat} HH:mm`)}</div>
                </div>
                <div style={{ fontWeight: isDiff ? "bold" : "normal" }}>{`${diff.hrs}:${diff.min}`}</div>
            </>)

        }},

        { title: 'Decimal hours', key: "8", align: 'center', render: (__, rec) => {
                let difference = getTimeDiff(rec.check_in, rec?.check_out, 'split');
                return Number(difference.decimalHrs).toFixed(2);
        }},

        { title: 'Verified', key: "_approved", align: 'center', render: (__, rec) => {
                return <ApprovalButton data={rec} ep_admin={ep_admin} client={client} />
        }},

        // { title: 'Product/Services', dataIndex: 'prod_and_services', render: (__, rec) => {
        //         if (!rec.prod_and_services || rec.prod_and_services == 'NULL') return null;
        //         return <>
        //             {rec?.prod_and_services?.title} {rec?.prod_and_services?.values && rec?.prod_and_services?.values != '1' ? ` @ ${rec?.prod_and_services?.values}` : ""}
        //             <div>
        //                 {rec.stat == 'yes' ? stat_holiday_icon : null}
        //                 {rec.do_not_charge_client == 'yes' ? do_not_charge_client_icon : null}
        //             </div>
        //         </>
        // }},

        { title: 'Activity logs', align: 'center', render: (___, rec) => {
            return (<>
                {/* <span>{rec.do_not_charge_client == 'yes' ? do_not_charge_client_icon : null}</span> */}
                {/* <span>{rec.stat == 'yes' ? stat_holiday_icon : null}</span> */}

                {/* {verifyRole('hours-schedule.view-activity') && <>
                    {rec?.recorded_by_user?.name && <div>recorded_by: {rec?.recorded_by_user?.name}</div>}
                    {rec?.updated_by_user?.name && <div>updated_by: {rec?.updated_by_user?.name}</div>}
                    {rec?.approved_by_user?.name && <div>approved_by: {rec?.approved_by_user?.name}</div>}
                </>} */}

                {(rec.activity_logs && rec.activity_logs.length > 0) && <>
                    <div>
                        <div style={{ fontSize: '11px' }}>{rec.activity_logs[rec.activity_logs.length - 1].note} by {rec.activity_logs[rec.activity_logs.length - 1]?.user?.name}</div>
                        {rec.activity_logs.length > 1 && <><Button type="link" onClick={() => setActivityLogs(rec.activity_logs)}> more...</Button></>}
                    </div>
                </>}

            </>)
        }},

        { title: '', align: 'right', key: "9", render: (___, rec) => {
            return <div>
                {/* {verifyRole('hours-schedule.update') &&
                    <IconButton onClick={() => edit_rec(rec)} icon={{ icon: "edit", color: 'green' }} />
                } */}
                {verifyRole('hours-schedule.delete') &&
                    <Popconfirm onConfirm={() => delete_rec(rec?._id)} title="Are you sure？">
                        <IconButton onClick={()=>void(0)} icon={{ icon: "trash-alt", color: 'red' }} />
                    </Popconfirm>
                }
                <FlagButton data={rec} user_id={ep_admin.user._id} client={client} />

            </div>
        }},

    ];

    return (<>
        <Subscriber QUERY={QUERY_SUBSCRIPTION} callback={onSubscriptionReceived} variables={{}} filter={filterParser(filter || [])} />

        <Table loading={loading}
            title={() => <Row>
                <Col flex="auto">{calculate_results()}</Col>
                {allowExport &&
                    <Col>
                        <Popover title="Export results" placement="bottomRight"
                            content={<Space direction='vertical'>
                                {(resultData && verifyRole('hours-schedule-export.paygrid')) && <>
                                    <Button type="dashed" block onClick={() => setShowPaygrid(true)} disabled={!resultData}>Export PayGrid Data</Button></>}
                                {(resultData && verifyRole('hours-schedule-export.invoice')) && <>
                                    <Button type="dashed" block onClick={() => setShowInvoice(true)} disabled={!resultData}>Export Invoice</Button>
                                    <Button type="dashed" block onClick={() => setShowBreakdownInvoice(true)} disabled={!resultData}>Export Breakdown Invoice</Button>
                                </>}
                                <Button onClick={() => setShowDailyBreakdown(true)} type="dashed" block disabled={!resultData}>Show Daily Breakdown</Button>
                            </Space>}
                        >
                            <Button type="primary" disabled={!resultData || resultData.length<1}>Export</Button>
                        </Popover>
                    </Col>
                }
            </Row>}
            columns={columns}
            dataSource={resultData ? resultData : null}
            pagination={false}
            expandable={{
                expandedRowRender: record => <div style={{ margin: 0 }}>
                    <div>ID: {record._id}</div>
                    <p><b>Comments: </b>{record.comments}</p>
                </div>,
                // rowExpandable: record => record.comments && record.comments.length>0,
            }}
            rowClassName={(record => {
                let flagClass = !record.flag ? 'flag_normal' : `flag_${record.flag}`;
                return `${flagClass} ${record.approval_status == 'new' ? 'hilighted-table-row' : ""}`;
            })}

            rowKey={obj => obj._id}
            summary={pageData => {
                let totals = calculateHoursTotal(pageData);

                return (<>
                    <_Table.Summary.Row style={{ fontWeight: "bold", backgroundColor: "#EEE" }}>
                        <_Table.Summary.Cell colSpan={3}>Total</_Table.Summary.Cell>
                        <_Table.Summary.Cell colSpan={1} align="center">{`${totals.total_assignedHrs.hrs}:${totals.total_assignedHrs.min}`}</_Table.Summary.Cell>
                        <_Table.Summary.Cell colSpan={1} align="center">{`${totals.total_workedHrs.hrs}:${totals.total_workedHrs.min}`}</_Table.Summary.Cell>
                        <_Table.Summary.Cell colSpan={1} align="center">{totals.total_decimalHrs.decimalHrs.toFixed(2)}</_Table.Summary.Cell>
                    </_Table.Summary.Row>
                </>);
            }}

            {...tableProps}
            // scroll={{ y:300 }}
        />

        <Modal title="Activity logs" centered visible={activityLogs !== false} width={500} footer={null}
                onCancel={() => setActivityLogs(false)}
                // onOk={() => this.setState({activityLogs:false})}
            ><div style={{ maxHeight: '500px', overflow: 'auto' }}>
                    {activityLogs && activityLogs.map((o, i) => {
                        if (!o || !o.user || !o.user.name) return null;
                        return <div key={i}><b>[{mongoToDate(o.date).format(`${defaultDateFormat} HH:mm`)}]</b> {o.note} by {o.user.name}</div>
                    })}
            </div>
        </Modal>

        {/* Edit Form */}
        <Drawer title={"Update Guard Hours"}
            height={'500px'} width={'100%'}
            placement="top"
            onClose={() => setShowScheduleForm(false)}
            visible={showScheduleForm ? true : false}
            bodyStyle={{ paddingBottom: 0 }}
            extra={<Button onClick={() => setShowScheduleForm(false)}>Cancel</Button>}
        >
            {showScheduleForm && <ScheduleForm onClose={() => setShowScheduleForm(false)} editNode={showScheduleForm} />}
        </Drawer>

        {verifyRole('hours-schedule-export.paygrid') && <>
            <Drawer
                title="Preview Paygrid Data"
                width={'100%'}
                onClose={() => setShowPaygrid(false)}
                visible={showPaygrid}
                bodyStyle={{ paddingBottom: 80 }}
                // extra={<Button onClick={() => this.toggleExportPaygrid() }>Cancel</Button>}
            >
                {showPaygrid && <PaygridPreview data={resultData} />}
            </Drawer>
        </>}

        {verifyRole('hours-schedule-export.invoice') && <>
            <Drawer
                title="Preview Invoice Data"
                width={'100%'}
                onClose={() => setShowInvoice(false)}
                visible={showInvoice}
                bodyStyle={{ paddingBottom: 80 }}
                // extra={<Button onClick={() => this.toggleExportPaygrid() }>Cancel</Button>}
            >
                {showInvoice && <InvoicePreview data={resultData} />}
            </Drawer>
            <Drawer
                title="Preview Breakdown Invoice Data"
                width={'100%'}
                onClose={() => setShowBreakdownInvoice(false)}
                visible={showBreakdownInvoice}
                bodyStyle={{ paddingBottom: 80 }}
                // extra={<Button onClick={() => this.toggleExportPaygrid() }>Cancel</Button>}
            >
                {showBreakdownInvoice && <BreakdownInvoicePreview calculateHoursTotal={calculateHoursTotal} data={resultData} />}
            </Drawer>
        </>}

        <Drawer
            title={"Daily Breakdown"}
            width={'100%'}
            onClose={() => setShowDailyBreakdown(false)}
            visible={showDailyBreakdown}
            bodyStyle={{ paddingBottom: 0 }}
            extra={<Button onClick={() => setShowDailyBreakdown(false)}>Cancel</Button>}
        >
            {showDailyBreakdown && <DailyBreakdownView calculateHoursTotal={calculateHoursTotal} data={resultData} />}
        </Drawer>


    </>)

}
const WithApollo = withApollo(RenderResultsComp)

const mapStateToProps = ({ ep_admin }) => {
    return ({ ep_admin });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export default connect(mapStateToProps)(WithApollo);


