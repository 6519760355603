import React, { useState } from 'react'
import { Button } from 'Common/components'
import { ContentArea } from 'Layout_v1'
import { withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper';
import { Space } from 'antd';
import moment from 'moment';


const RECEIVE_SMS = loader('src/Graphql/sms/receiveSms.graphql');

const NotificationTest = ({ client }) => {
  const [receiving_sms, set_receiving_sms] = useState(false)
  
  const receiveSms = async () => {

    const input = {
      sid: `a_${moment().unix()}`,
      // accountSid: String
      direction: "in",
      from: "123456789",
      to: "0321654987",
      // errorMessage: String
      body: "testing notification"
      // status: String
    }
    
    set_receiving_sms(true)
    let resutls = await client.mutate({ mutation: RECEIVE_SMS, variables: { input } }).then(r => (r?.data?.receiveSms))
      .catch(err => {
        console.log(__error("ERROR: "), err);
        return { error: { message: "Failed to process the request!" } }
      })
    set_receiving_sms(false)

    console.log("resutls: ", resutls)
  }

  return (<ContentArea>
    <div>NotificationTest</div>

    <Space direction='vertical'>
      <Button onClick={receiveSms} loading={receiving_sms}>Test SMS Notification</Button>
    </Space>

  </ContentArea>)
}

export default withApollo(NotificationTest);