import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { Table, Loader, Icon, IconButton, Button, DeleteButton, Drawer, DevBlock } from 'Common/components';
import { Breadcrumb } from "antd";
import { ContentArea } from "Layout_v1";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import ClientForm from './Client_form';
import { modulePermissions } from '.'
import SearchBar from './components/SearchBar'
import { defaultPageSize, uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import { ListHeader } from 'Common/components/Typography';
// import { verifyRole } from 'Common/scripts/Security';
// import { addToPaginationArray, updatePaginationArray } from 'Common/scripts/query_helpers';
// import ClientDetails from './client_details'
import { Link } from 'react-router-dom';

const LIST_DATA = loader('src/Graphql/client/clientQuery.graphql');
const DELETE = loader('src/Graphql/client/deleteClient.graphql');


class ClientComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null,
            error: null,
            kw:null,
            pagination: { current: 1, pageSize: defaultPageSize },
            variables: null,
            showClientDetails: false,

            showDrawer:false, initialData:null
        };
        // this.onSearch = this.onSearch.bind(this)
        this.searchList = this.searchList.bind(this)
        this.onDeleteClick = this.onDeleteClick.bind(this)
        // this.onUpdate = this.onUpdate.bind(this)
        // this.onAdd = this.onAdd.bind(this)
    }
    
    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ current: pagination.current, pageSize: pagination.pageSize });
    };

    fetchData = async (args = {}) => {
        const variables = {
            first: args.pageSize || this.state.pagination.pageSize, // number of rec to fetch
            after: (args.pageSize || this.state.pagination.pageSize) * ((args.current || this.state.pagination.current) - 1),
            filter: JSON.stringify(args.filter || {})
        }


        this.setState({ loading: true, variables })

        return this.props.client.query({ query: LIST_DATA, variables }).then(r => {
            const response = r.data.clientQuery;

            if (response.error) {
                this.setState({ loading: false, error: response.error.message });
                return;
            }

            this.setState({
                loading: false,
                data: response,
                pagination: {
                    ...this.state.pagination,
                    current: args.current || 1,
                    pageSize: args.pageSize || this.state.pagination.pageSize,
                    total: response.totalCount
                }
            });

            return response;

        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            this.setState({ loading: false, error: "Unable to complete your request at the moment!" });
            return false;
        })
    }

    searchList(value) {
        this.fetchData({
            // filter: { $or: [{ "personalinfo.f_name": value }, { "personalinfo.sin_no": value}]}
            filter: { search: { keywords: value }}
            
        });
    }
    
    columns = () => {
        let _cols = [
            {
                title: 'Client Name', dataIndex: 'name', render: (__, rec) => (<>
                    {/* <div className='a' onClick={() => this.toggleClientDetails(rec)}>{rec.name}</div> */}
                    <Link to={`/admin/clients/${rec._id}`}>{rec.name}</Link>
                    {/* <div style={{ fontSize: '12px', color: "#999" }}>Billing name: {rec.billing_name}</div> */}
                    {/* <span className='hidden-menu'>
                        {modulePermissions['client.account-edit'] && <IconButton className="item" icon="pen" onClick={() => this.toggeleDrawer(rec)} />}
                        {modulePermissions['client.account-delete'] && <DeleteButton className="item" onConfirm={() => this.onDeleteClick(rec?._id)} />}
                    </span> */}
                </>)
            },
            // { title: 'Sites', dataIndex: 'sites', render: (sites, rec) => {
            //         return sites ? sites.map((site, i) => <div key={i}>- {site.name}</div>) : null;
            //     }
            // },
            { title: 'Billing name', dataIndex: 'billing_name' },
            { title: 'Status', dataIndex: 'status', width: 100, align:"center" },
        ]

        // if (verifyRole("client.bill")) _cols.push({ title: 'Billing Name', dataIndex: 'billing_name' })

        return _cols;
    }

    onDeleteClick(_id) {
        if (!modulePermissions['client.account-delete']) return;

        this.props.client.mutate({
            mutation: DELETE, variables: { id: _id }
        }).then(r => {
            if (r.data.error) {
                this.setState({ loading: false, error: r.data.error.message });
                return;
            }

            const delIndex = this.state.data.edges.findIndex(data => data._id == r.data.deleteClient._id)
            const __data = this.state.data.edges ? this.state.data.edges.slice() : [];

            __data.splice(delIndex, 1)
            this.setState({ data: { edges: __data } })
            message.success("Record is successfully deleted!")
        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            this.setState({ loading: false, error: "Unable to delete at the moment!" });
        })


    }

    toggeleDrawer(initialData = null) {
        this.setState({ showDrawer: !this.state.showDrawer, initialData })
    }

    // onUpdate(node){
    //     const { data, variables } = this.state;

    //     let _data = updatePaginationArray({ 
    //         props: { clientQuery: data, client: this.props.client }, 
    //         queryName: "clientQuery", 
    //         node, 
    //         query: LIST_DATA, 
    //         variables
    //     })

    //     // console.log("_data: ", _data)
    //     this.setState({ data:_data })
    // }
    
    // onAdd(node){
    //     const { data, variables } = this.state;

    //     let _data = addToPaginationArray({
    //         props: { clientQuery: data, client: this.props.client },
    //         queryName: "clientQuery",
    //         node,
    //         query: LIST_DATA,
    //         variables
    //     })

    //     this.setState({ data: _data })
    // }

    toggleClientDetails(val = false) {
        this.setState({ showClientDetails: val })
    }


    render() {
        const { loading, data, error } = this.state;
        const { pagination, showDrawer, initialData } = this.state;

        // if (loading) return <ContentArea><Loader style={{ margin: "auto" }} loading={true} /></ContentArea>
        if (error) return <ContentArea><Alert message="Error" description={error} type="error" showIcon /></ContentArea>

        return (<>
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                {/* <Breadcrumb.Item href="/templates"><span>Templates</span></Breadcrumb.Item> */}
                <Breadcrumb.Item><Icon icon="user-tie" size="1x" /> Clients</Breadcrumb.Item>
            </Breadcrumb>

            <ClientForm showDrawer={showDrawer} initialValues={{}} onClose={() => this.toggeleDrawer()} />

            <ContentArea>
                {modulePermissions['client.account-add'] &&
                    <Row>
                        <Col flex="auto">
                            <ListHeader totalCount={(data && data.totalCount) || '0'} title='Clients' />
                        </Col>
                        <Col><Button type="primary" onClick={() => this.toggeleDrawer()} icon={<Icon icon='plus' />}>Add Client</Button></Col>
                        {/* <Search value={this.state.kw} onChange={(kw) => this.setState({kw:kw.target.value})} style={{width :"30%"}} placeholder="input search text" onSearch={this.searchList} enterButton /> */}
                    </Row>
                }
                
                <div className='table-header'><SearchBar fetchData={this.fetchData} /></div>

                <Table loading={loading}
                    // title={() => <b>Total {((data && data.totalCount) || '0')} record(s) found </b>}
                    columns={this.columns()}
                    dataSource={data ? data.edges : null}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    // expandable={{
                    //     expandedRowRender: record => {
                    //         console.log("record.sites: ", record.sites)
                    //         return(
                    //             <div>
                    //                 <Row>
                    //                     {record.sites.map((site, i) => <Col flex="500px" key={i} style={{border:'1px solid #EEE', borderRadius:'5px', margin:'5px', padding:'5px'}}>{site.name}</Col>)}
                    //                 </Row>
                    //             </div>
                    //         )
                    //     },
                    //     // rowExpandable: record => record.comments && record.comments.length > 0,
                    // }}

                    // onChange={this.handleTableChange}
                />
            </ContentArea>


            {/* <Drawer autoFocus={true} destroyOnClose={true} maskClosable={false} placement='top' transparent
                footer={false}
                title={'Client Details'}
                height={'100%'}
                onClose={() => this.toggleClientDetails()}
                visible={this.state.showClientDetails}
                bodyStyle={{ paddingBottom: 80 }}
            // extra={<Button size="small" onClick={onClose}>Cancel</Button>}
            >
                {this.state.showClientDetails && <ClientDetails inline match={{ params: { id: this.state.showClientDetails._id } }} onClose={() => this.toggleClientDetails()} />}
            </Drawer> */}

        </>)
    }
}

export const Client = withApollo(ClientComp)
export default Client;