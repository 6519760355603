import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Progress, message, Upload, Modal, Card, Alert, Row, Col, Image } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import _ from 'lodash';
// import axios from 'axios';
import { Field } from 'react-final-form'
import { __warning, __error, __success } from 'Common/scripts/consoleHelper';
import DevBlock from '../DevBlock';
import { IconButton } from '../Button';
// import DevBlock from '../DevBlock';


const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => resolve(false);
    });
};


const DisplayImage = ({ file, actions }) => {
    let { download, preview, remove } = actions;

    const [src, setSrc] = React.useState(false)

    if (!file) return null;
    
    getSrcFromFile(file).then(r=>{
        if(r) setSrc(r);
    });

    if (!src) return <p>Loading...</p>

    return <div style={{ border:"1px solid #EEE", textAlign:"center", height:"120px" }}>
        <Image preview={true} src={src} width={100} height={85} />
        <div><IconButton onClick={() => remove(file)} icon="trash-alt" /></div>
    </div>
}

export const FileField = props => {
    const [fileList, setFileList] = React.useState(props.fileList || []);
    const size = props.size || "medium";

    const beforeUpload = async (file, _list, callback) => {
        // console.log(__warning("beforeUpload()"), file);

        let _fileList = fileList.slice()
            _fileList = _fileList.concat(_list)
        setFileList(_fileList);
        if (callback) callback(_fileList);

        return false;
    }

    // const onRemove = (file) => {
    //     console.log("onRemove()", file)
    //     return true;

    //     const index = getFileIndex(file);
    //     console.log("index: ", index)

    //     if (index === false || index < 0) return;
    //     const newFileList = fileList.slice();
    //     newFileList.splice(index, 1);
    //     setFileList(newFileList);

    //     return false;
    // }

    // const removeFileFromLocal = file => {
    //     const index = getFileIndex(file);
    //     if (index === false || index < 0) return;
    //     const newFileList = fileList.slice();
    //     newFileList.splice(index, 1);
    //     setFileList(newFileList);
    // }

    const getFileIndex = file => {
        // var matchKey = file.uid !== undefined ? 'uid' : 'name';
        var matchKey;
        if (!matchKey && file.uid !== undefined) matchKey = 'uid';
        if (!matchKey && file.srcUrl !== undefined) matchKey = 'srcUrl';
        // if (!matchKey && file.name !== undefined) matchKey = 'name';

        if (!matchKey) {
            console.log(__error("Missing match key"), file);
            return false;
        }

        return fileList.findIndex(o => o[matchKey] == file[matchKey])
    }

    // const onChange = (args) => setFileList(args.fileList);

    // const onError = (error, response, file) => {
    //     if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) 
    //         message.error(error.response.data.error.message);
    //     else message.error("Error uploading file..")

    //     removeFileFromLocal(file);
    //     return false;
    // }

    // const onSuccess = (response, file, xhr) => {
    //     console.log(__success('onSuccess'), response);

    //     if (response.error) return message.error(response.error.message);
    //     if (response.success && response.success.message) message.success(response.success.message);

    //     message.success("Upload complete")

    //     try { if (typeof response === 'string') response = JSON.parse(response); }
    //     catch (e) { /* do nothing */ }

    //     let targetIndex = getFileIndex(file);
    //     if (targetIndex < 0) return;

    //     let targetItem = fileList[targetIndex];
    //     targetItem = Object.assign(targetItem, {
    //         percent: 100,
    //         status: 'done',
    //         response: response,
    //         xhr: xhr,
    //         uid: response.id || targetItem.uid || targetItem.srcUrl
    //     })

    //     if (props.onUploadComplete) props.onUploadComplete(response)

    //     // let newItem = {
    //     //     percent: 100,
    //     //     status: 'done',
    //     //     response: response,
    //     //     xhr: xhr,
    //     //     srcUrl: targetItem.srcUrl,
    //     //     thumbUrl: targetItem.thumbUrl,
    //     //     uid: targetItem.uid || targetItem.srcUrl
    //     // };

    //     const newFileList = fileList.slice();
    //     newFileList[targetIndex] = targetItem

    //     onChange({ fileList: newFileList, file: targetItem });
    // }

    // const onStart = file => {
    //     let targetIndex = getFileIndex(file);
    //     if (targetIndex < 0) return;
    //     let targetItem = fileList[targetIndex];

    //     targetItem = Object.assign(targetItem, { ...file }, { status: "uploading", percent: 0 })

    //     const newFileList = fileList.slice();
    //     newFileList[targetIndex] = targetItem

    //     setFileList(newFileList)
    // }

    // const customRequest = args => {
    //     // console.log(__warning("customRequest()"), args);
    //     const { action, data, file, filename, headers, onError, onProgress, onSuccess, withCredentials } = args;
    //     if (!action) return alert("no action found!");

    //     // EXAMPLE: post form-data with 'axios'
    //     // eslint-disable-next-line no-undef
    //     const formData = new FormData();
    //     if (data) {
    //         Object.keys(data).forEach(key => {
    //             formData.append(key, data[key]);
    //         });
    //     }
    //     formData.append(filename, file);
    //     formData.append("name", props.name);
    //     // if custom size if available
    //     if (props.thumbSize) formData.append("thumbSize", props.thumbSize);


    //     axios.post(action, formData, {
    //         withCredentials,
    //         headers,
    //         onUploadProgress: ({ total, loaded }) => {
    //             let percent = Math.round(loaded / total * 100).toFixed(2);
    //             // let percent = Math.round((loaded/2) / total * 100).toFixed(2);
    //             // if (percent == 100) return;
    //             onProgress({ percent: percent }, file);
    //             // onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) }, file);
    //         },
    //     })
    //         .then(({ data: response }) => {
    //             // onProgress({ percent: 100 }, file);
    //             onSuccess(response, file);
    //         })
    //         .catch(onError);

    //     return {
    //         abort() {
    //             console.log('upload progress is aborted.');
    //         },
    //     };
    // }



    const uploadProps = {
        action: false, // || `${process.env.REACT_APP_API_URL}/upload/assets`,
        disabled: props.disabled,
        multiple: props.multiple,
        // data: props.data,
        accept: props.accept, // ".png,.jpg,.jpeg",
        // method: props.method || "post",
        listType: "picture-card", // props.listType || "text", // text, picture, picture-card
        // headers: { Authorization: '$prefix $token' },
        // beforeUpload: beforeUpload,
        // showUploadList: false,
        // onRemove: onRemove,
        // onChange: onChange,
        // onError: onError,
        // fileList: fileList ? fileList.map(o=>(o.file)) : []
        fileList,
        itemRender: (originNode, file, fileList, actions) => {
            return <DisplayImage file={file} actions={actions} />
        },
        onPreview:(file)=>{
            console.log(file)
        }
    };

    return (<>
        <Field name={props.name} validate={props.validate}>
            {({ input, meta }) => {

                {/* const onChange = (args) => {
                    console.log("onChange()")
                    input.onChange(args.fileList)
                } */}

                const onRemove = file => {
                    const index = getFileIndex(file);
                    if (index === false || index < 0) return;

                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    setFileList(newFileList);

                    input.onChange(newFileList);
                }

                const onError = (error, response, file) => {
                    if (error.response && error.response.data && error.response.data.error && error.response.data.error.message)
                        message.error(error.response.data.error.message);
                    else message.error("Error uploading file..")

                    onRemove(file);
                    return false;
                }

                let disabled = props.disabled || (fileList && props.max > 0) && fileList.length >= props.max;
                let isRequired = props.validate ? props?.validate?.toString().includes("Required") : false;
                {/* let className = `form-field ${isRequired && 'validate'} ${((meta.error && meta.touched) || "") && 'has-errors'} ${!props.compact && "field-margins"} ${props.className || ""}`; */}
                let className = `form-field ${((meta.error && meta.touched) || "") && 'has-errors'} 
                    ${!props.compact && "field-margins"} 
                    ${disabled && "disabled"} 
                    ${props.className || ""}`;

                
                if(props.children) return(<>
                    <Upload 
                        multiple={props.multiple===false ? false : true} 
                        fileList={fileList}
                        defaultFileList={input.value || []}
                        beforeUpload={(_file, _fileList) => beforeUpload(_file, _fileList, input.onChange)}
                        onRemove={onRemove} 
                        onError={onError} 
                        disabled={disabled}
                        >
                        {props.children}
                    </Upload>
                </>)

                return (<>
                    <div className={`${className} file-upload`}>
                        {props.label && <label>{props.label}</label>}

                        <div className={`form-field file-upload compact ${isRequired && 'validate'}`}>
                            <Upload.Dragger {...uploadProps} 
                                defaultFileList={input.value || []}
                                // fileList={input.value || []}
                                beforeUpload={(_file, _fileList) => beforeUpload(_file, _fileList, input.onChange)}
                                // onChange={onChange} 
                                onRemove={onRemove} 
                                onError={onError} disabled={disabled}>
                                
                                {size=="large" && <>
                                    <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p>
                                </>}

                                {size =="medium" && <>
                                    <div style={{ padding: "0 10px" }}><Row gutter={[5]} align="middle">
                                        <Col flex="auto" />
                                        <Col><span style={{ color: "blue" }}><InboxOutlined /></span></Col>
                                        <Col>Click or drag file to this area</Col>
                                        <Col flex="auto" />
                                    </Row></div>
                                </>}
                            </Upload.Dragger>
                        </div>

                    </div>
                </>)
            }}
        </Field>
    </>)
}
FileField.propTypes = {
    validate: PropTypes.object,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    listType: PropTypes.string,
    accept: PropTypes.string,
    compacgt: PropTypes.bool,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    max: PropTypes.number,
    label: PropTypes.any,
    fileList: PropTypes.array,
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ])
}

export default FileField;
