import React, { useState, useEffect } from 'react'
import { Col, Row, Breadcrumb, Alert, message } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Icon, IconButton, Loader } from 'Common/components';
import { ContentArea } from "Layout_v1";
import { Link } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import { __error } from 'Common/scripts/consoleHelper';
import { loader } from 'graphql.macro';
import { gql } from '@apollo/client';
import { dateToMongo, mongoToDate } from 'Common/scripts/Functions';
import { defaultDateFormat, uriRoot } from 'configs';
import { CLIENT_INFO_FORM, GUARD_APPLICATION_FORM, SECURITY_GUARD_INTERVIEW } from './constants';

// const LATEST_DATA = loader('src/Graphql/FormsData/latest.graphql');

const LATEST_DATA = gql`
    query formsDatas($filter:String, $others:String){
        formsDatas(filter:$filter, others:$others){
            _id
            ref_key
            title
            status
            dupCount # read only
            created_at
            updated_at

            error { message }
        }
    }   
    `

const RECORD_LIMIT = 10;


const copyToClipboard = (e, from='string') => {
    let dateToCopy = "";

    if(from=='a'){
        console.log(e.target)
    }
    else if (from=='string'){
        dateToCopy = e;
    }
    else return alert("Invalid element type to cpy from")

    navigator.clipboard.writeText(dateToCopy).then(function () {
        message.info("Copied!")
    }, function () {
        message.error("Copy error!")
    });
}


const ApplicationsComp = (props) => {
    // ref_key = guard_application_form, client_info_form, security_guard_interview
    const [new_busy, set_newBusy] = useState(false);
    const [new_data, set_newData] = useState(null);
    
    const [active_busy, set_activeBusy] = useState(false);
    const [active_data, set_activeData] = useState(null);

    const [error, set_error] = useState(null);

    useEffect(() => {
        if (new_data || new_busy || !props.ref_key) return;
        getNewData();
        getActiveData();
    }, [props])

    const getNewData = async (args) => {
        set_newBusy(true);
        let variables = {
            filter: JSON.stringify({ 
                status: { $in: ['new'] },
                ref_key: props.ref_key
            }), 
            others: JSON.stringify({ limit: RECORD_LIMIT }),
        }

        const resutls = await props.client.query({ query: LATEST_DATA, variables }).then(r => {
            return r.data?.formsDatas;
        }).catch(err => {
            console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
            return { error: { message:"Unable to complete your request at the moment!" }}
        })
        
        set_newBusy(false);
        
        if (resutls.error){
            console.log(__error("ERROR: "), resutls);
            set_error(resutls.error.message)
            return;
        }

        if (resutls && resutls.length > 0) set_newData(resutls);
        return resutls;
    }

    const getActiveData = async (args) => {
        set_activeBusy(true);

        let variables = {
            filter: JSON.stringify({ 
                status: { $nin: ['new'] },
                ref_key: props.ref_key
            }), 
            others: JSON.stringify({ limit: RECORD_LIMIT })
        }

        const resutls = await props.client.query({ query: LATEST_DATA, variables }).then(r => {
            return r.data?.formsDatas;
        }).catch(err => {
            console.log(__error("ERROR: "), err);
            return { error: { message:"Unable to complete your request at the moment!" }}
        })

        set_activeBusy(false);

        if (resutls.error) {
            console.log(__error("ERROR: "), resutls);
            set_error(resutls.error.message)
            return;
        }

        if (resutls && resutls.length > 0) set_activeData(resutls);
        return resutls;
    }

    if (!props.ref_key) return <Alert message="Missing Application refference!" type="error" showIcon />

    var title = ""
    var outgoing_url = `${process.env.REACT_APP_HOST_URL}/inputForm/${props.ref_key}`
    if (props.ref_key == GUARD_APPLICATION_FORM){
        title = "Guard Application";
    }
    if (props.ref_key == CLIENT_INFO_FORM){
        title = "Client Information"
    }
    if (props.ref_key == SECURITY_GUARD_INTERVIEW){
        title = "Guard Interviews"
    }

    return(<>
        <h3 style={{margin:"0"}}>
            <Link to={`${uriRoot}/FormsData/${props.ref_key}`}>{title}</Link>
            <div>
                {/* <Link style={{ fontSize: "12px" }} target="_blank" to={`/inputForm/${props.ref_key}`}>open form <Icon icon="external-link-alt" /></Link> */}
                <a href={outgoing_url} target="_blank" style={{ fontSize: "12px" }}>open form <Icon icon="external-link-alt" /></a>
                <IconButton icon="copy" type="link" onClick={() => copyToClipboard(outgoing_url, 'string')} />
            </div>
        </h3>
        {error && <Alert message={error} type="error" showIcon />}

        {(!new_busy && new_data && new_data.length <1 && !active_busy && active_data && active_data.length<1) && 
            <Alert message="No records found!" type="info" showIcon />}
        {(new_busy || active_busy) && <div><Loader loading={true} /></div>}


        {/* New Records */}
        {(new_data && new_data.length>0) && <>
            <div style={{fontWeight:"bold", marginTop:"10px"}}>New Records</div>
            <table className='data-table'>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th width="80px">Status</th>
                        <th width="100px">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {new_data.map((item, i) => {
                        return (<tr key={i}>
                            <td><Link to={`${uriRoot}/FormsData/view/${item._id}`}>{item.title}</Link></td>
                            <td>{item.status}</td>
                            <td>{mongoToDate(item.created_at).format(defaultDateFormat)}</td>
                        </tr>)
                    })}
                </tbody>

            </table>
        </>}

        {/* Active records */}
        {(active_data && active_data.length>0) && <>
            <div style={{fontWeight:"bold", marginTop:"10px"}}>Active Records</div>
            <table className='data-table'>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th width="80px">Status</th>
                        <th width="100px">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {active_data.map((item, i) => {
                        return (<tr key={i}>
                            <td><Link to={`${uriRoot}/FormsData/view/${item._id}`}>{item.title}</Link></td>
                            <td>{item.status}</td>
                            <td>{mongoToDate(item.created_at).format(defaultDateFormat)}</td>
                        </tr>)
                    })}
                </tbody>

            </table>
        </>}
    </>)
}
export const ApplicationsHilights = withApollo(ApplicationsComp);

const FormDataHome = props => {
    return (<>
        {!props.inline && 
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={'/'}><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item>Forms Data Hilights</Breadcrumb.Item>
            </Breadcrumb>
        }

        <ContentArea transparent style={{ padding:"0px" }}>
            <Row gutter={[10]} style={{ minHeight:"100%" }}>
                <Col flex="33%">
                    <ContentArea style={{ margin: "0", minHeight: "400px", height:"100%", overflow: "auto" }}>
                        <ApplicationsHilights {...props} ref_key={GUARD_APPLICATION_FORM} />
                    </ContentArea>
                </Col>
                <Col flex="auto">
                    <ContentArea style={{ margin: "0", minHeight: "400px", height: "100%", overflow: "auto" }}>
                        <ApplicationsHilights {...props} ref_key={CLIENT_INFO_FORM} />
                    </ContentArea>
                </Col>
                <Col flex="33%">
                    <ContentArea style={{ margin: "0", minHeight: "400px", height: "100%", overflow: "auto" }}>
                        <ApplicationsHilights {...props} ref_key={SECURITY_GUARD_INTERVIEW} />
                    </ContentArea>
                </Col>
            </Row>

        </ContentArea>

    </>)
}
export default FormDataHome;
