import React, { Component, useState, useEffect } from 'react'
import { Button, DevBlock, Icon } from 'Common/components'
import { message, Row, Col, Popover, Tooltip } from 'antd';
import moment, { isMoment } from 'moment'
import _ from 'lodash'
import FilterForm from 'Common/FilteredViews/components/FilterForm';
import { dateFarmulaOptions } from 'Common/FilteredViews/constants';
import { filterGroups } from 'Common/FilteredViews/constants';
import { defaultDateFormat } from 'configs';

export const breakdownValues = (f) => {
    let { field, type, title } = f.item;
    let option = f.filter.option;
    let query = eval(`f.filter.${field}`);
    let value = query[option];

    return { field, type, title, option, query, value }
}

const createDescription = (f) => {
    if(!f) return false;

    let bd = breakdownValues(f);

    let option = bd.option;
    if (option == 'eq') option = 'equal to';
    else if (option == 'ne') option = 'not equal to';
    else if (option == 'lt') option = 'less than';
    else if (option == 'lte') option = 'less than or equal to';
    else if (option == 'gt') option = 'greater than';
    else if (option == 'gt') option = 'greater than or equal to';
    else if (option == 'contains') option = 'contains the keyword';
    else if (option == 'not_contains') option = 'excluding the keyword';
    else if (option == 'between') option = 'between the selected values';
    else option = "";
    
    let value = bd.value;

    if (_.isArray(value) && isMoment(value[0])) value = `${moment(value[0]).format(`${defaultDateFormat}`)} ~ ${moment(value[0]).format(`${defaultDateFormat}`) }`
    else if (isMoment(value)) value = moment(value).format(`${defaultDateFormat}`)
    else value = dateFarmulaOptions.find(o => o._id == value)?.description;


    return `${bd.title} ${option} ${value}`;
}


const FilterSelector = ({ field, onApply, onClear, initialValues, disabled }) => {
    const [selection, setSelection] = useState(initialValues);
    const [showPop, setShowPop] = useState(false);

    useEffect(() => {
        setSelection(initialValues);
    }, [initialValues])

    if (!field) return null;

    const onApplyPressed = values => {
        setShowPop(false);
        setSelection(values);
        if (onApply) onApply(values);
    }

    const parseValue = (val) => {
        if(val===false) return "any";
        if(val===true) return "empty";
        if (!val) return null;

        if (_.isArray(val)) return val.map(o => (parseValue(o))).toString()
        if(_.isString(val)) return val;
        else if (moment(val).isValid()) return moment(val).format(defaultDateFormat);
        
        return String(val);
    }

    let _field = selection ? selection?.item?.field : null;
    let _option = _field ? selection?.filter?.option : null;
    let _value = _field ? selection.filter[_field][_option] : null;
    // console.log({ _value, _option, _field })
    
    // if (selection){
    //     // let bd = breakdownValues(selection)
    //     // console.log("bd: ", bd)
    //     // console.log(`${bd.title} ${bd.option} ${bd.value}`)
    //     console.log( createDescription(selection) );
    // }

    // console.log(`${selection.item.title} ${selection.filter.option} ${selection[selection.item.field[selection.filter.option]]}`)

    _value = parseValue(_value)

    let group = filterGroups.find(o => o.key == 'schedule_info');
    let filterItem = group.filters.find(o => o.field == field)

    const filterContents = (<div style={{ minWidth:"300px" }}>
        <FilterForm filter={initialValues || {
            group_key: "schedule_info",
            item: filterItem
        }} onSubmit={onApplyPressed} onClear={onClear} initialValues={initialValues} />
    </div>)

    let description = createDescription(selection);

    return (<>
        <div style={{color:"#999"}}>{filterItem.title}</div>
        <Popover content={filterContents} title={false} trigger="click" disabled={disabled}>
            <Button type="dashed" color={_value ? undefined : 'lightgrey'}
                style={{ textAlign: "left" }} disabled={disabled}>
                {description && <Tooltip title={description}>
                    <Row align="middle">
                        <Col flex="auto"><span style={{ width: "120px" }} className="ellipsis">{_value === false ? 'false' : _value || filterItem.title}</span></Col>
                        <Col style={{ padding: "0px 0 0 5px", marginTop: "-5px" }}><Icon icon="angle-down" /></Col>
                    </Row>
                </Tooltip>}

                {!description && <Row align="middle">
                    <Col flex="auto"><span style={{ width: "120px" }} className="ellipsis">{_value === false ? 'false' : _value || filterItem.title}</span></Col>
                    <Col style={{ padding: "0px 0 0 5px", marginTop: "-5px" }}><Icon icon="angle-down" /></Col>
                </Row>}
             </Button>
        </Popover>
    </>)

}

const SearchBar = props => {
    const onApply = values => {
        props.onSearch(values)
    }

    const onClear = values => {
        props.onClear(values);
    }

    const getInitialValue = field_name => {
        if (!props?.activeFilter) return undefined;
        return props?.activeFilter?.find(o => {
            return o?.item?.field == field_name;
        })
    }

    let allowSearch = props?.activeFilter?.find(o=>{
        return o.item.field == 'check_in' || o.item.field == 'check_out' || o.item.field == 'scheduled_shift_start' || o.item.field == 'scheduled_shift_end';
    })

    // let desc_check_in = getInitialValue('check_in')?.filter?.check_in?.farmula ? dateFarmulaOptions.find(o => o._id == getInitialValue('check_in').filter.check_in.farmula) : null;
    // let desc_check_out = getInitialValue('check_out')?.filter?.check_out?.farmula ? dateFarmulaOptions.find(o => o._id == getInitialValue('check_out').filter.check_out.farmula) : null;
    // let desc_scheduled_shift_start = getInitialValue('scheduled_shift_start')?.filter?.scheduled_shift_start?.farmula ? dateFarmulaOptions.find(o => o._id == getInitialValue('scheduled_shift_start').filter.check_out.farmula) : null;
    // let desc_scheduled_shift_end = getInitialValue('scheduled_shift_end')?.filter?.scheduled_shift_end?.farmula ? dateFarmulaOptions.find(o => o._id == getInitialValue('scheduled_shift_end').filter.check_out.farmula) : null;
    // let desc_guard = getInitialValue('guard')?.filter?.guard?.farmula ? dateFarmulaOptions.find(o => o._id == getInitialValue('guard').filter.check_out.farmula) : null;
    // let desc_site = getInitialValue('site')?.filter?.site?.farmula ? dateFarmulaOptions.find(o => o._id == getInitialValue('site').filter.check_out.farmula) : null;

    return (<>
        {/* {!allowSearch && <div style={{color:"red", fontSize:"14px", fontWeight:"bold"}}>Pelase select at least one date to filter records</div>} */}
        {/* <DevBlock obj={getInitialValue('check_in').filter.check_in.farmula} /> */}
        <Row gutter={[10]}>
            <Col><FilterSelector field="check_in" onClear={onClear} onApply={onApply} initialValues={getInitialValue('check_in')} /></Col>
            <Col><FilterSelector field="check_out" onClear={onClear} onApply={onApply} initialValues={getInitialValue('check_out')} /></Col>
            <Col><FilterSelector field="scheduled_shift_start" onClear={onClear} onApply={onApply} initialValues={getInitialValue('scheduled_shift_start')} /></Col>
            <Col><FilterSelector field="scheduled_shift_end" onClear={onClear} onApply={onApply} initialValues={getInitialValue('scheduled_shift_end')} /></Col>
            <Col><FilterSelector field="guard" onClear={onClear} onApply={onApply} initialValues={getInitialValue('guard')} disabled={!allowSearch} /></Col>
            <Col><FilterSelector field="site" onClear={onClear} onApply={onApply} initialValues={getInitialValue('site')} disabled={!allowSearch} /></Col>
            <Col><FilterSelector field="approval_status" onClear={onClear} onApply={onApply} initialValues={getInitialValue('approval_status')} disabled={!allowSearch} /></Col>
        </Row>
        {/* {desc_check_in && <li>{desc_check_in.description}</li>}
        {desc_check_out && <li>{desc_check_out.description}</li>}
        {desc_scheduled_shift_start && <li>{desc_scheduled_shift_start.description}</li>}
        {desc_scheduled_shift_end && <li>{desc_scheduled_shift_end.description}</li>}
        {desc_guard && <li>{desc_guard.description}</li>}
        {desc_site && <li>{desc_site.description}</li>} */}
    </>)


}

export default SearchBar;

