/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
// import PropTypes from 'prop-types';
// import { Button } from 'Common/components';
import { Divider, Row, Col } from 'antd'
import { Breadcrumb } from "antd";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { ContentArea } from "Layout_v1";
import { ROOT } from './'; 
import { Button, Icon } from 'Common/components';
import { connect } from "react-redux";
import List from './components/list'
import UserForm from './components/UserForm'
import { modulePermissions } from '.'
import { uriRoot } from 'configs';


/**
 * Users
 */
const Users = props => {
  const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
  
  const toggleDrawer = args => {
    setState({ visible: !visible, fields: args.fields || null });
  };

  return (<>
    <Breadcrumb style={{ padding: "10px" }}>
      <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
      <Breadcrumb.Item><Icon icon="users" size="1x" /> Users</Breadcrumb.Item>
    </Breadcrumb>

    <ContentArea>
      {/* <h4>
        Users
        {modulePermissions['user.create'] &&
          <div className="buttons"><Button onClick={toggleDrawer} size="small">Add User</Button></div>
        }
      </h4> */}

      {modulePermissions['user.view'] && 
        <List onEditRecord={(rec) => toggleDrawer({ fields: rec })} toggleDrawer={toggleDrawer} />
      }

      {visible && <UserForm onClose={toggleDrawer} showform={visible} fields={fields} />}

    </ContentArea>
  </>)

}



Users.propTypes = {
  // login: PropTypes.object.isRequired,
}

const mapStateToProps = ({ ep_admin }) => {
  return ({ ep_admin });
}
//   const mapDispatchToProps = (dispatch, ownProps) => ({
//     doLogin: (payload) => dispatch(doLogin(payload)),
//   })
export default connect(mapStateToProps)(Users);

// export default Users;