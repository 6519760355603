import React from 'react';
import { addModule, addReducers } from '../connector';
import AssetTracking from './asset_tracking_list';
import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT, MODULE } from './constants';

export const modulePermissions = getmodulePermissions('asset_tracking');

// addReducers({ admins: reducers });
if (modulePermissions['at.manage']){
    addModule(
        { menuPath: `${ROOT}`, path: `${ROOT}`, component: AssetTracking, icon: 'user-tie', title: "Asset Tracking", exact: true, leftNav: true },
    )
}
// addModule(
//     { path: `/client/:id`, component: Detail, icon: 'desktop', title: "Client Detail", leftNav: false },
// )