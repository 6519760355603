import React, { Component } from 'react'
// import PropTypes from 'prop-types';
import { __error } from 'Common/scripts/consoleHelper'
import ListComp from './components/list';
import { DeleteButton, IconButton } from 'Common/components';
// import Shirt from '/icons/helmet.svg';


const KEY_VALUE = "company_assets";

const columns = ({ onEditClick, onDeleteClick }) => [
  { title: 'ID', dataIndex: '_id', width: 100 },
  {
    title: 'Title', dataIndex: 'title', render: (__, rec) => (<>
      <div>{rec.title} </div>
      <span className='hidden-menu'>
        <IconButton className="item" icon="pen" onClick={() => onEditClick(rec)} />
        <DeleteButton className="item" onConfirm={() => onDeleteClick(rec?._id)} />
      </span>
    </>)
  },
  { title: 'Value', dataIndex: 'values' },
  { title: 'Status', dataIndex: 'status', width: 100 },
];


const ListWrapper = props => {
  return <>
    {/* <img src='/icons/helmet.svg' alt="Helmet" width='100px' /> */}
    <ListComp key_value={KEY_VALUE} title="Company Assets" columns={columns} />
  </>
}
export default ListWrapper;