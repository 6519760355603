import React, { Component, useState, useEffect } from 'react'
import { mongoToDate, min2Hrs, getTimeDiff } from 'Common/scripts/Functions';
import moment from 'moment';
import { Select, Col, Row, Input, DatePicker, Alert } from 'antd';
import { CSVLink, CSVDownload } from "react-csv";
import { Button, DevBlock, Icon } from 'Common/components';
import { defaultDateFormat } from 'configs';


const BreakdownInvoicePreview = ({ calculateHoursTotal, data }) => {
    const [csvData, setCsvData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [updatedValues, setUpdatedValues] = useState([]); // [ row_id:rowIndex, field_name:value ]
    const [gst, setGst] = useState(5);

    const columns = [
        { title: "*InvoiceNo", defaultValue: "0000", width: 100 },
        { title: "*Customer", defaultValue: "", width: 300 },
        { title: "*InvoiceDate", defaultValue: "", width: 150 },
        { title: "*DueDate", defaultValue: "", width: 150 },
        { title: "Terms", defaultValue: "", width: 250 },
        { title: "Location", defaultValue: "", width: 250 },
        { title: "Memo", defaultValue: "", width: 250 },
        { title: "SERVICE DATE", defaultValue: "" },
        { title: "Item(Product/Service)", defaultValue: "" },
        { title: "ItemDescription", defaultValue: "" },
        { title: "ItemQuantity", defaultValue: "" },
        { title: "ItemRate", defaultValue: "" },
        { title: "*ItemAmount", defaultValue: "" },
        { title: "*ItemTaxCode", defaultValue: "GST" },
        { title: "ItemTaxAmount", defaultValue: `${gst} %` },
    ]

    useEffect(() => {
        createTableData();
    }, [data])


    const createTableData = () => {
        let _data = data.filter(o => o.approval_status == "aproved").map((row, rIndex) => {

            let service = row.service;
            let lead = row.lead;

            let total_decimalHrs = min2Hrs(getTimeDiff(row.check_in, row.check_out, 'minutes'))
            let total = 0;
            if (service) total = Number(total_decimalHrs.decimalHrs * service.rate);
            if (lead) total = Number(total_decimalHrs.decimalHrs * lead.rate);


            let _columns = columns.map((col, cIndex) => {
                let returnVal = {
                    ...col,
                    value: col.defaultValue
                };


                if (col.title == "*InvoiceNo") { }
                if (col.title == "*Customer") Object.assign(returnVal, { value: row?.site?.name || row?.client?.name })
                if (col.title == "*InvoiceDate") {}
                if (col.title == "*DueDate") {}
                if (col.title == "Terms") { }
                if (col.title == "Location") { }
                if (col.title == "Memo") { }
                if (col.title == "SERVICE DATE") Object.assign(returnVal, { value: moment(row.scheduled_shift_start).format(defaultDateFormat) })
                if (col.title == "Item(Product/Service)"){
                    if (service) Object.assign(returnVal, { value: service?.service_type?.title })
                    if (lead) Object.assign(returnVal, { value: lead?.service_type?.title })
                }
                if (col.title == "ItemDescription") { }
                if (col.title == "ItemQuantity") Object.assign(returnVal, { value: Number(total_decimalHrs.decimalHrs).toFixed(2) })
                if (col.title == "ItemRate") {
                    if (service) Object.assign(returnVal, { value: Number(service.rate || 0).toFixed(0) })
                    if (lead) Object.assign(returnVal, { value: Number(lead?.rate || 0).toFixed(0) })
                }
                if (col.title == "*ItemAmount") Object.assign(returnVal, { value: total.toFixed(2) })
                if (col.title == "*ItemTaxCode") Object.assign(returnVal, { value: "GST" })
                if (col.title == "ItemTaxAmount") Object.assign(returnVal, { value: Number((total / 100) * (gst || 0)).toFixed(2) })

                return returnVal;
            })

            return _columns;
        })

        setTableData(_data);
    }

    const updateRecords = ({ value, field_name, rowIndex, colIndex }) => {
        let _tableData = tableData.slice();
        _tableData[rowIndex][colIndex].value = value;

        setTableData(_tableData);
        setCsvData(null);
    }

    const renderColumn = ({ row, rowIndex, col, colIndex }) => {
        
        let thisRow = data[rowIndex]
        
        let total_decimalHrs = min2Hrs(getTimeDiff(thisRow.check_in, thisRow.check_out, 'minutes'))
        let total = 0;
        if (thisRow?.service?.rate) total = Number(total_decimalHrs.decimalHrs * thisRow.service.rate);

        if (col.title == "*InvoiceNo") return <Input value={col.value} onChange={e => updateRecords({ value: e.target.value, rowIndex, colIndex })} />
        if (col.title == "*Customer") {
            return (<>
            <Select value={col.value} style={{ width: "100%", textAlign: "left" }}
                onChange={(value, e) => updateRecords({ value, rowIndex, colIndex }) }
            >
                {thisRow?.site?._id && <Select.OptGroup label="Site Billing Name" key="g1">
                    <Select.Option key="opt1" value={thisRow.site.name}>{thisRow.site.name}</Select.Option>
                </Select.OptGroup>}
                {thisRow?.client?._id && <Select.OptGroup label="Client Billing Name" key="g2">
                    <Select.Option key="opt2" value={thisRow.client.name}>{thisRow.client.name}</Select.Option>
                </Select.OptGroup>}                
            </Select></>)
        }
        if (col.title == "*InvoiceDate") return <DatePicker onChange={(__, value) => updateRecords({ value, rowIndex, colIndex })} />
        if (col.title == "*DueDate") return <DatePicker onChange={(__, value) => updateRecords({ value, rowIndex, colIndex })} />
        if (col.title == "Terms") return <Input value={col.value} onChange={e => updateRecords({ value: e.target.value, rowIndex, colIndex })} />
        if (col.title == "Location") return <Input value={col.value} onChange={e => updateRecords({ value: e.target.value, rowIndex, colIndex })} />
        if (col.title == "Memo") return <Input value={col.value} onChange={e => updateRecords({ value: e.target.value, rowIndex, colIndex })} />
        // if (col.title == "SERVICE DATE") return moment(thisRow.scheduled_shift_start).format(defaultDateFormat)
        // if (col.title == "Item(Product/Service)") return thisRow?.service?.service_type?.title
        if (col.title == "ItemDescription") return <Input value={col.value} onChange={e => updateRecords({ value: e.target.value, rowIndex, colIndex })} />
        // if (col.title == "ItemQuantity") return Number(total_decimalHrs.decimalHrs).toFixed(2)
        // if (col.title == "ItemRate") return thisRow?.service?.rate?.toFixed(2) || 0;
        // if (col.title == "*ItemAmount") return total.toFixed(2)
        // if (col.title == "*ItemTaxCode") return "GST"
        // if (col.title == "ItemTaxAmount") return Number((total / 100) * (gst || 0)).toFixed(2)

        return col.value;
        // return <>{row[colIndex].value}</>
    }

    const createCSVData = () => {

        let _csvData = []
        // add header column
        _csvData.push(columns.map(o => (o.title)))
        // add row data
        // var unapproved = 0;
        _csvData = _csvData.concat(tableData.map((row, rIndex) => {
            // if (data[rIndex].approval_status == "new"){
            //     unapproved++;
            //     return false;
            // }
            return (row.map(col => (col.value)));
        } )); //.filter(o=>(o!=false))
        
        // if (unapproved > 0) alert(`${unapproved} unapproved records are removed`)
        setCsvData(_csvData);

    }

    if (!tableData || tableData.length<1) return <p>No data available</p>
    
    var tableWidth = 0;
    columns.forEach(col => {
        tableWidth += Number(col.width || 150);
    });

    let unapprovedCount = data.filter(o => o.approval_status != "aproved")?.length || 0;

    return (<>
        <h4>Daily Breakdown Invoice
            {unapprovedCount > 0 && <Alert message={`${unapprovedCount} unapproved record(s) excluded`} type='warning' showIcon />}
        </h4>

        <div style={{ position: "relative", width: "100%", paddingBottom: "5px" }}>
            <Row style={{ backgroundColor: "#FFFFFF" }}>
                <Col>
                    <div className='form-field input '>
                        <Row align="middle" gutter={[5]}>
                            <Col>GST: </Col>
                            <Col><Input type='number' min={0} max={100} defaultValue={5} onChange={({ target }) => {
                                setGst(target.value || 0);
                                setCsvData(null);
                            }} /></Col>
                            <Col>%</Col>
                        </Row>
                    </div>
                </Col>
                <Col flex="auto" align="right">
                    {!csvData && <Button onClick={() => createCSVData()}>Create CSV</Button>}
                    {csvData && <span style={{ border: '1px solid blue', borderRadius: '5px', padding: '4px 10px', display: 'inline-block' }}>
                        <CSVLink data={csvData} filename='site_invoice_hours.csv'><span style={{ fontSize: '12px' }}>Download CSV </span> <Icon icon="file-csv" size="1x" /></CSVLink>
                    </span>}
                </Col>
            </Row>
        </div>


        <div style={{ border: "1px solid #EEE", overflowX: "scroll", display: "block", height: "calc(100vh - 200px)", width: "100%" }}>
            <table className='dataTable' style={{ overflowX: "scroll", width: `${tableWidth}px` }}>

                <thead>
                    <tr>
                        {columns.map((col, i) => {
                            return <th key={i} style={{ width: `${col.width || 150}px` }}>{col.title}</th>
                        })}
                    </tr>
                </thead>

                <tbody>
                    {tableData.map((row, rowIndex) => {
                        return <tr key={rowIndex}>
                            {row.map((col, colIndex) => <td key={`${rowIndex}_${colIndex}`}>
                                {renderColumn({ row, rowIndex, col, colIndex })}
                            </td>)}
                        </tr>
                    })}
                </tbody>

            </table>
        </div>

        {/* <DevBlock obj={csvData} /> */}
    </>)
  
}

export default BreakdownInvoicePreview