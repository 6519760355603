import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import { Popconfirm, Alert, message, Row, Col, Menu, Modal } from 'antd';
import { Table, Loader, Icon, IconButton, Button, Drawer, TestFormSearch, TestCourseSearch } from 'Common/components';
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
import { loader } from 'graphql.macro';
import { withApollo } from '@apollo/client/react/hoc';

const UPDATE_PWD = loader('src/Graphql/TestResults/updateTestResultPassword.graphql');


const PasswordProtectionform = ({ initialValues, onSuccess, onClose, client, visible }) => {
    const [busy, setBusy] = useState(false);

    const onSubmit = async values => {
        setBusy(true);

        const input = {
            id: initialValues._id,
            password: values.password,
        }

        const results = await client.mutate({ mutation: UPDATE_PWD, variables: { ...input } })
            .then(e => (e?.data?.updateTestResultPassword))
            .catch(err => {
                console.log(__error("API Call ERROR: clients : "), err);
                // message.error("Request ERROR");
                return { error: { message:"Request ERROR" } }
            })

        setBusy(false);

        if (results.error){
            console.log(__error("ERROR: "), results);
            message.error(results.error.message);
            return;
        }

        onSuccess(results);
    }
    
    return (<>
        <Modal visible={visible} onCancel={onClose} footer={false} title={"Update or Add Password"}>
            {visible && <>
                <FormComponent onSubmit={onSubmit} id='UpdatePwdProtectionForm' loading={false} debug={false} fields={{}} moveOnError
                    form_render={formProps => {
                        const { values, submitting, form, invalid, dirty, valid } = formProps;

                        return (<div style={{ flex: 1, display: "flex", flexDirection: "column", width: "100%" }}>
                            <FormField label="Password" name="password" type="text" />
                            <Button type="primary" block htmlType='submit' size="large" loading={submitting}>Save</Button>
                        </div>)
                    }}
                />
            </>}
        </Modal>

    </>)
}

export default withApollo(PasswordProtectionform)