/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert, Checkbox, message, Row, Col } from 'antd';
import { Form, Field } from 'react-final-form'
import { Loader, Button, DevBlock, Icon } from 'Common/components'
import { rules, composeValidators, FormField } from 'Common/components/Form'
import { connect } from "react-redux";
import { doLogin } from 'Store/ep_admin.actions.js'
import { loginUser } from 'Store/saga.actions';
import './styles.scss';


/**
 * Login
 */

class Login extends Component { // eslint-disable-line react/prefer-stateless-function
  // Verification = verifyModule('Login');
  constructor(props) {
    super(props);
    this.state = { warning: false, error: false };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    this.setState({ error: false, warning:false });
    this.props.loginUser({ email: values.email, pwd: values.pwd, app: "web-admin" })
  }

  render() {
    const { busy, error } = this.props.ep_admin;

    return (<>
      <div className="login_form_page">

        <div className="lgf_wrapper">

          <Loader spinning={busy}>
            <div className="lgf_container">

              <div className="warning_container">
                {error && <Alert message="Error" type="error" description={String(error)} closable />}
                {/* {warning && <Alert message="Warning" type="warning" description={String(warning)} closable banner={true} />} */}
              </div>

              <Row align="middle">
                <Col><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-light.png`} width="200px" /></Col>
                <Col>
                  <div style={{ color: "#00D4BF", fontSize:16 }}>A-List Administration</div>

                  <Form onSubmit={this.onSubmit} initialValues={{}} validate={(__values) => (false)}>
                    {({ handleSubmit, submitting, reset, values, invalid }) => (<>
                      <form className="form_component" onSubmit={event => {
                        if (invalid) message.error("Invalid submission! Please verify your input.");
                        handleSubmit(event);
                      }}>

                        <FormField disabled={busy} name="email" size="large" type="text" placeholder="User name" validate={composeValidators(rules.required, rules.minChar(8))} />
                        <FormField disabled={busy} name="pwd" size="large" type="password" placeholder="Password" validate={composeValidators(rules.required, rules.minChar(4))} />
                        <Button type="primary" htmlType='submit' loading={busy}>Login</Button>

                      </form>

                    </>)}
                  </Form>

                </Col>
              </Row>


            </div>
          </Loader>

        </div>


      </div>
    </>)


  }
}



Login.propTypes = {
  // login: PropTypes.object.isRequired,
}

// const WithApollo = compose(

//   graphql(LOGIN_QUERY, {
//     props: ({ mutate }) => ({
//       userLogin: ({ email, pwd, app }) => mutate({
//         variables: { email, pwd, app }
//       }),
//     })
//   }),

// )(Login);


const mapStateToProps = ({ ep_admin }) => {
  return ({ ep_admin });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  doLogin: (payload) => dispatch(doLogin(payload)),
  loginUser: (payload) => dispatch(loginUser(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Login);
