import React from 'react'
import { Tooltip } from 'antd';
import Icon from '../Icon';

export const RenderError = ({ meta, showIcon }) => {
    if (!(meta.error && meta.touched)) return null;

    // return (meta.error && meta.touched) ? <div className="field-error">{meta.error}</div> : null;
    if (!showIcon) return <div className="field-error">{meta.error}</div>

    return <span style={{ position: "absolute", bottom: "5px", right: "10px" }}>
        <Tooltip title={meta.error} color="orange">
            <Icon color="orange" icon="exclamation-circle" />
        </Tooltip>
    </span>

}
