/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { E404 } from "Layout_v1";

import { SubMenu } from './';
import { Row, Col } from 'antd';

/**
 * Home
 */
export const Home = props => {
  let routs = SubMenu();

  var style = {
    border: "1px solid blue"
  }

  return (<>
    <h1>Template</h1>
    <Row>
      <Col style={style}>A</Col>
      <Col style={style}>B</Col>
    </Row>
    <Row>
      <Col flex="auto" style={style}>A</Col>
      <Col flex="200px" style={style}>B</Col>
    </Row>
    <Row align="middle">
      <Col align="center" style={style}>A</Col>
      <Col style={style}>B<br />C</Col>
    </Row>
    
    <Switch>
      {routs.map((item, i) => {
        if (!item.component) return null;
        return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
      })}
      <Route key={999} component={E404} />
    </Switch>
  </>)

}

Home.propTypes = {
  // login: PropTypes.object.isRequired,
}

export default Home;