import React, { Component } from 'react'
import { Icon, Loader } from 'Common/components';
import { ContentArea } from "Layout_v1";
import { HomeOutlined } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import { Breadcrumb, Popconfirm, Alert, message, Row, Col } from 'antd';
import { __error } from 'Common/scripts/consoleHelper';
import { E404 } from "Layout_v1";

import GuardApplicationForm_Preview from './GuardApplicationForm/preview';
import ClientQueryform_Preview from './ClientQueryForm/preview';
import SecurityGuardInterview_Preview from './SecurityGuardInterview/preview';
import FirewatchAcknowledgement_Preview from './FirewatchLogDoc/preview';
import ObservationReport_Preview from './ObservationReport/preview';

import { FIREWATCH_LOG_DOC, CLIENT_INFO_FORM, GUARD_APPLICATION_FORM, SECURITY_GUARD_INTERVIEW } from '../constants';
import { uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import { Link } from 'react-router-dom';


const FORM_DATA = loader('src/Graphql/FormsData/formsData.graphql');
const EDIT_FORMDATA = loader('src/Graphql/FormsData/editFormsData.graphql');

class DataPreviewComp extends Component {
    state = { loading: false, data: null, error: null };

    componentDidMount() {
        if (this?.props?.match?.params?.id) this.loadFormData({ _id: this.props.match.params.id });
        else this.setState({ error: 'No Data ID Found!' })
    }

    async loadFormData(filter = {}) {
        this.setState({ loading: true });

        return this.props.client.query({ query: FORM_DATA, variables: { filter: JSON.stringify(filter) } }).then(r => {
            let response = r.data?.formsData;

            if (!response) return this.setState({ loading: false, error: "Invalid response from server" });
            if (response.error) return this.setState({ loading: false, error: response.message });

            this.updateStatustoView(response).then(r=>{
                this.setState({ loading: false, data: { ...response, ...r } });
            });

        }).catch(err => {
            console.log(__error("ERROR: "), err);
            this.setState({ loading: false, error: "Unable to complete your request at the moment!" });
        })

    }

    async updateStatustoView(_data){
        if (_data.status!='new') return;

        const updated = this.props.client.mutate({ mutation: EDIT_FORMDATA, variables: { input: { _id: _data._id, status: 'viewed' } }}).then(r => {
            let response = r.data.editFormsData;
            if (!response || response.error) {
                message.error(response && response?.error?.message || "Invalid Response!")
                return {};
            }

        }).catch(err => {
            console.log(__error("ERROR"), err);
            message.error("Failed to update status!");
            return {};
        });

        return { status: 'viewed' };

    }

    goBack(e) {
        e.preventDefault();
        this.props.history.goBack();
    }

    renderForm(){
        const { data } = this.state;
        console.log("data: ", data)

        switch (data.ref_key) {
            case GUARD_APPLICATION_FORM:
                return <GuardApplicationForm_Preview data={data} />
                break;

            case CLIENT_INFO_FORM:
                return <ClientQueryform_Preview data={data} />
                break;

            case SECURITY_GUARD_INTERVIEW:
                return <SecurityGuardInterview_Preview data={data} />
                break;

            case FIREWATCH_LOG_DOC:
                return <FirewatchAcknowledgement_Preview data={data} />
                break;

            case 'observation_report':
                return <ObservationReport_Preview data={data} />
                break;

            default:
                return <E404 />
                break;
        }
    }


    render() {
        const { loading, data, error } = this.state;

        var pageTitle = "Invalid Data";

        if (error) return <ContentArea><Alert message="Error" description={error} type="error" showIcon /></ContentArea>
        if (loading) return <ContentArea><Loader loading={true} /></ContentArea>
        if (!data) return null;

        switch (data.ref_key) {
            case GUARD_APPLICATION_FORM:
                pageTitle = "Guard Application Details";
                break;

            case CLIENT_INFO_FORM:
                pageTitle = "Client Information Details";
                break;

            case SECURITY_GUARD_INTERVIEW:
                pageTitle = "Security Guard Interview";
                break;

            case FIREWATCH_LOG_DOC:
                pageTitle = "Firewatch Acknowledgement";
                break;

            case 'observation_report':
                pageTitle = "Observation Report";
                break;

            default:
                break;
        }


        return (<>
            {!this?.props?.inline && 
                <Breadcrumb style={{ padding: "10px" }}>
                    <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                    <Breadcrumb.Item><Link to={`${uriRoot}/FormsData`}>Form Data</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
                    <Breadcrumb.Item><span>Details</span></Breadcrumb.Item>
                </Breadcrumb>
            }

            {this.renderForm()}

        </>)
    }
}

export const DataPreview = withApollo(DataPreviewComp)
export default DataPreview;
