import React, { Component } from 'react'
import { Breadcrumb, Row, Col, Tabs } from 'antd'
import { uriRoot } from 'configs'
import { ContentArea } from 'Layout_v1'
import { Link } from 'react-router-dom'
import { Button, Drawer, Loader } from 'Common/components'
import { rules, FormField, FormComponent } from 'Common/components/Form'
// import FormDataList from 'Modules/ApplicationForm/containers/form_data_list'
import FormDataList from 'Modules/FormsData/containers/FormsDataList'
import PoRequestForm from '../components/po_request_form'
import { FORM_KEY } from '../constants'

export default class RequestList extends Component {
  state = { activeTab: "open", showAddForm: false, loading:false }

  constructor(props){
    super(props);
    this.onTabChanged = this.onTabChanged.bind(this);
  }

  onTabChanged(_activeTab){
    this.setState({ activeTab: _activeTab })
  }
  toggleAddForm(){
    this.setState({ showAddForm: !this.state.showAddForm })
  }

  columns = [
    { title: 'ID', dataIndex: '_id', width: 100 },
    { title: 'title', dataIndex: 'title'  },
  ]


  render() {
    const { activeTab, showAddForm, loading } = this.state;

    return (<>
      <Row align="middle" style={{ padding: "10px 10px 0 10px" }}>
        <Col flex="auto">
          <Breadcrumb>
            <Breadcrumb.Item href={uriRoot}>Home</Breadcrumb.Item>
            {/* <Breadcrumb.Item href="/templates"><span>Templates</span></Breadcrumb.Item> */}
            <Breadcrumb.Item>PO Requests</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <Button onClick={()=>this.toggleAddForm()} type="primary">Open Form</Button>
        </Col>
        {/* <Search value={this.state.kw} onChange={(kw) => this.setState({ kw: kw.target.value })} style={{ width: "30%" }} placeholder="input search text" onSearch={this.searchList} enterButton /> */}
      </Row>

      <ContentArea>

        <Tabs defaultActiveKey={activeTab} onChange={this.onTabChanged}>
          <Tabs.TabPane tab="Open Requests" key="open" />
          <Tabs.TabPane tab="Completed" key="completed" />
          <Tabs.TabPane tab="Archived" key="archived" />
        </Tabs>

        {activeTab == "open" && <FormDataList filter={{ ref_key: FORM_KEY }} columns={this.columns} />}
        {activeTab == "completed" && <FormDataList filter={{}} columns={this.columns} />}
        {activeTab == "archived" && <FormDataList filter={{}} columns={this.columns} />}

      </ContentArea>

      <PoRequestForm showForm={showAddForm} onClose={()=>this.toggleAddForm()} />
      
      {/* <Drawer
        placement="top"
        title={"Create PO Request"}
        height={"100%"}
        onClose={() => this.toggleAddForm()}
        visible={showAddForm}
        bodyStyle={{ paddingBottom: 80 }}
        extra={<Button onClick={() => this.toggleAddForm()}>Cancel</Button>}
        // footer={<Row><Col flex="auto"></Col><Col>
        //     <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} >Submit </Button>
        // </Col></Row>}
        destroyOnClose={true}
      ><>
          {loading && <Loader style={{ margin: "auto" }} loading={true} />}

          {(!loading && showAddForm) && <>

          </>}

      </></Drawer> */}


    </>)
  }
}
