import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Popconfirm, Input, message, Row, Col, Alert, Breadcrumb, Select } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, DevBlock, DeleteButton, Drawer } from 'Common/components';
import { defaultPageSize, tagsTypeArray, uriRoot } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { modulePermissions } from './'
import { ListHeader } from 'Common/components/Typography';
import TagsFormDrawer from './components/TagsFormDrawer'
import { ContentArea } from 'Layout_v1';
import { PlusOutlined, HomeOutlined } from '@ant-design/icons';
import SearchBar from './components/SearchBar';

const LIST_DATA = loader('src/Graphql/tags/tagsQuery.graphql');
const RECORD_DELETE = loader('src/Graphql/tags/deleteTag.graphql');
const RECORDS_DELETE = loader('src/Graphql/tags/deleteTags.graphql');


export const ListComp = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const [busy, setBusy] = useState(false)
    const [pagination, setPagination] = useState({ first: defaultPageSize, after: 0, filter: { type: tagsTypeArray[0]._id }, others: { sort: { title: -1 } } })
    const [queryResults, set_queryResults] = useState(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [deleting, setDeleting] = useState(false);

    const [tagsQuery, { called, loading, data }] = useLazyQuery(
        LIST_DATA, { variables: { ...pagination, filter: JSON.stringify(pagination.filter), others: JSON.stringify(pagination.others) } }
    );

    const [deleteTag, delete_details] = useMutation(RECORD_DELETE);
    const [deleteTags, bulkdelete_details] = useMutation(RECORDS_DELETE);

    useEffect(() => {
        if (called || loading || data) return;

        fetchData({})
    }, [])

    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    const fetchData = async (args) => {
        setBusy(true);

        let filter = (args && args.filter) || pagination.filter || {};
        let others = (args && args.others) || pagination.others || {};
        let first = defaultPageSize; //(args && args?.pagination?.first) || pagination.first || defaultPageSize;
        let after = (args && args?.pagination?.after) || pagination.after || 0;

        setPagination({
            first, after,
            filter, others,
        })

        let variables = {
            first,
            after,
            filter: JSON.stringify(filter),
            others: JSON.stringify(others)
        }

        let results = await tagsQuery({ variables }).then(r => (r?.data?.tagsQuery))

        setBusy(false)

        set_queryResults(results)
        return results;
    }
    const handleTableChange = (_pagination, filters, sorter) => {
        console.log("_pagination: ", _pagination)

        // fetchData({
        //     pagination: {
        //         first: _pagination.pageSize,
        //         after: _pagination.pageSize * (_pagination.current - 1),
        //         // filter: _pagination.filter
        //     }
        // })
    };

    const doSearch = (values) => {
        let _filter = {}
        if (values.kw) Object.assign(_filter, { search: { keywords: values.kw } })
        if (values.type) Object.assign(_filter, { type: values.type })

        fetchData({
            pagination: {
                first: defaultPageSize,
                after: 0,
            },
            filter: _filter
        })
    }

    const onSuccess = () => {
        if (visible) toggleDrawer({})
        fetchData({})
    }

    const handleDelete = async (id) => {
        if (!modulePermissions['manage.tags']) return;

        let results = await deleteTag({ variables: { id } }).then(r => (r?.data?.deleteTag))
            .catch(error => {
                console.log(__error("ERROR"), error);
                return { error: { message: "Unable to delete record" } }
            })

        if (!results || results.error) {
            message.error((results && results.error.message) || "invalid response")
            return false;
        }
        fetchData()
        return true;
    }

    const columns = [
        {
            title: 'Title', dataIndex: 'title', render: (__, rec) => {
                return (<>
                    <span>{rec.title}</span>
                    <span className='hidden-menu'>
                        {modulePermissions['manage.tags'] && <DeleteButton className="item" onClick={() => handleDelete(rec?._id)} />}
                        {modulePermissions['manage.tags'] && <IconButton icon="pen" className="item" onClick={() => toggleDrawer({ fields: rec })} />}
                    </span>
                </>)
            }
        },
        { title: 'Type', dataIndex: 'type', width: "150px" },
    ];

    const onSelectChange = (newSelectedRowKeys) => {
        // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    
    const hasSelected = selectedRowKeys.length > 0;


    const deleteBulk = async () => {
        setDeleting(true)
        await deleteTags({ variables: { ids: selectedRowKeys }})
        fetchData();
        // await sleep(2000)
        setDeleting(false)
    }



    if (!modulePermissions['access.tags']) return <Alert message="Access denied" type='warning' showIcon />

    return (<>
        <Breadcrumb style={{ padding: "10px" }}>
            <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
            <Breadcrumb.Item><Icon icon="tags" size="1x" /><b> Tags</b></Breadcrumb.Item>
        </Breadcrumb>

        <ContentArea>
            <Row>
                <Col flex="auto"><ListHeader title={"Tags"} totalCount={(queryResults && queryResults.totalCount) || '0'} /></Col>
                {modulePermissions['manage.tags'] && <Col><Button onClick={toggleDrawer}>Add Tags</Button></Col>}
            </Row>

            <SearchBar onSubmit={doSearch} defaultValues={{ type: pagination.filter.type }} />

            <Table
                rowSelection={{
                    selectedRowKeys,
                    onChange: onSelectChange,
                }}
                loading={busy}
                columns={columns}
                dataSource={queryResults ? queryResults.edges : null}
                pagination={{ ...pagination, pageSize: defaultPageSize }}
                // rowClassName={(record => {
                //     return record.status == 'disabled' ? 'show-menu-on-hover disabled-table-row' : "show-menu-on-hover";
                // })}
                onChange={handleTableChange}
                // title={() => <span>Header</span>}
                footer={() => {
                    return (<>
                        {hasSelected && <Row gutter={[5]}>
                            <Col>{selectedRowKeys.length} records selected</Col>
                            <Col><DeleteButton loading={deleting} onClick={deleteBulk}><Button color="red" size="small">Delete Selection</Button></DeleteButton></Col>
                        </Row>}
                    </>)
                }}
            />

        </ContentArea>

        <TagsFormDrawer onClose={toggleDrawer} onSuccess={onSuccess} visible={visible} fields={fields} />

    </>)
}


export default ListComp;