import React from 'react';
import { addModule, addReducers } from '../connector';
import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT, MODULE } from './constants'
import Knowledgebases from './Home';
import KnowledgebaseSearchResults from './KnowledgebaseSearchResults';

export const modulePermissions = getmodulePermissions('knowledgebases');

if (modulePermissions['knowledgebases.access']) {
  addModule(
    { path: `${ROOT}`, component: Knowledgebases, icon: 'book-reader', title:"Knowledgebase", exact:1, leftNav:true },
  )
  
  addModule(
    { path: `${ROOT}/search`, component: KnowledgebaseSearchResults, title: "KnowledgebaseSearchResults", exact: 0, leftNav: false },
    // { path: `/knowledgebase/view`, component: MyProfile, exact: true, leftNav: false },
  )
}




