import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import moment from 'moment';
import { Popconfirm, Input, message, Row, Col } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, DevBlock } from 'Common/components';
// import { getTimeDifference } from 'Common/scripts/Functions'
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { modulePermissions } from '../'
import { ListHeader } from 'Common/components/Typography';

const LIST_DATA = loader('src/Graphql/user/usersQuery.graphql');
const QUERY_SUBSCRIPTION = loader('src/Graphql/user/subscription.graphql');
const RECORD_DELETE = loader('src/Graphql/user/deleteUser.graphql');


const defaultFilter = {
    acc_type: { $ne: 'super-admin' }
    // $or: accTypes.filter(o => o.cat == 'staff').map(o=>({type:o._id}))
};


export class ListComp extends Component {
    state = { 
        pagination: { current: 1, pageSize: defaultPageSize },
        filter: {}
    };
    subscription = false;

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        // consider loading complete
        if (this.props.loading && !nextProps.loading) {
            this.setState({
                pagination: {
                    ...this.state.pagination,
                    total: nextProps.usersQuery.totalCount
                }
            })
        }

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    filter: JSON.stringify(defaultFilter),
                    others: JSON.stringify({})
                },
                _subscriptionName: "usersUpdated",
                _subscriptionType: "array",
                _queryName: "usersQuery",
                _typename: "UsersQuery",
                debug: true
            });
        }

    }

    handleDelete(id) {
        if (!modulePermissions['user.delete']) return;

        this.props.deleteUser(id).then(r => {
            if (r && r.data && r.data.deleteUser && r.data.deleteUser.error) {
                message.error(r.data.deleteUser.error.message);
                return false;
            }
            message.success("Record deleted")
        })
            .catch(error => {
                console.log(__error("ERROR"), error);
                message.error("Unable to delete record")
            })
    }

    renderTitleColumn = (text, record) => {
        return (<Row gutter={10} align="middle">
            <Col><Avatar src={record.avatar} alt={record.name} /></Col>
            <Col>
                {record.name || record.email}
                {/* <Link className="a" to={`/users/${record._id}`}>{record.fname || record.lname || record.email}</Link> */}
            </Col>
            <Col flex="auto"><div className='hidden-menu'>
                {modulePermissions['user.update'] &&
                    <Button onClick={() => this.props.onEditRecord(record)} icon={<Icon icon='pen' />} size='small' style={{marginLeft:10}}>Edit</Button>
                }
                {modulePermissions['user.delete'] &&
                    <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record._id)}>
                        <Button type='danger' icon={<Icon icon='trash-alt' />} size='small' style={{marginLeft:10}}>Delete</Button>
                    </Popconfirm>
                }
            </div></Col>
        </Row>)
    }

    columns = [
        { title: 'Name', dataIndex: 'name', render: this.renderTitleColumn },
        { title: 'User Email', dataIndex: 'email', align: "left" },
        // { title: 'Permissions', dataIndex: 'permissions', render: (txt, __) => (txt ? txt.replaceAll(",", ", ") : ""), width: "300px" },
        { title: 'Type', dataIndex: 'acc_type', width: "100px", align: "center" },
        { title: 'Status', dataIndex: 'status', width: "80px", align: "center" },
    ];

    handleTableChange = (pagination, filters, sorter) => {
        this.props.loadMoreRows(
            pagination.pageSize,
            pagination.pageSize * (pagination.current - 1),
            this.props.queryString
            // JSON.stringify({
            //   type: this.props.accType ? { $eq: this.props.accType } : { $ne: 'any' },
            // })
        )
    };

    async fetchData(filter){
        this.setState({ busy: true });

        const resutls = await this.props.loadMoreRows(
            this.state.pagination.pageSize || defaultPageSize, 0,
            JSON.stringify({ ...defaultFilter, ...filter })
        ).then(r => {
            return r.data.usersQuery;
        })
        
        this.setState({ busy: false, pagination: { ...this.state.pagination, current: 1, total: resutls.totalCount } })
    }

    doSearch(kw) {
        this.setState({
            filter: !kw ? {} : { search: { keywords: kw } }
        }, () => {
            this.fetchData(this.state.filter)
        })
    }


    render() {
        const { loading, usersQuery, queryErrors } = this.props;
        const { pagination } = this.state;

        if (!modulePermissions['user.view']) return null;

        return (<>
            <Row>
                <Col flex="auto"><ListHeader title={"User"} totalCount={(usersQuery && usersQuery.totalCount) || '0'} /></Col>
                {modulePermissions['user.create'] &&
                    <Col><Button onClick={this.props.toggleDrawer}>Add User</Button></Col>
                }
            </Row>

            <div className='table-header' style={{ padding: "10px" }}>
                <Row align="middle">
                    <Col flex="350px"><Input.Search onSearch={(kw)=>this.doSearch(kw)} placeholder="Keyword search.." style={{ width: 200 }} enterButton allowClear /></Col>
                    {/* <Col flex="auto" align="right"><span style={{ color: 'red' }}>* Notification will be generated when a blocked person apply for the job</span></Col> */}
                </Row>
            </div>

            <Table loading={loading}
                columns={this.columns}
                dataSource={usersQuery ? usersQuery.edges : null}
                pagination={pagination}
                rowClassName={(record => {
                    return record.status == 'disabled' ? 'show-menu-on-hover disabled-table-row' : "show-menu-on-hover";
                    // return record.status == 'disabled-table-row disabled' ? 'disabled-table-row disabled-table-row' : "";
                })}
                onChange={this.handleTableChange}
                // rowClassName={(record => {
                //     return 'show-menu-on-hover';
                //     return record.status == 'hidden' ? 'disabled-table-row' : "";
                // })}
                expandable={{
                    expandedRowRender: record => {
                        let _perm = record?.permissions ? record.permissions.split(",") : [];
                        return(<Row>
                            {_perm.map((o, i)=>(<Col key={i} flex="300px" style={{borderRightWidth:'1px', borderRightColor:'#000'}}>{o}</Col>))}
                        </Row>)
                    }
                    // rowExpandable: record => record.name !== 'Not Expandable',
                }}

                // title={() => <span>Header</span>}
                // footer={() => 'Footer'}
            />
        </>)
    }
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { first: defaultPageSize, after: 0, filter: JSON.stringify({ ...defaultFilter, acc_type: { $ne: 'super-admin' } }) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, usersQuery, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.usersQuery.totalCount
                    const newEdges = fetchMoreResult.usersQuery.edges
                    const pageInfo = fetchMoreResult.usersQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        usersQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'usersQuery'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, usersQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteUser: (id) => mutate({
                variables: { id }
            }),
        })
    }),

    // graphql(UPDATE_STATUS, {
    //     props: ({ mutate }) => ({
    //         updateOrderStatus: (id, status, status_notes) => mutate({
    //             variables: { id, status, status_notes }
    //         }),
    //     })
    // }),

)(ListComp);

export default WithApollo;