import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
// import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import { FormField } from './Form'
import { __error } from 'Common/scripts/consoleHelper'
// import { Loader, IconButton, Drawer, Button, DevBlock, Heading } from 'Common/components'
import { withApollo } from '@apollo/client/react/hoc';

const GUARDS = loader('src/Graphql/guard/guards.graphql');


const GuardSearchComp = props => {
    const [list, setList] = React.useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = React.useState({});

    useEffect(() => {
        // let changer_val = JSON.stringify([props.preload, props.filter]);
        // if (changer_val == changer) return;
        // setChanged(changer_val)
        _fetchData(props.preload === true ? undefined : props.preload);

        // if (props.preload && (!list || list.length < 1)) _fetchData(props.preload === true ? undefined : props.preload)
        //   return () => { effect };
    }, [props.preload])

    var lastFetchId = 0; 
    const _fetchData = async (value) => {
        if ((!value || value.length < 1) && !props.preload) return;
        lastFetchId += 1;
        const fetchId = lastFetchId;
   
        let _filter = { ...props.filter }

        if (props.preload && (!value || value.length<1)){
            setState({ loading: true })
            if (props.preload !== true) Object.assign(_filter, props.preload)
        } else {
            setState({ kw: value, loading: true })
            if (value && value.length>0) Object.assign(_filter, { search: { keywords: value } })
        }

        let filter = JSON.stringify(_filter);


        props.client.query({ query: GUARDS, variables: { filter } }).then(e => {
            if (e.error || e.errors) {
                console.log(__error("ERROR "), e);
                message.error((e.error && e.error.message) || (e.errors && e.errors[0].message));
                setState({ kw: value, loading: false })
                return;
            }

            if (e.data.guards){
                setList(e.data.guards.map(o => {
                    let name = `${o.f_name}` + (o.m_name ? `, ${o.m_name}` : "") + (o.l_name ? `, ${o.l_name}` : "");
                    return { _id: o._id, title: `${name}`, style: { color: o.status=='active' ? 'green' : "red" }, raw: o }
                }) )
            }
            setState({ loading: false })

        }).catch(err => {
            console.log(__error("API Call ERROR: guards : "), err);
            message.error("Request ERROR");
            setState({ loading: false })
        })


    }
    const fetchData = debounce(_fetchData, 800);


    return (<>
        <FormField 
            compact={props.compact}
            coloredItems
            width={props.width}
            allowClear
            validate={props.validate}
            // type={props.multi ? 'select-multiple' : "select" }
            type={props.mode == 'multiple' ? 'select-multiple' : 'select'}
            name={props.name}
            label={props.label}
            data={list}
            placeholder="Search guards"
            addNull={props.addNull || false}
            onSelect={props.onSelect}
            // keyMap="value=>text"
            // onChange={handelChange}
            // defaultValue={props.defaultValue && props.defaultValue._id}
            inputProps={{
                // onSelect: props.onSelect || undefined, // console.log,
                onDeselect: props.onDeselect || undefined, // console.log,
                loading:state.loading,
                // mode: props.mode || undefined, // tags, multiple
                showSearch: true,
                defaultActiveFirstOption: false,
                showArrow: true,
                notFoundContent: state.loading ? <Spin size="small" /> : null,
                filterOption: false,
                onSearch: fetchData,
                // onChange:handelChange,
            }}
        />
    </>)
}
GuardSearchComp.propTypes = {
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValues: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    preload: PropTypes.bool,
    compact: PropTypes.bool,
}

export const GuardSearch = withApollo(GuardSearchComp)
export default GuardSearch;