import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ApolloWrapper from './apollo/aClient';
import moment from 'moment'
import Store from 'Store';
import { Provider } from 'react-redux';

// moment.tz.setDefault("Asia/Karachi");

ReactDOM.render(
  <Provider store={Store.store}>
    <ApolloWrapper>
      <App />
    </ApolloWrapper>
  </Provider>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();











// import React from 'react';
// import ReactDOM from 'react-dom';
// import { ApolloProvider } from 'react-apollo';
// // import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';
// import client from './apollo/aClient';
// // import moment_tz from 'moment-timezone';
// import moment from 'moment'
// import Store from 'Store';
// import { Provider } from 'react-redux';

// moment.tz.setDefault("Asia/Karachi");

// ReactDOM.render(
//   <Provider store={Store.store}>
//     <ApolloProvider client={client}>
//       <App />
//     </ApolloProvider>
//   </Provider>,
//   document.getElementById('root')
// );

// // RectDOM.render(
// //   <React.StrictMode>
// //     <App />
// //   </React.StrictMode>,
// //   document.getElementById('root')
// // );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
