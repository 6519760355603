/* eslint-disable eqeqeq */
import { sleep } from 'Common/scripts/Functions';
import React from 'react';
import { connect } from "react-redux";
import { doLogout } from 'Store/ep_admin.actions'

/**
 * Logout
 */
export const Logout = props => { // eslint-disable-line react/prefer-stateless-function

  React.useEffect(() => {
    console.log("======== Logging out ============");

    props.doLogout();

    sleep(1000).then(r=>{
      window.location.href = "/";
    })

  })

  return <div>Logging out...</div>
}

const mapStateToProps = ({ ep_admin }) => {
  return ({ ep_admin });
}
  const mapDispatchToProps = (dispatch, ownProps) => ({
    doLogout: (payload) => dispatch(doLogout(payload)),
  })
export default connect(mapStateToProps, mapDispatchToProps)(Logout);
