import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { Table, Loader, Icon, IconButton, Button, DeleteButton, Drawer, DevBlock } from 'Common/components';
import { Breadcrumb } from "antd";
import { ContentArea } from "Layout_v1";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import AssetTrackingForm from './AssetTrackingForm';
import { modulePermissions } from '.'
import SearchBar from './components/SearchBar'
import { defaultPageSize, uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import { ListHeader } from 'Common/components/Typography';
import { __error } from 'Common/scripts/consoleHelper';
import TrackDetails from './components/trackDetails';

const LIST_DATA = loader('src/Graphql/trackable_assets/trackableAssetsQuery.graphql');
const DELETE = loader('src/Graphql/trackable_assets/deleteTrackableAssets.graphql');

class AssetTrackingComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null,
            error: null,
            kw:null,
            pagination: { current: 1, pageSize: defaultPageSize },
            variables: null,
            showClientDetails: false,
            showTracks: false,

            showDrawer:false, initialData:null
        };
        this.searchList = this.searchList.bind(this)
        this.onDeleteClick = this.onDeleteClick.bind(this)
        this.fetchData = this.fetchData.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
    }
    
    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ current: pagination.current, pageSize: pagination.pageSize });
    };

    fetchData = async (args = {}) => {
        const variables = {
            first: args.pageSize || this.state.pagination.pageSize, // number of rec to fetch
            after: (args.pageSize || this.state.pagination.pageSize) * ((args.current || this.state.pagination.current) - 1),
            filter: JSON.stringify(args.filter || {})
        }

        this.setState({ loading: true, variables })

        return this.props.client.query({ query: LIST_DATA, variables, fetchPolicy:'network-only' }).then(r => {
            const response = r?.data?.trackableAssetsQuery;

            if (response.error) {
                this.setState({ loading: false, error: response.error.message });
                return;
            }

            this.setState({
                loading: false,
                data: response,
                pagination: {
                    ...this.state.pagination,
                    current: args.current || 1,
                    pageSize: args.pageSize || this.state.pagination.pageSize,
                    total: response.totalCount
                }
            });

            return response;

        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            this.setState({ loading: false, error: "Unable to complete your request at the moment!" });
            return false;
        })
    }

    searchList(value) {
        if (!value) this.fetchData({})
        else this.fetchData({ filter: value });
        // else this.fetchData({ filter: { search: { keywords: value }} });
    }

    handleDelete = async (id) => {
        if (!modulePermissions['at.delete']) return;
        
        this.setState({ loading:true })
        let results = await this.props.client.mutate({ mutation: DELETE, variables: { id: Number(id) } }).then(r => (r?.data?.deleteTrackableAssets))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })
        this.setState({ loading: false })

        if (!results || results.error) {
            message.error((results && results.error.message) || "invalid response")
            return false;
        }
        this.fetchData()
        return true;
    }

    columns = () => {
        let _cols = [
            { title: 'Title', dataIndex: 'title', render: (__, rec) => (<>
                <div onClick={() => this.setState({ showTracks: rec })} className='a'>{rec.title}</div>

                <span className='hidden-menu'>
                    {modulePermissions['at.delete'] && <DeleteButton className="item" onClick={() => this.handleDelete(rec?._id)} />}
                    {modulePermissions['at.edit'] && <IconButton icon="pen" className="item" onClick={() => this.setState({ showDrawer: rec })} />}
                </span>
            </>)},
            { title: 'Last Notes', dataIndex: 'notes' },
            { title: 'site', dataIndex: 'site', render: (__, rec) => (rec.site.name) },
            { title: 'Status', dataIndex: 'status', width: 100, align:"center" },
        ]
        return _cols;
    }

    onDeleteClick(_id) {
        if (!modulePermissions['at.delete']) return;

        this.props.client.mutate({
            mutation: DELETE, variables: { id: _id }
        }).then(r => {
            if (r.data.error) {
                this.setState({ loading: false, error: r.data.error.message });
                return;
            }

            const delIndex = this.state.data.edges.findIndex(data => data._id == r?.data?.deleteTrackableAssets?._id)
            const __data = this.state.data.edges ? this.state.data.edges.slice() : [];

            __data.splice(delIndex, 1)
            this.setState({ data: { edges: __data } })
            message.success("Record is successfully deleted!")
        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            this.setState({ loading: false, error: "Unable to delete at the moment!" });
        })
    }

    render() {
        const { loading, data, error } = this.state;
        const { pagination, showDrawer, initialData, showTracks } = this.state;

        return (<>
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item>Assets Tracking</Breadcrumb.Item>
            </Breadcrumb>

            <AssetTrackingForm showDrawer={showDrawer} initialValues={showDrawer} onSuccess={()=>{
                this.setState({ showDrawer: false })
                this.fetchData()
            }} onClose={() => this.setState({ showDrawer: false })} />

            <ContentArea>
                {modulePermissions['at.add'] && <Row>
                    <Col flex="auto">
                        <ListHeader totalCount={(data && data.totalCount) || '0'} title='Assets Tracking' />
                    </Col>
                    <Col><Button type="primary" onClick={() => this.setState({ showDrawer: true })} icon={<Icon icon='plus' />}>Add Tracking Asset</Button></Col>
                </Row>}

                {error && <Alert message="Error" description={error} type="error" showIcon />}
                
                <div className='table-header'><SearchBar fetchData={this.searchList} /></div>

                <Table loading={loading}
                    columns={this.columns()}
                    dataSource={data ? data.edges : null}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                />
            </ContentArea>


            
            <Drawer title="Asset Tracks" width={"1000px"} placement='right'
                contentWrapperStyle={{ maxWidth: "1200px" }}
                onClose={() => this.setState({ showTracks: false })}
                visible={showTracks!==false}
                bodyStyle={{ paddingBottom: 80 }}
                footer={<Button onClick={() => this.setState({ showTracks: false })}>Cancel</Button>}
                destroyOnClose={true}
            ><>
                {showTracks && <TrackDetails assetData={showTracks} />}
            </></Drawer>

        </>)
    }
}

export const AssetTracking = withApollo(AssetTrackingComp)
export default AssetTracking;
