import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import { FormField } from './Form'
import { __error, __yellow } from 'Common/scripts/consoleHelper'
import _ from 'lodash'
import { withApollo } from '@apollo/client/react/hoc';

const TAGS = loader('src/Graphql/tags/tags.graphql');


const TagsSearchComp = props => {
    const [list, setList] = useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = useState({});
    const [changer, setChanged] = useState("");

    useEffect(() => {
        let changer_val = JSON.stringify([props.preload, props.filter]);
        if (changer_val == changer) return;

        setChanged(changer_val)
        _fetchData(props.preload === true ? undefined : props.preload);
    }, [props.preload, props.filter])

    // var lastFetchId = 0;
    const _fetchData = async (value) => {
        if ((!value || value.length < 1) && !props.filter && !props.preload) return;

        let filter = { ...props.filter };

        if (value === false || !_.isString(value)) {
            setState({ loading: true })
            if (!_.isString(value)) Object.assign(filter, { ...value })
        } else {
            if (!value || value.length < 1) return;
            setState({ kw: value || undefined, loading: true })
            Object.assign(filter, { search: { keywords: value } })
        }

        filter = JSON.stringify({ ...filter, ...props.filter }); // JSON.stringify({ search: { keywords: value }, ...props.filter });

        let resutls = await props.client.query({ query: TAGS, variables: { filter } }).then(e => {
            if (e.error || e.errors) {
                console.log(__error("ERROR "), e);
                return { error:{
                    message: e?.error?.message || e?.errors[0]?.message
                }}
            }

            return e.data.tags;
        }).catch(err => {
            console.log(__error("API Call ERROR: tags : "), err);
            return { error: { message:"Request ERROR"}}
        })

        if (!resutls || resutls?.error){
            message.error(resutls.error.message);
            setState({ kw: value, loading: false })
        }
        else{
            if (resutls) setList(resutls.map(o => ({ _id: o.title, title: o.title, raw: o })))
            setState({ loading: false })
        }


    }
    const fetchData = debounce(_fetchData, 800);


    return (<>
        <FormField validate={props.validate}
            // type={props.multi ? 'select-multiple' : "select" }
            type={props.mode == 'multiple' ? 'select-multiple' : 'select'}
            name={props.name}
            label={props.label}
            data={list}
            placeholder="Search Tags"
            addNull={props.addNull || false}
            onSelect={props.onSelect}
            // keyMap="value=>text"
            onChange={(_val, _calback) => {
                let raw = list.find(o => o._id == _val);
                if (props.onChange) props.onChange(_val, raw);
                _calback(_val);
            }}
            // defaultValue={props.defaultValue && props.defaultValue._id}
            inputProps={{
                // onSelect: props.onSelect || undefined, // console.log,
                onDeselect: props.onDeselect || undefined, // console.log,
                loading: state.loading,
                mode: props.mode || undefined, // tags, multiple
                showSearch: true,
                defaultActiveFirstOption: false,
                showArrow: true,
                notFoundContent: state.loading ? <Spin size="small" /> : null,
                filterOption: false,
                onSearch: fetchData,
                // onChange:handelChange,
            }}
        />
    </>)
}
TagsSearchComp.propTypes = {
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValues: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
}

export const TagsSearch = withApollo(TagsSearchComp)
export default TagsSearch;