import React from 'react';
import { addModule } from '../connector';
import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT } from './constants'
import ThreadsHome from './Home';

export const modulePermissions = getmodulePermissions('threads');


// addReducers({ admins: reducers });
if (modulePermissions['threads.access']) {
    addModule(
        { path: `${ROOT}`, component: ThreadsHome, icon: 'chalkboard', title: "Threads", exact: 0, leftNav: true },
    )
    // addModule(
    //     { path: `${ROOT}/my_notes`, component: MyNoticeboard, title: "My Noticeboard", exact: 1, leftNav: false },
    // )
}

// if (modulePermissions['guard.view']) {
//   addModule(
//     { path: `${ROOT}`, component: Guards, icon:'user-shield', title:"Guards", exact:1, leftNav:true },
//   )
//   addModule(
//     { path: `/guard_details/:id`, component: Detail, icon: 'desktop', title: "Guard Details", leftNav: false },
//   )
// }
