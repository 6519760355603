import React, { useState, useEffect } from 'react'
import { ContentArea } from 'Layout_v1'
import { Button, DeleteButton, DevBlock, Icon, IconButton, Loader } from 'Common/components';
import { Col, DatePicker, Row, Space, Table, Radio, Tooltip, Alert } from 'antd';
import axios from 'axios';
import moment from 'moment';
import moment_tz from 'moment-timezone';
import { __yellow } from 'Common/scripts/consoleHelper';
import { toPkGMT } from 'Common/scripts/Functions';
import { CSVLink, CSVDownload } from "react-csv";

// const ZKLib = require('qr-zklib')
// const DEVICE_IP = "192.168.1.21"

export default function AttendanceDashboard() {
  const [activeTab, setActiveTab] = useState("weekly");
  const [showCsvExport, set_showCsvExport] = useState(false);
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(null);
  const [called, setCalled] = useState(false);
  const [selectedDate, set_selectedDate] = useState(moment());
  const [attendanceData, set_attendanceData] = useState(null);
  const [machineUsers, set_machineUsers] = useState(null);
  var colCount = 0;


  const getDevice = async () => {
    // let results = await axios.post(`${process.env.REACT_APP_API_URL}/zkt_attendance_handler`, formData)
    let results = await axios.post(`${process.env.REACT_APP_API_URL}/zkt_attendance_handler`, { action: "get_device" })
      .then(({ data }) => (data))

    // setResponse(results)
    console.log("getDevice > results: ", results)
    return results;

  }

  const getUsers = async () => {
    // let results = await axios.post(`${process.env.REACT_APP_API_URL}/zkt_attendance_handler`, formData)
    let results = await axios.post(`${process.env.REACT_APP_API_URL}/zkt_attendance_handler`, { action: "get_users" })
      .then(({ data }) => (data))

    // setResponse(results)
    // console.log("getUsers > results: ", results)

    set_machineUsers(results)
    return results;

  }

  const flushAttendance = async () => {
    setBusy(true)

    let results = await axios.post(`${process.env.REACT_APP_API_URL}/zkt_attendance_handler`, { action: "flush_attendance" })
      .then(({ data }) => (data))
    // .catch(onError);

    console.log("flushAttendance > results: ", results)

    setBusy(false)
  }

  const getAttendance = async () => {
    setBusy(true)
    let results = await axios.post(`${process.env.REACT_APP_API_URL}/zkt_attendance_handler`, { action: "get_attendance" })
      .then(({ data }) => (data))
    // .catch(onError);
    
    set_attendanceData(results)
    setBusy(false)
  }

  const populateAttendance = async () => {
    setBusy(true)
    setCalled(true)
    let results = await axios.post(`${process.env.REACT_APP_API_URL}/zkt_attendance_handler`, { action: "get_full_attendance" })
      .then(({ data }) => (data))
    // .catch(onError);
    // console.log("populateAttendance > results: ", results)

    if (results.error) setError(results.error.message)
    if (results.users) set_machineUsers(results.users)
    if (results.attendance) set_attendanceData(results.attendance)
    setBusy(false)
  }

  useEffect(() => {
    if (called) return;
    populateAttendance();
  }, [])

  var start_week = selectedDate.clone().startOf("week");
  var end_week = selectedDate.clone().endOf("week");

  var start_month = selectedDate.clone().startOf("month");
  var end_month = selectedDate.clone().endOf("month");

  var start_day = selectedDate.clone().startOf("day");
  var end_day = selectedDate.clone().endOf("day");


  const getTodaysData = (user, _date, _activeTab, exportRaw=false) => {
    // console.log(__yellow("getTodaysData()"), _date.format("DD-MM-YYYY"));
    if (!attendanceData) return;

    var thisData;

    if (_activeTab == "daily") {
      thisData = attendanceData.filter(o => {
        let chk1 = String(o.deviceUserId) == String(user.userId);

        let _d1 = moment(o.recordDate, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY");
        // let _d1 = moment(o.recordTime).format("DD-MM-YYYY");
        let _d2 = _date.format("DD-MM-YYYY");
        let chk2 = _d1 == _d2;

        return (chk2 && chk1);
      })
    }

    if (_activeTab == "weekly") {
      // console.log("attendanceData: ", attendanceData)

      thisData = attendanceData.filter(o => {
        let chk1 = String(o.deviceUserId) == String(user.userId);

        let _d1 = moment(o.recordDate, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY");
        // let _d1 = moment(new Date(o.recordTime)).format("DD-MM-YYYY");

        let _d2 = _date.format("DD-MM-YYYY");
        let chk2 = _d1 == _d2;

        return (chk2 && chk1);
      })
    }

    if (_activeTab == "monthly") {
      thisData = attendanceData.filter(o => {
        let chk1 = String(o.deviceUserId) == String(user.userId);

        let _d1 = moment(o.recordDate, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY");
        // let _d1 = moment(o.recordTime).format("DD-MM-YYYY");
        let _d2 = _date.format("DD-MM-YYYY");
        let chk2 = _d1 == _d2;

        return (chk2 && chk1);
      })
    }

    if (!thisData || thisData.length < 0) return null;

    return thisData.map((o, i) => {
      let _d1 = moment(o.recordDate, "DD-MM-YYYY HH:mm:ss"); // toPkGMT(o.recordDate, false)

      let isIn = o.verifyState == 0;

      if (exportRaw) return { date: _d1 }

      return (<span key={i}>
        {/* <div>{o.recordDate}</div> */}
        <Tooltip placement="topRight" color="#1B90FF" title={<>{isIn ? "Signed In" : "Signed Out"} at {_d1.format("DD-MM-YYYY HH:mm:ss")}</>}>
          <span className={`attendance-column ${isIn ? "in" : "out"}`}>
            {_d1.format("HH:mm")}
            {/* {_d1.format("Do HH:mm")} */}
          </span>
        </Tooltip>
      </span>)

    })
  }

  const getColumns = () => {
    const columns = [
      {
        title: 'User', dataIndex: 'name', width: 250,
        fixed: 'left',
        render: (txt, rec) => {
          return <div style={{ fontSize: "18px" }}>{txt}</div>; // <>{txt} (uid:{rec.uid}), userId:{rec.userId}</>
        }
      },
      // { title: 'Sun', dataIndex: 'd1', render: () => <>Day 1</> },
    ]

    if (activeTab == "daily") {
      let _start_day = start_day.clone();

      let d1 = _start_day.clone()
      columns.push({ title: _start_day.format("Do ddd"), dataIndex: `d${d1.format("DD")}`, render: (txt, rec) => getTodaysData(rec, d1, activeTab), align: "center" })
    }

    if (activeTab == "weekly") {
      let _start_week = start_week.clone();

      for (let a = 0; a < 7; a++) {
        let d1 = _start_week.clone()
        columns.push({ title: _start_week.format("Do ddd"), dataIndex: `d${a}`, render: (txt, rec) => getTodaysData(rec, d1, activeTab), align: "center" })
        _start_week.add(1, "days");
      }
    }

    if (activeTab == "monthly") {
      let _start_month = start_month.clone();
      let days = selectedDate.daysInMonth();

      for (let a = 0; a < days; a++) {
        let d1 = _start_month.clone()

        columns.push({ title: _start_month.format("Do ddd"), dataIndex: `d${a}`, render: (txt, rec) => getTodaysData(rec, d1, activeTab), align: "center", width: 100 })
        _start_month.add(1, "days");
      }
    }

    return columns;
  }
  
  const onTabChange = ({ target: { value } }) => setActiveTab(value);

  const exportAttendance = async() => {

    setBusy(true);

    let cols = []
    attendanceData.forEach(att => {
      let _d1 = moment(att.recordDate, "DD-MM-YYYY HH:mm:ss"); //.format("DD-MM-YYYY");
      let day = _d1.format('DD-MM-YYYY')

      if (!cols.includes(day)) cols.push(day);
      
    });

    let dataRows = machineUsers.map(user => {
      let _user = [user.name];

      let att = attendanceData.filter(o => String(o.deviceUserId) == String(user.userId)); //.map(o => (o?.recordDate));

      for (let index = 0; index < cols.length; index++) {
        const day = cols[index];

        let thisAtt = att.filter(oo => {
          let d1 = moment(oo.recordDate, "DD-MM-YYYY HH:mm:ss");
          let found = (day == d1.format("DD-MM-YYYY"));
          return found;
        })

        let attString = thisAtt.map(o => (`${(o.verifyState == 0) ? "IN" : "OUT"} ${o.recordDate}`))

        _user.push(attString.toString().replaceAll(",", "\n"))
      }

      return _user;
    })

    const csvData = [
      ['USER', ...cols],
      ...dataRows
    ];
    set_showCsvExport(csvData)

    setBusy(false);
  }

  return (<>
    <ContentArea transparent style={{ padding: "0" }}>
      {/* <Space>
        <Button onClick={() => getDevice()}>Get Device Details</Button>
        <Button onClick={() => getUsers()}>Get Users</Button>
        <Button onClick={() => getAttendance()}>Get Attendance</Button>
        <Button onClick={() => populateAttendance()}>Populate Attendance</Button>
        <Button onClick={() => flushAttendance()}>Flush Attendance</Button>
      </Space> */}

      <Row gutter={[10, 10]} align="bottom">
        <Col flex="auto">
          {activeTab == "daily" &&
            <Row gutter={[10, 10]} align="middle">
              <Col><IconButton onClick={() => set_selectedDate(selectedDate.clone().subtract(1, "days"))} icon="chevron-left" /></Col>
              <Col><DatePicker value={selectedDate} onChange={(_dates) => set_selectedDate(_dates)} picker="day" /></Col>
              <Col><IconButton onClick={() => set_selectedDate(selectedDate.clone().add(1, "days"))} icon="chevron-right" /></Col>
              <Col>{selectedDate.format("DD-MM-YYYY")}</Col>
            </Row>
          }
          {activeTab == "weekly" &&
            <Row gutter={[10, 10]} align="middle">
              <Col><IconButton onClick={() => set_selectedDate(selectedDate.clone().subtract(7, "days"))} icon="chevron-left" /></Col>
              <Col><DatePicker value={selectedDate} onChange={(_dates) => set_selectedDate(_dates)} picker="week" /></Col>
              <Col><IconButton onClick={() => set_selectedDate(selectedDate.clone().add(7, "days"))} icon="chevron-right" /></Col>
              <Col>{start_week.format("DD-MM-YYYY")} ~ {end_week.format("DD-MM-YYYY")}</Col>
            </Row>
          }
          {activeTab == "monthly" &&
            <Row gutter={[10, 10]} align="middle">
              <Col><IconButton onClick={() => set_selectedDate(selectedDate.clone().subtract(1, "months"))} icon="chevron-left" /></Col>
              <Col><DatePicker value={selectedDate} onChange={(_dates) => set_selectedDate(_dates)} picker="month" /></Col>
              <Col><IconButton onClick={() => set_selectedDate(selectedDate.clone().add(1, "months"))} icon="chevron-right" /></Col>
              <Col>{selectedDate.format("MMMM-YYYY")}</Col>
            </Row>
          }
        </Col>
        {machineUsers && <Col><Button disabled={busy} onClick={() => exportAttendance()}>Export Attentance</Button></Col>}
        
        {showCsvExport && <CSVLink
          data={showCsvExport}
          filename='attendance_records.csv'
        >
          <span style={{ fontSize: '12px' }}>Download CSV </span> <Icon icon="file-csv" size="1x" />
        </CSVLink>}

        <Col>
          <Radio.Group
            options={[
              { label: 'Daily', value: 'daily', },
              { label: 'Weekly', value: 'weekly', },
              { label: 'Monthly', value: 'monthly', },
            ]}
            onChange={onTabChange}
            value={activeTab}
            optionType="button"
            buttonStyle="solid"
          />
        </Col>
      </Row>

      {/* {busy && <Loader loading={busy} />} */}
      {error && <Alert message={error} type='error' showIcon />}

      <Table
        loading={busy}
        bordered
        size='small'
        columns={getColumns()}
        dataSource={machineUsers}
        title={() => <span>Attendance</span>}
        // footer={() => 'Footer'}
        rowKey={(record, i) => {
          colCount++;
          return `${record.uid}_${i}_${colCount}`;
        }}
        pagination={false}
        // scroll={{
        //   x: 1000,
        //   y: 600
        // }}
      // pagination={{ pageSize: 1000 }}
      />

    </ContentArea>

  </>
  )
}
