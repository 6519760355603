import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Alert, Input } from 'antd';
import { Table, Loader, Button } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import { withApollo } from '@apollo/client/react/hoc';
import { VALUE_KEY } from './constants';

const LIST_DATA = loader('src/Graphql/addressbooks/addressbooks.graphql');

const ContactsMiniListComp = props => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);

    const fetchData = async (args = {}) => {
        if (!args?.search?.keywords) return;

        const variables = { filter: JSON.stringify({ ...args, category: VALUE_KEY }) }
        setLoading(true);

        const results = await props.client.query({ query: LIST_DATA, variables }).then(r => {
            const response = r.data.addressbooks;
            return response;
        }).catch(err => {
            console.log(__error("ERROR: "), err);
            return { error: { message:"Unable to complete your request at the moment!"}};
        })

        if (results.error) return setError(results.error.message);

        setLoading(false);
        setData(results);

    }
      
    if (error) return <Alert message="Error" description={error} type="error" showIcon />

    return (<>
        <div className='search-bar'>
            <Input.Search placeholder="input search text"
                onSearch={(val) => fetchData({ search: { keywords: val } })}
                // style={{ width: 200, }}
                loading={loading}
            />
        </div>

        <div className='list-body'>
            {loading && <Loader loading={true} />}

            {(!loading && (!data || data.length<1)) && <>
                <p style={{textAlign:"center"}}>No records available</p>
            </>}

            {(!loading && data && data.length > 0) && data.map((rec, i)=>{
                return <div key={i} style={{borderBottom:"1px solid black"}}>
                    <b>{rec.name}</b>
                    {rec.phones.map((o, i) => {
                        return (<div key={i}><Button onClick={() => props.onItemClick({ phone:o, name:rec.name })} type="link" key={i}>{o}</Button></div>)
                    })}
                </div>
            })}
        </div>

    </>)

}

export const ContactsMiniList = withApollo(ContactsMiniListComp);
export default ContactsMiniList;