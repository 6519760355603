import { sleep } from 'Common/scripts/Functions';
import { call, put, all, takeEvery, takeLatest, fork, select } from 'redux-saga/effects'
import { DO_LOGOUT, SET_BUSY, SET_ERROR, UPDATE_STATE, DO_LOGIN } from '../ep_admin.actions';
import { uriRoot } from 'configs';
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper';
import { message } from 'antd';
import axios from 'axios';

const LOGIN_QUERY = loader('src/Graphql/user/userLogin.graphql');


/*********** Saga Exmaples */
// yield client.query({
//     query: GET_PROD,
//     variables:{},
//     // fetchPolicy: "cache-first",
// }).then(result => {
//     console.log("GET_PROD: ", result);
//     // result.data
//     // result.loading
//     return result;
// }).catch(err => {
//     console.log(__error("Query Error: "), err);
// });

export function* testSaga() {
    console.log('Hello Test Sagas!')
    yield sleep(100);
}

export function* testSagaSync(payload) {
    console.log('Hello Test Async Sagas!', payload)

    // yield put({ type: SET_BUSY, payload: true });

    console.log("Async start");
    yield sleep(3000);
    console.log("Async end");

    // yield put({ type: SET_BUSY, payload: false });
    // yield put({ type: UPDATE_JOB_QUEUE, payload: jobs });
}





export const getAppState = (state) => state.nav;
export const getAppData = (state) => state.ep_admin;



export function* logoutUser({ type, payload }) {
    console.log('logoutUser()')

    // let app_state = yield select(getAppState); // <-- get the project
    // let app_data = yield select(getAppData); // <-- get the project

    yield put({ type: DO_LOGOUT, payload: { } });

    yield sleep(50)
    window.location = uriRoot;

    return;
}

export function* loginUser({payload, type}) {
    console.log('loginUser()', payload)

    yield put({ type: UPDATE_STATE, payload: { busy:true, error:null } });

    const results = yield axios({
        method: 'put',
        url: process.env.REACT_APP_API_URL + '/user/login/admin',
        data: { ...payload, username:payload.email, app: "web-admin" }
    })
        .then(async function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(__error("ERROR: "), error);
            return { error: { message: 'Netowrk Error' } }
        });



    // // yield sleep(3000);
    // const results = yield aClient.query({ query: LOGIN_QUERY, variables: { ...payload } }).then(r => {
    //     if (r.data.userLogin?.error?.message) return { error: r.data.userLogin?.error?.message }
    //     return r.data.userLogin;

    //     // if (r.data.userLogin.error) {
    //     //     // this.setState({ busy: false, error: r.data.userLogin.error.message });
    //     //     yield put({ type: UPDATE_STATE, payload: { busy: false, error: r.data.userLogin.error.message } });
    //     // }
    //     // else {
    //     //     yield put({ type: UPDATE_STATE, payload: { busy: false } });
    //     //     yield put({ type: DO_LOGIN, payload: r.data.userLogin });
    //     //     // this.props.doLogin(r.data.userLogin)
    //     // }

    // }).catch(err => {
    //     console.log(__error("ERROR: "), err);
    //     // yield put({ type: UPDATE_STATE, payload: { busy: false, error: "Unable to login at the moment!" } });
    //     return { error: "Unable to login at the moment!" }
    // })

    if (results.error) return yield put({ type: UPDATE_STATE, payload: { busy: false, error: results.error.message } });
    if (!results.user) return yield put({ type: UPDATE_STATE, payload: { busy: false, error: "User not found" } });
    if (!results.token) return yield put({ type: UPDATE_STATE, payload: { busy: false, error: "User token not found" } });

    yield put({ type: UPDATE_STATE, payload: { busy: false, error:false } });
    yield put({ type: DO_LOGIN, payload: results });
    yield sleep(50)
    window.location = uriRoot;


    // if (results.error){
    //     yield put({ type: UPDATE_STATE, payload: { busy: false, error: results.error.message } });
    // }else{
    //     yield put({ type: UPDATE_STATE, payload: { busy: false } });
    //     yield put({ type: DO_LOGIN, payload: results });
    //     yield sleep(50)
    //     window.location = uriRoot;
    // }

    console.log("Async end");

    // yield put({ type: SET_BUSY, payload: false });
    // yield put({ type: UPDATE_JOB_QUEUE, payload: jobs });
}
