import React, { Component } from 'react'
import diff from 'object-diff'
import { FormSpy, Form, Field } from 'react-final-form'

/******* Usage
 * 
 * <AutoSaveForm debounce={100} save={onSubmit} />
 */
class AutoSave extends React.Component {
    constructor(props) {
        super(props)
        this.state = { values: props.values, submitting: false }
    }

    componentWillReceiveProps(nextProps) {
        // if (nextProps.busy) return;

        if (this.timeout) {
            clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(this.save, this.props.debounce)
    }

    save = async () => {
        if (this.promise) {
            await this.promise
        }

        const { values, save } = this.props

        // this.setState({ submitting: true, values })
        // this.promise = save(values)
        // await this.promise
        // delete this.promise
        // this.setState({ submitting: false })

        // This diff step is totally optional
        const difference = diff(this.state.values, values)
        if (Object.keys(difference).length) {
            // values have changed
            this.setState({ submitting: true, values })
            // this.promise = save(difference)
            this.promise = save(values)
            await this.promise
            delete this.promise
            this.setState({ submitting: false })
        }
    }

    render() {
        return null
    }
}

export default props => (
    <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />
)
