export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_PAGE_SETTINGS = 'UPDATE_PAGE_SETTINGS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DO_LOGOUT = 'DO_LOGOUT';
export const DO_LOGIN = 'DO_LOGIN';
export const SET_BUSY = 'SET_BUSY';
export const SET_ERROR = 'SET_ERROR';
export const UPDATE_STATE = 'UPDATE_STATE';

export const updateState = payload => ({ type: UPDATE_STATE, payload });
export const setError = payload => ({ type: SET_ERROR, payload });
export const setBusy = payload => ({ type: SET_BUSY, payload });
export const doLogin = payload => ({ type: DO_LOGIN, payload });
export const doLogout = () => ({ type: DO_LOGOUT });
export const updateProfile = payload => ({ type: UPDATE_PROFILE, payload });

export const updatePageSettings = (payload) => ({ type: UPDATE_PAGE_SETTINGS, payload });
export const updateSettings = payload => ({ type: UPDATE_SETTINGS, payload });
