import React, { useState } from 'react'
import { verifyRole } from 'Common/scripts/Security'
import { withApollo } from '@apollo/client/react/hoc'
import { loader } from 'graphql.macro';
import { message, Popover } from 'antd';
import { Button, Icon, IconButton, Loader } from 'Common/components';
import { flagsArray } from 'configs';

const EDIT_FLAG = loader('src/Graphql/HoursSchedules/hoursSchedules_flagRecord.graphql')


const FlagButtonComp = ({ data, user_id, onRecord, client }) => {
    const [busy, setBusy] = useState(false)
    const [selected, selectFlag] = useState(data.flag || 'normal')

    if (!user_id) return <p>Missing user ID</p>

    const setFlag = async (flag) => {
        if (!verifyRole('hours-schedule.update')) return;
        if (!flag) { alert("Missing Flag"); return; }

        const variables = { id: data._id, by: user_id, flag }

        setBusy(true);
        const _response = await client.mutate({ mutation: EDIT_FLAG, variables }).then(r => {
            return r.data.hoursSchedules_flagRecord;
        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            return { error: { message: "Failed to update flag" } };
        })
        setBusy(false);

        if (!_response || _response.error) {
            message.error(!_response ? "Invalid response" : _response.error.message);
            return false;
        }

        selectFlag(flag)
        message.success("Flag updated");
        if (onRecord) onRecord(_response);
        return false;

    }


    return (<Loader loading={busy}>
        <Popover content={<div>
            {flagsArray.map((o, i) => {
                let _selected = o._id == selected;
                return <Button 
                    onClick={() => setFlag(o._id, data._id)} ghost={_selected} 
                    type={_selected ? "primary" : "dashed"} icon={<Icon icon="flag" color={o.color} style={{ marginRight: '5px' }} />} key={i}>{o.title}</Button>
            })}
        </div>}>
            <IconButton icon={{ icon: "flag", color: flagsArray.find(f => f._id == selected).color }} />
        </Popover>
    </Loader>)

}

export const FlagButton = withApollo(FlagButtonComp)
export default FlagButton