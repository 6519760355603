import React, { Component, useState, useEffect } from 'react'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Button, Icon } from 'Common/components'
import { message, Row, Col } from 'antd';
import { connect } from "react-redux";
import { updatePageSettings } from 'Store/ep_admin.actions.js'
// import { formArray } from '..';


const FormDataSearchBar = props => {
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);

    const filter = props?.ep_admin?.pageSettings?.formdata_searchBar;
    
    // remove isDuplicate filter
    // useEffect(() => {
    //     let f = props?.ep_admin?.pageSettings?.formdata_searchBar;
    //     let keys = Object.keys(f);
    //     if (keys.indexOf('isDuplicate') > -1) {
    //         delete f.isDuplicate;
    //         props.updatePageSettings({ page: 'formdata_searchBar', f })
    //     }

    // }, [props?.ep_admin?.pageSettings?.formdata_searchBar])


    useEffect(() => {
        onSubmitSearch(filter || {})
        // if (filter) {
        // } else {
        //     message.info("Please adjust the filter to get records.");
        // }
        //   return () => {
        //     effect
        //   };
    }, [])

    const onSubmitSearch = async (values, redux) => {
        setBusy(true);
        let resutls = await props.fetchData({ filter:values });
        setBusy(false);

        props.updatePageSettings({ page: 'formdata_searchBar', values })

        // return true;
    }

    let defaultValues = { 
        // ref_key: ref_keys[0]._id, 
        ...props.ep_admin?.pageSettings?.formdata_searchBar 
    };

    return (<>
        <FormComponent onSubmit={onSubmitSearch} id='FormDataSearch' loading={busy} debug={false}
            fields={{ ...defaultValues }}
            form_render={formProps => {
                const { values, form, invalid, dirty, valid } = formProps;

                return (<div>
                    <Row style={{flexWrap:"nowrap"}}>
                        {/* <Col flex='300px'>
                            <FormField label="Record Category" name="ref_key" placeholder={"Select Record Category"} size="medium" type="select" data={formArray.map(o => ({ _id: o.key, title: o.menuTitle }))} validate={rules.required} />
                        </Col> */}
                        <FormField name="search.keywords" placeholder={"Keyword search.."} size="medium" type="text" allowClear compact />
                        <Button type="primary" htmlType='submit'><Icon icon="search" /></Button>
                    </Row>
                </div>)
            }}
        />

    </>)


}
const mapStateToProps = ({ ep_admin }) => {
    // console.log("ep_admin: ", ep_admin.pageSettings.formdata_searchBar)
    return ({ ep_admin });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormDataSearchBar)

