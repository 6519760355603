import React, { useState } from 'react'
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { Col, Row, Space, Modal, Alert, message, Select } from 'antd';
import { Button, ValuePairsSearch } from 'Common/components';
import { defaultDateFormat, driving_license_classes, guard_status } from 'configs';
import { dateToMongo, removeSpecialChar } from 'Common/scripts/Functions';
import { verifyRole } from 'Common/scripts/Security';

const UPDATE_GUARD = loader('src/Graphql/guard/editGuard.graphql');

const BasicProfileForm = ({ client, data, onCloseClick, onUpdate }) => {
    const onSubmit = async (values) => {
        // console.log("onSubmit()", values)

        const input = {
            _id: data._id,
            f_name: values.f_name,
            m_name: values.m_name,
            l_name: values.l_name,
            email: values.email,
            home_address: values.home_address,
            phone: values.phone,
            whatsapp: values.whatsapp,
            skills: values.skills,
            security_license_no: values.security_license_no,
            security_license_expiry: values.security_license_expiry ? dateToMongo(values.security_license_expiry) : undefined,
            driving_license: values.driving_license,
            driving_license_expiry: values.driving_license_expiry ? dateToMongo(values.driving_license_expiry) : undefined,
            covid_vaccinated: values.covid_vaccinated ? 'yes' : 'no',
            vehicle: values.vehicle ? 'yes' : 'no',
            ppe: values.ppe ? 'yes' : 'no',
        }
        if (verifyRole('guard.sensitive-data')) Object.assign(input, { sin: values.sin || "" })


        const results = await client.mutate({ mutation: UPDATE_GUARD, variables: { input } }).then(e => {
            return e.data.editGuard;
        }).catch(err => {
            console.log(__error("Request Error: "), err);
            return { error: { message: "Request ERROR" } }
        })

        if (results.error){
            message.error(results.error.message);
            return false;
        }

        onUpdate(results);
        onCloseClick()
        return results;

    }

    const filterString = (e, onChange) => onChange(removeSpecialChar(e.target.value, { filter: { space: true } }));

    return (<>
        <FormComponent onSubmit={onSubmit} id='GuardBasicProfileForm' 
            fields={{ 
                ...data, 
                covid_vaccinated: data.covid_vaccinated=='yes',
                vehicle: data.vehicle =='yes',
                ppe: data.ppe =='yes',
            }} 
            debug={false} 
            style={{ padding:0, margin:0}}
            form_render={formProps => {
                const { handleSubmit, submitting, values, form, invalid, dirty, valid } = formProps;

                return (<>
                    <p style={{ fontSize:"22px" }}>Editing basic profile</p>

                    <Space style={{width:"100%"}} direction="vertical">
                        <Row>
                            <Col flex={"33%"}><FormField label="First name *" name="f_name" type="text" validate={composeValidators(rules.required, rules.maxChar(32))} maxLength={32} compact /></Col>
                            <Col flex={"auto"}><FormField label="Middle name (optional)" name="m_name" type="text" validate={composeValidators(rules.maxChar(32))} maxLength={32} compact /></Col>
                        </Row>
                        <FormField label="Last name *" name="l_name" type="text" validate={composeValidators(rules.required, rules.maxChar(32))} maxLength={32} compact />
                        <FormField label="Email Address" name="email" type="email" validate={composeValidators(rules.required, rules.maxChar(32))} maxLength={32} compact />
                        <FormField label="Home Address" name="home_address" type="text" compact />

                        <Row gutter={[0, 8]}>
                            <Col flex={"50%"}><FormField label="Phone" name="phone" type="text" validate={composeValidators(rules.required, rules.maxChar(32))} compact /></Col>
                            <Col flex={"50%"}><FormField label="Whatsapp" name="whatsapp" type="text" validate={composeValidators(rules.maxChar(32))} compact /></Col>

                            {/* <Col flex={"100%"}><FormField label="Skills" name="skills" type="text" compact /></Col> */}
                            <Col flex={"100%"}>
                                <ValuePairsSearch compact
                                    keyMap="values=>title"
                                    multiselect
                                    preload key_value="employee_skills"
                                    name="skills"
                                    label="Employee skills"
                                    style={{ width: "100%" }}
                                />
                            </Col>

                            <Col flex={"50%"}><FormField label="Security license no " onChange={filterString} maxLength={10} name="security_license_no" type="text" 
                                validate={composeValidators(rules.required, rules.maxChar(10))} compact /></Col>
                            <Col flex={"50%"}> <FormField style={{ width: "100%" }} name="security_license_expiry" type="date" size="medium" label="Security license expiry" placeholder={defaultDateFormat} compact /></Col>

                            {verifyRole('guard.sensitive-data') &&
                                <Col flex={"100%"}><FormField label="Social insurance no (SIN)" onChange={filterString} name="sin" type="text" 
                                    validate={composeValidators(rules.maxChar(10))} maxLength={10}
                                compact /></Col>
                            }

                            <Col flex={"50%"}><FormField size="medium" name="driving_license" type="select" label="Driving license" placeholder="SELECT" compact
                                data={driving_license_classes.map(o => ({ _id: o, title: o }))}
                            /></Col>
                            <Col flex={"50%"}> <FormField style={{ width: "100%" }} name="driving_license_expiry" type="date" size="medium" label="Driving license expiry" placeholder={defaultDateFormat} compact /></Col>
                        </Row>

                    </Space>

                    <FormField name="covid_vaccinated" checked={values.covid_vaccinated} type="checkbox">Covid vaccinated</FormField>
                    <FormField name="vehicle" checked={values.vehicle} type="checkbox">Have vehicle</FormField>
                    <FormField name="ppe" checked={values.ppe} type="checkbox">Have PPE</FormField>

                    <div style={{ height: "20px" }} />
                    <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} loading={submitting} >Update</Button>

                </>)
            }}
        />

    </>)
}
export default withApollo(BasicProfileForm);