import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Alert, Row, Col, Select, DatePicker, Input } from 'antd';
import { __error, __yellow } from 'Common/scripts/consoleHelper'
import { min2Hrs, getTimeDiff } from 'Common/scripts/Functions'
// import { loader } from 'graphql.macro';
import { CSVLink, CSVDownload } from "react-csv";
import { Loader, Icon, IconButton, Button, DevBlock } from 'Common/components';
import _ from 'lodash'

function groupBySiteId(data) {
  let __data = []
  data.forEach(row => {
    // get all records for this ID/
    if (row.approval_status == "new") return;

    let li = data.filter(o => {
      if (!(o.site_id == row.site_id)) return false;
      if (!(o?.prod_and_services?._id == row?.prod_and_services?._id)) return false;
      if (!(o?.services?.service_type?._id == row?.services?.service_type?._id)) return false;
      if (!(o?.lead?._id == row?.lead?._id)) return false;

      return true;
    })

    // add record if site ID not exists
    if (!__data.find(o => {
      if (!(o.site_id == row.site_id)) return false;
      if (!(o?.prod_and_services?._id == row?.prod_and_services?._id)) return false;
      if (!(o?.services?.service_type?._id == row?.services?.service_type?._id)) return false;
      if (!(o?.lead?._id == row?.lead?._id)) return false;
      // o.site_id == row.site_id && o?.prod_and_services?._id == row?.prod_and_services?._id
      return true;
    })) 
      __data.push({ 
        site_id: row.site_id, 
        rows: li,
        prod_and_services: row?.prod_and_services
      });
  });

  /*
    data.forEach(row => {
    console.log("groupBySiteId: ", row)

    // get all records for this ID/
    let li = data.filter(o => o.site_id == row.site_id && o?.prod_and_services?._id == row?.prod_and_services?._id)

    // add record if site ID not exists
    if (!__data.find(o => o.site_id == row.site_id && o?.prod_and_services?._id == row?.prod_and_services?._id)) 
      __data.push({ 
        site_id: row.site_id, 
        rows: li,
        prod_and_services: row?.prod_and_services
      });
  });
  */
  return __data;
}

function calculateGroupHours(group) {
  let data = group.map(t => {
    let difference = getTimeDiff(t.check_in, t.check_out, 'split');
    let decimalHrs = Number(difference.decimalHrs).toFixed(2);

    return {
      ...difference,
      // in: t.check_in,
      // out: t.check_out,
      decimalHrs,
      stat: t.stat
    }
  })
  // calculate regular min
  let regularMin = 0;
  data.forEach(row => {
    regularMin += row.minDiff;
  });

  let hrs = min2Hrs(regularMin);
  return hrs;

}

const createColumnData = ({ col, data, indexes, updatedValues }) => {

  if (!data?.rows || !col.field) return null;
  let thisRow = data.rows[0];
  let thisSite = thisRow.site;
  // let thisGuard = thisRow.guard;
  let thisClient = thisRow.client;
  
  // ERROR: Client relation with site is missing
  if (col.title == '*Schedule' && !thisClient){
    console.log(__error("Client not found for site: "), thisSite.name)
    return { value: <b style={{ color: 'red' }}>{`Missing client for ${thisSite.name}`}</b> }
  }
  
  let regularTimes = calculateGroupHours(data.rows);

  let temp = null;
  try {
    let field = updatedValues ? updatedValues.field : null;
    if (!field) field = (col.field && col.field.length > 1) ? col.field : null;

    if (col.func) temp = col.func(data);
    else if (col.field == 'function'){}
    else if (col.field == 'date') {
      return { value: updatedValues ? updatedValues.field : "" }
    }
    else if (field) temp = eval(field); //(col.field && col.field.length > 1) ? eval(col.field) : null;

  } catch (error) {
    console.log(__error("Eval Error: "), error)
  }
  
  function evalValue(val) {
    let temp = null;
    try {
      temp = eval(val);
    } catch (error) {
      console.log(__error("Eval Error: "), error)
    }
    return temp;
  }

  if (col.options) {
    let _options = col.options.slice();
    _options = _options.map(o => ({ ...o, value: evalValue(o.field) })).filter(o => (o.value && o.value.length > 0));

    temp = { value: temp }
    Object.assign(temp, { options: _options });
  }

  return (!temp || _.isString(temp)) ? { value: temp } : temp;

}
const Column = ({ col, data, indexes, updateExportSettings }) => {
  let colData = createColumnData({col, data, indexes});
  if (!colData) return colData;

  let field = (colData.options && colData.options[0] && colData.options[0].field) ? colData.options[0].field : null;

  if (colData.options) {
    return (<Select defaultValue={field} style={{ width:"100%", minWidth: 200, textAlign: "left" }} onChange={(__, vals) => updateExportSettings(vals, indexes)}>
      {colData.options.map((o, i) => {
        return <Select.OptGroup label={o.label} key={i}>
          <Select.Option value={o.field}>{o.value}</Select.Option>
        </Select.OptGroup>
      })}
    </Select>)
  }

  if (col.field == 'date') return (<DatePicker onChange={(__, vals) => updateExportSettings({ value:vals }, indexes)} />)

  return colData ? colData?.value || null : null;

}


export default class InvoicePreview extends Component {
  state = { data: null, error: false, loading: false, csvData: null, groupdData:null,
    gst: 5,
    updatedValues:[],
  };
  constructor(props){
    super(props);
    this.prepareData = this.prepareData.bind(this);
    this.updateExportSettings = this.updateExportSettings.bind(this);
    this.exportSettings = this.exportSettings.bind(this);
  }

  componentDidMount() {
    // this.fetchData()
    if (!this.props.data) return;
    this.prepareData(this.props.data)
  }

  exportSettings = () => [
    { title: "*InvoiceNo", value: "", width: 100, field: "function", func: (row) => (`0000`) },
    {
      title: "*Customer", value: "", width: 300, field: "thisClient?.billing_name", options: [
        { field: "thisClient?.billing_name", label: "Client Billing Name" },
        { field: "thisSite?.billing_name", label: "Site Billing Name" }
      ]
    },
    { title: "*InvoiceDate", value: "", width: 150, field: "date" },
    { title: "*DueDate", value: "", width: 150, field: "date" },
    { title: "Terms", value: "", field: "function", width: 200, func: (row) => (`Due on receipt`) },
    { title: "Location", value: "", field: "" }, // field: "thisSite?.address"
    { title: "Memo", value: "", field: "", width: 300 },
    // { title: "Item(Product/Service)", value: "", field: "data?.prod_and_services?.title" },
    { title: "SERVICE DATE", value: "", field: "" },
    {
      title: "Item(Product/Service)", value: "", width: 300, field: "function", func: (row) => {
        let data = row.rows[0];
        if (data?.lead?.service_type?._id) return `${data.lead.service_type.title}` // title, rate
        if (data?.service?.service_type?._id) return `${data.service.service_type.title}` // title, rate
      }
    },
    { title: "ItemDescription", value: "", field: "", width: 300 },
    { title: "ItemQuantity", value: "", field: "regularTimes?.decimalHrs.toFixed(2) || 0" },
    {
      title: "ItemRate", value: "", field: "function", func: (row) => {
        let data = row.rows[0];
        return `${(data && data?.service?.rate) || 0}`;
      }
    },
    {
      title: "*ItemAmount", value: "", field: "Number((regularTimes?.decimalHrs || 0) * (thisRow?.service?.rate || 0)).toFixed(2)",
      // func: (row) => {
      //   let data = row.rows[0]?.service?.rate || 0;
      //   let regularTimes?.decimalHrs.toFixed(2) || 0
      //   //  return `${data * }`
      // }
    },
    { title: "*ItemTaxCode", value: "", field: "function", func: (row) => (`GST`) },
    { title: "ItemTaxAmount", value: "", field: `Number((Number((regularTimes?.decimalHrs || 0) * (thisRow?.service?.rate || 0)).toFixed(2) / 100 ) * ${this.state.gst || 0}).toFixed(2)` }
  ]

  async prepareData(data) {
    // console.log("prepareData()")
    let groupdData = groupBySiteId(data);
    this.setState({ loading: false, groupdData });
  }

  createCSVData(data) {
    let csvData = []

    // setting up Header
    let csv_header = this.exportSettings().map(o=>o.title); // [];
    csvData.push(csv_header);

    let __info = this.state.groupdData.map((row, i) => {
        return this.exportSettings().map((col, ii) => {
          let updatedValues = this.state.updatedValues.filter(o => (o.row == i && o.col == ii));

          let d = createColumnData({ 
              col, 
              data: row, 
              indexes: { row: i, col: ii },
              updatedValues: updatedValues ? updatedValues[0] : undefined
            });
          return d ? d.value : null;
        })
    })

    // console.log("__info: ", __info)
    csvData = csvData.concat(__info)
    this.setState({ csvData })

    console.log("csvData: ", csvData)

  }

  updateExportSettings(vals, indexes) {
    // console.log("updateExportSettings() ", { vals, indexes });

    // let value = vals.children;
    let field = vals.value;

    let updatedValues = this.state.updatedValues.slice();
        updatedValues = updatedValues.filter(o => {
          let r = o.row == indexes.row && o.col == indexes.col;
          // console.log(`${o.row} != ${indexes.row} && ${o.col} != ${indexes.col}: `, r);
          return !r;
        })

    updatedValues.push({
      row: indexes.row,
      col: indexes.col,
      field
    })

    this.setState({ updatedValues, csvData:null })
    // Object.assign(exportSettings, {})
  }


  render() {
    const { data, error, loading, csvData, groupdData } = this.state;

    if (loading) return <Loader loading={true} />
    // if (!data) return <Alert message="No data found" type="error" showIcon />
    if (error) return <Alert message="Error" description={error} type="error" showIcon />
    if (!groupdData) return <p>No Group Data available</p>

    var tableWidth = 0;
    this.exportSettings().forEach(col => {
      tableWidth += Number(col.width || 150);
    });

    let unapprovedCount = this.props.data.filter(o => o.approval_status != "aproved")?.length || 0;

    return (<>
      <h4>Invoice
        {unapprovedCount > 0 && <Alert message={`${unapprovedCount} unapproved record(s) excluded`} type='warning' showIcon />}
      </h4>


      <div style={{ position: "relative", width:"100%", padding:"5px 0" }}>
        <Row style={{ backgroundColor: "#FFFFFF" }}>
          <Col>
            <div className='form-field input '>
              <Row align="middle" gutter={[5]}>
                <Col>GST: </Col>
                <Col><Input type='number' min={0} max={100} defaultValue={5} onChange={({ target }) => {
                  this.setState({ gst: target.value || 0, csvData:null }, () => this.prepareData(this.props.data))
                  }} /></Col>
                <Col>%</Col>
              </Row>
            </div>
          </Col>
          <Col flex="auto" align="right">
            {!csvData && <Button onClick={()=>this.createCSVData()}>Create CSV</Button>}
            {csvData && <span style={{ border: '1px solid blue', borderRadius: '5px', padding: '4px 10px', display: 'inline-block' }}>
              <CSVLink data={csvData} filename='site_invoice_hours.csv'><span style={{ fontSize: '12px' }}>Download CSV </span> <Icon icon="file-csv" size="1x" /></CSVLink>
            </span>}
          </Col>
        </Row>
      </div>

      <div style={{ border: "1px solid #EEE", overflowX: "scroll", display: "block", height: "calc(100vh - 150px)", width: "100%" }}>
        <table className='dataTable' style={{ overflowX: "scroll", width: `${tableWidth}px` }}>

          <thead>
            <tr>
              {this.exportSettings().map((col, i) => {
                return <th key={i} style={{ width: `${col.width || 150}px` }}>{col.title}</th>
              })}
            </tr>
          </thead>

          <tbody>
            {groupdData.map((row, i)=>{
              return <tr key={i}>
                {this.exportSettings().map((col, ii) => <td key={ii}><Column col={col} data={row} indexes={{ row: i, col: ii }} updateExportSettings={this.updateExportSettings} /></td> )}
              </tr>
            })}
          </tbody>
          
        </table>
      </div>

      {/* <DevBlock obj={this.state.updatedValues} />
      <DevBlock obj={this.state.csvData} /> */}

    </>)


  }
}


InvoicePreview.propTypes = {
  data: PropTypes.array.isRequired,
  filter: PropTypes.string,
  // sites: PropTypes.array.isRequired,
}