import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Popconfirm, Input, message, Row, Col, Alert, Tag, Divider } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, DevBlock, DeleteButton, BackButton, TagsAvailable } from 'Common/components';
import { connect } from "react-redux";
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
// import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { modulePermissions } from '../'
import { ListHeader } from 'Common/components/Typography';
import KnowledgebasesForm from './KnowledgebasesForm'
import ArticlePreview from './ArticlePreview'

const LIST_DATA = loader('src/Graphql/Knowledgebases/knowledgebasesQuery.graphql');
const RECORD_DELETE = loader('src/Graphql/Knowledgebases/deleteKnowledgebase.graphql');

// const defaultPageSize = 5

const ListComp = props => {
    const [{ visible, fields }, setState] = React.useState({ visible: false, fields: null });
    const [busy, setBusy] = useState(false)
    const [showPreview, set_showPreview] = useState(false)
    // const [filter, setFilter] = useState({})
    // const [pagination, setPagination] = useState({ first: defaultPageSize, after: 0, filter: props.filter || {}, others: { sort: { title: -1 } } })
    const [pagination, setPagination] = useState({ 
        // first: defaultPageSize, after: 0, 
        current: 1, 
        pageSize: defaultPageSize,
        total: 0,
        filter: props.filter || {}, 
        others: { sort: { title: -1 } }
    })
    const [queryResults, set_queryResults] = useState(null)

    const [knowledgebasesQuery, { called, loading, data }] = useLazyQuery(
        LIST_DATA, { variables: { ...pagination, filter: JSON.stringify(pagination.filter), others: JSON.stringify(pagination.others) } }
    );

    const [deleteKnowledgebase, delete_details] = useMutation(RECORD_DELETE);

    useEffect(() => {
        if (called || loading || data) return;

        fetchData({})
    }, [])

    const toggleDrawer = args => {
        setState({ visible: !visible, fields: args.fields || null });
    };

    const fetchData = async (args={}) => {
        setBusy(true);

        let filter = (args && args.filter) || pagination.filter || {};
        let others = (args && args.others) || pagination.others || {};
        // let first = (args && args?.pagination?.first) || pagination.first || defaultPageSize;
        // let after = (args && args?.pagination?.after) || pagination.after || 0;
        let first = defaultPageSize; // (args && args.pageSize) || (pagination && pagination.pageSize) || defaultPageSize; // number of rec to fetch
        let after = defaultPageSize * (((args && args.current) || pagination.current) - 1); // (args.pageSize || pagination.pageSize) * ((args.current || pagination.current) - 1);

        if (props.filter) Object.assign(filter, { ...props.filter })

        let variables = {
            first,
            after,
            filter: JSON.stringify({ ...filter, ...props.filter }),
            others: JSON.stringify(others)
        }

        let results = await knowledgebasesQuery({ variables }).then(r => (r?.data?.knowledgebasesQuery))
        setBusy(false);
        if (!results || results.error) {
            message.error((results && results.error.message) || "Invalid response");
            return false;
        }

        setPagination({
            current: args.current || pagination.current,
            first, after,
            filter, others,
            total: results.totalCount,
            pageSize: args.pageSize || pagination.pageSize,
        })


        set_queryResults(results)
        return results;
    }
    const handleTableChange = (_pagination, filters, sorter) => {
        console.log("handleTableChange._pagination: ", _pagination)
        // fetchData({ current: _pagination.current, pageSize: _pagination.pageSize });
        fetchData({ ..._pagination })

        // fetchData({
        //     pagination: {
        //         first: _pagination.pageSize,
        //         after: _pagination.pageSize * (_pagination.current - 1),
        //         // filter: _pagination.filter
        //     }
        // })
    };

    const doSearch = (kw) => {
        fetchData({
            pagination: {
                first: defaultPageSize,
                after: 0,
            },
            filter: !kw ? {} : { search: { keywords: kw } }
        })
    }

    const onSuccess = () => {
        if (visible) toggleDrawer({})

        fetchData({
            // pagination,
            // filter,
            // others: pagination.others
        })
    }

    const handleDelete = async (id) => {
        if (!modulePermissions['knowledgebases.delete']) return;

        let results = await deleteKnowledgebase({ variables: { id } }).then(r => (r?.data?.deleteKnowledgebase))
            .catch(error => {
                console.log(__error("ERROR"), error);
                return { error: { message:"Unable to delete record"}}
            })

        if (!results || results.error){
            message.error((results && results.error.message) || "invalid response")
            return false;
        }
        fetchData()
        return true;
    }

    const previewArticle = (rec) => set_showPreview(rec._id)

    const columns = [
        // { title: "ID", dataIndex: '_id' },
        { title: <Icon icon="lock" color="#999" />, dataIndex: 'locked', width: "40px", render: (__, rec) => (<Icon icon={`${rec.locked == 'yes' ? 'lock' : 'lock-open'}`} />) },
        { title: 'Title', dataIndex: 'title', render:(__, rec) => {
            let isLocked = false;
            if (rec.locked == 'yes' && String(rec.author._id) !== String(props.ep_admin.user._id)) isLocked = true;

            return (<>
                <a onClick={()=>previewArticle(rec)} className='a'>{rec.title}</a>
                {!isLocked && <span className='hidden-menu'>
                    {modulePermissions['knowledgebases.delete'] && <DeleteButton className="item" onClick={() => handleDelete(rec?._id)} />}
                    {modulePermissions['knowledgebases.edit'] && <IconButton icon="pen" className="item" onClick={() => toggleDrawer({ fields: rec })} />}
                </span>}
            </>)
        } },
        { title: 'Author', dataIndex: ['author', 'name'] },
        { title: 'Site', dataIndex: ['site','name'] },
        { title: 'Category', dataIndex: 'category', width:"150px" },
    ];

    if (!modulePermissions['knowledgebases.access']) return <Alert message="Access denied" type='warning' showIcon />

    const title = props?.filter?.tags?.$in ? <span>Knowledgebase Filtered Resutls <Tag>{props?.filter?.tags?.$in[0]}</Tag></span> : "Knowledgebase";
        
    return (<>
        <Row gutter={[10]}>
            <Col flex="auto">
                <Row>
                    {props?.filter?.tags?.$in && <Col><BackButton href={`/admin/knowledgebase`} /></Col>}
                    <Col flex="auto"><ListHeader title={title}
                        totalCount={(queryResults && queryResults.totalCount) || '0'} /></Col>
                    {modulePermissions['knowledgebases.add'] && <Col><Button onClick={toggleDrawer}>Add Knowledgebase</Button></Col>}
                </Row>

                <div className='table-header' style={{ padding: "10px" }}>
                    <Row align="middle" gutter={[5]}>
                        <Col><Input.Search onSearch={(kw) => doSearch(kw)} placeholder="Keyword search.." style={{ width: 200 }} enterButton allowClear /></Col>
                        {/* {props?.filter?.tags?.$in && <Col><Tag>{props?.filter?.tags?.$in[0]}</Tag></Col>} */}
                    </Row>
                </div>

                <Table
                    loading={busy}
                    columns={columns}
                    dataSource={queryResults ? queryResults.edges : null}
                    pagination={pagination}
                    // rowClassName={(record => {
                    //     return record.status == 'disabled' ? 'show-menu-on-hover disabled-table-row' : "show-menu-on-hover";
                    // })}
                    onChange={handleTableChange}
                    // title={() => <span>Header</span>}
                    // footer={() => 'Footer'}
                />
            </Col>
            {!props.mini && <Col>
                <TagsAvailable filter={{ type:'knowledgebase' }} targetUrl={(v) => (`/admin/knowledgebase/search/?tag=${v}`)} />
            </Col>}
        </Row>

        <KnowledgebasesForm onClose={toggleDrawer} onSuccess={onSuccess} visible={visible} fields={fields} />

        <ArticlePreview id={showPreview} visible={showPreview != false} onClose={() => set_showPreview(false)} />
    </>)
}

export default connect(({ ep_admin }) => ({ ep_admin }), undefined)(ListComp);
