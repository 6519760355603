import React, { useState } from 'react'
import { Card, Col, PageHeader, Row } from 'antd'
import { ContentArea } from 'Layout_v1';
import { RecentResponse, RecentClosed } from '../components/tickets';



export default function ThreadsDashboard(props) {

    return (<>
        {/* <PageHeader title="Dashboard" /> */}

        <Row gutter={[10]} className='nowrap'>
            <Col flex="auto">
                <ContentArea style={{ marginLeft:0, marginRight:0 }}>
                    <RecentResponse />
                </ContentArea>
            </Col>
            <Col flex="400px">
                <ContentArea style={{ marginLeft: 0, marginRight: 0 }}>
                    <RecentClosed />
                </ContentArea>
            </Col>
        </Row>

        {/* <ContentArea>
            <p>latest response</p>
            <p>open tickets</p>
            <p>recently closed tickets</p>
        </ContentArea> */}
    </>)

}
