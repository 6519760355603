import React, { useState, useEffect } from 'react'
import { Space } from 'antd'
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import { DevBlock, Loader } from 'Common/components';
import { ThreadMessage } from './ThreadMessage'
import { ROOT_PATH } from '../../constants'
import { Link, useHistory } from 'react-router-dom';

const GET_RECENT = loader('src/Graphql/threads/threadsTicketRecentResponse.graphql');

export const RecentResponse = (props) => {
    let history = useHistory();
    const [loadResponse, { called, loading, data }] = useLazyQuery(
        GET_RECENT, { variables: { filter: JSON.stringify({ }) } }
    );

    useEffect(() => {
        if (called || loading || data) return;
        loadResponse()
    }, [])

    if(loading) return <Loader loading={true} center />

    return (<>
        <p style={{ fontWeight: "bold" }} className='h1 heading'>Latest Reponse ({data?.threadsTicketRecentResponse?.length || 0})</p>

        <Space direction='vertical' style={{ width:"100%" }} size={5}>
            {data && data?.threadsTicketRecentResponse?.map((item, i) => {
                return <div onClick={() => history.push(`${ROOT_PATH}/ticket/${item._id}`)} style={{ cursor:"pointer" }} key={i}>{item.message && <ThreadMessage compact header={<b>{item.subject}</b>} ticket_status={item.status} messageData={item.message} ticket_id={item._id} />}</div>

                {/* return (<Link to={`${ROOT_PATH}/ticket/${item._id}`} key={i}>
                    {item.message && <ThreadMessage compact header={<b>{item.subject}</b>} ticket_status={item.status} messageData={item.message} ticket_id={item._id} />}
                </Link>) */}
            })}
        </Space>
    </>)

}
