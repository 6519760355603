import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { __error, __yellow } from 'Common/scripts/consoleHelper'
import { useLazyQuery, useMutation } from '@apollo/client';
import DevBlock from './DevBlock';
import { BackButton, Button, DataRow, DeleteButton, Drawer, IconButton, Loader, Table, Tabs } from 'Common/components'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Alert, Col, Modal, Row, Space, Tag, message, Table as AntTable } from 'antd';
import { defaultDateFormat, pageInfoStatusArray, pageInfoTypesArray } from 'configs';
// import { FieldArray } from 'react-final-form-arrays'
// import arrayMutators from 'final-form-arrays'
import { mongoToDate, parseJson, parseNewLine, sleep, timestamp } from 'Common/scripts/Functions';
import { verifyRole } from 'Common/scripts/Security';
import Notes from 'Modules/Notes';
import InfoPageGallery from './infoPageGallery'

const DEFAULTS = loader('src/Graphql/value_pairs/valuePairs.graphql');
const ADD_PAGE = loader('src/Graphql/InfoPages/addInfoPage.graphql');
const EDIT_PAGE = loader('src/Graphql/InfoPages/editInfoPage.graphql');
const GET_PAGE = loader('src/Graphql/InfoPages/infoPage.graphql');

const UPDATE_FIELD = loader('src/Graphql/InfoPages/updateInfoPageField.graphql');
const ADD_FIELD = loader('src/Graphql/InfoPages/addInfoPageField.graphql');
const DELETE_FIELD = loader('src/Graphql/InfoPages/deleteInfoPageField.graphql');



const InfoPageFieldAddForm = ({ onCancel, page_id, onSuccess }) => {
    const [error, setError] = useState(null)

    const [addInfoPageField, add_details] = useMutation(ADD_FIELD);

    const onSubmit = async (values) => {
        let variables = {
            input: {
                label: values.label,
                value: values.value,
                type: values.type,
                name: `fk_${timestamp() }`,// values.name,
            },
            page_id
        }

        let resutls = await addInfoPageField({ variables }).then(r => (r?.data?.addInfoPageField))
            .catch(err => {
                console.log(__error("Error"), err);
                return { error: { message: "Query Error!" } }
            })

        if (!resutls || resutls.error) {
            setError((resutls && resutls?.error?.message) || "Invalid Response");
            return true;
        }
        onSuccess(resutls)
        return true;
    }


    return (<>
        <FormComponent onSubmit={onSubmit} id='PageInfoAddFieldsForm' //fields={initialValues}
            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    {error && <Alert message="Error!" description={error} type="error" showIcon />}

                    <Row gutter={[5]}>
                        <Col flex="auto"><FormField label="label" placeholder="label" name={`label`} type="text" validate={rules.required} compact /></Col>
                        <Col flex="150px"><FormField label="type" placeholder="type" name={`type`} data={pageInfoTypesArray} type="select" validate={rules.required} compact /></Col>
                        <Col span={24}><FormField label="Value" placeholder="Value" name={`value`} type="text" validate={rules.required} compact /></Col>
                    </Row>

                    <Row gutter={[10]} style={{ margin: "10px 5px" }}>
                        <Col flex="auto" />
                        <Col><Button onClick={onCancel} color="lightgrey" size="medium">Cancel</Button></Col>
                        <Col><Button type="primary" style={{ width: "200px" }} color="orange" size="medium" htmlType='submit' loading={submitting} disabled={!valid}>Save</Button></Col>
                    </Row>

                </>)
            }}
        />

        {/* <DevBlock obj={initialValues} /> */}
    </>)
}

const InfoPageFieldValueForm = ({ onCancel, page_id, initialValues, onSuccess }) => {
    const [error, setError] = useState(null)

    const [updateInfoPageField, update_details] = useMutation(UPDATE_FIELD);

    const onSubmit = async(values) => {
        let variables = {
            input: {
                label: values.label,
                value: values.value,
                type: values.type,
                name: values.name,
            },
            page_id
        }

        let resutls = await updateInfoPageField({ variables }).then(r => (r?.data?.updateInfoPageField))
        .catch(err=>{
            console.log(__error("Error"), err);
            return { error:{message:"Query Error!"}}
        })

        if (!resutls || resutls.error){
            setError((resutls && resutls?.error?.message) || "Invalid Response");
            return true;
        }
        onSuccess(resutls)
        return true;
    }

    return (<>
        <FormComponent onSubmit={onSubmit} id='PageInfofieldsForm' fields={initialValues}
            mutators={{
                // ...arrayMutators,
                setBody: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'value', () => newValueArray[0])
                },
            }}

            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    {error && <Alert message="Error!" description={error} type="error" showIcon />}

                    {initialValues.type == 'textarea' && <Row>
                        <Col span={24}><FormField label='Title' placeholder="value" name={`label`} type="text" compact /></Col>
                        <Col span={24}><FormField placeholder="value" name={`value`} type="textarea" compact /></Col>
                    </Row>}
                    {initialValues.type == 'text' && <Row>
                        <Col span={24}><FormField label='Title' placeholder="value" name={`label`} type="text" compact /></Col>
                        <Col span={24}><FormField placeholder="value" name={`value`} type="text" compact /></Col>
                    </Row>}
                    {initialValues.type == 'html' && <Row>
                        <Col span={24}><FormField label='Title' placeholder="value" name={`label`} type="text" compact /></Col>
                        <Col span={24}>
                            <FormField name="value" type="html" validate={rules.required} compact
                                data={(values && values.value) ? values.value : null}
                                onUpdate={(val) => formProps.form.mutators.setBody(val)}
                            />
                        </Col>
                        {/* <Col span={24}><FormField placeholder="value" name={`value`} type="html" compact /></Col> */}
                    </Row>}
                    {initialValues.type == 'url' && <Row>
                        <Col><FormField label='Label' placeholder="value" name={`label`} type="text" compact /></Col>
                        <Col flex="auto"><FormField label='url' placeholder="value" name={`value`} type="text" compact /></Col>
                    </Row>}
                    {initialValues.type == 'array' && <Row>
                        <Col span={24}><FormField label='Label' placeholder="value" name={`label`} type="text" compact /></Col>
                        <Col span={24}><FormField label='url' placeholder="value" name={`value`} type="textarea" compact /></Col>
                    </Row>}
                    {initialValues.type == 'component' && <Row>
                        <Col><FormField label='Label' placeholder="value" name={`label`} type="text" compact /></Col>
                        <Col flex="auto"><FormField label='url' placeholder="value" name={`value`} type="text" compact /></Col>
                    </Row>}


                    <Row gutter={[10]} style={{ margin: "10px 5px" }}>
                        <Col flex="auto" />
                        <Col><Button onClick={onCancel} color="lightgrey" size="medium">Cancel</Button></Col>
                        <Col><Button type="primary" style={{ width: "200px" }} color="orange" size="medium" htmlType='submit' loading={submitting} disabled={!valid}>Save</Button></Col>
                    </Row>

                </>)
            }}
        />

        {/* <DevBlock obj={initialValues} /> */}
    </>)

}

const FieldRender = ({ onUpdate, page_id, componentsList, ...props }) => {
    const [busy, setBusy] = useState(false)
    const [mode, setMode] = useState('preview')
    const [initialValues, set_initialValues] = useState(props.initialValues)

    const [deleteInfoPageField, del_details] = useMutation(DELETE_FIELD);

    const onDeletePress = async() => {
        setBusy(true)
        await deleteInfoPageField({ variables: {
            page_id, field_name: initialValues.name
        }})
        setBusy(false)
        onUpdate()
    }

    let value = initialValues.value;

    if (initialValues.type == 'array') {
        // value = JSON.parse(value)
        value = parseJson(value)
        return <DevBlock obj={value} />
    }

    if (initialValues.type == 'component') {
        if (componentsList && componentsList[value]) {
            let Comp = componentsList[value];
            return <Comp initialValues={initialValues} />
        }

        return (<>
            <Alert message={`Component Not found: ${value}`} type='error' showIcon />
            {verifyRole('site-docs.manage') && <Row gutter={[10]}>
                <Col flex="auto"><Alert message={`Component Not found: ${value}`} type='error' showIcon /></Col>
                <Col>{verifyRole('site-docs.manage') && <div><DeleteButton busy={busy} onClick={() => onDeletePress()} /></div>}</Col>
            </Row>}

        </>)
    }

    if (mode == 'preview') {
        return (<>
            {/* <Row gutter={[5]} align="middle">
                <Col><div style={{ fontWeight: "bold" }}>{initialValues.label}</div></Col>
                <Col><IconButton size="small" onClick={() => setMode(mode == 'preview' ? 'edit' : 'preview')} icon={mode == 'preview' ? "pen" : 'times'} /></Col>
            </Row> */}

            {initialValues.type == 'text' && <><Row gutter={[5]} align="middle">
                <Col><h2 style={{ margin: "0" }}><span>
                    {initialValues.label} 
                    {verifyRole('site-docs.manage') && <Space>
                        <IconButton size="small" onClick={() => setMode(mode == 'preview' ? 'edit' : 'preview')} icon={mode == 'preview' ? "pen" : 'times'} />
                        <DeleteButton busy={busy} onClick={()=>onDeletePress()} />
                    </Space>}
                </span></h2></Col>
                <Col flex="auto">{value}</Col>
            </Row><hr /></>}

            {initialValues.type == 'url' && <><Space>
                <a href={value} target='blank'>{initialValues.label}</a>
                {verifyRole('site-docs.manage') && <Space>
                    <IconButton size="small" onClick={() => setMode(mode == 'preview' ? 'edit' : 'preview')} icon={mode == 'preview' ? "pen" : 'times'} />
                    <DeleteButton busy={busy} onClick={()=>onDeletePress()} />
                </Space>                }
            </Space><hr /></>}

            {initialValues.type == 'textarea' && <>
                <h2 style={{ margin: "0" }}><span>
                    {initialValues.label} 
                    {verifyRole('site-docs.manage') && <Space>
                        <IconButton size="small" onClick={() => setMode(mode == 'preview' ? 'edit' : 'preview')} icon={mode == 'preview' ? "pen" : 'times'} />
                        <DeleteButton busy={busy} onClick={()=>onDeletePress()} />
                    </Space>}
                </span></h2>
                <div>{parseNewLine(value)}</div>
                <hr />
            </>}

            {initialValues.type == 'html' && <>
                <h2 style={{ margin: "0" }}><span>
                    {initialValues.label} 
                    {verifyRole('site-docs.manage') && <Space>
                        <IconButton size="small" onClick={() => setMode(mode == 'preview' ? 'edit' : 'preview')} icon={mode == 'preview' ? "pen" : 'times'} />
                        <DeleteButton busy={busy} onClick={()=>onDeletePress()} />
                    </Space>}
                </span></h2>
                <div style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: value }} />
                <hr />
            </>}

        </>)
    }

    if (verifyRole('site-docs.manage') && mode == 'edit') {
        return (<>
            <InfoPageFieldValueForm
                onCancel={() => setMode('preview')}
                onSuccess={(v) => {
                    set_initialValues(v);
                    setMode('preview')
                    onUpdate()
                }}
                page_id={page_id} initialValues={initialValues} />
        </>)
    }

    return <Alert message="Impossible happened"  showIcon type='error' />

}

const PageDetails = ({ activePage, parent_id, componentsList }) => {
    const [error, setError] = useState(null)
    const [page_id, setPageId] = useState(null)
    const [pageData, setPageData] = useState(null)
    const [busy, setBusy] = useState(false)
    const [showAddForm, set_showAddForm] = useState(false)

    const [infoPage, { called, loading, data }] = useLazyQuery(
        GET_PAGE, // { variables: { filter: JSON.stringify({ ...filter }), others: JSON.stringify({ sort: { order: 1 } }) } }
    );

    const [addInfoPage, add_details] = useMutation(ADD_PAGE);

    useEffect(() => {
        if (!activePage || page_id == activePage._id) return;

        setPageData(null);
        setPageId(activePage._id)
        fetchData();
    }, [activePage])


    const createPage = async() => {
        setBusy('Initilizing page!')

        let resutls = await addInfoPage({ variables:{
            input:{
                title: activePage.values.title,
                page_key: activePage.key,
                parent_id,
                category: activePage.values.category,
                fields: activePage?.values?.fields?.map(o=>({ label: o.label, name: o.name, type: o.type, value: o.value || " " })),
                version: 1,
                status: 'active',
                imgAttachments: activePage?.values?.imgAttachments ? { allowed: activePage?.values?.imgAttachments?.allowed, types: activePage?.values?.imgAttachments?.types } : undefined,
            }
        }
        }).then(r => (r?.data?.addInfoPage)).catch(err=>{
            console.log(__error("Error: "), err)
            return { error:{message:"Unable to initilized page"}}
        })

        if (!resutls || resutls.error) {
            setError(resutls.error.message)
            return;
        }
        setBusy(false)
        setPageData(resutls)
    }

    const fetchData = async(refresh=false) => {
        // let resutls = await infoPage({ variables: { filter: JSON.stringify({ _id: Number(activePage._id) }) } }).then(r => (r.data.infoPage))
        let resutls = await infoPage({ 
            variables: { 
                filter: JSON.stringify({ 
                    parent_id,
                    category: activePage.value_key,
                    page_key: activePage.key,
                    // _id: Number(activePage._id)
                }) 
            },
            fetchPolicy: refresh ? "network-only" : undefined
        }).then(r => (r?.data?.infoPage))
        .catch(err=>{
            console.log(__error("Error: "), err)
            return { error:{message:"Query Error"}}
        })

        if (!resutls) return createPage();

        if (resutls && resutls.error){
            setError((resutls && resutls?.error?.message) || "No Records found!")
            return;
        }

        setPageData(resutls)
    }

    const onUpdate = () => fetchData(true)

    return (<div className='preview_card'>
        {error && <Alert message="Error!" description={error} type="error" showIcon />}
        {busy && <Loader loading={true}>{busy}</Loader>}

        {(!busy && pageData) && <>
        
            <Loader loading={loading}>
                <Row align="middle">
                    <Col flex="auto"><label className='label'>Author:</label> {pageData && pageData.author.name} - <label className='label'>Last updated:</label> {pageData && mongoToDate(pageData.updated_at).format(defaultDateFormat)}</Col>
                    <Col>
                        {(pageData && pageData.status) ? <Tag color={pageData.status == 'active' ? "green" : "red"}>{pageData.status}</Tag> : ""}
                        {verifyRole('site-docs.manage') && !(pageData && pageData.fields && pageData?.fields[0]?.type=='component') && <>
                            <IconButton onClick={() => set_showAddForm(true)} color="blue" icon="plus" />
                        </>}
                    </Col>
                </Row>
                <hr />

                <Row className='nowrap' gutter={[20]}>
                    <Col flex="auto">
                        <Space direction='vertical' style={{ width: "100%" }}>
                            {pageData && pageData?.fields?.map((item, i) => (<FieldRender onUpdate={onUpdate} page_id={pageData._id} componentsList={componentsList} initialValues={item} key={i} />))}
                        </Space>
                    </Col>
                    {pageData?.imgAttachments?.allowed === true && <Col style={{ borderLeft: "1px solid #EEE", width: "300px" }}>
                        <InfoPageGallery page_id={pageData._id} attachments={pageData.attachments} />

                        <hr />

                        <div><b>Attachments</b></div>
                        <Notes id={pageData._id} formkey="infopage_attachments" type="document" />
                    </Col>}
                </Row>
            </Loader>

            <Modal visible={showAddForm} onCancel={() => set_showAddForm(false)} destroyOnClose>
                {showAddForm && <InfoPageFieldAddForm 
                    onCancel={() => set_showAddForm(false)} 
                    page_id={pageData._id} 
                    onSuccess={(v) => {
                        set_showAddForm(false);
                        onUpdate(v);
                    }}
                />}
            </Modal>
        
        </>}

    </div>)
}

export function InfoPages({ type = "tabs", category, parent_id, componentsList }) {
    const [activeTab, set_activeTab] = useState(null);
    const [tabs, set_tabs] = useState(null);
    // const [showPageManager, set_showPageManager] = useState(false);

    const [valuePairs, { called, loading }] = useLazyQuery(
        DEFAULTS, { variables: { 
            filter: JSON.stringify({ value_key: category }),
            others: JSON.stringify({ sort: { order: 1 } })
        } }
    );

    useEffect(() => {
        if (called || loading) return;
        fetchTabs();
    }, [])

    const fetchTabs = async() => {
        let resutls = await valuePairs().then(r => (r.data.valuePairs))
            .catch(err=>{
                console.log(__error("Error: "), err)
                return { error:{message:"Query Error!"}}
            })

        if (!resutls || resutls.error) return;
        set_tabs(resutls.map(o=>({...o, values: parseJson(o.values) })))
        set_activeTab(resutls[0]?._id)
    }

    return (<>
       
        {(type == 'tabs') && <>
            <Tabs
                // onEdit={() => set_showPageManager(true)}
                loading={loading}
                tabsArray={tabs && tabs?.filter(o => (o.status=='published')).map(o => ({ key: o._id, title: o.values.title }))}
                // tabsArray={tabs && tabs?.map(o => ({ key: o._id, title: o.values.title }))}
                activeTab={activeTab}
                onChange={set_activeTab}
                size="large"
            />
            
            <PageDetails componentsList={componentsList} parent_id={parent_id} activePage={tabs && tabs.find(o => (o._id == activeTab))} />
        </>}


        {/* <Drawer title={`Info ${type} manager`} width={600} visible={showPageManager} onClose={() => set_showPageManager(false)}>
            {showPageManager && <PageManager onSuccess={(v)=>{
                set_showPageManager(false)
                fetchTabs()
            }} />}
        </Drawer> */}

    </>)


}
InfoPages.propTypes = {
    // filter: PropTypes.object,
    componentsList: PropTypes.object,
    type: PropTypes.string,
    category: PropTypes.string.isRequired,
    parent_id: PropTypes.string.isRequired,
}
