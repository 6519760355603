/* eslint-disable eqeqeq */
import React, { Component, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { HomeOutlined } from '@ant-design/icons';
import { ContentArea } from "Layout_v1";
import { connect } from "react-redux";
import { Button } from 'Common/components'
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper';
import { message, Row, Col, Breadcrumb } from 'antd';
import { loader } from 'graphql.macro';
import { updateProfile } from 'Store/ep_admin.actions';
import { uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';

const RECORD_EDIT = loader('src/Graphql/user/editUser.graphql');


/**
 * MyProfile
 */

const MyProfile = props => {
  const [busy, setBusy] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const { user } = props.ep_admin;


  const onSubmit = async values => {
    const input = {
      name: values.name,
      _id: user._id
    }

    if (values.password && values.password != values.confirm_pwd){
      alert("Password missmatch");
      return;
    }
    if (values.password){
      Object.assign(input, { password: values.password });
    }

    setBusy(true);

    await props.client.mutate({ mutation: RECORD_EDIT, variables: { input } }).then(r => {
      const response = r?.data?.editUser;

      if (response.error) return { error: response.error.message };
      message.success("Profile updated");
      props.updateProfile({user:input});
      return response;

    }).catch(err => {
      console.log(__error("ERROR: "), err);
      message.error("Failed to process the request!");
    })

    setBusy(false);



  }




  return (<>
    <Breadcrumb style={{ padding: "10px" }}>
      <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
      {/* <Breadcrumb.Item href="/templates"><span>Templates</span></Breadcrumb.Item> */}
      <Breadcrumb.Item>My Profile</Breadcrumb.Item>
    </Breadcrumb>

    <ContentArea>
      <FormComponent onSubmit={onSubmit} id='UserForm' loading={busy} fields={{ 
        name: user.name, email: user.email,
       }} debug={true} style={{width:'500px'}}
        form_render={formProps => {
          const { values, form, invalid, dirty, valid } = formProps;

          return (<>
            {/* <Row>
              <Col flex="70%"><FormField type="select" name="acc_type" label="Type" data={userRoles.map(o => ({ ...o, _id: o.acc_type }))} validate={rules.required} /></Col>
              <Col flex="auto">
                <FormField className={values.status == 'enabled' ? "active" : "inactive"} type="select" name="status" label="Status" data={userStatus} validate={rules.required} />
              </Col>
            </Row> */}

            <FormField type="text" disabled name="email" label="Email Address (Login User)" validate={composeValidators(rules.required, rules.isEmail)} />
            <FormField type="text" name="name" label="Name" validate={rules.required} />
            
            <Row>
              <Col flex="auto" />
              <Col><Button type="link" onClick={() => setChangePassword(!changePassword)}>Change Password</Button></Col>
            </Row>
            
            {changePassword &&
              <Row>
                <Col flex="auto">
                  <FormField type="password" name="password" label="Password" validate={!values._id ? rules.required : undefined} />
                </Col>
                <Col flex="49%">
                  <FormField type="password" name="confirm_pwd" label="Confirm Password" validate={!values._id ? rules.required : undefined} />
                </Col>
              </Row>
            }


            <div />
            <Button type="primary" htmlType="submit">Submit</Button>

          </>)
        }}
      />                

    </ContentArea>


  </>)
}
MyProfile.propTypes = {
  // login: PropTypes.object.isRequired,
}

const mapStateToProps = ({ ep_admin }) => {
  return ({ ep_admin });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  updateProfile: (payload) => dispatch(updateProfile(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)( withApollo(MyProfile) );
