import React, { Component, useState, useEffect } from 'react'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Field as FinalField } from 'react-final-form'
import { Button, Icon, IconButton, Loader } from 'Common/components';
import { message, Row, Col, Collapse, Drawer, Alert } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
// import { connect } from "react-redux";
import { loader } from 'graphql.macro';
// import { E404 } from "Layout_v1";
import arrayMutators, { update } from 'final-form-arrays'
// import { Field } from "react-final-form";
import { FieldArray } from 'react-final-form-arrays'
import { publishStatus } from 'configs';
// import { sleep } from 'Common/scripts/Functions';
import { withApollo } from '@apollo/client/react/hoc';
import { testFormFieldTypes } from 'configs'

const ADD_RECORD = loader('src/Graphql/TestForms/createTestForm.graphql');
const UPDATE_RECORD = loader('src/Graphql/TestForms/updateTestForm.graphql');
const GET_TEST_FORM = loader('src/Graphql/TestForms/testForm.graphql');


const { Panel } = Collapse;
const fieldKey = "::INPUT_FIELD::";

const Options = ({ name, parent, values }) => {
    const [correct_answer, set_correct_answer] = useState(null)

    return(<>
        {/* <FormField type="single-radio" name="option" value="contains1" label={<span style={{ fontSize: "16px" }}>contains 1</span>} />
        <FormField type="single-radio" name="option" value="contains2" label={<span style={{ fontSize: "16px" }}>contains 2</span>} /> */}

        <FieldArray name={`${name}.options`}>
            {({ fields }) => {
                return (<div>
                    <b>Options</b>
                    {/* <p>{parent.correct_answer}</p> */}


                    {(parent?.options && parent?.options?.length > 0 && !parent.correct_answer) && <div style={{color:"red"}}>Please select the correct answer, from the options below</div>}
                    
                    {fields.map((o_name, index) => {
                        {/* let thisField = fields?.value[index]; */}

                        return (<div key={index} style={{  }}>
                            {/* <p>{`${name}.correct_answer`}</p> */}

                            {/* <FinalField name={`${name}.correct_answer1`} type="radio" value={'val1'}>
                                {props => (<div><input {...props.input} /> Option 1</div>)}
                            </FinalField> */}


                            <Row align="middle">
                                <Col flex="30px" style={{ borderRight: "2px solid #EEE" }}>
                                    <FinalField name={`${name}.correct_answer`} type="radio" value={index} checked={String(parent.correct_answer) === String(index)}>
                                        {props => {
                                            return (<input {...props.input} checked={String(parent.correct_answer) === String(index)} />)
                                        }}
                                    </FinalField>

                                    {/* <FormField 
                                        name={`${name}.correct_answer`} 
                                        type="single-radio" 
                                        // onChange={console.log}
                                        // checked={true}
                                        // checked={String(parent.correct_answer) === String(index)} 
                                        value={index} compact validate={rules.required} /> */}
                                </Col>
                                <Col flex="50px" style={{ fontSize: "14px", textAlign: "center" }}><div>{index + 1}</div></Col>
                                <Col flex="auto"><FormField name={`${o_name}.text`} size="medium" type="text" compact validate={rules.required} /></Col>
                                <Col><IconButton icon="trash-alt" onClick={() => fields.remove(index)} /></Col>
                            </Row>
                        </div>)

                    })}

                    <div style={{ padding:"0 14px" }}><IconButton icon="plus" onClick={() => fields.push({})} /></div>

                </div>)
            }}
        </FieldArray>
    </>)
}

const Fields = ({ name, values, parent }) => {
    return(<>
        <FieldArray name={`${name}.fields`}>
            {({ fields }) => {

                return (<div>
                    <div style={{marginTop:"20px"}}><b>Fields</b></div>

                    {fields.map((f_name, index) => {
                        const thisNode = parent.fields[index];

                        return (<div key={index} className="card">
                            <Row align="middle">
                                <Col flex="50px" style={{ fontSize: "14px", textAlign: "right", fontWeight:"bold", marginRight:"10px" }}><div style={{height:"20px"}} />{index + 1}</Col>
                                <Col flex="150px"><FormField name={`${f_name}.type`} label="Type" size="medium" type="select" data={testFormFieldTypes} compact validate={rules.required} /></Col>
                                
                                {thisNode.type == 'heading' && <Col flex="auto"><FormField name={`${f_name}.title`} label="Heading" size="medium" type="text" compact validate={rules.required} /></Col>}
                                {thisNode.type == 'text' && <Col flex="auto"><FormField name={`${f_name}.title`} label="Simple Text / Paragraph" size="medium" rows={2} type="textarea" compact validate={rules.required} /></Col>}

                                {(thisNode.type == 'radio' || thisNode.type == 'fill-in-the-blanks') && <Col flex="auto">
                                    <FormField 
                                        name={`${f_name}.question`} 
                                        label={<>Question {thisNode.type == 'fill-in-the-blanks' ? <span style={{ color: "#999", marginLeft: "50px" }}><i><b>EXAMPLE:</b> A red fox is <span style={{ color: "blue" }}><b>{fieldKey}</b></span> on the road</i></span> : ""}</>} 
                                        size="medium" 
                                        type="text" 
                                        compact 
                                        validate={rules.required} />
                                </Col>}
                                {thisNode.type == 'text-input' && <Col flex="auto"><FormField name={`${f_name}.title`} label="Input Field Name" size="medium" type="text" compact validate={rules.required} /></Col>}
                                {thisNode.type == 'long-text-input' && <Col flex="auto"><FormField name={`${f_name}.title`} label="Input Field Name (long text input)" size="medium" type="text" compact validate={rules.required} /></Col>}
                                {thisNode.type == 'link' && <Col flex="auto"><FormField name={`${f_name}.title`} label="Link" size="medium" type="text" compact validate={rules.required} /></Col>}
                                
                                <Col><div style={{ height: "20px" }} /><IconButton icon="trash-alt" onClick={() => fields.remove(index)} /></Col>
                            </Row>

                            <div style={{ marginLeft: "100px" }}>
                                {thisNode.type =='radio' && <><Options name={f_name} values={values} parent={thisNode} /></>}
                                
                                {thisNode.type =='fill-in-the-blanks' && <><Options name={f_name} values={values} parent={thisNode} /></>}
                            </div>


                        </div>)

                    })}

                    <div><IconButton icon="plus" size="large" onClick={() => fields.push({})} /></div>

                </div>)
            }}
        </FieldArray>
    </>)
}

const Sections = ({values}) => {
    return(<>
        <FieldArray name={`sections`}>
            {({ fields }) => {

                return (<>
                    <h5>Section <IconButton icon="plus" size="large" onClick={() => fields.push({ passing_percent:0 })} /></h5>

                    <Collapse defaultActiveKey={['1']} accordion>
                        {fields.map((name, index) => {
                            const thisNode = values.sections[index];

                            return (<Panel header={<><span style={{ fontSize: "18px", marginRight: "10px" }}><span style={{color:"#999"}}>Step {index + 1}:</span> {thisNode.title || ""}</span></>} key={index+1}>
                                <Row>
                                    <Col flex="auto"><FormField name={`${name}.title`} label="Step Title" size="large" compact type="text" validate={rules.required} /></Col>
                                    <Col><FormField type="number" min={0} max={100} label="Passing %" name={`${name}.passing_percent`} size="medium" compact validate={rules.required} /></Col>
                                </Row>
                                
                                <Fields name={name} values={values} parent={thisNode} />
                            </Panel>)
                        })}
                    </Collapse>
                </>)

            }}
        </FieldArray>
    </>)
}

class TestForm extends Component {
    // state = { busy:false };
    constructor(props){
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit(values){
        if (!values.sections || values.sections.length<1){
            message.error("Oops! you forgot to add any section!")
            return;
        }
        
        let noFields = values.sections.find(o => !o.fields || o.fields.length<1);
        if (noFields){
            message.error("Please add fields to all of the sections you have created")
            return;
        }

        if (this.props.onSubmit) return this.props.onSubmit(values)
    }


    render() {
        // const { busy } = this.state;
        const { busy } = this.props;

        return (<>
            <FormComponent onSubmit={this.onSubmit} id='TestForm' loading={busy} debug={true} fields={{ ...this.props.fields }} moveOnError
                mutators={{
                    ...arrayMutators,
                    // removeFiles: (newValueArray, state, tools) => {
                    //   tools.changeValue(state, 'fileList', () => []);
                    //   // // tools.changeValue(state, 'tagged_to_users', () => _client);
                    // },

                }}

                form_render={formProps => {
                    const { values, form, invalid, dirty, valid } = formProps;

                    return(<>
                        <Row style={{ marginBottom: "20px" }}>
                            <Col flex="auto"><FormField type="text" label="Test Title" name="title" size="large" validate={rules.required} /></Col>
                            <Col flex="150px"><FormField data={publishStatus} type="select" label="Status" name="status" size="large" validate={rules.required} /></Col>
                        </Row>
                        <Row>
                            <Col flex="auto" />
                            <Col><FormField type="checkbox" name="auto_submit">Auto Submit On Reattempt</FormField></Col>
                            <Col><FormField type="checkbox" name="allow_redo_step">Allow re-attempt Steps</FormField></Col>
                            <Col><FormField type="checkbox" name="hide_result_status">Hide Results from candidate</FormField></Col>
                        </Row>

                        <Sections values={values} />

                    </>)

                }}
            />

        </>)
    }
}

export const TestFormDrawerComponent = ({ editNode, client, onClose, onSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [edit_fields, setFields] = useState(null);
    const [fatel_error, setFatel_error] = useState(null)

    const [busy, setBusy] = useState(false)
    const [error, setError] = useState(null)
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (showForm) return;

        setError(null);
        // setBusy(null);
        setFatel_error(null);
        setFields(null);
        // setLoading(null);

        //   return () => {
        //     effect
        //   };
    }, [showForm])

    useEffect(() => {
        if (editNode && editNode._id){
            // setShowForm(true)
            setLoading(true);
            setShowForm(true)

            const variables = {
                id: editNode._id
            }

            return client.query({ query: GET_TEST_FORM, variables }).then(r => {
                let response = r.data?.testForm;
                
                if (!response) {
                    setFatel_error("Invalid response from server");
                    setLoading(false)
                    return;
                }
                if (response.error) {
                    setFatel_error(response.message);
                    setLoading(false)
                }

                let _fields = { ...response }
                // setup check fields
                Object.assign(_fields, { auto_submit: _fields.auto_submit == 'yes' })
                Object.assign(_fields, { allow_redo_step: _fields.allow_redo_step == 'yes' })
                Object.assign(_fields, { hide_result_status: _fields.hide_result_status == 'yes' })

                setFields(_fields)
                setFatel_error(null);
                setLoading(false)

                return response;

            }).catch(err => {
                console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
                setFatel_error("Unable to complete your request at the moment!");
                setLoading(false)
                return false;
            })


        }



    //   return () => {
    //     effect
    //   };
    }, [editNode])

    const onSubmit = async values => {
        // console.log("onSubmit(): ", values)

        if (editNode && editNode._id) return updateRecords(values)
        else return addRecords(values)

    }

    const updateRecords = async (values) => {
        setBusy(true);

        const getFields = (fields) => {
            return fields.map(o=>{
                return {
                    _id: o._id,
                    type: o.type,
                    title: o.title,
                    question: o.question || undefined,
                    options: !o.options ? undefined : o.options.map(o=>{
                        return { text: o.text, _id:o._id }
                    }),
                    correct_answer: o.correct_answer || undefined
                }
            })
        }
        const getSectionInput = (sections) => {
            return sections.map(o=>{
                return {
                    // section_num: o.section_num,
                    _id: o._id,
                    title: o.title,
                    passing_percent: o.passing_percent,
                    fields: getFields(o.fields)
                }
            })
        }

        const input = {
            _id: editNode._id,
            title: values.title,
            status: values.status,
            allow_redo_step: values.allow_redo_step ? 'yes' : 'no',
            auto_submit: values.auto_submit ? 'yes' : 'no',
            hide_result_status: values.hide_result_status ? 'yes' : 'no',
            sections: getSectionInput(values.sections), // [TestFormSection_Input!]!
        }


        await client.mutate({ mutation: UPDATE_RECORD, variables: { input } }).then(r => {
            let response = r?.data?.updateTestForm;
            setBusy(false);

            if (!response || response.error) {
                setError(response && response?.error?.message || "Invalid Response!")
                return false;
            }

            if (response.status == 'error') {
                message.error(response.message);
                setError(response.message);
                return;
            }

            setShowForm(false);
            // setError(null);
            setBusy(false);
            if (onSuccess) onSuccess(response)


        }).catch(err => {
            console.log(__error("ERROR"), err);
            setBusy(false);
            setError("Failed to process the request!");
            message.error("Failed to process the request!");
            return false;
        });

        return false;
    }

    const addRecords = async (values) => {
        setBusy(true);
        const input = {
            ...values,
            allow_redo_step: values.allow_redo_step === true ? 'yes' : 'no',
            auto_submit: values.auto_submit === true ? 'yes' : 'no',
            hide_result_status: values.hide_result_status === true ? 'yes' : 'no',
        }

        await client.mutate({ mutation: ADD_RECORD, variables: { input } }).then(r => {
            let response = r?.data?.createTestForm;
            setBusy(false);

            if (!response || response.error) {
                setError(response && response?.error?.message || "Invalid Response!")
                return false;
            }

            if (response.status == 'error') {
                message.error(response.message);
                setError(response.message);
                return;
            }

            setShowForm(false);
            // setError(null);
            setBusy(false);
            if (onSuccess) onSuccess(response)


        }).catch(err => {
            console.log(__error("ERROR"), err);
            setBusy(false);
            setError("Failed to process the request!");
            message.error("Failed to process the request!");
            return false;
        });

        return false;
    }

    const toggleDrawer = (val) => {
        if (showForm){
            onClose()
            setFields(null)
            setError(null)
        }
        setShowForm(!showForm)
    }


    return(<>
        <Drawer
            title={`${(editNode && editNode._id) ? "Edit " : "Add New "} Test`} placement="top" height="100%" onClose={() => toggleDrawer()} visible={showForm}
            footer={<>
                <span></span>
                <Button block loading={busy || loading} type="primary" onClick={() => {
                    document.getElementById('TestForm').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
                }}>Save</Button>
            </>}

        ><>
            {fatel_error && <Alert message={"ERROR"} description={fatel_error} type="error" showIcon />}
            {error && <Alert message={"ERROR"} description={error} type="error" showIcon />}
            {loading && <Loader loading={true} />}
            {(showForm && !loading && !fatel_error) && <><TestForm onSubmit={onSubmit} busy={busy} fields={edit_fields} /></>}
        </></Drawer>

        <Button onClick={() => toggleDrawer()}>Create A New Test</Button>
    </>)
}

export const TestFormDrawer = withApollo(TestFormDrawerComponent)
