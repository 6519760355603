import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
// import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import _ from 'lodash'
import { FormField } from './Form'
import { __error } from 'Common/scripts/consoleHelper'
// import { Loader, IconButton, Drawer, Button, DevBlock, Heading } from 'Common/components'
import { withApollo } from '@apollo/client/react/hoc';

const VALUES = loader('src/Graphql/value_pairs/valuePairs.graphql');

const ValuePairsSearchComp = props => {
    const [list, setList] = React.useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = React.useState({});
    const [changed, setChanged] = React.useState("");

    useEffect(() => {
        if (!props.preload) return;

        let changed_val = JSON.stringify([props.preload, props.filter]);
        if (changed_val == changed) return;

        setChanged(changed_val)
        _fetchData(props.preload === true ? undefined : props.preload);

        // if (props.preload && (!list || list.length < 1)) _fetchData(props.preload === true ? undefined : props.preload)
        //   return () => { effect };
    }, [props.preload, props.filter])

    var keyMap = ['_id', 'title'];
    var keytype = 'string';
    if (props.keyMap && _.isString(props.keyMap)) keyMap = props.keyMap.split("=>");
    if (props.keyMap && !_.isString(props.keyMap)) {
        keytype = 'function'
        keyMap = props.keyMap;
    }


    const _fetchData = async (value) => {
        let filter = { key: props.key_value, status: 'published' };

        if (value === false || !_.isString(value)){
            setState({ loading: true })
            if (!_.isString(value)) Object.assign(filter, { ...value })
        }else{
            if (!value || value.length < 1) return;
            setState({ kw: value || undefined, loading: true })
            Object.assign(filter, { search: { keywords: value } })
        }

        Object.assign(filter, { ...props.filter })

        const resutls = await props.client.query({ query: VALUES, variables: { filter: JSON.stringify(filter) } }).then(e => {
            const response = e?.data?.valuePairs;
            if (!response){
                console.log(__error("ERROR: Invalid response"), e);
                return { error: { message:"Invalid response" } }
            }
            return response;
        }).catch(err => {
            console.log(__error("API Call ERROR: Values : "), err);
            return { error: { message:"Query ERROR!" } }
        })

        if (resutls.error){
            message.error(resutls.error.message);
        }
        else{
            // var keyMap = ['_id', 'title'];
            // var keytype = 'string';

            // if (props.keyMap && _.isString(props.keyMap)) keyMap = props.keyMap.split("=>");
            // if (props.keyMap && !_.isString(props.keyMap)) {
            //     keytype = 'function'
            //     keyMap = props.keyMap;
            // }

            // const keyMap = props.keyMap ? props.keyMap.split("=>") : ['_id', 'title']
            setList(resutls.map(o => {
                if (keytype == 'function') return props.keyMap(o)
                return { _id: o[keyMap[0]], title: o[keyMap[1]], raw: o }
            }))
        }

        setState({ loading: false });

    }
    const fetchData = debounce(_fetchData, 800);

    // const onSelect = (value, node) => {
    //     let raw = _.cloneDeep(list.find(o => o._id == value)?.raw);
    //     delete raw.__typename;
    //     delete raw.error;

    //     if (props.onSelect) props.onSelect(value, node, raw);
    // }


    return (<>
        <FormField 
            size={props.size}
            compact={props.compact}
            allowClear
            validate={props.validate}
            // type={props.multi ? 'select-multiple' : "select" }
            // type={props.mode == 'multiple' ? 'select-multiple' : 'select'}
            type={props.multiselect ? 'select-multiple' : 'select'}
            name={props.name}
            label={props.label}
            data={list}
            placeholder={props.placeholder || "Search Values"}
            addNull={props.addNull || false}
            disabled={props.disabled}
            onSelect={props.onSelect}
            // keyMap="_id=>title"
            onChange={(_val, _calback)=>{
                let raw = list.find(o => o._id == _val);
                if (props.onChange) props.onChange(_val, raw);
                _calback(_val);
            }}
            // defaultValue={props.defaultValue && props.defaultValue._id}
            inputProps={{
                // onSelect: onSelect,
                onDeselect: props.onDeselect || undefined, // console.log,
                loading:state.loading,
                mode: props.multiselect ? 'multiple' : undefined,
                // mode: props.mode || undefined, // tags, multiple
                showSearch: true,
                defaultActiveFirstOption: false,
                showArrow: true,
                notFoundContent: state.loading ? <Spin size="small" /> : null,
                filterOption: false,
                onSearch: fetchData,
                // onChange:handelChange,
            }}
        />
    </>)
}
ValuePairsSearchComp.propTypes = {
    filter: PropTypes.object,
    mode: PropTypes.string,
    keyMap: PropTypes.string,
    name: PropTypes.string.isRequired,
    key_value: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValues: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    multiselect: PropTypes.bool,
    disabled: PropTypes.bool,
    preload: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
}

export const ValuePairsSearch = withApollo(ValuePairsSearchComp)
export default ValuePairsSearch;