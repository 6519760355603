import React from 'react'
// import { DevBlock, Heading } from 'Common/components';
import { ContentArea } from 'Layout_v1';
import { Card, Col, Divider, PageHeader, Row } from 'antd';
import { ListHeader } from 'Common/components/Typography';
import { mongoToDate } from 'Common/scripts/Functions';
import { defaultDateFormat, defaultDateTimeFormat } from 'configs';
import Notes from 'Modules/Notes';

const FormsDataView = ({ data }) => {
    if (!data) return null;

    function getField(_fieldname, fromArray) {
        let fields = data.fields;
        return fromArray ?
            fromArray.find(o => o.name == _fieldname) || {} :
            fields.find(o => o.name == _fieldname) || {};
    }

    const duty_date = getField('duty_date')
    const shift_time = getField('shift_time')
    const summary = getField('Summary')


    return (<>
        {/* <PageHeader title={data && data?.ref_key?.replace("_", " ")?.toUpperCase()} /> */}
        <div style={{ padding: "10px 20px" }}><ListHeader title={data.title} /></div>

        <ContentArea style={{ marginRight: 0 }} transparent>
            <h2>
                <span>Created by: {data?.author?.name}</span>
                <span>{`Creatrd at: ${mongoToDate(data.created_at).format(defaultDateTimeFormat)}`}</span>
            </h2>

            <Row gutter={[15]}>
                <Col flex="auto" style={{ borderRight:"0px solid #DDD" }}>

                    <ContentArea style={{ margin:"0" }}>
                        <Divider orientation="left" style={{ margin:"0", fontSize:"24px" }}>{summary.label}</Divider>
                        <div dangerouslySetInnerHTML={{ __html: summary.value }} />
                    </ContentArea>
                </Col>
                <Col flex="350px">
                    {duty_date && <p>{duty_date.label}: <b>{mongoToDate(duty_date.value).format(defaultDateFormat)}</b></p>}
                    {data?.guard?.name && <p>Guard: <b>{data.guard.name}</b></p>}
                    {data?.site?.name && <p>Site: <b>{data.site.name}</b></p>}

                    {shift_time && <p>{shift_time.label}: <b>{shift_time.value}</b></p>}

                    <Divider>Notes</Divider>
                    <Card style={{ margin: "0", padding: "0", borderRadius:"10px" }} bodyStyle={{ padding: "10px" }}>
                        <Notes id={data._id} formkey="obs_report" />
                    </Card>
                </Col>
            </Row>
        </ContentArea>

    </>)

}
export default FormsDataView;