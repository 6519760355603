import React, { useState } from 'react'
import { verifyRole } from 'Common/scripts/Security';
import { withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { message, Tooltip } from 'antd';
import { __error } from 'Common/scripts/consoleHelper';
import { IconButton } from 'Common/components';


const APPROVE_SCHEDULE = loader('src/Graphql/HoursSchedules/hoursSchedules_approve.graphql');

const ApprovalButtonComp = props => {
    const [busy, setBusy] = useState(false);
    const [status, setStatus] = useState(props.data.approval_status);

    const onApprovalPressed = async args => {
        if (!verifyRole('hours-schedule.approve')) return;

        setBusy(true);

        const variables = {
            val: status == 'new' ? 'aproved' : 'new',
            id: props.data._id
        }

        await props.client.mutate({ mutation: APPROVE_SCHEDULE, variables }).then(r => {
            const response = r.data.hoursSchedules_approve;

            if (response.error) {
                alert(response.error.message);
                message.error(response.message);
                return response.error;
            }
            message.success("Approval status updated");
            setStatus(status == 'new' ? 'aproved' : 'new')

        }).catch(err => {
            console.log(__error("ERROR: "), err);
            message.error("Failed to Edit Schedule!");
            return;
        })

        setBusy(false);

    }

    return status == 'new'
        ? <Tooltip title="Verification required" color="red"><IconButton disabled={busy} onClick={() => onApprovalPressed()} icon={'check'} type='danger' /></Tooltip>
        : <Tooltip title="Verified" color="green"><IconButton disabled={busy} onClick={() => onApprovalPressed()} icon={{ icon: 'check', color: 'white' }} type='dafault' style={{ backgroundColor: 'rgba(0, 210, 0, 1)' }} /></Tooltip>
}

export const ApprovalButton = withApollo(ApprovalButtonComp)
export default ApprovalButton;