import React from 'react'
import { Breadcrumb } from "antd";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import RolesManegementList from '../components/roles_management_list'
import { ContentArea } from "Layout_v1";
import { uriRoot } from 'configs';
// import { Heading } from 'Common/components'

export const RolesManagement = props => {
    return (<>
        <Breadcrumb style={{ padding: "10px" }}>
            <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
            <Breadcrumb.Item>User Roles Management</Breadcrumb.Item>
        </Breadcrumb>

        <ContentArea>
            <RolesManegementList />
        </ContentArea>

    </>)
}

export default RolesManagement;
