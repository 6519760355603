import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { withApollo } from '@apollo/client/react/hoc'
import { loader } from 'graphql.macro';
import { Drawer, Space, Alert, message, Row, Col, Divider, Tooltip } from 'antd';
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Loader, Button, DevBlock, ValuePairsSearch, IconButton, Icon } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper';
import { verifyRole } from 'Common/scripts/Security';

const UPDATE_SERVICES = loader('src/Graphql/site/updateSiteServices.graphql');
const SITE = loader('src/Graphql/site/site.graphql');


const SiteServiceForm = ({ onSubmit, siteData }) => {

    const renderCommision = (values, returnAs = 'component') => {
        const { service_type, rate, contract } = values;
        if (!service_type || !service_type.key || (!rate && rate!==0)) return (returnAs == 'boolean') ? false : null;

        let vals = JSON.parse(service_type?.values);


        let thisVal = vals.find(o => {
            if (contract != o.contract) return false;
            let min = o.range[0];
            let max = o.range[1];

            return (rate >= min && rate <= max && contract == o.contract)
        })

        let info = vals.map((o, i)=>{
            return <div key={i}>Tier {o.tier} ({o.range[0]} ~ {o.range[1]}) @ {o.contract == 'yes' ? 'With contract' : 'Without contract'}</div>
        })

        if (!thisVal) return (returnAs == 'boolean') ? false : <Alert message={<>
            <Row style={{ flexWrap:"nowrap" }} gutter={[10]}>
                <Col><Tooltip title={<>{info}</>} color="orange" placement="bottomLeft"><Icon tooltip="helo" icon="exclamation-circle" color="orange" /></Tooltip></Col>
                {/* <Col><IconButton onClick={()=>{}} icon={{ icon:"exclamation-circle", color:"orange" }} /></Col> */}
                <Col flex="auto">No Tier found for {service_type.title} @ ${rate}, {contract == 'yes' ? 'with contract' : 'without contract'}</Col>
            </Row>
        </>} type="warning" />;
        return (returnAs == 'boolean') ? true : (<div style={{ margin: "0 10px", textAlign: "center" }}><b>Tier {thisVal.tier}</b> $({thisVal.range[0]} ~ {thisVal.range[1]}) @ {thisVal.commission}%</div>)
    }


    return (<>
        <FormComponent onSubmit={onSubmit} id='AddresBookForm' debug={true}
            fields={{
                // services: [{ rate: 0 }],
                ...siteData,
                services: (!siteData?.services || siteData?.services?.length < 1) ? [{ rate: 0 }] : siteData?.services,
            }}
            mutators={{
                ...arrayMutators,
                selectService: (newValueArray, state, tools) => {
                    let { raw, index } = newValueArray[0];

                    // let thisNode = state.formState.values.services[index];
                    let services = state.formState.values.services.map((o, i)=> {
                        if (!(i == index)) return o;

                        return {
                            ...o,
                            service_type: raw
                        }
                    })

                    tools.changeValue(state, 'services', () => services);
                },
                de_selectService: (newValueArray, state, tools) => {
                    let { raw, index } = newValueArray[0];
                    
                    let services = state.formState.values.services.map((o, i) => {
                        return (i == index) ? { ...o, service_type:undefined } : o;
                    })

                    tools.changeValue(state, 'services', () => services);

                },

            }}
            form_render={({ values, form, invalid, dirty, valid, submitting }) => {
                return (<>
                    <FieldArray name="services">
                        {({ fields }) => (
                            <div>
                                {fields.map((name, index) => (<div key={index} style={{ marginBottom:"10px" }}>

                                    <Row align="middle" gutter={[5]}>
                                        <Col span={8}><FormField name={`${name}.contract`} label="Contract Type" type="select" validate={rules.required} compact
                                            data={[
                                                { title: "Without any open contract", _id: "no" },
                                                { title: "With contract", _id: "yes" },
                                            ]}
                                        /></Col>
                                        <Col span={4}>
                                            <FormField name={`${name}.contract_exp_date`} style={{ width: "100%" }} label="Expiry date" type="date" compact 
                                                validate={(values?.services[index]?.contract == 'yes') ? rules.required : undefined} 
                                                disabled={!(values?.services[index]?.contract == 'yes')}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <ValuePairsSearch name={`${name}.service_type._id`} key_value="lead_service_type" label="Service Type" compact
                                                preload={true}
                                                onSelect={(txt, node, raw) => form.mutators.selectService({ raw, index })}
                                                onDeselect={(txt, node) => form.mutators.de_selectService({ node, index })}
                                                // defaultValues={values.service_type?._id ? [{ _id: values.service_type._id, title: values.service_type.title }] : []}
                                                validate={rules.required}
                                            />
                                        </Col>
                                        {(verifyRole("site.bill")) && <>
                                            <Col span={4}>
                                                <Row style={{ flexWrap:"nowrap" }} align="bottom">
                                                    <Col flex="auto"><FormField name={`${name}.rate`} label="Service Rate $/hr" type="number" validate={rules.required} compact /></Col>
                                                    {(fields.length > 1 && index > 0) &&
                                                        <Col><IconButton icon="trash-alt" onClick={() => fields.remove(index)} /></Col>
                                                    }
                                                </Row>
                                            </Col>
                                        </>}
                                    </Row>
                                    {verifyRole("site.bill") && renderCommision(values?.services[index])}
                                    {/* {values?.services[index]?.isDuplicate && <Alert message="Duplicate service" type='warning' showIcon />} */}

                                </div>))}

                                <div style={{ margin: "20px", textAlign: "center" }}>
                                    <IconButton icon="plus" size="large" onClick={() => fields.push({ rate: 0 })} />
                                </div>
                            </div>
                        )}
                    </FieldArray>

                    <Col align="right">
                        <Button type="primary" size="medium" htmlType='submit' loading={submitting} disabled={!dirty || !valid || values.services.find(o => o.isDuplicate == true)}>Save</Button>
                    </Col>
                </>)
            }}
        />

    </>)
}

const SiteServiceList = ({ site, onClose, onUpdate, client }) => {
    const [busy, setBusy] = useState(false);
    const [siteData, setSiteData] = useState(null)

    useEffect(() => {
        getServices();
    }, [site._id])

    const getServices = async () => {
        setBusy(true);

        const resutls = await client.query({ query: SITE, variables:{ id:Number(site._id) } })
            .then(r => (r.data.site))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message:"Unable to login at the moment!" } }
            })

        setSiteData(resutls);
        setBusy(false);
    }

    const onSubmit = async (values) => {
        
        var services = values.services.map(service => {
            let val = {
                // _id: Number(service._id),
                title: service.title,
                // rate: service.rate,
                contract: service.contract,
                contract_exp_date: service.contract_exp_date,
                service_type: {
                    _id: Number(service.service_type._id),
                    title: service.service_type.title,
                    key: service.service_type.key,
                    values: service.service_type.values
                }
            }

            if (verifyRole("site.bill")) Object.assign(val, { rate: service.rate })
            // else if (!service._id) Object.assign(val, { rate: 0 })

            return val;
        })

        const resutls = await client.mutate({ mutation: UPDATE_SERVICES, variables: { site_id: Number(site._id), services } })
            .then(r => (r.data.updateSiteServices))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Query !Error!" } }
            });

        if (resutls.error){
            message.error(resutls.error.message);
            return false;
        }

        onUpdate(resutls);
        return true;

    }

    if (!siteData) return <Alert message="Site Not found!" type='error' showIcon />
    if (siteData.error) return <Alert message={siteData.error} type='error' showIcon />
    if (busy) return <Loader loading={true} />

    return (<>
        <p>Services for <b>{siteData.name}</b></p>
        {/* {(!siteData?.services || siteData?.services?.length < 1) && <Alert message="No services assigned" type="warning" showIcon />} */}

        <SiteServiceForm onSubmit={onSubmit} siteData={siteData} />

        <h3 style={{ marginBottom:"0px", marginTop:"10px" }}>History</h3>
        {siteData?.services_history?.slice()?.reverse()?.map((row, i)=>{
            return (<Row key={i} gutter={[5]}>
                <Col><Icon icon="chevron-right" /></Col>
                <Col flex="auto">{row}</Col>
            </Row>)
        })}
        {/* <DevBlock title="history" obj={siteData.services_history} /> */}


    </>)
}
export const SiteServiceManager = withApollo(SiteServiceList);
SiteServiceManager.propTypes = {
    site: PropTypes.object,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
}

const SiteServiceManagerDrawer = (props) => {
    return(<>
        <Drawer title="Manage Site Services" width={1000} bodyStyle={{ paddingBottom: 80 }}
            visible={props.show}
            destroyOnClose={true}
            onClose={props.onClose} extra={<Button onClick={props.onClose}>Cancel</Button>}
        ><>
            {props.show && <SiteServiceManager {...props} />}
        </></Drawer>

    </>)
}


export default SiteServiceManagerDrawer;