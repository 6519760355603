import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Alert, message, Popconfirm, Row, Col, Input, Drawer, Button } from 'antd';
import { Table, IconButton, Avatar } from 'Common/components';
import { __blue, __error, __yellow } from 'Common/scripts/consoleHelper'
import { connect } from "react-redux";
import { defaultDateFormat, defaultPageSize } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import { useSubscription, gql } from "@apollo/client";
// import { modulePermissions } from '../';
import { mongoToDate } from 'Common/scripts/Functions';
import { ListHeader } from 'Common/components/Typography';
import AddressBook from 'Modules/Addressbooks/address_list'
import { SMSFormModal } from './sms_form';
import { isSuperAdmin, verifyRole } from 'Common/scripts/Security';
import { Link } from 'react-router-dom';
import { ROOT } from '../constants';

const SMS_LIST = loader('src/Graphql/sms/smsQuery.graphql');
const QUERY_SUBSCRIPTION = loader('src/Graphql/sms/smsUpdated.graphql');
const DELETE = loader('src/Graphql/sms/deleteSms.graphql');

function Subscriber({ QUERY, callback, filter }) {
    let variables = {};

    if (filter) Object.assign(variables, { ...filter });

    const { data, loading, error } = useSubscription(
        QUERY_SUBSCRIPTION,
        {
            // subscription: QUERY,
            // variables: { filter: JSON.stringify(variables) },
            // fetchPolicy: "network-only" // cache-first, cache-only, cache-and-network, network-only, no-cache, standby
            onSubscriptionData: ({ client, subscriptionData }) => {
                const { data, error, loading, variables } = subscriptionData
                console.log({ subscriptionData })
                if (callback) callback(subscriptionData)
            },
            shouldResubscribe: false, // Determines if your subscription should be unsubscribed and subscribed again
        }
    );

    if (loading) {
        console.log("Receiving subscription on ....", variables);
        return null;
    }

    if (data) {
        console.log('Subscription received: ', data);
        // const { mutation, node } = data.formsDatasUpdated;
        // if (callback) callback({ mutation, node })
    }

    return null;

}


class SMSList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            busy: false,
            data: null,
            error: null,
            pagination: { current: 1, pageSize: defaultPageSize },
            showAddressBook: false,
            filter:{}
        }
        this.fetchData = this.fetchData.bind(this)
        this.doSearch = this.doSearch.bind(this)
        this.onSubscriptionReceived = this.onSubscriptionReceived.bind(this)
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ current: pagination.current, pageSize: pagination.pageSize, filter: this.state.filter });
    };

    fetchData = async (args = {}) => {
        let filter = { ...args.filter, ...this.props.filter }

        const variables = {
            first: args.pageSize || this.state.pagination.pageSize, // number of rec to fetch
            after: (args.pageSize || this.state.pagination.pageSize) * ((args.current || this.state.pagination.current) - 1),
            filter: JSON.stringify(filter),
            // others: JSON.stringify({ sort: { name: 0 } })
        }

        this.setState({ busy: true })

        let resutls = await this.props.client.query({ query: SMS_LIST, variables }).then(r => (r.data.smsQuery))
        .catch(err => {
            console.log(__error("ERROR: "), err);
            return { error: { message:"Unable to fetch SMS at the moment!" }}
        })

        if (resutls.error){
            this.setState({ busy: false, error: resutls.error.message });
        }else{
            this.setState({
                busy: false,
                data: resutls,
                pagination: {
                    ...this.state.pagination,
                    current: args.current || 1,
                    pageSize: args.pageSize || this.state.pagination.pageSize,
                    total: resutls.totalCount
                }
            });

        }
    }

    doSearch = (kw) => {
        this.setState({
            filter: !kw ? {} : { search:{ keywords:kw } }
        }, ()=>{
            this.fetchData({ filter: this.state.filter })
        })
    }

    delete(_id){
        this.props.client.mutate({ mutation: DELETE, variables: { id: _id } }).then(r => {
            let response = r.data.deleteSms;

            if (response.error) {
                this.setState({ loading: false, error: response.error.message });
                return;
            }
            message.success("SMS Removed");

            const __data = this.state.data.edges ? this.state.data.edges.slice() : [];
            const index = __data.findIndex(data => data._id === response._id)
            __data.splice(index, 1);
            const __totalCount = this.state.data.totalCount - 1;
            this.setState({ busy: false, data: { edges: __data, totalCount: __totalCount } })
            
        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            message.error("Failed to process the request!");
        })

    }

    columns = () => {
        let cols_bk = [
            { title: 'ID', dataIndex: '_id', width: 100 },
            { title: 'From', dataIndex: "from", width: "130px", render: (__, rec) => {
                let sender = rec.sender || rec.from_user;
                return (<>
                    {(sender && sender._id) && <div><b>{sender.name}</b></div>}
                    <SMSFormModal name={(sender && sender._id) ? sender.name : ""} phone={rec.from} />
                </>)
            }},
            { title: 'To', dataIndex: "to", width: "130px", render:(__, rec)=>{
                let receiver = rec?.to_user;
                return (<>
                    {(receiver && receiver._id) && <div><b>{receiver?.name}</b></div>}
                    <SMSFormModal name={(receiver && receiver._id) ? receiver.name : ""} phone={rec.to} />
                </>)
            } },
            { title: 'Body', dataIndex: "body", render:(__, rec)=>{
                return <>
                    <Row>
                        <Col flex="auto" />
                        <Col>
                            <Row gutter={[10]} style={{ color: "#999", fontSize: "10px", backgroundColor: "#EEE", borderRadius:"10px" }}>
                                <Col><b>{rec?.sender?.name}</b></Col>
                                <Col>{mongoToDate(rec.created_at).format(`${defaultDateFormat} ~ HH:mm`)}</Col>
                            </Row>
                        </Col>
                    </Row>
                    {rec.body}
                </>
            } },
            // { title: 'Timestamp', dataIndex: "created_at", width: "170px", render: (__, rec) => (mongoToDate(rec.created_at).format(`${defaultDateFormat} HH:mm:ss`)) },
        ]

        let cols = [
            { title: 'ID', dataIndex: '_id', width: 100 },
            { title: 'Message', dataIndex: 'body', render: (___, rec) => {
                    console.log("Message: ", rec)
                    let sender = rec.sender || rec.from_user;
                    let receiver = rec?.to_user;

                    let className = "";
                    let isMyMessage = this.props.sender_number == rec.from;
                    if (isMyMessage) className = "is_my_message"

                    return (<Row gutter={[5]} className={`nowrap ${className}`}>
                        <Col>
                            <Avatar tooltipProps={{ color: "#FFFFFF" }} tooltip={<>
                                {/* sender && sender._id && sender.name */}
                                <Link to={`/admin${ROOT}/conversation/${rec.from}`}><span className='sms_userinfo'>{sender && sender._id && sender.name}</span></Link>
                                <SMSFormModal name={(sender && sender._id) ? sender.name : ""} phone={rec.from} />
                            </>} alt={sender && sender._id && sender.name} size={32} />
                        </Col>
                        <Col>
                            <Row gutter={[5]} style={{ fontSize: "11px" }}>
                                {/* <Col><span className='sms_userinfo'>#{receiver && receiver._id && receiver.name}</span></Col> */}
                                <Col><Link to={`/admin${ROOT}/conversation/${rec.to}`}><span className='sms_userinfo'>{receiver && receiver._id && receiver.name}</span></Link></Col>
                                <Col><span className='sms_msg_number'><SMSFormModal name={(receiver && receiver._id) ? receiver.name : ""} phone={rec.to} /></span></Col>
                                <Col>{mongoToDate(rec.created_at).format(`${defaultDateFormat} ~ HH:mm`)}</Col>
                            </Row>
                            <div>{rec.body}</div>
                        </Col>
                    </Row>)
                }
            },
        ]


        // if (modulePermissions['sms-management.delete']) cols.push({
        if (verifyRole('sms-management.delete', { user:this.props.user })) cols.push({
            title: '', render: (___, rec) => {
                return <div>
                    <Popconfirm onConfirm={() => this.delete(rec?._id)} title="Are you sure？">
                        <IconButton icon="trash-alt" />
                    </Popconfirm>
                </div>
            },
            width: '50px', align: "center"
        })

        if (!isSuperAdmin(this?.props?.user)) cols = cols.filter(o => o.dataIndex != '_id');

        return cols;
    };

    componentDidMount() {
        this.fetchData()
    }

    onSubscriptionReceived({ data, error, loading, variables }) {
        console.log(__yellow("onSubscriptionReceived()"), data.smsUpdated);
        const { mutation, node } = data.smsUpdated;

        var edges = this.state.data.edges ? this.state.data.edges.slice() : [];

        if (mutation == "DELETED") edges = edges.filter(o => o._id != node._id);
        else if (mutation == "UPDATED") edges = edges.map(o => (o._id == node._id) ? { ...node } : o)
        else if (mutation == "CREATED") {
            edges.pop();
            edges.unshift(node);
            // edges.push(node);
        }
        else {
            console.log(__error("Invalid Subscription received: ", data));
            return;
        }

        this.setState({ data: { ...this.state.data, edges: edges } })
    }

    render() {
        const { busy, error, data, pagination } = this.state;

        if (data && data.edges){
            console.log("data.edges: ", data.edges)
        }

        if (error) return <Alert message="Error" description={error} type="error" showIcon />
        return (<>
            <Subscriber QUERY={QUERY_SUBSCRIPTION} callback={this.onSubscriptionReceived} variables={{}} filter={{}} />
            
            <Table loading={busy} size="small"
                title={() => <Row align="middle" gutter={[10]}>
                    <Col flex="auto">
                        <ListHeader totalCount={(data && data.totalCount) || '0'} title={this.props.title || "SMS"} />
                    </Col>
                    <Col><Input.Search onSearch={this.doSearch} placeholder="Search SMS" style={{ width: 200 }} enterButton allowClear /></Col>
                    <Col><Button onClick={() => this.setState({ showAddressBook: !this.state.showAddressBook })}>Address book</Button></Col>
                    <Col><IconButton onClick={() => this.fetchData()} icon="sync-alt" loading={busy} size="medium" /></Col>
                </Row>}
                columns={this.columns()}
                dataSource={data && data.edges}
                pagination={pagination}
                onChange={this.handleTableChange}
            />

            <Drawer title="Address Book" placement="right" width="600px" 
                bodyStyle={{padding:0, margin:0}}
                onClose={() => this.setState({ showAddressBook: !this.state.showAddressBook })} 
                visible={this.state.showAddressBook}>
                {this.state.showAddressBook && <AddressBook inlineView />}
            </Drawer>

        </>)
    }
}


const mapStateToProps = ({ ep_admin }) => {
    // console.log("ep_admin?.user: ", ep_admin?.user)
    return { user: ep_admin?.user }
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export default connect(mapStateToProps)(withApollo(SMSList))


// export default withApollo(SMSList);