import React, { useState } from 'react'
import { ContentArea } from "Layout_v1";
import { Breadcrumb, message, Row, Col, Drawer, Space } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'Common/components';
import FormsDataList from '../Components/FormsDataList';
import { Link } from 'react-router-dom';
import SendApplicationForm from '../Components/SendApplicationForm';
import { CLIENT_INFO_FORM, GUARD_APPLICATION_FORM, SECURITY_GUARD_INTERVIEW } from '../constants';

const ClientInfoList = props => {
  const [showSendForm, set_showSendForm] = useState(false);
  const [activeTab, set_activeTab] = useState('active');

  let dataFilter = activeTab;
  if (activeTab == 'active') dataFilter = { status: { $in: ['new', 'viewed'] } }
  if (activeTab == 'processed') dataFilter = { status: 'processed' }


  return (<>
    <Row align="middle">
      <Col flex="auto">
        <Breadcrumb style={{ padding: "10px" }}>
          <Breadcrumb.Item to={'/'}><HomeOutlined /></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/admin/FormsData">Form Data</Link></Breadcrumb.Item>
          <Breadcrumb.Item>Client Information</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col style={{ marginRight: "10px", fontWeight: "bold" }}>
        <Space>
          <Button color='grey' onClick={() => set_showSendForm(true)}>Send Client Info Form</Button>
        </Space>
      </Col>
    </Row>

    <ContentArea style={{ marginTop: 0 }}>
      <FormsDataList permanent_filter={dataFilter} title="Client Information" ref_key={CLIENT_INFO_FORM} />
    </ContentArea>

    <SendApplicationForm type={CLIENT_INFO_FORM} visible={showSendForm} onCancel={() => set_showSendForm(false)} />

  </>)
}

export default ClientInfoList;
