import React, { useState, useEffect } from 'react'
import { Drawer, Space, Alert, message, Row, Col, Divider } from 'antd';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Loader, ClientSearch, Button, SiteSearch, UsersSearch, ValuePairsSearch } from 'Common/components';
import { modulePermissions } from '..';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper';
import { dateToMongo } from 'Common/scripts/Functions';

const ADD_LEADS = loader('src/Graphql/leads/addLeads.graphql');
const EDIT_LEADS = loader('src/Graphql/leads/editLeads.graphql');
const GET_LEADS = loader('src/Graphql/leads/lead.graphql');

const defaultValues = { status: "open", rate: 24, contract:"no" }

const LeadForm = props => {
    const [error, setError] = useState(null);

    const onSubmitLead = async (values) => {
        setError(null);

        let tier = renderCommision(values, 'boolean');
        if (!tier){
            message.error("No matching Tier found!");
            return false;
        }

        const input = {
            contract: values.contract,
            contract_exp_date: values.contract=='yes' ? dateToMongo(values.contract_exp_date) : undefined,
            // service_name: values.service_name,
            rate: values.rate,
            service_type: {
                _id: values.service_type._id, title: values.service_type.title
            },
            title: values.title,
            status: values.status,
            agent: {
                _id: Number(values.agent._id), name: values.agent.name
            },
            client: {
                _id: Number(values.client._id), name: values.client.name
            },
            site: {
                _id: Number(values.site._id), name: values.site.name
            },
            description: values.description,

            // contract: values.contract,
            // contract_exp_date: dateToMongo(values.contract_exp_date),
            // service_name: values.service_name,
            // rate: Number(values.rate).toFixed(2),
            // service_type: {
            //     _id: values.service_type._id,
            //     title: values.service_type.title
            // },
            // title: values.title,
            // status: values.status,
            // agent: {
            //     _id: Number(values.agent._id),
            //     title: values.agent.title
            // },
            // client: {
            //     _id: Number(values.client._id),
            //     name: values.client.name
            // },
            // site: {
            //     _id: Number(values.site._id),
            //     name: values.site.name
            // },
            // description: values.description,
        }
        console.log("input: ", input)


        if (props?.lead?._id){
            if (!modulePermissions['leads.edit']) return setError("Permission denied");

            let results = await props.client.mutate({ mutation: EDIT_LEADS, variables: { input: { ...input, _id: props.lead._id } } }).then(r => (r.data.editLeads))
                .catch(err => {
                    console.log(__error("ERROR: "), err);
                    return { error: { message: "Query Error!" } }
                })

            if (results.error) {
                message.error(results.error.message);
                setError(results.error.message);
                return false;
            }

            props.onListUpdate('update', results);
        }
        else{
            if (!modulePermissions['leads.add']) return setError("Permission denied");

            let results = await props.client.mutate({ mutation: ADD_LEADS, variables: { input } }).then(r => (r.data.addLeads))
                .catch(err => {
                    console.log(__error("ERROR: "), err);
                    return { error: { message: "Query Error" } }
                })

            if (results.error) {
                message.error(results.error.message);
                setError(results.error.message);
                return false;
            }
            
            props.onListUpdate('add', results);
        }

        props.onClose();
        return true;
    }

    const renderCommision = (values, returnAs='component') => {
        if (!values?.service_type?.values  || !values.rate || values.rate < 1) return (returnAs=='boolean') ? false : null;

        let vals = JSON.parse(values?.service_type?.values);

        let thisVal = vals.find(o=>{
            if (values.contract != o.contract) return false;

            let min = o.range[0]
            let max = o.range[1]

            return (values.rate >= min && values.rate <= max && values.contract == o.contract)
        })

        if (!thisVal) return (returnAs == 'boolean') ? false : <Alert message={`No Tier found for ${values.service_type.title} @ ${values.rate}$, ${values.contract=='yes' ? 'with contract' : 'without contract'}`} type="warning" showIcon />;

        return (returnAs == 'boolean') ? true : (<div style={{ margin: "0 10px", textAlign: "center" }}><b>Tier {thisVal.tier}</b> ({thisVal.range[0]} ~ {thisVal.range[1]})$ @ {thisVal.commission}%</div>)
    }

    if (props.loading) return <Loader loading={true} />
    if (props.error) return <Alert message="Error" description={props.error.message} type="error" showIcon />

    return(<>
        {error && <Alert message="Error" description={error} type="error" showIcon />}

        <FormComponent onSubmit={onSubmitLead} id='LEadsForm' debug={true} fields={{ ...defaultValues, ...props.lead }}
            mutators={{
                selectClient: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    let val = { _id: node.value, name: node.children };
                    tools.changeValue(state, 'client', () => val);
                },
                de_selectClient: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'client', () => undefined)
                },

                selectSite: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    let val = { _id: node.value, name: node.children };
                    tools.changeValue(state, 'site', () => val);
                },
                de_selectSite: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'site', () => undefined)
                },

                selectAgent: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    let val = { _id: node.value, name: node.children };
                    tools.changeValue(state, 'agent', () => val);
                },
                de_selectAgent: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'agent', () => undefined)
                },
                
                selectService: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    tools.changeValue(state, 'service_type', () => node);
                },
                de_selectService: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'service_type', () => undefined)
                },
            }}
            form_render={formProps => {
                const { values, form, submitting, dirty, valid } = formProps;

                return (<>
                    <Divider style={{marginTop:"0px"}}>Contract Info</Divider>
                    <Row>
                        <Col span={16}><FormField data={[
                            { title: "Without any open contract", _id: "no" },
                            { title: "With contract", _id: "yes" },
                        ]} label="Contract Type" name="contract" type="select" validate={rules.required} /></Col>
                        <Col span={8}>
                            {values.contract =='yes' && 
                                <FormField style={{width:"100%"}} label="Expiry date" name="contract_exp_date" type="date" validate={rules.required} />
                            }
                        </Col>
                    </Row>

                    <Row>
                        {/* <Col span={16}><FormField label="Service Name" name="service_name" type="text" validate={rules.required} /></Col> */}
                        <Col span={16}>
                            <ValuePairsSearch key_value="lead_service_type" label="Service Type" name='service_type._id'
                                preload={true}
                                onSelect={(txt, node, raw) => form.mutators.selectService(raw)}
                                onDeselect={(txt, node) => form.mutators.de_selectService(node)}
                                // defaultValues={values.service_type?._id ? [{ _id: values.service_type._id, title: values.service_type.title }] : []}
                                validate={rules.required}
                            />
                        </Col>
                        <Col span={8}><FormField label="Service Rate $/hr" name="rate" type="number" validate={rules.required} /></Col>
                    </Row>
                    {renderCommision(values)}

                    <Divider>Lead Info</Divider>

                    <Row>
                        <Col flex="auto"><FormField placeholder="Firewatch A-List office" label="Lead Title" name="title" type="text" validate={rules.required} /></Col>
                        <Col flex="150px">
                            <FormField label="Status" name="status" data={[
                                { _id: "open", title: "Open" },
                                { _id: "closed", title: "Closed" },
                                { _id: "canceled", title: "Canceled" },
                            ]} type="select" validate={rules.required} />
                        </Col>
                    </Row>

                    <Row>
                        <Col flex="50%">
                            <UsersSearch label="Sales Agent" name="agent._id"
                                filter={{ status: "enabled" }}
                                preload={props?.lead?.agent?._id ? { _id: Number(props?.lead?.agent?._id)} : false}
                                onSelect={(txt, node) => form.mutators.selectAgent(node)}
                                onDeselect={(txt, node) => form.mutators.de_selectAgent(node)}
                                // defaultValues={(values && values.agent) ? [{ _id: values.agent._id, title: values.agent.title }] : []}
                                validate={rules.required}
                            />
                        </Col>
                        <Col flex="auto"></Col>
                    </Row>

                    <Row>
                        <Col flex="auto">
                            <ClientSearch label="Client" name='client._id'
                                preload={props?.lead?.client?._id ? { _id: Number(props?.lead?.client?._id) } : false}
                                onSelect={(txt, node) => form.mutators.selectClient(node)}
                                onDeselect={(txt, node) => form.mutators.de_selectClient(node)}
                                // defaultValues={(values && values.client) ? [{ _id: values.client._id, title: values.client.name }] : []}
                                validate={rules.required}
                            />
                        </Col>
                        <Col flex="50%">
                            <SiteSearch disabled={!values?.client?._id} label="Site" name='site._id'
                                filter={values?.client?._id ? { client_id: Number(values.client._id) } : {}}
                                preload={values?.client?._id ? true : false}
                                onSelect={(txt, node) => form.mutators.selectSite(node)}
                                onDeselect={(txt, node) => form.mutators.de_selectSite(node)}
                                // defaultValues={(values && values.site) ? [{ _id: values.site._id, title: values.site.name }] : []}
                                validate={rules.required}
                            />
                        </Col>
                    </Row>

                    <FormField label="Description" name="description" type="textarea" validate={rules.required} />

                    <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Submit </Button>

                </>)
            }}
        />

    </>)
}


const EditWrapper = compose(
    graphql(GET_LEADS, {
        options: ({ fields }) => {
            return {
                variables: { id: Number(fields._id) },
                fetchPolicy: "no-cache",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, lead, error } = data;
            if (error) console.log(__error("error"), error);
            return { loading, lead, queryErrors: error, }
        },
    }),
)(LeadForm);

const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <LeadForm {...props} />

const LeadFormDrawer = ({ onListUpdate, client, showDrawer, onClose, fields }) => {
    return(<>
        <Drawer
            title={fields ? "Edit Leads" : "Create New Leads"}
            width={600}
            onClose={onClose}
            visible={showDrawer}
            bodyStyle={{ paddingBottom: 80 }}
            extra={<Button onClick={onClose}>Cancel</Button>}
            destroyOnClose={true}
        ><>
            {/* {busy && <Loader loading={true} />} */}
            {/* {error && <Alert message={error} type="error" showIcon />} */}
            {showDrawer && <Wrapper onClose={onClose} onListUpdate={onListUpdate} client={client} fields={fields} />}
            {/* {showDrawer && !busy && !error && <LeadForm onClose={onClose} onListUpdate={onListUpdate} client={client} initialData={data} />} */}
        </></Drawer>
    </>)

}

export default withApollo(LeadFormDrawer)