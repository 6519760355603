import React from 'react';
import { Select } from 'antd';
const {OptGroup} = Select;

export const defaultDateFormat = 'YYYY-MM-DD';
export const defaultDateTimeFormat = 'YYYY-MM-DD HH:mm';

export const ActiveInactiveStatus = [
  { title: "Active", _id: "active" },
  { title: "Inactive", _id: "inactive" },
]

export const siteStatusArray = ActiveInactiveStatus;
export const clientStatusArray = ActiveInactiveStatus;
export const pageInfoStatusArray = ActiveInactiveStatus;

export const genders = [
  { title: "Male", _id: "male" },
  { title: "Female", _id: "female" },
]

// export const noticeboardCategories = [
//   { title: "Shared", _id: "shared" },
//   { title: "Personal", _id: "personal" },
// ]

export const priorityArray = [
  { title: "Normal", _id: "normal", color: 'green' },
  { title: "Low", _id: "low", color:"orange" },
  { title: "High", _id: "high", color: 'red' },
]

export const flagsArray = [
  { _id: "normal", title: "Normal", color: 'green' },
  { _id: "invalid", title: "Invalid", color: 'red' },
  { _id: "canceled", title: "Canceled", color: 'grey' }
]

export const pair_value_types = [
  { title: "String", _id: "string" },
  { title: "Array", _id: "array" },
  { title: "Object", _id: "object" },
]

export const applicationStatus = [
  { title: "New", _id: "new" },
  { title: "Viewed", _id: "viewed" },
  { title: "Processing", _id: "processing" },
  { title: "Approved", _id: "approved" },
  { title: "Rejected", _id: "rejected" },
  { title: "Hired", _id: "hired" },
  { title: "Terminated", _id: "terminated" },
]

export const formData_guardApplication = [
  { title: "New", _id: "new" },
  { title: "Viewed", _id: "viewed" },
  { title: "Processing", _id: "processing" },
  { title: "Rejected", _id: "rejected" },
  { title: "Hired", _id: "hired" },
  { title: "Perspective", _id: "perspective" },
]

export const testStatus = [
  { title: "Incomplete", _id: "incomplete" },
  { title: "New", _id: "new" },
  { title: "Viewed", _id: "viewed" },
  { title: "Archived", _id: "archived" },
  { title: "Training", _id: "training" },
  { title: "Hired", _id: "hired" },
  // { title: "Invitation", _id: "invitation" }
]
export const dorFlagsArray = [
  { title: "Normal", _id: "normal" },
  { title: "Important", _id: "important" },
]
export const testResults = [
  { title: "None", _id: "none" },
  { title: "Pass", _id: "pass" },
  { title: "Fail", _id: "fail" },
]
export const testFormFieldTypes = [
  { _id: "heading", title: "Heading" },
  { _id: "text", title: "Text" },

  { _id: "radio", title: "Radio" },
  { _id: "fill-in-the-blanks", title: "Fill in the blanks" },
  { _id: "text-input", title: "Text Input" },
  { _id: "long-text-input", title: "Long Text Input" },
  { _id: "link", title: "Link" },
  // { _id: "select", title:"Select" },
]

export const userStatus = [{ title: "Enabled", _id: 'enabled' }, { title: "Disabled", _id: 'disabled' }];
export const publishStatus = [{ title: "Published", _id: 'published' }, { title: "Hidden", _id: 'hidden' }];
// export const noticeboardStatus = [{ title: "Published", _id: 'published' }, { title: "Hidden", _id: 'hidden' }, { _id: "archived", title:"Archived"}];
export const noticeboardStatus = [{ title: "Open", _id: 'open' }, { title: "Resolved", _id: 'resolved' }, { _id: "processing", title:"Processing"}];

export const GOOGLE_API_KEY = "AIzaSyDhplL9_Tnp7Ig4lZE5TW5zme47NOeSwSw";

export const categoryTypes = [
  { title: 'Contract', _id:'contract' }, 
  { title: 'Application', _id:'application' },
  { title: 'General', _id:'general' },
]

export const driving_license_classes = ['Class 1', 'Class 2', 'Class 3', 'Class 4', 'Class 5', 'Class 5 GDL', 'Class 5 ADV', 'Class 6', 'Class 7'];

export const guard_status = [
  { title: "Active", _id: "active" },
  { title: "In-Active", _id: "in-active" },
  { title: "Resigned", _id: "resigned" },
  { title: "Terminated", _id: "terminated" },
  { title: "Suspended", _id: "suspended" },
  { title: "On Leave", _id: "on-leave" }
]

export const site_types = [
  { _id: "Plaza", title: "Plaza" },
  { _id: "Condo_apartments", title: "Condo apartments" },
  { _id: "Shopping_center", title: "shopping center" },
  { _id: "shop ", title: "shop / pharmacy" },
  { _id: "Warehouse", title: "Warehouse" },
  { _id: "Office", title: "Office" },
  { _id: "Restaurant", title: "Restaurant" },
  { _id: "Farm_house", title: "Farm house" },
  { _id: "Other", title: "Other" },
]

export const filter_ref_keys = [
  { _id: 'hours.schedule', title: "Hour Schedule" }
]

export const tagsTypeArray = [
  { title: "Knowledgebase", _id: "knowledgebase" },
  { title: "Soemthing", _id: "soemthing" },
]

export const pageInfoTypesArray = [
  { title: "Text", _id: "text" },
  { title: "Textarea", _id: "textarea" },
  { title: "HTML", _id: "html" },
  { title: "URL", _id: "url" },
  { title: "Array", _id: "array" },
  { title: "Component", _id: "component" },
]


/*********************
* Rights constructor rules
{
_id: => Number
title: => String
rules: => Array [
    {
      title: => String
      key: => String => MainModuleName-ChildModuleName-2ndChildModuleName-nthChildModuleName
        * Each child module name should be preceeded by a DASH [-]
        eg: accounts-create-sales
            accounts-update-sales
            accounts-delete-sales
    }
  ]
},

**************************/
// export const availableUserTypes = [
//   { title: 'Syetem Admin', _id: "system-admin" },
//   { title: 'Manager', _id: 'manager' },
//   { title: 'Dispatch Manager', _id: 'dispatch-manager' },
//   { title: 'Dispatch', _id: 'dispatch' },
//   { title: 'Client', _id: 'client' },
//   // { title: 'super-admin', _id: 'super-admin' },
// ]


const notesTypes = [
  'hr_guard', 'guard_application_form', 'client_info_form', 'security_guard_interview', 'firewatch_log_doc'
]