import React from 'react';
import { Row, Col } from 'antd';
import { Component} from 'react';
import Logo from './alist.png'
import './style.scss'
// import 'antd/dist/antd.css';

class Header extends Component {
    
    render(props) {
        return (
            <>
                <div style={{justifyContent:"center"}}>
                    <Row className='row' align="middle" style={{ flexWrap: "nowrap", justifyContent: "center"}}>
                        <Col style={{textAlign: "center", padding:'0 10px'}}><img style={{width:"100%"}} src={Logo} /></Col>
                        <Col className='size'>
                            {/*<span className='uppercase bold' style={{ fontSize: "28px" }} >A-List Security Group INC.</span>*/}
                            <p className='bold  add' style={{ marginBottom: 0, marginTop: "50px", fontSize: "25px" }}>{this.props.title ? "Client Information Form" : "Application Form"}</p>
                            <p className='bold ' style={{width: "550px"}} >{this.props.title ? null : `Qualified applicants are considered for all positions without discrimination based on race, color, religion, sex, national origin, marital status or disability`}</p>
                            {/* <p className='bold ' >{this.props.title ? null : `race, color, religion, sex, national origin, marital status or disability`}</p> */}
                            <p style={{ margin: 0 }} className='bold '>Tel: +1 (844) 585-9595</p>
                        </Col>
                        {/* tel: +1 (844) 585-9595 */}
                    </Row>
                </div>
                {/* <hr className='line' /> */}
                {/* <div className='card' style={{padding:"20px 10px 5px 10px", textAlign:"center"}}>
                    <div className='uppercase bold cyan' style={{ fontSize: "28px", textAlign: "center" }} >{this.props.title ? <u>Client Information form</u> : <u>Application form</u> }</div>
                    <p style={{ marginBottom: "3px" }}>{this.props.title ? null : "Qualified applicants are considered for all positions without discrimination based on race, color, religion, sex, national origin, marital status or disability"}</p> */}
                    {/*<p className='cyan'><b>NOTE:</b> ALL FIELDS CONTAINING () MUST BE FILLED COMPLETELY OTHERWISE APPLICATION WILL BE CONSIDERED INCOMPLETE AND WILL NOT BE BE PROCESSED FOR CONSIDERATION.</p>*/}
                {/* </div> */}
                {/* <hr className='line' /> */}

                {/* <p className='bold uppercase add' style={{ marginBottom: 0, marginTop: "50px" }}>{this.props.title ? "Client Information form" : "Application form"}</p>
                <p className='bold uppercase add' style={{ width: "50%" }}>{this.props.title ? null : "Qualified applicants are considered for all positions without discrimination based on race, color, religion, sex, national origin, marital status or disability"}</p> */}
            </>
        )
    }
}


export default Header;
