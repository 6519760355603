import React from 'react'
import { Col, Popover, Row } from 'antd'
import { Avatar } from './Avatar'
import Icon from './Icon'


export const UserCardPopover = ({ children, userInfo }) => {

    if (!userInfo) return children;

    const UserCardBody = ({ userInfo }) => {
        return (<div style={{ width: "300px" }}>
            <Row gutter={[10, 1]}>
                <Col><Icon className="gray3" icon="briefcase" /></Col>
                <Col flex="auto" className="gray2">{userInfo.designation} Position</Col>
                <Col span={24} />

                <Col><Icon className="gray3" icon="map-marker-alt" /></Col>
                <Col flex="auto" className="gray2">{userInfo?.address?.city} Location</Col>
                <Col span={24} />

                <Col><Icon className="gray3" icon="envelope" /></Col>
                <Col flex="auto" className="gray2">{userInfo.email} Email</Col>
                <Col span={24} />

                <Col><Icon className="gray3" icon="chair" /></Col>
                <Col flex="auto" className="gray2">{userInfo.department} Department</Col>
                <Col span={24} />
            </Row>
        </div>)
    }

    const UserCardTitle = ({ userInfo }) => {
        return (<>
            <Row gutter={[10]}>
                <Col><Avatar src={userInfo && userInfo.avatar} alt={userInfo && userInfo.name} size={64} /></Col>
                <Col flex="auto">
                    <div className='gray3'>{userInfo.gender == "female" ? "She/Her" : "He/Him"}</div>
                    <div>{userInfo.name}</div>
                    <div className="gray2"><Icon className="gray3" icon="clock" /> local time: </div>
                </Col>
            </Row>
        </>)
    }

    return (<>
        <Popover placement="right" className='user-card-popover' title={() => UserCardTitle({ userInfo }) } content={() => UserCardBody({ userInfo })} trigger="hover">
            <span>{children}</span>
        </Popover>
    </>)
}
