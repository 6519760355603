import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects'
// import Api from '...'

import { TEST_SAGA, TEST_SAGA_SYNC, LOGIN_USER, LOGOUT_USER } from './saga.actions'
import { testSaga, testSagaSync, loginUser, logoutUser } from './reducers/saga_functions';


// // worker Saga: will be fired on USER_FETCH_REQUESTED actions
// function* example(action) {
//    try {
//       // do API call/
//       // const user = yield call(Api.fetchUser, action.payload.userId);
//       yield put( receiveExample('Example from redux-saga') );
//       // yield put({type: "RECEIVE_EXAMPLE", text: 'Example from redux-saga'});
//    } catch (e) {
//       yield put( receiveExample("Catch example from redux-saga") );
//    }
// }

function* actionWatcher() {
   yield takeEvery(TEST_SAGA, testSaga);
   yield takeEvery(TEST_SAGA_SYNC, testSagaSync);

   yield takeLatest(LOGIN_USER, loginUser);
   yield takeLatest(LOGOUT_USER, logoutUser);
}


/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
export default function* rootSaga() {
   // yield takeLatest("REQUEST_BRANCH", branch);
   yield all([
      actionWatcher(),
   ]);

}

