import React, { Component, useState, useEffect } from 'react'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Button, Icon, SiteSearch, GuardSearch } from 'Common/components'
import { message, Row, Col } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { connect } from "react-redux";
import { updatePageSettings } from 'Store/ep_admin.actions.js'



const SearchBar = props => {
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);

    const filter = props?.ep_admin?.pageSettings?.adressbook_searchBar;

    useEffect(() => {
        if (props.pauseAtLoad) return;
        onSubmitSearch(filter || {})
    }, [])


    const onSubmitSearch = (values, redux) => {
        setBusy(true);
        props.fetchData({ filter:values }).then(r => {
            setBusy(false);
        });

        props.updatePageSettings({
            page: 'adressbook_searchBar',
            values: {
                ...values
            }
        })
    }

    let defaultValues = { 
        // ref_key: ref_keys[0]._id, 
        ...props.ep_admin?.pageSettings?.adressbook_searchBar 
    };




    return (<>

        <FormComponent onSubmit={onSubmitSearch} id='ClientSearch' loading={busy} debug={false}
            fields={{ ...defaultValues }}
            form_render={formProps => {
                const { values, form, invalid, dirty, valid } = formProps;

                return (<>
                    <Row align="middle">
                        <Col flex='300px'>
                            <FormField name="search.keywords" placeholder={"Keyword search.."} size="medium" type="text" allowClear />
                        </Col>
                        <Col><Button type="primary" htmlType='submit'><Icon icon="search" /></Button></Col>
                    </Row>
                </>)
            }}
        />

    </>)


}
const mapStateToProps = ({ ep_admin }) => {
    // console.log("ep_admin: ", ep_admin.pageSettings.formdata_searchBar)
    return ({ ep_admin });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)

