import React, { useState } from 'react'
import { Button, DataRow, Heading, IconButton } from 'Common/components'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Alert, Col, Row, message } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper';
import { verifyRole } from 'Common/scripts/Security';

const EDIT_CLIENT = loader('src/Graphql/client/editClient.graphql');

// verifyRole('guard.sensitive-data')
const fields = [
    { name: 'site_address_street', type: "text", label: 'Street' },
    { name: 'site_address_unit', type: "text", label: 'Unit #' },
    { name: 'site_address_city', type: "text", label: 'City' },
    { name: 'site_address_state', type: "text", label: 'Province/State' },
    { name: 'site_address_zip', type: "text", label: 'Postal/ZIP Code' },
    { name: 'site_address_country', type: "text", label: 'Country' },
]

const SiteAddressForm = withApollo(({ initialValues, onUpdate, onCloseClick, client }) => {
    const [error, seteError] = useState(false);

    const renderField = (field_name, attributes) => {
        let theField = fields.find(o => o.name == field_name);
        if (!theField) return <Alert message={`Field (${field_name}) not found`} type='warning' />

        if (theField.type == "checkbox") return <FormField name={theField.name} data={theField.data} size="medium" type="checkbox" validate={theField.validate} {...attributes}>{theField.label}</FormField>
        return <FormField {...theField} size="medium" {...attributes} />
    }

    const onSubmit = async (values) => {
        const getFieldData = (field_name) => ({ name: field_name, label: fields.find(o => o.name == [field_name]).label, value: values[field_name] })

        let input = {
            _id: initialValues._id,
            // name: values.client_name,
            fields:[
                getFieldData("site_address_street"),
                getFieldData("site_address_unit"),
                getFieldData("site_address_city"),
                getFieldData("site_address_state"),
                getFieldData("site_address_zip"),
                getFieldData("site_address_country"),
            ]
        }
        // console.log({ input })

        let resutls = await client.mutate({ mutation: EDIT_CLIENT, variables: { input } }).then(r => (r.data.editClient))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request at the moment!" } }
            })

        if (resutls.error) {
            message.error(resutls.error.message);
            return false;
        }

        onUpdate(resutls);
        return false;
    }

    return (<>
        <FormComponent onSubmit={onSubmit} id='ClientForm' fields={initialValues} debug={false}
            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    {error && <Alert message="Error!" description={error} type="error" showIcon />}

                    {renderField("site_address_street", { validate: false })}
                    {renderField("site_address_unit", { validate: false })}
                    {renderField("site_address_city", { validate: false })}
                    {renderField("site_address_state", { validate: false })}
                    {renderField("site_address_zip", { validate: false })}
                    {renderField("site_address_country", { validate: false })}

                    <Row style={{ marginTop: '20px', marginRight: '10px' }}>
                        <Col flex="auto"></Col>
                        <Col>
                            <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!valid}>Save</Button>
                        </Col>
                    </Row>

                </>)
            }}
        />

    </>)
});

const ReadOnlySiteAddress = ({ data }) => {
    const renderField = (field_name) => {
        if (!data.fields) return null;
        let thisField = data.fields.find(o => o.name == field_name);
        let theField = fields.find(o => o.name == field_name)
        if (!thisField) return null;

        return <DataRow col1={theField.label} col2={thisField.value} inline size={['150px']} />
    }

    return (<>
        {fields.map((o, i) => <div key={i}>{renderField(o.name)}</div>)}
    </>)
}

export default function SiteAddress({ data, onUpdate }) {
    const [edit, set_edit] = useState(false);

    let initialValues = { _id: data._id }
    data?.fields?.forEach(field => {
        Object.assign(initialValues, { [field.name]: field.value })
    });

    const onDataUpdate = (values) => {
        onUpdate(values);
        set_edit(false);
    }

    return (<>
        {/* <h3 style={{ marginBottom:"0px" }}>Site Address <IconButton onClick={() => set_edit(!edit)} icon={edit ? "times" : "pen"} /></h3> */}
        <Heading>Site Address <IconButton onClick={() => set_edit(!edit)} icon={edit ? "times" : "pen"} /></Heading>

        {edit && <SiteAddressForm initialValues={initialValues} onUpdate={onDataUpdate} onCloseClick={() => set_edit(false)} />}
        {!edit && <ReadOnlySiteAddress data={data} />}

    </>)
}
