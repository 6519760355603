/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { Form } from 'react-final-form';
import { Button, DevBlock, Icon, IconButton } from 'Common/components'
import { Row, Col, message, Result, Alert } from 'antd'
import SignaturePad from 'react-signature-canvas'
import { loader } from 'graphql.macro';
import Header from '../Components/Header';
import { withApollo } from '@apollo/client/react/hoc';
import { CLIENT_INFO_FORM } from '../../constants';
import { sleep } from 'Common/scripts/Functions';
import { __error } from 'Common/scripts/consoleHelper';

const ADD_DATA = loader('src/Graphql/FormsData/addFormsData.graphql');
export const FORM_KEY = CLIENT_INFO_FORM;

const fields = [
  // Client Information
  { name: 'client_name', label: 'Client Name', type:"text", defaultValue: "Client name 1" },
  { name: 'client_compnay_name', type: "text", label: 'Company Name', sub_label:"(if applicable)",  defaultValue:"client compnay" },
  { name: 'client_phone', type: "text", label: 'Phone Number', defaultValue:"000000000" },
  { name: 'client_email', type: "text", label: 'Email address', defaultValue:"client@mail.com" },

  // Client Contact Person
  { name: 'contact_person', type: "text", label: 'Contact Title', defaultValue:"client contact person" },
  { name: 'contact_email', type: "text", label: 'Email Address', defaultValue:"client_contact@mailcom" },
  { name: 'contact_pri_name', type: "text", label: 'Phone Number (Primary)', defaultValue:"000000000" },
  { name: 'contact_alt_name', type: "text", label: 'Phone Number (Alternate)', defaultValue:"111111111" },
  { name: 'contact_preff_method', data: [
      { _id: "Phone", title: "Phone" },
      { _id: "Email", title: "Email" },
    ], type: "select", label: 'Preferred Method of Contact' },

  // Site Address
  { name: 'site_address_street', type: "text", label: 'Street Address', defaultValue:"site street address XYZ" },
  { name: 'site_address_unit', type: "text", label: 'Unit #', defaultValue:"0000" },
  { name: 'site_address_city', type: "text", label: 'City', defaultValue:"Some city" },
  { name: 'site_address_state', type: "text", label: 'Province/State', defaultValue:"some state" },
  { name: 'site_address_zip', type: "text", label: 'Postal/ZIP Code', defaultValue:"12345" },
  { name: 'site_address_country', type: "text", label: 'Country', defaultValue:"CA" },

  // Emergency Contact Information
  { name: 'emergency_name', type: "text", label: 'Name', defaultValue:"emergency contact person name" },
  { name: 'emergency_phone', type: "text", label: 'Phone Number', defaultValue:"0000000000" },
  { name: 'emergency_email', type: "text", label: 'Email Address', defaultValue:"emergency@mail.com" },
  
  // Billing Address(if different from above)
  { name: 'bil_add_street', type: "text", label: 'Street Address', defaultValue:"billing street address" },
  { name: 'bil_add_city', type: "text", label: 'City', defaultValue:"billing city" },
  { name: 'bil_add_state', type: "text", label: 'Province/State', defaultValue:"billing state" },
  { name: 'bil_add_zip', type: "text", label: 'Postal/ZIP Code', defaultValue:"123456" },
  { name: 'bil_add_country', type: "text", label: 'Country', defaultValue:"country" },
  
  // Security Requirements
  { name: 'sec_req_type', type: "text", label: 'Type of Security Service Required', placeholder: "e.g., Uniformed Guards, Mobile Patrol, Event Security, etc.", defaultValue: "security req. tupe" },
  { name: 'sec_req_num_guards', type: "text", label: 'Number of Security Guards Required', defaultValue:"10" },
  { name: 'sec_req_shift_schedule', type: "text", label: 'Shift Schedule', placeholder: "e.g., 24/7, Weekdays, Weekends, Specific Hours", defaultValue:"24/7" },
  { name: 'sec_req_location', type: "textarea", label: 'Location(s) to be Secured', placeholder: "Provide addresses if different from the client address", defaultValue:"security address" },
  { name: 'sec_req_purpose', type: "textarea", label: 'Purpose of Security Service', placeholder: "e.g., Property Protection, Crowd Control, Access Control", defaultValue:"some security purpose" },
  { name: 'sec_req_instructions', type: "textarea", label: 'Any Specific Instructions or Requirements', defaultValue:"security instructions" },
  
  // Additional Information
  { name: 'have_washroom', type: "checkbox", label: 'Is there a washorm on site?', defaultValue:true },
  { name: 'have_lunchroom', type: "checkbox", label: 'Is There a Lunch Room On Site?', defaultValue:false },
  { name: 'how_did_you_hear', type: "text", label: 'How did you hear about A-List Security Group Inc.?', defaultValue:"from friend" },
  { name: 'sec_compnay_worked_with', type: "checkbox", label: 'Have you worked with a security company before?', sub_label:"(If yes, please provide details)", defaultValue:false },
  { name: 'sec_compnay_details', type: "textarea", label: 'Details' },
  
  // Additional Comments or Questions
  { name: 'additional_comments', type: "textarea", label: 'Additional Comments or Questions', placeholder: "Please use this space to provide any additional information or ask questions regarding our security services" },

]
const defaultValues = {}
fields.forEach(field => {
  if (field.defaultValue) Object.assign(defaultValues, { [field.name]: field.defaultValue })
});


const SuccessScreen = () => {
  return (<div style={{ backgroundColor: '#001529', flex: 1, justifyContent: "center", alignItems: "center", display: 'flex' }}>
    <Result
      style={{ width:"800px" }}
      status="success"
      title={<span style={{ color: 'white' }}>Thank you for considering A-List Security Group Inc.</span>}
      extra={<>
        <p style={{ color:"#FFF", fontSize:"18px" }}>We look forward to providing you with top-notch security services.If you have any immediate questions, please don't hesitate to contact us at <br />+1 (844) 585-9595.</p>
        <img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-light.png`} />
      </>}
    // extra={<img src="https://sp-ao.shortpixel.ai/client/q_glossy,ret_img/https://alistsecurity.com/wp-content/uploads/2021/03/Website-Logo-Dark-Ver.-2.png" />}
    />
  </div>)
}

const SectionHeading = ({ children, style }) => <div style={{ fontSize: "25px", color: "#060079", marginTop: "30px", ...style }}>{children}</div>

class ClientQueryFormComp extends Component {
  signaturePad = {}

  constructor(props) {
    super(props)
    this.state = { loading: false, data: null, success: false, signatureDataURL:null }
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values) {

    // await sleep(100)
    this.setState({ error: null })

    if (!this.state.signatureDataURL || this.state.signatureDataURL.length < 5){
      alert("Please sign the document at the bottom")
      return false;
    }

    const input = {
      ref_key: FORM_KEY,
      title: `${values.client_name}`,
      signature: this.state.signatureDataURL || undefined,
      
      fields: fields.map(o => {
        let value = values[o.name];
        if (o.type == "checkbox") value = (o.value === true) ? "yes" : "no";
        return {
          name: o.name,
          label: o.label,
          value,
        }
      })
    }

    let resutls = await this.props.client.mutate({ mutation: ADD_DATA, variables: { input } }).then(r => (r.data.addFormsData))
    .catch(err => {
      console.log(__error("ERROR: "), err);
      return { error: { message:"Failed to process the request at the moment!" } }
    })

    if (resutls.error){
      message.error(resutls.error.message);
      this.setState({ error: resutls.error.message })
      return false;
    }

    this.setState({ success: resutls })
    return false;

  }

  renderField(field_name, attributes){
    let theField = fields.find(o => o.name == field_name);
    if (!theField) return <Alert message={`Field (${field_name}) not found`} type='warning' />

    if (theField.type == "checkbox") return <FormField name={theField.name} data={theField.data} size="medium" type="checkbox" validate={theField.validate} {...attributes}>{theField.label} {theField.sub_label && `(${theField.sub_label})`}</FormField>
    return <FormField {...theField} label={<>{theField.label} {theField.sub_label && <small>{theField.sub_label}</small>}</>} size="medium" {...attributes} />
  }

  clearSignature = (event) => {
    event.preventDefault()
    this.signaturePad.clear()
    if (this.state.signatureDataURL !== null) this.setState({ signatureDataURL: null })
  }

  trimSignature = (event) => {
    event.preventDefault()
    const signatureDataURL = this.signaturePad.getTrimmedCanvas().toDataURL('image/png');

    this.setState({ signatureDataURL }, () => {
      // console.log("this is the url of sign pad", this.state.signatureDataURL)
    })
  }



  render() {
    const { busy, error, success } = this.state;

    if (success) return <SuccessScreen />

    return(<>
      <div style={{ backgroundColor: "#f8f9fa" }} className="form-data">
        <Header title="Client Information Form" />

        <div style={{ display: 'flex', justifyContent: "center" }}>
          <div style={{ maxWidth: "1200px", width: "100%", margin: "20px" }}>

            <Form onSubmit={this.onSubmit} initialValues={defaultValues}>
              {({ handleSubmit, submitting, form, values, invalid, dirty, valid }) => (<>

                <form className="ClientInfoForm" onSubmit={handleSubmit}>

                  <div className='card'>
                    <SectionHeading style={{ marginTop: 0 }}>Client Information</SectionHeading>
                    <Row>
                      <Col lg={6} span={24}>{this.renderField("client_name", { validate: rules.required })}</Col>
                      <Col lg={6} span={24}>{this.renderField("client_compnay_name", { validate: rules.required })}</Col>
                      <Col lg={6} span={24}>{this.renderField("client_phone", { validate: false })}</Col>
                      <Col lg={6} span={24}>{this.renderField("client_email", { validate: false })}</Col>
                    </Row>

                    <SectionHeading>Client Contact Person</SectionHeading>
                    <Row>
                      <Col md={12} span={24}>{this.renderField("contact_person", { validate: rules.required })}</Col>
                      <Col md={12} span={24}>{this.renderField("contact_email", { validate: rules.required })}</Col>
                      <Col md={12} span={24}>{this.renderField("contact_pri_name", { validate: rules.required })}</Col>
                      <Col md={12} lg={6} span={24}>{this.renderField("contact_alt_name", { validate: rules.required })}</Col>
                      <Col md={12} lg={6} span={24}>{this.renderField("contact_preff_method", { validate: rules.required })}</Col>
                    </Row>

                    <SectionHeading>Site Address</SectionHeading>
                    <Row>
                      <Col lg={9} md={12} span={24}>{this.renderField("site_address_street", { validate: rules.required })}</Col>
                      <Col lg={3} md={12} span={24}>{this.renderField("site_address_unit", { validate: rules.required })}</Col>
                      <Col lg={3} md={12} span={24}>{this.renderField("site_address_city", { validate: rules.required })}</Col>
                      <Col lg={3} md={12} span={24}>{this.renderField("site_address_state", { validate: rules.required })}</Col>
                      <Col lg={3} md={12} span={24}>{this.renderField("site_address_zip", { validate: rules.required })}</Col>
                      <Col lg={3} md={12} span={24}>{this.renderField("site_address_country", { validate: rules.required })}</Col>
                    </Row>

                    <SectionHeading>Billing Address (if different from above)</SectionHeading>
                    <Row>
                      <Col lg={12} md={12} span={24}>{this.renderField("bil_add_street")}</Col>
                      <Col lg={3} md={12}  span={24}>{this.renderField("bil_add_city")}</Col>
                      <Col lg={3} md={12} span={24}>{this.renderField("bil_add_state")}</Col>
                      <Col lg={3} md={12} span={24}>{this.renderField("bil_add_zip")}</Col>
                      <Col lg={3} md={12} span={24}>{this.renderField("bil_add_country")}</Col>
                    </Row>

                    <SectionHeading>Emergency Contact Information</SectionHeading>
                    <Row>
                      <Col lg={8} md={12} span={24}>{this.renderField("emergency_name", { validate: rules.required })}</Col>
                      <Col lg={8} md={12} span={24}>{this.renderField("emergency_phone", { validate: rules.required })}</Col>
                      <Col lg={8} md={12} span={24}>{this.renderField("emergency_email", { validate: rules.required })}</Col>
                    </Row>

                    <SectionHeading>Security Requirements</SectionHeading>
                    <Row>
                      <Col span={24}>{this.renderField("sec_req_type", { validate: rules.required })}</Col>
                      <Col lg={6} span={24}>{this.renderField("sec_req_num_guards", { validate: rules.required })}</Col>
                      <Col lg={18} span={24}>{this.renderField("sec_req_shift_schedule", { validate: rules.required })}</Col>
                      <Col lg={12} span={24}>{this.renderField("sec_req_location", { validate: rules.required })}</Col>
                      <Col lg={12} span={24}>{this.renderField("sec_req_purpose", { validate: rules.required })}</Col>
                      <Col span={24}>{this.renderField("sec_req_instructions", { validate: rules.required })}</Col>
                    </Row>

                    <SectionHeading>Additional Information</SectionHeading>
                    <Row>
                      <Col lg={12} span={24}>{this.renderField("have_washroom")}</Col>
                      <Col lg={12} span={24}>{this.renderField("have_lunchroom")}</Col>
                      <Col span={24}>{this.renderField("how_did_you_hear", { validate: rules.required })}</Col>
                      <Col span={24}>{this.renderField("sec_compnay_worked_with")}</Col>
                      <Col span={24}>{this.renderField("sec_compnay_details", { disabled: !values.sec_compnay_worked_with })}</Col>
                    </Row>

                    <SectionHeading>Additional Comments or Questions</SectionHeading>
                    {this.renderField("additional_comments")}


                    <SectionHeading style={{ marginTop: "30px" }}>Client's Signature</SectionHeading>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "20px auto" }}>
                      <div style={{ display: "flex", flexDirection: "column", position: "relative" }} >
                        <span className="bold uppercase">Please sign the document</span>
                        <span style={{ border: "1px solid #CCC", borderRadius: "5px" }}><SignaturePad
                          dotSize={1}
                          throttle={50}
                          velocityFilterWeight={0.1}
                          onEnd={this.trimSignature}
                          backgroundColor="#FFF"
                          canvasProps={{ width: 500, height: 200 }}
                          required ref={(ref) => { this.signaturePad = ref }} /></span>
                        <IconButton onClick={this.clearSignature} icon="sync-alt" color="green" style={{ position: "absolute", top: '28px', right: "5px" }} size="2x" />
                      </div>
                    </div>

                  </div>


                  {/* <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} >Submit </Button> */}

                    <Alert message="Once completed, we will review your information and get in touch with you to discuss your security needs further" type="info" showIcon />
                  <div style={{ padding:"40px" }} >
                    <Button type="primary" block htmlType='submit' style={{ marginBottom: "50px", height: "40px" }} disabled={!dirty || !valid} loading={submitting}>Submit Application</Button>
                  </div>

                </form>
              </>)}
            </Form>


          </div>
        </div>
      </div>
    </>)

  }
}

export const ClientQueryForm = withApollo(ClientQueryFormComp);
export default ClientQueryForm;
