import { uriRoot } from 'configs';

export const ROOT = '/threads';
export const ROOT_PATH = `${uriRoot}/threads`;
export const MODULE = 'THREADS';


export const thread_priority_array = [
    { _id: "normal", title: "Normal" },
    { _id: "high", title: "High" },
]
export const channel_status_array = [
    { _id: "active", title: "Active" },
    { _id: "inactive", title: "Inactive" },
]
export const ticket_status_array = [
    { _id: "open", title: "Open" },
    { _id: "closed", title: "Closed" },
    { _id: "archived", title: "Archived" },
]