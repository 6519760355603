/* eslint-disable eqeqeq */
import _ from 'lodash';
import base64 from 'base-64';
import moment from 'moment';
import moment_tz from 'moment-timezone';
import { __error } from './consoleHelper'
import { defaultDateFormat, defaultTZ } from 'configs'



export const timestamp = () => moment().valueOf();

/*************************** MOngo Record filters FUNCTIONS *******************
 *
*/
export const omitTypeName = (obj) => {
  // console.log("omitTypeName()", obj);
  let o = {};
  for(let b in obj){
    if(b!='__typename')
      o[b] = obj[b];
  }
  // console.log("omitTypeName()", o);
  return o;
}

export const omitAllTypeName = (obj) => {

  if(_.isArray(obj) || _.isObject(obj))
  {
    let o = _.isArray(obj) ? [] : {};
    for(let b in obj)
    {
      if(b!='__typename'){
        o[b] = _.isString(obj[b]) ? obj[b] : omitAllTypeName(obj[b]); //_.isObject(obj) ? omitAllTypeName(obj[b]) : obj[b];
      }
    }
    obj = o;
  }

  return obj;

  // _.isArray()
  // _.isString()
}

export const formToFilter = (fields) => {
  let temp;
  let filter = {}
  let keys = Object.keys(fields);

  const translateFilterValue = (val) => {
    if (val instanceof moment) return dateToMongo(val);
    if (_.isString(val)) return String(val);
    if (_.isInteger(val)) return Number(val);
    if (val.keywords) return val;
    return false;
  }

  keys.forEach(element => {
    let val = fields[element];

    if (!val) { }
    // else if (val.keywords) filter[element] = val
    else if (translateFilterValue(val)) filter[element] = translateFilterValue(val);
    else {
      temp = {};
      if (val.gt) temp = Object.assign(temp, { "$gt": translateFilterValue(val.gt) });
      else if (val.gte) temp = Object.assign(temp, { "$gte": translateFilterValue(val.gte) });
      else if (val.lt) temp = Object.assign(temp, { "$lt": translateFilterValue(val.lt) });
      else if (val.lte) temp = Object.assign(temp, { "$lte": translateFilterValue(val.lte) });
      else {
        console.log(__error("Invalid filter element"), val);
      }
      if (Object.entries(temp).length > 0) filter[element] = temp;
    }
  });
  // console.log("filter: ", filter);
  return filter;
}



/*************************** GENERAL FUNCTIONS *******************
 *
*/
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const string_to_slug = str => {
  if (!str || str.length < 1) return "";

  //# Ver 1
  // return String(str).replace(/[^a-z0-9]/gi, '-').replace(/-+/g, '-').replace(/^-|-$/g, '').toLowerCase();

  //# Ver 2
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaaaeeeeiiiioooouuuunc------";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .toString()
    .normalize('NFD')
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // replace whitespace by -
    .replace(/-+/g, '-') // replace dashes
    .replace(/&/g, '-y-') // Replace & with 'and'
    // .replace(/\-\-+/g, '-') // Replace multiple - with single -
    // .replace(/^-+/, "") // trim - from start of text
    // .replace(/-+$/, "") // trim - from end of text
    .trim() // Remove whitespace from both sides of a string

  return str;
}

export const removeSpecialChar = (str, options) => {
  const { replaceWith, filter } = options || {};
  var new_str = str;

  let regxString = "`*;*~*!*#*@*\\$*%*\\^*&*\\**\\(*\\)*\\+*\\{*\\}*\\|*\\[*\\]*/*" 
  let _regx = new RegExp(regxString, 'gi')
  
  // if (filter && filter.space) _regx = /[`~!@#$%^&*|+=?;'"<>\s\{\}\[\]\\\/]+/gi

  try {
    // new_str = str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/[\s]+/gi, " ");
    // new_str = str.replace(/[`~!^|+=;'"<>\{\}\[\]\\\/]/gi, '').replace(/[\s]+/gi, " ");
    // new_str = str.replace(/[`~!@#$%^&*|+=?;'"<>\s\{\}\[\]\\\/]+/gi, '').trim();
    new_str = str.replace(_regx, (replaceWith || '')); //.trim();
    // if (!(filter && filter.trim === false)) new_str = new_str.trim()

    if (filter && filter.space) new_str = new_str.replace(/\s/gi, (replaceWith || ''))
    if (filter && filter.trim) new_str = new_str.trim()

  } catch (error) {
  }

  return new_str;
}



export const parseNewLine = (txt) => (txt.split("\n")?.map((o, i) => (<div key={i}>{o}</div>)))



/*************************** ARRAY FUNCTIONS *******************
 *
*/
function dynamicSort(property) {
  /***
   var People = [
      {Name: "Name", Surname: "Surname"},
      {Name:"AAA", Surname:"ZZZ"},
      {Name: "Name", Surname: "AAA"}
    ];

    People.sort(dynamicSort("Name"));
    People.sort(dynamicSort("Surname"));
    People.sort(dynamicSort("-Surname"));
   */
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers, 
     * and you may want to customize it to your needs
     */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}
function dynamicSortMultiple() {
  /*
   * save the arguments object as it will be overwritten
   * note that arguments object is an array-like object
   * consisting of the names of the properties to sort by
   * 
   * 
   People.sort(dynamicSortMultiple("Name", "-Surname"));

   */
  var props = arguments;
  return function (obj1, obj2) {
    var i = 0, result = 0, numberOfProperties = props.length;
    /* try getting a different result from 0 (equal)
     * as long as we have extra properties to compare
     */
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i])(obj1, obj2);
      i++;
    }
    return result;
  }
}



export const constructCategoryArray = (allCats, parent = null) => {
  let arr = allCats?.filter(word => word.parent_cat_id == parent).map(item => ({
    ...item,
    // _id: item._id,
    // title: item.title,
    // slug: item.slug,
    // status: item.status,
    // menu_bg_img: item.menu_bg_img,
    // title_img: item.title_img,
    children: constructCategoryArray(allCats, item._id),
  }))

  return arr;
}


// export const searchArrayByObjIndex = (arr, index) => {
//   let obj = arr.find(o => o[index]);
//   return obj;
// }


/*************************** JSON FUNCTIONS *******************
 *
*/
export const jsonStringify = (jsonObj, a=0, b=0) => {
  if(!jsonObj) return jsonObj;
  let result = false;

  try{
            if (typeof jsonObj!=="string"){
              result = JSON.stringify(jsonObj, a, b);
            }else{
                result = false;
            }
  }
  catch (error){
      console.error("ERROR : jsonStringify()", error);
  }

  return result;
}

export const parseJson = (jsonString) => {
  let jsonObject = {};

  if(!jsonString) return jsonObject;

  try{
            if (typeof jsonString!=="string"){
                // return false;
            }else{
                jsonObject = JSON.parse(jsonString);
                // return jsonObject;
            }
  }
  catch (error){
            console.log("ERROR : parseJson()", error);
  }

  return jsonObject;
}

export const decode64 = (str) => {
  let _str = "";
  if(!str) return _str;
  try{
            if (typeof str!=="string"){
                // return false;
            }else{
                _str = base64.decode(str);
                // return jsonObject;
            }
  }
  catch (error){   console.log("ERROR : decode64()", error);   }
  return _str;
}



/**** Image functions ************/
/*
getBase64(File Field Object)
*/
export const getBase64 = (file, as='DataURL') => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // reader.onload = () => resolve(reader);
    reader.onload = () => {
      // console.log("FileReader: ", reader);
      resolve(reader.result)
    }
    reader.onerror = error => reject(error);
  });
}

// export const getSrcFromFile = (file) => {
//   return new Promise((resolve) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = () => resolve(false);
//   });
// };

export const resizeImage = (_image, maxWidth=300, maxHeight=300) => {

    return new Promise(async function(resolve,reject){
      var thumbnailWidth, thumbnailHeight;
      var image = new Image();
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');

      image.onload = function(){
        //Calculate the size of the thumbnail, to best fit within max/width (cropspadding)
        var thumbnailScale = (image.width / image.height) > (maxWidth / maxHeight) ?
        maxWidth / image.width : maxHeight / image.height;
        thumbnailWidth = image.width * thumbnailScale;
        thumbnailHeight = image.height * thumbnailScale;

        // set its dimension to target size
        canvas.width = thumbnailWidth;
        canvas.height = thumbnailHeight;

        // draw source image into the off-screen canvas:
        ctx.drawImage(image, 0, 0, thumbnailWidth, thumbnailHeight);

        //Draw border (optional)
        // ctx.rect(0, 0, thumbnailWidth, thumbnailHeight - 1);
        // ctx.strokeStyle = "#555555";
        // ctx.stroke();

        resolve(canvas.toDataURL('image/png', 70));
        // resolve(canvas.toDataURL('image/jpeg', 70));
        // return canvas.toDataURL('image/jpeg', 70);
      }

      image.src = _image;

    });

}



/*************************** DATE FUNCTIONS ******************* 
 * 
*/
export const dateToMongo = (_date, returnString = true) => {
  return returnString ? moment_tz.utc(_date).format() : moment_tz.utc(_date);
}

export const mongoToDate = (_date, format) => {
  // return moment_tz.utc(_date).tz("Canada/Mountain");//America/Toronto
  if (format) return moment_tz.utc(_date, format).tz(defaultTZ);//America/Toronto
  return moment_tz.utc(_date).tz(defaultTZ);//America/Toronto
}

export const dateToCanada = (date_string, as = false) => {
  // console.log("\n\n dateToCanada() \n")

  let caTime = moment.tz(defaultTZ);

  let to_moment = date_string ? moment.parseZone(date_string) : caTime;
  let to_ca_zone = to_moment.tz(defaultTZ, true);

  return as ? to_ca_zone.format(as) : to_ca_zone;
}

export const toPkGMT = (_time, keepLocalTime=true) => {
  return moment(_time).utcOffset(5, keepLocalTime);
}
export const toUTCDate = timeString => {
  console.log("converting to utc: ", timeString);

  if (!_.isString(timeString)){
    alert("Conversation to UTC failed, must provide time string");
    return;
  }
  // get timeDate only
  let m = moment(timeString).format(`${defaultDateFormat} hh:mm:ss`);
  // convert to UTC
  m = moment_tz.utc(m, `${defaultDateFormat} hh:mm:ss`);
  console.log(m);
  return m;
}



// export const getTimeDifference = _date => {
export const getTimeDifference = ({ before, after }) => {

  if (!before || !after) return "Invalid Date";

  var _before = moment(before || new Date()); //todays date
  var _after = moment(after || new Date()); // another date
  var duration = moment.duration(_after.diff(_before));

  var years = duration.asYears();
  if (years >= 1) return `${Math.floor(years)} Years`;

  var months = duration.asMonths();
  if (months >= 1) return `${Math.floor(months)} Months`;

  var weeks = duration.asWeeks();
  if (weeks >= 1) return `${Math.floor(weeks)} Weeks`;

  var days = duration.asDays();
  if (days >= 1) return `${Math.floor(days)} Days`;

  var hours = duration.asHours();
  if (hours >= 1) return `${Math.floor(hours)} Hours`;

  var minutes = duration.asMinutes();
  if (minutes >= 1) return `${Math.floor(minutes)} Minutes`;

  return `A few seconds`;

}

export const convertTo24Hrs = timeString => {
  let newStr = String(timeString);
  if (newStr.length < 4)
    for (let a = 0; a < (4 - newStr.length); a++)
      newStr = "0" + newStr;
  return newStr;
}

export const convertTo12Hrs = timeString => {
  let _timeString = convertTo24Hrs(timeString);
  return moment(_timeString, ["HHmm"]).format("hh:mm A");
}



/************* Detect if color is dark or light ************** */
export const lightOrDark = (color) => {
  var r,g,b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  }
  else {

    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'
    )
    );

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  console.log("hsp: ", hsp);

  // Using the HSP value, determine whether the color is light or dark
  // if (hsp > 127.5) return 'light';
  if (hsp > 140) return 'light';
  else return 'dark';

}





export const min2Hrs = (mins) => {
  let difference = mins;

  let decimalHrs = difference / 60;
  let decimalMins = decimalHrs % 1;

  return {
    raw: mins,
    decimalHrs,
    decimalMins,
    hrs: decimalHrs - decimalMins,
    min: difference - ((decimalHrs - decimalMins) * 60),
    minDiff: difference,
  }


  // let decimalHrs = mins / 60;
  // let decimalValue = decimalHrs % 1;
  // let isDecimal = decimalValue > 0;
  // let minutes = Math.floor(60 * decimalValue);
  // let hours = (mins - minutes) / 60;

  // // console.log({ decimalHrs, decimalValue, isDecimal, minutes, hours })
  // return { decimalHrs, decimalValue, isDecimal, minutes, hours };
}

/*
getTimeDiff('2022-04-01', '2022-05-01', 'minutes')
> param1: Date string
> param2: Date string
> param3: return as [years, months, weeks, days, hours, minutes, seconds]
> *** param3:split >> this will provide an object
*/
export const getTimeDiff = (timeString_1, timeString_2, as = 'string') => {
  if (!_.isString(timeString_1) || !_.isString(timeString_2)) {
    alert("Need string value to calculate time difference");
    return null;
  }

  let d1 = mongoToDate(timeString_1);
  let d2 = mongoToDate(timeString_2);

  // handel Day Saving Time
  let d1Dst = d1.isDST();
  let d2Dst = d2.isDST();
  // if (d1Dst !== d2Dst && d2Dst === true) d2.add(1, 'hour');// d2.add(d2.utcOffset(), 'm')
  if (d1Dst !== d2Dst && d2Dst === true){
    // d2.add(d2.utcOffset(), 'm')

    // get offset dif between both dates, and add it to ending date
    let utc1 = d1.utcOffset();
    let utc2 = d2.utcOffset();
    let utc_diff = utc2 - utc1;

    d2.add(utc_diff, 'm')
  }

  // calculate total duration
  var duration = moment.duration(d2.diff(d1));

  // duration in hours/minutes
  var days = parseInt(duration.asDays());
  var hours = parseInt(duration.asHours());
  var minutes = parseInt(duration.asMinutes()) % 60;

  var minDiff = d2.diff(d1, 'minutes');
  if (as == "minutes") return minDiff;

  let decimalHrs = minDiff / 60;
  let decimalMins = decimalHrs % 1;

  if(as=="string"){
    if (days >= 1) return `${days} days ago`
    if (hours >= 1) return `${days} hrs ago`
    if (minutes >= 1) return `${days} min ago`
  }

  if(as=='split'){
    return {
      raw: minDiff,
      decimalHrs,
      decimalMins,
      hrs: hours, // hrs: decimalHrs - decimalMins,
      min: minutes, // min: difference - ((decimalHrs - decimalMins) * 60),
      days,
      minDiff,
    }
  }

  return d2.diff(d1, as); // AS > [years, months, weeks, days, hours, minutes, seconds]


}

export const BK_getTimeDiff = (timeString_1, timeString_2, as='split') => {
  if (!_.isString(timeString_1) || !_.isString(timeString_2)){
    alert("Need string value to calculate time difference");
    return null;
  }

  let format = `${defaultDateFormat} HH:mm'`;

  let d1 = mongoToDate(timeString_1);
  let d2 = mongoToDate(timeString_2);

  // handel Day Saving Time
  let d1Dst = d1.isDST();
  let d2Dst = d2.isDST();

  console.log(`${d1.format()} = ${d2.format()}`)
  console.log({ d1Dst, d2Dst })
  if (d1Dst !== d2Dst && d2Dst === true) d2.add(1, 'hour');// d2.add(d2.utcOffset(), 'm')
  console.log(d2.diff(d1, 'hours'))

  let new_d1 = moment(d1.format(format), format);
  let new_d2 = moment(d2.format(format), format);

  let difference = 0;

  if (as =='split'){
    difference = new_d2.diff(new_d1, 'minutes');
    return min2Hrs(difference);

    // let decimalHrs = difference / 60;
    // let decimalMins = decimalHrs % 1;

    // return {
    //   decimalHrs,
    //   decimalMins,
    //   hrs: decimalHrs - decimalMins,
    //   min: difference - ((decimalHrs - decimalMins) * 60),
    //   minDiff: difference,
    // }

    // let decimal_hrs = difference / 60;
    // let minutes = (difference / 60 ) % 1;

    // console.log("minutes: ", minutes*100)
    // console.log({ 
    //   hrs: decimal_hrs - minutes, 
    //   minutes 
    // })

  }else{
    difference = new_d2.diff(new_d1, as); // AS > [years, months, weeks, days, hours, minutes, seconds]
  }


  return difference;
}




export const getWindowDimensions = () => {
  const hasWindow = typeof window !== 'undefined';
  const width = hasWindow ? window.innerWidth : null;
  const height = hasWindow ? window.innerHeight : null;
  return {
    width,
    height,
  };
}

export const showNotification = (message, options = {}) => {
  if (!("Notification" in window)) {
    alert("Browser does not support desktop notification");
    return;
  } else {
    if (Notification.permission === "granted") { }
    else Notification.requestPermission();
  }

  let _options = {
    dir: "auto",  // rtl, ltr
    badge: "/logo192.png", // A string containing the URL of the image used to represent the notification when there isn't enough space to display the notification itself.
    // body: threadsTicketsUpdated.message.body,
    // tag, // A string representing an identifying tag for the notification. The default is the empty string.
    icon: "/logo192.png", // A string containing the URL of an icon to be displayed in the notification.
    image: "/logo192.png", // a string containing the URL of an image to be displayed in the notification.
    // data: // Arbitrary data that you want associated with the notification. This can be of any data type.
    vibrate: true,
    // renotify: , // A boolean value specifying whether the user should be notified after a new notification replaces an old one. The default is false, which means they won't be notified. If true, then tag also must be set.
    // requireInteraction: // Indicates that a notification should remain active until the user clicks or dismisses it, rather than closing automatically. The default value is false.
    // actions: [
    //   { action: "A string identifying a user action to be displayed on the notification." },
    //   { title: "A string containing action text to be shown to the user." },
    //   { icon: "/logo192.png" }
    // ],
    ...options
  }

  new Notification(message, _options)
}




export const scrollTo = (id) => {
  if (!id || id.length < 5 || id=="null") return;
  var el = document.getElementById(id);
  // el.scrollIntoView();

  el?.scrollIntoView({
    behavior: 'smooth', // smooth scroll
    block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
  })
}


