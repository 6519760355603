import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { mongoToDate, sleep, scrollTo, getTimeDiff, getTimeDifference, parseNewLine } from 'Common/scripts/Functions';
import { Col, Row, Tooltip, message, Avatar as AntAvatar, Image, Divider } from 'antd';
import html2canvas from 'html2canvas';
import { Avatar, Button, Icon, Loader, MoticonIcon, MoticonMenu, UserCardPopover, ShowAttachments } from 'Common/components';
import { ContentArea } from 'Layout_v1';
import { defaultDateTimeFormat } from 'configs';
import { connect } from "react-redux";
import { verifyRole } from 'Common/scripts/Security';

const DISCARD_MESSAGE = loader('src/Graphql/threads/discardThreadsTicketMessage.graphql');
const DELETE_MESSAGE = loader('src/Graphql/threads/deleteThreadsTicketMessage.graphql');
const REACT_TO_MESSAGE = loader('src/Graphql/threads/reactToThreadsTicketMessage.graphql');

function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(
                ([entry]) => setIsIntersecting(entry.isIntersecting),
                { rootMargin: "-100px" }
            ),
        [],
    );

    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}

// const ShowAttachments = ({ attachments }) => {
//     if(!attachments || attachments.length<1) return null;

//     return (<>
//         {/* <div style={{ fontWeight: "bold" }}><Icon icon="paperclip" /> {attachments.length} attachments</div> */}
//         <hr />
        
//         <Row gutter={[10, 5]}>
//             {attachments.map((item, i) => {
//                 let type = item.type.split("/")[1]

//                 return (<Col key={i}>
//                     {(['jpeg', 'jpg', 'png', 'gif'].includes(type)) ? 
//                         <Image width={50} src={`${process.env.REACT_APP_DATA_URL}/${item.srcUrl}`} /> :
//                         <a href={`${process.env.REACT_APP_DATA_URL}/${item.srcUrl}`} target="_blank"><Avatar alt={type} tooltip={item.title} shape="square" size={50}>{type}</Avatar></a>
//                     }
//                 </Col>)
//             })}
//         </Row>
//     </>)
// }


export const ThreadMessageComp = ({ messageData, ticket_status, ticket_id, onReplyTo, header, user, compact }) => {
    const [viewed, set_viewed] = useState(false);
    const [viewed_updated, set_viewed_updated] = useState(false);
    const [busy, set_busy] = useState(false);
    const exportRef = useRef();

    const [discardThis, { data, loading, error }] = useMutation(DISCARD_MESSAGE);
    const [deleteThis, del_response] = useMutation(DELETE_MESSAGE);
    const [reactToThreadsTicketMessage, reaction_response] = useMutation(REACT_TO_MESSAGE);

    const ref1 = useRef(null);
    const isInViewport = useIsInViewport(ref1);

    useEffect(() => {
        if (viewed || viewed_updated || !isInViewport) return;
        set_viewed_updated(true);

        sleep(500).then(r => {
            set_viewed(true);
        })

    }, [isInViewport])

    const discardMessage = async () => {
        set_busy(true);
        let results = await discardThis({ variables: { id: messageData._id, ticket_id } }).then(r => {
            return r.data.discardThreadsTicketMessage;
        });
        set_busy(false)

        if (results.error) message.error(results.error.message);
        else message.success("Message discarded");
        return false;
    }

    const deleteMessage = async () => {
        set_busy(true);
        let results = await deleteThis({ variables: { id: String(messageData._id), ticket_id: Number(ticket_id) } }).then(r => (r.data.deleteThreadsTicketMessage));
        set_busy(false)

        if (results.error) message.error(results.error.message);
        else message.success("Message deleted");
        return false;
    }

    const grabReplyMessage = async (element) => {
        const canvas = await html2canvas(element, { height: 50, width: 500, backgroundColor: "#EEEEEE" });
        return canvas.toDataURL("image/png", 1.0);
    }
    const replyThisMessage = async () => {
        let image = await grabReplyMessage(exportRef.current, "test")
        return onReplyTo({ image, message: messageData })
    }

    const addReaction = async (icon) => {
        let input = {
            ticket_id: Number(ticket_id),
            message_id: messageData._id,
            icon: icon
        }

        set_busy(true);
        let results = await reactToThreadsTicketMessage({ variables: { input } }).then(r => (r.data.reactToThreadsTicketMessage));
        set_busy(false)

        if (results.error) message.error(results.error.message);
        // else message.success("Message discarded");
        return false;
    }

    return (<div ref={ref1} id={`${messageData._id}`}>
        <Loader loading={busy}>
            <ContentArea className={`threads-message ${compact ? "compact" : ""} ${viewed ? "" : "isNew"} status_${messageData.status}`}>
                {messageData?.replied_to?.img && <div className='replyCaptured' onClick={() => scrollTo(messageData?.replied_to?.msg_id)}>
                    <div style={{ fontWeight: "bold", fontSize: "12px" }}>Replied To:</div>
                    <span className='imgHolder'><img src={messageData.replied_to.img} className='image' alt="" /></span>
                </div>}

                {/* {header && <Row gutter={[10, 0]} className='nowrap'>
                    <Col flex="43px" />
                    <Col><div style={{ color: "black" }}>{header}</div></Col>
                </Row>} */}

                <Row gutter={[10, 5]} ref={exportRef} className='nowrap'>
                    <Col><UserCardPopover userInfo={messageData?.author}><Avatar src={messageData?.author?.avatar} alt={messageData.author.name} size={32} /></UserCardPopover></Col>
                    <Col flex="auto">
                        <Row gutter={[5, 5]}>
                            <Col span={24}><div style={{ color: "black", fontWeight: 200 }}>{header}</div></Col>
                            <Col span={24}>
                                <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                                    {messageData?.author?.name}
                                    <span className="gray3" style={{ fontSize: "12px", marginLeft: "5px" }}>{mongoToDate(messageData.created_at).format(defaultDateTimeFormat)}</span>
                                </div>
                            </Col>
                            <Col span={24}><div style={{ fontSize: "14px" }}>
                                <div>
                                    {parseNewLine(messageData.body)}
                                    {/* {messageData.body.split("\n").map(o=>(<div>{o}</div>))} */}
                                    {/* {messageData.body.replace(new RegExp("\n", "g"), "</div><div>")} */}
                                </div>
                            </div></Col>
                            <Col span={24}><ShowAttachments attachments={messageData?.attachments} /></Col>
                        </Row>
                    </Col>
                </Row>


                {ticket_status == "open" && <>
                    <div className='hover_menu'>
                        <Row gutter={[0, 0]}>
                            {messageData.status != "archived" && <>
                                <Col><Tooltip placement="top" title={"Reply to message"}><Button icon={<Icon className="menu_icon" icon="reply" />} color="white" className="menu-btn" onClick={replyThisMessage} /></Tooltip></Col>
                                <Col><MoticonMenu onClick={addReaction} /></Col>
                                <Col><Tooltip placement="top" title={"Discard comment"}><Button onClick={discardMessage} color="white" className="menu-btn"><Icon className="menu_icon" icon="times" /></Button></Tooltip></Col>
                            </>}
                            {verifyRole("th.tik.del.msg", {user}) &&
                                <Col><Tooltip placement="top" title={"Delete comment"}><Button color="white" className="menu-btn" onClick={deleteMessage}><Icon className="menu_icon" icon="trash-alt" /></Button></Tooltip></Col>
                            }
                        </Row>
                    </div>
                </>}

                {/* {messageData?.reactions?.length > 0 && <div style={{ height: "5px" }} />} */}
                {messageData?.reactions?.length > 0 && <div className='reaction_wrapper'>
                    <div className='reactions_row'>
                        {messageData.reactions.map((r, i) => (<span className='reaction_moticon' key={i}><Tooltip color="#1B90FF" title={`${r.author.name}`}><Icon icon={r.icon} /></Tooltip></span>))}
                    </div>
                </div>}
            </ContentArea>
        </Loader>
    </div>)
}

const mapStateToProps = ({ ep_admin }) => {
    return ({ user: ep_admin.user });
    // return ({ permissions: ep_admin.user.permissions });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     on_updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export const ThreadMessage = connect(mapStateToProps, null)(ThreadMessageComp)
