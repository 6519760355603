/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import { Row, Col, message, Result, Divider, Upload, Alert, Steps } from 'antd'
import { InboxOutlined } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import SignaturePad from 'react-signature-canvas'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import axios from 'axios'
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { Button, DataRow, Icon, IconButton } from 'Common/components'
import Header from '../Components/Header';
import { __error } from 'Common/scripts/consoleHelper';
import { GUARD_APPLICATION_FORM } from '../../constants';
import { withApollo } from '@apollo/client/react/hoc';
import moment from 'moment';
import { defaultDateFormat } from 'configs';
import { removeSpecialChar, sleep } from 'Common/scripts/Functions';

const env = process.env;
const ADD_DATA = loader('src/Graphql/FormsData/addFormsData.graphql');
const ADD_ATTACHMENTS = loader('src/Graphql/FormsData/addFormsDataAttachments.graphql');
const MAX_FILES_UPOLOAD = 4;
export const FORM_KEY = GUARD_APPLICATION_FORM;


const SuccessScreen = () => {
  return (<div style={{ backgroundColor: '#001529', flex: 1, justifyContent: "center", alignItems: "center", display: 'flex' }}>
    <Result
      status="success"
      title={<span style={{ color: 'white' }}>Security Guard Job Application Submitted Successfully!</span>}
      extra={<img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-light.png`} />}
    // extra={<img src="https://sp-ao.shortpixel.ai/client/q_glossy,ret_img/https://alistsecurity.com/wp-content/uploads/2021/03/Website-Logo-Dark-Ver.-2.png" />}
    />
  </div>)
}

const testData = () => {
  if (env.NODE_ENV != 'development') return {};

  return {
    "f_name": "Faheem 1",
    "m_name": "Ullah 1",
    "l_name": "Hashmi 1",
    // "appt_no": "12",
    "street": "Some street 1",
    "province": "alberta",
    "city": "calgary",
    "postal_code": "123451",
    "home_phone": "1234567891",
    "mobile_phone": "1234567891",
    "email_address": "mail1@mail.com",
    "d_o_b": "2023-01-01T04:58:56.097Z",
    "gender": "male",
    "position_applied_for": "petrol_guard",
    "license_no": "1234567891",
    "license_expiry": "2023-01-01T04:59:07.581Z",
    "currently_licensed": true,
    "worked": true,
    "applied": true,
    "question_current": true,
    "older_than_18": true,
    "legally_entitled": true,
    "convicted_of_crime": true,
    "previously_worked_date": "2023-01-01T04:59:13.530Z",
    "previously_applied_date": "2023-01-01T04:59:15.246Z",
    "current_company_name": "Some company name 1",
    "crime": "Some description about the crime I did 1.",
    "available_to_work": "part_time",
    "available_start_date": "2023-01-01T05:00:02.552Z",
    "morning_mon": true,
    "afternoon_tue": true,
    "night_wed": true,
    "afternoon_thu": true,
    "morning_fri": true,
    "afternoon_sat": true,
    "night_sun": true,
    "true_availability": true,
    "availability_changes": true,
    "valid_driving_license": true,
    "vehicle_owner": true,
    "vehicle_make": "Toyota",
    "vehicle_model": "Corola",
    "vehicle_license_plate_no": "123ABC1",
    "vehicle_color": "white",
    "experience": [
      {
        "firstName": "",
        "lastName": "",
        "company_name": "Some company name",
        "supervisor_name": "Jhon Mark",
        "supervisor_work_number": "12345",
        "hourly_rate": "12",
        "work_location": "some work location",
        "work_site_property_name": "some site",
        "category": "condo",
        "work_start_date": "2023-01-01T05:01:34.686Z",
        "work_end_date": "2023-01-31T05:01:36.800Z",
        "work_end_season": "some work end reason"
      }
    ],
    "highest_level_of_education": "Some highest education 1",
    "secondary_school_diploma": "yes",
    "college_university_techicalschool": "yes",
    "security_training_course": true,
    "training_courses_detail": "some course i did 1",
    refrences: [
      {
        "fullname": "Name 1",
        "number": "111111111",
        "position": "some position 1",
        "company": "some company 1",
      },
      {
        "fullname": "Name 2",
        "number": "222222",
        "position": "some position 2",
        "company": "some company 2",
      },
    ],
    "emergency_contact": [
      {
        "fullname": "Emergency contact name 1",
        "number": "1111111111",
        "relation": "relation 1"
      },
      {
        "fullname": "Emergency contact name 2",
        "number": "2222222221",
        "relation": "relation 2"
      }
    ]

  }
}

const defaultValue = {
  emergency_contact: [
    { firstName: "", lastName: "", fullname: "", number: "", relation: "" },
  ],
  max_file_upload: MAX_FILES_UPOLOAD,
  ...testData()
}

const DataPreview = props => {
  // console.log("props.fileList: ", props.fileList)

  return (<>
    <p style={{ width: "100%", fontSize: "30px", textAlign: "center", textTransform: "capitalize", color: "#2F2F2F", fontWeight: "bold" }}>{props.f_name} {props.m_name} {props.l_name}</p>

    <div className='preview_card'>
      <Row gutter={[15]}>
        <Col flex="50%" style={{ borderRight: "1px solid #EEE" }}>
          <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="address-book" /> Contact</div>
          <DataRow col1='Email address' col2={props.email_address} />
          <DataRow col1='Home phone' col2={props.home_phone} />
          <DataRow col1='Mobile phone' col2={props.mobile_phone} />
          <DataRow col1='Address: ' col2={`${props.street}, ${props.province}, ${props.city}, ${props.postal_code}`} />
        </Col>
        <Col flex="auto">
          <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="user-alt" /> Personal Information</div>
          <DataRow col1='DOB: ' col2={moment(props.d_o_b).format(defaultDateFormat)} />
          {/* <DataRow col1='Residential status: ' col2={props.residential_status} /> */}
          <DataRow col1='Position applied for: ' col2={props.position_applied_for} />
          <DataRow col1='Security guard license #: ' col2={props.license_no} />
          <DataRow col1='Security license expiry #: ' col2={moment(props.license_expiry).format(defaultDateFormat)} />
        </Col>
      </Row>
      <hr style={{ margin: "50px" }} />

      <Row gutter={[15]}>
        <Col flex="50%" style={{ borderRight: "1px solid #EEE" }}>
          <DataRow col1='Do you have a valid Alberta Security License?: ' col2={<Icon icon={props.currently_licensed ? "check" : "times"} size={"1x"} color={props.currently_licensed ? "green" : "red"} />} />
          <DataRow col1='Are you 18 years of age or older?: ' col2={<Icon icon={props.older_than_18 ? "check" : "times"} size={"1x"} color={props.older_than_18 ? "green" : "red"} />} />
          <DataRow col1='Are you legally entitled to work in Canada?: ' col2={<Icon icon={props.legally_entitled ? "check" : "times"} size={"1x"} color={props.legally_entitled ? "green" : "red"} />} />
          <DataRow col1='Have you ever been convicted of a crime?: ' col2={<Icon icon={props.convicted_of_crime ? "check" : "times"} size={"1x"} color={props.convicted_of_crime ? "green" : "red"} />} />
        </Col>
        <Col flex="auto">
          <DataRow col1='Have you previously worked with us?: ' col2={<Icon icon={props.worked ? "check" : "times"} size={"1x"} color={props.worked ? "green" : "red"} />} />
          {props.worked && 
            <DataRow col1='Previous working date: ' col2={moment(props.previously_worked_date).format(defaultDateFormat)} />
          }
          <DataRow col1='Have you previously applied with us?: ' col2={<Icon icon={props.applied ? "check" : "times"} size={"1x"} color={props.applied ? "green" : "red"} />} />
          {props.applied &&
            <DataRow col1='Previous working date: ' col2={moment(props.previously_applied_date).format(defaultDateFormat)} />
          }
          <DataRow col1='Are you currently working for another company?: ' col2={<Icon icon={props.question_current ? "check" : "times"} size={"1x"} color={props.question_current ? "green" : "red"} />} />
          {props.question_current && 
            <DataRow col1='Compnay name: ' col2={props.current_company_name} />
          }
        </Col>
      </Row>
      <hr style={{ margin: "50px" }} />


      <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="clock" /> Availability</div>
      <Row>
        <Col flex="50%"><DataRow col1="Are you available to work?" col2={props.available_to_work} inline /></Col>
        <Col flex="auto"><DataRow col1="Available start date?" col2={moment(props.available_start_date).format(defaultDateFormat)} inline /></Col>
      </Row>

      <Row align="middle" style={{ color: "#060079" }} className='dark'>
        {['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
          return <Col flex="12.5%" align="center" style={{ borderBottom: "1px solid #EEE", paddingTop: "10px", borderRight: "1px solid #EEE", fontWeight: "bold" }} key={index}>{day}</Col>
        })}
      </Row>

      {["Morning", "Afternoon", "Night"].map((daypart, index) => (
        <Row align="middle" key={index}>
          <Col flex="12.5%" style={{ height: "40px", paddingTop: "10px" }}>{daypart}</Col>
          {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
            return <Col key={i} flex="12.5%" align="center" style={{ height: "40px", padding: "10px", borderBottom: "1px solid #EEE", borderRight: "1px solid #EEE" }}>
              <>
                {props[`${String(daypart).toLowerCase()}_${day}`] ?
                  <Icon icon="check" size={"1x"} color="green" /> :
                  <Icon icon="times" size={"1x"} color="#EEE" />
                }
              </>
            </Col>
          })}
        </Row>
      ))}

      <hr style={{ margin: "50px" }} />
      <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="car-side" /> Driving Information</div>
      <div className='dark'>
        <DataRow col1='Do you have a valid driving license?: ' col2={<Icon icon={props.valid_driving_license ? "check" : "times"} size={"1x"} color={props.valid_driving_license ? "green" : "red"} />} />
      </div>
      <div className='simple'>
        <DataRow col1='Do you own or have regular access to a vehicle to get back and forth to and from work?: ' col2={<Icon icon={props.vehicle_owner ? "check" : "times"} size={"1x"} color={props.vehicle_owner ? "green" : "red"} />} />
        {props.vehicle_owner && <Row gutter={[12]}>
          <Col flex="25%"><DataRow col1="Make" col2={props.vehicle_make} inline /></Col>
          <Col flex="25%"><DataRow col1="Model" col2={props.vehicle_model} inline /></Col>
          <Col flex="25%"><DataRow col1="License plate no." col2={props.vehicle_license_plate_no} inline /></Col>
          <Col flex="25%"><DataRow col1="Color" col2={props.vehicle_color} inline /></Col>
        </Row>}
      </div>

      <hr style={{ margin: "50px" }} />
      <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="user-graduate" /> Education</div>
      <div className='dark'><DataRow col1="The highest level of education completed?" col2={props.highest_level_of_education} /></div>
      <div className='simple'><DataRow col1="Do you have a secondary school diploma?" col2={<Icon icon={props.secondary_school_diploma ? "check" : "times"} size={"1x"} color={props.secondary_school_diploma ? "green" : "red"} />} /></div>
      <div className='dark'><DataRow col1="Have you completed a college, university or technical school program?" col2={<Icon icon={props.college_university_techicalschool ? "check" : "times"} size={"1x"} color={props.college_university_techicalschool ? "green" : "red"} />} /></div>
      {props.training_courses_detail && <div className='simple'><DataRow col1="Training courses details" col2={props.training_courses_detail} /></div>}


      <hr style={{ margin: "50px" }} />
      <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="lock" /> Security Experience</div>
      {(!props.experience || props.experience.length < 1) && <p style={{ marginLeft: "30px" }}>No security experience</p>}
      {(props.experience && props.experience.length > 0) && props.experience.map((row, i) => {
        return (<div key={i}>
          <Row>
            <Col flex="auto"><DataRow col1="Security company name" col2={row.company_name} /></Col>
            <Col flex="33%"><DataRow col1="Supervisor name" col2={row.supervisor_name} /></Col>
            <Col flex="33%"><DataRow col1="Supervisor work number" col2={row.supervisor_work_number} /></Col>
          </Row>

          <Row>
            <Col flex="150px"><DataRow col1="Hourly rate" col2={row.hourly_rate} /></Col>
            <Col flex="auto"><DataRow col1="Work location / site address(where security guard duty is/was performed)" col2={row.work_location} /></Col>
          </Row>

          <Row>
            <Col flex="50%"><DataRow col1="Work / site / property name" col2={row.work_site_property_name} /></Col>
            <Col flex="auto"><DataRow col1="Category (condo, truck yard etc)" col2={row.category} /></Col>
          </Row>
          <Row>
            <Col flex="50%"><DataRow col1="Work duration" col2={<>{moment(row.work_start_date).format(defaultDateFormat)} till <br /> {moment(row.work_end_date).format(defaultDateFormat)}</>} /></Col>
            <Col flex="auto"><DataRow col1="Work end reason" col2={row.work_end_season} /></Col>
          </Row>
        </div>)
      })}


      <hr style={{ margin: "50px" }} />
      <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="address-card" /> References</div>
      {(!props.refrences || props.refrences.length < 1) && <p style={{ marginLeft: "30px" }}>No References provided</p>}
      {(props.refrences && props.refrences.length > 0) && props.refrences.map((row, i) => {
        return (<div key={i}>
          <Divider orientation="left">Refrence # {i}</Divider>
          <Row>
            <Col flex="250px"><DataRow col1="Name" col2={row.fullname} /></Col>
            <Col flex="250px"><DataRow col1="Contact number" col2={row.number} /></Col>
            <Col flex="250px"><DataRow col1="Position" col2={row.position} /></Col>
            <Col flex="250px"><DataRow col1="Company Name" col2={row.company} /></Col>
          </Row>
        </div>)
      })}

      <hr style={{ margin: "50px" }} />
      <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="address-book" /> Emergency Contacts</div>
      <ol>
        {(props.emergency_contact && props.emergency_contact.length > 0) ? props.emergency_contact.map((contact, i) => (
          <li key={i}>
            <Icon icon="phone" /> {contact.number} - {contact.fullname} - ({contact.relation})
          </li>
        )) : <p >No emergency contacts</p>}
      </ol>


      <hr style={{ margin: "50px" }} />
      <div>
        <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="file-alt" /> Attachment(s)</div>
        <Row gutter={[10, 10]}>
          {props.fileList.map((item, i) => {
            return <Col key={i}>{item.name}</Col>
          })}
        </Row>
      </div>



      {/* <hr style={{ margin: "50px" }} />
      <Row style={{ flexWrap: "nowrap" }}>
        <Col flex="auto">
          <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="handshake" /> Signature</div>
          <div className='dark'>
            <DataRow col1={props.certify ? <Icon icon="check" size={"1x"} color="green" /> : <Icon icon="times" size={"1x"} color="red" />} 
              col2={"I certify that my answers are true and complete to the best of my knowledge. If this application leads to employment, I understand that false or misleading information in my application or interview may result in my release"} />
          </div>
        </Col>
        <Col>
          <img src={props.signatureDataURL} alt="" style={{ margin: "10px auto" }} />
        </Col>
      </Row>

      <DataRow col1="How did you hear about this job?" col2={props.job_info_source} />
      <DataRow col1="Extra Notes (optional)" col2={props.note} /> */}

    </div>

  </>)
}


class ClientQueryFormComp extends Component {
  signaturePad = {}

  constructor(props) {
    super(props)
    this.state = { 
      loading: false, data: null, success: false, signatureDataURL: null, fileList: [], 
      activeStep: 0,
      // max_file_upload: MAX_FILES_UPOLOAD
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.onStepClick = this.onStepClick.bind(this);
  }

  clearSignature = (event) => {
    event.preventDefault()
    this.signaturePad.clear()
    if (this.state.signatureDataURL !== null) this.setState({ signatureDataURL: null })
  }

  trimSignature = (event) => {
    event.preventDefault()

    const signatureDataURL = this.signaturePad.getTrimmedCanvas().toDataURL('image/png');

    this.setState({ signatureDataURL }, () => {
      console.log("this is the url og sign pad", this.state.signatureDataURL)
    })
  }

  async handleUpload(_id) {
    const { fileList } = this.state;
    if (!fileList || fileList.length<1) return false;

    var _files = fileList.slice()
    if (_files.length > 6) _files = _files.slice(0, 6)
    // else _files = this.state.fileList

    const formData = new FormData();
      formData.append('action', "form_data.attachments");
      formData.append('_id', _id);
      _files.forEach(file => {
        formData.append('files[]', file);
      });

    const uploadResults = await axios.post(process.env.REACT_APP_API_URL + '/upload/form_data', formData)
      .then(({ data }) => {
        // console.log("data: ", data)
        // const { result, status, success, error } = data;
        return data;
      })
      .catch(function (error) {
        console.log(error);
        return { error: { message: "Request Error!" } };
      });

    return uploadResults;
  };


  async onSubmit(values) {
    const { activeStep } = this.state;

    if (activeStep < 5){
      let next = activeStep + 1; // this.onStepClick(activeStep + 1, values);
      
      if (activeStep < next && !this.validateStep(values)) return false;
      this.setState({ activeStep: next })
      return false;
    }

    const input = { ref_key: FORM_KEY, title: `${values.f_name}`, }

    const fields = [
      { name: 'f_name', label: 'First name', value: values.f_name },
      { name: 'm_name', label: 'Middle name', value: values.m_name },
      { name: 'l_name', label: 'Last name', value: values.l_name },
      
      // { name: 'appt_no', label: 'Apt / House #', value: values.appt_no },
      { name: 'street', label: 'Street address', value: values.street },
      { name: 'province', label: 'Province', value: values.province },
      { name: 'city', label: 'City', value: values.city },
      
      { name: 'postal_code', label: 'Postal code', value: values.postal_code },
      { name: 'home_phone', label: 'Home phone', value: values.home_phone },
      { name: 'mobile_phone', label: 'Mobile phone', value: values.mobile_phone },

      { name: 'email_address', label: 'Email address', value: values.email_address },
      { name: 'd_o_b', label: 'Date of birth', value: values.d_o_b },
      { name: 'gender', label: 'Gender', value: values.gender },
      { name: 'position_applied_for', label: 'Position applied for', value: values.position_applied_for },
      
      // { name: 'residential_status', label: 'Residential status', value: values.residential_status },
      // { name: 'sin_no', label: 'SIN Card No.', value: values.sin_no },
      
      { name: 'license_no', label: 'Security guard license #', value: values.license_no },
      { name: 'license_expiry', label: 'License expiry', value: values.license_expiry },
      
      { name: 'currently_licensed', label: 'Do you have a valid Alberta Security License?', value: values.currently_licensed ? 'yes' : 'no' },
      
      { name: 'worked', label: 'Have you previously worked with us?', value: values.worked ? 'yes' : 'no' },
      { name: 'previously_worked_date', label: 'Previously worked date', value: values.previously_worked_date },
      
      { name: 'applied', label: 'Have you previously applied with us?', value: values.applied ? 'yes' : 'no' },
      { name: 'previously_applied_date', label: 'Have you previously applied with us?', value: values.previously_applied_date },
      
      { name: 'question_current', label: 'Are you currently working for another company?', value: values.question_current ? 'yes' : 'no' },
      { name: 'current_company_name', label: 'Company name', value: values.current_company_name },
      
      { name: 'older_than_18', label: 'Are you 18 years of age or older?', value: values.older_than_18 ? 'yes' : 'no' },
      
      { name: 'legally_entitled', label: 'Are you legally entitled to work in Canada?', value: values.older_than_18 ? 'yes' : 'no' },
      { name: 'convicted_of_crime', label: 'Have you ever been convicted of a crime?', value: values.convicted_of_crime ? 'yes' : 'no' },
      { name: 'crime', label: 'Describe your crime', value: values.crime },
      
      { name: 'available_to_work', label: 'Are you available to work?', value: values.available_to_work },
      { name: 'available_start_date', label: 'Available start date?', value: values.available_start_date },
      
      { name: 'availability', label: 'Availability', value: JSON.stringify({
        mon: [(values.morning_mon ? "morning" : ""), (values.afternoon_mon ? "afternoon" : ""), (values.night_mon ? "night" : "")],
        tue: [(values.morning_tue ? "morning" : ""), (values.afternoon_tue ? "afternoon" : ""), (values.night_tue ? "night" : "")],
        wed: [(values.morning_wed ? "morning" : ""), (values.afternoon_wed ? "afternoon" : ""), (values.night_wed ? "night" : "")],
        thu: [(values.morning_thu ? "morning" : ""), (values.afternoon_thu ? "afternoon" : ""), (values.night_thu ? "night" : "")],
        fri: [(values.morning_fri ? "morning" : ""), (values.afternoon_fri ? "afternoon" : ""), (values.night_fri ? "night" : "")],
        sat: [(values.morning_sat ? "morning" : ""), (values.afternoon_sat ? "afternoon" : ""), (values.night_sat ? "night" : "")],
        sun: [(values.morning_sun ? "morning" : ""), (values.afternoon_sun ? "afternoon" : ""), (values.night_sun ? "night" : "")],
      }) },

      { name: 'true_availability', label: 'I confirm this availability to be true', value: values.true_availability ? 'yes' : 'no' },
      { name: 'availability_changes', label: 'I acknowledge if my availability changes with in 90 days (with in the probation period), A-list security have the right to void my contract', value: values.availability_changes ? 'yes' : 'no' },

      { name: 'valid_driving_license', label: 'Do you have a valid driving license?', value: values.valid_driving_license ? 'yes' : 'no' },
      { name: 'vehicle_owner', label: 'Do you own or have regular access to a vehicle to get back and forth to and from work?', value: values.vehicle_owner ? 'yes' : 'no' },
      { name: 'vehicle_make', label: 'Make', value: values.vehicle_make },
      { name: 'vehicle_model', label: 'Model', value: values.vehicle_model },
      { name: 'vehicle_license_plate_no', label: 'License plate No.', value: values.vehicle_license_plate_no },
      { name: 'vehicle_color', label: 'Color', value: values.vehicle_color },
      
      { name: 'highest_level_of_education', label: 'The highest level of education completed?', value: values.highest_level_of_education },
      { name: 'secondary_school_diploma', label: 'Do you have a secondary school diploma?', value: values.secondary_school_diploma },
      { name: 'college_university_techicalschool', label: 'Have you completed a college, university or technical school program?', value: values.college_university_techicalschool },
      { name: 'security_training_course', label: 'Have you completed any training courses relevant to the security industry?', value: values.security_training_course ? 'yes' : 'no' },
      { name: 'training_courses_detail', label: 'Training courses details', value: values.training_courses_detail },
      
      { name: 'experience', label: 'Experience', value: JSON.stringify(values.experience) },

      { name: 'refrences', label: 'refrences', value: JSON.stringify(values.refrences) },

      { name: 'emergency_contact', label: 'Emergency contact', value: JSON.stringify(values.emergency_contact) },
      
      { name: 'certify', label: 'I certify that my answers are true and complete to the best of my knowledge. If this application leads to employment, I understand that false or misleading information in my application or interview may result in my release', value: values.certify ? 'yes' : 'no' },
      
      { name: 'job_info_source', label: 'How did you hear about this job?', value: values.job_info_source },
      
      { name: 'submit_physically', label: "I'll provide my documents physically", value: values.submit_physically ? 'yes' : 'false' },

      { name: 'signatureDataURL', label: "Signatures", value: this.state.signatureDataURL },

      { name: 'note', label: "Extra Notes (optional)", value: values.note },
      
    ]
    Object.assign(input, { fields });

    if (!this.state.signatureDataURL){
      alert("Oops! you forgot to sign your application!")
      return;
    }

    this.setState({ loading: true, error:null });

    const added = await this.props.client.mutate({ mutation: ADD_DATA, variables: { input } }).then(r => (r.data.addFormsData)).catch(err => {
      console.log(__error("ERROR"), err);
      return { error: { message:"Failed to process the request!" }};
    });

    if (added.error){
      message.error(added.error.message);
      this.setState({ loading: false, error: added.error.message });
      return false;
    }

    const attachments = await this.handleUpload(added._id);

    if(!attachments) console.log("No attachments found")
    else if ((attachments && attachments.error) || !attachments.result) message.error("Unable to upload your documents, please submit them in person.")
    else if (attachments && attachments.result && attachments.success){
      await this.props.client.mutate({ mutation: ADD_ATTACHMENTS, variables: { attachments: attachments.result, id: added._id } })
      .then(r => (r.data.addFormsDataAttachments))
      .catch(err => {
        console.log(__error("ERROR"), err);
        message.error("Unable to save uploaded data");
      });

      message.success("Submission successfull!")
    }
    
    this.setState({ loading: false, success: added });

    return true;

  }

  onStepClick(val, values){
    if (this.state.activeStep < val && !this.validateStep(values)) {
      return false;
    }
    this.setState({ activeStep:val })
  }

  validateStep(values){
    if (this.state.activeStep == 4 && (this.state.fileList.length < (values.max_file_upload || 0))){
      // console.log("======: ", `${this.state.fileList.length} < (${values.max_file_upload} || 0)`, (this.state.fileList.length < (values.max_file_upload || 0)))
      message.error("Please provide the required document(s)");
      return false;

    }

    // if (this.state.activeStep == 4 && this.state.fileList.length < (values.max_file_upload || 0) && !values.submit_physically){
    //   message.error("Oops! looks like you forgot to attach the required documents");
    //   return false;
    // }
    return true;
  }

  render() {
    const { busy, error, success, activeStep } = this.state;
    const filterString = (e, onChange) => onChange(removeSpecialChar(e.target.value, { filter:{space:true} }));

    if (success) return <SuccessScreen />

    return (<>
      <div style={{ backgroundColor: "#f8f9fa" }} className="form-data">
        <Header title="Security Guard Job Application Form" />

        <div style={{ display: 'flex', justifyContent: "center" }}>
          <div style={{ maxWidth:"1200px", width:"100%", margin:"20px" }}>

            <FormComponent onSubmit={this.onSubmit} id='GuardInfoForm' loading={busy} debug={true} fields={defaultValue} moveOnError
                style={{ width:"100%", padding:"0", margin:"0" }}
                mutators={{ 
                  ...arrayMutators,
                  updateMaxUplpads: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    tools.changeValue(state, 'max_file_upload', () => node);
                  },
                }}
                form_render={formProps => {
                  const { values, form, submitting, invalid, dirty, valid } = formProps;
                  // if (formProps.errors && formProps.submitFailed) this.moveToErrorPosition(formProps.errors);
                  let avilabilitySetup =  values.morning_mon || values.afternoon_mon || values.night_mon || values.morning_tue || values.afternoon_tue || values.night_tue || values.morning_wed || values.afternoon_wed || values.night_wed || values.morning_thu || values.afternoon_thu || values.night_thu || values.morning_fri || values.afternoon_fri || values.night_fri || values.morning_sat || values.afternoon_sat || values.night_sat || values.morning_sun || values.afternoon_sun || values.night_sun;

                  return (<>
                    <div style={{margin:"0 0 20px 0"}}>
                      <Steps current={activeStep} direction="horizontal" size="small" labelPlacement="vertical"
                        // onChange={(v) => this.onStepClick(v, values)} 
                        // type="navigation" className="site-navigation-steps"
                        >
                        <Steps.Step title={<span style={activeStep==0 ? {color:"black", fontWeight:"bold" } : {}}>Personal Information</span>} 
                          // description={"Please provide your personal infomration"} 
                          />
                        <Steps.Step title={<span style={activeStep == 1 ? { color: "black", fontWeight: "bold" } : {}}>Availability</span>} 
                          // description={"Your availability and other skills"} subTitle="Left 00:00:08" 
                          />
                        <Steps.Step title={<span style={activeStep == 2 ? { color: "black", fontWeight: "bold" } : {}}>Experience</span>} 
                          // description={"Experience and Education"} 
                          />
                        <Steps.Step title={<span style={activeStep == 3 ? { color: "black", fontWeight: "bold" } : {}}>References</span>} 
                          // description={"References and Contacts"} 
                          />
                        <Steps.Step title={<span style={activeStep == 4 ? { color: "black", fontWeight: "bold" } : {}}>Document</span>} 
                          // description={"Documents & Attachments"} 
                          />
                        <Steps.Step title={<span style={activeStep == 5 ? { color: "black", fontWeight: "bold" } : {}}>Acknowledgement</span>} 
                          // description={"Review and Acknowledgement"} 
                          />
                      </Steps>
                    </div>

                    {activeStep==0 && <>
                      <div className='card'>
                        <span style={{ fontSize: "24px", color: "#001529" }}><Icon icon="user-alt" color={"#001529"} style={{ marginLeft: "10px" }} /> Personal Information</span>

                        <Row>
                          <Col span={24} sm={12} md={12} lg={10}>
                            <Row style={{ flexWrap:"nowrap" }}>
                              <Col flex="100px"><FormField name="gender" type="select" size="medium" label="Gender" validate={rules.required} placeholder="SELECT" data={[
                                { _id: "male", title: "Male" }, { _id: "female", title: "Female" }, { _id: "x", title: "X" },
                              ]} /></Col>
                              <Col flex="auto"><FormField id="f_name" label="First name" name="f_name" size="medium" type="text" 
                                validate={composeValidators(rules.required, rules.maxChar(32))} maxLength={32}
                              /></Col>
                            </Row>
                          </Col>
                          <Col span={24} sm={12} md={7} lg={7}><FormField label="Middle name" name="m_name" size="medium" type="text" 
                            validate={composeValidators(rules.maxChar(32))}
                          /></Col>
                          <Col span={24} sm={12} md={7} lg={7}><FormField label="Last name" name="l_name" size="medium" type="text" 
                            validate={composeValidators(rules.required, rules.maxChar(32))} maxLength={32}
                          /></Col>
                        </Row>

                        <Row>
                          <Col span={24} sm={12} md={12}><FormField name="street" type="text" size="medium" label="Street address" 
                            validate={composeValidators(rules.required, rules.maxChar(256))} maxLength={256}
                          /></Col>
                          <Col span={12} sm={6} md={4}>
                            <FormField name="province" type="select" size="medium" placeholder="SELECT" validate={rules.required} label="Province" data={[
                              { _id: "alberta", title: "Alberta" }
                            ]} />
                          </Col>
                          <Col span={12} sm={6} md={4}>
                            <FormField name="city" type="select" size="medium" placeholder="SELECT" label="City" validate={rules.required} data={[
                              { _id: "calgary", title: "Calgary" }, { _id: "airdrie", title: "Airdrie" }, { _id: "toronto", title: "Toronto" }
                            ]} />
                          </Col>
                          <Col span={12} sm={6} md={4}>
                            <FormField name="postal_code" size="medium" type="text" label="Postal code" validate={rules.required} />
                          </Col>

                          <Col span={24} sm={8} lg={6} md={6}><FormField name="home_phone" type="text" size="medium" label="Home phone " placeholder="FORMAT: XXXXXXXXXX" 
                            onChange={filterString}
                            validate={composeValidators(rules.required, rules.maxChar(16))} maxLength={16}
                          /></Col>
                          <Col span={24} sm={8} lg={6} md={6}><FormField name="mobile_phone" type="text" size="medium" label="Mobile phone " placeholder="FORMAT: XXXXXXXXXX" 
                            onChange={filterString}
                            validate={composeValidators(rules.required, rules.maxChar(16))} maxLength={16}
                          /></Col>
                          <Col span={24} sm={8} lg={6} md={6}><FormField name="email_address" type="email" size="medium" label="Email address" 
                            validate={composeValidators(rules.required, rules.maxChar(128), rules.isEmail)} maxLength={128}
                          /></Col>
                          <Col span={24} sm={8} lg={6} md={6}><FormField name="d_o_b" size="medium" type="date" label="Date of birth" placeholder={defaultDateFormat} 
                            validate={rules.required} /></Col>
                        </Row>

                        <Row>
                          <Col span={24} sm={12} md={8}>
                            <FormField name="position_applied_for" validate={rules.required} type="select" label="Position applied for" size="medium" placeholder="SELECT" data={[
                              { _id: "petrol_guard", title: "Petrol Guard" }, { _id: "security_guard", title: "Security guard" },
                            ]} />
                          </Col>
                          <Col span={24} sm={12} md={8}><FormField name="license_no" type="text" size="medium" placeholder='WXXXXXX' label="Security guard license #" 
                            onChange={filterString}
                            validate={composeValidators(rules.required, rules.maxChar(9))} maxLength={9}
                          /></Col>
                          <Col span={24} sm={12} md={6}><FormField name="license_expiry" type="date" size="medium" label="License expiry" placeholder={defaultDateFormat} 
                            validate={rules.required} /></Col>
                          {/* <Col span={24} sm={12} md={6}></Col> */}
                        </Row>

                        <hr />

                        <div className='dark'>
                          <FormField type="checkbox" name="currently_licensed" style={{ padding: "0px", margin: "0px" }} validate={rules.required}>
                            Do you have a valid Alberta Security License?
                          </FormField>
                          {/* <Row align="middle" gutter={[10, 10]}>
                            <Col><FormField type="switch" name="currently_licensed" style={{ padding: "0px", margin: "0px" }} validate={rules.required} /></Col>
                            <Col flex="auto">Do you have a valid Alberta Security License?</Col>
                          </Row> */}
                        </div>

                        <div className='simple'>
                          <Row align="middle" gutter={[10, 10]}>
                            <Col flex="auto">
                              <FormField type="checkbox" name="worked" style={{ padding: "0px", margin:"0px" }}>
                                Have you previously worked with us?
                              </FormField>
                            </Col>
                            <Col>{values.worked &&
                              <FormField name="previously_worked_date" size="medium" compact type="date" placeholder={defaultDateFormat} validate={rules.required} />
                            }</Col>
                          </Row>
                          {/* <Row align="middle" gutter={[10, 10]}>
                            <Col><FormField type="switch" name="worked" style={{ padding: "0px", margin:"0px" }} /></Col>
                            <Col flex="auto">Have you previously worked with us?</Col>
                            <Col>{values.worked &&
                              <FormField name="previously_worked_date" size="medium" compact type="date" placeholder={defaultDateFormat} validate={rules.required} />
                            }</Col>
                          </Row> */}
                        </div>

                        <div className='dark'>
                          <Row align="middle" gutter={[10, 10]}>
                            <Col flex="auto">
                              <FormField type="checkbox" name="applied" style={{ margin: "0px", padding: "0px" }}>
                                Have you previously applied with us?
                              </FormField>
                            </Col>
                            <Col>{values.applied &&
                              <FormField name="previously_applied_date" size="medium" compact type="date" placeholder="YY-MM-DD" validate={rules.required} />
                            }</Col>
                          </Row>
                          {/* <Row align="middle" gutter={[10, 10]}>
                            <Col><FormField type="switch" name="applied" style={{ margin: "0px", padding: "0px" }} /></Col>
                            <Col flex="auto">Have you previously applied with us?</Col>
                            <Col>{values.applied &&
                              <FormField name="previously_applied_date" size="medium" compact type="date" placeholder="YY-MM-DD" validate={rules.required} />
                            }</Col>
                          </Row> */}
                        </div>

                        <div className='simple'>
                          <Row align="middle" gutter={[10, 10]}>
                            <Col flex="auto">
                              <FormField name="question_current" type="checkbox" style={{ margin: "0px", padding: "0px" }}>
                                Are you currently working for another company?
                              </FormField>
                            </Col>
                            <Col flex="350px">{values.question_current &&
                              <FormField name="current_company_name" size="medium" compact type="text" placeholder="Please enter the company name" validate={rules.required} />
                            }</Col>
                          </Row>
                          {/* <Row align="middle" gutter={[10, 10]}>
                            <Col><FormField name="question_current" type="switch" style={{ margin: "0px", padding: "0px" }} /></Col>
                            <Col flex="auto">Are you currently working for another company?</Col>
                            <Col flex="350px">{values.question_current &&
                              <FormField name="current_company_name" size="medium" compact type="text" placeholder="Please enter the company name" validate={rules.required} />
                            }</Col>
                          </Row> */}
                        </div>

                        <div className='dark'>
                          <FormField type="checkbox" name="legally_entitled" style={{ margin: "0px", padding: "0px" }}>
                            Are you legally entitled to work in Canada?
                          </FormField>
                          {/* <Row align="middle" gutter={[10, 10]}>
                            <Col><FormField type="switch" name="legally_entitled" style={{ margin: "0px", padding: "0px" }} /></Col>
                            <Col flex="auto">Are you legally entitled to work in Canada?</Col>
                          </Row> */}
                        </div>

                        <div className='simple'>
                          <FormField name="convicted_of_crime" type="checkbox" style={{ margin: "0px", padding: "0px" }}>
                            Have you ever been convicted of a crime?
                          </FormField>
                          {/* <Row align="middle" gutter={[10, 10]}>
                            <Col><FormField name="convicted_of_crime" type="switch" style={{ margin: "0px", padding: "0px" }} /></Col>
                            <Col flex="auto">Have you ever been convicted of a crime?</Col>
                          </Row> */}
                        </div>

                        {values.convicted_of_crime &&
                          <FormField align="left" rows={2} name="crime" size="medium" type="textarea" label="Describe your crime" validate={rules.required} />
                        }

                        <div className='simple'>
                          <FormField type="checkbox" name="older_than_18" style={{ margin: "0px", padding: "0px" }} validate={rules.required}>
                            I confirm  that I am 18 at least 18 years old
                          </FormField>
                          {/* <Row align="middle" gutter={[10, 10]}>
                            <Col><FormField type="switch" name="older_than_18" style={{ margin: "0px", padding: "0px" }} validate={rules.required} /></Col>
                            <Col flex="auto">Are you 18 years of age or older?</Col>
                          </Row> */}
                        </div>


                      </div>
                    </>}

                    {activeStep==1 && <>
                      <div className='card' style={{ maxWidth:"1200px", overflowX:"auto" }}>
                        <span style={{ fontSize: "28px", color: "#001529" }} > <Icon color={"#001529"} icon="clock" style={{ marginLeft: "10px" }} /> Please Indicate Your Availability</span>
                        <Row align="top">
                          <Col flex="50%"><FormField name="available_to_work" size="medium" placeholder="SELECT" type="select" label="Are you available to work?" validate={rules.required} data={[{ _id: "part_time", title: "Part Time" }, { _id: "full_time", title: "Full Time" }, { _id: "temporary", title: "temporary" }]} /></Col>
                          <Col flex="auto"><FormField name="available_start_date" size="medium" placeholder={defaultDateFormat} type="date" label="Available start date?" validate={rules.required} /></Col>
                        </Row>

                        <p>
                          <b style={{ color: "red" }}>Note:</b> For full-timers  (Availability must be week days, Weekends, Public holidays, Morning, Night & Afternoon Shifts) For part-timers (Fill the chart below)
                        </p>

                        <div style={{ minWidth:"800px" }}>
                          <Row className='dark' align="middle">
                            {['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
                              return <Col flex="12.5%" className="bold" key={index}>{day}</Col>
                            })}
                          </Row>
                          <Row className='simple' align="middle">
                            {['Morning', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                              if (i == 0) return <Col flex="12.5%" className="bold" key={i}>{day}</Col>;
                              return <Col flex="12.5%" key={i}><FormField compact name={`morning_${day}`} type='switch' /></Col>
                            })}
                          </Row>
                          <Row className='dark' align="middle">
                            {['Afternoon', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                              if (i == 0) return <Col flex="12.5%" className="bold" key={i}>{day}</Col>;
                              return <Col flex="12.5%" key={i}><FormField compact name={`afternoon_${day}`} type='switch' /></Col>
                            })}
                          </Row>
                          <Row className='simple' align="middle">
                            {['Night', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                              if (i == 0) return <Col flex="12.5% " className="bold" key={i}>{day}</Col>;
                              return <Col flex="12.5%" key={i}><FormField compact name={`night_${day}`} type='switch' /></Col>
                            })}
                          </Row>
                        </div>

                        {avilabilitySetup && <>
                          <FormField name="true_availability" type="checkbox" validate={rules.required}>I confirm this availability to be true</FormField>
                          <FormField name="availability_changes" type="checkbox" validate={rules.required}>I acknowledge if my availability changes with in 90 days (with in the probation period), A-list security have the right to void my contract</FormField>
                        </>}

                      </div>
                      <div style={{ height: '20px' }} />
                      <div className='card'>
                        <span style={{ fontSize: "28px", color: "#001529" }} > <Icon color={"#001529"} icon="car-side" style={{ marginLeft: "10px", marginRight: "5px" }} />Driving Information</span>

                        <Row className='dark'>
                          <Col flex="100%"><FormField name="valid_driving_license" label="Do you have a valid driving license?" type='switch' /></Col>
                        </Row>

                        <Row className='simple'>
                          <Col flex="100%"><FormField name="vehicle_owner" label="Do you own or have regular access to a vehicle to get back and forth to and from work?" type='switch' /></Col>
                        </Row>

                        {values.vehicle_owner && <Row>
                          <Col span={24} sm={12} md={6}><FormField name="vehicle_make" size="medium" type="text" label="Make" /></Col>
                          <Col span={24} sm={12} md={6}><FormField name="vehicle_model" type="text" size="medium" label="Model" /></Col>
                          <Col span={24} sm={12} md={6}><FormField name="vehicle_license_plate_no" type="text" size="medium" label="License plate no." /></Col>
                          <Col span={24} sm={12} md={6}><FormField name="vehicle_color" type="text" label="Color" size="medium" /></Col>
                        </Row>}
                      </div>
                    </>}

                    {activeStep==2 && <>
                      <div className='card'>
                        <FieldArray name="experience">
                          {({ fields }) => (
                            <div>

                              <Row>
                                <Col flex="auto"><span style={{ fontSize: "28px", color: "#001529" }} ><Icon color={"#001529"} icon="lock" style={{ marginLeft: "10px", marginRight: "5px" }} />Security Experience </span></Col>
                                <Col><Button onClick={() => fields.push({ firstName: '', lastName: '' })}>Add</Button></Col>
                              </Row>
                              {!values.experience && <p style={{ marginLeft: "10px" }} >Please add your experience if you have previously worked as Security Guard</p>}

                              {fields.map((name, index) => (
                                <Row key={index} style={{ borderBottom: "1px solid #EEE", marginBottom: "10px" }}>
                                  <Col span={24} md={1} style={{ fontSize: "34px", textAlign: "center" }}>
                                    <span>{index + 1}</span>
                                    <IconButton icon="trash-alt" onClick={() => fields.remove(index)} />
                                  </Col>
                                  <Col span={24} md={23}>
                                    <Row>
                                      <Col span={24} sm={12} md={8}><FormField name={`${name}.company_name`} label="Company name" size="medium" type="text" validate={rules.required} /></Col>
                                      <Col span={24} sm={12} md={8}><FormField name={`${name}.supervisor_name`} label="Supervisor name" type="text" size="medium" /></Col>
                                      <Col span={24} sm={12} md={8}><FormField name={`${name}.supervisor_work_number`} label="Supervisor work number" type="text" size="medium" /></Col>
                                    </Row>
                                    <Row>
                                      <Col flex="auto"><FormField name={`${name}.work_location`} size="medium" label={<>Work location / site address <span style={{fontSize:"9px"}}>(where security guard duty is/was performed)</span></>} type="text" validate={rules.required} /></Col>
                                      <Col flex="100px"><FormField name={`${name}.hourly_rate`} label="Hourly rate" type="text" size="medium" /></Col>
                                    </Row>
                                    <Row>
                                      <Col span={24} lg={8}><FormField name={`${name}.work_site_property_name`} size="medium" label="Work / site / property name" type="text" /></Col>
                                      <Col span={24} lg={16}><FormField name={`${name}.category`} size="medium" label="Category (condo, truck yard etc)" type="text" /></Col>
                                    </Row>

                                    {/* <FormField key={index+3} name={`${name}.list_duties_performed`} label="LIST DUTIES PERFORMED" type="text" validate={rules.required} /> */}

                                    <Row>
                                      <Col flex="150px">
                                        <div><FormField type="date" name={`${name}.work_start_date`} size="medium" label="Work start date" validate={rules.required} /></div>
                                        <div><FormField type="date" name={`${name}.work_end_date`} size="medium" label="Work end date" validate={rules.required} /></div>
                                      </Col>
                                      <Col flex="auto"><FormField name={`${name}.work_end_season`} size="medium" label="Work end reason" type="textarea" /></Col>
                                    </Row>
                                  </Col>
                                </Row>

                              ))}
                            </div>
                          )}
                        </FieldArray>
                      </div>
                      <div style={{ height: '20px' }} />

                      <div className='card' >
                        <span style={{ fontSize: "28px", color: "#001529" }}><Icon color={"#001529"} icon="user-graduate" style={{ marginLeft: "10px", marginRight: "5px" }} />Education</span>
                        <FormField name="highest_level_of_education" label="The highest level of education completed?" size="medium" type="text" validate={rules.required} />
                        <FormField label="Do you have a secondary school diploma?" name="secondary_school_diploma" type="text" size="medium" validate={rules.required} />
                        <FormField label="Have you completed a college, university or technical school program?" name="college_university_techicalschool" size="medium" type="text" validate={rules.required} />

                        <FormField label="Have you completed any training courses relevant to the security industry?" name="security_training_course" size="medium" type='switch' />
                        {values.security_training_course && <FormField label="Training courses details" name="training_courses_detail" size="medium" type="text" validate={rules.required} />}
                      </div>

                    </>}

                    {activeStep==3 && <>
                      <div className='card'>
                        <span style={{ fontSize: "28px", color: "#001529" }} ><Icon color={"#001529"} icon="address-card" style={{ marginLeft: "10px", marginRight: "5px" }} />References</span>
                        <p style={{ marginLeft: "10px" }}>We may contact references for verification </p>
                        <Divider orientation="left">Refrence # 1</Divider>
                        <Row>
                          <Col span={24} sm={12} md={6}><FormField name="refrences[0]fullname" label="Name" size="medium" type="text" /></Col>
                          <Col span={24} sm={12} md={6}><FormField name="refrences[0]number" label="Contact number" type="text" size="medium" /></Col>
                          <Col span={24} sm={12} md={6}><FormField name="refrences[0]position" label="Position" type="text" size="medium" /></Col>
                          <Col span={24} sm={12} md={6}><FormField name="refrences[0]company" label="Company Name" type="text" size="medium" /></Col>

                        </Row>
                        <Divider orientation="left">Refrence # 2</Divider>
                        <Row>
                          <Col span={24} sm={12} md={6}><FormField name="refrences[1]fullname" label=" Name" size="medium" type="text" /></Col>
                          <Col span={24} sm={12} md={6}><FormField name="refrences[1]number" label="Contact number" type="text" size="medium" /></Col>
                          <Col span={24} sm={12} md={6}><FormField name="refrences[1]position" label="Position" type="text" size="medium" /></Col>
                          <Col span={24} sm={12} md={6}><FormField name="refrences[1]company" label="Company Name" type="text" size="medium" /></Col>
                        </Row>
                      </div>
                      <div style={{ height: '20px' }} />

                      <div className='card'>
                        <FieldArray name="emergency_contact">
                          {({ fields }) => (
                            <div>
                              <Row>
                                <Col flex="auto"><span style={{ fontSize: "28px", color: "#001529" }} ><Icon color={"#001529"} icon="address-book" style={{ marginLeft: "10px", marginRight: "5px" }} />Emergency Contacts</span></Col>
                                <Col><Button onClick={() => fields.push({ firstName: '', lastName: '' })}>Add</Button></Col>
                              </Row>

                              {fields.map((name, index) => (
                                <Row key={index} style={{ borderBottom: "1px solid #EEE", marginBottom: "2px" }}>
                                  <Col flex="50px" style={{ marginTop: "10px", fontSize: "34px", textAlign: "center", display: "flex", flexDirection: "row" }}>
                                    <div>{index + 1}</div>
                                    {index >0 && <IconButton style={{ marginTop: "15px", marginLeft: "5px" }} icon="trash-alt" onClick={() => fields.remove(index)} />}
                                  </Col>
                                  <Col flex="auto">
                                    <Row key={index}>
                                      <Col span={24} md={8}><FormField name={`${name}.fullname`} label="Emergency contact name" size="medium" type="text" validate={rules.required} /></Col>
                                      <Col span={24} md={8}><FormField name={`${name}.number`} label="Emergency contact number" type="text" size="medium" validate={rules.required} /></Col>
                                      <Col span={24} md={8}><FormField name={`${name}.relation`} label="Emergency contact relation" type="text" size="medium" validate={rules.required} /></Col>
                                    </Row>

                                  </Col>
                                </Row>

                              ))}
                            </div>
                          )}
                        </FieldArray>
                      </div>

                    </>}

                    {activeStep==4 && <>
                      <div className='card'>
                        <Row style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                          <Col>Upload the following ducuments <span style={{ color: 'red' }}> (Only .PDF, .JPG, .PNG file formats are allowed)</span>
                            <ol>
                              {/* <li>SIN front</li> */}
                              <li>Security license front</li>
                              {/* <li>First aid card</li> */}
                              <li>Government issued photo identification</li>
                              <li>Government issued document showing eligibility to work</li>
                              <li>Resume</li>
                            </ol>
                          </Col>
                          <Col flex="400px">
                            <FormField name="submit_physically" type="checkbox" 
                              onChange={(e)=>{
                                // this.setState({ max_file_upload: e ? 1 : MAX_FILES_UPOLOAD })
                                form.mutators.updateMaxUplpads(e ? 1 : MAX_FILES_UPOLOAD)
                                return e;
                              }}
                            >I'll provide my documents physically</FormField>

                            {values.submit_physically && <Alert message="You must upload a copy of Security License" type="warning" showIcon />}

                            <div>
                              <Upload.Dragger
                                // disabled={values.submit_physically}
                                // disabled={this.state.max_file_upload <= this.state.fileList.length}
                                fileList={this.state.fileList}
                                onRemove={(file) => {
                                  this.setState(state => {
                                    const index = state.fileList.indexOf(file);
                                    const newFileList = state.fileList.slice();
                                    newFileList.splice(index, 1);
                                    return { fileList: newFileList };
                                  });
                                }}
                                beforeUpload={(file) => {
                                  if (values.max_file_upload <= this.state.fileList.length) return false;

                                  this.setState(state => ({
                                    fileList: [...state.fileList, file],
                                  }));
                                  return false;
                                }}
                                // fileList={this.state.fileList}
                                accept=".jpeg,.gif,.jpg,.png,.pdf"
                                multiple={true}
                                maxCount={values.max_file_upload}
                                // itemRender={(originNode, file, currFileList)=>{
                                //   console.log(originNode, file, currFileList);
                                //  return <div>File {temp.props.children}</div>
                                // }}
                              >
                                <div style={{ width: '100%', padding: '0 20px' }}>
                                  <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                  <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p>
                                  <p>({this.state?.fileList?.length || 0} of {values.max_file_upload})</p>
                                </div>
                              </Upload.Dragger>
                            </div>
                          </Col>

                        </Row>
                      </div>

                    </>}

                    {activeStep==5 && <>
                      <div className='card'>
                        <DataPreview {...values} fileList={this.state.fileList} />
                      </div>
                      <hr style={{ margin: "50px" }} />                      

                      <div className='card'>
                        <span style={{ fontSize: "28px", color: "#001529" }} > <Icon color={"#001529"} icon="handshake" style={{ marginLeft: "10px", marginRight: "5px" }} />Sign Agreement</span>
                        <Row className='dark' style={{ flexWrap: "nowrap" }}>
                          <Col><FormField name="certify" type="checkbox" validate={rules.required} /></Col>
                          <Col flex="auto" style={{ paddingTop: "8px" }}>I certify that my answers are true and complete to the best of my knowledge. If this application leads to employment, I understand that false or misleading information in my application or interview may result in my release</Col>
                        </Row>

                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "20px auto" }}>
                          <div style={{ display: "flex", flexDirection: "column", position: "relative" }} >
                            <span className="cyan bold uppercase">Please sign the agreement:</span>
                            <span style={{ border: "1px solid #CCC", borderRadius: "5px" }}><SignaturePad
                              dotSize={1}
                              throttle={50}
                              velocityFilterWeight={0.1}
                              onEnd={this.trimSignature}
                              backgroundColor="#FFF"
                              canvasProps={{ width: 500, height: 200 }}
                              required ref={(ref) => { this.signaturePad = ref }} /></span>
                            <IconButton onClick={this.clearSignature} icon="sync-alt" color="green" style={{ position: "absolute", top: '28px', right: "5px" }} size="2x" />
                          </div>
                        </div>

                        <FormField name="note" size="medium" type="textarea" label="Extra Notes (optional)" placeholder={"Provide any extra information"} />

                      </div>
                      <div style={{ height: '20px' }} />

                      <div style={{ display: "flex", justifyContent: "center", marginBottom: "30px" }}>
                        <div className='dark' style={{ width: "80%", minWidth: "400px" }}>
                          <FormField name="job_info_source" size="medium" type="text" label="How did you hear about this job?" validate={rules.required} />
                        </div>
                      </div>
                    </>}

                    {/* {(!valid && dirty) && <Alert message="Please check your form fields, seems like you forgot some information!" type="warning" showIcon />} */}

                    {error && <div style={{ padding: "20px" }}><Alert title={<b>Error!</b>} description={error} type='error' showIcon /></div>}

                    <Row style={{margin:"20px"}}>
                      <Col><Button type="primary" onClick={() => this.onStepClick(activeStep - 1, values)}  disabled={activeStep == 0} size="large">Previous</Button></Col>
                      <Col flex="auto" />
                      <Col>
                        {activeStep < 5 &&
                          <Button type="primary" htmlType='submit' size="large" style={{ marginBottom: "50px" }} loading={busy}>Next</Button>
                        }
                        {activeStep == 5 &&
                          <Button type="primary" htmlType='submit' disabled={!valid} size="large" style={{ marginBottom: "50px" }} loading={submitting}>Submit Application</Button>
                        }
                      </Col>
                    </Row>

                  </>)
                }}
              />

          </div>
        </div>
      </div>
    </>)

  }
}

export const ClientQueryForm = withApollo(ClientQueryFormComp)
export default ClientQueryForm;
