import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
// import { graphql } from '@apollo/client/react/hoc';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { rules, composeValidators, FormField } from 'Common/components/Form'
import { Table, Loader, IconButton, Button } from 'Common/components';
import { Input } from 'antd';
import { Form } from 'react-final-form';
import { Drawer, Select, DatePicker, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Breadcrumb } from "antd";
import { ContentArea } from "Layout_v1";
import { HomeOutlined } from '@ant-design/icons';
import { modulePermissions } from '.'
import { uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import { ListHeader } from 'Common/components/Typography';

const { Search } = Input;
// const { Option } = Select;

const QUERY_DATA = loader('src/Graphql/black_list/blackListQuery.graphql');
const UPDATE_DATA = loader('src/Graphql/black_list/add.graphql');
const REMOVE_DATA = loader('src/Graphql/black_list/remove.graphql');
/**
 * Dashboard
 */
class BlackListComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      error: null,
      visible: false,
      busy: null,
      kw: null,
      pagination: { current: 1, pageSize: 10, total:0 }
    };
    this.onSubmit = this.onSubmit.bind(this)
    this.showDrawer = this.showDrawer.bind(this)
    this.onClose = this.onClose.bind(this)
    this.remove = this.remove.bind(this)
    this.searchList = this.searchList.bind(this)
  }

  componentDidMount() {
    this.fetchData()
  };

  fetchData = (args={}) => {
   
    const variables = {
      first: args.pageSize || this.state.pagination.pageSize, // number of rec to fetch
      after: (args.pageSize || this.state.pagination.pageSize) * ((args.current || this.state.pagination.current) - 1),
      filter: JSON.stringify(args.filter || {})
    }

    this.setState({ loading: true })

    this.props.client.query({ query: QUERY_DATA, variables }).then(r => {
        if (r.data.error) {
          this.setState({ loading: false, error: r.data.error.message });
          return;
        }

        this.setState({ 
            loading: false, 
            data: r.data.blackListQuery,
            pagination: {
              ...this.state.pagination,
              current: args.current || 1,
              pageSize: args.pageSize || this.state.pagination.pageSize,
              total: r.data.blackListQuery.totalCount
            }
        });

    }).catch(err => {
      console.log("ERROR: ", err);
      this.setState({ busy: false, error: "Query Error" });
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetchData({ current: pagination.current, pageSize: pagination.pageSize });
  };

  searchList(value) {
    console.log("searchList: ", value)
    this.fetchData({
      // filter: { $or: [{ "personalinfo.f_name": value }, { "personalinfo.sin_no": value}]}
      filter: { search: { keywords: value } }

    });
  }

  remove(id) {

    this.props.client.mutate({ mutation: REMOVE_DATA, variables: { id } }).then(r => {
      console.log(r.data, "the data in response")
      if (r.data.error) {
        this.setState({ loading: false, error: r.data.error.message });
        return;
      }
      message.success("Successfully Removed from BlackList!");
      const __data = this.state.data.edges ? this.state.data.edges.slice() : [];
      const index = __data.indexOf(id)
      __data.splice(index, 1);
      const __totalCount = this.state.data.totalCount - 1;
      this.setState({ busy: false, data: { edges: __data, totalCount: __totalCount}  })

    }).catch(err => {
      console.log("ERROR: ", JSON.stringify(err, 0, 2));
      message.error("Failed to process the request!");
    })

  }

  onSubmit(values) {
    console.log(values, "form values")

    this.props.client.mutate({ mutation: UPDATE_DATA, variables: { input: values } }).then(r => {
      if (r.data.error) {
        this.setState({ loading: false, error: r.data.error.message });
        return;
      }
      message.success("Successfully Added to BlackList!");
      // this.state.data.push(r.data.blacklist)
      const __data = this.state.data.edges ? this.state.data.edges.slice() : [];
      const __totalCount = this.state.data.totalCount+1;
      __data.unshift(r.data.addtoBlack)
      this.setState({ busy: false, data: { edges: __data, totalCount: __totalCount }  })
      this.onClose()

    }).catch(err => {
      console.log("ERROR: ", JSON.stringify(err, 0, 2));
      message.error("Failed to process the request!");
    })
  }

  showDrawer = () => {
    this.setState({ visible: true, });
  };

  onClose = () => {
    this.setState({ visible: false, });
  };

  columns = [
    // { title: 'ID', dataIndex: '_id', width: "100px" },
    { title: 'Name', dataIndex: 'name' },
    { title: 'SIN', dataIndex:'sin' },
    { title: 'Email', dataIndex:'email' },
    { title: 'Phone', dataIndex:'phone' },
    // {
    //   title: 'Date', render: (__, rec) => {
    //     return moment(rec?.createdAt).format('DD-MM-YYYY')
    //   }
    // },
    {
      title: '', width:'50px', render: (__, rec) => {
        return(
          <Popconfirm onConfirm={() => this.remove(rec._id)} title="Are you sure？">
            <IconButton icon={{ icon: 'trash-alt' }}></IconButton>
          </Popconfirm>
        )
        // return <Button type="primary" size="medium" danger onClick={e => this.remove(rec._id)}>Remove </Button>
      }
    },
    // { title: 'Name', dataIndex: 'email', width: "100px", align: "left" },
    // { title: 'city', dataIndex: 'type', width: "100px", align: "center" },
    // { title: 'View', dataIndex: 'type', width: "100px", align: "center" },  
  ];


  render() {
    const { pagination, loading, data, error } = this.state;

    if (loading) return <Loader loading={true} />
    if (error) return <Alert message="Error" description={error} type="error" showIcon />

    if (!modulePermissions['blacklist-manage']) return null;

    return (<>
      <Breadcrumb style={{ padding: "10px" }}>
        <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
        {/* <Breadcrumb.Item href="/templates"><span>Templates</span></Breadcrumb.Item> */}
        <Breadcrumb.Item>Blacklist</Breadcrumb.Item>
      </Breadcrumb>
      <ContentArea>
        <Row>
          <Col flex="auto"><ListHeader title={"BlackList"} totalCount={(data && data.totalCount) || '0'} /></Col>
          <Col><Button type="primary" onClick={this.showDrawer} icon={<PlusOutlined />}>Add New</Button></Col>
        </Row>

        <div className='table-header' style={{ padding:"10px" }}>
          <Row align="middle">
            <Col flex="350px"><Search value={this.state.kw} onChange={(kw) => this.setState({ kw: kw.target.value })} placeholder="input search text" onSearch={this.searchList} enterButton /></Col>
            <Col flex="auto" align="right"><span style={{ color: 'red' }}>* Notification will be generated when a blocked person apply for the job</span></Col>
          </Row>
        </div>

        <Table loading={loading}
          // title={() => (<Row align="middle">
          //   <Col flex="350px"><Search value={this.state.kw} onChange={(kw) => this.setState({ kw: kw.target.value })}  placeholder="input search text" onSearch={this.searchList} enterButton /></Col>
          //   <Col flex="auto" align="right"><span style={{ color: 'red' }}>* Notification will be generated when a blocked person apply for the job</span></Col>
          // </Row>)}
          columns={this.columns}
          pagination={pagination}
          dataSource={data ? data.edges : null}
          onChange={this.handleTableChange}
          expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.note}</p>,
            rowExpandable: record => record.note && record.note.length>0,
          }}

        />

        <Drawer
          placement="top"
          title="Add to BlackList"
          height={320}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          // extra={<Space><Button onClick={this.onClose}>Cancel</Button></Space>}
          footer={null}
        >
          <Form onSubmit={this.onSubmit} initialValues={{}} validate={(__values) => (false)}>
            {({ handleSubmit, submitting, form, values, invalid, dirty, valid }) => (<>
              <form className="form_component" onSubmit={handleSubmit}>

                <Row>
                  <Col><FormField label="Name" name="name" type="text" /></Col>
                  <Col><FormField label="Email Address" name="email" type="text" /></Col>
                  <Col><FormField label="Phone Number" name="phone" type="text" /></Col>
                  <Col><FormField label="SIN Number" name="sin" type="text" /></Col>
                </Row>

                <FormField rows="2" label="Note" name="note" type="textarea" />

                <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} >Submit </Button>

              </form>
            </>)}
          </Form>
        </Drawer>
      </ContentArea>

    </>)
  }

}

export const BlackList = withApollo(BlackListComp);
export default BlackList;