import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

// https://ant.design/components/spin/

/***
 * Spin.setDefaultIndicator(indicator: ReactNode)

 * <Spin 
 * spinning={boolean}
 * tip={string}
 * size={small | default | large}
 * wrapperClassName={className}
 * delay={number}
 */

/**
   //  custom icon
   import { LoadingOutlined } from '@ant-design/icons';
   const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
   <Spin indicator={antIcon} />
 */

export const Loader = props => {
  const propTypes = {
    loading: PropTypes.bool.isRequired,
    center: PropTypes.bool.isRequired,
  }

  let _props = { ...props };
  delete _props.loading;
  delete _props.center;
  _props = Object.assign(_props, { spinning: props.loading || false })

  if (props.center) return <div style={{ minHeight:"100px", display:"flex", flex:1, border:"0px solid black", alignItems:"center", justifyContent:"center", flexDirection:"row" }}>
    <Spin {..._props} />
  </div>

return <Spin {..._props} />
  // if (props.loading===true) _props = Object.assign(_props, {spinning:props.loading})
};
export default Loader;