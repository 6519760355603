import React, { useState } from 'react'
import { Button, DataRow, IconButton } from 'Common/components'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Alert, Col, Row, Tag, message } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper';
import { verifyRole } from 'Common/scripts/Security';
import { clientStatusArray } from 'configs';

const EDIT_CLIENT = loader('src/Graphql/client/editClient.graphql');

const fields = [
    // Client Information
    // { name: 'client_name', label: 'Client', type: "text" },
    { name: 'client_compnay_name', type: "text", label: 'Compnay' },
    { name: 'client_phone', type: "text", label: 'Phone' },
    { name: 'client_email', type: "text", label: 'Email' },
]

const BasicProfileForm = withApollo(({ initialValues, onUpdate, onCloseClick, client }) => {
    const [error, seteError] = useState(false);

    const renderField = (field_name, attributes) => {
        let theField = fields.find(o => o.name == field_name);
        if (!theField) return <Alert message={`Field (${field_name}) not found`} type='warning' />

        if (theField.type == "checkbox") return <FormField name={theField.name} data={theField.data} size="medium" type="checkbox" validate={theField.validate} {...attributes}>{theField.label}</FormField>
        return <FormField {...theField} size="medium" {...attributes} />
    }

    const onSubmit = async (values) => {
        const getFieldData = (field_name) => ({ name: field_name, label: fields.find(o => o.name == [field_name]).label, value: values[field_name] })

        let input = {
            _id: initialValues._id,
            name: values.name,
            status: values.status,
            fields:[
                // getFieldData("client_name"),
                getFieldData("client_compnay_name"),
                getFieldData("client_phone"),
                getFieldData("client_email"),
            ]
        }
        // console.log({ input })

        let resutls = await client.mutate({ mutation: EDIT_CLIENT, variables: { input } }).then(r => (r.data.editClient))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request at the moment!" } }
            })

        if (resutls.error) {
            message.error(resutls.error.message);
            return false;
        }

        onUpdate(resutls);
        return false;
    }

    return (<>
        <FormComponent onSubmit={onSubmit} id='ClientForm' fields={initialValues} debug={false}
            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    {error && <Alert message="Error!" description={error} type="error" showIcon />}

                    <Row>
                        <Col flex="auto" />
                        <Col flex="120px"><FormField label="Status" name="status" data={clientStatusArray} type="select" validate={rules.required} /></Col>
                    </Row>

                    <FormField name="name" label="Client Name" type="text" size="medium" validate={rules.required} />
                    {/* {renderField("client_name", { validate: rules.required })} */}
                    {renderField("client_compnay_name", { validate: rules.required })}
                    {renderField("client_phone", { validate: false })}
                    {renderField("client_email", { validate: false })}

                    <Row style={{ marginTop: '20px', marginRight: '10px' }}>
                        <Col flex="auto"></Col>
                        <Col>
                            <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!valid}>Save</Button>
                        </Col>
                    </Row>

                </>)
            }}
        />

    </>)
});

const ReadOnlyBasicProfile = ({ data }) => {
    const renderField = (field_name) => {
        if (!data.fields) return null;
        let thisField = data.fields.find(o => o.name == field_name);
        let theField = fields.find(o => o.name == field_name)
        if (!thisField) return null;

        return <DataRow col1={theField.label} col2={thisField.value} inline size={['150px']} />
    }

    console.log("data: ", data)

    return (<>
        <Tag color={data.status == 'active' ? '#87d068' : '#F00'}>{data.status}</Tag>
        {renderField("client_compnay_name")}
        {verifyRole('guard.sensitive-data') && <>
            {renderField("client_phone")}
            {renderField("client_email")}
        </>}
    </>)
}

export default function BasicProfile({ data, onUpdate }) {
    const [edit, set_edit] = useState(false);

    let initialValues = { _id: data._id, name: data.name, status: data.status }
    data?.fields?.forEach(field => {
        Object.assign(initialValues, { [field.name]: field.value })
    });

    const onDataUpdate = (values) => {
        onUpdate(values);
        set_edit(false);
    }

    return (<>
        {/* <Row align="top" gutter={[25]}>
            <Col flex="auto"><hr /></Col>
            <Col><IconButton onClick={() => set_edit(!edit)} style={{ position: "absolute", top: 0, right: 0 }} icon={edit ? "times" : "pen"} /></Col>
        </Row> */}
        <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}>{data.name} <IconButton onClick={() => set_edit(!edit)} icon={edit ? "times" : "pen"} /></div>

        {edit && <BasicProfileForm initialValues={initialValues} onUpdate={onDataUpdate} onCloseClick={() => set_edit(false)} />}
        {!edit && <ReadOnlyBasicProfile data={data} />}

    </>)
}
