import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT, MODULE } from './constants'

const modulePermissions = getmodulePermissions('hours-schedule-management');

// addReducers({ admins: reducers });
if (modulePermissions['hours-schedule.view']){
  addModule(
    { path: `${ROOT}`, component: Home, icon: 'calendar-alt', title:"Schedule Hours", exact:1, leftNav:true },
  )
}
