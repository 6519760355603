import React, { useState } from 'react'
import { ContentArea } from "Layout_v1";
import { Breadcrumb, message, Row, Col, Drawer, Space } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Tabs } from 'Common/components';
import FormsDataList from '../Components/FormsDataList';
import { reportsTypeArray } from '../constants'; 
import { Link } from 'react-router-dom';

const ReportList = props => {
  const [activeTab, set_activeTab] = useState(reportsTypeArray[0].key);
  // const [dataFilter, set_dataFilter] = useState({ ref_key: "firewatch_log_doc" });
  // let dataFilter = { ref_key: "firewatch_log_doc" };

  // var outgoing_url = `${process.env.REACT_APP_HOST_URL}/inputForm/${activeTab}`

  return (<>
    {/* <Row align="middle">
      <Col flex="auto">
        <Breadcrumb style={{ padding: "10px" }}>
          <Breadcrumb.Item to={'/'}><HomeOutlined /></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/admin/FormsData/reports">Reports</Link></Breadcrumb.Item>
        </Breadcrumb>
      </Col>
    </Row> */}
    
    {/* <p>{activeTab}</p> */}
    <div style={{ margin: "15px 15px 0 15px" }}><Tabs 
      tabsArray={reportsTypeArray} 
      onChange={set_activeTab}
      size="large"
    /></div>

    <ContentArea style={{marginTop:0}}>
      <FormsDataList permanent_filter={{ ref_key: activeTab }} title={reportsTypeArray.find(o => o.key == activeTab).title} ref_key={activeTab} />
    </ContentArea>

  </>)
}

export default ReportList;
