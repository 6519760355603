import React from 'react';
import { Row, Col } from 'antd';
import { Component} from 'react';
import Logo from '../assets/alist.png'
import '../style.scss'

export class Header extends Component {
    
    render() {
        const { title, extra } = this.props;

        return (
            <>
                <div style={{justifyContent:"center"}}>
                    <Row className='row' align="middle" style={{ flexWrap: "nowrap", justifyContent: "center"}}>
                        <Col style={{textAlign: "center", padding:'0 10px'}}><img style={{width:"100%"}} src={Logo} /></Col>
                        <Col className='size'>
                            <p className='bold  add' style={{ marginBottom: 0, marginTop: "50px", fontSize: "25px" }}>{title}</p>
                            {extra && <>
                                <p style={{width: "550px", fontWeight:'bold'}} >{extra}</p>
                            </>}
                            <p style={{ margin: 0 }} className='bold '>Tel: +1 (844) 585-9595</p>
                        </Col>
                        {/* tel: +1 (844) 585-9595 */}
                    </Row>
                </div>
            </>
        )
    }
}


export default Header;
