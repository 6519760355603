import React, { Component, useRef, useEffect, useState, useMemo } from 'react'
import { Avatar, Icon, UserCardPopover, Loader, Button } from 'Common/components'
import { ContentArea } from 'Layout_v1'
import { Col, Row, Space, Tooltip, Alert, message, Divider } from 'antd'
import { mongoToDate, sleep } from 'Common/scripts/Functions';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { defaultDateTimeFormat } from 'configs';
import { connect } from "react-redux";
import { channel_status_array } from '../../constants';
import TicketList from '../../components/tickets/TicketList'
import ChannelFormModal from '../../components/channels/channel_form';

const GET_CHANNEL = loader('src/Graphql/threads/threadsChannel.graphql');

const ChannelHeader = ({ data, setMode }) => {
  return (<ContentArea style={{ margin: "0" }} className="shadow threads-message">
    <Row gutter={[10, 0]} className='nowrap'>
      <Col><UserCardPopover userInfo={data?.author}><Avatar src={data?.author?.avatar} alt={data.author.name} size={32} /></UserCardPopover></Col>
      <Col>
        <div style={{ fontSize: "16px" }}>{data.author.name}</div>
        <div className="gray2" style={{ fontSize: "10px" }}>{mongoToDate(data.created_at).format(defaultDateTimeFormat)}</div>
      </Col>

      <Col flex="auto" style={{ lineHeight:"20px", marginLeft:"20px", position:"relative" }}>
        <div style={{ fontSize: "24px" }}>{data.title}</div>
        <div><span className="gray2">Status:</span> <b>{channel_status_array.find(o => (o._id == data.status))?.title}</b></div>

        <div className='hover_menu shadow'>
          <Row gutter={[10, 0]}>
            <Col><Tooltip placement="top" title={"Edit"}><Icon onClick={() => setMode("edit")} className="menu_icon a" icon={"pen"} /></Tooltip></Col>
          </Row>
        </div>
      </Col>

      {/* <Col>
        <ActionMenu trigger="hover" contentStyle={{}} hideArrow menu={[
          { title: "Edit", onClick: () => setMode("edit") },
          { title: "Threads history", to: `${ROOT_PATH}/tickets/history` },
        ]}><Icon icon="ellipsis-h" /></ActionMenu> 
      </Col> */}
    </Row>

  </ContentArea>)
}

class SubscriptionHandler extends Component {
  componentDidMount(){
    document.body.addEventListener('channel-event', this.onHandel.bind(this));
  }
  
  componentWillUnmount(){
    document.body.removeEventListener('channel-event', this.onHandel.bind(this));
  }

  onHandel(e){
    this.props.handleEventChange(e)
  }

  render() {
    return null
  }
}


function ChannelDisplay({ match, user }) { // match.params.id
  const [ticketsArray, set_setTicketsArray] = useState([])
  const [mode, setMode] = useState("preview")

  const [loadChannel, { called, loading, data }] = useLazyQuery(
    GET_CHANNEL, { variables: { filter: JSON.stringify({ _id: Number(match.params.id) }) } }
  );

  const handleEventChange = ({ detail }) => {
    console.log("handleEventChange()", detail)

    if (detail.mutation == "MESSAGE_DISCARDED" || detail.mutation=="MESSAGE_UPDATED") {
      if (data.threadsChannel._id == detail.message.ticket_id){
        let _ticketsArray = ticketsArray.map(o => (o._id == detail.message._id) ? detail.message : o);
        set_setTicketsArray(_ticketsArray)
      }
    }

    if (detail.mutation == "MESSAGE_ADDED") {
      if (data.threadsChannel._id == detail.message.ticket_id){
        let _ticketsArray = ticketsArray.slice();
        _ticketsArray.push(detail.message);
        set_setTicketsArray(_ticketsArray)
      }
    }

    if (detail.mutation == "MESSAGE_DELETED") {
      if (data.threadsChannel._id == detail.message.ticket_id){
        let _ticketsArray = ticketsArray.slice();
        _ticketsArray = _ticketsArray.filter(o => !(o._id == detail.message._id))
        set_setTicketsArray(_ticketsArray)
      }
    }

  }

  useEffect(() => {
    // let _filter = { _id: Number(match.params.id) }

    loadChannel().then(r=>{
      if (r.data.threadsChannel.error){
        message.error(r.data.threadsChannel.error.message);
        return r;
      }
      else{
        set_setTicketsArray(r.data.threadsChannel.messages);
      }
      return r;
    })
  }, [match.params.id])


  if (loading) return <Loader loading={loading} center />
  if (!data || !data?.threadsChannel || data?.threadsChannel?.error) return <Alert message={(data && data?.threadsChannel?.error?.message) || "No records found!"} type='error' showIcon />

  return (<>
    <SubscriptionHandler handleEventChange={handleEventChange} ticketsArray={ticketsArray} ticket={data.threadsChannel} />

    <ChannelHeader data={data.threadsChannel} setMode={setMode} />
    <div style={{ height: "20px" }} />

    <Row className='nowrap'>
      <Col flex="auto">
        <TicketList
          // title={<Row gutter={[10]} className='nowrap'>
          //   <Col>Threads</Col>
          //   <Col style={{ fontSize: "14px", lineHeight:"15px" }} flex="auto">
          //     <div>under</div>
          //     <div style={{ fontWeight: "bold", border: "1px solid black", width:"100%", maxWidth:"400px", display:"inline-block" }} className='ellipsis'>{data.threadsChannel.title}</div>
          //   </Col>
          // </Row>}
          // title={<>Threads <span style={{ fontSize: "14px" }}>under <b className='ellipsis' style={{ display:"inline-block" }}>{data.threadsChannel.title}</b></span></>}
          filter={{ status:"open" }}
          onDeleteClick={null}
          onEditClick={null}
          permanentFilter={{ "channel._id": Number(match.params.id) }} />
      </Col>

      <Col flex="300px">
        <ContentArea style={{ margin: "0 0 10px 10px" }}>
          <div>
            <Divider><b>Members</b></Divider>
            <Space direction='vertical'>
              {data.threadsChannel.members.map((o, i) => {
                return <div style={{ fontSize: "14px" }} key={i}><UserCardPopover userInfo={o}><Avatar src={o.avatar} alt={o.name} size={32} /></UserCardPopover> {o.name}</div>
              })}
            </Space>
          </div>
        </ContentArea>
      </Col>

    </Row>

    <ChannelFormModal visible={mode == "edit"} initialValues={data.threadsChannel} onSuccess={() => setMode("preview")} onCancel={() => setMode("preview")} />
  </>)

}
const mapStateToProps = ({ ep_admin:{user} }) => {
  return ({ user });
}
export default connect(mapStateToProps, null)(ChannelDisplay)

