import { CHANGE_TEMPLATE, CHANGE_PAGE_SIZE } from './actions';

const INITIAL_STATE = { template: "template1", pageSize: "A4" };

const changeTemplate = (state, template) => {
  return { ...state, template }
}
const changePageSize = (state, pageSize) => {
  return { ...state, pageSize }
}

export const po_reducer = (state = INITIAL_STATE, {type, payload} ) => {
  switch (type) {
    case CHANGE_TEMPLATE:
      return changeTemplate(state, payload);
    case CHANGE_PAGE_SIZE:
      return changePageSize(state, payload);
      // break;
    default:
      return state;
  }
}

export default { po_settings: po_reducer };

// const pConfig = {
//   key: 'cart',
//   storage,
//   blacklist: ['somethingTemporary'],
//   whitelist: ['cart'],
//   // stateReconciler: autoMergeLevel2,
//   // stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
// }
// export default persistReducer(pConfig, user_reducer);
