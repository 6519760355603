import React, { useState, useEffect } from 'react'
import { Space } from 'antd'
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import { Loader } from 'Common/components';
import { ThreadMessage } from './ThreadMessage'
import { Link } from 'react-router-dom';
import { ROOT_PATH } from '../../constants'

const RECENT_CLOSED = loader('src/Graphql/threads/threadsTicketRecentClosed.graphql');

export const RecentClosed = (props) => {

    const [loadResponse, { called, loading, data }] = useLazyQuery(
        RECENT_CLOSED, { variables: { filter: JSON.stringify({ }) } }
    );

    useEffect(() => {
        if (called || loading || data) return;
        loadResponse()
    }, [])

    if(loading) return <Loader loading={true} center />

    return (<>
        <p style={{ fontWeight: "bold" }} className='h1 heading'>Recently Closed ({data?.threadsTicketRecentClosed?.length || 0})</p>
        <Space direction='vertical' style={{ width:"100%" }} size={5}>
            {data && data?.threadsTicketRecentClosed?.map((item, i) => {
                return (<Link to={`${ROOT_PATH}/ticket/${item._id}`} key={i}>
                    {item.message && <ThreadMessage compact header={<b>{item.subject}</b>} ticket_status={item.status} messageData={item.message} ticket_id={item._id} />}
                </Link>)
            })}
        </Space>
    </>)

}
