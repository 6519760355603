import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT, MODULE } from './constants'

export const modulePermissions = getmodulePermissions('form-data-management');


if (modulePermissions['blacklist-manage']) {
  addModule(
    { path: `${ROOT}`, component: Home, icon: 'user-slash', title:"Black List", exact:1, leftNav:true },
  )
}
