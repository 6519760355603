import { UPDATE_STEPS, INIT_FORM_DATA, UPDATE_INFO, CLEAR_TEST, UPDATE_FORM_DATA } from '../constants';

export const clearTest = (payload) => {
    return {
        type: CLEAR_TEST,
        payload
    }
}

export const initForm = (payload) => {
    return {
        type: INIT_FORM_DATA,
        payload
    }
}
export const updateForm = (payload) => {
    return {
        type: UPDATE_FORM_DATA,
        payload
    }
}

export const updateSteps = (payload) => {
    return {
        type: UPDATE_STEPS,
        payload
    }
}

export const updateInfo = (payload) => {
    return {
        type: UPDATE_INFO,
        payload
    }
}




