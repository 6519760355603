import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { ROOT, MODULE } from './constants'
// import {Icon} from 'common/components'

// addReducers({ admins: reducers });
addModule(
  { path:`${ROOT}`, component:Home, icon:'desktop', title:"Dashboard", exact:1, leftNav:true },
)
