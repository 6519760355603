import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Popconfirm, Alert, message, Row, Col, Menu, Modal } from 'antd';
import { Table, Loader, Icon, IconButton, Button, Drawer, DeleteButton } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import { Link } from 'react-router-dom';
import { Breadcrumb } from "antd";
import { ContentArea } from "Layout_v1";
import { HomeOutlined } from '@ant-design/icons';
import InvitationSearchBar from './Components/InvitationSearchBar'
import { connect } from "react-redux";
import { updatePageSettings } from 'Store/ep_admin.actions.js'
import { useSubscription, gql } from "@apollo/client";
import { defaultPageSize, uriRoot } from 'configs'
import InvitationForm from './Components/InvitationForm'
import { mongoToDate } from 'Common/scripts/Functions';
import { timestamp } from 'Common/scripts/Functions';
import { withApollo } from '@apollo/client/react/hoc';
import PasswordProtectionform from './Components/PasswordProtectionform'

const UPDATE_PWD = loader('src/Graphql/TestResults/updateTestResultPassword.graphql');
const LIST_DATA = loader('src/Graphql/TestResults/testResultsQuery.graphql');
const UPDATED_STATUS = loader('src/Graphql/TestResults/updateTestResultStatus.graphql');
const QUERY_SUBSCRIPTION = loader('src/Graphql/TestResults/sub_testResultsUpdated.graphql');

function Subscriber({ QUERY, callback, filter, variables }) {
    // let variables = { };
    // if (filter){
    //     Object.assign(variables, { 
    //         ref_key: filter.ref_key
    //     });
    // }

    const { data, loading, error } = useSubscription(
        QUERY_SUBSCRIPTION,
        {
            // subscription: QUERY,
            // variables: { filter: JSON.stringify(variables) },
            variables,
            // fetchPolicy: "network-only" // cache-first, cache-only, cache-and-network, network-only, no-cache, standby
            onSubscriptionData: ({ client, subscriptionData }) => {
                const { data, error, loading, variables } = subscriptionData
                if (callback) callback(subscriptionData)
            },
            shouldResubscribe: false, // Determines if your subscription should be unsubscribed and subscribed again
        }
    );

    if (loading) {
        console.log("Receiving subscription on ....", variables);
        return null;
    }

    if (data) {
        console.log('Subscription received: ', data);
        // const { mutation, node } = data.formsDatasUpdated;
        // if (callback) callback({ mutation, node })
    }

    return null;

}

const defaultFilter = { $and: [{ status: { $eq: "invitation" } }] }

class InvitationsListComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null,
            error: null,
            kw: null,
            pagination: { current: 1, pageSize: defaultPageSize },

            showDrawer: false,
            showPasswordForm: false,
            filter: {},
        };
        // this.onSearch = this.onSearch.bind(this)
        // this.searchList = this.searchList.bind(this)
        // this.delete = this.delete.bind(this)
        this.onSubscriptionReceived = this.onSubscriptionReceived.bind(this)
        this.onSuccess = this.onSuccess.bind(this)
    }

    fetchData = async (args = {}) => {
        const variables = {
            first: args.pageSize || this.state.pagination.pageSize, // number of rec to fetch
            after: (args.pageSize || this.state.pagination.pageSize) * ((args.current || this.state.pagination.current) - 1),
            filter: JSON.stringify({ ...this.state.filter, ...args.filter, ...this.props.ep_admin?.pageSettings?.invitation_searchBar })
            // filter: JSON.stringify(args.filter || this.props.ep_admin?.pageSettings?.formdata_searchBar || {})
        }

        this.setState({ loading: true, filter: args?.filter || {} })

        return this.props.client.query({ query: LIST_DATA, variables }).then(r => {
            let response = r.data?.testResultsQuery;

            if (!response || response.error) {
                this.setState({ loading: false, error: (response && response?.message?.error) ||  "Invalid response from server" });
                return;
            }

            this.setState({
                loading: false,
                data: response,
                pagination: {
                    ...this.state.pagination,
                    current: args.current || 1,
                    pageSize: args.pageSize || this.state.pagination.pageSize,
                    total: response.totalCount
                }
            });

            return response;

        }).catch(err => {
            console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
            this.setState({ loading: false, error: "Unable to complete your request at the moment!" });
            return false;
        })
    }
    onSuccess() {
        this.fetchData()
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ current: pagination.current, pageSize: pagination.pageSize });
    };

    async updatePwd(_id, password) {
        this.setState({ loading: true });

        const resutls = await this?.props?.client?.mutate({
            mutation: UPDATE_PWD, variables: { password, id: _id }
        }).then(r => (r?.data?.updateTestResultPassword))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message:"Unable to update at the moment!" }}
            })

        if (!resutls || resutls.error){
            this.setState({ loading: false, error: resutls ? resutls.error.message : "Invalid response!" });
            return false;
        }

        this.fetchData({ filter:this.state.filter })
        message.success("Record successfully updated!")
    }

    async archive(_id) {
        this.setState({ loading: true });

        const resutls = await this.props.client.mutate({
                mutation: UPDATED_STATUS, variables: { status:'archived', id: _id }
            }).then(r => (r?.data?.updateTestResultStatus))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message:"Unable to delete at the moment!" }}
            })

        if (!resutls || resutls.error){
            this.setState({ loading: false, error: resutls ? resutls.error.message : "Invalid response!" });
            return false;
        }

        this.fetchData({ filter:this.state.filter })
        message.success("Record successfully deleted!")
    }

    toggeleDrawer() {
        this.setState({ showDrawer: !this.state.showDrawer })
    }

    onSubscriptionReceived({ data, error, loading, variables }) {
        const { mutation, node } = data.formsDatasUpdated;

        let _data = this.state.data.edges ? this.state.data.edges.slice() : [];

        if (mutation == "DELETED") {
            _data = _data.filter(o => {
                return o._id != node._id;
            });
        }

        else if (mutation == "UPDATED") {
            // let data = this.state.data ? this.state.data.slice() : [];
            _data = _data.map(o => {
                if (o._id == node._id) return { ...node }
                return o;
            })
        }

        else if (mutation == "CREATED") {
            // let data = this.state.data ? this.state.data.slice() : [];
            _data.push(node);
        }

        else {
            console.log(__error("Invalid Subscription received: ", data));
            return;
        }

        this.setState({ data: { ...this.state.data, edges: _data } })
    }

    columns = [
        { title: 'ID', dataIndex: '_id', width: "100px" },
        {
            title: 'Test Title', dataIndex: 'title', render: (___, rec) => {
                return (<>
                    <Link to={`./view/${rec._id}`}>{rec?.test_form?.title}</Link>
                    <div>Invitation URL <Link to={`/dotest/${Buffer.from(timestamp() + "," + rec._id).toString('base64')}`} target="_blank"><Icon icon="external-link-alt" /></Link></div>
                    {rec?.course?._id && <div>Course: {rec?.course?.title}</div>}

                    <span className='hidden-menu'>
                        <IconButton className="item" icon={{ icon: 'archive' }} />
                        <Button onClick={()=>this.setState({ showPasswordForm: rec })} size="small">{rec.pwd_protect === 'yes' ? 'Update password' : 'Password protect'}</Button>
                    </span>

                </>)
            }
        },
        // { title: 'Phone No#', dataIndex: "candiateInfo.phone_number", render: (___, rec) => rec.candiateInfo.phone_number },
        { title: 'Candidate', dataIndex: 'candiateInfo.name', render: (___, rec) => {
            return <>
                <div>{rec.pwd_protect === 'yes' && <Icon icon="lock" />} {rec.candiateInfo.name}</div>
                {rec.candiateInfo.lc_number && <div>Lic #: {rec.candiateInfo.lc_number}</div>}
                <div>{rec.candiateInfo.phone_number}</div>
                <div>{rec.candiateInfo.lc_number}</div>
            </>
        } },
        { title: 'Date', dataIndex: 'created_at', render: (___, rec) => mongoToDate(rec.created_at).format('DD-MMM-YYYY HH:mm'), width: "170px" },
        {
            title: '', width: "50px", align: 'center', render: (___, rec) => {
                return <div>
                    {/* <IconButton onClick={() => this.toggeleDrawer(rec)} icon={{ icon: 'pen' }}></IconButton> */}
                    <Popconfirm onConfirm={() => this.archive(rec?._id)} title="Are you sure to archive this record?">
                        <IconButton className="item" icon={{ icon: 'archive' }}></IconButton>
                    </Popconfirm>
                </div>
            }
        },
    ];


    render() {
        const { loading, data, error } = this.state;
        const { pagination, showDrawer } = this.state;

        // if (loading) return <ContentArea><Loader style={{ margin: "auto" }} loading={true} /></ContentArea>
        if (error) return <ContentArea><Alert message="Error" description={error} type="error" showIcon /></ContentArea>

        return (<>
            <Subscriber QUERY={QUERY_SUBSCRIPTION} callback={this.onSubscriptionReceived} variables={{ status: "invitation" }} />

            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                {/* <Breadcrumb.Item href="/templates"><span>Templates</span></Breadcrumb.Item> */}
                <Breadcrumb.Item>Test Invitations</Breadcrumb.Item>
            </Breadcrumb>

            <ContentArea>
                <Row style={{ margin: "0 10px" }} gutter={[10]}>
                    <Col flex="auto"></Col>
                    <Col><a href='/dotest/protected' target='_blank'>Open Protected Url</a></Col>
                    <Col><Button onClick={() => this.toggeleDrawer()}>Create New Test Invitation</Button></Col>
                </Row>

                <div style={{ marginTop: "10px", padding: "0 10px 5px 10px", border: "1px solid #EEE", borderTopRightRadius: "5px", borderTopLeftRadius: "5px", backgroundColor: "rgba(0, 0, 0, 0.02)" }}>
                    <InvitationSearchBar fetchData={(args) => this.fetchData(args)} defaultFilter={defaultFilter} />
                </div>

                <Table loading={loading}
                    title={() => <b>Total {((data && data.totalCount) || '0')} record(s) found </b>}
                    columns={this.columns}
                    dataSource={data ? data.edges : null}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    // rowClassName={(record => record.status == 'new' ? 'hilighted-table-row' : "")}
                />
            </ContentArea>

            <InvitationForm onSuccess={this.onSuccess} showDrawer={showDrawer} onClose={() => this.toggeleDrawer()} />

            <PasswordProtectionform
                visible={this.state.showPasswordForm !== false}
                initialValues={this.state.showPasswordForm}
                onClose={() => this.setState({ showPasswordForm: false })}
                onSuccess={() => this.setState({ showPasswordForm: false })}
            />

        </>)
    }
}
export const InvitationsList = withApollo(InvitationsListComp)

const mapStateToProps = ({ ep_admin }) => {
    return ({ 
        ep_admin, 
        invitation_searchBar: ep_admin?.pageSettings?.invitation_searchBar 
    });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(InvitationsList)
