import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col, Alert } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import { Drawer, Button, Heading, Icon, Loader, ValuePairsSearch, Avatar } from 'Common/components'
import { rules, composeValidators, FormField, UploadField, FormComponent } from 'Common/components/Form'
import { generalStatus, availableUserTypes, genders, userStatus } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper';

const GET_USER = loader('src/Graphql/user/user.graphql');
const RECORD_EDIT = loader('src/Graphql/user/editUser.graphql');
const RECORD_ADD = loader('src/Graphql/user/addUser.graphql');
const LIST_ROLES = loader('src/Graphql/user_role/userRoles.graphql');

const defaultFields = { status: userStatus[0]._id }

export const UserForm = props => {
    const [error, setError] = useState(null);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [busy, setBusy] = useState(false);
    const [fields, setFields] = useState(false);
    const { userRoles, loading } = props;

    // let fields = __fields ? { ...__fields } : props.fields ? { ...props.fields } : {}
    // if (!props.showform && __fields) setFields(false);

    useEffect(() => {
        console.log("fields changed");
        
        /// fetch user if edit request
        if (props?.fields?._id){
            setLoadingEdit(true);
    
            props.client.query({ query: GET_USER, variables: { id: props.fields._id } }).then(e => {
                const response = e?.data?.user;
    
                if (!response || response.error) {
                    message.error(!response ? "Invalid response!" : response.error);
                }else{
                    // let _settings = response.settings ? JSON.parse(response.settings) : {};
                    setFields({ ...response });
                }
    
                setLoadingEdit(false);
    
            }).catch(err => {
                message.error("Response Error!");
                console.log(__error("API Call ERROR: USER_ROLE : "), err);
                setError("Response Error!");
                setLoadingEdit(false);
            })
        }

    //   return () => {
    //     effect
    //   };
    }, [props.fields])

    const onSubmit = async(values) => {
        const { onClose, editUser, addUser, callback } = props;
        const _id = fields ? fields._id : false;

        if (!_id && !values.password){
            message.error("Please provide password!")
            return;
        } 

        const filteredValues = {
            name: values.name,
            email: values.email,
            acc_type: values.acc_type,
            password: values.password,
            status: values.status,
            note: values.note,
            avatar: values.avatar,
            // settings: JSON.stringify(values.settings)
            // permissions: { type: String }, // [{module:"guard_application", permissions:["view.list", "add.notes", "delete.application", "request.correction"]}] 
        };
        if (_id) Object.assign(filteredValues, { _id })
        
        if (values.password && (values.password != values.confirm_pwd)) {
            message.error("Password missmatch")
            return;
        } else if (values.password && (values.password == values.confirm_pwd)) {
            Object.assign(filteredValues, { password: values.password });
        }

        const thisFunction = _id ? editUser : addUser;
        
        setBusy(true);
        thisFunction(filteredValues).then((e) => {
            setBusy(false);

            const response = e.data.editUser || e.data.addUser;

            if (response.error){
                let err = response.error;
                message.error(err.message);
                return false;
            }

            message.success("Success");
            if (callback) callback(e.data.addUser ? 'added' : 'updated', response)
            
            onClose(response);

        }).catch(error => {
            setBusy(false)
            console.log(__error("ERROR: "), error);
            message.error("Query Error");
        });

    }


    return (
        <Drawer width={500} destroyOnClose maskClosable={false} placement="right"
            onClose={props.onClose}
            visible={props.showform}
            bodyStyle={{ }}
            footer={<>
                <span></span>
                <Button loading={busy} type="primary" onClick={() => {
                    document.getElementById('UserForm').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
                }}>Save</Button>
            </>}
            title={`${fields && fields._id ? 'Edit' : 'Add'} User`}
        ><>
            {error && <Alert message="Error" description={error} type="error" showIcon />}
            {(loading || loadingEdit) && <Loader loading={true} />}

            {(!error && !loading && !loadingEdit) && <>
                <FormComponent onSubmit={onSubmit} id='UserForm' loading={busy || loading} fields={{ ...defaultFields, ...fields }} debug={true}
                        mutators={{
                            updateAvatar: (newValueArray, state, tools) => {
                                let node = newValueArray[0];
                                tools.changeValue(state, 'avatar', () => node);
                            },
                        }}

                    form_render={formProps => {
                        const { values, form, invalid, dirty, valid } = formProps;

                        return(<>
                            <Row>
                                <Col flex="70%"><FormField type="select" name="acc_type" label="Type" data={userRoles.map(o => ({ ...o, _id: o.acc_type }))} validate={rules.required} /></Col>
                                <Col flex="auto">
                                    <FormField className={values.status == 'enabled' ? "active" : "inactive"} type="select" name="status" label="Status" data={userStatus} validate={rules.required} />
                                </Col>
                            </Row>

                            <Row align="middle">
                                <Col><Avatar uploadAvatar={async (v)=>{
                                    // console.log(v);
                                    form.mutators.updateAvatar(v);
                                    return {};
                                }} src={fields.avatar} alt={fields.name} size={100} /></Col>
                                <Col flex="auto">
                                    <FormField type="text" name="name" label="Name" validate={rules.required} />
                                    <FormField type="text" name="email" label="Email Address (Login User)" validate={composeValidators(rules.required, rules.isEmail)} />
                                </Col>
                            </Row>

                            <Row>
                                <Col flex="auto">
                                    <FormField type="password" name="password" label="Password" validate={!values._id ? rules.required : undefined} />
                                </Col>
                                <Col flex="49%">
                                    <FormField type="password" name="confirm_pwd" label="Confirm Password" validate={!values._id ? rules.required : undefined} />
                                </Col>
                            </Row>

                            <FormField type="textarea" name="note" label="Note" placeholder="Notes" />

                            <hr />
                            {/* <ValuePairsSearch key_value="noticeboard_categories" preload name="settings.noticeboard.categories" label="Noticeboard Categories" multiselect style={{ width: "100%" }} /> */}

                            {/* <Button type="primary" htmlType="submit">Submit</Button> */}
                        </>)
                    }}
                />                
            </>}


        </></Drawer>
    )
}

UserForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    callback: PropTypes.func,
    fields: PropTypes.object,
}

const WithApollo = compose(
    graphql(LIST_ROLES, {
        options: props => {
            return { variables: {} };
        },
        props: ({ ownProps, data }) => {
            const { loading, userRoles, error } = data;
            if (error) console.log(__error("error"), error);

            return { loading, userRoles, queryErrors: error }
        }
    }),

    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editUser: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
    graphql(RECORD_ADD, {
        props: ({ mutate }) => ({
            addUser: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
)(UserForm);

export default withApollo(WithApollo);