import React, { Component, useState } from 'react'
import { Row, Col } from 'antd';
// import { loader } from 'graphql.macro';
import { Icon, DataRow } from 'Common/components';
import { ContentArea } from "Layout_v1";
import { mongoToDate } from 'Common/scripts/Functions';
import Notes from 'Modules/Notes';
import { withApollo } from '@apollo/client/react/hoc';
import { TheField } from '../Components'
import { ListHeader } from 'Common/components/Typography';
import { FORM_KEY } from '.';

const SectionHeading = ({ children }) => (<div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}>{children}</div>)


const FormsDataViewComp = ({ data, client }) => {
    if (!data) return null;

    function getField(_fieldname, fromArray){
        let fields = data.fields;
        return fromArray ? 
            fromArray.find(o => o.name == _fieldname) || {} : 
            fields.find(o => o.name == _fieldname) || {};
    }

    const renderPosition = () => {
        var field_data = getField('position');
        
        let value = JSON.parse(field_data.value);
        let date_to_start = value.others.find(o => o.name == 'date_to_start');
        let notes = value.others.find(o => o.name == 'notes');
        let special_notes = value.others.find(o => o.name == 'special_notes');

        return (<>
            <SectionHeading>Position</SectionHeading>
            <Row>
                <Col flex="20%">
                    <p><b>Looking for a position as</b></p>
                    {value.lookingfor.filter(o => o.value == 'yes').map((o, i) => {
                        return <TheField label={o.label} value={o.value} key={i} isCheckbox />
                    })}
                </Col>
                <Col flex="20%">
                    <p><b>Availbility to work</b></p>
                    {value.availability_for_work.filter(o => o.value == 'yes').map((o, i) => {
                        return <TheField label={o.label} value={o.value} key={i} isCheckbox />
                    })}
                </Col>
                <Col flex="20%">
                    <p><b>Availability for shifts</b></p>
                    {value.availability_for_shift.filter(o => o.value == 'yes').map((o, i) => {
                        return <TheField label={o.label} value={o.value} key={i} isCheckbox />
                    })}
                </Col>
                <Col flex="auto">
                    <DataRow col1={date_to_start.label} col2={date_to_start.value.split("T")[0]} />
                    <DataRow col1={notes.label} col2={notes.value} vertical />
                </Col>
            </Row>
            <DataRow col1={special_notes.label} col2={special_notes.value} vertical />

        </>)
    }

    const renderAvailability = () => {
        const availability = getField('availability').value ? JSON.parse(getField('availability').value) : [];

        return(<>
            <SectionHeading>Days Available to work</SectionHeading>
            <Row align="middle" style={{ color: "#060079" }} className='dark'>
                {['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
                    return <Col flex="12.5%" align="center" style={{ borderBottom: "1px solid #EEE", paddingTop: "10px", borderRight: "1px solid #EEE", fontWeight: "bold" }} key={index}>{day}</Col>
                })}
            </Row>

            {["Morning", "Afternoon", "Night"].map((daypart, index) => (
                <Row align="middle" key={index}>
                    <Col flex="12.5%" style={{ height: "40px", paddingTop: "10px" }}>{daypart}</Col>
                    {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                        return <Col key={i} flex="12.5%" align="center" style={{ height: "40px", padding: "10px", borderBottom: "1px solid #EEE", borderRight: "1px solid #EEE" }}>
                            <>
                                {availability[day].indexOf(daypart.toLowerCase()) > -1 ?
                                    <Icon icon="check" size={"1x"} color="green" /> :
                                    <Icon icon="times" size={"1x"} color="#EEE" />
                                }
                            </>
                        </Col>
                    })}
                </Row>
            ))}

        </>)
    }

    const renderEducation = () => {
        var field_data = JSON.parse(getField('edu').value);

        let security_lc_no = field_data.find(o => o.name =='security_lc_no')
        let designation1 = field_data.find(o => o.name =='designation1')
        let designation2 = field_data.find(o => o.name =='designation2')
        let designation3 = field_data.find(o => o.name =='designation3')
        let designation4 = field_data.find(o => o.name =='designation4')
        let designation5 = field_data.find(o => o.name =='designation5')
        let special_skills = field_data.find(o => o.name =='special_skills')
        let lc_no_mob_p_only = field_data.find(o => o.name =='lc_no_mob_p_only')

        return(<>
            <SectionHeading>Education and Training</SectionHeading>
            <DataRow col1={security_lc_no.label} col2={security_lc_no.value} />

            <Row gutter={[10]}>
                <Col><b>Designation</b></Col>
                <Col flex="auto">
                    <DataRow col1={designation1.label} col2={designation1.value} />
                    <DataRow col1={designation2.label} col2={designation2.value} />
                    <DataRow col1={designation3.label} col2={designation3.value} />
                    <DataRow col1={designation4.label} col2={designation4.value} />
                    <DataRow col1={designation5.label} col2={designation5.value} />
                </Col>
            </Row>
            <DataRow col1={special_skills.label} col2={special_skills.value} />
            <DataRow col1={lc_no_mob_p_only.label} col2={lc_no_mob_p_only.value} />
        </>)
    }

    const renderOfficeUse = () => {
        var field_data = JSON.parse(getField('office_use').value);

        let date_interviewed = field_data.find(o => o.name =='date_interviewed')
        let gender = field_data.find(o => o.name =='gender')
        let photos = JSON.parse(field_data.find(o => o.name =='photos').value)
        let all_fields_filled = field_data.find(o => o.name =='all_fields_filled')
        let check_legibility = field_data.find(o => o.name =='check_legibility')
        let have_resume = field_data.find(o => o.name =='have_resume')
        let yrs_in_security_industry = field_data.find(o => o.name =='yrs_in_security_industry')
        let mobile_driving_yrs = field_data.find(o => o.name =='mobile_driving_yrs')
        let have_own_car = field_data.find(o => o.name =='have_own_car')
        let in_calgary = field_data.find(o => o.name =='in_calgary')
        let know_about_calgary = field_data.find(o => o.name =='know_about_calgary')
        let is_citizen = field_data.find(o => o.name =='is_citizen')
        let is_permanent_resident = field_data.find(o => o.name =='is_permanent_resident')
        let is_valid_work_permit = field_data.find(o => o.name =='is_valid_work_permit')
        let work_at_christmas = field_data.find(o => o.name =='work_at_christmas')
        let special_holidays = field_data.find(o => o.name =='special_holidays')
        let pant_shirt_size = field_data.find(o => o.name =='pant_shirt_size')
        let other_notes = field_data.find(o => o.name =='other_notes')

        return (<>
            <SectionHeading>Office Use</SectionHeading>
            <Row>
                <Col flex="50%">
                    <TheField label={date_interviewed.label} value={date_interviewed.value.split("T")[0]} />
                </Col>
                <Col flex="auto">
                    <TheField label={gender.label} value={gender.value.split("T")[0]} />
                </Col>
            </Row>

            <hr style={{margin:"30px"}} />
            <Row gutter={[15]}>
                <Col><b>Have you taken a photo copy of</b></Col>
                <Col>
                    <Row gutter={[15]}>
                        {photos.map((o, i) => {
                            return <Col key={i} flex="50%"><TheField label={o.label} value={o.value} isCheckbox key={i} /></Col>
                        })}
                    </Row>
                </Col>
            </Row>

            <hr style={{ margin: "30px" }} />
            <TheField label={all_fields_filled.label} value={all_fields_filled.value} horizontal isCheckbox />
            <TheField label={check_legibility.label} value={check_legibility.value} horizontal isCheckbox />
            <TheField label={have_resume.label} value={have_resume.value} horizontal isCheckbox />
            
            <hr style={{ margin: "30px" }} />
            <DataRow col1={yrs_in_security_industry.label} col2={yrs_in_security_industry.value} horizontal />
            <DataRow col1={mobile_driving_yrs.label} col2={mobile_driving_yrs.value} horizontal />
            <DataRow col1={have_own_car.label} col2={have_own_car.value} horizontal />
            <DataRow col1={in_calgary.label} col2={in_calgary.value} horizontal />
            <DataRow col1={know_about_calgary.label} col2={know_about_calgary.value} horizontal />

            <hr style={{ margin: "30px" }} />
            <Row gutter={[15]}>
                <Col><b>Canadian Immigration Status</b></Col>
                <Col>
                    <TheField label={is_citizen.label} value={is_citizen.value} horizontal isCheckbox />
                    <TheField label={is_permanent_resident.label} value={is_permanent_resident.value} horizontal isCheckbox />
                    <TheField label={is_valid_work_permit.label} value={is_valid_work_permit.value} horizontal isCheckbox />
                </Col>
            </Row>
            
            <hr style={{ margin: "30px" }} />
            <TheField label={work_at_christmas.label} value={work_at_christmas.value} horizontal isCheckbox />
            <DataRow col1={special_holidays.label} col2={special_holidays.value} vertical />
            <DataRow col1={pant_shirt_size.label} col2={pant_shirt_size.value} vertical />
            <DataRow col1={other_notes.label} col2={other_notes.value} vertical />

        </>)

    }

    let name = `${getField('f_name').value} ${getField('m_name').value} ${getField('l_name').value}`

    return(<>
        <Row style={{ flexWrap: "nowrap" }}>
            <Col flex="auto">
                <ContentArea>
                    <Row style={{ marginBottom: "30px" }}>
                        <Col flex="auto"><ListHeader title={name} /></Col>
                        <Col style={{ color: '#999' }}>{mongoToDate(data.created_at).format('Do MMM YYYY - HH:mm')}</Col>
                    </Row>

                    <SectionHeading>General Information</SectionHeading>
                    <Row gutter={[10]}>
                        <TheField {...getField('f_name')} vertical />
                        <TheField {...getField('m_name')} vertical />
                        <TheField {...getField('l_name')} vertical />
                    </Row>

                    <div style={{height:"40px"}} />
                    <hr />
                    {renderPosition()}

                    <div style={{ height: "40px" }} />
                    <hr />
                    {renderAvailability()}

                    <div style={{ height: "40px" }} />
                    <hr />
                    {renderEducation()}

                    <div style={{ height: "40px" }} />
                    <hr />
                    {renderOfficeUse()}

                </ContentArea>
            </Col>
            <Col flex="400px">
                {/* <ContentArea style={{ marginLeft:0 }}>A</ContentArea> */}

                <ContentArea style={{ marginLeft: 0 }}><Notes id={data._id} formkey={FORM_KEY} /></ContentArea>
            </Col>
        </Row>
    </>)

}

export const FormsDataView = withApollo(FormsDataViewComp)
export default FormsDataView;