import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as compose from 'lodash.flowright';
import { loader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';
import { Popconfirm, Alert, message } from 'antd';
import { Loader, Icon, Button, IconButton, Table } from 'Common/components';
import { defaultPageSize } from 'configs';
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
const axios = require('axios');

const LIST_DATA = loader('src/Graphql/user_role/userRolesQuery.graphql');
const QUERY_SUBSCRIPTION = loader('src/Graphql/user_role/subscription.graphql');
const RECORD_DELETE = loader('src/Graphql/user_role/deleteUserRole.graphql');

/**
 * TypeList
 */
export class TypeList extends Component { // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
        super(props);
        this.state = { selectedRecord: false, showDrawr: false, busy: false, error: null }
        this.handelResponse = this.handelResponse.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;

        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: { 
                        filter: JSON.stringify({}), 
                        others: JSON.stringify({}) 
                    },
                _subscriptionName: "userRolesUpdated",
                _subscriptionType: "array",
                _queryName: "userRolesQuery",
                _typename: "UserRole",
                debug: true
            });
        }

    }

    renderActions = (text, record) => {
        return (
            <span className="action_buttons">
                <IconButton onClick={() => this.props.onEditRecord(record)} icon="pen" />
                <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record._id)}>
                    <IconButton icon="trash-alt" />
                </Popconfirm>
            </span>
        )
    }

    columns = [
        { title: 'Name', dataIndex: 'title' },
        { title: 'Type', dataIndex: 'acc_type', },
        // { title: 'Rights', dataIndex: 'rights', },
        {
            title: 'Actions',
            dataIndex: '',
            render: this.renderActions,
            className: 'actions-column',
            align: 'right',
            width: '120px'
        },
    ];

    handleDelete = _id => {
        console.log(`handleDelete(${_id})`);
        this.props.deleteUserRole(_id).then(e => { })
    };

    toggleDrawr(record = false) {
        this.setState({ showDrawr: !this.state.showDrawr, selectedRecord: record })
    }

    handelResponse(response) {
        this.setState({ busy: false });

        if (response.error) {
            this.setState({ error: response.error });
            return;
        }
        if (response.data.error) {
            this.setState({ error: response.data.error.message });
            return;
        }
    }

    SyncTypes = args => {
        const { userRolesQuery } = this.props;
        const accountTypes = userRolesQuery.edges.map(t => ({ title: t.title, _id: t.acc_type }) )

        if (this.state.busy) return false;

        this.setState({ busy: true, error: false });
        var handelResponse = this.handelResponse;

        axios({
            method: 'put',
            url: process.env.REACT_APP_API_URL + '/sync/account_types',
            data: { accountTypes: accountTypes }
        })
            .then(async function (response) {
                handelResponse(response);
            })
            .catch(function (error) {
                console.log("ERROR: ", error);
                handelResponse({ error: 'Netowrk Error' });
            });

    }



    render() {
        const { loading, userRolesQuery, queryErrors } = this.props;
        const { showDrawr, selectedRecord, busy, error } = this.state;
        
        if (queryErrors) console.log(__error("queryErrors"), queryErrors);

        if (loading) return <Loader loading={loading} />

        return (
            <>
                {/* <PageHeader title={`Manage Account Types`}
                    right={<Button type="primary" onClick={() => this.SyncTypes()}><Icon type="SyncOutlined" spin={busy} /> Sync Types</Button>}
                /> */}

                {error && <Alert message="Error" type="error" description={error} onClose={() => this.setState({ error: false })} showIcon closable />}
                {/* {queryErrors && <Alert type='error' message={`${queryErrors.networkError.name}`} description={`${queryErrors.networkError.response.statusText} : ${queryErrors.networkError.response.status}`} showIcon />} */}

                <Table loading={loading}
                    columns={this.columns}
                    dataSource={userRolesQuery ? userRolesQuery.edges : null}
                    // title={() => <span>Header</span>}
                    // footer={() => 'Footer'}
                    expandable={{
                        expandedRowRender: record => <p style={{ margin: 0 }}>{record.rights}</p>,
                        // rowExpandable: record => record.name !== 'Not Expandable',
                    }}
                />

            </>
        );
    }
}

TypeList.propTypes = {
    // prop: PropTypes.type.isRequired
    onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { variables: { first: defaultPageSize, after: 0 } };
        },
        props: ({ ownProps, data }) => {
            const { loading, userRolesQuery, error, fetchMore, subscribeToMore } = data;
            
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.userRolesQuery.totalCount
                    const newEdges = fetchMoreResult.userRolesQuery.edges
                    const pageInfo = fetchMoreResult.userRolesQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        userRolesQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'UserRole'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, userRolesQuery, queryErrors:error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(RECORD_DELETE, {
        props: ({ mutate }) => ({
            deleteUserRole: (id) => mutate({
                variables: { id }
            }),
        })
    }),

)(TypeList);

export default WithApollo;
