import React, { Component } from 'react'
import { Icon, Loader } from 'Common/components';
import { ContentArea } from "Layout_v1";
import { HomeOutlined } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import { Breadcrumb, Popconfirm, Alert, message, Row, Col } from 'antd';
import { __error } from 'Common/scripts/consoleHelper';

import TestFormPreview from './Components/test_result_preview';
import { uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';


const TEST_RESULT = loader('src/Graphql/TestResults/testResult.graphql');
const UPDATE_STATUS = loader('src/Graphql/TestResults/updateTestResultStatus.graphql');

class DataPreviewComp extends Component {
    state = { loading: true, data: null, error: null };

    componentDidMount() {
        // console.log("this.props.match.params.id: ", this.props.match.params.id)
        if (this.props.match.params.id) {
            this.loadFormData({ id: Number(this.props.match.params.id) });
        } else {
            this.setState({ error: 'No Data ID Found!' })
        }
    }

    async loadFormData(filter = {}) {
        // console.log("loadFormData() ", filter)

        this.setState({ loading: true });

        return this.props.client.query({ 
                query: TEST_RESULT, 
                variables: filter,
                fetchPolicy: "no-cache" // cache-first, cache-only, cache-and-network, network-only, no-cache, standby
            }).then(r => {
            let response = r.data?.testResult;
            // console.log("response: ", response)

            if (!response) return this.setState({ loading: false, error: "Invalid response from server" });
            if (response.error) return this.setState({ loading: false, error: response.message });

            this.updateStatustoView(response).then(r=>{
                this.setState({ loading: false, data: { ...response, ...r } });
            });


        }).catch(err => {
            console.log("ERROR: ", err);
            this.setState({ loading: false, error: "Unable to complete your request at the moment!" });
        })

    }

    async updateStatustoView(_data){
        if (_data.status!='new') return;

        const updated = this.props.client.mutate({ mutation: UPDATE_STATUS, variables: { id: _data._id, status: 'viewed' }}).then(r => {
            let response = r.data.updateTestResultStatus;
            if (!response || response.error) {
                message.error(response && response?.error?.message || "Invalid Response!")
                return {};
            }

        }).catch(err => {
            console.log(__error("ERROR"), err);
            message.error("Failed to update status!");
            return {};
        });

        return { status: 'viewed' };

    }

    goBack(e) {
        e.preventDefault();
        this.props.history.goBack();
    }

    // renderForm(){
    //     const { data } = this.state;

    //     switch (data._id) {
    //         case "test_1":
    //             // this.setState({ pageTitle:"Guard Application Preview" })
    //             return <DriverTestForm_Preview data={data} />
    //             break;

    //         // case CLIENT_INFO_FORM:
    //         //     // this.setState({ pageTitle: "Client Query Preview" })
    //         //     return <ClientQueryform_Preview data={data} />
    //         //     break;

    //         default:
    //             return <E404 />
    //             break;
    //     }
    // }


    render() {
        const { loading, data, error } = this.state;

        var pageTitle = "Invalid Data";

        if (error) return <ContentArea><Alert message="Error" description={error} type="error" showIcon /></ContentArea>
        if (loading) return <ContentArea><Loader loading={true} /></ContentArea>
        if (!data) return null;

        pageTitle = data.title;


        return (<>
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item onClick={(e) => this.goBack(e)} href="#"><span><Icon icon="clipboard-list" size="1x" /> Form Data</span></Breadcrumb.Item>
                <Breadcrumb.Item><span>{pageTitle}</span></Breadcrumb.Item>
            </Breadcrumb>

            <div style={{flex:1}}>
                <TestFormPreview data={data} />
                {/* {this.renderForm()} */}
            </div>

        </>)
    }
}

export const DataPreview = withApollo(DataPreviewComp)
export default DataPreview;
