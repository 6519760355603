import React from 'react'
import PropTypes from 'prop-types';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Button, UsersSearch } from 'Common/components';
import { loader } from 'graphql.macro';
import { message, Row, Col, Modal } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';
import { __error } from 'Common/scripts/consoleHelper';

const UPDATE_MEMBERS = loader('src/Graphql/threads/updateTicketMembers.graphql');


const TicketMembersForm = ({ onSuccess, onCancel, client, initialValues }) => {
       
    const onTicketSubmit = async (values) => {
        const input = {
            _id: Number(initialValues._id),
            members: values.members && values?.members?.map(o=>({ _id:o._id, name:o.name })),
        }

        let results = await client.mutate({ mutation: UPDATE_MEMBERS, variables: { input } }).then(r => (r?.data?.updateTicketMembers))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })


        if (results.error){
            message.error(results.error.message);
            return false;
        }

        onSuccess();
        return false;
    }

    return (<>
        <FormComponent onSubmit={onTicketSubmit} id='TicketMembersForm' fields={initialValues} debug={false} 
            mutators={{
                selectUser: (newValueArray, state, tools) => {
                    let node = newValueArray[0];

                    let _dataArray = state.formState.values.members || [];
                    _dataArray.push({ _id: node._id, name: node.name })

                    tools.changeValue(state, 'members', () => _dataArray);
                },
                de_selectUser: (newValueArray, state, tools) => {
                    let _dataArray = state.formState.values.members || [];
                    let _ids = state.formState.values.members_ids || [];

                    _dataArray = _dataArray.filter(o => (_ids.includes(o._id)))

                    tools.changeValue(state, 'members', () => _dataArray)
                },
            }}
            form_render={formProps => {
                const { values, submitting, form, invalid, dirty, valid } = formProps;

                return (<>
                    <UsersSearch mode="multiple" label="Members" name='members_ids'
                        filter={{ status:"enabled" }}
                        preload disableSearch
                        disabled={!values.channel || values.channel.length<1}
                        onSelect={(___, opt, raw) => form.mutators.selectUser(raw)}
                        onDeselect={(txt, node) => form.mutators.de_selectUser(node)}
                        defaultValues={values.members ? values.members.map(o => ({ ...o, title: o.name })) : []}
                        validate={rules.required}
                    />
                    
                    <Row gutter={[10]}>
                        <Col flex="auto" />
                        <Col><Button onClick={onCancel} color="white">Cancel</Button></Col>
                        <Col><Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >{values._id ? "Update" : "Create"} Ticket</Button></Col>
                    </Row>                    

                </>)
            }}
        />

    </>)
}
const WithApollo = withApollo(TicketMembersForm)

export default function TicketMembersFormModal({ initialValues, visible, onCancel, onSuccess }) {
    const handel_onSuccess = (values) => {
        if (onSuccess) onSuccess()
        else onCancel()
    }

    let members_ids = initialValues && initialValues?.members?.map(o=>(o._id));

    return (<>
        <Modal title="Members" visible={visible} width="700px" onCancel={onCancel} footer={false} closable centered destroyOnClose>
            {visible && <WithApollo onCancel={onCancel} initialValues={{ ...initialValues, members_ids }} onSuccess={handel_onSuccess} />}
        </Modal>
    </>)
}
TicketMembersFormModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    initialValues: PropTypes.object,
}

