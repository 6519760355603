import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { DevBlock, Icon, IconButton } from 'Common/components';
import { Row, Col } from 'antd';
import _ from 'lodash'


const ExplainSelectedFilters = ({ filters, onEditClick, onRemoveClick }) => {
    const parseFilter = filter => {
        return (<>
            <Row align="middle">
                <Col className="title" flex="auto"><a onClick={() => onEditClick(filter)}>{filter.item.title}</a> is {filter.filter.option}</Col>
                <Col className="actions"><IconButton onClick={() => onRemoveClick(filter)} icon="trash-alt" /></Col>
            </Row>
        </>)
    }


    return (<>
        <div className='filters_to_apply'>
            {_.isArray(filters) && filters?.map((filter, i)=>{
                return (<div key={i}>
                    {i > 0 && <div style={{ color:"#1191AE", margin:"0 5px"}}>and</div>}
                    <div className='filter_container'>{parseFilter(filter)}</div>
                </div>)
            })}
        </div>
        {/* <DevBlock obj={filters} /> */}
    </>)
}

ExplainSelectedFilters.propTypes = {
    filters: PropTypes.array.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onRemoveClick: PropTypes.func.isRequired,
}

export default ExplainSelectedFilters;