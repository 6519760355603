import React from 'react';
import { addModule, addReducers } from '../connector';
import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT, MODULE } from './constants'
import Sites from './site_list';
// import SiteDetailsWrapper from './site_details'
import SiteDetailsWrapper from './components/SiteDetails'
import AllReports from 'Modules/FormsData/containers/all_reports';

export const modulePermissions = getmodulePermissions('sites-management');


// addReducers({ admins: reducers });
if (modulePermissions["site.view"]) {
  addModule(
    { path: `${ROOT}`, component: Sites, icon:'map-marker-alt', title:"Sites", exact:1, leftNav:true },
  )
  addModule(
    { path: `${ROOT}/site_reports/:site_id`, component: AllReports, title: "All Sites Reports", exact: 1, leftNav: false },
  )
  addModule(
    { path: `${ROOT}/:id`, component: SiteDetailsWrapper, title:"Sites Details", exact:1, leftNav:false },
  )
}
