import React from 'react'
import PropTypes from 'prop-types';
import { FormComponent, FormField, rules } from 'Common/components/Form'
import { Loader } from 'Common/components'
import { Row, Col, Space } from 'antd';
import { connect } from "react-redux";
import { updatePageSettings } from 'Store/ep_admin.actions.js'
import { thread_priority_array, ticket_status_array } from '../../constants';
import debounce from 'lodash/debounce';
// import { sleep } from 'Common/scripts/Functions';


const SearchBar = ({ onUpdate, onSearch, threads_ticket_history_filter, on_updatePageSettings }) => {
    let fields = { ...threads_ticket_history_filter };

    const doSubmitSearch = async(values) => {
        let filter = {}

        let keys = Object.keys(values);
        keys.forEach(k => {
            if(k.indexOf("__")>-1) Object.assign(filter, { [String(k).replaceAll("__", ".")]: values[k]  })
            else Object.assign(filter, { [k]:values[k] })
        });

        await onSearch(filter)
        // await sleep(3000)
        return false;
    }
    
    const submitForm = () => document?.getElementById('ThreadsSearchForm')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    const doSubmitForm = debounce(submitForm, 500);

    return (<Row align="middle" gutter={[5]}>
        <Col>
            <FormComponent onSubmit={doSubmitSearch} id='ThreadsSearchForm' loading={false} debug={false}
                mutators={{
                    onFormChanged: (newValueArray, state, tools) => {
                        let value = newValueArray[0];
                        let field_name = newValueArray[1];

                        if (value && value.length>0) tools.changeValue(state, field_name, () => value);
                        else tools.changeValue(state, field_name, () => undefined);

                        if (field_name=="keyword"){
                            if (value && value.length>0) tools.changeValue(state, "messages__body", () => ({ $regex: `${value}`, $options: "i" }));
                            else tools.changeValue(state, "messages__body", () => undefined);
                        }

                        const difference = JSON.stringify(state.formState.values) !== JSON.stringify(state.lastFormState.values);

                        if (difference) doSubmitForm()
                        // else console.log("nothign changed")
                    },
                }}

                fields={{ status: "open", ...fields }}
                subscription={{} /* No need to subscribe to anything */}
                form_render={({ handleSubmit, values, submitting, form, invalid, dirty, valid }) => {

                    return (<Loader loading={submitting}>
                        <Space>
                            <FormField 
                                onChange={(e, callback) => {
                                    form.mutators.onFormChanged(e.target.value, "keyword")
                                    // callback(e);
                                }}
                                placeholder="Keyword search" disabled={submitting} name="keyword" type="text" size="large" width="200px" compact allowClear />

                            <FormField
                                onChange={(value, callback) => {
                                    form.mutators.onFormChanged(value, "priority")
                                    // callback(value);
                                }}
                                placeholder="Priority" disabled={submitting} name="priority" type="select" data={thread_priority_array} size="large" width="100px" compact allowClear />
                            <FormField
                                onChange={(value, callback) => {
                                    form.mutators.onFormChanged(value, "status")
                                    // callback(value);
                                }}
                                placeholder="Status" disabled={submitting} name="status" type="select" data={ticket_status_array.filter(o=>o._id!="archived")} size="large" width="110px" compact allowClear />

                        </Space>

                    </Loader>)
                }}
            />
        </Col>
    </Row>)


}
SearchBar.propTypes = {
    onUpdate: PropTypes.func,
}

const mapStateToProps = ({ ep_admin }) => {
    return ({ 
        ep_admin,
        // pageSettings: ep_admin?.pageSettings || {},
        threads_ticket_history_filter: ep_admin?.pageSettings?.threads_ticket_history_filter || {},
    });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    on_updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)

