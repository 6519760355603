import React from 'react'
import { Avatar, DeleteButton, Icon, IconButton, UserCardPopover } from 'Common/components';
import { dateToMongo, getTimeDifference, mongoToDate } from 'Common/scripts/Functions';
import { ContentArea } from 'Layout_v1';
import { Col, Row, Space, Tooltip } from 'antd';
import { defaultDateTimeFormat } from 'configs';
import { ticket_status_array } from 'Modules/Threads/constants';
import { connect } from "react-redux";
import { verifyRole } from 'Common/scripts/Security';



const ThreadHeaderComp = ({ data, set_showAllDiscardedMessages, showAllDiscardedMessages, onEditClick, onDeleteClick, user }) => {
    // let created_at = mongoToDate(data.created_at)
    let closed_at = data.closed_at && mongoToDate(data.closed_at);

    return (<ContentArea style={{ margin: "0" }} className={`shadow threads-message threads-message-header ${data.status == "closed" ? "closed" : ""}`}>
        <Row gutter={[10, 0]} className='nowrap'>
            <Col><UserCardPopover userInfo={data?.author}><Avatar src={data?.author?.avatar} alt={data.author.name} size={40} /></UserCardPopover></Col>

            <Col>
                <div style={{ fontSize: "16px" }}>{data.author.name}</div>
                <div className="gray2" style={{ fontSize: "10px" }}>{mongoToDate(data.created_at).format(defaultDateTimeFormat)}</div>
            </Col>
            <Col>
                <div className='hover_menu' style={{ right: "unset" }}>
                    <Space>
                        {(user._id == data.author._id) && <IconButton className="item" icon="pen" onClick={() => onEditClick(data)} />}
                        {(verifyRole('th.tik.del', {user}) && user._id == data.author._id) && <DeleteButton className="item" onConfirm={() => onDeleteClick(data?._id)} />}
                        {verifyRole("th.tik.sho.discarded", {user}) &&
                            <Col><Tooltip placement="top" title={showAllDiscardedMessages ? "Hide discarded message" : "Show discarded message"}><Icon onClick={() => set_showAllDiscardedMessages(!showAllDiscardedMessages)} className="menu_icon a"
                                icon={showAllDiscardedMessages ? "eye" : "eye-slash"} /></Tooltip></Col>
                        }
                    </Space>
                    {/* <Row gutter={[10, 0]}>
                        {(user._id == data.author._id) && <IconButton className="item" icon="pen" onClick={() => onEditClick(data)} />}
                        {(verifyRole('th.tik.del', user) && user._id == data.author._id) && <DeleteButton className="item" onConfirm={() => onDeleteClick(data?._id)} />}
                        {verifyRole("th.tik.sho.discarded", user) &&
                            <Col><Tooltip placement="top" title={showAllDiscardedMessages ? "Hide discarded message" : "Show discarded message"}><Icon onClick={() => set_showAllDiscardedMessages(!showAllDiscardedMessages)} className="menu_icon a"
                                icon={showAllDiscardedMessages ? "eye" : "eye-slash"} /></Tooltip></Col>
                        }
                    </Row> */}
                </div>
            </Col>
            <Col flex="auto" />

            {/* <Col>
                {closed_at && <>
                Ticket time: {getTimeDifference({ before: data.created_at, after: data.closed_at })} | 
                </>}
            </Col> */}

            <Col align="right">
                {closed_at && <div>
                    Ticket time: {getTimeDifference({ before: data.created_at, after: data.closed_at })}
                </div>}
                <div>status: <b>{ticket_status_array.find(o => (o._id == data.status)).title}</b></div>
                <div><span className={`info_tag ${data.priority == "high" ? "red" : ""}`}>{data.priority}</span></div>
            </Col>

        </Row>
        <div style={{ fontSize: "24px" }}>{data.subject}</div>

        {/* <div className='hover_menu'>
            <Row gutter={[10, 0]}>
                {(user._id == data.author._id) && <IconButton className="item" icon="pen" onClick={() => onEditClick(data)} />}
                {(verifyRole('th.tik.del', user) && user._id == data.author._id) && <DeleteButton className="item" onConfirm={() => onDeleteClick(data?._id)} />}

                {verifyRole("th.tik.sho.discarded", user) && 
                    <Col><Tooltip placement="top" title={showAllDiscardedMessages ? "Hide discarded message" : "Show discarded message"}><Icon onClick={() => set_showAllDiscardedMessages(!showAllDiscardedMessages)} className="menu_icon a"
                        icon={showAllDiscardedMessages ? "eye" : "eye-slash"} /></Tooltip></Col>
                }
            </Row>
        </div> */}

    </ContentArea>)
}

const mapStateToProps = ({ ep_admin }) => {
    return ({ user: ep_admin.user });
    // return ({ permissions: ep_admin.user.permissions });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     on_updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export const ThreadHeader = connect(mapStateToProps, null)(ThreadHeaderComp)
