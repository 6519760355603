import React from 'react'
import PropTypes from 'prop-types';
import { DatePicker as _DatePicker } from 'antd';
import { Field } from 'react-final-form'
import { Label } from './FormField'
import _ from 'lodash'
import moment from 'moment';
// import debounce from 'lodash/debounce';


export const DateField = _props => {
    let props = { ..._props };
        delete props.label;
        delete props.returnAs;
        // delete props.formt;

    let fieldProps = {..._props}
        delete fieldProps.format;
        delete fieldProps.onChange;

    return (
        <Field {...fieldProps}>
            {({ input, meta }) => {

                let value = input.value;
                {/* if (moment.isMoment(input.value)) value = moment(input.value) */}
                if (value && _.isString(value)) {
                    value = moment(value).isValid() ? moment(value) : value;
                }

                props.onChange = (_mom, _str) => {
                    if (_props.onChange) _props.onChange(_mom, _str);
                    if (_props.returnAs == 'moment') input.onChange(_mom.startOf('minutes'), _str);
                    else input.onChange(_mom ? _mom.startOf('minutes').format() : _mom);
                }

                let className = `form-field ${props.validate && 'validate'} ${(meta.error && meta.touched) && 'has-errors'} ${!props.compact && "field-margins"} ${props.className}`;

                return (
                    <div className={`${className} date`}>
                        {_props.label && <label>{_props.label}</label>}
                        <div><_DatePicker
                            {...input}
                            {...props}
                            value={value}
                            // onChange={(m, dateString)=>{
                            //                 console.log(dateString);
                            //             }}
                            // format={"YYYY-MM-DD"}
                            // {...props.inputProps}
                        /></div>

                        {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    </div>
                )
            }}
        </Field>
    )

}
DateField.propTypes = {
    name: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
    returnAs: PropTypes.string, // moment, string (default)
}

export default DateField;




export const DatePicker = props => {
    let _props = { ...props }
    
    let style = { width: "100%", ...props.style }
    if (props.width) style = Object.assign(style, { width: props.width })
    
    delete _props.label
    delete _props.style
    
    return (<div className="simple-field">
        {props.label && <Label>{props.label}</Label>}
        <_DatePicker
            style={{...style}}
            {..._props}
            // onChange={onChange}
        />
    </div>)
}