import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Popconfirm, Alert, message, Row, Col, Tooltip, Tabs, Select, Space, Modal } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';
import { mongoToDate } from 'Common/scripts/Functions';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper'
import { updatePageSettings } from 'Store/ep_admin.actions.js'
import { Table, Loader, Icon, IconButton, Button, DeleteButton, Drawer, DevBlock } from 'Common/components';
import { defaultPageSize, uriRoot, defaultDateFormat, defaultDateTimeFormat, formData_guardApplication } from 'configs'
import { reportsTypeArray, FIREWATCH_LOG_DOC, CLIENT_INFO_FORM, GUARD_APPLICATION_FORM, SECURITY_GUARD_INTERVIEW } from '../constants';

import FormDataSearchBar from './FormDataSearchBar'
import { verifyRole } from 'Common/scripts/Security';
import ObservationReport from '../Forms/ObservationReport';
// import GuardEvaluationReport from '../Forms/GuardEvaluationReport';
import ReportPreview from 'Modules/FormsData/Forms/DataPreview'
import BulkStatusForm from './BulkStatusForm';

const GUARD_INTERVIEW_LIST_DATA = loader('src/Graphql/FormsData/guardInterviewQuery.graphql');
const GUARD_INFO_LIST_DATA = loader('src/Graphql/FormsData/clientInfoDataQuery.graphql');
const GUARD_APP_LIST_DATA = loader('src/Graphql/FormsData/guardApplicationQuery.graphql');
const FORM_LIST_DATA = loader('src/Graphql/FormsData/formsDataQuery.graphql');
const UPDATE_FORM_DATA_STATUS = loader('src/Graphql/FormsData/editFormsDataStatus.graphql');
const UPDATE_FORM_DATA_ARCHIVED = loader('src/Graphql/FormsData/editFormsArchive.graphql');
const DELETE = loader('src/Graphql/FormsData/deleteFormsData.graphql');



class FormsDataListComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null,
            error: null,
            kw: null,
            pagination: { current: 1, pageSize: defaultPageSize },
            // pagination: { current: 1, pageSize: 1 },
            showDrawer: false, initialData: null,
            filter: { isArchived: {$ne:"yes"} },
            showReportCreateForm: false,
            showDetails: false,
            selectedRowKeys: null,
            showBulkUpdate: false,
        };
        this.searchList = this.searchList.bind(this)
        this.delete = this.delete.bind(this)
        this.onStatusDDChanged = this.onStatusDDChanged.bind(this)
        this.onSuccess = this.onSuccess.bind(this)
    }

    fetchData = async (args = {}) => {
        let filter = { ...this.state.filter }
        if (args.filter) Object.assign(filter, args.filter);
        Object.assign(filter, { isDuplicate:{$ne:'yes'} })

        const variables = {
            first: args.pageSize || this.state.pagination.pageSize, // number of rec to fetch
            after: (args.pageSize || this.state.pagination.pageSize) * ((args.current || this.state.pagination.current) - 1),
            filter: JSON.stringify({ ...filter, ...this.props.permanent_filter }) // , status: args.status || { $ne:"archived" }
        }

        var queryTo = {}
        if (this.props.ref_key == CLIENT_INFO_FORM) queryTo = { query: GUARD_INFO_LIST_DATA, name:"clientInfoDataQuery" }
        else if (this.props.ref_key == GUARD_APPLICATION_FORM) queryTo = { query: GUARD_APP_LIST_DATA, name:"guardApplicationQuery" }
        else if (this.props.ref_key == SECURITY_GUARD_INTERVIEW) queryTo = { query: GUARD_INTERVIEW_LIST_DATA, name:"guardInterviewQuery" }
        // else if (this.props.ref_key == FIREWATCH_LOG_DOC) queryTo = { query: FORM_LIST_DATA, name:"formsDataQuery" }
        else if (reportsTypeArray.find(o => o.key == this.props.ref_key)?.key == 'firewatch_log_doc') queryTo = { query: FORM_LIST_DATA, name:"formsDataQuery" }
        else if (reportsTypeArray.find(o => o.key == this.props.ref_key)?.key == 'observation_report') queryTo = { query: FORM_LIST_DATA, name:"formsDataQuery" }
        else if (reportsTypeArray.find(o => o.key == this.props.ref_key)?.key == 'guard_evaluation') queryTo = { query: FORM_LIST_DATA, name:"formsDataQuery" }
        else {
            alert("Invalid ref_key");
            this.setState({ data:null })
            return false;
        }

        this.setState({ loading: true })



        return this.props.client.query({ query: queryTo.query, variables }).then(r => {
            let response = r.data[queryTo.name];

            if (!response) return this.setState({ loading: false, error: "Invalid response from server" });
            if (response.error) return this.setState({ loading: false, error: response.message });

            this.setState({
                loading: false,
                data: response,
                pagination: {
                    ...this.state.pagination,
                    current: args.current || 1,
                    pageSize: args.pageSize || this.state.pagination.pageSize,
                    total: response.totalCount
                }
            });

            return response;

        }).catch(err => {
            console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
            this.setState({ loading: false, error: "Unable to complete your request at the moment!" });
            return false;
        })
    }

    componentDidUpdate(prevProps, prevState){
        // console.log("componentDidUpdate: > this.props :", this.props)
        if (JSON.stringify(prevProps.permanent_filter) !== JSON.stringify(this.props.permanent_filter))
            this.fetchData()
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ current: pagination.current, pageSize: pagination.pageSize });
    };
    onSuccess(){
        this.setState({ showReportCreateForm: false }, () => this.fetchData({ ...this.state.pagination }))
    }

    searchList(value) {
        this.fetchData({
            // filter: { $or: [{ "personalinfo.f_name": value }, { "personalinfo.sin_no": value}]}
            filter: { search: { keywords: value } }
        });
    }

    async delete(_id) {
        this.setState({ loading: true, error: null });

        let resutls = await this.props.client.mutate({ mutation: DELETE, variables: { id: _id } }).then(r => (r?.data?.deleteFormsData))
        .catch(err => {
            console.log(__error("ERROR: "), err);
            return { error: { message:"Unable to delete at the moment!"}};
        })

        if (!resutls || resutls.error){
            this.setState({ loading: false, error: (resutls && resutls?.error?.message) || "Unable to delete at the moment!" });
            return;
        }

        message.success("Record successfully deleted!")
        this.fetchData(this.state.pagination)
        return resutls;
    }

    async archiveThis(rec) {
        this.setState({ loading: true });

        let resutls = await this.props.client.mutate({
            mutation: UPDATE_FORM_DATA_ARCHIVED, variables: { _id: rec._id, doArchive: rec.isArchived=="yes" ? "no" : "yes" }
        }).then(r => (r?.data?.editFormsArchive))
        .catch(err => {
            console.log(__error("ERROR: "), err);
            return { error: { message:"Unable to archive at the moment!" } }
        })

        if (!resutls || resutls.error){
            this.setState({ loading: false, error: (resutls && resutls.error.message) || "Invalid response!" });
            return false;
        }

        message.success("Moved to acrive!")

        this.fetchData({
            ...this.state.pagination,
        })
    }

    toggeleDrawer(initialData = null) {
        this.setState({ showDrawer: !this.state.showDrawer, initialData })
    }

    columns = () => {
        var colArray = []

        if (this.props.ref_key == CLIENT_INFO_FORM){
            colArray = [
                { title: 'ID', dataIndex: '_id', width: "100px" },
                {
                    title: 'Title', dataIndex: 'title', render: (___, rec) => {
                        return (<Link to={`${uriRoot}/FormsData/view/${rec._id}`}>{rec.title}</Link>)
                    }
                },
                { title: <>Created at <div style={{ fontSize: "10px" }}>{defaultDateFormat}</div></>, dataIndex: 'created_at', width: '130px', render: (___, rec) => mongoToDate(rec.created_at).format(defaultDateFormat) },
                {
                    title: '', width: "50px", align: 'center', render: (___, rec) => {
                        return <div>
                            {/* <IconButton onClick={() => this.toggeleDrawer(rec)} icon={{ icon: 'archive' }}></IconButton> */}
                            <Popconfirm onConfirm={() => this.delete(rec?._id)} title="Are you sure？">
                                <IconButton icon={{ icon: 'trash-alt' }}></IconButton>
                            </Popconfirm>
                        </div>
                    }
                },
            ]

        }

        if (this.props.ref_key == GUARD_APPLICATION_FORM){
            colArray = [
                { title: 'ID', dataIndex: '_id', width: "100px" },
                {
                    title: 'Title', dataIndex: 'title', render: (___, rec) => {
                        return (<Row gutter={[12]}>
                            <Col><Link to={`${uriRoot}/FormsData/view/${rec._id}`}>{rec.title}</Link></Col>
                            <Col>{(rec.blacklist && rec.blacklist.length > 0) &&
                                <Tooltip title={"Applicant is black listed"}>
                                    <span><Icon icon="user-slash" color="red" /></span>
                                </Tooltip>
                            }</Col>
                        </Row>)
                    }
                },
                { title: 'Attachments', dataIndex: 'attachments', width: '100px', align: "center", render: (___, rec) => rec?.attachments?.length || null },
                { title: 'Ex Employee', dataIndex: 'ex_employee', width: '100px', align: "center", render: (txt, rec) => {
                    return (!rec?.ex_employee?._id) ? null : <Link to={`${uriRoot}/guard/${rec.ex_employee._id}`} target="_blank">{rec.ex_employee.name}</Link>;
                } },
                { title: 'Duplicates', dataIndex: 'duplicates', width: '100px', align: "center", render: (___, rec) => rec.dupCount || '' },
                // { title: 'Blacklist', dataIndex: '__', width: '150px', render: (___, rec)=>{
                //     if (rec.blacklist && rec.blacklist.length > 0){
                //         return rec.blacklist.map((item, i)=>{
                //             return <div key={i}>{item.value}</div>
                //         });
                //     }
                //     return null
                // } },
                {
                    title: 'Security License', dataIndex: '__', width: '150px', render: (___, rec) => {
                        return rec.fields.find(o => o.name == 'license_no')?.value
                    }
                },
                {
                    title: 'SIN#', dataIndex: '__', width: '150px', render: (___, rec) => {
                        return rec.fields.find(o => o.name == 'sin_no')?.value
                    }
                },
                { title: 'Status', dataIndex: 'status', width: '100px', align: "center" },
                { title: <>Created at <div style={{ fontSize: "10px" }}>{defaultDateFormat}</div></>, dataIndex: 'created_at', width: '130px', render: (___, rec) => mongoToDate(rec.created_at).format(defaultDateFormat) },
                // {
                //     title: '', width: "50px", align: 'center', render: (___, rec) => {
                //         return <div>
                //             {/* <IconButton onClick={() => this.toggeleDrawer(rec)} icon={{ icon: 'pen' }}></IconButton> */}
                //             <Popconfirm onConfirm={() => this.delete(rec?._id)} title="Are you sure？">
                //                 <IconButton icon={{ icon: 'trash-alt' }}></IconButton>
                //             </Popconfirm>
                //         </div>
                //     }
                // },
            ]
        }

        if (this.props.ref_key == SECURITY_GUARD_INTERVIEW){
            colArray = [
                { title: 'ID', dataIndex: '_id', width: "100px" },
                {
                    title: 'Title', dataIndex: 'title', render: (___, rec) => {
                        return (<Link to={`${uriRoot}/FormsData/view/${rec._id}`}>{rec.title}</Link>)
                    }
                },
                { title: <>Status</>, dataIndex: 'status', width: '130px' },
                { title: <>Created at <div style={{ fontSize: "10px" }}>{defaultDateFormat}</div></>, dataIndex: 'created_at', width: '130px', render: (___, rec) => mongoToDate(rec.created_at).format(defaultDateFormat) },
                {
                    title: '', width: "50px", align: 'center', render: (___, rec) => {
                        return <div>
                            {/* <IconButton onClick={() => this.toggeleDrawer(rec)} icon={{ icon: 'pen' }}></IconButton> */}
                            <Popconfirm onConfirm={() => this.delete(rec?._id)} title="Are you sure？">
                                <IconButton icon={{ icon: 'trash-alt' }}></IconButton>
                            </Popconfirm>
                        </div>
                    }
                },
            ]

        }

        if (this.props.ref_key == 'firewatch_log_doc') {
            colArray = [
                { title: 'ID', dataIndex: '_id', width: "100px" },
                {
                    title: 'Title', dataIndex: 'title', render: (___, rec) => {
                        return (<>
                            <span><Link to={`${uriRoot}/FormsData/view/${rec._id}`}>{rec.title}</Link></span>
                            <span className='hidden-menu'>
                                {verifyRole("archive-report", this.props.ep_admin.user) && <IconButton className="item" onClick={() => this.archiveThis(rec)} 
                                    color={rec.isArchived == "yes" ? "green" : "red"}
                                    icon={{ icon: 'archive' }} />}
                                {verifyRole("delete-report", this.props.ep_admin.user) && <DeleteButton className="item" onClick={() => this.delete(rec?._id)} />}
                            </span>
                        </>)
                    }
                },
                { title: <>Created at <div style={{ fontSize: "10px" }}>{defaultDateFormat}</div></>, dataIndex: 'created_at', width: '150px', render: (___, rec) => mongoToDate(rec.created_at).format(defaultDateTimeFormat) },
                // {
                //     title: '', width: "50px", align: 'center', render: (___, rec) => {
                //         return <div>
                //             {rec.ref_key == "firewatch_log_doc" && <>
                //                 {verifyRole("archive-report", this.props.ep_admin.user) && <IconButton onClick={() => this.toggeleDrawer(rec)} icon={{ icon: 'archive' }} />}
                //                 {verifyRole("delete-report", this.props.ep_admin.user) && <Popconfirm onConfirm={() => this.delete(rec?._id)} title="Are you sure？">
                //                     <IconButton icon={{ icon: 'trash-alt' }}></IconButton>
                //                 </Popconfirm>}
                //             </>}
                //         </div>
                //     }
                // },
            ]

        }

        if (this.props.ref_key == 'observation_report') {
            colArray = [
                { title: 'ID', dataIndex: '_id', width: "100px" },
                {
                    title: 'Title', dataIndex: 'title', render: (___, rec) => {
                        return (<>
                            <div onClick={() => this.setState({ showDetails: rec._id })} className='a'>{rec.title}</div>
                            {/* <Link to={`${uriRoot}/FormsData/view/${rec._id}`}>{rec.title}</Link> */}
                            <span className='hidden-menu'>
                                {rec.ref_key == "observation_report" && <>
                                    {verifyRole("archive-report", this.props.ep_admin.user) && <IconButton className="item" onClick={() => this.archiveThis(rec)} 
                                        color={rec.isArchived == "yes" ? "green" : "red"}
                                        icon={{ icon: 'archive' }} />}
                                    {verifyRole("delete-report", this.props.ep_admin.user) && <DeleteButton className="item" onClick={() => this.delete(rec?._id)} />}
                                </>}
                            </span>
                        </>)
                    }
                },
                { title: <>Site</>, dataIndex: 'site', render:(__, rec)=>(rec?.site?.name) },
                { title: <>Guard</>, dataIndex: 'guard', render: (__, rec) => (rec?.guard?.name) },
                { title: <>Author</>, dataIndex: 'author', render: (__, rec) => (rec?.author?.name) },
                { title: <>Created at <div style={{ fontSize: "10px" }}>{defaultDateFormat}</div></>, dataIndex: 'created_at', width: '150px', render: (___, rec) => mongoToDate(rec.created_at).format(defaultDateTimeFormat) },
            ]
        }


        return colArray;

    }

    onStatusDDChanged(newStatus){
        let filter = { ...this.state.filter }

        if (newStatus && newStatus=="archived") Object.assign(filter, { isArchived: { $eq: "yes" } })
        else Object.assign(filter, { isArchived: { $ne: "yes" } })

        this.setState({ filter }, ()=>{
            this.fetchData({})
        })
    }


    render() {
        const { loading, data, error } = this.state;
        const { pagination, showDrawer, initialData, showDetails, showBulkUpdate } = this.state;

        if (error) return <Alert message="Error" description={error} type="error" showIcon />
        var outgoing_url = `${process.env.REACT_APP_HOST_URL}/inputForm/${this.props.ref_key}`

        return (<>
            {/* <p>{this.props.ref_key}</p> */}
            <Table loading={loading} size="small"
                title={() => {
                    return (<div>
                        <Row gutter={[10]}>
                            <Col>
                                <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                                    <Space>
                                        {this.props.title}
                                        {this.props.ref_key ==='firewatch_log_doc' && 
                                            <a type="link" href={outgoing_url} target="_blank" style={{ fontSize: "12px" }}>Open Form <Icon icon="external-link-alt" /></a>
                                        }
                                        {((this.props.ref_key !== 'firewatch_log_doc' && this.props.ref_key !== 'guard_evaluation') && reportsTypeArray.find(o => o.key == this.props.ref_key)) && <Button onClick={()=>this.setState({ showReportCreateForm:true })} size="small">Create Report</Button>}
                                        {(this.props.ref_key == 'guard_evaluation') && <a type="link" href={outgoing_url} target="_blank" style={{ fontSize: "12px" }}>Open Form <Icon icon="external-link-alt" /></a>}
                                    </Space>
                                </div>
                                <div style={{ fontSize: "12px" }}>Total {((data && data.totalCount) || '0')} record(s)</div>
                            </Col>
                            <Col flex="auto" />
                            <Col>
                                <Space>
                                    <Select defaultValue="" onChange={this.onStatusDDChanged} options={[
                                        { value: '', label: 'Un-Archived' },
                                        { value: 'archived', label: 'Archived' },
                                    ]}></Select>
                                    <FormDataSearchBar fetchData={(args) => this.fetchData(args)} />
                                </Space>
                            </Col>
                        </Row>
                    </div>)
                }}
                columns={this.columns()}
                dataSource={data ? data.edges : null}
                pagination={pagination}
                onChange={this.handleTableChange}
                rowSelection={{
                    type: "checkbox",
                    onChange: (selectedRowKeys, selectedRows) => {
                        this.setState({ selectedRowKeys })
                        // console.log("selectedRowKeys: ", selectedRowKeys)
                        // console.log("selectedRows: ", selectedRows)
                        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    },
                    // getCheckboxProps: (record) => ({
                    //     disabled: record.name === 'Disabled User',
                    //     // Column configuration not to be checked
                    //     name: record.name,
                    // }),
                }}
                // rowClassName={(record => record.status == 'new' ? 'hilighted-table-row' : "")}
                // onChange={this.handleTableChange}

                footer={()=>{
                    if (!this.state.selectedRowKeys || this.state.selectedRowKeys.length<1) return null;

                    return (<Row gutter={[10]} align="middle">
                        <Col>{this.state.selectedRowKeys.length} records selected</Col>
                        <Col><Button onClick={() => this.setState({ showBulkUpdate:'status-update' })}>Update Status</Button></Col>
                    </Row>)
                }}
            />

            <Modal title="Bulk update" visible={showBulkUpdate != false} onCancel={() => this.setState({ showBulkUpdate:false })} footer={false} destroyOnClose>
                {showBulkUpdate && <>
                    {showBulkUpdate == 'status-update' && <BulkStatusForm 
                        ids={this.state.selectedRowKeys} 
                        onUpdate={() => this.setState({ showBulkUpdate: false })}
                        statusArray={formData_guardApplication}
                        ref_key={this.props.note_ref_key} //{this.props.ref_key}
                    />}
                </>}
            </Modal>
            

            <Drawer visible={showDetails !== false} placement="top" height="100%" closable={true} onClose={() => this.setState({ showDetails:false })} destroyOnClose maskClosable={false} footer={false}
                bodyStyle={{ padding:"0px" }}
                title={"Reports Details"}
            >
                {showDetails && <ReportPreview inline={this.props.ref_key == 'observation_report'} match={{ params: { id: showDetails } } } />}
            </Drawer>


            <Drawer visible={this.state.showReportCreateForm!==false} placement="top" height="100%" closable={false} destroyOnClose maskClosable={false} footer={false}
                bodyStyle={{ padding:"0px" }}
                title={<Row>
                    <Col flex="auto">Create {this?.props?.ref_key?.replace("_", " ")}</Col>
                    <Col><Button onClick={() => this.setState({ showReportCreateForm: false })}>Close</Button></Col>
                </Row>}
            >
                {this.state.showReportCreateForm && <>
                    {this.props.ref_key == 'observation_report' && <ObservationReport onSuccess={this.onSuccess} onClose={() => this.setState({ showReportCreateForm: false })} />}
                    {/* {this.props.ref_key == 'guard_evaluation' && <GuardEvaluationReport onSuccess={this.onSuccess} onClose={() => this.setState({ showReportCreateForm: false })} />} */}
                    
                </>}
            </Drawer>
        </>)
    }
}
const mapStateToProps = ({ ep_admin }) => {
    return ({ ep_admin });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
})

export const FormsDataList = withApollo(FormsDataListComp)
export default connect(mapStateToProps, mapDispatchToProps)(FormsDataList)
