import React from 'react'
import PropTypes from 'prop-types';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Loader, Button, UsersSearch, ChannelSearch, Icon, IconButton } from 'Common/components';
import { loader } from 'graphql.macro';
import { Drawer, Space, Alert, message, Row, Col, Modal, Upload } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';
import { __error } from 'Common/scripts/consoleHelper';
import { thread_priority_array, ticket_status_array } from '../../constants';
import axios from 'axios'
import { useHistory } from 'react-router-dom';

const CREATE_TODO = loader('src/Graphql/Todos/addTodo.graphql');
const UPDATE_TODO = loader('src/Graphql/Todos/updateTodo.graphql');
const ATTACHMENT_LIMIT = 4;

const defaultValues = { }


const TodoForm = ({ onSuccess, onCancel, client, initialValues }) => {

    async function updateRecords(input){
        let _input = {...input}
        delete _input.message;

        return client.mutate({ mutation: UPDATE_TODO, variables: { input: _input } }).then(r => (r?.data?.updateTodo))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })
    }
    
    async function addRecords(input){
        return client.mutate({ mutation: CREATE_TODO, variables: { input } }).then(r => (r?.data?.addTodo))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })
    }
    
    const onTodoSubmit = async (values) => {
        const input = {
            title: values.title,
            status: values.status || "open",
            body: values.body,
            shared_with: values.shared_with && values?.shared_with?.map(o => ({ _id: o._id, name: o.name })),
            // attachments: [AttachmentSchema_Input]
        }
        if (values.shared_with) Object.assign(input, { shared_with: values.shared_with.map(o=>({ _id:o._id, name:o.name })) })
        if (initialValues && initialValues._id) Object.assign(input, { _id: Number(initialValues._id) })

        let results;
        if (input._id) results = await updateRecords(input)
        else results = await addRecords(input);

        if (results.error){
            message.error(results.error.message);
            return false;
        }

        if (values.attachments){
            var upload_results = await handleUpload({ fileList: values.attachments, _id: results._id }).catch(err => {
                console.log(__error("ERROR: ", err));
                return { error: { message: 'Unable to complete upload request' } }
            });
            if (upload_results && upload_results.error) {
                message.error(upload_results.error.message);
                // return upload_results;
            }
        }

        onSuccess(results);

        return "reset"
    }

    const handleUpload = async ({ fileList, _id }) => {
        if (!fileList || fileList.length < 1) return; // { error: { message: "No file to upload!!" } }
        if (fileList.length > ATTACHMENT_LIMIT) return { error: { message: `You can upload max of ${ATTACHMENT_LIMIT} files only!` } }
        if (!_id) return { error: { message: `Missing ticket ID for upload` } }

        const hide = message.loading('Uploading..', 0);

        const formData = new FormData();
        formData.append('action', "ticket.attachments");
        formData.append('ticket_id', _id);
        fileList.forEach(file => {
            formData.append('files[]', file);
        });

        const results = await axios.post(process.env.REACT_APP_API_URL + '/thread_upload/upload', formData)
            .catch((error) => {
                console.log(__error("Error: "), JSON.stringify(error, 0, 2));
                return { error: { message: "Upload request fail!" } };
            });

        hide();
        return results;
    };



    return (<>
        <FormComponent onSubmit={onTodoSubmit} id='TodoForm' fields={{ ...defaultValues, ...initialValues }} debug={true} 
            mutators={{
                selectUser: (newValueArray, state, tools) => {
                    let node = newValueArray[0];

                    let _dataArray = state.formState.values.shared_with || [];
                    _dataArray.push({ _id: node._id, name: node.name })

                    tools.changeValue(state, 'shared_with', () => _dataArray);
                },
                de_selectUser: (newValueArray, state, tools) => {
                    let _dataArray = state.formState.values.shared_with || [];
                    let _ids = state.formState.values.shared_with_ids || [];

                    _dataArray = _dataArray.filter(o => (_ids.includes(o._id)))

                    tools.changeValue(state, 'shared_with', () => _dataArray)
                },



                selectFiles: (newValueArray, state, tools) => {
                    let file = newValueArray[0];
                    let action = newValueArray[1];
                    let attachments = state.formState.values.attachments || []

                    if (action == 'add') {
                        if (attachments.length >= ATTACHMENT_LIMIT) return;
                        attachments.push(file);
                    }
                    if (action == 'remove') attachments = attachments.filter(o => o.uid != file.uid);

                    tools.changeValue(state, 'attachments', () => attachments);
                },
            }}
            form_render={formProps => {
                const { values, submitting, form, invalid, dirty, valid } = formProps;

                return (<>
                    {values._id && <Row>
                        <Col flex="auto" />
                        <Col><FormField label="Status" name="status" width={"100px"} data={ticket_status_array} type="select" validate={rules.required} /></Col>
                    </Row>}

                    <FormField label="Title" name="title" type="text" validate={rules.required} />

                    <UsersSearch mode="multiple" label="Shared with" name='shared_with_ids'
                        filter={{ status: "enabled" }}
                        preload disableSearch
                        // disabled={!values.channel || values.channel.length<1}
                        onSelect={(___, opt, raw) => form.mutators.selectUser(raw)}
                        onDeselect={(txt, node) => form.mutators.de_selectUser(node)}
                        defaultValues={values.shared_with ? values.shared_with.map(o => ({ ...o, title: o.name })) : []}
                        // validate={rules.required}
                    />

                    {!values._id && <FormField label="Todo Item" name="body" type="textarea" validate={rules.required} />}
                    
                    {/* <div style={{ marginBottom:"10px" }}>
                        <Upload
                            fileList={values?.attachments}
                            disabled={values?.attachments?.length >= ATTACHMENT_LIMIT}
                            onRemove={(file) => {
                                form.mutators.selectFiles(file, "remove");
                            }}
                            beforeUpload={(file) => {
                                form.mutators.selectFiles(file, "add");
                                return false;
                            }}
                            accept=".jpg,.jpeg,.png,.pdf,.doc.,docx,.xls,.xlsx,.csv"
                            multiple={true}
                            maxCount={ATTACHMENT_LIMIT}
                            showUploadList={false}
                        >
                            <span className='a' style={{ fontSize: "12px", padding: "2px 5px" }}><Icon icon="paperclip" /> add attachments ({values?.attachments?.length || 0}/{ATTACHMENT_LIMIT})</span>
                        </Upload>

                        {values?.attachments?.map((item, i) => {
                            return <Row key={i} className='upload-item-list' align="middle">
                                <Col flex="auto">{item.name}</Col>
                                <Col style={{ marginLeft: "10px" }}><IconButton onClick={() => form.mutators.selectFiles(item, "remove")} icon="trash-alt" /></Col>
                            </Row>
                        })}

                    </div> */}

                    
                    <Row gutter={[10]}>
                        <Col flex="auto" />
                        <Col><Button onClick={onCancel} color="white">Cancel</Button></Col>
                        <Col><Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >{values._id ? "Update" : "Create"} Todo</Button></Col>
                    </Row>                    

                </>)
            }}
        />

    </>)
}
const WithApollo = withApollo(TodoForm)

export default function TodoFormModal({ initialValues, visible, onCancel, onSuccess }) {
    let history = useHistory();

    const handel_onSuccess = (values) => {
        if (onSuccess) return onSuccess()
        history.push(`/admin/threads/todo/${values._id}`);
        onCancel()

        // else onCancel()
    }

    let members_ids = initialValues && initialValues?.members?.map(o=>(o._id));

    return (<>
        <Modal title="Todo" visible={visible} width="700px" onCancel={onCancel} footer={false} closable centered destroyOnClose>
            {visible && <WithApollo onCancel={onCancel} initialValues={{ ...initialValues, members_ids }} onSuccess={handel_onSuccess} />}
        </Modal>
    </>)
}
TodoFormModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    initialValues: PropTypes.object,
}

