import React, { useState } from 'react'
import moment from 'moment';
import { Button, DataRow, Heading, IconButton, ClientSearch } from 'Common/components';
import { defaultDateFormat, site_types } from 'configs';
import { Alert, Col, Row, Space, message } from 'antd';
import { verifyRole } from 'Common/scripts/Security';
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { dateToMongo, removeSpecialChar } from 'Common/scripts/Functions';
import SiteServiceManager from './SiteServiceManager'

const SiteServicesDetails = ({ data, onUpdate, client }) => {
    const [edit, set_edit] = useState(false);

    return (<>
        <Heading>Services Allowed 
            {verifyRole('site.manage-services') && <IconButton onClick={() => set_edit(!edit)} icon={edit ? "times" : "pen"} />}
        </Heading>

        {!edit && <>
            <Row>
                {data?.services?.map((service, i) => (<Col className='service-tag' key={i}>{service?.service_type?.title}</Col>))}
            </Row>
        </>}

        {verifyRole('site.manage-services') &&
            <SiteServiceManager show={edit} site={data}
                onUpdate={(val) => {
                    console.log("onUpdate()", val);
                    onUpdate(val)
                    set_edit(false);
                }}
            onClose={() => set_edit(false)}
            />
        }

    </>)
}

export default withApollo(SiteServicesDetails);