import React, { useState } from 'react'
import { Row, Col } from 'antd';
import { Button, Drawer } from 'Common/components';
import { ContentArea } from "Layout_v1";
import { mongoToDate } from 'Common/scripts/Functions';
import Notes from 'Modules/Notes';
import { defaultDateTimeFormat } from 'configs';
import { TheField } from '../Components'
import { FORM_KEY } from '.';
import Client_form from 'Modules/Clients/Client_form';
import { __yellow } from 'Common/scripts/consoleHelper';
import { useHistory } from 'react-router-dom';




const FormsDataView = ({ data }) => {
    const [showClientForm, set_showClientForm] = useState(false);
    let history = useHistory();

    if (!data?._id) return null;

    function getField(_fieldname){
        return data.fields.find(o => o.name == _fieldname);
    }

    const onAdd = (values) => {
        console.log(__yellow("onAdd()"))
        history.push(`/admin/clients/${values._id}`);
    }

    const onShowFormClick = () => {
        let initialValues = { }

        data.fields.forEach(field => {
            Object.assign(initialValues, { [field.name]: field.value })
        });

        set_showClientForm(initialValues)
    }

    return (<>
        <ContentArea transparent style={{ margin: "0" }}><Row style={{ flexWrap: "nowrap" }} gutter={[15]}>
            <Col flex="auto" className='form-data'><ContentArea style={{ margin: "0" }}>
                <Row>
                    <Col flex="auto"><Button onClick={() => onShowFormClick()} size="small">Create client account from this application</Button></Col>
                    <Col><span style={{ color: '#999', fontSize: "12px" }}>{mongoToDate(data.created_at).format(defaultDateTimeFormat)}</span></Col>
                </Row>

                <p style={{ width: "100%", fontSize: "30px", textAlign: "center", textTransform: "capitalize", color: "#2F2F2F", fontWeight: "bold" }}>{data.title}</p>
                {/* <div style={{ height:"30px" }} /> */}

                <h4 style={{ marginTop:"20px", marginBottom:"0px" }}>Client Information</h4>
                <TheField {...getField('client_name')} size={['25%']} horizontal />
                <TheField {...getField('client_compnay_name')} size={['25%']} horizontal />
                <TheField {...getField('client_phone')} size={['25%']} horizontal />
                <TheField {...getField('client_email')} size={['25%']} horizontal />

                <h4 style={{ marginTop: "20px", marginBottom: "0px" }}>Client Contact Person</h4>
                <TheField {...getField('contact_person')} size={['25%']} horizontal />
                <TheField {...getField('contact_email')} size={['25%']} horizontal />
                <TheField {...getField('contact_pri_name')} size={['25%']} horizontal />
                <TheField {...getField('contact_alt_name')} size={['25%']} horizontal />
                <TheField {...getField('contact_preff_method')} size={['25%']} horizontal />

                <h4 style={{ marginTop: "20px", marginBottom: "0px" }}>Site Address</h4>
                <TheField {...getField('site_address_street')} size={['25%']} horizontal />
                <TheField {...getField('site_address_unit')} size={['25%']} horizontal />
                <TheField {...getField('site_address_city')} size={['25%']} horizontal />
                <TheField {...getField('site_address_state')} size={['25%']} horizontal />
                <TheField {...getField('site_address_zip')} size={['25%']} horizontal />
                <TheField {...getField('site_address_country')} size={['25%']} horizontal />

                <h4 style={{ marginTop: "20px", marginBottom: "0px" }}>Billing Address (if different from above)</h4>
                <TheField {...getField('bil_add_street')} size={['25%']} horizontal />
                <TheField {...getField('bil_add_city')} size={['25%']} horizontal />
                <TheField {...getField('bil_add_state')} size={['25%']} horizontal />
                <TheField {...getField('bil_add_zip')} size={['25%']} horizontal />
                <TheField {...getField('bil_add_country')} size={['25%']} horizontal />

                <h4 style={{ marginTop: "20px", marginBottom: "0px" }}>Emergency Contact Information</h4>
                <TheField {...getField('emergency_name')} size={['25%']} horizontal />
                <TheField {...getField('emergency_phone')} size={['25%']} horizontal />
                <TheField {...getField('emergency_email')} size={['25%']} horizontal />

                <h4 style={{ marginTop: "20px", marginBottom: "0px" }}>Security Requirements</h4>
                <TheField {...getField('sec_req_type')} size={['25%']} horizontal />
                <TheField {...getField('sec_req_num_guards')} size={['25%']} horizontal />
                <TheField {...getField('sec_req_shift_schedule')} size={['25%']} horizontal />
                <TheField {...getField('sec_req_location')} size={['25%']} horizontal />
                <TheField {...getField('sec_req_purpose')} size={['25%']} horizontal />
                <TheField {...getField('sec_req_instructions')} size={['25%']} horizontal />

                <h4 style={{ marginTop: "20px", marginBottom: "0px" }}>Additional Information</h4>
                <TheField {...getField('have_washroom')} size={['25%']} horizontal />
                <TheField {...getField('have_lunchroom')} size={['25%']} horizontal />
                <TheField {...getField('how_did_you_hear')} size={['25%']} horizontal />
                <TheField {...getField('sec_compnay_worked_with')} size={['50%']} horizontal />
                <TheField {...getField('sec_compnay_details')} size={['25%']} horizontal />

                <h4 style={{ marginTop: "20px", marginBottom: "0px" }}>Additional Comments or Questions</h4>
                <TheField {...getField('additional_comments')} size={['25%']} horizontal />


                <hr style={{ margin: "50px" }} />
                {data.signature && <div align="center" style={{ padding:"40px" }}><img src={data.signature} /></div>}

            </ContentArea></Col>
            <Col flex="400px"><ContentArea style={{ margin: "0" }}><Notes id={data._id} formkey={FORM_KEY} /></ContentArea></Col>
        </Row></ContentArea>


        <Client_form onAdd={onAdd}
            showDrawer={showClientForm !== false} initialValues={showClientForm} onClose={() => set_showClientForm(false)} />

    </>)



}
export default FormsDataView;