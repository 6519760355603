import React from 'react'
import PropTypes from 'prop-types';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Loader, Button, UsersSearch } from 'Common/components';
import { loader } from 'graphql.macro';
import { Drawer, Space, Alert, message, Row, Col, Modal } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';
import { __error } from 'Common/scripts/consoleHelper';
import { channel_status_array } from '../../constants';

const CREATE_CHANNEL = loader('src/Graphql/threads/createThreadsChannel.graphql');
const UPDATE_CHANNEL = loader('src/Graphql/threads/editThreadsChannel.graphql');

const defaultValues = {
    status: channel_status_array[0]._id
}


const ChannelForm = ({ initialValues, onSuccess, onCancel, client }) => {
    
    const updateChannel = (input) => {
        return client.mutate({ mutation: UPDATE_CHANNEL, variables: { input } }).then(r => (r?.data?.editThreadsChannel))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })
    }

    const addChannel = async (input) => {
        return client.mutate({ mutation: CREATE_CHANNEL, variables: { input } }).then(r => (r?.data?.createThreadsChannel))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })
    }

    const onChannelSubmit = async (values) => {

        const input = {
            title: values.title,
            status: values.status,
        }
        if (values.members) Object.assign(input, { members: values.members.map(o=>({ _id:o._id, name:o.name })) })

        let results;
        if (initialValues && initialValues._id) results = updateChannel({ ...input, _id: Number(initialValues._id) });
        else results = addChannel(input);

        // let results = await client.mutate({ mutation: CREATE_CHANNEL, variables: { input } }).then(r => (r?.data?.createThreadsChannel))
        //     .catch(err => {
        //         console.log(__error("ERROR: "), err);
        //         return { error: { message:"Failed to process the request!" } }
        //     })

        if (results.error){
            message.error(results.error.message);
            return false;
        }

        onSuccess();
        return "reset"
    }


    return (<>
        <FormComponent onSubmit={onChannelSubmit} id='ChannelForm' fields={{ ...defaultValues, ...initialValues }} debug={true}
            mutators={{
                selectUser: (newValueArray, state, tools) => {
                    let node = newValueArray[0];

                    let _dataArray = state.formState.values.members || [];
                    _dataArray.push({ _id: node._id, name: node.name })

                    tools.changeValue(state, 'members', () => _dataArray);
                },
                de_selectUser: (newValueArray, state, tools) => {
                    let _dataArray = state.formState.values.members || [];
                    let _ids = state.formState.values.members_ids || [];

                    _dataArray = _dataArray.filter(o => (_ids.includes(o._id)))

                    tools.changeValue(state, 'members', () => _dataArray)
                },

            }}
            form_render={formProps => {
                const { values, submitting, form, invalid, dirty, valid } = formProps;

                return (<>
                    <Row>
                        <Col flex="auto"><FormField label="Channel name" name="title" type="text" validate={rules.required} /></Col>
                        <Col flex="120px">
                            <FormField label="Status" name="status" type="select" data={channel_status_array} validate={rules.required} />
                        </Col>
                    </Row>
                    
                    <UsersSearch mode="multiple" label="Members" name='members_ids' preload
                        filter={{ status: "enabled" }}
                        onSelect={(___, opt, raw) => form.mutators.selectUser(raw)}
                        onDeselect={(txt, node) => form.mutators.de_selectUser(node)}
                        defaultValues={values.members ? values.members.map(o => ({ ...o, title: o.name })) : []}
                        validate={rules.required}
                    />

                    <Row gutter={[10]}>
                        <Col flex="auto" />
                        <Col><Button onClick={onCancel} color="white">Cancel</Button></Col>
                        <Col><Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >{(initialValues && initialValues._id) ? "Update" : "Create"} Channel</Button></Col>
                    </Row>                    

                </>)
            }}
        />

    </>)
}
const WithApollo = withApollo(ChannelForm)

export default function ChannelFormModal({ initialValues, visible, onCancel, onSuccess }) {
    const handel_onSuccess = (values) => {
        if (onSuccess) onSuccess()
        else onCancel()
    }

    let _initialValues;
    if (initialValues && initialValues.members){
        let members_ids = initialValues.members.map(o => (o._id));
        _initialValues = { ...initialValues, members_ids }
    }

    return (<>
        <Modal title="Channel" visible={visible} onCancel={onCancel} footer={false} closable centered destroyOnClose>
            {visible && <WithApollo onCancel={onCancel} initialValues={_initialValues} onSuccess={handel_onSuccess} />}
        </Modal>
    </>)
}
ChannelFormModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    initialValues: PropTypes.object,
}

