/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import { Row, Col, message, Result, Divider, Upload, Card, Steps } from 'antd'
import { loader } from 'graphql.macro';
// import arrayMutators from 'final-form-arrays'
import SignaturePad from 'react-signature-canvas'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Button, Icon, IconButton } from 'Common/components'
import Header from '../Components/Header';
import { __error } from 'Common/scripts/consoleHelper';
import { FIREWATCH_LOG_DOC } from '../../constants';
import { withApollo } from '@apollo/client/react/hoc';
// import { ListHeader } from 'Common/components/Typography';
import moment from 'moment';
// import { dateToMongo } from 'Common/scripts/Functions';

const ADD_DATA = loader('src/Graphql/FormsData/addFormsData.graphql');
export const FORM_KEY = FIREWATCH_LOG_DOC;

const defaultValue = {
  duty_date: moment(), duty_time: moment()
}

const DisplayInput = ({ children }) => (<span style={{ fontWeight: "bold", color: "blue", borderBottom: "1px solid blue", minWidth: "100px", display: "inline-block" }}>{children}</span>)

const CalgaryHeader = () => (<Row>
  <Col><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/form_data/calgary_firewatch_logo2.png`} height="100px" /></Col>
  <Col flex="auto" />
  <Col><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/form_data/calgary_firewatch_logo1.png`} height="100px" /></Col>
  <Col></Col>
</Row>)

const SuccessScreen = () => {
  return (<div style={{ backgroundColor: '#001529', flex: 1, justifyContent: "center", alignItems: "center", display: 'flex' }}>
    <Result
      status="success"
      title={<span style={{ color: 'white' }}>Security Guard Firewatch Acknowledgement Submitted Successfully!</span>}
      extra={<img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-light.png`} />}
    // extra={<img src="https://sp-ao.shortpixel.ai/client/q_glossy,ret_img/https://alistsecurity.com/wp-content/uploads/2021/03/Website-Logo-Dark-Ver.-2.png" />}
    />
  </div>)
}

const DutiesComp = ({ trimSignature, signatures, clearSignature, duty_signature, onSubmit }) => {
  return (<>
    <div style={{ display: "flex", justifyContent: "center", flexDirection:"column", margin: "20px auto", fontSize:"18px" }}>

      <div style={{ textAlign:"center", fontSize:"24px", padding:"20px" }}>FIRE WATCH REQUIREMENTS DURING SHUTDOWN OF FIRE ALARM AND DETECTION SYSTEMS</div>
      <div style={{ borderBottom:"1px solid #000", width:"100%", margin:"0 0 20px 0" }} />
      <p>The National Fire Code – Alberta Edition 2019 states:</p>

      <Row gutter={[10]} className='nowrap'>
        <Col flex="130px"><b>2.8.2.8.</b></Col>
        <Col flex="auto">
          <b>Shutdown of Fire Alarm Systems</b>
          <ol>
            <li>If a fire alarm and detection system, or part thereof, is inoperative for more than 2 hours for any reason, the owner shall notify the fire department, and when directed, provide acceptable surveillance within the building continuously until the fire alarm and detection system is restored to operating condition.</li>
            <li>Procedures acceptable to the fire department shall be developed to notify occupants if a fire or other emergency occurs while the fire alarm and detection system is inoperative. (See Note A- 2.8.2.8.(2).)</li>
          </ol>
        </Col>
      </Row>

      <Row gutter={[10]} className='nowrap'>
        <Col flex="130px"><b>A-2.8.2.8.(2)</b></Col>
        <Col flex="auto"><p>Interruption of normal automatic operation of the fire alarm system for periodic testing purposes constitutes a “temporary shutdown”. Appropriate alternative measures for informing building occupants and the fire department of a fire during shutdown of a fire alarm system should be worked out in cooperation with the local fire department. The alternative measures decided upon should be recorded as part of the building fire safety plan.</p></Col>
      </Row>

      <p style={{ fontWeight:"bold" }}>Appropriate alternative measures shall be in the form of a fire watch with the following requirements;</p>

      <ol>
        <li>
          Dedicated watchman utilized/employed for fire watch service in a facility/building shall have knowledge of and be capable of performing the following functions:
          <ul>
            <li>Recognizing an emergency situation.</li>
            <li>Transmitting an emergency warning to facility/building occupants.</li>
            <li>Transmitting an emergency message to 911.</li>
            <li>Operating equipment required for fire control and emergency warning.</li>
          </ul>
        </li>
        <li>No other duties shall interfere with a watchman's duties.</li>
        <li>A sufficient number of watchmen shall be utilized/employed to make at least one complete patrol of the area to be protected <b><u>including stairwells</u></b> every 60 minutes or less, depending on the risk and requirements of the AHJ.</li>
        <li>A process shall be in place to track the patrol route.</li>
        <li>Each watchman shall be provided with a means of communication to 911 both within and outside of the facility/building required to be protected. (Cellular phone is acceptable).</li>
        <li>
          Each watchman shall be advised of:
          <ul>
            <li>Route to be followed during patrol.</li>
            <li>Details to be observed during the patrol.</li>
            <li>Situations that require notification of emergency personnel.</li>
            <li>Emergency notification instructions.</li>
            <li>Fire safety plan emergency procedures relevant to the area(s) for which fire watch has been assigned.</li>
            <li>Fire extinguishers and/or hose cabinet locations.</li>
          </ul>
        </li>
      </ol>

      <p style={{ fontWeight: "bold" }}>Fire watch shall be maintained until the fire alarm and detection system is fully operational.</p>
       
      <div style={{ display: "flex", flexDirection: "column", alignSelf:"center", position: "relative", width:"400px" }} >
        <span className="bold uppercase">Please sign the document</span>
        <span style={{ border: "1px solid #CCC", borderRadius: "5px" }}><SignaturePad
          penColor="blue"
          dotSize={1}
          throttle={50}
          velocityFilterWeight={0.1}
          onEnd={(e) => trimSignature(e, 'duty_signature')}
          backgroundColor="#FFF"
          canvasProps={{ width: 350, height: 200 }}
          required 
          ref={(ref) => { signatures.duty_signature = ref }} /></span>
        <IconButton onClick={(e) => clearSignature(e, 'duty_signature')} icon="sync-alt" color="green" style={{ position: "absolute", top: '28px', right: "5px" }} size="2x" />
      </div>
    </div>

    <hr />
    <p>The City of Calgary | P.O. Box 2100 Stn. M | Calgary, AB, Canada T2P 2MS | calgary.ca</p>
    <hr />

    <Row>
      <Col flex="auto" />
      <Col flex="100px"><Button disabled={duty_signature == null} onClick={onSubmit} block>I agree</Button></Col>
    </Row>
  </>)
}

class TheFormComp extends Component {
  signatures = { duty_signature: null, acknowledgement_signature: null }

  constructor(props) {
    super(props)
    this.state = { 
      loading: false, data: null, success: false, signatureDataURL: null, fileList: [],
      currentStep: 0
    }
    this.onSubmit = this.onSubmit.bind(this);
  }

  clearSignature = (event, name) => {
    event.preventDefault()
    this.signatures[name].clear()
    if (this.state[name] !== null) this.setState({ [name]: null })
  }

  trimSignature = (event, name) => {
    event.preventDefault()
    const signatureDataURL = this.signatures[name].getTrimmedCanvas().toDataURL('image/png');
    this.setState({ [name]: signatureDataURL })
  }

  async onSubmit(values) {
    const input = { ref_key: FORM_KEY, title: `${values.guard_name}` }

    const fields = [
      { name: 'duty_date', label: 'First name', value: values.duty_date },
      { name: 'duty_time', label: 'Middle name', value: values.duty_time },
      { name: 'guard_name', label: 'Last name', value: values.guard_name },
      { name: 'site_address', label: 'Last name', value: values.site_address },
      // { name: 'email_to', label: 'Last name', value: values.email_to },
      // { name: 'email_to', label: 'Last name', value: values.email_to },
      { name: 'duty_signature', label: 'Duty Signature', value: this.state.duty_signature },
      { name: 'acknowledgement_signature', label: 'Acknowledgement Signature', value: this.state.acknowledgement_signature },
      // { name: 'fire_sco', label: 'Last name', value: values.fire_sco },
    ]
    Object.assign(input, { fields })

    const added = await this.props.client.mutate({ mutation: ADD_DATA, variables: { input } }).then(r => (r?.data?.addFormsData))
    .catch(err => {
      console.log(__error("ERROR"), err);
      message.error("Failed to process the request!");
      return false;
    });

    if (!added || added.error){
      message.error(added.error.message);
      return false;
    }

    this.setState({ success: added })
    return false;
  }


  render() {
    const { busy, error, success, currentStep, duty_signature } = this.state;
    if (success) return <SuccessScreen />

    return (<>
      <div style={{ backgroundColor: "#f8f9fa" }} className="form-data">
        <Header title="Firewatch Log Form" />

        <div style={{ display: 'flex', justifyContent: "center" }}>
          <div style={{ maxWidth: "1200px", width: "100%" }}>

            <Steps current={currentStep} initial={0} style={{ padding:"20px 20px"}}>
              <Steps.Step title="Duties" />
              <Steps.Step title="Acknowledgement" />
            </Steps>

            <Card style={{ border: "1px #EEEEEE black", backgroundColor:"#FFFFFF", borderRadius:"5px" }}>
              <CalgaryHeader />

              <div style={{ display: (currentStep === 0 ? "block" : "none") }}>
                <DutiesComp 
                  trimSignature={this.trimSignature}
                  signatures={this.signatures}
                  duty_signature={this.state.duty_signature}
                  clearSignature={this.clearSignature}
                  onSubmit={() => this.setState({ currentStep: 1 })}
                />
              </div>

              {currentStep===1 && <>
                <div style={{ fontSize: "30px", textAlign: "center", fontWeight: "600", padding:"20px" }}>FIRE WATCH NOTICE</div>

                <FormComponent onSubmit={this.onSubmit} id='FirewatchLogForm' loading={busy} debug={true} fields={defaultValue} moveOnError
                  form_render={formProps => {
                    const { values, submitting, form, invalid, dirty, valid } = formProps;

                    return (<>
                      <div>
                        <Row>
                          <Col span={12} md={4}><FormField name="duty_date" label="Date" size="medium" type="date" validate={rules.required} /></Col>
                          <Col span={12} md={4}><FormField name="duty_time" label="Time" returnAs="moment" size="medium" type="time" validate={rules.required} /></Col>
                          <Col span={24} md={8}><FormField name="guard_name" label="Guard Name" size="medium" type="text" validate={rules.required} /></Col>
                          <Col span={24} md={8}><FormField name="site_address" label="Site Address" size="medium" type="text" validate={rules.required} /></Col>
                        </Row>

                        <div style={{ padding: "20px", fontSize: "20px" }}>
                          <p>I, <DisplayInput>{values.guard_name}</DisplayInput> take complete responsibility for maintaining fire watch at <DisplayInput>{values.site_address}</DisplayInput>.</p>
                          <p>The above mentioned shall maintain fire watch in all or part of the building according to the National Fire Code - Alberta Edition 2019 and the requirements of the Calgary Fire Department. I understand that fire watch is required until all fire alarm and detection system is repaired and fully functional with documentation made available to the Calgary Fire Department. All documentation is to be emailed or faxed to Fire SCO.</p>
                        </div>

                        <Row align="bottom">
                          <Col flex="auto"><FormField name="email_to" label="Email address" size="medium" type="email" disabled /></Col>
                          <Col md={4} span={24} align="center" style={{ fontSize: "24px", marginBottom: "10px" }}>or faxed to</Col>
                          <Col flex="auto"><FormField name="fax_to" label="Faxed number" size="medium" type="phone" disabled /></Col>
                        </Row>

                        <Row>
                          <Col md={12}>
                            <FormField name="guard_name" label="Building Representative" size="medium" type="text" validate={rules.required} />
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "20px auto" }}>
                              <div style={{ display: "flex", flexDirection: "column", position: "relative" }} >
                                <span className="bold uppercase">Please sign the document</span>
                                <span style={{ border: "1px solid #CCC", borderRadius: "5px" }}><SignaturePad
                                  penColor="blue"
                                  dotSize={1}
                                  throttle={50}
                                  velocityFilterWeight={0.1}
                                  onEnd={(e) => this.trimSignature(e, 'acknowledgement_signature')}
                                  backgroundColor="#FFF"
                                  canvasProps={{ width: 350, height: 200 }}
                                  required ref={(ref) => { this.signatures.acknowledgement_signature = ref }} /></span>
                                <IconButton onClick={(e) => this.clearSignature(e, 'acknowledgement_signature')} icon="sync-alt" color="green" style={{ position: "absolute", top: '28px', right: "5px" }} size="2x" />
                              </div>
                            </div>
                          </Col>
                          <Col md={12}>
                            <FormField name="fire_sco" disabled label="Fire SCO" size="medium" type="text" />
                          </Col>
                        </Row>

                        <hr />
                        <p>The City of Calgary | P.O. Box 2100 Stn. M | Calgary, AB, Canada T2P 2MS | calgary.ca</p>
                        <hr />

                        <div style={{ height: '20px' }} />
                        <Row>
                          <Col flex="100px"><Button onClick={() => { this.setState({ currentStep: 0 }) }} block>Back</Button></Col>
                          <Col flex="auto" />
                          <Col flex="100px"><Button disabled={invalid || submitting || this.state.acknowledgement_signature == null} type="primary" color="orange" block htmlType='submit' size="large" style={{ marginBottom: "50px" }} loading={submitting}>Submit Form</Button></Col>
                        </Row>

                      </div>
                    </>)

                  }}
                />
              </>}
            </Card>

            <div style={{ height:"20px" }} />

          </div>
        </div>
      </div>
    </>)

  }
}

export const TheForm = withApollo(TheFormComp)
export default TheForm;
