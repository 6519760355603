import React, { Component, useEffect, useState, useMemo } from 'react'
import { Avatar, Button, UserCardPopover, Loader, ShowAttachments, Icon, ActionMenu, IconButton } from 'Common/components'
import { ContentArea } from 'Layout_v1'
import { Col, Row, Space, Alert, message, Divider, Modal, Card, PageHeader, Tag } from 'antd'
import { mongoToDate, scrollTo } from 'Common/scripts/Functions';
import { useLazyQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { defaultDateTimeFormat } from 'configs';
import { connect } from "react-redux";
import { ListHeader } from 'Common/components/Typography';
import { isSuperAdmin } from 'Common/scripts/Security';
import TodoItemFormModal from 'Modules/Threads/components/todos/todo_item_form';
import TodoSharedForm from 'Modules/Threads/components/todos/TodoSharedForm';

const GET_TODO = loader('src/Graphql/Todos/todo.graphql');
const UPDATE_TODO = loader('src/Graphql/Todos/updateTotoItem.graphql');
const DELETE_TODO = loader('src/Graphql/Todos/deleteTotoItem.graphql');
const UPDATE_TODO_STATUS = loader('src/Graphql/Todos/updateStatusToto.graphql');
const UPDATE_ITEM_STATUS = loader('src/Graphql/Todos/updateStatusTotoItem.graphql');

const TodoItem = ({ item, todo_id, index }) => {
  const [busy, setBusy] = useState(false)
  const [updateStatusTotoItem, status_update_details] = useMutation(UPDATE_ITEM_STATUS);
  const [deleteTotoItem, delete_details] = useMutation(DELETE_TODO);


  const toggleCancel = async (item) => {
    setBusy(true)
    let resutls = await updateStatusTotoItem({
      variables: {
        parent_id: Number(todo_id),
        id: Number(item.id),
        status: item.status == "canceled" ? "open" : "canceled"
      }
    })
      .then(r => (r.data.updateStatusTotoItem))

    setBusy(false)
    if (resutls.error) {
      message.error(resutls.error.message);
    }

    return resutls;
  }

  const toggleItemStatus = async (item) => {
    setBusy(true)

    let resutls = await updateStatusTotoItem({
      variables: {
        parent_id: Number(todo_id),
        id: Number(item.id),
        status: item.status == "open" ? "completed" : "open"
      }
    })
      .then(r => (r.data.updateStatusTotoItem))

    setBusy(false)
    if (resutls.error) {
      message.error(resutls.error.message);
    }
    return resutls;
  }

  const onDelClick = (item) => {
    message.open({
      content: <>
        <p style={{ fontWeight:"bold", marginBottom:"10px" }}>Comfirm Deletion!</p>
        <p>Are you sure to delete this record?</p>
        <Row style={{ width:"300px", marginTop:"20px" }}>
          <Col flex="auto" color="red" align="left"><Button onClick={() => message.destroy()}>No</Button></Col>
          <Col><Button color="green" onClick={()=>onDeleteConfirm(item)}>Yes</Button></Col>
        </Row>
      </>
    })
  }

  const onDeleteConfirm = async(item) => {
    const resutls = await deleteTotoItem({ variables: { parent_id: Number(todo_id), id: Number(item.id) } }).then(r => (r.data.deleteTotoItem))
    message.destroy();
    if (resutls.error) message.error(resutls.error.message)
    return resutls;
  }

  let actionMenu = [
    { title: "Delete", onClick: () => onDelClick(item) }
  ]
  if (item.status == "canceled") actionMenu.push({ title: "Undo Cancel", onClick: () => toggleCancel(item) })
  if (item.status == "open") actionMenu.push({ title: "Cancel", onClick: () => toggleCancel(item) })
  if (item.status == "completed") actionMenu.push()

  return (<Loader loading={busy}>
    <Row gutter={[20, 10]} align="middle">
      <Col style={{ fontSize: "22px", borderRight: "1px solid #EEE", color: "#999" }} flex="50px" align="right">{index + 1}</Col>
      <Col flex="auto" className={`body ${["canceled"].includes(item.status) ? "disabled" : ""}`} onClick={() => (["canceled"].includes(item.status)) ? void(0) : toggleItemStatus(item)}>{item.body}</Col>
      <Col style={{ borderLeft: "1px solid #EEE" }}>
        <ActionMenu trigger="hover" contentStyle={{}} hideArrow menu={actionMenu}><Icon icon="ellipsis-h" /></ActionMenu> 
      </Col>
    </Row>
  </Loader>)
}

function TodoDetails({ match, user }) { // match.params.id
  const [todoArray, set_todos] = useState([])
  const [showItemForm, set_showItemForm] = useState(false)
  const [showSharedForm, set_showSharedForm] = useState(false)

  const [loadTodo, { called, loading, data }] = useLazyQuery(GET_TODO, { variables: { filter: JSON.stringify({ _id: Number(match.params.id) }) } } );
  const [updateStatusToto, todostatus_details] = useMutation(UPDATE_TODO_STATUS);

  useEffect(() => {
    loadTodo().then(r=>{
      if (r.data.todo.error){
        message.error(r.data.todo.error.message);
        return r;
      }
      else{
        set_todos(r.data.todo.messages);
      }
      return r;
    })
  }, [match.params.id])

  const toggleArchiveThis = async() => {
    const resutls = await updateStatusToto({ variables: { _id: Number(data.todo._id), status: (data.todo.status == "completed") ? "open" : "completed" } }).then(r => (r.data.updateStatusToto))
    return resutls;
  }

  if (loading) return <Loader loading={loading} center />
  if (!data || !data?.todo || data?.todo?.error) return <Alert message={(data && data?.todo?.error?.message) || "No records found!"} type='error' showIcon />

  return (<>
    <ListHeader title={data?.todo.title} />
    <div style={{ height:"10px" }} />
    
    <Row gutter={[10, 10]}>
      <Col flex="auto">

        {data?.todo?.list_items.map((item, i) => {
          return (<ContentArea className={`todo-message status_${item.status}`} style={{ marginBottom:"5px" }} key={i}>
            <TodoItem item={item} todo_id={data.todo._id} index={i} />
          </ContentArea>)
        })}

        <Divider><Button onClick={() => set_showItemForm(true)} size="small">Add another</Button></Divider>
      </Col>


      <Col flex="300px">
        <Button 
          disabled={data?.todo?.author._id !== user._id && !isSuperAdmin()}
          style={{ margin: "0 0 10px 10px" }} 
          block color={data?.todo.status == "open" ? "red" : "green"} 
          onClick={toggleArchiveThis}>{data?.todo.status == "open" ? "Archive" : "Un-Archive"} this</Button>
        {/* {data?.todo.status == "open" && <Button style={{ margin: "0 0 10px 10px" }} block color={data?.todo.status == "open" ? "red" : "green"} onClick={toggleArchiveThis}>{data?.todo.status == "open" ? "Archive" : "Un-Archive"} this</Button>} */}
        {/* {data?.todo.status == "completed" && <Button style={{ margin: "0 0 10px 10px" }} block color="green" onClick={toggleArchiveThis}>Un-Archive this</Button>} */}

        <ContentArea style={{ margin: "0 0 10px 10px" }}>
          <div>
            <span className='gray3'>Author:</span> {data?.todo?.author?.name}<br />
            <span className='gray3'>Creatred:</span> {mongoToDate(data?.todo.created_at).format(defaultDateTimeFormat)}<br />
            <span className='gray3'>Lasy updated: </span> {mongoToDate(data?.todo.updated_at).format(defaultDateTimeFormat)}
          </div>
          <div>
            <Divider><b>Shared with</b> <IconButton onClick={() => set_showSharedForm(!showSharedForm)} icon="pen" /></Divider>
            <Space direction='vertical'>
              {/* {data.todo.shared_with.map((o, i) => (<Tag color='blue' key={i}>{o.name}</Tag>))} */}

              {data?.todo.shared_with.map((o, i) => {
                return <div style={{ fontSize: "14px" }} key={i}><UserCardPopover userInfo={o}><Avatar src={o.avatar} alt={o.name} size={32} /></UserCardPopover> {o.name}</div>
              })}
            </Space>
          </div>

          {data?.todo?.attachments?.length > 0 && <div>
            <Divider><b>Attachments</b></Divider>
            <ShowAttachments attachments={data?.threadsTicket?.attachments} />
          </div>}

        </ContentArea>
      </Col>
    </Row>

    <TodoSharedForm visible={showSharedForm} onCancel={() => set_showSharedForm(false)} onSuccess={() => set_showSharedForm(false)} initialValues={data?.todo} />
    <TodoItemFormModal onSuccess={() => set_showItemForm(false)} todo={data?.todo} onCancel={() => set_showItemForm(false)} initialValues={{}} visible={showItemForm} />

  </>)

}
const mapStateToProps = ({ ep_admin:{user} }) => {
  return ({ user });
}
export default connect(mapStateToProps, null)(TodoDetails)

