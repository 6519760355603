import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import { IconButton, Loader } from 'Common/components';
import { message, Popconfirm } from 'antd';
import { connect } from 'react-redux'
import { withApollo } from '@apollo/client/react/hoc';
import { __error } from 'Common/scripts/consoleHelper'
import { updateModule } from '../InternalStore/actions'
import { loader } from 'graphql.macro';


const DELETE = loader('src/Graphql/Courses/deleteModuleScreen.graphql');



class ScreenPreview extends Component {
    state = { busy: false, error: null, showForm: false, screenToEdit: null, showDetails:false }
    constructor(props){
        super(props);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.toggleModuleForm = this.toggleModuleForm.bind(this);
    }

    onEdit = args => this.props.onEditClick({ screen:this.props.screen })

    onDelete = args => {
        const { course_id, module } = this.props;

        this.setState({ busy:true, error:null });
        const { screen, client } = this.props;

        client.mutate({ mutation: DELETE, variables: { 
            course_id,
            module_id: module._id,
            id: screen._id
        } }).then(r => {
            let response = r.data.deleteModuleScreen;
            this.setState({ busy: false });

            if (response.error) {
                message.error(response.error.message)
                return;
            }
            message.success("Screen removed!");
            this.props.updateModule(response)

        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            message.error("Failed to process the request!");
            this.setState({ busy: false, error:"Failed to process the request!" })
        })

    }

    toggleModuleForm(args) {
        if (!args) return this.setState({ screenToEdit: null, showForm: null })
        this.setState({ screenToEdit: args, showForm: true })
    }


    render() {
        const { screen, formData } = this.props;
        const { busy, showForm, screenToEdit, showDetails } = this.state;

        return (<Loader loading={busy}><div style={{ border: "1px solid #DDD", borderRadius:"5px", padding:"10px", margin:"5px" }}>
            {/* {(screen && screen._id) && <ModuleForm editNode={screen} course_id={formData._id} isEditing={isEditing} onClose={() => setIsEditing(false)} />} */}
            <div style={{ fontSize: "18px" }}>{screen.title}
                <IconButton onClick={() => this.setState({ showDetails: !this.state.showDetails })} icon={{ icon: showDetails ? "eye" : "eye-slash" }} />
                <IconButton onClick={this.onEdit} icon={{ icon: "pen" }} />
                <Popconfirm onConfirm={this.onDelete} title="Are you sure？">
                    <span><IconButton theme="red" onClick={console.log} icon={{ icon: "trash-alt", color: 'red' }} /></span>
                </Popconfirm>
            </div>
            {showDetails && <>
                <div dangerouslySetInnerHTML={{ __html: screen.body }} />
                {/* <h1 style={{marginBottom:"0", marginTop:"20px"}}>
                    <span>Attachments</span>
                    <span><IconButton onClick={console.log} icon={{ icon: "plus", color: 'red' }} /></span>
                </h1>
                <div>[attachments]</div> */}
            </>}

        </div></Loader>)
    }
}
ScreenPreview.propTypes = {
    screen: PropTypes.object,
    onEditClick: PropTypes.func.isRequired,
    module: PropTypes.object.isRequired,
    course_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
}



const mapStateToProps = ({ formData }) => {
    return { formData };
};
const mapDispatchToProps = dispatch => ({
    updateModule: (payload) => dispatch(updateModule(payload)),
});
const Wrapper = connect(mapStateToProps, mapDispatchToProps)(ScreenPreview)

export default withApollo(Wrapper);