import React, { Component, useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { ListHeader } from 'Common/components/Typography';
import { Col, Row, Space } from 'antd';
import TicketsSearchBar from '../tickets/Tickets_SearchBar';
import { Avatar, DeleteButton, IconButton, Table } from 'Common/components';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation } from '@apollo/client';
import { defaultDateTimeFormat, defaultPageSize } from 'configs';
import { Link } from 'react-router-dom';
import { ROOT_PATH } from '../../constants';
import { verifyRole } from 'Common/scripts/Security';
import { connect } from "react-redux";

const GET_CHANNEL_PAGINATION = loader('src/Graphql/threads/threadsChannelsQuery.graphql');

class SubscriptionHandler extends Component {
    componentDidMount() {
        document.body.addEventListener('ticket-event', this.onHandel.bind(this));
    }

    componentWillUnmount() {
        document.body.removeEventListener('ticket-event', this.onHandel.bind(this));
    }

    onHandel(e) {
        this.props.handleEventChange(e)
    }

    render() {
        return null
    }
}


const ChannelsList = ({ title, filter, user, onEditClick, onDeleteClick, permanentFilter }) => {
    const [pagination, setPagination] = useState({ first: defaultPageSize, after: 0, filter: { ...permanentFilter }, others: { sort: { _id: -1 } } })
    const [channelList, set_channelList] = useState(null)
    const [queryResults, set_queryResults] = useState(null)

    const [threadsChannelsQuery, { called, loading, data }] = useLazyQuery(
        GET_CHANNEL_PAGINATION, { variables: { ...pagination, filter: JSON.stringify(pagination.filter), others: JSON.stringify(pagination.others) } }
    );

    useEffect(() => {
        // if (called || loading || data) return;
        let variables = {
            first: defaultPageSize, // Number(pagination.first),
            after: 0, // Number(pagination.after),
            filter: JSON.stringify({ ...filter, ...permanentFilter }),
            others: JSON.stringify(pagination.others)
        }

        if (JSON.stringify(filter) !== JSON.stringify(pagination.filter)) {
            setPagination({
                first: defaultPageSize, after: 0,
                filter: { ...filter, ...permanentFilter },
                others: pagination.others,
            })
        }

        threadsChannelsQuery({ variables }).then(r => {
            set_channelList(r?.data?.threadsChannelsQuery?.edges);
            set_queryResults(r?.data?.threadsChannelsQuery);
        })
    }, [filter])


    const columns = () => [
        {
            title: 'Title', fixed: 'title', key: 'title', render: (__, rec) => {
                return (<>
                    <Space>
                        <Avatar src={rec.author.avatar} alt={rec.author.name} tooltip={`Created by ${rec.author.name}`} size={25} />
                        <Link to={`${ROOT_PATH}/channel/${rec._id}`} style={{ fontSize: "22px" }}>{rec.title}</Link>
                    </Space>

                    <span className='hidden-menu'>
                        {(verifyRole('th.ch.update', {user}) && onEditClick) && <IconButton className="item" icon="pen" onClick={() => onEditClick(rec)} />}
                        {(verifyRole('th.ch.delete', {user}) && onDeleteClick) && <DeleteButton className="item" onConfirm={() => onDeleteClick(rec?._id)} />}
                    </span>
                </>)
            }
        },
        {
            title: 'Member', key: 'members', dataIndex: 'members', render: (__, rec) => <Space>
                {(rec?.members?.map((o, i) => (<Avatar src={o.avatar} alt={o.name} tooltip={`${o.name}`} size={25} key={i} />)))}
            </Space>
        },
        { title: 'Status', width: 100, key: 'status', dataIndex: 'status' },
        { title: 'Created by', width: 100, key: 'author.name', dataIndex: 'author.name', render: (__, rec) => (rec.author.name) },
        { title: 'Open Tickets', width: 100, key: 'open_tickets', dataIndex: 'open_tickets' },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        console.log("handleTableChange()", { pagination, filters, sorter });
    };

    const handleEventChange = ({ detail }) => {
        if (detail.mutation == "TICKET_UPDATED" || detail.mutation == "TICKET_CLOSED" || detail.mutation == "TICKET_ARCHIVED") {
            if (queryResults && queryResults?.edges && detail.ticket._id) {
                let _threadsList = queryResults?.edges.map(o => ((o._id == detail.ticket._id) ? detail.ticket : o))

                set_channelList(_threadsList)
            }
        }

    }


    return (<>
        <SubscriptionHandler handleEventChange={handleEventChange} />

        <Row>
            <Col flex="auto"><ListHeader title={title || `Threads`} totalCount={(queryResults && queryResults?.totalCount) || 0} /></Col>
            <Col><TicketsSearchBar /></Col>
        </Row>

        <Table loading={loading} size="small"
            // title={() => <b>Total {((data && data.totalCount) || '0')} record(s) found </b>}
            columns={columns()}
            // dataSource={(data && data.threadsChannelsQuery && data.threadsChannelsQuery.edges) || null}
            dataSource={channelList || null}
            pagination={pagination}
            onChange={handleTableChange}
            // scroll={{ x: 1500, y: this.dimensions.height - 350 }}
            rowClassName={(rec => {
                let classes = 'show-menu-on-hover '
                // const isViewed = rec?.viewed_by.find(o => (o.user_id == this.props.user._id));
                // const expired = rec?.expiry_date ? mongoToDate(rec.expiry_date).isBefore(mongoToDate()) : false;
                // if (!isViewed) classes += ' yellow-table-row'

                return classes; //record.status == 'disabled' ? 'disabled-table-row' : "";
            })}
        // expandable={{
        //   expandedRowRender: record => <div style={{ margin: 0 }}>
        //     <p>{record.body}</p>
        //   </div>,
        //   // rowExpandable: record => record.comments && record.comments.length > 0,
        // }}
        />
    </>)
}
ChannelsList.propTypes = {
    title: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    filter: PropTypes.object,
    permanentFilter: PropTypes.object,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
}


const mapStateToProps = ({ ep_admin }) => {
    return ({
        user: ep_admin.user,
        thr_ch_history_fltr: ep_admin?.pageSettings?.thr_ch_history_fltr || {},
    });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     on_updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export default connect(mapStateToProps, null)(ChannelsList)
