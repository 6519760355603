
import { INIT_FORM_DATA, UPDATE_FORM_DATA, FLUSH_DATA, UPDATE_MODULE, REMOVE_MODULE } from '../constants';

const initialState = {
    _id:null,
    title:null,
    modules:null
};


const initForm = (state, payload) => {
    if (!payload || !payload._id) return {};
    return { ...payload }
}

const updateForm = (state, payload) => {
    if (!payload || !payload._id) return {};
    return { ...payload }
}

function updateModule(state, payload){
    let modules = state.modules ? state.modules.slice() : [];
    let index = modules.findIndex(o => o._id == payload._id);

    if (index > -1) modules[index] = payload;
    else modules.push(payload);

    return {
        ...state,
        modules,
    }
}

function removeModule(state, { _id }){
    console.log("redux > removeModule()");

    if (!_id) return state;

    let modules = state.modules ? state.modules.slice() : [];
        modules = modules.filter(o=>o._id!=_id)

    return {
        ...state,
        modules,
    }
}

function flushData(state, no_payload_required){
    return { ...initialState }
}

const formReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case INIT_FORM_DATA:
            return initForm(state, payload);

        case UPDATE_FORM_DATA:
            return updateForm(state, payload);

        case FLUSH_DATA:
            return flushData(state, payload);

        case UPDATE_MODULE:
            return updateModule(state, payload);

        case REMOVE_MODULE:
            return removeModule(state, payload);

        default:
            return state;
    }
}
export default formReducer;

