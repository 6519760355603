import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { routes } from 'Modules';
import { E404 } from "Layout_v1";
import Wrapper from "Layout_v1/Wrapper";
import { PersistGate } from 'redux-persist/integration/react';
import Store from 'Store';
import Applicant_form from './Modules/ApplicationForm/Applicant_form';
// import Client_form from './Modules/ClientInfo/Client_form'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import TestForm from 'Modules/TestManager/Forms';
import ProtectedTestForm from 'Modules/TestManager/ProtectedTestForm';
import AppHome from 'Modules/AppHome';
import InputForm from 'Modules/FormsData/Forms';



export const App = () => (
  <DndProvider backend={HTML5Backend}>
    <BrowserRouter>
      <Switch>
        <Route path={'/dotest/protected'} component={ProtectedTestForm} key={1} />
        <Route path={'/dotest/:invitationId'} component={TestForm} key={1} />
        <Route path={'/employment_application_request/:id?/:section?'} component={Applicant_form} key={1} />
        {/* <Route path={'/client_form/:section?'} component={Client_form} key={2} /> */}
        <Route path={'/inputForm/:formType'} component={InputForm} key={3} />
        {/* <Route path={'/applicant_list'} component={Applicants_list} key={2} /> */}
        {/* <Route path={'/application_details/:id'} component={Detail} key={2} /> */}
        <Route path="/" exact={true} component={AppHome} />
        
        <PersistGate persistor={Store.persistor} loading={<div>Initilizing data...</div>} onBeforeLift={() => { /* do soemting */ }}>
          <Wrapper>
            <Switch>
                {routes}
                <Route component={E404} />
            </Switch>
          </Wrapper>
        </PersistGate>
      </Switch>
    </BrowserRouter>
  </DndProvider>

)
export default App;



// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <p>
//           Login page
//         </p>
//       </header>
//     </div>
//   );
// }

// export default App;
