import { Col, Image, Row } from 'antd';
import React from 'react'
import { Avatar } from './Avatar';

export const ShowAttachments = ({ attachments }) => {
    if (!attachments || attachments.length < 1) return null;

    return (<>
        <Row gutter={[10, 5]}>
            {attachments.map((item, i) => {
                let type = item.type.split("/")[1]

                return (<Col key={i}>
                    {(['jpeg', 'jpg', 'png', 'gif'].includes(type)) ?
                        <Image width={50} src={`${process.env.REACT_APP_DATA_URL}/${item.srcUrl}`} /> :
                        <a href={`${process.env.REACT_APP_DATA_URL}/${item.srcUrl}`} rel="noreferrer" target="_blank"><Avatar alt={type} tooltip={item.title} shape="square" size={50}>{type}</Avatar></a>
                    }
                </Col>)
            })}
        </Row>
    </>)
}

