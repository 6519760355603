import React from 'react';
import { addModule, addReducers } from '../connector';
import Leads from './leads_list';
import LeadsDetails from './leads_details';
import { getmodulePermissions, verifyRoles } from 'Common/scripts/Security';
import { ROOT } from './constants'

export const modulePermissions = getmodulePermissions('leds-mg');

// addReducers({ admins: reducers });
if (modulePermissions["leads.list"]) {
  addModule(
    { path: `${ROOT}`, component: Leads, icon: 'map-marker-alt', title:"Leads", exact:1, leftNav:true },
  )
}

if (modulePermissions["leads.details"]) {
  addModule(
    { path: `${ROOT}/id/:id`, component: LeadsDetails, exact:1, leftNav:false },
  )
}


// addModule(
//   { path: `${ROOT}`, component: Leads, icon: 'map-marker-alt', title: "Leads", exact: 1, leftNav: true },
// )
