import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'; 
import { Avatar as _Avatar, message, Tooltip, Upload } from 'antd';
import { IconButton } from './Button';
import { Loader } from './Loader';
import { __error } from 'Common/scripts/consoleHelper';
import ImgCrop from 'antd-img-crop';

export const Avatar = _props => {
    const [fileList, setFileList] = useState([]);
    const [src, setSrc] = useState(null);
    const [busy, setBusy] = useState(false);

    var props = { ..._props }
    delete props.alt;
    delete props.tooltip;
    delete props.onUpdate;
    delete props.children;


    useEffect(() => {
        if (_props?.src) setSrc(_props.src);
    }, [_props])

    const getSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };

    const startUpload = async (file) => {
        setFileList([file])
        setBusy(true);

        let fileObj = await getSrcFromFile(file);

        const results = await props.uploadAvatar(fileObj).then(response => (response)).catch(err => {
            console.log(__error("Request Error: "), err);
            return { error: { message: "Request ERROR" } }
        })
        setBusy(false);
        if (results.error) {
            message.error(results.error.message);
            return false;
        }

        setSrc(fileObj);
    }

    let children = _props.children;
    if (!children && _props.alt) children = <span style={{ fontSize: _props.size ? `${(_props.size / 1.3)}px` : `18px` }}>{String(_props.alt).trim()[0]}</span>;
    const size = _props.size || undefined;

    return <span style={{ position: "relative", display: "inline-block" }}>
        <Loader loading={busy}>
            {_props.tooltip && <Tooltip title={_props.tooltip} {..._props.tooltipProps}>
                <_Avatar src={src} shape={_props.shape || 'circle'} size={size} alt={_props.alt || false}>{children}</_Avatar>
            </Tooltip>}
            {!_props.tooltip && <_Avatar src={src} shape={_props.shape || 'circle'} size={size} alt={_props.alt || false}>{children}</_Avatar>}
        </Loader>
        {_props.uploadAvatar && <div style={{ position: "absolute", top: 0, right: 0 }}>
            <ImgCrop rotate>
                <Upload disabled={busy}
                    beforeUpload={(file) => {
                        startUpload(file);
                        return false;
                    }}
                    onRemove={(file) => setFileList([])}
                    showUploadList={false}
                    action={false} //"https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    // listType="picture-card"
                    fileList={fileList}
                    defaultFileList={[]}
                    accept=".jpg,.png,.jpeg"
                // onChange={console.log}
                // onPreview={onPreview}
                >
                    <IconButton icon="pen" />
                </Upload>
            </ImgCrop>
        </div>}

    </span>
}

Avatar.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    uploadAvatar: PropTypes.func,
}
