import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Form } from 'react-final-form'
import { loader } from 'graphql.macro';
import { rules, FormField } from 'Common/components/Form';
import { withApollo } from '@apollo/client/react/hoc';
import { Button } from 'Common/components';
import { Alert, message } from 'antd';
import { __error } from 'Common/scripts/consoleHelper';

// const CHANGE_STATUS = loader('src/Graphql/FormsData/editFormsDataStatus.graphql');
const CHANGE_STATUS = loader('src/Graphql/FormsData/editFormsDataStatus_Bulk.graphql');

const BulkStatusFormComp = ({ onUpdate, ids, statusArray, client, ref_key }) => {
    const [successNotes, set_successNotes] = useState(null);

    const onSubmit = async values => {

        const input = { 
            ids: ids, 
            status: values.status,
            note: values.note,
            ref_key: ref_key,
        };

        const updated = await client.mutate({ mutation: CHANGE_STATUS, variables: { input } }).then(r => {
            return r.data.editFormsDataStatus_Bulk;
        }).catch(err => {
            console.log(__error("ERROR"), err);
            return { error: { message:"Failed to process the request!" }};
        });

        if (updated.error) return message.error(updated.error,message);

        if (onUpdate) onUpdate(updated);
        set_successNotes(`${values.status}`)
        return true;
    }

    if (!ids) return <Alert message="Missing IDs" />
    if (!ref_key) return <Alert message="Missing ref_key" />

    if (successNotes) return <Alert message={successNotes} type="success" showIcon />

    return (<>
        <Form onSubmit={onSubmit}>
            {({ handleSubmit, submitting, form, values, invalid, dirty, valid }) => (<>
                <form className="form_component" onSubmit={handleSubmit}>
                    <FormField name="status" type="select" size="medium" placeholder="SELECT" label="Status"
                        data={statusArray.filter(o => o._id !== 'new')}
                    />
                    {dirty && <>
                        <FormField name="note" type="textarea" size="small" placeholder="Please provide the reason of status change" label="Notes" validate={rules.required} />
                        <div style={{ margin: "0 10px", textAlign: "right" }}>
                            <Button type="primary" size="medium" htmlType='submit' loading={submitting} disabled={!dirty || !valid}>Save</Button>
                        </div>
                    </>}
                </form>
            </>)}
        </Form>
    </>)
}
BulkStatusFormComp.propTypes = {
    ids: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
    statusArray: PropTypes.array.isRequired,
}


export const BulkStatusForm = withApollo(BulkStatusFormComp);
export default BulkStatusForm;