import React, { useState } from 'react'
import { DataRow, Icon, IconButton, Button } from 'Common/components';
import { Modal, Row, Col, message } from 'antd';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays';

const UPDATE_GUARD = loader('src/Graphql/guard/editGuard.graphql');


const EmergencyContacts = ({ data, onUpdate, client }) => {
    const [showForm, set_showForm] = useState(false);

    const onSubmit = async values => {
        // return console.log("onSubmit()", values);

        const input = {
            _id: data._id,
            emergency_contact: !values.emergency_contact ? undefined : values.emergency_contact.map(({ ename, ephone }) => ({ ename, ephone })),
        }

        const results = await client.mutate({ mutation: UPDATE_GUARD, variables: { input } }).then(r => {
            return r.data.editGuard;
        }).catch(err => {
            console.log(__error("ERROR: "), err);
            return { error: { message: "Failed to process the request!" } }
        })

        if (results.error) {
            message.error(results.error.message);
            return false;
        }
        
        onUpdate(results)
        set_showForm(false)
        return results;

    }

    return (<>
        <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="address-book" /> Emergency Contacts <IconButton onClick={() => set_showForm(true)} icon={"pen"} /></div>
        <div style={{ marginLeft: "20px" }}>
            {data?.emergency_contact?.length > 0 ? data.emergency_contact.map((contact, i) => (
                <DataRow col1={contact.ephone} col2={`${contact.ename}`} inline key={i} />
            )) : <p >No emergency contacts</p>}
        </div>
        {/* <DataRow col1={<Icon icon="phone" />} col2={`${contact.ephone} ${contact.ename}`} inline key={i} /> */}


        <Modal title={`Edit emergency contacts`} visible={showForm} footer={false} onCancel={() => set_showForm(false)}>
            {showForm && <>
                <FormComponent onSubmit={onSubmit} id='GuardcontactsForm' fields={{...data }} debug={false}
                    mutators={{
                        ...arrayMutators
                    }}

                    form_render={formProps => {
                        const { handleSubmit, submitting, values, form, invalid, dirty, valid } = formProps;

                        return (<>
                            <FieldArray name="emergency_contact">
                                {({ fields }) => (
                                    <div >
                                        <Row style={{ justifyContent: "space-around" }}>
                                            <Col flex="auto"><span style={{ fontSize: "18px", color: "#001529" }} >Emergency Contacts</span></Col>
                                            <Col><Button onClick={() => fields.push({ firstName: '', lastName: '' })}>Add</Button></Col>
                                        </Row>

                                        {fields.map((name, index) => (
                                            <Row key={index} style={{ marginBottom: "2px" }}>
                                                <Col flex="auto">
                                                    <Row key={index} style={{ flexWrap: "nowrap", alignItems: "center" }}>
                                                        <IconButton style={{ marginTop: "15px", marginLeft: "5px", }} icon="trash-alt" onClick={() => fields.remove(index)} />
                                                        <Col flex="auto"><FormField name={`${name}.ename`} label="Emergency contact name" size="medium" type="text" validate={rules.required} /></Col>
                                                        <Col flex="auto"><FormField name={`${name}.ephone`} label="Emergency contact number" type="text" size="medium" validate={rules.required} /></Col>
                                                    </Row>

                                                </Col>
                                            </Row>

                                        ))}
                                    </div>
                                )}
                            </FieldArray>

                            <div style={{ height: "20px" }} />
                            <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} loading={submitting} >Update</Button>

                        </>)
                    }}
                />

            </>}
        </Modal>

    </>)
}
export default withApollo(EmergencyContacts);