import React, { Component } from 'react'
import { DataRow, Icon } from 'Common/components';

export const TheField = ({ label, value, name, horizontal, vertical, condition, isCheckbox, size }) => {
    let _value = (condition) ? condition(value) : value;
    
    if (isCheckbox) {
        if (_value == 'yes') _value = <Icon icon="check" color="green" />
        if (_value == 'no') _value = <Icon icon="times" color="red" />
        return <DataRow col1={_value} col2={label} inline size={size} />
    }
    if (horizontal) return <DataRow col1={label} col2={_value} inline size={size} />
    if (vertical) return <DataRow col1={label} col2={_value} size={['100%', '100%']} />
    return (<DataRow col1={label} col2={_value} size={size} />)
}
