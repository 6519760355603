import React, { Component } from 'react'
import { Row, Col, message, Result, Divider, Upload, Steps, Alert, Modal } from 'antd'
import { loader } from 'graphql.macro';
import { connect } from "react-redux";
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { Button, Icon, IconButton, DevBlock, Loader } from 'Common/components'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Header from './Header';
import { __error, __yellow } from 'Common/scripts/consoleHelper';
import { updateForm, updateSteps, updateInfo, clearTest } from '../InternalStore/actions'
import { SuccessScreen, RenderField, UserInfoForm } from './'
import { withApollo } from '@apollo/client/react/hoc';
import AutoSaveForm from './AutoSaveForm';
import { sleep } from 'Common/scripts/Functions';
import CoursePlay from '../..//Courses/CoursePlay'


const UPDATE_CANDIDATE_INFO = loader('src/Graphql/TestResults/updateCandidateInfo.graphql');
const SUBMIT_STEP = loader('src/Graphql/TestResults/submitTestStep.graphql');



const RenderFormFields = ({ prevAttemp, fields, hide_result_status }) => {
    var num = 0;
    return (<>

        {fields.map((q, i) => {
            let attemptHistory = undefined; // prevAttemp ? prevAttemp.fields.find(o => o._id == q._id) : false;

            if (q.type !== 'heading' && q.type !== 'text' && q.type !== 'link') {
                attemptHistory = prevAttemp ? prevAttemp.fields.find(o => o._id == q._id) : false;
                num++;
            }
            return (<div key={i}>
                <RenderField num={num} hide_result_status={hide_result_status} field={q} attemptHistory={attemptHistory} />
            </div>)
        })}

    </>)
}

const validateResults = async (values, fields) => {
    let total = 0; //fields.length;
    let score = 0;
    fields.forEach(field => {
        if (field.type == 'radio' || field.type == 'fill-in-the-blanks'){
            total++;

            let correctAns = field.options[field.correct_answer];
            let inputAns = values[`field_${field._id}`];
    
            if (correctAns.text == inputAns) score++;
        }
    });

    let percent = Math.ceil((score / total) * 100);

    // console.log({ total, score, percent })
    return { total, score, percent };
}

const StepForm = ({ onSubmit, busy, thisStep, prevAttemp, test_form, currentStep, onBackClick }) => {
    const save = async (values) => {
        if (test_form.auto_submit=='no') return false;

        if (!busy) {
            validateResults(values, thisStep.fields).then(r => {
                if (thisStep.passing_percent <= r.percent){
                    // auto_submit
                    onSubmit(values);
                }else{
                }
            });
        }
    }

    const fields = {}; // (prevAttemp && prevAttemp.fields) ? prevAttemp.fields.map(o => ({ [`field_${o._id}`]: o.value })) : {}
    if (prevAttemp && prevAttemp.fields) prevAttemp.fields.forEach(field => {
        Object.assign(fields, { [`field_${field._id}`]: field.value })
    });


    return (<>
        <FormComponent
            // validate={values => {
            //     // console.log("validate: ", values)
            //     if (prevAttemp || !busy) {
            //         validateResults(values, 1).then(r => {
            //             if (r) {
            //                 console.log(__success("PASSED"))
            //                 // onSubmit(values);
            //             }
            //             else console.log(__error("FAIL"))
            //         });
            //     }
            // }}

            subscription={{ dirty: true, invalid: true, errors: true, values:true }}
            onSubmit={onSubmit} id='StepForm' loading={busy} debug={true} fields={{ ...fields }} moveOnError
            form_render={formProps => {
                const { values, submitting, form, invalid, dirty, valid } = formProps;

                return (<>
                    {prevAttemp && <AutoSaveForm debounce={100} save={save} busy={busy} />}

                    <div className='card'>
                        {(thisStep.passing_percent > 0 && test_form.hide_result_status !== 'yes') && <p align="right" style={{color:"#999"}}>Passing Percent: {thisStep.passing_percent}%</p>}
                        <RenderFormFields hide_result_status={test_form.hide_result_status} fields={thisStep.fields} prevAttemp={prevAttemp} />
                    </div>

                    <Row gutter={[50]}>
                        <Col span={12}>
                            {currentStep > 0 && <Button onClick={onBackClick} block size="large" loading={submitting}>Back</Button>}
                        </Col>
                        <Col span={12}>
                            <Button type="primary" block htmlType='submit' size="large" loading={submitting}>Next</Button>
                        </Col>
                    </Row>
                </>)
            }}
        />
    </>)
}



class TestFormFilable extends Component {
    state = { section: 1, busy: false, error: null, success: null, result: null, currentStep: 0, fatel_error: null, };

    constructor(props){
        super(props);
    }

    async onInfoSubmit(values) {
        console.log("onInfoSubmit()", values)
        // const { formData } = this.props;
        const { formData, client } = this.props;
        
        if (!values?.candiateInfo?.student_signature || values?.candiateInfo?.student_signature.length<10){
            return message.error("Please Sign in to proceed.");
        }

        this.setState({ busy: true, error: null });

        const input = {
            _id: formData._id,
            name: values.candiateInfo.name,
            phone_number: values.candiateInfo.phone_number,
            lc_number: values.candiateInfo.lc_number,
            student_signature: values?.candiateInfo?.student_signature || ""
        }

        await client.mutate({ mutation: UPDATE_CANDIDATE_INFO, variables: { input } }).then(r => {
            let response = r?.data?.updateCandidateInfo;
            // console.log("onInfoSubmit: ", response)

            if (!response || response.error) {
                this.setState({ busy: false, error: response && response?.error?.message || "Invalid Response!" });
                return false;
            }

            if (response.status == 'error') {
                message.error(response.message);
                this.setState({ busy: false, error: response.message });
            }

            this.props.updateForm(response)

            if (formData.course && formData.course._id) return this.setState({ busy: false, section: 2 });
            this.setState({ busy: false, section: 3 });


        }).catch(err => {
            console.log(__error("ERROR"), err);
            message.error("Failed to process the request!");
            this.setState({ busy: false, error: "Failed to process the request" });
            return false;
        });

    }

    async onStepSubmit(values, step_id) {
        console.log(__yellow("onStepSubmit()"))

        const { formData, test_form } = this.props;
        const { time_spent, currentStep } = this.state;

        if (values.type == 'signature') { // signature step
            alert("signature received");
            return;
        }

        this.setState({ busy: true, error: null });
        await sleep(100);

        const input = {
            rec_id: formData._id,
            _id: step_id,
            // step, //: currentStep + 1,
            formInput: JSON.stringify(values),
            time_spent: 100,
        }

        this.props.client.mutate({ mutation: SUBMIT_STEP, variables: { input } }).then(r => {
            let response = r?.data?.submitTestStep;

            if (!response || response.error) {
                this.setState({ busy: false, error: response && response?.error?.message || "Invalid Response!" });
                return false;
            }

            /***** test completed and passed */
            if (response?.success){
                window.onbeforeunload = undefined; // function () { return true; }
                return this.setState({ busy: false, success: response.success });
            }


            this.props.updateForm(response)

            let thiStepResponse = response.steps.find(o=>o._id==step_id);
            if (thiStepResponse.status == 'fail' && test_form.allow_redo_step == 'yes' && test_form.hide_result_status!=='yes'){
                return this.setState({ busy: false, result: "Sorry! you were unable to pass the test, please try again later." });
            }

            let hasNextStep = test_form.sections.length > currentStep+1;
            if (hasNextStep){
                this.setState({ busy: false, currentStep: currentStep+1 });
                var section = document.querySelector(`div[id='page_top']`);
                if (section) section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                return;
            }

            // this.setState({ busy: false, success: response });
            this.setState({ busy: false, success: "Thank you! test has been completed successfully" });
            

        }).catch(err => {
            console.log(__error("ERROR"), err);
            message.error("Failed to process the request!");
            this.setState({ busy: false, error: "Failed to process the request" });
            return false;
        });

    }

    async onCourseComplete(values){
        // console.log("onCourseComplete()", values);
        // alert("Course Completed, show test now");

        Modal.confirm({
            title: 'Course Completed',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you ready for accessment test!',
            onOk: () => {
                this.setState({ section: 3 })
            },
            onCancel() {
                console.log('Cancel');
            },
        });


        // this.setState({ section: 3 })
    }

    renderStep() {
        const { formData, test_form } = this.props;
        const { currentStep, busy, success, error, result } = this.state;
        let hasNextStep = test_form.sections.length > currentStep + 1;

        const thisStep = test_form.sections[currentStep];
        var prevSubmission = (formData && formData.steps) ? formData.steps.find(o => o._id == thisStep._id) : false;

        /// continue next if reattempt step is not allowed
        if (prevSubmission && test_form.allow_redo_step == 'no'){
            return <div>
                <Alert message={"Already completed"} description={<>
                    <p>You have already completed this section, move next</p>
                    <Button onClick={() => this.setState({ currentStep: currentStep + 1 })} disabled={!hasNextStep}>Next</Button>
                </>} type="info" showIcon />
            </div>
        }

        if (success){
            return <SuccessScreen success_message = {<>
                    <p stye={{ textAlign: "center" }}><span style={{ fontSize: "24px" }}>{success}</span></p>
                </>} />
        }

        if (prevSubmission && prevSubmission.status == 'pass' && test_form.allow_redo_step == 'no'){
            return <div>
                <Alert message={"Already passed"} description={<>
                    <p>You have already passed this section, move next</p>
                    <Button onClick={() => this.setState({ currentStep: currentStep + 1 })} disabled={!hasNextStep}>Next</Button>
                </>} type="success" showIcon />
            </div>
        }

        return (<div>
            {(prevSubmission && test_form.hide_result_status!=='yes') && <div style={{ color: "red" }}>Failed attempts: {prevSubmission.attempts.length}</div>}
            <StepForm 
                currentStep={currentStep}
                onBackClick={() => this.setState({ currentStep: (currentStep-1) })}
                onSubmit={(val) => this.onStepSubmit(val, thisStep._id)} 
                busy={busy} 
                thisStep={thisStep} 
                test_form={test_form}
                prevAttemp={prevSubmission} 
            />
        </div>)
    }

    printResults(){
        const { result } = this.state;
        const { test_form, formData } = this.props;

        if (!result) return null;

        if (test_form.allow_redo_step == 'yes'){
            return (<div style={{ display: 'flex', justifyContent: "center", paddingTop: "20px", paddingBottom: "20px" }}>
                <div style={{ maxWidth: "1020px" }}>
                    <div style={{ height: "50px" }} />
                    <Alert message={"Failed!"} description={<>
                        <div>{result}</div>
                        <Button onClick={() => this.setState({ result: null })}>Re-attempt</Button>
                    </>} type="info" showIcon />
                </div>
            </div>)
        }

        return <p>Impossible happened</p>;
    }

    render() {
        const { section, busy, error, success, result, currentStep } = this.state;
        const { test_form, formData } = this.props;
        console.log({ formData })

        if (!test_form) return <p>...</p>;
        // let hasNextStep = test_form.sections.length > currentStep + 1;
        if (!this.props.formData?._id) return <Alert message={"No data found ......!"} /*description={"error"}*/ type="error" showIcon />

        const { candiateInfo } = formData;


        if (success) return <SuccessScreen success_message={<>
            <p stye={{ textAlign: "center" }}><span style={{ fontSize: "24px" }}>{success}</span></p>
        </>} />


        if (section === 1) {
            return <UserInfoForm busy={busy} error={error} 
                title={formData?.test?.title}
                fields={{ candiateInfo }}
                onSubmit={(val) => this.onInfoSubmit(val)} 
            />
        }

        if (section === 2) return <CoursePlay course={formData.course} onCourseComplete={(val) => this.onCourseComplete(val)} />


        if (section === 3) {
            return(<>
                <Header title={test_form.title} right={<div>
                    <div>Name: {formData?.candiateInfo?.name}</div>
                    <div>Phone Number: {formData?.candiateInfo?.phone_number}</div>
                    <div>License Number: {formData?.candiateInfo?.lc_number}</div>
                </div>} />

                <div style={{ display: 'flex', justifyContent: "center", backgroundColor: "rgba(0,0,0,0.03)", paddingTop: "20px", paddingBottom: "20px" }}>
                    <div style={{ maxWidth: "1020px", width:"100%" }}>
                        <Steps current={currentStep}>
                            {test_form?.sections && test_form.sections.map((o, i)=>{
                                return <Steps.Step title={`Step ${i + 1}`} description={o.title} key={i} />
                            })}
                        </Steps>
                        <div style={{ height: "20px" }} id="page_top" />

                        {error && <Alert message={error} /*description={"error"}*/ type="error" showIcon />}

                        {!result && this.renderStep()}

                    </div>
                </div>

                {test_form.hide_result_status!=='yes' && this.printResults()}

                <div style={{ height: "300px" }} />

            </>)
        }


        return (<>
            <div>Impossible Happened!</div>
        </>)
    }
}

const WithApollo = withApollo(TestFormFilable)

const mapStateToProps = ({ formData }) => {
    return { formData };
};
const mapDispatchToProps = dispatch => ({
    // initForm: (payload) => dispatch(initForm(payload)),
    updateForm: (payload) => dispatch(updateForm(payload)),
    updateSteps: (payload) => dispatch(updateSteps(payload)),
    updateInfo: (payload) => dispatch(updateInfo(payload)),
    clearTest: (payload) => dispatch(clearTest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithApollo)
