import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Breadcrumb, Popconfirm, Alert, message, Row, Col, Input, Tag } from 'antd';
import { ContentArea } from "Layout_v1";
import { Table, Loader, Icon, Button, IconButton, DeleteButton, DevBlock, Drawer, Tabs } from 'Common/components';
import { PlusOutlined, HomeOutlined } from '@ant-design/icons';
import SiteFormDrawer from './components/SiteFormDrawer';
import { modulePermissions } from '.';
import SearchBar from './components/SearchBar'
import { defaultPageSize, siteStatusArray, uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import { ListHeader } from 'Common/components/Typography';
import SiteServiceManager from './components/SiteServiceManager'
import { addToPaginationArray, updatePaginationArray } from 'Common/scripts/query_helpers';
import SiteDetails from './components/SiteDetails'
import { Link } from 'react-router-dom';

const SITES_LIST = loader('src/Graphql/site/siteQuery.graphql');
const DELETE = loader('src/Graphql/site/deleteSite.graphql');

class SitesComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            busy: false,
            data: null,
            error: null,
            visible: false,
            pagination: { current: 1, pageSize: defaultPageSize },
            variables: null,
            showDetails: false,
            activeTab: siteStatusArray[0]._id,
            showDrawer:false,
            showServicesDrawer:false,
            initialData: null,
        }
        this.fetchData = this.fetchData.bind(this)
        this.onDeleteClick = this.onDeleteClick.bind(this)
        this.onUpdateSite = this.onUpdateSite.bind(this)
        this.onUpdate = this.onUpdate.bind(this)
        this.onAdd = this.onAdd.bind(this)
        this.onTabChange = this.onTabChange.bind(this)
   }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ current: pagination.current, pageSize: pagination.pageSize });
    };

    fetchData = async (args = {}) => {
        const variables = {
            first: args.pageSize || this.state.pagination.pageSize, // number of rec to fetch
            after: (args.pageSize || this.state.pagination.pageSize) * ((args.current || this.state.pagination.current) - 1),
            filter: JSON.stringify({ ...args.filter, status: this.state.activeTab } || { status: this.state.activeTab }),
            others: JSON.stringify({ sort: { name: 0 } })
        }

        this.setState({ loading: true, variables })

        this.props.client.query({ query: SITES_LIST, variables }).then(r => {
            const response = r.data.siteQuery;

            if (response.error) {
                this.setState({ loading: false, error: response.error.message });
                return;
            }

            this.setState({
                loading: false,
                data: response,
                pagination: {
                    ...this.state.pagination,
                    current: args.current || 1,
                    pageSize: args.pageSize || this.state.pagination.pageSize,
                    total: response.totalCount
                }
            });

        }).catch(err => {
            console.log("ERROR: ", err);
            this.setState({ busy: false, error: "Query Error" });
        })
    }

    onDeleteClick(_id){
        if (!modulePermissions['site.delete']) return;

        this.props.client.mutate({ mutation: DELETE, variables: { id: _id } }).then(r => {
            let response = r.data.deleteSite;

            if (response.error) {
                this.setState({ loading: false, error: response.error.message });
                return;
            }
            message.success("Site Removed");

            const __data = this.state.data.edges ? this.state.data.edges.slice() : [];
            const index = __data.findIndex(data => data._id === response._id)
            __data.splice(index, 1);
            const __totalCount = this.state.data.totalCount - 1;
            this.setState({ busy: false, data: { edges: __data, totalCount: __totalCount } })

        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            message.error("Failed to process the request!");
        })

    }

    toggleServices(site=false){
        this.setState({ showServicesDrawer: site })
    }

    toggleDetails(val = false) {
        this.setState({ showDetails: val })
    }

    columns = () => {
        let cols = [
            {
                title: 'Site Name', dataIndex: 'name', width: 300, render: (__, rec) => (<>
                    {/* <div onClick={() => this.toggleDetails(rec)} className='a'>{rec.name}</div> */}
                    <Link to={`${uriRoot}/sites/${rec._id}`}>{rec.name}</Link>
                    <div style={{ fontSize: '12px', color: "#999" }}>Billing name: {rec.billing_name}</div>
                    <span className='hidden-menu'>
                        {/* {modulePermissions['site.update'] && <IconButton className="item" icon="pen" onClick={() => this.toggeleDrawer(rec)} />} */}
                        {modulePermissions['site.delete'] && <DeleteButton className="item" onConfirm={() => this.onDeleteClick(rec?._id)} />}
                    </span>
                </>)
            },
            { title: 'Client name', render: (___, rec) => (rec?.client?.name), width: "300px" },
            {
                title: 'Services Allowed', dataIndex: "note", render: (___, rec) => {
                    return (<Row>
                        {rec?.services?.map((service, i) => (<Col className='service-tag' key={i}>{service?.service_type?.title}</Col>))}
                    </Row>)

                    // return (<Row>
                    //     <Col><Row>
                    //         {rec?.services?.map((service, i) => (<Col className='service-tag' key={i}>{service?.service_type?.title}</Col>))}
                    //     </Row></Col>
                    //     <Col><div style={{ position: "relative", display: "inline-block" }}>
                    //         <span className='hidden-menu left'>
                    //             {modulePermissions['site.manage-services'] && <IconButton className="item" icon="pen" onClick={() => this.toggleServices(rec)} />}
                    //         </span>
                    //     </div></Col>
                    // </Row>)
                }
            },
            { title: 'Status', render: (___, rec) => (<Tag color={rec.status == 'active' ? '#87d068' : '#F00'}>{rec.status}</Tag>), width: "100px" },

        ];

        return cols;
    }

    toggeleDrawer(initialData=null){
        this.setState({ showDrawer: !this.state.showDrawer, initialData })
    }

    onUpdateSite(site){
        var data = {
            ...this.state.data,
            edges: this.state.data.edges.map(o=>o._id==site._id ? site : o)
        };

        this.setState({ data })
    }

    onUpdate(node) {
        console.log("onUpdate()", node)
        const { data, variables } = this.state;

        let _data = updatePaginationArray({
            props: { siteQuery: data, client: this.props.client },
            queryName: "siteQuery",
            node,
            query: SITES_LIST,
            variables
        })

        this.setState({ data: _data })
    }

    onAdd(node) {
        const { data, variables } = this.state;

        let _data = addToPaginationArray({
            props: { siteQuery: data, client: this.props.client },
            queryName: "siteQuery",
            node,
            query: SITES_LIST,
            variables
        })

        this.setState({ data: _data })
    }

    onTabChange(_activeTab){
        this.setState({ activeTab: _activeTab }, ()=>{
            this.fetchData({})
        })
    }

    render() {
        const { loading, error, data, pagination, showDrawer, showServicesDrawer, initialData, showDetails, activeTab } = this.state;

        // if (loading) return <Loader style={{ margin: "auto" }} loading={true} />
        if (error) return <Alert message="Error" description={error} type="error" showIcon />
        return (<>
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item><Icon icon="map-marker-alt" size="1x" /><b> Sites</b></Breadcrumb.Item>
            </Breadcrumb>


            <Tabs onChange={this.onTabChange} style={{ margin: "5px 0 5px 20px", padding: "0" }} size="large" activeTab={activeTab} tabsArray={siteStatusArray.map(o=>({ key: o._id, title: o.title }))}></Tabs>
            
            <ContentArea style={{ marginTop:0, }}>
                {modulePermissions['site.add'] &&
                    <Row>
                        <Col flex={"auto"}>
                            <ListHeader totalCount={(data && data.totalCount) || '0'} title='Sites' />
                        </Col>
                        <Col><Button onClick={() => this.toggeleDrawer()}>Add Site</Button></Col>
                    </Row>
                }

                <div style={{ marginTop: "10px", padding: "0 10px 0 10px", border: "1px solid #EEE", borderTopRightRadius: "5px", borderTopLeftRadius: "5px", backgroundColor: "rgba(0, 0, 0, 0.02)" }}>
                    <SearchBar fetchData={(args) => this.fetchData(args)} />
                </div>

                <Table loading={loading}
                    // title={() => <b>Total {((data && data.totalCount) || '0')} record(s) found </b>}
                    columns={this.columns()}
                    dataSource={data ? data.edges : null}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    expandable={{
                        expandedRowRender: record => <div style={{ margin: 0 }}>
                            <p>{record.note}</p>
                        </div>,
                        // rowExpandable: record => record.comments && record.comments.length > 0,
                    }}

                />
            </ContentArea>

            <SiteFormDrawer onUpdate={this.onUpdate} onAdd={this.onAdd} 
                showDrawer={showDrawer} initialData={initialData} onClose={() => this.toggeleDrawer()} />

            {modulePermissions['site.manage-services'] &&
                <SiteServiceManager 
                    show={showServicesDrawer !== false} 
                    site={showServicesDrawer} 
                    onUpdate={(val) => {
                        console.log("onUpdate()", val);
                        this.onUpdateSite(val)
                        this.toggleServices(false);
                    }}
                    onClose={() => this.toggleServices(false)}
                />
            }

            <Drawer autoFocus={true} destroyOnClose={true} maskClosable={false} placement='right' transparent
                footer={false}
                title={`${showDetails ? showDetails.name : ""}`} // Site Details
                width={'100%'}
                onClose={() => this.toggleDetails()}
                visible={this.state.showDetails}
                bodyStyle={{ paddingBottom: 80 }}
            // extra={<Button size="small" onClick={onClose}>Cancel</Button>}
            >
                {this.state.showDetails && <SiteDetails inline match={{ params: { id: this.state.showDetails._id } }} onClose={() => this.toggleDetails()} />}
            </Drawer>


        </>)
    }
}

export const Sites = withApollo(SitesComp)
export default Sites;