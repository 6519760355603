import React, { useState, useEffect } from 'react'
import { loader } from 'graphql.macro';
import { Drawer, Space, Alert, message, Row, Col } from 'antd';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Loader, Button } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import { withApollo } from '@apollo/client/react/hoc';
import { getmodulePermissions } from 'Common/scripts/Security';
const modulePermissions = getmodulePermissions('course-mg');

const GET_COURSE = loader('src/Graphql/Courses/course.graphql');
const ADD_COURSE = loader('src/Graphql/Courses/addCourse.graphql');
const EDIT_COURSE = loader('src/Graphql/Courses/editCourse.graphql');


const CoruseForm = ({ client, editNode, onClose }) => {
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);

    const updateRecord = (input) => {
        if (!modulePermissions['course-mg.edit']) return;

        setBusy(true);

        client.mutate({ mutation: EDIT_COURSE, variables: { input } }).then(r => {
            let response = r.data.editCourse;

            if (response.error) {
                setBusy(false);
                setError(response.error.message);
                return;
            }
            message.success("Saved!");
            onClose()

        }).catch(err => {
            console.log("ERROR: ", err);
            message.error("Failed to process the request!");
            setBusy(false);
        })
    }

    const addRecord = (input) => {
        if (!modulePermissions['course-mg.add']) return;

        setBusy(true);

        client.mutate({ mutation: ADD_COURSE, variables: { input } }).then(r => {
            let response = r.data.addCourse;

            if (response.error) {
                setBusy(false);
                // setError(response.error.message);
                message.error(response.error.message)
                return;
            }
            message.success("Course Added!");
            window.location.href = `courses/id/${response._id}`
            // onClose()

        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            message.error("Failed to process the request!");
            setError("Failed to process the request!")
            setBusy(false);
        })

    }

    const onSubmitCourse = (values) => {
        const filteredData = {
            title: values.title,
        }

        if (editNode && editNode._id) {
            Object.assign(filteredData, { _id: editNode._id });
            updateRecord(filteredData);
        } else {
            addRecord(filteredData);
        }

    }

    if (error) return <Alert message="Error" description={error} type="error" showIcon />

    return(<>

        <FormComponent onSubmit={onSubmitCourse} id='CourseForm' loading={busy} fields={{ ...editNode }}
            mutators={{
                selectClient: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    // let drivers = state.formState.values.drivers || [];
                    // drivers.push({ _id: node.value, name: node.children })
                    let _client = { _id: node.value, name: node.children };

                    tools.changeValue(state, 'client', () => _client);
                },
                de_selectClient: (newValueArray, state, tools) => {
                    // let drivers = state.formState.values.drivers || [];
                    // let drivers_ids = state.formState.values.drivers_ids || [];
                    // drivers = drivers.filter(o => (drivers_ids.includes(o._id)))

                    tools.changeValue(state, 'client', () => undefined)
                },
            }}
            form_render={formProps => {
                const { values, form, invalid, dirty, valid } = formProps;

                return (<>
                    <Row>
                        <Col flex="auto"><FormField placeholder="Course Title" name="title" type="text" compact validate={rules.required} /></Col>
                        <Col><Button type="primary" size="medium" htmlType='submit' disabled={!dirty || !valid} >Save</Button></Col>
                    </Row>

                </>)
            }}
        />

    </>)
}


const CoruseFormWrapper = props => {
    const [error, setError] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [busy, setBusy] = useState(false)
    const [data, setData] = useState(null)
    const { editNode } = props;
    
    const toggeleDrawer = () => {
        if (showForm){
            setBusy(false);
            setError(false);
            setData(null);
        }
        setShowForm(!showForm)
    }

    const fetchCourse = args => {
        props.client.query({ query: GET_COURSE, variables: { id: editNode._id } }).then(r => {
            const response = r.data.course;
            setBusy(false);

            if (response.error) {
                setError(response.error.message);
                return;
            }

            setData(response);

        }).catch(err => {
            console.log("ERROR: ", err);
            setBusy(false);
            setError("Query Error")
        })

    }

    useEffect(() => {
        if (editNode && editNode._id){
            setBusy(true)
            fetchCourse();
            toggeleDrawer();
        }
    //   return () => {
    //     effect
    //   };
    }, [editNode])


    return(<>
        <Row>
            <Col flex={"auto"} align="right"><Button onClick={() => toggeleDrawer()}>Create Course</Button></Col>
        </Row>

        <Drawer
            placement="top"
            title={editNode ? "Edit Course" : "Create New Course"}
            height={"250px"}
            onClose={() => toggeleDrawer()}
            visible={showForm}
            // bodyStyle={{ paddingBottom: 80 }}
            // extra={<Button onClick={onClose}>Cancel</Button>}
            destroyOnClose={true}
        ><>
                {busy && <Loader loading={true} />}
                {error && <Alert message="Error" description={error} type="error" showIcon />}
                {(!busy && showForm) && <CoruseForm editNode={{ ...data }} client={props.client} onClose={()=>toggeleDrawer()} />}

        </></Drawer>

    </>)


}

export default withApollo(CoruseFormWrapper)