import _ from 'lodash'

export const removeFromArray = (props, arrayName, id, query, variables) => {
    let data = props[arrayName] ? props[arrayName].slice() : [];
    data = data.filter(o => o._id != id);

    props.client.writeQuery({
        query,
        variables, //: { filter: JSON.stringify({ category: props.category }) },
        data: {
            [arrayName]: data,
        },
    });

}

export const updateToArray = (props, arrayName, node, query, variables) => {
    let data = props[arrayName] ? props[arrayName].slice() : [];
    data = data.map(o => {
        if (o._id == node.id) return node;
        return o
    })

    props.client.writeQuery({
        query,
        variables, //: { filter: JSON.stringify({ category: props.category }) },
        data: {
            [arrayName]: data,
        },
    });

}

/****
 * Usage
 addToArray(props, 'filteredViews', response, RECORDS, { filter: JSON.stringify({ category: props.category }) });
 */
export const addToArray = (props, arrayName, node, query, variables) => {
    let data = props[arrayName] ? props[arrayName].slice() : [];
    data.push(node);

    props.client.writeQuery({
        query,
        variables, //: { filter: JSON.stringify({ category: props.category }) },
        data: {
            [arrayName]: data,
        },
    });

}

/****
 * Usage
 addToPaginationArray(props, 'myQueryName', response, RECORDS, { filter: JSON.stringify({ category: props.category }) });
 */
export const addToPaginationArray = ({ props, queryName, node, query, variables }) => {
    // console.log("addToPaginationArray()");
    if (!props.client) return alert("Missing client for addToPaginationArray")

    let data = props[queryName] ? _.cloneDeep(props[queryName]) : {};
        data.edges.push(node);
    // {
    //     edges: [],
    //     pageInfo:{
    //         endCursor: null,
    //         hasNextPage: false,
    //         __typename: "PageInfo"
    //     },
    //     totalCount: 0,
    //     __typename: queryName
    // };
    // Object.assign(data, { edges: data.edges.push(node) });

    props.client.writeQuery({
        query,
        variables, //: { filter: JSON.stringify({ category: props.category }) },
        data: {
            [queryName]: data,
        },
    });

    return data;

}
export const updatePaginationArray = ({ props, queryName, node, query, variables }) => {
    // console.log("updateToPaginationArray()");
    if (!props.client) return alert("Missing client for updatePaginationArray")

    let data = props[queryName] ? _.cloneDeep(props[queryName]) : {};
    data.edges = data.edges.map(o => ((o._id == node._id) ? node : o))
    // Object.assign(data, {  edges: data.edges.map(o => ((o._id == node._id) ? node : o)) });

    props.client.writeQuery({
        query,
        variables, //: { filter: JSON.stringify({ category: props.category }) },
        data: {
            [queryName]: data,
        },
    });

    return data;
}
