import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import { Loader } from './Loader'
import { Divider, Tag } from 'antd';

const TAGS_LIST = loader('src/Graphql/tags/tags.graphql');

export const TagsAvailable = ({ filter, targetUrl, title, width }) => {
    const [getTags, { called, loading, data }] = useLazyQuery(
        TAGS_LIST, { variables: { filter: JSON.stringify(filter), others: JSON.stringify({}) } }
    );

    useEffect(() => {
        if (called || loading || data || !filter || !targetUrl) return;
        getTags();
    }, [])

    if (loading) return <Loader loading={true} />
    if(!data) return null;

    return (<div style={{ width: width || '250px' }}>
        <Divider style={{ marginTop: "2px" }}>{title || 'Tags'}</Divider>
        {data && data?.tags?.map((item, i) => {
            return <Tag key={i}><a style={{ fontSize: "12px" }} href={targetUrl(item.title)}>{item.title}</a></Tag>
        })}
    </div>)
}
TagsAvailable.propTypes = {
    width: PropTypes.string,
    filter: PropTypes.object.isRequired,
    targetUrl: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
}

