/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import { Row, Col, message, Result, Divider, Upload } from 'antd'
import { loader } from 'graphql.macro';
import arrayMutators from 'final-form-arrays'
import axios from 'axios'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Button, Icon } from 'Common/components'
import Header from '../Components/Header';
import { __error } from 'Common/scripts/consoleHelper';
import { SECURITY_GUARD_INTERVIEW } from '../../constants';
import { withApollo } from '@apollo/client/react/hoc';
import { ListHeader } from 'Common/components/Typography';

const ADD_DATA = loader('src/Graphql/FormsData/addFormsData.graphql');
export const FORM_KEY = SECURITY_GUARD_INTERVIEW;

const dummyData = {
  "l_name": "asd asd",
  "f_name": " sa dasd sa",
  "m_name": "as das dsad",
  "position": {
    "lookingfor": {
      "mobile_patrol": true
    },
    "availability_for_work": {
      "on_call": true,
      "part_time": false,
      "full_time": true,
    },
    "availability_for_shift": {
      "night": true
    },
    "others": {
      "notes": "s ad sdasdsad",
      "date_to_start": "2023-01-04T13:43:41+05:00",
      "special_notes": "asd as dsa dsad "
    }
  },
  "availability": {
    "morning_mon": true,
    "afternoon_tue": true,
    "night_wed": true,
    "afternoon_thu": true,
    "morning_fri": true,
    "afternoon_sat": true,
    "afternoon_sun": true,
    "morning_sun": true
  },
  "edu": {
    "security_lc_no": "asd as das dsa dsad",
    "designation1": "as dasd asd as",
    "designation2": "as das dasda",
    "designation3": "asd asdasd",
    "designation4": "as dasd",
    "designation5": "as dasd asd sad",
    "special_skills": "as da das dasd asd as",
    "lc_no_mob_p_only": "da sd asdasd asd"
  },
  "office_use": {
    "date_interviewed": "2023-01-09T13:44:10+05:00",
    "photos": {
      "driver_license": true,
      "direct_deposit_form": true,
      "sin_card": true,
      "other": true
    },
    "gender": "female",
    "all_fields_filled": true,
    "check_legibility": true,
    "have_resume": true,
    "yrs_in_security_industry": "as das dasda",
    "mobile_driving_yrs": "a das dasd",
    "have_own_car": "as ds das",
    "in_calgary": "as dasd as",
    "know_about_calgary": "as dasd asd",
    "is_permanent_resident": true,
    "is_citizen": true,
    "work_at_christmas": true,
    "special_holidays": "as das das das",
    "pant_shirt_size": "asd a dasd",
    "other_notes": "asd asd as dsad asd"
  }
}

const SuccessScreen = () => {
  return (<div style={{ backgroundColor: '#001529', flex: 1, justifyContent: "center", alignItems: "center", display: 'flex' }}>
    <Result
      status="success"
      title={<span style={{ color: 'white' }}>Security Guard Job Application Submitted Successfully!</span>}
      extra={<img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-light.png`} />}
    // extra={<img src="https://sp-ao.shortpixel.ai/client/q_glossy,ret_img/https://alistsecurity.com/wp-content/uploads/2021/03/Website-Logo-Dark-Ver.-2.png" />}
    />
  </div>)
}

class TheFormComp extends Component {
  signaturePad = {}

  constructor(props) {
    super(props)
    this.state = { loading: false, data: null, success: false, signatureDataURL: null, fileList: [], }
    this.onSubmit = this.onSubmit.bind(this);
  }

  clearSignature = (event) => {
    event.preventDefault()
    this.signaturePad.clear()
    if (this.state.signatureDataURL !== null) this.setState({ signatureDataURL: null })
  }

  trimSignature = (event) => {
    event.preventDefault()
    const signatureDataURL = this.signaturePad.getTrimmedCanvas().toDataURL('image/png');
    this.setState({ signatureDataURL })
  }

  async handleUpload(_id) {
    const { fileList } = this.state;

    if (!fileList || !fileList.length<1){
      alert("No files found to upload!!")
      return false;
    }

    var _files = fileList.slice()
    if (fileList.length > 6) {
      _files = _files.slice(0, 6)
    }
    else _files = this.state.fileList

    const formData = new FormData();
    formData.append('action', "job.application.attachments");
    formData.append('_id', _id);
    _files.forEach(file => {
      formData.append('files[]', file);
    });

    return axios.post(process.env.REACT_APP_API_URL + '/upload/job_application/attachments', formData)
      .then(({ data }) => {
        console.log(data);
        console.log("form data of the file list going back to db", formData)
        return data;
      })
      .catch(function (error) {
        console.log(error);
        return { error: { message: "Request Error!" } };
      });
  };

  async onSubmit(values) {
    const input = { ref_key: FORM_KEY, title: `${values.f_name}` }

    const fields = [
      { name: 'f_name', label: 'First name', value: values.f_name },
      { name: 'm_name', label: 'Middle name', value: values.m_name },
      { name: 'l_name', label: 'Last name', value: values.l_name },
      
      
      { name: 'position', label: 'Looking for a position as', value: JSON.stringify({
          lookingfor:[
            { name: 'mobile_patrol', label: 'Mobile Patrol', value: values.position.lookingfor.mobile_patrol ? 'yes' : 'no' },
            { name: 'static_guard', label: 'Static Guard', value: values.position.lookingfor.static_guard ? 'yes' : 'no' },
          ],
          availability_for_work:[
            { name: 'full_time', label: 'Full Time', value: values.position.availability_for_work.full_time ? 'yes' : 'no' },
            { name: 'part_time', label: 'Part Time', value: values.position.availability_for_work.part_time ? 'yes' : 'no' },
            { name: 'on_call', label: 'On Call', value: values.position.availability_for_work.on_call ? 'yes' : 'no' },
          ],
          availability_for_shift:[
            { name: 'day', label: 'Day', value: values.position.availability_for_shift.day ? 'yes' : 'no' },
            { name: 'night', label: 'Night', value: values.position.availability_for_shift.night ? 'yes' : 'no' },
            { name: 'rotating', label: 'Rotating', value: values.position.availability_for_shift.rotating ? 'yes' : 'no' },
          ],
          others: [
            { name: 'date_to_start', label: 'Date availability to start', value: values.position.others.date_to_start },
            { name: 'notes', label: 'Notes', value: values.position.others.notes },
            { name: 'special_notes', label: 'Any special times to note?', value: values.position.others.special_notes },
          ]
        })
      },

      // Availability
      { name: 'availability', label: 'Availability', value: JSON.stringify({
          mon: [(values.availability.morning_mon ? "morning" : ""), (values.availability.afternoon_mon ? "afternoon" : ""), (values.availability.night_mon ? "night" : "")],
          tue: [(values.availability.morning_tue ? "morning" : ""), (values.availability.afternoon_tue ? "afternoon" : ""), (values.availability.night_tue ? "night" : "")],
          wed: [(values.availability.morning_wed ? "morning" : ""), (values.availability.afternoon_wed ? "afternoon" : ""), (values.availability.night_wed ? "night" : "")],
          thu: [(values.availability.morning_thu ? "morning" : ""), (values.availability.afternoon_thu ? "afternoon" : ""), (values.availability.night_thu ? "night" : "")],
          fri: [(values.availability.morning_fri ? "morning" : ""), (values.availability.afternoon_fri ? "afternoon" : ""), (values.availability.night_fri ? "night" : "")],
          sat: [(values.availability.morning_sat ? "morning" : ""), (values.availability.afternoon_sat ? "afternoon" : ""), (values.availability.night_sat ? "night" : "")],
          sun: [(values.availability.morning_sun ? "morning" : ""), (values.availability.afternoon_sun ? "afternoon" : ""), (values.availability.night_sun ? "night" : "")],
        })
      },

      { name: 'edu', label: 'Education and Training', value: JSON.stringify([
          { name: 'security_lc_no', label: 'Security License Number: WO', value: values.edu.security_lc_no },    
          { name: 'designation1', label: '1', value: values.edu.designation1 },
          { name: 'designation2', label: '2', value: values.edu.designation2 },
          { name: 'designation3', label: '3', value: values.edu.designation3 },
          { name: 'designation4', label: '4', value: values.edu.designation4 },
          { name: 'designation5', label: '5', value: values.edu.designation5 },
          { name: 'special_skills', label: 'Special Skills', value: values.edu.special_skills },
          { name: 'lc_no_mob_p_only', label: 'License Number (Mobile Patrol Only)', value: values.edu.lc_no_mob_p_only },
        ])
      },
      
      {
        name: 'office_use', label: 'Office Use', value: JSON.stringify([
          { name: 'date_interviewed', label: 'Date interviewed', value: values.office_use.date_interviewed },
          { name: 'gender', label: 'Gender', value: values.office_use.gender },
          
          { name: 'photos', label: 'Have you taken a photo copy of:', value: JSON.stringify([
            { name: 'driver_license', label: 'Driver License', value: values.office_use.photos.driver_license ? 'yes' : 'no' },
            { name: 'security_license', label: 'Security License', value: values.office_use.photos.security_license ? 'yes' : 'no' },
            { name: 'sin_card', label: 'SIN Card', value: values.office_use.photos.sin_card ? 'yes' : 'no' },
            { name: 'canadian_citizenship', label: 'Canadian Citizenship', value: values.office_use.photos.canadian_citizenship ? 'yes' : 'no' },
            { name: 'other', label: 'Other', value: values.office_use.photos.other ? 'yes' : 'no' },
          ]) },
          
          { name: 'all_fields_filled', label: 'Have they filled out all of the fields required?', value: values.office_use.all_fields_filled ? 'yes' : 'no' },
          { name: 'check_legibility', label: 'Check legibility of form and rewrite anything properly if need be.', value: values.office_use.check_legibility ? 'yes' : 'no' },
          { name: 'have_resume', label: 'Do they have a resume?', value: values.office_use.have_resume ? 'yes' : 'no' },
          
          { name: 'yrs_in_security_industry', label: 'How many years have you been in security industry?', value: values.office_use.yrs_in_security_industry },
          { name: 'mobile_driving_yrs', label: 'How long have you been driving? (Mobile only)', value: values.office_use.mobile_driving_yrs },
          { name: 'have_own_car', label: 'Do you have your own car?', value: values.office_use.have_own_car },
          { name: 'in_calgary', label: 'How long have you been in Calgary?', value: values.office_use.in_calgary },
          { name: 'know_about_calgary', label: 'How well do you know the area of Calgary?', value: values.office_use.know_about_calgary },
    
          { name: 'is_citizen', label: 'Are you a citizen?', value: values.office_use.is_citizen ? 'yes' : 'no' },
          { name: 'is_permanent_resident', label: 'Are you a permanent resident?', value: values.office_use.is_permanent_resident ? 'yes' : 'no' },
          { name: 'is_valid_work_permit', label: 'Do you have a valid work permit?', value: values.office_use.is_valid_work_permit ? 'yes' : 'no' },
          
          { name: 'work_at_christmas', label: 'Are you available to work Christmas and New Years?', value: values.office_use.work_at_christmas ? 'yes' : 'no' },
          { name: 'special_holidays', label: 'Are there any special holidays throughout the year that you are not able to work? (Provide Dates)', value: values.office_use.special_holidays },
          { name: 'pant_shirt_size', label: 'What size of Pants and Shirt do you take? S, M, L, XL', value: values.office_use.pant_shirt_size },
          { name: 'other_notes', label: 'Other Notes', value: values.office_use.other_notes },
          
        ])
      },
      
    ]
    Object.assign(input, { fields });

    // if (!this.state.signatureDataURL){
    //   alert("Oops! you forgot to sign your application!")
    //   return;
    // }

    this.setState({ loading:true });

    const added = await this.props.client.mutate({ mutation: ADD_DATA, variables: { input } }).then(r => {
      let response = r.data.addFormsData;

      if (!response || response.error) { 
        this.setState({ loading: false, error: response && response?.error?.message || "Invalid Response!" }); 
        return false;
      }

      this.setState({ loading: false, success: response })

    }).catch(err => {
      console.log(__error("ERROR"), err);
      message.error("Failed to process the request!");
      return false;
    });

    if (!added) return;

  }


  render() {
    const { busy, error, success } = this.state;

    if (success) return <SuccessScreen />

    return (<>
      <div style={{ backgroundColor: "#f8f9fa" }} className="form-data">
        <Header title="Security Guard Interview Form" />

        <div style={{ display: 'flex', justifyContent: "center" }}>
          <div style={{ maxWidth: "1200px", width: "100%", margin: "20px" }}>

            <FormComponent onSubmit={this.onSubmit} id='ClientInfoForm' loading={busy} debug={true} fields={{ ...dummyData }} moveOnError
              mutators={{ 
                ...arrayMutators,
                // selectClient: (newValueArray, state, tools) => {
                //   let node = newValueArray[0];
                //   let _client = { _id: node.value, name: node.children };
                //   tools.changeValue(state, 'client', () => _client);
                // },
              }}
              form_render={formProps => {
                const { values, form, invalid, dirty, valid } = formProps;
                // if (formProps.errors && formProps.submitFailed) this.moveToErrorPosition(formProps.errors);

                return (<>
                  <div className='card'>
                    <ListHeader pre={<Icon icon="user-alt" color={"#001529"} />} title={<>General Information</>} style={{ marginBottom: "20px" }} />
                    <Row >
                      <Col flex="35%">
                        <FormField name="l_name" label="Last name" size="medium" type="text" validate={rules.required} />
                      </Col>
                      <Col flex="35%"><FormField label="First name" name="f_name" size="medium" type="text" /></Col>
                      <Col flex="auto"><FormField label="Middle Int." name="m_name" size="medium" type="text" validate={rules.required} /></Col>
                    </Row>
                  </div>

                  <div style={{ height: '20px' }} />
                  <div className='card'>
                    <Row align="top">
                      <Col flex="650px">
                        <ListHeader pre={<Icon icon="flag" color={"#001529"} />} title={<>Position</>} sub="Check all that apply" style={{ marginBottom:"20px" }} />
                        <Row>
                          <Col flex="35%">
                            <div><b>Looking for a position as:</b></div>
                            <FormField name="position.lookingfor.mobile_patrol" label="Mobile Patrol" size="medium" type="switch" />
                            <FormField name="position.lookingfor.static_guard" label="Static Guard" size="medium" type="switch" />
                            {/* <FormField name="position.lookingfor.doorman_bouncer" label="Doorman/Bouncer" size="medium" type="switch" /> */}
                          </Col>
                          <Col flex="35%">
                            <div><b>Availbility to work:</b></div>
                            <FormField name="position.availability_for_work.full_time" label="Full Time" size="medium" type="switch" />
                            <FormField name="position.availability_for_work.part_time" label="Part Time" size="medium" type="switch" />
                            <FormField name="position.availability_for_work.on_call" label="On Call" size="medium" type="switch" />
                          </Col>
                          <Col flex="auto">
                            <div><b>Availability for shifts:</b></div>
                            <FormField name="position.availability_for_shift.day" label="Day" size="medium" type="switch" />
                            <FormField name="position.availability_for_shift.night" label="Night" size="medium" type="switch" />
                            <FormField name="position.availability_for_shift.rotating" label="Rotating" size="medium" type="switch" />
                          </Col>
                        </Row>
                      </Col>
                      <Col flex="auto">
                        <FormField name="position.others.date_to_start" label="Date availability to start" size="medium" type="date" />
                        <FormField name="position.others.notes" label="Notes" size="medium" type="textarea" />
                      </Col>
                    </Row>

                    <ListHeader title={<>Days Available to work</>} style={{ marginTop: "20px" }} />
                    <Row className='dark' align="middle">
                      {['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
                        return <Col flex="12.5%" className="bold" key={index}>{day}</Col>
                      })}
                    </Row>
                    <Row className='simple' align="middle">
                      {['Morning', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                        if (i == 0) return <Col flex="12.5%" className="bold" key={i}>{day}</Col>;
                        return <Col flex="12.5%" key={i}><FormField compact name={`availability.morning_${day}`} type='switch' /></Col>
                      })}
                    </Row>
                    <Row className='dark' align="middle">
                      {['Afternoon', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                        if (i == 0) return <Col flex="12.5%" className="bold" key={i}>{day}</Col>;
                        return <Col flex="12.5%" key={i}><FormField compact name={`availability.afternoon_${day}`} type='switch' /></Col>
                      })}
                    </Row>
                    <Row className='simple' align="middle">
                      {['Night', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                        if (i == 0) return <Col flex="12.5% " className="bold" key={i}>{day}</Col>;
                        return <Col flex="12.5%" key={i}><FormField compact name={`availability.night_${day}`} type='switch' /></Col>
                      })}
                    </Row>

                    <FormField label="Any special times to note?" name="position.others.special_notes" size="medium" type="text" />

                  </div>

                  <div style={{ height: '20px' }} />
                  <div className='card'>
                    <ListHeader pre={<Icon icon="user-graduate" color={"#001529"} />} title={<>Education and Training</>} style={{ marginBottom: "20px" }} />
                    <FormField label="Security License Number: WO" name="edu.security_lc_no" size="medium" type="text" />
                    <Row gutter={[20, 0]}>
                      <Col flex="20px"></Col>
                      <Col style={{paddingTop:'13px'}}><b>Designation</b></Col>
                      <Col flex="auto">
                        <Row gutter={[10, 0]} align="middle">
                          <Col>1</Col><Col flex="auto"><FormField name="edu.designation1" size="medium" type="text" /></Col>
                        </Row>
                        <Row gutter={[10, 0]} align="middle">
                          <Col>2</Col><Col flex="auto"><FormField name="edu.designation2" size="medium" type="text" /></Col>
                        </Row>
                        <Row gutter={[10, 0]} align="middle">
                          <Col>3</Col><Col flex="auto"><FormField name="edu.designation3" size="medium" type="text" /></Col>
                        </Row>
                        <Row gutter={[10, 0]} align="middle">
                          <Col>4</Col><Col flex="auto"><FormField name="edu.designation4" size="medium" type="text" /></Col>
                        </Row>
                        <Row gutter={[10, 0]} align="middle">
                          <Col>5</Col><Col flex="auto"><FormField name="edu.designation5" size="medium" type="text" /></Col>
                        </Row>
                      </Col>
                    </Row>

                    <FormField label="Special Skills" name="edu.special_skills" size="medium" type="text" />
                    <FormField label="License Number (Mobile Patrol Only)" name="edu.lc_no_mob_p_only" size="medium" type="text" />
                  </div>

                  <div style={{ height: '20px' }} />
                  <div className='card'>
                    <ListHeader pre={<Icon icon="file" color={"#001529"} />} title={<>Office Use</>} style={{ marginBottom: "20px" }} />

                    <Row>
                      <Col flex="300px"><FormField label="Date interviewed" name="office_use.date_interviewed" size="medium" type="date" /></Col>
                      <Col flex="auto">
                        <FormField label={<b>Gender?</b>} horizontal data={[
                          { label: 'Female', value: 'female' }, { label: 'Male', value: 'male' },
                        ]} name="office_use.gender" size="medium" type="radio" />
                      </Col>
                    </Row>
                    <hr color="#EEE" />

                    <Row style={{ flexWrap: 'nowrap', marginBlock: '25px' }}>
                      <Col flex="300px"><b>Have you taken a photo copy of: </b></Col>
                      <Col flex="auto">
                        <Row gutter={[12, 12]}>
                          <Col flex="33%"><FormField compact label="Driver License" name="office_use.photos.driver_license" size="medium" type="switch" /></Col>
                          <Col flex="33%"><FormField compact label="Security License" name="office_use.photos.security_license" size="medium" type="switch" /></Col>
                          <Col flex="33%"><FormField compact label="SIN Card" name="office_use.photos.sin_card" size="medium" type="switch" /></Col>
                          <Col flex="33%"><FormField compact label="Canadian Citizenship" name="office_use.photos.canadian_citizenship" size="medium" type="switch" /></Col>
                          <Col flex="33%"><FormField compact label="Direct deposit form" name="office_use.photos.direct_deposit_form" size="medium" type="switch" /></Col>
                          <Col flex="33%"><FormField compact label="Other" name="office_use.photos.other" size="medium" type="switch" /></Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr color="#EEE" />


                    <Row style={{ flexWrap: 'nowrap', marginBlock: '25px' }}>
                      <Col flex="300px"><b>Have they filled out all of the fields required?</b></Col>
                      <Col><FormField label="Yes" compact name="office_use.all_fields_filled" size="medium" type="switch" /></Col>
                    </Row>
                    <hr color="#EEE" />

                    <Row style={{ flexWrap: 'nowrap', marginBlock: '25px' }}>
                      <Col flex="300px"><b>Check legibility of form and rewrite anything properly if need be.</b></Col>
                      <Col><FormField label="Yes" compact name="office_use.check_legibility" size="medium" type="switch" /></Col>
                    </Row>
                    <hr color="#EEE" />

                    <Row style={{ flexWrap: 'nowrap', marginBlock: '25px' }} gutter={[12, 12]}>
                      <Col flex="300px"><b>Do they have a resume?</b></Col>
                      <Col><FormField label="Yes" compact name="office_use.have_resume" size="medium" type="switch" /></Col>
                    </Row>
                    <hr color="#EEE" />

                    <Row gutter={[12, 12]} style={{ marginBlock: '50px' }}>
                      <Col flex="33%"><FormField label="How many years have you been in security industry?" compact name="office_use.yrs_in_security_industry" size="medium" type="text" /></Col>
                      <Col flex="33%"><FormField label="How long have you been driving? (Mobile only)" compact name="office_use.mobile_driving_yrs" size="medium" type="text" /></Col>
                      <Col flex="33%"><FormField label="Do you have your own car?" compact name="office_use.have_own_car" size="medium" type="text" /></Col>
                      <Col flex="33%"><FormField label="How long have you been in Calgary?" compact name="office_use.in_calgary" size="medium" type="text" /></Col>
                      <Col flex="33%"><FormField label="How well do you know the area of Calgary?" compact name="office_use.know_about_calgary" size="medium" type="text" /></Col>
                    </Row>
                    <hr color="#EEE" />

                    <Row style={{ flexWrap: 'nowrap', marginBlock: '50px' }}>
                      <Col flex="300px"><b>Canadian Immigration Status</b></Col>
                      <Col flex="auto">
                        <Row gutter={[12, 12]}>
                          <Col flex="33%"><FormField label="Are you a citizen?" compact name="office_use.is_citizen" size="medium" type="switch" /></Col>
                          <Col flex="33%"><FormField label="Are you a permanent resident?" compact name="office_use.is_permanent_resident" size="medium" type="switch" /></Col>
                          <Col flex="33%"><FormField label="Do you have a valid work permit?" compact name="office_use.is_valid_work_permit" size="medium" type="switch" /></Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr color="#EEE" />

                    <div style={{ marginBlock: '50px' }}>
                      <FormField label="Are you available to work Christmas and New Years?" name="office_use.work_at_christmas" size="medium" type="switch" />
                      <FormField label="Are there any special holidays throughout the year that you are not able to work? (Provide Dates)" name="office_use.special_holidays" size="medium" type="text" />
                      <FormField label="What size of Pants and Shirt do you take? S, M, L, XL" name="office_use.pant_shirt_size" size="medium" type="text" />
                      <FormField label="Other Notes" name="office_use.other_notes" size="medium" type="textarea" />
                    </div>

                  </div>

                  <div style={{ height: '20px' }} />
                  <Button type="primary" block htmlType='submit' size="large" style={{ marginBottom: "50px" }} loading={busy}>Submit Form</Button>

                </>)
              }}
            />

          </div>
        </div>
      </div>
    </>)

  }
}

export const TheForm = withApollo(TheFormComp)
export default TheForm;
