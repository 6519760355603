import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Popconfirm, Alert, message, Tabs } from 'antd';
import { Table, Loader, Icon, IconButton, Button } from 'Common/components';
import { __error, __yellow } from 'Common/scripts/consoleHelper'
import { Link } from 'react-router-dom';
import { Breadcrumb } from "antd";
import { ContentArea } from "Layout_v1";
import { HomeOutlined } from '@ant-design/icons';
import FormDataSearchBar from './Components/FormDataSearchBar'
import { connect } from "react-redux";
import { updatePageSettings } from 'Store/ep_admin.actions.js'
import { useSubscription, gql } from "@apollo/client";
import { defaultDateTimeFormat, defaultPageSize, uriRoot } from 'configs'
import { mongoToDate, timestamp } from 'Common/scripts/Functions'; 
import { withApollo } from '@apollo/client/react/hoc';


const LIST_DATA = loader('src/Graphql/TestResults/testResultsQuery.graphql');
// const DELETE = loader('src/Graphql/TestResults/deleteTestResult.graphql');
const UPDATED_STATUS = loader('src/Graphql/TestResults/updateTestResultStatus.graphql');
const QUERY_SUBSCRIPTION = loader('src/Graphql/TestResults/sub_testResultsUpdated.graphql');

function Subscriber({ QUERY, callback, filter }){
    let variables = {};

    if (filter){
        Object.assign(variables, { 
            ref_key: filter.ref_key
        });
    }

    const { data, loading, error } = useSubscription(
        QUERY_SUBSCRIPTION,
        { 
            // subscription: QUERY,
            variables: { 
                filter: JSON.stringify(variables),
            },
            // fetchPolicy: "network-only" // cache-first, cache-only, cache-and-network, network-only, no-cache, standby
            onSubscriptionData: ({ client, subscriptionData }) => {
                const { data, error, loading, variables } = subscriptionData
                if (callback) callback(subscriptionData)
            },
            shouldResubscribe: false, // Determines if your subscription should be unsubscribed and subscribed again
        }
    );

    if (loading){
        console.log("Receiving subscription on ....", variables);
        return null;
    }

    if (data){
        console.log('Subscription received: ', data);
        // const { mutation, node } = data.formsDatasUpdated;
        // if (callback) callback({ mutation, node })
    }

    return null;

}

const defaultFilter = {}; // { $and: [{ status: { $nin: ["invitation"] } } ] }

class FormsResultListComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null,
            error: null,
            kw: null,
            pagination: { current: 1, pageSize: defaultPageSize },

            showDrawer: false, initialData: null,
            filter: {}
        };
        // this.onSearch = this.onSearch.bind(this)
        // this.searchList = this.searchList.bind(this)
        // this.delete = this.delete.bind(this)
        this.onSubscriptionReceived = this.onSubscriptionReceived.bind(this)
    }

    fetchData = async (args = {}) => {
        console.log(__yellow("fetchData()", args));

        let _filter = { ...args.filter, ...this.props?.filter }
        
        const variables = {
            first: args.pageSize || this.state.pagination.pageSize, // number of rec to fetch
            after: (args.pageSize || this.state.pagination.pageSize) * ((args.current || this.state.pagination.current) - 1),
            // filter: JSON.stringify(args.filter || this.props.ep_admin?.pageSettings?.formdata_searchBar || {})
            filter: JSON.stringify({..._filter}),
            others: JSON.stringify({ sort: { completed_at: -1, created_at: -1 } }),
        }

        this.setState({ loading: true, filter: args?.filter || {} })

        return this.props.client.query({ query: LIST_DATA, variables }).then(r => {
            let response = r.data?.testResultsQuery;

            if (!response){
                this.setState({ loading: false, error: "Invalid response from server" }); return;
            }
            if (response.error) {
                this.setState({ loading: false, error: response.message }); return;
            }

            this.setState({
                loading: false,
                data: response,
                pagination: {
                    ...this.state.pagination,
                    current: args.current || 1,
                    pageSize: args.pageSize || this.state.pagination.pageSize,
                    total: response.totalCount
                }
            });

            return response;

        }).catch(err => {
            console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
            this.setState({ loading: false, error: "Unable to complete your request at the moment!" });
            return false;
        })
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ current: pagination.current, pageSize: pagination.pageSize });
    };

    // searchList(value) {
    //     this.fetchData({
    //         // filter: { $or: [{ "personalinfo.f_name": value }, { "personalinfo.sin_no": value}]}
    //         filter: { search: { keywords: value } }
    //     });
    // }

    // delete(_id) {
    //     this.setState({ loading: true });

    //     this.props.client.mutate({
    //         mutation: DELETE, variables: { id: _id }
    //     }).then(r => {
    //         const response = r.data.deleteTestResult;

    //         if (!response){ this.setState({ loading: false, error: "Invalid response!" }); return; }
    //         if (response.error) { 
    //             message.error(response.error.message);
    //             this.setState({ loading: false }); 
    //             return; 
    //         }

    //         this.setState({ loading: false });
    //         this.fetchData(this.state.filter)

    //         message.success("Record successfully deleted!")
    //         return response;
    //     }).catch(err => {
    //         console.log("ERROR: ", err);
    //         this.setState({ loading: false, error: "Unable to delete at the moment!" });
    //         return false;
    //     })

    // }

    toggeleDrawer(initialData = null) {
        this.setState({ showDrawer: !this.state.showDrawer, initialData })
    }

    onSubscriptionReceived({ data, error, loading, variables }){
        const { mutation, node } = data.formsDatasUpdated;

        let _data = this.state.data.edges ? this.state.data.edges.slice() : [];
        
        if (mutation == "DELETED"){
            _data = _data.filter(o => {
                return o._id != node._id;
            });
        }

        else if (mutation == "UPDATED"){
            // let data = this.state.data ? this.state.data.slice() : [];
            _data = _data.map(o => {
                if (o._id == node._id) return { ...node }
                return o;
            })
        }

        else if (mutation == "CREATED"){
            // let data = this.state.data ? this.state.data.slice() : [];
            _data.push(node);
        }

        else{
            console.log(__error("Invalid Subscription received: ", data));
            return;
        }
        
        this.setState({ data: { ...this.state.data, edges: _data } })
    }

    async archive(_id) {
        this.setState({ loading: true });

        const resutls = await this.props.client.mutate({
            mutation: UPDATED_STATUS, variables: { status: 'archived', id: _id }
        }).then(r => (r?.data?.updateTestResultStatus))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Unable to delete at the moment!" } }
            })

        if (!resutls || resutls.error) {
            this.setState({ loading: false, error: resutls ? resutls.error.message : "Invalid response!" });
            return false;
        }

        this.fetchData({ filter: this.state.filter })
        message.success("Record successfully deleted!")
    }

    columns = [
        { title: 'ID', dataIndex: '_id', width: "100px" },
        // { title: 'Test ID', dataIndex: 'test_id', width: "100px", render: (___, rec) => (rec.test_form.title) },
        // { title: 'Title', dataIndex: 'title', render:(___, rec)=>{
        //     return (<>
        //         <Link to={`./view/${rec._id}`}>{rec.title}</Link>
        //         {rec.status =="incomplete" && 
        //             <div>Incomplete Test URL <Link to={`/dotest/${Buffer.from(timestamp() + "," + rec._id).toString('base64')}`} target="_blank"><Icon icon="external-link-alt" /></Link></div>
        //         }
        //         <div>{rec.passcode}</div>
        //     </>)
        // } },
        { title: 'Candidate', dataIndex: 'candiateInfo.name', render: (___, rec) => {
            return (<>
                <Link to={`./view/${rec._id}`}>{rec?.candiateInfo?.name}</Link>
                <div>Test: {rec?.test_form?.title}</div>
                {rec.course && <div>Course: {rec?.course?.title}</div>}
                {rec.status =="incomplete" && 
                    <div>Incomplete Test URL <Link to={`/dotest/${Buffer.from(timestamp() + "," + rec._id).toString('base64')}`} target="_blank"><Icon icon="external-link-alt" /></Link></div>
                }
            </>)
        } },
        // { title: 'License No.', dataIndex: 'candiateInfo.driving_lc_no', render: (___, rec) => (rec.candiateInfo.driving_lc_no) },
        { title: 'Created at', dataIndex: 'create_at', render: (___, rec) => (mongoToDate(rec.created_at).format(defaultDateTimeFormat)), width: 160, align: 'center' },
        { title: 'Submitted at', dataIndex: 'completed_at', width: 160, align:'center', render: (___, rec) => {
            if (!rec.completed_at) return null;
            return (mongoToDate(rec.completed_at).format(defaultDateTimeFormat))
        } },
        { title: 'Result', dataIndex: 'test_result', width: 100, align: 'center', render: (___, rec) => {
            if (rec.test_result == 'pass') return <span style={{ color: "green" }}>Passed</span>
            if (rec.test_result == 'fail') return <span style={{ color: "red" }}>Failed</span>
            return null;

            // let failed = rec?.steps ? rec.steps.find(o=>o.status=='fail') : null;
            // if (failed) return <span style={{ color: "red" }}>Failed at Step {failed.step}</span>;
            // if (rec.test_result == 'pass') return <span style={{ color: "green" }}>Passed</span>
            // return null;
        } },
        { title: 'status', dataIndex: 'status', width: 100, align: 'center' },
        {
            title: '', width: "50px", align: 'center', render: (___, rec) => {
                return <div>
                    <Popconfirm onConfirm={() => this.archive(rec?._id)} title="Are you sure to archive this record?">
                        <IconButton icon={{ icon: 'archive' }}></IconButton>
                    </Popconfirm>

                    {/* <Popconfirm onConfirm={() => this.delete(rec?._id)} title="Are you sure？">
                        <IconButton icon={{ icon: 'trash-alt' }}></IconButton>
                    </Popconfirm> */}
                </div>
            }
        },
    ];



    render() {
        const { loading, data, error } = this.state;
        const { pagination, showDrawer, initialData } = this.state;

        // if (loading) return <ContentArea><Loader style={{ margin: "auto" }} loading={true} /></ContentArea>
        if (error) return <Alert message="Error" description={error} type="error" showIcon />

        return (<>
            <Subscriber QUERY={QUERY_SUBSCRIPTION} callback={this.onSubscriptionReceived} variables={{}} filter={this.props.ep_admin?.pageSettings?.form_result_searchBar} />

            <div style={{ marginTop: "10px", padding: "0 10px 5px 10px", border: "1px solid #EEE", borderTopRightRadius: "5px", borderTopLeftRadius: "5px", backgroundColor: "rgba(0, 0, 0, 0.02)" }}>
                <FormDataSearchBar hide={this.props.hideSearch} fetchData={(args) => this.fetchData(args)} defaultFilter={defaultFilter} />
            </div>

            <Table loading={loading}
                title={() => <b>Total {((data && data.totalCount) || '0') } record(s) found </b>}
                columns={this.columns}
                dataSource={data ? data.edges : null}
                pagination={pagination}
                onChange={this.handleTableChange}
                rowClassName={(record => record.status == 'new' ? 'hilighted-table-row' : "")}
                // onChange={this.handleTableChange}
            />
        </>)
    }
}
export const FormsResultList = withApollo(FormsResultListComp)

const FormsResultListWrapper = props => {
    const [selectedTab, setSelectedTab] = React.useState("results")

    return (<>
        <Breadcrumb style={{ padding: "10px" }}>
            <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
            {/* <Breadcrumb.Item href="/templates"><span>Templates</span></Breadcrumb.Item> */}
            <Breadcrumb.Item>Test Results</Breadcrumb.Item>
        </Breadcrumb>
        
        <ContentArea>

            <Tabs defaultActiveKey="1" onChange={(v) => setSelectedTab(v)}>
                <Tabs.TabPane tab="Results" key="results">
                    {selectedTab == 'results' && <FormsResultList {...props} filter={{ status: { $nin: ['archived', 'hired', 'training', 'invitation'] } }} />}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Archived" key="archived">
                    {selectedTab == 'archived' && <FormsResultList {...props} filter={{ status: { $eq: 'archived', $nin: ['invitation'] } }} hideSearch={true} />}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Hired" key="hired">
                    {selectedTab == 'hired' && <FormsResultList {...props} filter={{ status: { $eq: 'hired', $nin: ['invitation'] } }} hideSearch={true} />}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Training" key="training">
                    {selectedTab == 'training' && <FormsResultList {...props} filter={{ status: { $eq: 'training', $nin: ['invitation'] } }} hideSearch={true} />}
                </Tabs.TabPane>
            </Tabs>
        </ContentArea>
    </>)
}


const mapStateToProps = ({ ep_admin }) => {
    return ({ ep_admin });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(FormsResultListWrapper)
