import { Row, Col } from 'antd';
import { DeleteButton, IconButton } from 'Common/components';
import React, { Component } from 'react'
// import PropTypes from 'prop-types';
// import { __error } from 'Common/scripts/consoleHelper'
import ListComp from './components/list';

const KEY_VALUE = "lead_service_type";

const columns = ({ onEditClick, onDeleteClick }) => [
  { title: 'ID', dataIndex: '_id', width: 100 },
  {
    title: 'Title', dataIndex: 'title', width: 400, render: (__, rec) => (<>
      <div>{rec.title} </div>
      <span className='hidden-menu'>
        <IconButton className="item" icon="pen" onClick={() => onEditClick(rec)} />
        <DeleteButton className="item" onConfirm={() => onDeleteClick(rec?._id)} />
      </span>
    </>)
  },
  // { title: 'Type', dataIndex: 'value_type', width: 100 },
  { title: 'Value', dataIndex: 'values', render:(__, rec) => {
    let values = JSON.parse(rec.values);

    return (<div style={{display:"inline-block"}}>
      <table>
        <tbody>

          {values.map((item, i)=>{
            return (<tr key={i} gutter={[2]}>
              <td flex="60px">{`Tier ${item.tier}`}</td>
              <td flex="130px">{`${item.contract == 'yes' ? 'With contract' : 'Without contract'}`}</td>
              <td flex="80px">{`(${item.range.toString().replaceAll(',', ' ~ ')})`}</td>
              <td>{`@ ${item.commission}%`}</td>
            </tr>)
          })}

        </tbody>
      </table>
      {/* {`Tier ${values.tier}, ${values.contract == 'yes' ? 'With contract' : 'Without contract'} (${values.range.toString().replaceAll(',', ' ~ ')}) @ ${values.commission}%`} */}
      {/* {rec.values} */}
    </div>)
  } },
  { title: 'Status', dataIndex: 'status', width: 100 },
  // { title: 'Value Key', dataIndex: 'value_key', width: '200px' },
];

const LeadServiceType = props => {
  return <ListComp key_value={KEY_VALUE} title="Lead Service Type" columns={columns} />
}
export default LeadServiceType;