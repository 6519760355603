import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Image, Text as TextPdf, View, StyleSheet } from '@react-pdf/renderer';

export const Row = ({ children, style, theme }) => {
    let _style = { ...comp_style.row, ...style }

    return <View style={_style}>{children}</View>;
}

export const Col = ({ children, style, align, flex, theme }) => {
    let _style = { ...comp_style.col, ...style }
    if (align && align.includes("middle")) Object.assign(_style, { ...comp_style.middle })
    if (align && align.includes("right")) Object.assign(_style, { ...comp_style.right })

    if (flex == "auto") Object.assign(_style, { flex: "1 1 auto" })
    else if (flex) Object.assign(_style, { flex: `1 ${flex} auto` })

    return <View style={_style}>{children}</View>
}

export const P = (props) => {
    const { children, style } = props;
    let _style = [comp_style.paragraph]
    if (style) _style.push(style)

    return <Text {...props} style={_style} wrap>{children}</Text>
}

export const Hr = ({ color, style, theme }) => (<View style={{ borderBottom: `1px solid ${color || "#EEE"}`, marginVertical: 10, ...style }} />)

export const Text = ({ children, style, color, page_break = false, bold, size, underline, italic, center, wrap, theme }) => {
    var _style = [comp_style.text]
    if (bold) _style.push(comp_style.bold)
    if (underline) _style.push(comp_style.underline)
    if (italic) _style.push(comp_style.italic)
    if (center) _style.push(comp_style.center)
    if (size) _style.push({ fontSize: size })
    if (color) _style.push({ color })
    if (style) _style.push(style)

    return <TextPdf style={_style} wrap={wrap} break={page_break}>{children}</TextPdf>
}

export const Heading = (props) => {
    // if(props.theme)
    let _style = comp_style[props.type || "h1"];
    
    let themed = typographyThemes[props.theme] || {};
    if (themed) {
        let __style = themed[props.type || "h1"];
        Object.assign(_style, { ...__style })
    }

    return <Text {...props} style={_style} />
    // return <Text {...props} style={comp_style[props.type || "h1"]} />
}

export const Li = ({ value, children, valueWidth, valueStyle, style }) => {
    return (<Row style={style}>
        <Col style={{ width: valueWidth || "20px" }}><Text bold style={valueStyle}>{value}</Text></Col>
        <Col style={{ width:"100%" }}>{children}</Col>
    </Row>)
} 


const comp_style = StyleSheet.create({
    row: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        // flexFlow: "row wrap",
        flexWrap: "nowrap"
    },
    middle: { display: "flex", flexDirection: "row", alignItems: "center", }, // middle align columns
    right: { alignItems: "flex-end" }, // middle align columns
    col: {
        // border: "1px solid blue",
        position: "relative",
        // maxWidth: "100%",
        minHeight: 1,
    },
    paragraph: {
        margin: 12,
        textAlign: 'justify',
    },
    text: {
        // border: "1px solid blue",
        // fontSize: 12,
        textAlign: 'justify',
        fontFamily: 'Helvetica',
        lineHeight: 1.5,
    },
    bold: { fontFamily: 'Helvetica-Bold', },
    underline: { textDecoration:"underline" },
    italic: { fontStyle:"italic" },
    center: { textAlign: "center" },

    h1: { fontSize: 14, lineHeight: 1 },
    h2: { fontSize: 16, lineHeight: 1 },
    h3: { fontSize: 18, lineHeight: 1 },
    h4: { fontSize: 20, lineHeight: 1 },
    h5: { fontSize: 24, lineHeight:1 },

    // italic: { fontStyle: 'Helvetica-Oblique' },
    // boldItalic: { fontStyle: 'Helvetica-BoldOblique' },
});

export const template1_comp_style = StyleSheet.create({
    row: {},
    col: {},
    paragraph: {},
    text: {},

    h1: {},
    h2: {},
    h3: {},
    h4: {},
    h5: {},
});

export const template2_comp_style = StyleSheet.create({
    row: {},
    col: {},
    paragraph: {},
    text: {},

    h1: { backgroundColor: "#EEE", padding:"5" },
    h2: { backgroundColor: "#EEE", padding: "5" },
    h3: { backgroundColor: "#EEE", padding: "5" },
    h4: { backgroundColor: "#EEE", padding: "5" },
    h5: { backgroundColor: "#EEE", padding: "5" },
});

const typographyThemes = {
    template1: template1_comp_style,
    template2: template2_comp_style,
}
