/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { Row, Col, Divider, Alert, message, Tag, PageHeader } from 'antd'
import { loader } from 'graphql.macro';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Button, DataGrid, DataRow, DevBlock } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper';
import { withApollo } from '@apollo/client/react/hoc';
import _ from 'lodash';
import { mongoToDate } from 'Common/scripts/Functions';
import { defaultDateTimeFormat } from 'configs';


const ADD_DATA = loader('src/Graphql/FormsData/addFormsData.graphql');

const fieldsArray = [
  { type: "text", name: "license_no", label: "Security License Number", validate: rules.required, span: 12 },
  { type: "date", name: "date_interviewd", label: "Date interviewd", validate: rules.required, span: 12 },
  { type: "radio", label:"Gyard Type", name: "guard_type", validate: rules.required, span:12,
    data: [
      { value: "is_mobile_guard", label: "Mobile Guard" },
      { value: "is_static_guard", label: "Static Guard" },
    ]
  },
  { type: "radio", label:"Gender", name: "gender", validate: rules.required, span: 12, 
    data: [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
    ]
  },
  { type: "divider", children: "Data Collection" },
  // { type: "checkbox", name: "driving_license", children: "Driver License", span: 8, validate: rules.required },
  { type: "radio", label: "Driver License", name: "driving_license", validate: rules.required, span: 8,
    data: [ { value: "yes", label: "Yes" }, { value: "no", label: "No" }, ]
  },
  // { type: "checkbox", name: "security_license", children: "Security License", span: 8, validate: rules.required },
  { type: "radio", label: "Security License", name: "security_license", validate: rules.required, span: 8,
    data: [{ value: "yes", label: "Yes" }, { value: "no", label: "No" },]
  },
  // { type: "checkbox", name: "sin_card", children: "SIN Card", span: 8, validate: rules.required },
  { type: "radio", label: "SIN Card", name: "sin_card", validate: rules.required, span: 8,
    data: [{ value: "yes", label: "Yes" }, { value: "no", label: "No" },]
  },

  // { type: "checkbox", name: "canadian_citizenship", children: "Canadian Citizenship", span: 8, validate: rules.required },
  { type: "radio", label: "Canadian Citizenship", name: "canadian_citizenship", validate: rules.required, span: 8,
    data: [{ value: "yes", label: "Yes" }, { value: "no", label: "No" },]
  },
  // { type: "checkbox", name: "all_fields_filled", children: "Have they filled out all of the fields required in application?", validate: rules.required, span: 16, },
  { type: "radio", label: "Have they filled out all of the fields required in application?", name: "all_fields_filled", validate: rules.required, span: 16,
    data: [{ value: "yes", label: "Yes" }, { value: "no", label: "No" },]
  },

  { type: "select", name: "other_documents", label: "Any other documents you may have collected", placeholder: "list other documents", mode:"tags", span: 24 }, // make it tags

  { type: "h1", children: "Check pronunciation of names and get phonetic spelling if necessary." },
  // { type: "checkbox", name: "phonetic_spelling", children: "I have written phonetic spelling beside the name in brackets", validate: rules.required },
  { type: "radio", label: "I have written phonetic spelling beside the name in brackets", name: "phonetic_spelling", validate: rules.required, span: 24,
    data: [{ value: "yes", label: "Yes" }, { value: "no", label: "No" },]
  },
  // { type: "checkbox", name: "legibility_check", children: "Checked legibility of form and re-write anything properly if need be.", validate: rules.required },
  { type: "radio", label: "Checked legibility of form and re-write anything properly if need be.", name: "legibility_check", validate: rules.required, span: 24,
    data: [{ value: "yes", label: "Yes" }, { value: "no", label: "No" },]
  },
  // { type: "checkbox", name: "have_resume", children: "Have you collected the resume?", validate: rules.required },
  { type: "radio", label: "Have you collected the resume?", name: "have_resume", validate: rules.required, span: 24,
    data: [{ value: "yes", label: "Yes" }, { value: "no", label: "No" },]
  },

  { type: "divider", children: "Question Asked" },
  { type: "text", name: "secuity_guard_experience", label: "How many years have you been a ______________?", validate: rules.required },
  { type: "text", name: "driving_experience", label: "How long have you been driving? (Mobile only)", validate: rules.required },
  { type: "text", name: "have_own_car", label: "Do you have your own car?", validate: rules.required },
  { type: "text", name: "in_calgary_yrs", label: "How long have you been in Calgary?", validate: rules.required },
  { type: "text", name: "know_about_calgary", label: "How well do you know the area of Calgary?", validate: rules.required },

  { type: "divider", children: "Canadian Immigration Status" },
  { type: "radio", name: "immigration_status", validate: rules.required, direction: "horisontal",
    data: [
      { value: "citizen", label: "Are you a citizen?", span:24 },
      { value: "permanent", label: "Are you a permanent resident?", span: 24 },
      { value: "work_permit", label: "Do you have a valid work permit / student?", span: 24 },
    ]
  },
  { type: "text", name: "work_restictions", label: "Are there any restrictions on the number of hours you can work?", validate: rules.required },
  // { type: "checkbox", name: "available_on_xmas", children: "Are you available to work Christmas and New Years?", validate: rules.required },
  { type: "radio", label: "Are you available to work Christmas and New Years?", name: "available_on_xmas", validate: rules.required, span: 24,
    data: [{ value: "yes", label: "Yes" }, { value: "no", label: "No" },]
  },

  { type: "text", name: "spacial_holidays", label: "Are there any special holidays throughout the year that you are not able to work? (Provide Dates)", validate: rules.required },
  // { type: "text", name: "grammar_spelling", label: "Give them a paragraph to write describing the country they grew up in. How was their grammar spelling and comprehension?" },
  { label: "What size of Uniform Shirt do you take?", validate: rules.required,
    type: "radio", name: "uniform_size", direction: "horisontal", size: [10, 10],
    data: [
      { value: "small", label: "Small (S)", span:6 },
      { value: "medium", label: "Medium (M)", span: 6 },
      { value: "large", label: "Large (L)", span: 6 },
      { value: "extra-large", label: "Extra Large (XL)", span: 6 },
      { value: "2-extra-large", label: "2 x XL (XXL)", span: 6 },
      { value: "3-extra-large", label: "3 x XL (XXXL)", span: 6 },
      { value: "4-extra-large", label: "4 x XL (XXXXL)", span: 6 },
    ]
  },
  // { type: "checkbox", name: "direct_deposit", children: "Direct Deposit filled out?" },
  // { type: "text", name: "salary_told", label: <>Have you explained to each candidate the rate of pay for their particular position <small>(Specify what you quoted them)</small></> },
  // { type: "checkbox", name: "explained_abt_non_paying", children: "Have you explained to each candidate that the first day of training is Non Paying?" },
  { type: "textarea", name: "comments", label: "Your comments", validate: rules.required },

]



const GuardScreeningProcessForm = ({ application, onCancel, onSuccess, client }) => {
  const [error, setError] = useState(null)

  let license_no = application && application?.fields?.find(o => o.name == 'license_no')?.value;

  const onSubmit = async (values) => {
    setError(null);
    let err = false;

    let fields = fieldsArray.filter(o => !(o.type == 'divider' || o.type == 'h1')).map(o => {

      let value = values[o.name];
      if (value === true || value === false) value = (value===true) ? 'yes' : 'no';
      if (_.isArray(value)) value = JSON.stringify(value);

      let label = o.label || o.children || o.name || o.type;
      // let type = o.type;

      if (o.validate && (!value && !(value === false)) && !err) {
        err = `"${label}" is required`;
      }

      return { name: o.name, label, value }

    })

    if (err){
      message.error(err);
      setError(err)
      return false;
    }

    const input = {
      ref_key: 'screening_report',
      title: `${license_no}`,
      parent_id: application._id,
      fields,
    }

    const added = await client.mutate({ mutation: ADD_DATA, variables: { input } }).then(r => (r.data.addFormsData)).catch(err => {
      console.log(__error("ERROR"), err);
      return { error: { message:"Failed to process the request!" }};
    });

    if (!added || added.error){
      setError((added && added.error.message) || "Invalid response!")
      return false;
    }

    onSuccess(added);
    return false;

  }

  const renderFields = (item, values) => {
    let fieldProps = {
      name: item.name,
      validate: item.validate,
      data: item.data,
      type: item.type,
      label: item.label ? <b>{item.label}</b> : undefined,
      placeholder: item.placeholder,
      size: item.size,
    }
    
    if (item.type == 'radio') return (<FormField direction={item.direction || "horisontal"} {...fieldProps} />)
    if (item.type == 'divider') return <Divider>{item.children}</Divider>
    if (item.type == 'checkbox') return (<FormField {...fieldProps}>{item.children}</FormField>)
    if (item.type == 'text') return (<FormField {...fieldProps} />)
    if (item.type == 'select') return (<FormField {...fieldProps} mode={item.mode} />)
    if (item.type == 'date') return (<FormField {...fieldProps} />)
    if (item.type == 'textarea') return (<FormField {...fieldProps} />)
    if (item.type == 'h1') return (<div><b>{item.children}</b></div>)

    return (<p>Invalid field type ({item.type})</p>)
  }

  return (<>
    <FormComponent onSubmit={onSubmit} id='GuardScreeningForm' debug={true} fields={{ license_no }} moveOnError
        style={{ width: "100%", padding: "0", margin: "0" }}
        // mutators={{ 
        //   ...arrayMutators,
        //   updateMaxUplpads: (newValueArray, state, tools) => {
        //     let node = newValueArray[0];
        //     tools.changeValue(state, 'max_file_upload', () => node);
        //   },
        // }}
        form_render={formProps => {
          const { values, form, submitting, invalid, dirty, valid } = formProps;

          return (<>
            {error && <div style={{ padding: "20px" }}><Alert title={<b>Error!</b>} description={error} type='error' showIcon /></div>}

            <Row gutter={[10, 5]}>
              {fieldsArray.map((item, i) => {
                return (<Col key={i} span={item.span || 24}>
                  {renderFields(item, values)}
                </Col>)
              })}
            </Row>


            {/* {values.guard_type == "is_mobile_guard" && <></>}
            {values.guard_type == "is_static_guard" && <></>} */}
            {/* <FormField name="is_mobile_guard" type="checkbox" validate={rules.required}>Is Mobile Guard</FormField> */}


            <Row gutter={[10]} style={{ padding:"0 20px" }}>
              <Col flex="auto"><Button onClick={onCancel} size="large">Cancel</Button></Col>
              <Col><Button type="primary" htmlType='submit' size="large" loading={submitting}>Save</Button></Col>
            </Row>

          </>)
        }}
      />

  </>)

}

export const GuardScreeningProcess = withApollo(GuardScreeningProcessForm)
export default GuardScreeningProcess;



export const GuardScreeningProcessPreview = ({ onCancel, data }) => {
  const renderFields_1 = (item) => {

    return <DataRow col1={item.label} col2={item.value} 
      // style={{ border:"1px solid black"}}
      vertical={false}
      size={['30%', 'auto']}
      // inline, size, vertical, style
      />

    return (<Row gutter={[10]}>
      <Col span={6}>{item.label}</Col>
      <Col span={18}>{item.value}</Col>
    </Row>)

    return <DevBlock obj={item} />

    // let fieldProps = {
    //   name: item.name,
    //   validate: item.validate,
    //   data: item.data,
    //   type: item.type,
    //   label: item.label ? <b>{item.label}</b> : undefined,
    //   placeholder: item.placeholder,
    //   size: item.size,
    // }

    // if (item.type == 'radio') return (<FormField direction={item.direction || "horisontal"} {...fieldProps} />)
    // if (item.type == 'divider') return <Divider>{item.children}</Divider>
    // if (item.type == 'checkbox') return (<FormField {...fieldProps}>{item.children}</FormField>)
    // if (item.type == 'text') return (<FormField {...fieldProps} />)
    // if (item.type == 'select') return (<FormField {...fieldProps} mode={item.mode} />)
    // if (item.type == 'date') return (<FormField {...fieldProps} />)
    // if (item.type == 'textarea') return (<FormField {...fieldProps} />)
    // if (item.type == 'h1') return (<div><b>{item.children}</b></div>)

    // return (<p>Invalid field type ({item.type})</p>)
  }

  const renderFields = (item) => {

    let val = data.fields.find(o => o.name == item.name);
    
    // <DataRow col1={item.label} col2={item.name} />; // (<DevBlock obj={item} direction={item.direction || "horisontal"} />)
    if (item.type == 'radio') {
      return <DataRow col1={<b>{item.label}</b>} col2={item?.data?.find(o => o.value == val.value)?.label} />;
    }
    if (item.type == 'divider') return <Divider>{item.children}</Divider>
    if (item.type == 'checkbox') return <DataRow col1={<b>{item.children}</b>} col2={val.value=='yes' ? <Tag color='green'>YES</Tag> : <Tag color='red'>NO</Tag>} />
    if (item.type == 'text') return <DataRow col1={item.label} col2={val.value} vertical={!(item.name == 'license_no')} />
    if (item.type == 'select') {
      if (item.name =='other_documents') return <DataRow col1={item.label} col2={<>{val.value ? JSON.parse(val.value).map((item, i) => (<Tag key={i}>{item}</Tag>)) : "NO"}</>} vertical />
      return (<DevBlock obj={item} />)
    }
    if (item.type == 'date') return <DataRow col1={item.label} col2={val.value ? mongoToDate(val.value).format(defaultDateTimeFormat) : ''} />
    if (item.type == 'textarea') return <DataRow col1={item.label} col2={val.value} vertical />
    if (item.type == 'h1') return (<div><b>{item.children}</b></div>)

    return (<p>Invalid field type ({item.type})</p>)
  }


  return (<>

    <h1>Author: {data.author.name}</h1>

    <Row gutter={[10, 5]}>
      {fieldsArray.map((item, i) => {
        return (<Col key={i} span={item.span || 24}>
          {renderFields(item)}
        </Col>)
      })}
    </Row>

  </>)
}