import React, { useState } from 'react'
import { ContentArea } from "Layout_v1";
import { Breadcrumb, message, Row, Col, Drawer, Space } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'Common/components';
import FormsDataList from '../Components/FormsDataList';
import { Link } from 'react-router-dom';
import SendApplicationForm from '../Components/SendApplicationForm';
import { SECURITY_GUARD_INTERVIEW } from '../constants';

const GuardInterviewList = props => {
  const [showSendForm, set_showSendForm] = useState(false);
  const [activeTab, set_activeTab] = useState('active');

  let dataFilter = activeTab;
  if (activeTab == 'active') dataFilter = { status: { $in: ['new', 'viewed'] } }
  if (activeTab == 'processed') dataFilter = { status: 'processed' }

  // Object.assign(dataFilter, { ref_key: SECURITY_GUARD_INTERVIEW })

  return (<>
    <Row align="middle">
      <Col flex="auto">
        <Breadcrumb style={{ padding: "10px" }}>
          <Breadcrumb.Item to={'/'}><HomeOutlined /></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/admin/FormsData">Form Data</Link></Breadcrumb.Item>
          <Breadcrumb.Item>Client Information</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      {/* <Col style={{ marginRight: "10px", fontWeight: "bold" }}>
        <Space>
          <Button color='grey' onClick={() => set_showSendForm(true)}>Send Client Info Form</Button>
        </Space>
      </Col> */}
    </Row>

    {/* <div style={{ margin: "15px 15px 0 15px" }}><Tabs
      tabsArray={[
        { key: "active", title: "Active" },
        { key: "processed", title: "Processed" },
      ]}
      onChange={set_activeTab}
      size="large" /></div> */}

    <ContentArea style={{ marginTop: 0 }}>
      <FormsDataList permanent_filter={dataFilter} title="Guard Interviews" ref_key={SECURITY_GUARD_INTERVIEW} />
    </ContentArea>


    {/* <SendApplicationForm type={SECURITY_GUARD_INTERVIEW} visible={showSendForm} onCancel={() => set_showSendForm(false)} /> */}

  </>)
}

export default GuardInterviewList;
