import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Alert, message, Row, Col, Breadcrumb, Drawer, Collapse } from 'antd';
import { HomeOutlined } from '@ant-design/icons'; 
import { uriRoot } from 'configs';
import { ContentArea } from "Layout_v1";
import { loader } from 'graphql.macro';
import { Button, Icon, Loader } from 'Common/components';
// import { rules, FormField, FormComponent } from 'Common/components/Form'
import { withApollo } from '@apollo/client/react/hoc';
import { Provider, connect } from 'react-redux'
import configureStore from './InternalStore';
import { initForm, flushData } from './InternalStore/actions'
import { ROOT } from '../constants'

import ModuleForm from './components/ModuleForm'
import ModulePreview from './components/ModulePreview'
import { getmodulePermissions } from 'Common/scripts/Security';
const modulePermissions = getmodulePermissions('course-mg');

const COURSE = loader('src/Graphql/Courses/course.graphql');
const store = configureStore()


class CoursesEditor extends Component {
    state = { 
        loading: false, error: null, 
        moduleToEdit: null, showModuleForm: false, 
        screenToEdit:null, showScreenForm:false, 
    }
    constructor(props){
        super(props);
        this.toggleModuleForm = this.toggleModuleForm.bind(this);
    }

    componentDidMount(){
        this.fetchDate();    
    }

    fetchDate(){
        const id = this.props?.match?.params?.id
        console.log(`fetchDate(${id})`);
        
        if (!id) return;

        this.setState({loading:true});

        this.props.client.query({ 
            query: COURSE, 
            variables: { id: Number(id) },
            fetchPolicy: "no-cache",
        }).then(r => {
            const response = r.data.course;
            console.log({ response })

            if (response && response.error) {
                this.setState({ loading: false, error: response.error.message });
                return;
            }

            this.props.initForm(response)
            this.setState({ loading: false });

        }).catch(err => {
            console.log("ERROR: ", err);
            this.setState({ loading: false, error: "Unable to fetch record at the moment!" });
        })

    }

    toggleModuleForm(module){
        if (!module) return this.setState({ moduleToEdit: null, showModuleForm: null })
        this.setState({ moduleToEdit: module, showModuleForm: true })
    }



    render() {
        const { error, loading, moduleToEdit, screenToEdit, showModuleForm, showScreenForm } = this.state;
        const { formData } = this.props;
        console.log({ formData })

        if (!modulePermissions['course-mg.edit']) return <Alert message="Error" description={"Access Denied"} type="error" showIcon />;

        if (loading) return <Loader loading={true} />

        if (!this.props?.match?.params?.id) return <Alert message="Error" description={"Missing ID"} type="error" showIcon />
        if (error) return <Alert message="Error" description={error} type="error" showIcon />
        if (!formData || !formData._id) return <Alert message="Error" description={"Course not found!"} type="error" showIcon />


        return (<>
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={`${uriRoot}${ROOT}`}><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item href={`${uriRoot}${ROOT}/courses`}>Course</Breadcrumb.Item>
                <Breadcrumb.Item><b> {formData?.title || ""}</b></Breadcrumb.Item>
            </Breadcrumb>

            <ContentArea>
                <h4>{formData.title}</h4>
                <Row>
                    <Col flex="auto" />
                    <Col><Button size="large" onClick={() => this.setState({ showModuleForm:true })}><Icon icon="plus" /> Add Module</Button></Col>
                </Row>

                <ModuleForm showForm={showModuleForm} editNode={moduleToEdit} onClose={()=>this.toggleModuleForm()} />
                {/* <ScreenForm showForm={showScreenForm} editNode={screenToEdit} course_id={formData._id} onClose={this.onScreenFormClose} /> */}

                <Collapse defaultActiveKey={['0']} accordion>
                    {formData && formData.modules.map((module, i) => {
                        return <Collapse.Panel header={module.title} key={i}>
                            <ModulePreview module={module} onEditClick={this.toggleModuleForm} />
                        </Collapse.Panel>
                    })}
                </Collapse>

            </ContentArea>


            {/* <Drawer
                placement="top"
                title={this.props.initialData ? "Update Module" : "Create New Module"}
                height={"600px"}
                onClose={()=>this.toggleModule()}
                visible={show_addModule}
                // bodyStyle={{ paddingBottom: 80 }}
                extra={<Button onClick={() => this.toggleModule()}>Cancel</Button>}
                footer={<>
                    <span></span>
                    <Button loading={false} type="primary" onClick={() => {
                        document.getElementById('CourseModuleForm').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
                    }}>Save</Button>
                </>}

                destroyOnClose={true}
            ><>
                    {show_addModule && <ModuleForm initialData={{}} onClose={() => this.toggleModule()} course_id={data._id} />}

                </></Drawer> */}


        </>)
    }
}


const mapStateToProps = ({ formData }) => {
    return { formData };
};
const mapDispatchToProps = dispatch => ({
    initForm: (payload) => dispatch(initForm(payload)),
    flushData: (payload) => dispatch(flushData(payload)),
});
const InputForm = connect(mapStateToProps, mapDispatchToProps)(CoursesEditor)


const Wrapper = (props) => {
    // const invitationId = props.match.params.invitationId;
    // if (!invitationId || invitationId.length < 5) return <E404 title="Invalid test request" />

    return (<Provider store={store}>
        <InputForm {...props} />
    </Provider>)
}

export default withApollo(Wrapper)