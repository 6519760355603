import React, { useState, useEffect } from 'react'
import { Button } from 'Common/components';
import { Row, Col } from 'antd'


const ScreenPlay = props => {
    const [screenNum, setScreenNum] = useState(-1)
    const { screens } = props;

    useEffect(() => {
        setScreenNum(-1)
        //   return () => {
        //     effect
        //   };
    }, [props.module])

    const moveNext = () => {
        let total = screens.length;
        let num = screenNum + 1;
        if (num > total) return;

        setScreenNum(num);
    }
    const moveBack = () => {
        let total = screens.length;
        let num = screenNum - 1;
        if (num < -1) return;
        
        setScreenNum(num);
    }

    const onNextModule = () => {
        props.onNextModule();
    }
    const onBackModule = () => {
        props.onBackModule()
    }


    if (screenNum < 0) {
        return (<>
            <div style={{ flex: 1, width: "100%" }}>{props.children}</div>
            <Button onClick={() => moveNext()} type="primary" size="large" style={{ width: "300px", borderRadius: "50px", marginBottom: "20px" }}>Continue</Button>
        </>)
    }

    const thisScreen = screens[screenNum];
    const total = screens ? screens.length : 0;
    const hasNext = screens ? (screenNum + 1) < screens.length : false;
    const hasBack = screens ? screenNum > -1 : false;

    return (
        <div style={{ alignItems: "center", alignContent: "center", display: "flex", flexDirection: "column", maxWidth: "1000px", width: "100%", }}>
            <div style={{ flex: 1, width: "100%" }}>
                {thisScreen && <>
                    <div style={{ alignItems: "center", alignContent: "center", display: "flex", flexDirection: "column", }}><h6>{thisScreen.title}</h6></div>
                    <div style={{ minHeight: "250px" }} className="course_screen" dangerouslySetInnerHTML={{ __html: thisScreen.body }} />
                </>}

                {(!hasNext && props.onNextModule) && <div style={{ alignItems: "center", alignContent: "center", display: "flex", flexDirection: "column", }}>
                    <Button onClick={() => onNextModule()} size="large" style={{ borderRadius: "50px", width: "220px" }}>Continue To Next Module</Button>
                </div>}
            </div>

            {thisScreen && <>
                <div style={{ maxWidth: "1000px", width: "100%" }}>
                    <Row style={{ padding: "15px 50px" }} align="middle">
                        <Col><Button type="default" onClick={() => moveBack()} disabled={!hasBack} style={{ borderRadius: "50px" }}>Back</Button></Col>
                        <Col flex="auto" style={{ textAlign: "center" }}>{thisScreen && <span>{screenNum + 1}/{screens.length}</span>}</Col>
                        <Col><Button type="primary" onClick={() => moveNext()} disabled={!hasNext} style={{ borderRadius: "50px" }}>Next</Button></Col>
                    </Row>
                </div>
            </>}
        </div>
    )

}

export default ScreenPlay;