import React, { useState } from 'react'
import { Button, DataRow, Icon, IconButton, UsersSearch } from 'Common/components'
import { rules, FormField, FormComponent, FormFieldGroup } from 'Common/components/Form'
import { Alert, Col, Modal, Row, Tag, message } from 'antd';



export const DorFilter = ({ initialValues, onSearch }) => {

    const onSubmit = async (values) => {
        let filter = {}
        if (values?.user?._id) Object.assign(filter, { "user._id": values?.user?._id })

        return onSearch(filter)
    }

    return (<div className="pagination-filter">
        <FormComponent onSubmit={onSubmit} id='CustomerFilterForm' fields={initialValues} style={{ padding: 0 }} 
            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    <Row className="pagination-filter-row">
                        <Col flex="auto" >
                            <UsersSearch name="user._id" filter={{ status:"enabled" }} preload compact />
                            {/* <FormFieldGroup compact style={{ padding: 0, margin: 0 }}>
                                <UsersSearch name="user._id" label="Employee" />
                                <FormField returnAs="moment" label="DOR Date" name="record_date" type="date" validate={rules.required} />
                            </FormFieldGroup> */}
                        </Col>
                        <Col className="go-column">
                            <Button className="send_button" loading={submitting} htmlType="submit"><Icon icon="search" /></Button>
                        </Col>
                    </Row>

                </>)


            }}
        />
    </div>)

}
