import React, { useState } from 'react'
import { loader } from 'graphql.macro';
import { Drawer, Space, Alert, message, Row, Col, Button } from 'antd';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Loader, ClientSearch, Heading, SiteSearch, GuardSearch } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { ActiveInactiveStatus } from 'configs';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

const ADD_RECORD = loader('src/Graphql/trackable_assets/addAssetTracks.graphql');


const AssetTrackForm = ({ assetData, onSuccess, onCancel }) => {
    const [error, setError] = useState(null)

    const [addAssetTracks, add_details] = useMutation(ADD_RECORD);

    const onSubmit = async (values) => {
        setError(null)

        let input = {
            asset_id: assetData?._id,
            employee: { _id: values.employee._id, name: values.employee.name },
            notes: values.notes,
            status: values.status,
            // attachments: [AttachmentSchema_Input]
        };

        let results = await addAssetTracks({ variables: { input } }).then(r => (r?.data?.addAssetTracks)).catch(err=>{
            console.log(__error("Error: "), err)
            return { error: { message:"Invalid response" } }
        })

        if (!results || results.error){
            setError((results && results?.error?.message) || "Invalid response")
            return;
        }


        message.success("Record Saved");
        if (onSuccess) return onSuccess(results)
    }

    return (<>
        <FormComponent onSubmit={onSubmit} id='AssetTrackForm' fields={{}} debug={true}
            mutators={{
                ...arrayMutators,
                // selectFiles: (newValueArray, state, tools) => {
                //     let file = newValueArray[0];
                //     let action = newValueArray[1];
                //     let fileList = state.formState.values.fileList || []
                //     if (fileList.length >= FILE_LIMIT) return;

                //     if (action == 'add') fileList.push(file);
                //     if (action == 'remove') fileList = fileList.filter(o => o.uid != file.uid);

                //     tools.changeValue(state, 'fileList', () => fileList);
                // },
                // removeFiles: (newValueArray, state, tools) => {
                //     tools.changeValue(state, 'fileList', () => []);
                // },
                selectEmployee: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    let val = { _id: node.value, name: node.children };
                    tools.changeValue(state, 'employee', () => val);
                },
                de_selectEmployee: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'employee', () => undefined)
                },
            }}

            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    {error && <Alert message="Error!" description={error} type="error" showIcon />}

                    notes: values.notes,


                    <Row gutter={[10, 0]}>
                        <Col span={12}><GuardSearch label="Employee" name='employee._id' compact allowClear
                            // disabled={!values?.client?._id} 
                            // filter={values?.client?._id ? { client_id: Number(values.client._id) } : {}}
                            // preload={values?.client?._id ? true : false}
                            onSelect={(txt, node) => form.mutators.selectEmployee(node)}
                            onDeselect={(txt, node) => form.mutators.de_selectEmployee(node)}
                            validate={rules.required}
                        />
                        </Col>
                        <Col span={12}><FormField type="select" name="status" label="Status" data={ActiveInactiveStatus} compact validate={rules.required} /></Col>
                        <Col span={24}><FormField type="textarea" name="notes" label="Notes" compact validate={rules.required} /></Col>
                    </Row>



                    <Row style={{ marginTop: '20px', marginRight: '10px' }}>
                        <Col flex="auto"><Button onClick={onCancel}>Cancel</Button></Col>
                        <Col flex="200px">
                            <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!valid}>Add Track</Button>
                        </Col>
                    </Row>

                </>)
            }}
        />

    </>)


}

export default AssetTrackForm;