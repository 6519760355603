import React from 'react'

import { Breadcrumb } from "antd";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { ContentArea } from "Layout_v1";

import { Button } from 'Common/components'
import TypeForm from '../components/type_form';
import TypeList from '../components/type_list';
import { uriRoot } from 'configs';


export const TypeManagement = props => {
    const [{ visible, fields }, setState] = React.useState({ visible:false, fields:{} });
    
    const toggleDrawer = args => {
        setState({ visible: !visible, fields:args.fields || null });
    };

    return (<>
        <Breadcrumb style={{ padding: "10px" }}>
            <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
            <Breadcrumb.Item>Roles Type Management</Breadcrumb.Item>
        </Breadcrumb>

        {/* <div className="buttons"><Button onClick={toggleDrawer} size="small">Add Account Type</Button></div>  */}

        <ContentArea>
            <Button onClick={toggleDrawer} size="small">Add Account Type</Button>
            <TypeForm onClose={toggleDrawer} showform={visible} fields={fields} />
            <TypeList onEditRecord={(record) => toggleDrawer({ fields: record })} />
        </ContentArea>

    </>)

}

export default TypeManagement;