import React from 'react';
import { Row, Col } from 'antd';
import { Component} from 'react';
// import Logo from '../assets/alist.png'
// import '../style.scss'
// import 'antd/dist/antd.css';

export class Header extends Component {
    
    render() {
        const { title, extra, right } = this.props;

        return (<div style={{ backgroundColor: 'white', display:"flex", justifyContent: "center", padding:"20px" }}>
            <div style={{ width:"100%", maxWidth: "1020px", justifyContent: "left" }}>
                <Row>
                    <Col>
                        <img height="80px" src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/blue-logo-with-name.png`} />
                        <div style={{ fontSize: "25px" }}>{title}</div>
                    </Col>
                    {right && <Col flex="auto" align="right">{right}</Col>}
                </Row>
            </div>
        </div>)

    }
}

export default Header;