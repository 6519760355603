import React, { useEffect } from 'react'
import { connect } from "react-redux";
import Login from 'Modules/Login/Home'
import { E404, LayoutWrapper } from "Layout_v1";
import { message } from 'antd';
import { logoutUser } from 'Store/saga.actions';
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper';
import { useSubscription, gql } from "@apollo/client";

import './styles/styles.scss';

import { withApollo } from '@apollo/client/react/hoc';
import { showNotification } from 'Common/scripts/Functions';

const VALIDATE_USER = loader('src/Graphql/user/validateUserSession.graphql');
const NOTIFICATION_SUBSCRIPTION = loader('src/Graphql/sub_systemNotificationUpdated.graphql');
// const TICKETE_SUBSCRIPTION = loader('src/Graphql/threads/sub_threadsTicketsUpdated.graphql');

function Subscriber({ query, callback, variables }) {
  const { data, loading, error } = useSubscription(
    query,
    {
      // subscription: QUERY,
      variables,
      // fetchPolicy: "network-only" // cache-first, cache-only, cache-and-network, network-only, no-cache, standby
      onSubscriptionData: ({ client, subscriptionData }) => {
        const { data, error, loading, variables } = subscriptionData
        console.log({ subscriptionData })
        if (callback) callback(subscriptionData)
      },
      shouldResubscribe: false, // Determines if your subscription should be unsubscribed and subscribed again
    }
  );

  if (error) console.log(__error("Subscription Error: "), error)
  if (loading) {
    console.log("Receiving subscription on ....", { variables, query: query.definitions[query.definitions.length-1].name.value });
    return null;
  }

  if (data) {
    console.log('Subscription received: ', data);
    // const { mutation, node } = data.formsDatasUpdated;
    // if (callback) callback({ mutation, node })
  }

  return null;

}


const Wrapper = (props) => {
  const { ep_admin, children } = props;

  useEffect(() => {
    if (ep_admin?.user?._id) validateUser();
  }, [props])

  useEffect(() => {
    if (!("Notification" in window)) {
      alert("Browser does not support desktop notification");
    } else {
      if (Notification.permission === "granted") return;
      Notification.requestPermission();
    }
    // return () => {};
  }, [])

  const validateUser = async() => {
    const results = await props.client.query({ query: VALIDATE_USER }).then(e => {

      if (e.error || e.errors) {
        console.log(__error("ERROR "), e);
        return { error: { message: (e.error && e.error.message) || (e.errors && e.errors[0].message), code:"101" }}
      }

      return e.data.validateUserSession;

    }).catch(err => {
      console.log(__error("API Call ERROR: clients : "), err);
      message.error("Unable to sync, please cehck your internt connection");
      return {}
    })


    if (results.error){
      alert(results.error.message);
      if (results.error.code == "101") props.logoutUser();
    }

  }

  const onSubscriptionReceived = ({ data: { systemNotificationUpdated } }) => {
    // console.log("onSubscriptionReceived: ", systemNotificationUpdated)
    // document.body.addEventListener('my-set-lang', this.handleLangChange.bind(this));

    if (systemNotificationUpdated.mutation == "CHANNEL_CREATED") {
      if (systemNotificationUpdated.channel.author._id != ep_admin.user._id) {
        showNotification(`New channel created by: ${systemNotificationUpdated?.channel?.author?.name}`, { body: systemNotificationUpdated?.channel?.title })
      }

      document.body.dispatchEvent(new CustomEvent("channel-event", {
        detail: systemNotificationUpdated
      }));
    }

    if (systemNotificationUpdated.mutation == "CHANNEL_UPDATED") {
      if (systemNotificationUpdated.channel.author._id != ep_admin.user._id) {
        showNotification(`New channel updated by: ${systemNotificationUpdated?.channel?.author?.name}`, { body: systemNotificationUpdated?.channel?.title })
      }

      document.body.dispatchEvent(new CustomEvent("channel-event", {
        detail: systemNotificationUpdated
      }));
    }

    if (systemNotificationUpdated.mutation == "CHANNEL_DELETED") {
      if (systemNotificationUpdated.channel.author._id != ep_admin.user._id) {
        // showNotification(`New channel updated by: ${systemNotificationUpdated?.channel?.author?.name}`, { body: systemNotificationUpdated?.channel?.title })
      }

      document.body.dispatchEvent(new CustomEvent("channel-event", {
        detail: systemNotificationUpdated
      }));
    }


    if (systemNotificationUpdated.mutation == "TICKET_CREATED") {
      if (systemNotificationUpdated.ticket.author._id != ep_admin.user._id) {
        // message.success(`${systemNotificationUpdated.ticket.author.name} has created a new ticket`)
        showNotification(`New ticket by: ${systemNotificationUpdated?.ticket?.author?.name}`, { body: systemNotificationUpdated?.ticket?.subject })
      }

      document.body.dispatchEvent(new CustomEvent("ticket-event", {
        detail: { ticket: systemNotificationUpdated.ticket, mutation: systemNotificationUpdated.mutation }
      }));

      // let _messagesArray = (messagesArray && messagesArray.slice()) || [];
      // _messagesArray.push(systemNotificationUpdated.node);
      // set_setMessagesArray(_messagesArray)
    }

    if (systemNotificationUpdated.mutation == "TICKET_UPDATED") {

      if (systemNotificationUpdated.ticket.author._id != ep_admin.user._id) {
        // message.success(`${systemNotificationUpdated.ticket.author.name} has updated a ticket`);
        showNotification(`New update by: ${systemNotificationUpdated?.ticket?.author?.name}`, { body: systemNotificationUpdated?.ticket?.subject })
      }


      document.body.dispatchEvent(new CustomEvent("ticket-event", {
        detail: { ticket: systemNotificationUpdated.ticket, mutation: systemNotificationUpdated.mutation }
      }));
    }

    if (systemNotificationUpdated.mutation == "TICKET_DELETED") {
      document.body.dispatchEvent(new CustomEvent("ticket-event", {
        detail: { ticket: systemNotificationUpdated.ticket, mutation: systemNotificationUpdated.mutation }
      }));
    }

    if (systemNotificationUpdated.mutation == "TICKET_ARCHIVED") {
      document.body.dispatchEvent(new CustomEvent("ticket-event", {
        detail: { ticket: systemNotificationUpdated.ticket, mutation: systemNotificationUpdated.mutation }
      }));
    }

    if (systemNotificationUpdated.mutation == "TICKET_CLOSED") {
      if (systemNotificationUpdated?.ticket?.author?._id != ep_admin.user._id) {
        // message.success(`${systemNotificationUpdated.ticket.author.name} has closed a ticket`);
        showNotification(`Ticket closed by: ${systemNotificationUpdated?.ticket?.author?.name}`, { body: systemNotificationUpdated?.ticket?.subject })
      }

      document.body.dispatchEvent(new CustomEvent("ticket-event", {
        detail: { ticket: systemNotificationUpdated.ticket, mutation: systemNotificationUpdated.mutation }
      }));
    }


    if (systemNotificationUpdated.mutation == "MESSAGE_ADDED") {
      if (systemNotificationUpdated.message.author._id != ep_admin.user._id) {
        // message.success(`${systemNotificationUpdated.message.author.name} has responded to a ticket`)
        showNotification(`${systemNotificationUpdated.message.author.name} has responded to a ticket`, { body: systemNotificationUpdated.message.body })
      }

      document.body.dispatchEvent(new CustomEvent("message-event", {
        detail: { message: systemNotificationUpdated.message, mutation: systemNotificationUpdated.mutation }
      }));
    }

    if (systemNotificationUpdated.mutation == "MESSAGE_UPDATED") {
      if (systemNotificationUpdated.message.author._id != ep_admin.user._id) {
        if (systemNotificationUpdated.msg_string) showNotification(systemNotificationUpdated.msg_string, { body: systemNotificationUpdated.message.body })
        else showNotification(`${systemNotificationUpdated.message.author.name} has updated the response`, { body: systemNotificationUpdated.message.body })
      }

      document.body.dispatchEvent(new CustomEvent("message-event", {
        detail: { message: systemNotificationUpdated.message, mutation: systemNotificationUpdated.mutation }
      }));
    }

    if (systemNotificationUpdated.mutation == "MESSAGE_DELETED") {
      document.body.dispatchEvent(new CustomEvent("message-event", {
        detail: { message: systemNotificationUpdated.message, mutation: systemNotificationUpdated.mutation }
      }));
    }

    if (systemNotificationUpdated.mutation == "MESSAGE_DISCARDED") {
      document.body.dispatchEvent(new CustomEvent("message-event", {
        detail: { message: systemNotificationUpdated.message, mutation: systemNotificationUpdated.mutation }
      }));
    }




    if (systemNotificationUpdated.mutation == "SMS_RECEIVED") {
      let _from = systemNotificationUpdated?.sms?.from_user?.name || systemNotificationUpdated?.sms.sender?.name || systemNotificationUpdated?.sms?.from;
      // if (systemNotificationUpdated?.sms?.from_user?.name || systemNotificationUpdated?.sender?.name || systemNotificationUpdated?.sms?.from)
      showNotification(`SMS received from ${_from}`, { body: systemNotificationUpdated.sms.body })

      document.body.dispatchEvent(new CustomEvent("sms-event", {
        detail: { sms: systemNotificationUpdated.sms, mutation: systemNotificationUpdated.mutation }
      }));
    }



    if (systemNotificationUpdated.mutation == "ALERT_RECEIVED") {
      // let _from = systemNotificationUpdated?.alerts || systemNotificationUpdated?.sms.sender?.name || systemNotificationUpdated?.sms?.from;
      // if (systemNotificationUpdated?.sms?.from_user?.name || systemNotificationUpdated?.sender?.name || systemNotificationUpdated?.sms?.from)

      let _alerts = systemNotificationUpdated?.alerts;
      console.log("_alerts: ", _alerts)

      showNotification(_alerts[0].subject, { body: _alerts[0].subject })

      document.body.dispatchEvent(new CustomEvent("alert-received", {
        detail: _alerts
      }));
    }

  }

  if(!ep_admin.ap_key || ep_admin.ap_key!=process.env.REACT_APP_APP_KEY || !ep_admin.user || !ep_admin.user._id) return <Login />

  if (ep_admin?.user?._id && (process.env.REACT_APP_APP_VER !== ep_admin.REACT_APP_APP_VER || !ep_admin.token)) {
    alert("Your session has expired, please login again");
    props.logoutUser();
    return <p>Redirecting ...</p>;
  }

  // if (ep_admin.REACT_APP_APP_VER !== process.env.REACT_APP_APP_VER && ep_admin?.user?._id){
  //   alert("Update Available: Please login again to activate updates..");
  //   props.logoutUser();
  //   return null;
  // }


  return (<LayoutWrapper skipContentArea={true}>
    <Subscriber query={NOTIFICATION_SUBSCRIPTION} callback={onSubscriptionReceived} variables={{}} />
    
      <>{children}</>
      {process.env.NODE_ENV=='development' && <div>
        <hr />
        {ep_admin?.user?.permissions?.replaceAll(",", ", ")}
      </div>}
    
    </LayoutWrapper>)
}

const mapStateToProps = ({ ep_admin }) => {
    // console.log("ep_admin: ", ep_admin)
  return ({ ep_admin });
  }
const mapDispatchToProps = (dispatch, ownProps) => ({
  logoutUser: (payload) => dispatch(logoutUser(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withApollo(Wrapper));
  
//   export default Wrapper;

