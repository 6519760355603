import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { PDFViewer, Page, Image, Text as TextPdf, View, Document, StyleSheet } from '@react-pdf/renderer';
import letterhead_header_1 from "./letterhead-header-1.jpg";

import { Row, Col, P, Hr, Text } from './typography'

const pageProps = {
    // size: "A4",
    orientation: "portrait",
    wrap: true,
    // style:{},
    // debug: true,
    dpi: 72,
    // id: 
}

export const Template1 = ({ children, headerRight, pageSize }) => {
    return (<>
        <Document>
            <Page {...pageProps} size={pageSize || "A4"} style={layout1_style.page}>
                <View style={layout1_style.header} fixed>
                    <Row>
                        <Col style={{ flex: "1 1 auto" }}><Image source={letterhead_header_1} style={layout1_style.header_image} /></Col>
                        <Col align="middle"><View style={{ fontSize: 10, paddingRight: 35 }}>{headerRight}</View></Col>
                        {/* <Col align="middle"><TextPdf style={{ fontSize: 10, paddingRight: 35 }}>{headerRight}</TextPdf></Col> */}
                    </Row>
                </View>

                <View style={layout1_style.body}>
                    {children}
                </View>

                <View style={{ ...layout1_style.footer, borderTop: "1px solid #EEE" }} fixed>
                    <Row>
                        <Col flex="auto"><TextPdf>www.alistsecurity.com</TextPdf></Col>
                        <Col flex="100px" align="right">
                            <TextPdf style={layout1_style.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} />
                        </Col>
                    </Row>
                </View>
            </Page>
        </Document>
      </>)
}
export default Template1;

const layout1_style = StyleSheet.create({
    page: {
        paddingBottom: 50,
    },
    header: {
        marginTop: 35,
        marginBottom: 10,
        width: "100%",
    },
    header_image: {
        width: "100%",
        // height: 60,
        paddingHorizontal: 35,
        // paddingLeft: 35,
    },

    body: {
        // border: "5px solid green",
        paddingHorizontal: 35,
        // flex:1,
        // display:"flex",
    },

    footer: {
        position: "absolute",
        paddingHorizontal: 35,
        bottom: 35,
        width: "100%",
        fontSize: 12,
        color: 'grey',

    },
    // pageNumber: {
    //     // position: 'absolute',
    //     fontSize: 12,
    //     color: 'grey',
    //     // bottom: 30,
    //     // left: 0,
    //     // right: 0,
    //     // textAlign: 'center',
    //     // border: "1px solid blue",
    // },

});

