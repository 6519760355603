import React, { useEffect, useState } from 'react'
import { Col, Row  } from 'antd';
import Loader from './Loader';
import Button from './Button';


/*
<Tabs 
      tabsArray={[
        { key: "active", title:"Active" },
        { key: "hired", title:"Hired" },
        { key: "rejected", title:"Rejected" },
      ]} 
      onChange={set_activeTab}
    size="large" />
*/
export const Tabs = ({ size, activeTab, tabsArray = [], onChange, style, loading, onEdit }) => {
    const [_activeTab, set_activeTab] = useState(activeTab || (tabsArray && tabsArray[0]?.key));

    useEffect(() => {
        if (_activeTab == activeTab) return;
        set_activeTab(activeTab)
    }, [activeTab])

    const onTabClick = (v) => {
        if (onChange) onChange(v);
        set_activeTab(v)
    }

    return (<>
        <Row className={`custom-tabs ${size || ''} `} style={style}>
            {tabsArray && tabsArray.map((tab, i) => {
                return <Col key={i}>
                    <span onClick={() => onTabClick(tab.key)} className={`tab-item ${_activeTab == tab.key ? 'active' : ''}`}>{tab.title}</span>
                </Col>
            })}
            {loading && <Col key={'tab_loader'}><Loader loading={true} /></Col>}
            {/* {onEdit && <><Col flex="auto" /><Col key='tab_editor'><Button onClick={onEdit}>Manage</Button></Col></>} */}
            {onEdit && <><Col flex="auto" /><Col key='tab_editor'><Button size="small" onClick={onEdit}>Manage</Button></Col></>}
        </Row>

    </>)
}
export default Tabs