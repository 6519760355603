import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { message, Row, Col, Alert, Divider } from 'antd';
import { loader } from 'graphql.macro';
import { Drawer, Button, Heading, Icon, Loader, ValuePairsSearch, Avatar, TagsSearch, DevBlock, SiteSearch } from 'Common/components'
import { rules, composeValidators, FormField, UploadField, FormComponent } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { mongoToDate } from 'Common/scripts/Functions';
import { defaultDateTimeFormat } from 'configs';
import { verifyRole } from 'Common/scripts/Security';

const GET_RECORD = loader('src/Graphql/Knowledgebases/knowledgebase.graphql');
const RECORD_ADD = loader('src/Graphql/Knowledgebases/addKnowledgebase.graphql');
const RECORD_UPDATE = loader('src/Graphql/Knowledgebases/updateKnowledgebase.graphql');

const defaultValues = { locked:"no" }

const KnowledgebasesForm = (props) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState(defaultValues);

    const [knowledgebase, { called, data}] = useLazyQuery( // { called, loading, data }
        GET_RECORD, { 
            // variables: { filter: JSON.stringify({ _id: Number(props.fields._id) }) }
        }
    );

    const [addKnowledgebase, add_details] = useMutation(RECORD_ADD);
    const [updateKnowledgebase, update_details] = useMutation(RECORD_UPDATE);

    useEffect(() => {
        if (called || loading || data || !props?.fields?._id) return;

        setLoading(true)

        knowledgebase({ variables: { filter: JSON.stringify({ _id: Number(props.fields._id) }) } }).then(r => {
            let resutls = r?.data?.knowledgebase;
            setFields({ ...defaultValues, ...resutls });
            setLoading(false)
        })

    }, [props?.fields?._id])

    const onSubmit = async (values) => {
        const _id = fields ? fields._id : false;

        const input = {
            title: values.title,
            body: values.body,
            // attachments: [AttachmentSchema_Input]
            category: values.category,
            tags: values.tags,
            locked: values.locked,
            site: values.site ? { _id: values.site._id, name: values.site.name } : undefined,
        };
        if (_id) Object.assign(input, { _id })

        let resutls;
        if (input._id) resutls = await updateKnowledgebase({ variables: { input } }).then(r => (r?.data?.updateKnowledgebase))
        else resutls = await addKnowledgebase({ variables: { input } }).then(r => (r?.data?.addKnowledgebase))

        if (!resutls || resutls.error){
            message.error((resutls && resutls?.error?.message) || "Invalid response!");
            setError((resutls && resutls?.error?.message) || "Invalid response!")
            return false;
        }

        message.success("Success");
        props.onSuccess(resutls);
        return false;
    }

    if (loading) return <Loader loading={true} />

    let edit_by = false;
    if (verifyRole('knowledgebases.edithistory', { user: props.user }) && fields.edit_by){
        edit_by = fields.edit_by.slice()
        edit_by.reverse()
    }

    return (<>
        <FormComponent onSubmit={onSubmit} id='knowledgebase_form' loading={loading} fields={fields} debug={false}
            mutators={{
                selectSite: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    let val = { _id: node.value, name: node.children };
                    tools.changeValue(state, 'site', () => val);
                },
                de_selectSite: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'site', () => undefined)
                },
            }}

            form_render={formProps => {
                const { values, submitting, form, invalid, dirty, valid } = formProps;

                return (<>
                    {error && <Alert message="Error" description={error} type="error" showIcon />}

                    <Row>
                        <Col span={16}><FormField type="text" name="title" placeholder="Title" validate={rules.required} /></Col>
                        <Col span={4}><FormField type="select" data={[
                            { _id: "dispatch", title: "Disaptch" },
                            { _id: "general", title: "General" },
                            { _id: "site", title: "Site" },
                        ]} name="category" placeholder="Category" validate={rules.required} /></Col>
                        <Col span={4}><SiteSearch 
                            name="site._id" 
                            placeholder="Site" 
                            // filter={values?.site?._id ? { _id: Number(values.site._id) } : {}}
                            // preload={values?.site?._id ? true : false}
                            onSelect={(txt, node) => form.mutators.selectSite(node)}
                            onDeselect={(txt, node) => form.mutators.de_selectSite(node)}
                            allowClear
                        /></Col>

                        <Col span={20}><TagsSearch preload mode="tags" placeholder="tags" name="tags" /></Col>
                        <Col span={4}><FormField type="select" data={[
                                { _id: "no", title: "Unlocked" },
                                { _id: "yes", title: "Locked" },
                            ]} name="locked" placeholder="Locked" validate={rules.required} />
                        </Col>
                    </Row>
                    

                    <FormField editorStyle={{ height:"calc(110vh - 380px)" }} type="html" name="body" label="body" validate={rules.required} />

                    <div style={{ padding:"0 10px" }}><Button type="primary" htmlType="submit" disabled={submitting || invalid || !dirty} loading={submitting}>Submit</Button></div>

                    {/* <DevBlock obj={values} /> */}
                </>)
            }}
        />

        {edit_by && <>
            <Divider>Edit History</Divider>
            <ol>
                {edit_by.map((item, i) => {
                    return <li key={i}>{item?.user?.name} - {mongoToDate(item.edit_date).format(defaultDateTimeFormat)}</li>
                })}
            </ol>
        </>}

    </>)
}

export const KnowledgebasesComp = props => {
    return (
        <Drawer height={"100%"} destroyOnClose maskClosable={false} placement="top" footer={false}
            onClose={props.onClose}
            visible={props.visible}
            bodyStyle={{ padding:"0 10px 10px 10px" }}
            // footer={<>
            //     <span></span>
            //     <Button loading={busy} type="primary" onClick={() => {
            //         document.getElementById('knowledgebase_form').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
            //     }}>Save</Button>
            // </>}
            title={`${props?.fields?._id ? 'Edit' : 'Add'} Knowledge Article`}
        ><>
            {props.visible && <KnowledgebasesForm {...props} />}
        </></Drawer>
    )
}
KnowledgebasesComp.propTypes = {
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    callback: PropTypes.func,
    fields: PropTypes.object,
}

export default KnowledgebasesComp;