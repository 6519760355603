/* eslint-disable eqeqeq */
import React, { Component, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, message, Result, Divider, Upload, Alert, Steps, Card } from 'antd'
import { InboxOutlined } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import SignaturePad from 'react-signature-canvas'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import axios from 'axios'
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { Button, DataRow, Icon, IconButton, Drawer, Loader, ClientSearch, Heading, ValuePairsSearch, DevBlock } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper';
import { withApollo } from '@apollo/client/react/hoc';
// import moment from 'moment';
import { defaultDateFormat, defaultDateTimeFormat } from 'configs';
// import { removeSpecialChar } from 'Common/scripts/Functions';
import { FORM_KEY } from '../constants'
import { mongoToDate } from 'Common/scripts/Functions';
import PreviewReport from './previewReport';

// const env = process.env;
const ADD_DATA = loader('src/Graphql/FormsData/addFormsData.graphql');
const ADD_ATTACHMENTS = loader('src/Graphql/FormsData/addFormsDataAttachments.graphql');
// const MAX_FILES_UPOLOAD = 4;


const SuccessScreen = () => {
  return (<div style={{ backgroundColor: '#001529', flex: 1, justifyContent: "center", alignItems: "center", display: 'flex' }}>
    <Result
      status="success"
      title={<span style={{ color: 'white' }}>Security Guard Job Application Submitted Successfully!</span>}
      extra={<img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-light.png`} />}
    // extra={<img src="https://sp-ao.shortpixel.ai/client/q_glossy,ret_img/https://alistsecurity.com/wp-content/uploads/2021/03/Website-Logo-Dark-Ver.-2.png" />}
    />
  </div>)
}

const testValue = {
  "report_type": "fire_watch",
  "report_date": "2023-06-06T04:25:00+05:00",
  "client": "1066",
  "project_name": "asd sa dada",
  "project_location": "asd asd asd",
  "unit_num": "123",
  "incident_type": "Broken window",
  "inciden_time": "2023-06-06T06:26:00+05:00",
  "incident_location": "some incident location",
  "reported_by": "reported by the user",
  "reported_to": "reported to someone",
  "reported_to_phone": "123456789",
  "reported_to_email": "mail@mail.com",
  "description": "<p><strong>somethign here</strong></p>\n<ul>\n<li>hello world</li>\n<li>something</li>\n</ul>\n<p>asd a as dad sa dsad sada</p>\n",
  "witness": [
    {
      "name": "witness 1",
      "phone": "123654789",
      "email": "w1@mail.com",
      "details": "some details about witness 1",
      "attachment": [
        {
          "uid": "rc-upload-1686957952786-2",
          "lastModified": 1680036829582,
          "lastModifiedDate": "2023-03-28T20:53:49.582Z",
          "name": "1 (8).png",
          "size": 825192,
          "type": "image/png",
          "percent": 0,
          "originFileObj": {
            "uid": "rc-upload-1686957952786-2"
          }
        }
      ]
    },
    {
      "name": "witness 2",
      "phone": "987654321",
      "email": "w2@mail.com",
      "details": "some details about witness 2",
      "attachment": [
        {
          "uid": "rc-upload-1686957952786-5",
          "lastModified": 1683217345876,
          "lastModifiedDate": "2023-05-04T16:22:25.876Z",
          "name": "Consulting proposal-20230523 (1).pdf",
          "size": 53885,
          "type": "application/pdf",
          "percent": 0,
          "originFileObj": {
            "uid": "rc-upload-1686957952786-5"
          }
        },
        {
          "uid": "rc-upload-1686957952786-7",
          "lastModified": 1683217344757,
          "lastModifiedDate": "2023-05-04T16:22:24.757Z",
          "name": "Consulting proposal-20230523.pdf",
          "size": 53885,
          "type": "application/pdf",
          "percent": 0,
          "originFileObj": {
            "uid": "rc-upload-1686957952786-7"
          }
        }
      ]
    }
  ],
  "actions_taken": "some action taken",
  "recommendations": "some recommendations"
}

const testValue1 = {
  "report_type": {
    "label": "Fire Watch",
    "values": "fire_watch"
  },
  "report_date": "2023-06-13T04:09:00+05:00",
  "client": {
    "label": "Shams Mumtaz",
    "_id": "1065"
  },
  "project_name": "asd sa dada",
  "project_location": "asd asd asd",
  "unit_num": "123",
  "incident_type": "Broken window",
  "inciden_time": "2023-06-14T04:10:00+05:00",
  "incident_location": "asdasd ad sad sad d sa da asdasd ad sad sad d sa da",
  "reported_by": "asd sad asd sa",
  "reported_to": "asd sad sa ds",
  "reported_to_phone": "asd as dsa dsada",
  "reported_to_email": "bluevision@gmail.com",
  "description": "<p>as das da da&nbsp;</p>\n<p>da dsa dsa dadsa dsa dsad</p>\n",
  "witness": [
    {
      "name": "asdasd",
      "phone": "asdasd",
      "email": "bluevision@gmail.com",
      "details": "asd a da dsad adad"
    }
  ],
  "actions_taken": "asd. das dsad",
  "recommendations": "asd ad as dad sa dadsad asd"
}

const defaultValue = {
  // ...testValue1
}

const stepsArray = [
  { title: "Report" },
  { title: "Incident" },
  { title: "Witness" },
  { title: "Action Taken" },
  { title: "Preview" },
]

const ReportPreview = ({ values }) => {

  const Heading1 = ({ children }) => {
    return <div style={{ fontSize:"22px", fontWeight:"600" }}>{children}</div>
  }

  // return <DevBlock obj={values} />

  return (<Col align="center">
    <Heading>Report Preview</Heading>

    <Card className='shadow' style={{ 
      width:"1000px", textAlign:"left",
      backgroundImage: "url('/images/bg-letterhead.jpg')",
      backgroundSize: "cover"
      }}>

      <Col align="center">
        <div style={{ fontSize: "32px" }}>{values.report_type.label} Report</div>
        <div><b>Report Date:</b> {mongoToDate(values.report_date).format(defaultDateFormat)}</div>
        <Col align="center"><b>Customer:</b> {values.client.label}</Col>
      </Col>

      <div style={{ backgroundColor:"#E9F2FD", padding:"10px" }}>
        <div style={{ fontWeight:"bold", fontSize:"16px" }}>Project: {values.project_name}</div>
        <Row gutter={[20]}>
          <Col><b>Location:</b> {values.project_location}</Col>
          <Col><b>Unit#:</b> {values.unit_num}</Col>
        </Row>

        <Row gutter={[20]}>
          <Col><b>Incident Type:</b> {values.incident_type}</Col>
          <Col><b>Incident Time:</b> {mongoToDate(values.inciden_time).format(defaultDateTimeFormat)}</Col>
          <Col><b>Incident Location:</b> {values.incident_location}</Col>
        </Row>

        <Row gutter={[50]}>
          <Col>
            <b>Reported by:</b>
            <div>{values.reported_by}</div>
          </Col>
          <Col>
            <b>Reported to:</b>
            <div>{values.reported_to} | {values.reported_to_phone} | {values.reported_to_email}</div>
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: "30px" }} />
      <Heading1>Description: </Heading1>
      <div style={{ border:"1px solid #EEE", padding:"20px" }} dangerouslySetInnerHTML={{ __html: values.description }} />

      {values.witness && <>
        <div style={{ marginTop: "30px" }} />
        <Heading1>Witness: </Heading1>
        {values.witness.map((w, i)=>{
          return <div key={i}>
            {i>0 && <hr />}

            <Row gutter={[20]}>
              <Col style={{ textAlign:"center", fontWeight:"bold", fontSize:"32px" }}>{i+1}</Col>
              <Col style={{ marginLeft:"5px solid #EEE" }} flex="auto">
                <div><b>Name: </b>{w.name}</div>
                <div><b>Phone: </b>{w.phone}</div>
                <div><b>Email: </b>{w.email}</div>
                <div><b>Details: </b></div>
                <div style={{ }}>{w.details}</div>
                {/* {w.attachment && <ol>
                  {w.attachment.map((item , ii)=>{
                    return <li key={ii}>{item.name}</li>
                  })}
                </ol>} */}
              </Col>
              {w.attachment && <Col>
                <div style={{ backgroundColor:"#EEE", height:"200px", width:"200px" }}>picture</div>
              </Col>}
            </Row>
          </div>
        })}
      </>}
      

      <div style={{ marginTop: "30px" }} />
      <Heading1>WitnessActions Taken: </Heading1>
      <div style={{ border: "1px solid #EEE", padding: "10px", marginBottom:"10px" }}>{values.actions_taken}</div>

      <div style={{ marginTop: "30px" }} />
      <Heading1>Recommendations: </Heading1>
      <div style={{ border: "1px solid #EEE", padding: "10px", marginBottom: "10px" }}>{values.recommendations}</div>

      {values?.extra_attachment?.map((img, i)=>{
        return <div style={{ width: "100%", backgroundColor: "#EEE", marginBottom: "5px" }} key={i}>{img.name}</div>
      })}
      
    </Card>

    {/* <DevBlock obj={values} /> */}
  </Col>)
}


class PoRequestForm extends Component {
  signaturePad = {}
  state = { 
    loading: false, data: null, success: false, signatureDataURL: null, fileList: [], 
    activeStep: 0,
    // max_file_upload: MAX_FILES_UPOLOAD
  }

  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this);
  }

  clearSignature = (event) => {
    event.preventDefault()
    this.signaturePad.clear()
    if (this.state.signatureDataURL !== null) this.setState({ signatureDataURL: null })
  }

  trimSignature = (event) => {
    event.preventDefault()
    const signatureDataURL = this.signaturePad.getTrimmedCanvas().toDataURL('image/png');

    this.setState({ signatureDataURL }, () => {
      console.log("this is the url of sign pad", this.state.signatureDataURL)
    })
  }

  async handleUpload(_id) {
    const { fileList } = this.state;
    if (!fileList || fileList.length<1) return false;

    var _files = fileList.slice()
    if (_files.length > 6) _files = _files.slice(0, 6)
    // else _files = this.state.fileList

    const formData = new FormData();
      formData.append('action', "form_data.attachments");
      formData.append('_id', _id);
      _files.forEach(file => {
        formData.append('files[]', file);
      });

    const uploadResults = await axios.post(process.env.REACT_APP_API_URL + '/upload/form_data', formData)
      .then(({ data }) => {
        // console.log("data: ", data)
        // const { result, status, success, error } = data;
        return data;
      })
      .catch(function (error) {
        console.log(error);
        return { error: { message: "Request Error!" } };
      });

    return uploadResults;
  };



  async onSubmit(values) {
    console.log("onSubmit()", values);

    const { activeStep } = this.state;
    this.moveStep("next");

    if (activeStep < stepsArray.length-1) return;

    alert("Test mode on");
    return;






    const input = { ref_key: FORM_KEY, title: `${values.f_name}`, }
    if (!this.state.signatureDataURL) {
      alert("Oops! you forgot to sign your application!")
      return;
    }

    const fields = [
      { name: 'f_name', label: 'First name', value: values.f_name },
    ]
    Object.assign(input, { fields });

    this.setState({ loading: true, error:null });


    const added = await this.props.client.mutate({ mutation: ADD_DATA, variables: { input } }).then(r => (r.data.addFormsData))
    .catch(err => {
      console.log(__error("ERROR"), err);
      return { error: { message:"Failed to process the request!" }};
    });

    if (added.error){
      message.error(added.error.message);
      this.setState({ loading: false, error: added.error.message });
      return false;
    }

    const attachments = await this.handleUpload(added._id);
    console.log("attachments: ", attachments)

    if(!attachments){
      console.log("No attachments found")
    }
    else if ((attachments && attachments.error) || !attachments.result){
      message.error("Unable to upload your documents, please submit them in person.")
    } 
    else if (attachments && attachments.result && attachments.success){
      await this.props.client.mutate({ mutation: ADD_ATTACHMENTS, variables: { attachments: attachments.result, id: added._id } }).then(r => {
        return r.data.addFormsDataAttachments;
      }).catch(err => {
        console.log(__error("ERROR"), err);
        message.error("Unable to save uploaded data");
      });

      message.success("Submission successfull!")
    }
    
    this.setState({ loading: false, success: added });

    return true;
  }

  moveStep(step){
    if (step=="next") return this.setState({ activeStep: this.state.activeStep+1 })
    if (step=="previous") return this.setState({ activeStep: this.state.activeStep-1 })
    
    this.setState({ activeStep: step })
  }


  render() {
    const { busy, error, success, activeStep } = this.state;
    // const filterString = (e, onChange) => onChange(removeSpecialChar(e.target.value));

    if (success) return <SuccessScreen />

    return (<>
      <FormComponent onSubmit={this.onSubmit} id='PORequestForm' loading={busy} debug={true} fields={defaultValue} moveOnError
        style={{ width: "100%", padding: "0", margin: "0" }}
        mutators={{
          ...arrayMutators,
          updateValue: (newValueArray, state, tools) => {
            let node = newValueArray[0];
            // console.log("node: ", node)
            tools.changeValue(state, node.field_name, () => node.value);
          },
        }}
        form_render={formProps => {
          const { values, submitting, form, invalid, dirty, valid } = formProps;

          return (<>
            {error && <div style={{ padding: "20px" }}><Alert title={<b>Error!</b>} description={error} type='error' showIcon /></div>}

            <div style={{ margin: "0 0 20px 0" }}>
              <Steps current={activeStep} direction="horizontal" size="small" labelPlacement="vertical"
                // onChange={(v) => this.onStepClick(v, values)} 
                type="navigation" className="site-navigation-steps"
              >
                {stepsArray.map((step, i)=>{
                  return (<Steps.Step title={<span style={activeStep == 0 ? { color: "black", fontWeight: "bold" } : {}}>{step.title}</span>} description={step.description} key={i} />)                  
                })}
              </Steps>
            </div>


            {/* Report */}
            {activeStep===0 && <>
              <Heading>Report Details</Heading>

              <Row>
                <Col span={4}>
                  {/* <FormField label="Report Type" placeholder="Report Type" name="report_type" type="select" data={[]} validate={rules.required} /> */}
                  <ValuePairsSearch 
                    keyMap="values=>title"
                    multiselect={false}
                    preload 
                    key_value="report_types"
                    name="report_type.values"
                    label="Report Type"
                    placeholder="Report Type"
                    style={{ width: "100%" }}
                    validate={rules.required}
                    onChange={(___, _raw)=>{
                      // console.log("_raw: ", _raw)
                      form.mutators.updateValue({ field_name: "report_type", value: { label: _raw.title, values: _raw._id } })
                    }}
                  />
                </Col>
                <Col><FormField label="Date" name="report_date" type="date" validate={rules.required} /></Col>
                <Col span={8}>
                  <ClientSearch label="Client" name="client._id" validate={rules.required}
                    onChange={(___, _raw) => {
                      // console.log("_raw: ", _raw)
                      form.mutators.updateValue({ field_name: "client", value: { label: _raw.title, _id: _raw._id } })
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={4}><FormField label="Project Name" placeholder="Project / Site Name" name="project_name" type="text" validate={rules.required} /></Col>
                <Col span={8}><FormField label="Project Location" placeholder="Project / Site Location" name="project_location" type="text" validate={rules.required} /></Col>
                <Col span={4}><FormField label="Unit Number" placeholder="Unit Number if available" name="unit_num" type="text" validate={rules.required} /></Col>
              </Row>
            </>}

            {/* Incident */}
            {activeStep === 1 && <>
              <Heading>Incident Details</Heading>

              <Row>
                <Col span={4}>
                  {/* <FormField label="Incident Type" placeholder="Incident Type" name="inciden_type" type="select" data={[]} validate={rules.required} /> */}
                  <ValuePairsSearch
                    keyMap="values=>title"
                    multiselect={false}
                    preload
                    key_value="incident_types"
                    name="incident_type"
                    label="Incident Type"
                    placeholder="Incident Type"
                    style={{ width: "100%" }}
                    validate={rules.required}
                  />
                </Col>
                <Col><FormField label="Date and Time of Incident" name="inciden_time" type="date" format="DD-MM-YYYY HH:mm" showTime={{ format: 'HH:mm' }} validate={rules.required} /></Col>
                <Col span={8}><FormField label="Incident Location" placeholder="Please provide the specific location where the incident occurred" name="incident_location" type="text" validate={rules.required} /></Col>
              </Row>

              <Row>
                <Col span={8}><FormField label="Reported By" placeholder="Reported By" name="reported_by" type="text" validate={rules.required} /></Col>
              </Row>

              <Row>
                <Col span={8}><FormField label="Reported To" placeholder="Reported To" name="reported_to" type="text" validate={rules.required} /></Col>
                <Col span={8}><FormField label="Phone" placeholder="Phone" name="reported_to_phone" type="text" /></Col>
                <Col span={8}><FormField label="Email" placeholder="Email" name="reported_to_email" type="text" /></Col>
              </Row>

              <FormField label="Please provide a detailed description of the incident, including any relevant information such as individuals involved, actions taken, and any other pertinent details" 
                editorStyle={{ minHeight:"200px" }}
                // data={values.description}
                name="description" type="html" validate={rules.required} />

            </>}
            
            {/* Witness */}
            {activeStep === 2 && <>
              <Heading>Witness Information (if applicable)</Heading>
              <p>Please provide the names and contact information of any witnesses to the incident:</p>

              <FieldArray name="witness">
                {({ fields }) => (
                  <div>
                    {fields.map((name, index) => {

                      return (<Row key={index} style={{ borderBottom: "1px solid #EEE", marginBottom: "0px", flexWrap: "nowrap" }} align="middle" gutter={[10]}>
                        <Col style={{ fontWeight: "bold" }} align="center">
                          <IconButton icon="trash-alt" onClick={() => fields.remove(index)} /><br />
                          Witness<br />
                          <div style={{ fontSize: "32px" }}>{index + 1}</div>
                        </Col>
                        <Col flex="auto" style={{ borderLeft: "3px solid #EEE" }}>
                          <Row align="middle">
                            <Col span={3}><FormField label="Name" placeholder="Witness name" name={`${name}.name`} type="text" validate={rules.required} /></Col>
                            <Col span={3}><FormField label="Phone" placeholder="Witness phone" name={`${name}.phone`} type="text" /></Col>
                            <Col span={3}><FormField label="Email" placeholder="Witness email" name={`${name}.email`} type="email" validate={rules.isEmail} /></Col>
                            <Col flex="auto"><FormField label="Details" placeholder="Witness details" name={`${name}.details`} type="text" validate={rules.required} /></Col>
                            <Col span={24}>
                              <FormField label="Attachment" fileList={values.witness[index]?.attachment} name={`${name}.attachment`} type="file" accept=".png,.jpg,.jpeg" max={1} />
                            </Col>

                          </Row>
                        </Col>
                        {/* <Col>
                          <IconButton icon="trash-alt" onClick={() => fields.remove(index)} />
                          {(fields.length > 1) && <><IconButton icon="trash-alt" onClick={() => fields.remove(index)} /></>}
                        </Col> */}
                      </Row>)
                    })}

                    <div style={{ margin: "20px", textAlign: "center" }}>
                      <IconButton icon="plus" onClick={() => fields.push({ name: "" })} />
                    </div>

                  </div>
                )}
              </FieldArray>

            </>}
            
            {/* Response */}
            {activeStep === 3 && <>
              <Heading>Actions Taken</Heading>
              <FormField label="Actions Taken" placeholder="Please describe any immediate actions taken in response to the incident" name="actions_taken" type="textarea" validate={rules.required} />
              <FormField label="Recommendations" placeholder="Based on the incident, please provide any recommendations or actions that should be taken to prevent similar incidents in the future" name="recommendations" type="textarea" validate={rules.required} />
              <FormField label="Extra Attachment" name={`extra_attachment`} fileList={values.extra_attachment} type="file" multiple accept=".png,.jpg,.jpeg" size="large" max={5} />
            </>}
            
            {/* {activeStep === 4 && <><ReportPreview values={values} /></>} */}
            {activeStep === 4 && <>
              <PreviewReport values={values} />
              {/* <hr />
              <ReportPreview values={values} /> */}
            </>}


            <Row style={{ margin: "50px" }} gutter={[10]} align="middle">
              <Col flex="auto" />
              <Col flex="100px"><Button disabled={activeStep===0} block color="white" onClick={() => this.moveStep("previous")}>Previous</Button></Col>
              {activeStep < stepsArray.length-1 && <Col flex="100px">
                {/* <Button block onClick={() => this.moveStep("next")}>Next</Button> */}
                {/* <Button block htmlType='submit' disabled={!dirty || !valid} >Next</Button> */}
                <Button block htmlType='submit' disabled={!valid} >Next</Button>
              </Col>}
              {activeStep === stepsArray.length-1 && <Col flex="200px">
                <Button size="large" color="orange" block htmlType='submit' disabled={!dirty || !valid} >{values._id ? "Update Report" : "Create Report"}</Button>
              </Col>}
            </Row>


          </>)
        }}
      />
    </>)

  }
}

const PoRequestFormWrapper = ({ showForm, onClose, client }) => {
  const [loading, set_loading] = useState(false);

  return (<>
    <Drawer
      placement="top"
      title={"Create PO Request"}
      height={"100%"}
      onClose={() => onClose()}
      visible={showForm}
      bodyStyle={{ paddingBottom: 80 }}
      extra={<Button onClick={() => onClose()}>Cancel</Button>}
      // footer={<Row><Col flex="auto"></Col><Col>
      //     <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} >Submit </Button>
      // </Col></Row>}
      destroyOnClose={true}
    ><>
        {loading && <Loader style={{ margin: "auto" }} loading={true} />}

        {(!loading && showForm) && <>
          <PoRequestForm client={client} />
        </>}

      </></Drawer>
  </>)
}
PoRequestFormWrapper.propTypes = {
  showForm: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default withApollo(PoRequestFormWrapper)
