import React, { Component } from 'react'
import { message, Row, Col, Alert } from 'antd'
import SignaturePad from 'react-signature-canvas'
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { Button, Icon, IconButton } from 'Common/components'
import Header from './Header';



class SignatureForm extends Component {
    signaturePad = {}
    state = { signatureDataURL: null }

    clearSignature = (event) => {
        event.preventDefault()
        this.signaturePad.clear()
        if (this.props.updateSign) this.props.updateSign("")
    }

    trimSignature = (event) => {
        event.preventDefault()
        const signatureDataURL = this.signaturePad.getTrimmedCanvas().toDataURL('image/png');
        if (this.props.updateSign) this.props.updateSign(signatureDataURL)
    }


    render() {
        let width = 300;
        let height = 150;
        return (<>

            <div style={{ display: "flex", flexDirection: "column", alignContent:"center", alignItems:"center", position: "relative" }} >
                <div>Please sign the test to continue:</div>
                <span style={{ border: "1px solid #CCC", borderRadius: "5px", padding: "0px", width: `${width+2}px`, height: `${height+2}px`, position:"relative" }}>
                    <span><SignaturePad
                            dotSize={1}
                            throttle={50}
                            velocityFilterWeight={0.1}
                            onEnd={this.trimSignature}
                            backgroundColor="#FFF"
                            canvasProps={{ width, height }}
                            required ref={(ref) => { this.signaturePad = ref }} 
                    /></span>
                    <IconButton onClick={this.clearSignature} icon="sync-alt" color="green" style={{ position: "absolute", top: '-15px', right: "-15px" }} size="2x" />
                </span>
            </div>

        </>)
    }
}


export const UserInfoForm = ({ busy, fields, onSubmit, error, title }) => {

    return (<div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>

        <div style={{ alignSelf: "center", textAlign: "left", overflowX:"auto", maxWidth:"600px" }}>
            <Row align="center"><Header title={title} /></Row>

            {error && <Alert message={error} /*description={"error"}*/ type="error" showIcon />}

            <Row align="center">
                    <FormComponent onSubmit={onSubmit} id='UserInfoForm' loading={busy} debug={false} fields={{ ...fields }} moveOnError
                        // style={{ width:"500px" }}
                        mutators={{
                            updateSign: (newValueArray, state, tools) => {
                                let sigUrl = newValueArray[0];

                                tools.changeValue(state, 'candiateInfo.student_signature', () => sigUrl || undefined);
                                
                                //   tools.changeValue(state, 'fileList', () => []);
                              // // tools.changeValue(state, 'tagged_to_users', () => _client);
                            },

                        }}
                        form_render={({ form }) => {
                            return(<>
                                <Row>
                                    <Col flex="auto" style={{ width: "250px" }}><FormField label="Full Name" id="name" name="candiateInfo.name" size="medium" type="text" validate={rules.required} /></Col>
                                    <Col flex="auto" style={{ width: "250px" }}><FormField label="Phone Number" name="candiateInfo.phone_number" size="medium" type="text" validate={rules.required} /></Col>
                                    <Col flex="auto" style={{ width: "250px" }}><FormField label="License Number" name="candiateInfo.lc_number" size="medium" type="text" validate={rules.required} /></Col>
                                </Row>

                                {fields.candiateInfo.student_signature ?
                                    <div style={{ textAlign: "center" }}><img src={fields.candiateInfo.student_signature} /></div>
                                    : <SignatureForm updateSign={form.mutators.updateSign} />
                                }

                                <div style={{ padding: "10px 10px 20px 10px" }}><Button type="primary" block htmlType='submit' size="large" loading={busy}>Continue</Button></div>
                            </>)
                        }}
                    />
            </Row>

        </div>

    </div>)

}

