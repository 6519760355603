import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Breadcrumb, Row, Col, Tabs } from 'antd';
import { Button, Icon } from 'Common/components';
import { connect } from "react-redux";
import { HomeOutlined } from '@ant-design/icons';
import { uriRoot } from 'configs';
// import { modulePermissions } from '.'
// import GuardsList from './containers/guard-list';
// // import ReturnableUniforms from './containers/returnable-uniforms';
// import GuardListWrapper from './containers/guard-list-wrapper';
import GuardList from './containers/guard-list';
import { ContentArea } from 'Layout_v1';
import { Link } from 'react-router-dom';


const Guards = props => {
    const [activeTab, setActiveTab] = useState("guards-list")

    return (<>
        <Row>
            <Col>
                <Breadcrumb style={{ padding: "10px" }}>
                    <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                    {/* <Breadcrumb.Item href="/templates"><span>Templates</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><Link to={`${uriRoot}/guards`}><Icon icon="user-shield" size="1x" /><b> Guard</b></Link></Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col style={{ borderLeft:"1px solid #EEE" }}>
                <Tabs defaultActiveKey="guards-list" onChange={(v) => setActiveTab(v)} tabBarStyle={{ margin: "0px", padding: "0px" }}>
                    <Tabs.TabPane tab="Guards List" key="guards-list" />
                    <Tabs.TabPane tab="Returnable Uniform" key="returnable-uniform" />
                </Tabs>
            </Col>
        </Row>

        <ContentArea>
            {activeTab == 'guards-list' && <GuardList {...props} />}
            {activeTab == 'returnable-uniform' && <>
                <p>This list includes everyone excluding Active, On-Leave </p>
                <GuardList
                    permanent_filter={{
                        $and: [
                            { status: { $nin: ['active', 'on-leave'] } },
                            { company_assets: { $type: 'array', $ne: [] } }
                        ]
                    }}
                    filter_config={{ status: 'hide', skipSettings: true }}
                />
            </>}
        </ContentArea>
    </>)
}


const mapStateToProps = ({ ep_admin }) => {
    return { filter: ep_admin?.pageSettings?.guard_searchBar }
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export default connect(mapStateToProps)(Guards)

