import React, { useState } from 'react'
import PropTypes from 'prop-types'; 
import { Col, Input, Row } from 'antd'
import _ from 'lodash'
import { Button, DevBlock, Icon, List } from 'Common/components';
import { filterGroups, options } from '../constants';

const FilterSelection = ({ onClick, exclude=[] }) => {
    const [kw, setKw] = useState(null);

    const searchFilter = (group) => {
        let filters = _.cloneDeep(group.filters);

        return filters.filter(filter=>{
            let condition = true;
            if (kw) condition = String(filter.title).toLowerCase().includes(String(kw).toLowerCase());
            
            if (condition && exclude && exclude.length>0) condition = exclude.find(o => {
                return o.item.field == filter.field && o.group_key == group.key
            }) ? false : true;

            return condition;
        })

    }


    return (<>
        <div>
            <Input size="large" placeholder="input search text" allowClear
                onChange={e => setKw(e.target.value)}
                // onChange={(e) => { setAllowClear(e?.target?.value?.length > 0); onSearch(e.target.value); }} 
                suffix={kw ? undefined : <Icon icon="search" color="#A7A7A7" />} />
        </div>
        <div style={{ height:"calc(100% - 120px)", overflowY:"auto", paddingTop:"10px" }}>
            {filterGroups.map((group, i) => {
                return <List key={i} title={group.title} 
                    items={searchFilter(group)} 
                    // items={!kw ? group.filters : group.filters.filter(o => String(o.title).toLowerCase().includes(String(kw).toLowerCase()))} 
                    onClick={(item) => onClick({ item, group_key:group.key })}
                />
            })}
        </div>
    </>)
}

FilterSelection.propTypes = {
    exclude: PropTypes.array,
    onClick: PropTypes.func.isRequired,
}

export default FilterSelection;