import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import Logout from './logout';
import { ROOT, MODULE } from './constants'
// import {Icon} from 'common/components'

// addReducers({ admins: reducers });
addModule(
  { path:`${ROOT}`, component:Home, icon:'login', title:"Login", exact:1, leftNav:false },
)
addModule(
  { path: `/logout`, component: Logout, icon:'sign-out-alt', title:"Logout", exact:1, leftNav:false },
)
