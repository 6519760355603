
import { UPDATE_STEPS, INIT_FORM_DATA, UPDATE_INFO, CLEAR_TEST, UPDATE_FORM_DATA } from '../constants';

const initialState = {
    _id: null,
    attendanceSheet: null, // { default_date, student_signature, instructor_signature, fields },
    test_id: null,
    title: null,
    candiateInfo: null, // { name, driving_lc_no }
    steps: null, // { ...TestStepInfo }
    test_result: null,
    status: null,
    signature: null, // { notes signature_data }

    created_at: null,
    updated_at: null

};


function prepareFields(fields){
    if (!fields) return []
    return fields.map(o=>{
        return {
            name: o.name,
            label: o.label,
            value: o.value,
            correctAnswer: o.correctAnswer,
            isCorrect: o.isCorrect,
        }
    })
}


const updateInfo = (state, payload) => {
    if (!payload || !payload._id) return {};

    return {
        ...state,
        _id: payload._id,
        // attendanceSheet: payload.attendanceSheet,
        candiateInfo: payload.candiateInfo,
        status: payload.status,
        // test_id: payload.test_id,
        // test_key: payload.test_key,
        // title: payload.title,

        // name: payload.name,
        // driving_lc_no: payload.driving_lc_no,
        // phone: payload.phone,
        // email: payload.email,

        // fields: prepareFields(payload.fields),
        // results: !payload.results ? [] : payload.results.map(o=>{
        //     return {
        //         step: o.step,
        //         score: Number(o.score),
        //         total: Number(o.total),
        //         percent: Number(o.percent),
        //         timecount: Number(o.timecount),
        //         attempts: o.attempts, // add timecount in seconds
        //     }
        // }),
    }
}

const initForm = (state, payload) => {
    if (!payload || !payload._id) return {};
    return { ...payload }
}

const updateForm = (state, payload) => {
    if (!payload || !payload._id) return {};
    return { ...payload }
}

function updateSteps(state, payload){
    if (!payload.steps){
        alert("Steps are missing");
        return{...state}
    }

    return {
        ...state,
        steps: payload.steps,
    }
}

function clearTest(state, no_payload_required){
    return { ...initialState }
}

const formReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_TEST:
            return clearTest(state, payload);

        case UPDATE_INFO:
            return updateInfo(state, payload);

        case INIT_FORM_DATA:
            return initForm(state, payload);

        case UPDATE_FORM_DATA:
            return updateForm(state, payload);

        case UPDATE_STEPS:
            return updateSteps(state, payload);

        default:
            return state;
    }
}
export default formReducer;

