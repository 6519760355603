import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import { IconButton, Loader } from 'Common/components';
import { message, Row, Col, Popconfirm } from 'antd';
import { connect } from 'react-redux'
import { withApollo } from '@apollo/client/react/hoc';
import { removeModule } from '../InternalStore/actions'
import { loader } from 'graphql.macro';

import ScreenForm from './ScreenForm'
import ScreenPreview from './ScreenPreview'

const DELETE = loader('src/Graphql/Courses/deleteCourseModule.graphql');



class ModulePreview extends Component {
    state = { busy: false, error: null, showForm:false, screenToEdit:null }
    constructor(props){
        super(props);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.toggleModuleForm = this.toggleModuleForm.bind(this);
    }

    onEdit = args => this.props.onEditClick(this.props.module)

    onDelete = args => {
        this.setState({ busy:true, error:null });
        const { module, r_removeModule, client } = this.props;

        client.mutate({ mutation: DELETE, variables: { id: module._id } }).then(r => {
            let response = r.data.deleteCourseModule;
            this.setState({ busy: false });

            if (response.error) {
                message.error(response.error.message)
                return;
            }
            message.success("Module removed!");
            r_removeModule(response)

        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            message.error("Failed to process the request!");
            this.setState({ busy: false, error:"Failed to process the request!" })
        })

    }

    toggleModuleForm(args) {
        if (!args) return this.setState({ screenToEdit: null, showForm: null })
        this.setState({ screenToEdit: args, showForm: true })
    }



    render() {
        const { module, formData } = this.props;
        const { busy, showForm, screenToEdit } = this.state;

        return (<Loader loading={busy}>
            {/* {(module && module._id) && <ModuleForm editNode={module} course_id={formData._id} isEditing={isEditing} onClose={() => setIsEditing(false)} />} */}
            <div style={{ fontSize: "28px" }}>{module.title}
                <IconButton onClick={this.onEdit} icon={{ icon: "pen" }} />
                <Popconfirm onConfirm={this.onDelete} title="Are you sure？">
                    <span><IconButton theme="red" onClick={console.log} icon={{ icon: "trash-alt", color: 'red' }} /></span>
                </Popconfirm>
            </div>
            <div style={{fontSize:"12px"}} dangerouslySetInnerHTML={{ __html: module.body }} />
            

            {/* <ScreensPreview module_id={} /> */}
            {module.screens && module.screens.map((screen, i) => {
                return <Row align='middle' style={{ flexWrap:"nowrap" }} key={i}>
                    <Col style={{width:"50px", fontSize:"30px", textAlign:"right", color:"#CCC", marginRight:"5px"}}>{i+1}</Col>
                    <Col><ScreenPreview module={module} course_id={formData._id} screen={screen} onEditClick={(val) => this.toggleModuleForm({ ...val, module })} key={i} /></Col>
                </Row>
            })}

            <Row>
                <Col flex="auto" />
                <Col><IconButton onClick={() => this.toggleModuleForm({module})} size="large" icon={{ icon: "plus" }} /></Col>
            </Row>

            <ScreenForm showForm={showForm} editNode={screenToEdit} onClose={() => this.toggleModuleForm()} />

            {/* <h2>Screens <ScreenForm course_id={formData._id} module_id={module._id} /></h2> */}
        </Loader>)
    }
}
ModulePreview.propTypes = {
    module: PropTypes.object,
    onEditClick: PropTypes.func.isRequired,
}



const mapStateToProps = ({ formData }) => {
    return { formData };
};
const mapDispatchToProps = dispatch => ({
    r_removeModule: (payload) => dispatch(removeModule(payload)),
});
const Wrapper = connect(mapStateToProps, mapDispatchToProps)(ModulePreview)

export default withApollo(Wrapper);