import React, { Component, useState } from 'react'
import { E404, LayoutFooter } from "Layout_v1";
import { Provider, connect } from 'react-redux'
import configureStore from './InternalStore';
import { DevBlock, Loader } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import { loader } from 'graphql.macro';
import { withApollo } from '@apollo/client/react/hoc';
import TestFormFilable from './Components/TestFormFilable'
import { initForm, clearTest } from './InternalStore/actions'
import './style.scss'

const store = configureStore()
const GET_TEST = loader('src/Graphql/TestResults/testResultByInvitation.graphql');


class InputFormComponent extends Component {
  state = { busy: false, error: null, fatel_error: null, test_form:null };

  componentDidMount(){
    window.onbeforeunload = function () { return false; }

    if(this.props.form){
      let resutls = this.props.form;
      const _resutls = { ...resutls }
      delete _resutls.test_form;

      this.props.initForm(_resutls)
      this.setState({ busy: false, test_form: resutls.test_form });

      return;
    }

    this.fetchData();
  }

  async fetchData(){
    this.setState({ busy: true, fatel_error: null, error: null, test_form:false })

    const { invitationId, client } = this.props;
    const variables = { invitationId }

    const resutls = await client.query({
      query: GET_TEST,
      variables,
      fetchPolicy: "no-cache"
    }).then(r => {
      let response = r.data?.testResultByInvitation;
      return response;

      // if (!response) return this.setState({ busy: false, fatel_error: "Invalid response from server" });
      // if (response.error) return this.setState({ busy: false, fatel_error: response.error.message });

      // const _response = { ...response }
      // delete _response.test_form;

      // this.props.initForm(_response)
      // this.setState({ busy: false, test_form: response.test_form });

    }).catch(err => {
      console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
      return { error: { message:"Unable to complete your request at the moment!"}}
      // this.setState({ busy: false, fatel_error: "Unable to complete your request at the moment!" });
    })

    if (!resutls || resutls.error) return this.setState({ busy: false, fatel_error: (resutls && resutls?.error?.message) || "Invalid response from server" });

    const _resutls = { ...resutls }
    delete _resutls.test_form;

    this.props.initForm(_resutls)
    this.setState({ busy: false, test_form: resutls.test_form });
  }

  render() {
    const { busy, fatel_error, test_form } = this.state;
    // const { invitationId } = this.props;

    // if (!invitationId) return <E404 />;
    if (fatel_error) return <E404 subTitle={fatel_error} />;
    if (busy) return <Loader loading={true} />
    if (!test_form) return <E404 />;


    return (<TestFormFilable test_form={test_form} />)

    // return (<div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
    //   <div style={{ flex: 1 }}><TestFormFilable test_form={test_form} /></div>
    //   <LayoutFooter />
    // </div>)

  }
}

const mapStateToProps = ({ formData }) => {
  return { formData };
};
const mapDispatchToProps = dispatch => ({
  initForm: (payload) => dispatch(initForm(payload)),
  clearTest: (payload) => dispatch(clearTest(payload)),
});

const InputForm = connect(mapStateToProps, mapDispatchToProps)(InputFormComponent)

const Wrapper = props => {
  const invitationId = props?.match?.params?.invitationId;
  const form = props.form;

  if ((!invitationId || invitationId.length < 5) && !form) return <E404 title="Invalid test request" />
  
  return (<Provider store={store}>
    {/* {invitationId && <InputForm {...props} invitationId={invitationId} />} */}
    <InputForm {...props} invitationId={invitationId} />
  </Provider>)
}

export default withApollo(Wrapper);