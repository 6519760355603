import React from 'react'
import { Layout } from 'antd';

const { Footer } = Layout;


export const LayoutFooter = props => {
    return (
        <Footer className="site-footer">A-List Security ©2021</Footer>
    )
}
