import React from 'react';
import { addModule, addReducers } from '../connector';
import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT, MODULE } from './constants'
import TagsList from './tags_list';

export const modulePermissions = getmodulePermissions('tags');

if (modulePermissions["access.tags"]) {
  addModule(
    { path: `${ROOT}`, component: TagsList, icon:'tags', title:"Tags", exact:1, leftNav:true },
  )
}
