import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { ROOT } from './constants';
import { uriRoot } from 'configs';
import { isSuperAdmin } from 'Common/scripts/Security';

import IconList from './IconsList';
import PDFPage from './PDFPage';


var subMenuArray = [
    { path: `${uriRoot}${ROOT}/icons`, exact: true, title: 'Icons', component: IconList },
    { path: `${uriRoot}${ROOT}/pdf`, exact: true, title: 'PDF', component: PDFPage },
]
export const SubMenu = props => subMenuArray;

if(isSuperAdmin()){
    addModule(
        {
            path: `${ROOT}`, component: Home, icon: 'clipboard-list', title: "Template", exact: false, leftNav: true,
            subMenu: SubMenu()
        },
    )
}
