/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import { Row, Col, message, Result, Divider, Upload, Card, Steps } from 'antd'
import { loader } from 'graphql.macro';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import SignaturePad from 'react-signature-canvas'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Button, GuardSearch, Icon, IconButton, SiteSearch } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper';
import { withApollo } from '@apollo/client/react/hoc';
import moment from 'moment';
export const FORM_KEY = 'observation_report';

const ADD_DATA = loader('src/Graphql/FormsData/addFormsData.graphql');

const defaultValue = {
    // duty_date: moment(),
    // event_timeline: [{ event_time: moment() }],
    // issues: [{}],
}

class TheFormComp extends Component {
    signatures = { acknowledgement_signature: null }

    constructor(props) {
        super(props)
        this.state = {
            loading: false, data: null, signatureDataURL: null, fileList: [],
            currentStep: 0
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    clearSignature = (event, name) => {
        event.preventDefault()
        this.signatures[name].clear()
        if (this.state[name] !== null) this.setState({ [name]: null })
    }

    trimSignature = (event, name) => {
        event.preventDefault()
        const signatureDataURL = this.signatures[name].getTrimmedCanvas().toDataURL('image/png');
        this.setState({ [name]: signatureDataURL })
    }

    async onSubmit(values) {
        const input = {
            ref_key: 'incident_report', 
            title: `${values.title}`,
            guard: values?.guard?._id ? { _id: values.guard._id, name: values.guard.name } : undefined,
            site: values?.site?._id ? { _id: values.site._id, name: values.site.name } : undefined,
        }

        const fields = [
            { name: 'duty_date', label: 'Duty Date', value: values.duty_date },
            { name: 'shift_time', label: 'Shift Time', value: values.shift_time },
            { name: 'Summary', label: 'Summary', value: values.summary },
            { name: 'event_timeline', label: 'Timeline of Events', value: JSON.stringify(values.event_timeline) },
            // Issues Identified
            { name: 'issues', label: 'Issues Identified', value: JSON.stringify(values.issues) },
            { name: 'conclusion', label: 'Conclusion', value: values.conclusion },

            { name: 'acknowledgement_signature', label: 'Acknowledgement Signature', value: this.state.acknowledgement_signature },
        ]
        Object.assign(input, { fields })

        const added = await this.props.client.mutate({ mutation: ADD_DATA, variables: { input } }).then(r => (r?.data?.addFormsData))
            .catch(err => {
                console.log(__error("ERROR"), err);
                message.error("Failed to process the request!");
                return false;
            });

        if (!added || added.error) {
            message.error(added.error.message);
            return false;
        }

        this.props.onSuccess(added)
        return false;
    }


    render() {
        const { busy, error, success, currentStep } = this.state;
        const { initialValues } = this.props;
        console.log("initialValues: ", initialValues)

        return (<>
            <div className="form-data" style={{ backgroundColor: "#f8f9fa", margin: "0px" }}>
                <div style={{ display: 'flex', justifyContent: "center" }}>
                    <div style={{ maxWidth: "1200px", width: "100%" }}>

                        <Card style={{ border: "1px #EEEEEE black", backgroundColor: "#FFFFFF", borderRadius: "5px" }}>

                            <FormComponent onSubmit={this.onSubmit} id='IncidentReportForm' loading={busy} debug={true} 
                                fields={{ ...defaultValue, ...initialValues }} 
                                moveOnError
                                mutators={{
                                    ...arrayMutators,
                                    onSiteSelect: (newValueArray, state, tools) => {
                                        let node = newValueArray[0];
                                        tools.changeValue(state, 'site', () => ({ _id: node._id, name: node.name }));
                                    },
                                    onGuardSelect: (newValueArray, state, tools) => {
                                        let node = newValueArray[0];
                                        tools.changeValue(state, 'guard', () => ({ _id: node.value, name: node.label }));
                                    },
                                }}
                                form_render={formProps => {
                                    const { values, submitting, form, invalid, dirty, valid } = formProps;

                                    return (<>
                                        <div>
                                            <Row>
                                                <Col span={24}><Divider style={{ fontSize: "26px" }}>Basic Info</Divider></Col>
                                                <Col span={16} md={18}><FormField name="title" label="Title" size="medium" type="text" validate={rules.required} /></Col>
                                                <Col span={8} md={6}><FormField name="duty_date" label="Date" size="medium" type="date" validate={rules.required} /></Col>

                                                <Col span={24} md={6}><SiteSearch name="site._id"
                                                    preload={(initialValues && initialValues?.site?._id) ? true : false}
                                                    onSelect={(__1, __2, __3) => form.mutators.onSiteSelect(__3)}
                                                    label="Site" validate={rules.required} /></Col>
                                                <Col span={24} md={6}><GuardSearch 
                                                    preload={(initialValues && initialValues?.guard?._id) ? true : false}
                                                    onSelect={(__1, __2) => form.mutators.onGuardSelect(__2)} name="guard._id" label="Guard (optional)" /></Col>
                                                <Col span={24} md={6}><FormField name="shift_time" label="Shift Time" size="medium" type="text" placeholder={'00:00 - 00:00'} validate={rules.required} /></Col>

                                                <Col span={24}><FormField name="summary" label="Summary" size="medium" type="textarea" validate={rules.required} /></Col>

                                                <Col span={24}>
                                                    <Divider style={{ fontSize: "26px", marginTop: "40px" }}>Timeline of Events</Divider>
                                                    <FieldArray name="event_timeline">
                                                        {({ fields }) => (
                                                            <div>

                                                                {fields.map((name, index) => (
                                                                    <Row key={index} style={{ borderBottom: "1px solid #EEE", marginBottom: "2px" }}>
                                                                        <Col flex="50px" style={{ marginTop: "10px", fontSize: "34px", textAlign: "center", display: "flex", flexDirection: "row" }}>
                                                                            <div>{index + 1}</div>
                                                                            {index > 0 && <IconButton style={{ marginTop: "15px", marginLeft: "5px" }} icon="trash-alt" onClick={() => fields.remove(index)} />}
                                                                        </Col>
                                                                        <Col flex="auto">
                                                                            <Row key={index}>
                                                                                <Col flex="150px"><FormField name={`${name}.event_time`} label="Time" returnAs="moment" size="medium" type="time" validate={rules.required} /></Col>
                                                                                <Col flex="auto"><FormField name={`${name}.body`} label="Body" type="textarea" size="medium" validate={rules.required} /></Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>

                                                                ))}

                                                                <Button block onClick={() => fields.push({ event_time: moment() })} type="dashed">Add</Button>

                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                </Col>

                                                <Col span={24}>
                                                    <Divider style={{ fontSize: "26px", marginTop: "40px" }}>Issues Identified</Divider>
                                                    <FieldArray name="issues">
                                                        {({ fields }) => (
                                                            <div>

                                                                {fields.map((name, index) => (
                                                                    <Row key={index} style={{ borderBottom: "1px solid #EEE", marginBottom: "2px" }}>
                                                                        <Col flex="50px" style={{ marginTop: "10px", fontSize: "34px", textAlign: "center", display: "flex", flexDirection: "row" }}>
                                                                            <div>{index + 1}</div>
                                                                            {index > 0 && <IconButton style={{ marginTop: "15px", marginLeft: "5px" }} icon="trash-alt" onClick={() => fields.remove(index)} />}
                                                                        </Col>
                                                                        <Col flex="auto">
                                                                            <FormField name={`${name}.subject`} placeholder="Subject" size="medium" type="text" validate={rules.required} />
                                                                            <FormField name={`${name}.body`} placeholder="Body" size="medium" type="textarea" validate={rules.required} />
                                                                        </Col>
                                                                    </Row>

                                                                ))}

                                                                <Button block onClick={() => fields.push({ event_time: moment() })} type="dashed">Add</Button>

                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                </Col>

                                                <Col span={24}><FormField name="conclusion" label="Conclusion" size="medium" type="textarea" validate={rules.required} /></Col>
                                            </Row>

                                            <div style={{ height: '20px' }} />
                                            <Row>
                                                {/* <Col flex="100px"><Button onClick={() => { this.setState({ currentStep: 0 }) }} block>Back</Button></Col> */}
                                                <Col flex="auto" />
                                                <Col flex="100px"><Button disabled={invalid || submitting} loading={submitting} type="primary" color="orange" block htmlType='submit' size="large" style={{ marginBottom: "50px" }}>Submit</Button></Col>
                                            </Row>

                                        </div>
                                    </>)

                                }}
                            />


                        </Card>

                        <div style={{ height: "20px" }} />
                    </div>
                </div>
            </div>
        </>)

    }
}

export const FirewatchReport = withApollo(TheFormComp)
export default FirewatchReport;
