import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { mongoToDate, sleep, scrollTo, getTimeDiff, getTimeDifference } from 'Common/scripts/Functions';
import { Col, Row, Space, Tooltip, Alert, message, Divider, Modal, Upload } from 'antd'
import html2canvas from 'html2canvas';
import { Avatar, Button, Icon, IconButton, Loader, MoticonIcon, MoticonMenu, UserCardPopover } from 'Common/components';
import { ContentArea } from 'Layout_v1';
import { defaultDateTimeFormat } from 'configs';
import { connect } from "react-redux";
import { verifyRole } from 'Common/scripts/Security';
import { __error } from 'Common/scripts/consoleHelper';
import axios from 'axios'
import { rules, FormField, FormComponent } from 'Common/components/Form'



const POST_MESSAGE = loader('src/Graphql/threads/addThreadsTicketMessage.graphql');
const ATTACHMENT_LIMIT = 4;


const PostCommentsFormComp = ({ replyTo, onCancelReply, ticket, user, onSuccess }) => {
    const [addMessage, { data, loading, error }] = useMutation(POST_MESSAGE);

    const onSubmit = async (values) => {
        let input = {
            // status: String
            threadsTicket_id: Number(ticket._id),
            body: values.body,
            // replied_to: RepliedToSchema_Add
            // attachments: [String]
        }
        if (replyTo) {
            Object.assign(input, {
                replied_to: {
                    msg_id: `${replyTo.message._id}`,
                    img: replyTo.image,
                }
            })
        }

        let results = await addMessage({ variables: { input } }).then(r => (r.data.addThreadsTicketMessage));

        if (results.error) {
            message.error(results.error.message);
            return false;
        }

        var upload_results = await handleUpload({ fileList: values.attachments, ticket_id: results.ticket_id, message_id: results._id }).catch(err => {
            console.log(__error("ERROR: ", err));
            return { error: { message: 'Unable to complete upload request' } }
        });
        if (upload_results && upload_results.error) {
            message.error(upload_results.error.message);
            // return upload_results;
        }

        if (onSuccess) onSuccess(results);
        message.success("Comments added")
        onCancelReply()

        return "reset";

    }

    const handleUpload = async ({ fileList, ticket_id, message_id }) => {
        if (!fileList || fileList.length < 1) return; // { error: { message: "No file to upload!!" } }
        if (fileList.length > ATTACHMENT_LIMIT) return { error: { message: `You can upload max of ${ATTACHMENT_LIMIT} files only!` } }
        if (!ticket_id) return { error: { message: `Missing ticket ID for upload` } }
        if (!message_id) return { error: { message: `Missing message ID for upload` } }

        // message.info("Uploading....")
        const hide = message.loading('Uploading..', 0);

        const formData = new FormData();
        formData.append('action', "message.attachments");
        formData.append('ticket_id', ticket_id);
        formData.append('message_id', message_id);
        fileList.forEach(file => {
            formData.append('files[]', file);
        });

        // return axios.post(process.env.REACT_APP_API_URL + '/upload/job_application/attachments', formData)
        const results = await axios.post(process.env.REACT_APP_API_URL + '/thread_upload/upload', formData)
            .catch((error) => {
                console.log(__error("Error: "), JSON.stringify(error, 0, 2));
                return { error: { message: "Upload request fail!" } };
            });

        hide();
        return results;
    };


    return (<>
        {/* <div style={{ height:"50px" }} /> */}
        <hr />
        <FormComponent onSubmit={onSubmit} id='ThreadMessageForm' debug={false}
            mutators={{
                de_selectClient: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'client', () => undefined)
                },
                selectFiles: (newValueArray, state, tools) => {
                    let file = newValueArray[0];
                    let action = newValueArray[1];
                    let attachments = state.formState.values.attachments || []

                    if (action == 'add') {
                        if (attachments.length >= ATTACHMENT_LIMIT) return;
                        attachments.push(file);
                    }
                    if (action == 'remove') attachments = attachments.filter(o => o.uid != file.uid);

                    tools.changeValue(state, 'attachments', () => attachments);
                },

            }}
            form_render={formProps => {
                const { values, submitting, form, invalid, dirty, valid } = formProps;

                return (<>
                    <div style={{ position: "relative", border: "1px solid #EEE", paddingBottom: "0px" }} className='thread-message-replyForm'>
                        {replyTo && replyTo.image && <div className='replyCaptured'>
                            <div style={{ fontWeight: "bold" }}>Reply To:</div>
                            <Row align='middle'>
                                <Col flex="auto">
                                    <span className='imgHolder'><img src={replyTo.image} className='image' alt="" /></span>
                                </Col>
                                <Col><Button onClick={onCancelReply} color="red" icon={<Icon icon="times" />} /></Col>
                            </Row>
                        </div>}
                        <FormField placeholder={`type in your message...`} name="body" type="textarea" style={{ border: "0px solid #EEE", marginBottom:"5px" }} validate={rules.required} compact />

                        <Row className='nowrap'>
                            <Col flex="auto">
                                <Upload
                                    fileList={values?.attachments}
                                    disabled={values?.attachments?.length >= ATTACHMENT_LIMIT}
                                    onRemove={(file) => {
                                        form.mutators.selectFiles(file, "remove");
                                    }}
                                    beforeUpload={(file) => {
                                        form.mutators.selectFiles(file, "add");
                                        return false;
                                    }}
                                    accept=".jpg,.jpeg,.png,.pdf,.doc.,docx,.xls,.xlsx,.csv"
                                    multiple={true}
                                    maxCount={ATTACHMENT_LIMIT}
                                    showUploadList={false}
                                >
                                    <span className='a' style={{ fontSize: "12px", padding: "2px 5px" }}><Icon icon="paperclip" /> add attachments ({values?.attachments?.length || 0}/{ATTACHMENT_LIMIT})</span>
                                </Upload>

                                {values?.attachments?.map((item, i) => {
                                    return <Row key={i} className='upload-item-list' align="middle">
                                        <Col flex="auto">{item.name}</Col>
                                        <Col style={{ marginLeft: "10px" }}><IconButton onClick={() => form.mutators.selectFiles(item, "remove")} icon="trash-alt" /></Col>
                                    </Row>
                                })}
                            </Col>
                            <Col flex="50px" />
                            <Col><Button type="primary" size="medium" shape="round" htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Send</Button></Col>
                        </Row>
                        {/* <Button type="primary" size="medium" style={{ position:"absolute", bottom:"5px", right:"5px" }} shape="round" htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Send</Button> */}
                    </div>
                </>)
            }}
        />
    </>)
}

const mapStateToProps = ({ ep_admin }) => {
    return ({ user: ep_admin.user });
    // return ({ permissions: ep_admin.user.permissions });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     on_updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export const PostCommentsForm = connect(mapStateToProps, null)(PostCommentsFormComp)
