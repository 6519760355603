import React, { Component, useState, useEffect } from 'react'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Button, Icon, SiteSearch, GuardSearch } from 'Common/components'
import { message, Row, Col } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
// import moment from 'moment'
// import { Form } from 'react-final-form';
import { connect } from "react-redux";
import { updatePageSettings } from 'Store/ep_admin.actions.js'
// import { GUARD_APPLICATION_FORM, CLIENT_INFO_FORM } from '../constants'

const ref_keys = [
    // { _id: GUARD_APPLICATION_FORM, title:'Guard Applications' },
    // { _id: CLIENT_INFO_FORM, title:'Client Information' },
];


const SearchBar = props => {
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);

    const filter = props?.ep_admin?.pageSettings?.client_searchBar;

    useEffect(() => {
        onSubmitSearch(filter || {})
        // if (filter) {
        //     onSubmitSearch(filter || {})
        // } else {
        //     message.info("Please adjust the filter to get records.");
        // }
        //   return () => {
        //     effect
        //   };
    }, [])

    const onSubmitSearch = async (values, redux) => {
        setBusy(true);
        await props.fetchData({ filter:values });
        setBusy(false);

        props.updatePageSettings({
            page: 'client_searchBar',
            values: { ...values }
        })
        return false;
    }

    let defaultValues = { 
        // ref_key: ref_keys[0]._id, 
        ...props.ep_admin?.pageSettings?.client_searchBar 
    };




    return (<>

        <FormComponent onSubmit={onSubmitSearch} id='ClientSearch' loading={busy} debug={false}
            fields={{ ...defaultValues }}
            form_render={formProps => {
                const { values, form, invalid, dirty, valid } = formProps;

                return (<>
                    <Row align="top">
                        {/* <Col flex='300px'>
                            <FormField label="Record Category" name="ref_key" placeholder={"Select Record Category"} size="medium" type="select" data={ref_keys} validate={rules.required} />
                        </Col> */}
                        <Col flex='300px'>
                            <FormField label="Keyword" name="search.keywords" placeholder={"Keyword search.."} size="medium" type="text" allowClear />
                        </Col>
                        {/* <Col><Button style={{ marginTop: "29px" }} type="primary" htmlType='submit' disabled={!dirty || !valid} ><Icon icon="search" /></Button></Col> */}
                        <Col><Button style={{ marginTop: "29px" }} type="primary" htmlType='submit'><Icon icon="search" /></Button></Col>
                    </Row>
                </>)
            }}
        />

    </>)


}
const mapStateToProps = ({ ep_admin }) => {
    // console.log("ep_admin: ", ep_admin.pageSettings.formdata_searchBar)
    return ({ ep_admin });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)

