import React from 'react'
import { Icon, DataRow, DevBlock } from 'Common/components';
import { withApollo } from '@apollo/client/react/hoc';
import { View, Text as TextPdf, StyleSheet, Image, PDFViewer, Document, Page } from '@react-pdf/renderer';
import { Text, Li, P, Row, Col, Hr, Heading, Template3 } from 'Common/components/PdfDoc/components';
import moment from 'moment';
import { Header, Footer, Body } from 'Common/components/PdfDoc/components/template3'
import { defaultDateFormat } from 'configs';

const pageProps = {
    size: "A4",
    orientation: "portrait",
    wrap: true,
    style: { paddingBottom: 50 },
    // debug: true,
    dpi: 72,
    // id: 
}


const FormsDataViewComp = ({ data, client }) => {
    if (!data) return null;

    function getField(_fieldname, fromArray){
        let fields = data.fields;
        return fromArray ? 
            fromArray.find(o => o.name == _fieldname) || {} : 
            fields.find(o => o.name == _fieldname) || {};
    }

    return (<>
        <PDFViewer style={{ width: "100%", height: "calc(100vh - 100px)" }}>
            <Document>
                <Page {...pageProps}>
                    <Header />
                    <Body>
                        <Text style={{ fontSize: "11px" }} center bold>FIRE WATCH REQUIREMENTS DURING SHUTDOWN OF FIRE ALARM AND DETECTION SYSTEMS</Text>
                        <Hr />
                        <Text style={{ fontSize: "10px", marginBottom: "10px", marginTop: "20px" }}>The National Fire Code – Alberta Edition 2019 states:</Text>

                        <View style={{ display: "flex", width: "100%", flexDirection: "column", textWrap: "wrap" }}>
                            <Row style={{ fontSize: "10px" }}>
                                <Col style={{ width: "80px" }}><Text bold>2.8.2.8.</Text></Col>
                                <Col style={{ width: "100%", }}>
                                    <Text bold>Shutdown of Fire Alarm Systems</Text>
                                    <View style={{ height: "10px" }} />
                                    <Li style={{ marginBottom: "10px" }} valueStyle={{ fontSize: "12px" }} value="1)"><Text size="10px">If a fire alarm and detection system, or part thereof, is inoperative for more than 2 hours for any reason, the owner shall notify the fire department, and when directed, provide acceptable surveillance within the building continuously until the fire alarm and detection system is restored to operating condition.</Text></Li>
                                    <Li style={{ marginBottom: "10px" }} valueStyle={{ fontSize: "12px" }} value="2)"><Text size="10px">Procedures acceptable to the fire department shall be developed to notify occupants if a fire or other emergency occurs while the fire alarm and detection system is inoperative. (See Note A- 2.8.2.8.(2).)</Text></Li>
                                </Col>
                            </Row>
                        </View>

                        <Row style={{ fontSize: "10px" }}>
                            <Col style={{ width: "80px" }}><Text bold>A-2.8.2.8.(2)</Text></Col>
                            <Col style={{ width: "100%" }}>
                                <Text>Interruption of normal automatic operation of the fire alarm system for periodic testing purposes constitutes a “temporary shutdown”. Appropriate alternative measures for informing building occupants and the fire department of a fire during shutdown of a fire alarm system should be worked out in cooperation with the local fire department. The alternative measures decided upon should be recorded as part of the building fire safety plan.</Text>
                            </Col>
                        </Row>

                        <P style={{ fontSize: "10px" }} bold>Appropriate alternative measures shall be in the form of a fire watch with the following requirements;</P>

                        <Li valueStyle={{ fontSize: "12px", marginBottom: "10px" }} value="1.">
                            <Text size="10px">Dedicated watchman utilized/employed for fire watch service in a facility/building shall have knowledge of and be capable of performing the following functions:</Text>
                            <View style={{ height: "10px" }} />
                            <Li valueStyle={{ fontSize: "12px" }} value="- "><Text size="10px">Recognizing an emergency situation.</Text></Li>
                            <Li valueStyle={{ fontSize: "12px" }} value="- "><Text size="10px">Transmitting an emergency warning to facility/building occupants.</Text></Li>
                            <Li valueStyle={{ fontSize: "12px" }} value="- "><Text size="10px">Transmitting an emergency message to 911.</Text></Li>
                            <Li valueStyle={{ fontSize: "12px" }} value="- "><Text size="10px">Operating equipment required for fire control and emergency warning.</Text></Li>
                            <View style={{ height: "10px" }} />
                        </Li>
                        <Li valueStyle={{ fontSize: "12px", marginBottom: "10px" }} value="2."><Text size="10px">No other duties shall interfere with a watchman's duties.</Text></Li>
                        <Li valueStyle={{ fontSize: "12px", marginBottom: "20px" }} value="3."><Text size="10px">A sufficient number of watchmen shall be utilized/employed to make at least one complete patrol of the area to be protected <Text bold underline>including stairwells</Text> every 60 minutes or less, depending on the risk and requirements of the AHJ.</Text></Li>
                        <Li valueStyle={{ fontSize: "12px", marginBottom: "10px" }} value="4."><Text size="10px">A process shall be in place to track the patrol route.</Text></Li>
                        <Li valueStyle={{ fontSize: "12px", marginBottom: "20px" }} value="5."><Text size="10px">Each watchman shall be provided with a means of communication to 911 both within and outside of the facility/building required to be protected. (Cellular phone is acceptable).</Text></Li>
                        <Li valueStyle={{ fontSize: "12px", marginBottom: "10px" }} value="6.">
                            <Text size="10px">Each watchman shall be advised of:</Text>
                            <Li valueStyle={{ fontSize: "12px" }} value="- "><Text size="10px">Route to be followed during patrol.</Text></Li>
                            <Li valueStyle={{ fontSize: "12px" }} value="- "><Text size="10px">Details to be observed during the patrol.</Text></Li>
                            <Li valueStyle={{ fontSize: "12px" }} value="- "><Text size="10px">Situations that require notification of emergency personnel.</Text></Li>
                            <Li valueStyle={{ fontSize: "12px" }} value="- "><Text size="10px">Emergency notification instructions.</Text></Li>
                            <Li valueStyle={{ fontSize: "12px" }} value="- "><Text size="10px">Fire safety plan emergency procedures relevant to the area(s) for which fire watch has been assigned.</Text></Li>
                            <Li valueStyle={{ fontSize: "12px" }} value="- "><Text size="10px">Fire extinguishers and/or hose cabinet locations.</Text></Li>
                        </Li>

                        <P bold size="10px">Fire watch shall be maintained until the fire alarm and detection system is fully operational.</P>

                        <View style={{ height: "50px" }} />
                        <P underline size="12">{getField('guard_name').value}</P>
                        {getField('duty_signature')?.value ?
                            <Image source={getField('duty_signature').value} style={{ width: "200px", height: "100px" }} />
                            : <Text></Text>
                        }


                    </Body>
                    <Footer />
                </Page>

                <Page {...pageProps}>
                    <Header />
                    <Body>
                        <Text style={{ fontSize: "16px" }} center bold>FIRE WATCH NOTICE</Text>
                        <P size="12px" bold>DATE: <Text color="blue" underline>{moment(getField("duty_date")?.value).format(defaultDateFormat)}</Text></P>
                        <P size="12px" bold>TIME: <Text color="blue" underline>{moment(getField("duty_time")?.value).format("HH:mm")}</Text></P>

                        <P size="12px">I, <Text underline color="blue">{getField("guard_name")?.value}</Text> take complete responsibility for maintaining fire watch at <Text underline color="blue">{getField("site_address")?.value}.</Text></P>

                        <P size="12px">The above mentioned shall maintain fire watch in all or part of the building according to the National Fire Code - Alberta Edition 2019 and the requirements of the Calgary Fire Department. I understand that fire watch is required until all fire alarm and detection system is repaired and fully functional with documentation made available to the Calgary Fire Department. All documentation is to be emailed or faxed to Fire SCO.</P>

                        <P size="12px">Email address: <Text underline>______________________________</Text> or faxed to <Text underline>(_____)________________.</Text></P>

                        <View style={{ height:"50px" }} />

                        <Row>
                            <Col style={{ width:"50%", marginRight:"10px" }}>
                                <Text size="12px" color="blue">{getField("guard_name")?.value}</Text>
                                <View style={{ borderTop: "1px solid black" }} />
                                <Text size="12px" bold>Building Representative <Text italic>(Please Print)</Text></Text>

                                <View style={{ border: "0px solid black", height: "150px", marginTop:"20px" }}>
                                    {getField('duty_signature')?.value ?
                                        <Image source={getField('duty_signature').value} style={{ width: "200px", height: "100px" }} />
                                        : <Text></Text>
                                    }
                                </View>
                                <P size="12px" style={{ borderTop: "1px solid black" }} bold>Signature</P>
                            </Col>
                            <Col style={{ width: "50%", marginLeft: "10px" }}>
                                <Text size="12px" color="blue"> </Text>
                                <View style={{ borderTop: "1px solid black" }} />
                                <Text size="12px" bold>Fire SCO <Text italic>(Please Print)</Text></Text>

                                <View style={{ border: "0px solid black", height: "150px", marginTop: "20px" }}>
                                    {/* {getField('acknowledgement_signature')?.value ?
                                        <Image source={getField('acknowledgement_signature').value} style={{ width: "200px", height: "100px" }} />
                                        : <Text></Text>
                                    } */}
                                </View>
                                <P size="12px" style={{ borderTop: "1px solid black" }} bold>Signature</P>
                            </Col>
                        </Row>
                        
                    </Body>
                    <Footer />
                </Page>
            </Document>

        </PDFViewer>

        {/* <DevBlock obj={data} /> */}

    </>)

}
export const FormsDataView = withApollo(FormsDataViewComp)
export default FormsDataView;