import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { PDFViewer, Page, Image, Text as TextPdf, View, Document, StyleSheet } from '@react-pdf/renderer';
import letterhead_header_2 from "./letterhead-header-2.jpg";
import calgary_firewatch_logo1 from "./images/calgary_firewatch_logo1.png";
import calgary_firewatch_logo2 from "./images/calgary_firewatch_logo2.png";

import { Row, Col, P, Hr, Text } from './typography'

const pageProps = {
    // size: "A4",
    orientation: "portrait",
    wrap: true,
    // style:{},
    // debug: true,
    dpi: 72,
    // id: 
}

export const Header = () => {
    return (<View style={layout3_style.header} fixed>
        <Row style={{ padding: "0 50px 20px 50px" }}>
            <Col><Image source={calgary_firewatch_logo2} style={{ width: "auto", height: "60px" }} /></Col>
            <Col flex="auto" />
            <Col><Image source={calgary_firewatch_logo1} style={{ width: "auto", height: "60px" }} /></Col>
        </Row>
    </View>)
}
export const Footer = () => {
    return (<View style={{ ...layout3_style.footer, borderTop: "1px solid #EEE" }} fixed>
        <Row>
            <Col flex="auto" style={{ fontSize: "7px" }}><Text>The City of Calgary | P.O.Box 2100 Stn. M | Calgary, AB, Canada T2P 2MS | <Text color="red">calgary.ca</Text></Text></Col>
            <Col flex="100px" align="right">
                <TextPdf style={layout3_style.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} />
            </Col>
        </Row>
    </View>)
}
export const Body = ({ children }) => (<View style={layout3_style.body}>{children}</View>)

export const Template3 = ({ children, headerRight, pageSize }) => {
    return (<>
        <Document>
            <Page {...pageProps} size={pageSize || "A4"} style={layout3_style.page}>
                <Header />
                <Body>{children}</Body>
                <Text>Hello world</Text>
                <Footer />
            </Page>
        </Document>
      </>)
}
export default Template3;


const layout3_style = StyleSheet.create({
    page: {
        paddingBottom: 50,
    },
    header: {
        marginTop: 35,
        marginBottom: 10,
        width: "100%",
    },
    header_image: {
        // width: "100%",
        width: 310,
        height: 70,
        paddingHorizontal: 35,
        // paddingLeft: 35,
    },

    body: {
        // border: "5px solid green",
        paddingHorizontal: 35,
        // flex:1,
        // display:"flex",
    },

    footer: {
        position: "absolute",
        paddingHorizontal: 35,
        bottom: 35,
        width: "100%",
        fontSize: 12,
        color: 'grey',

    },
    // pageNumber: {
    //     // position: 'absolute',
    //     fontSize: 12,
    //     color: 'grey',
    //     // bottom: 30,
    //     // left: 0,
    //     // right: 0,
    //     // textAlign: 'center',
    //     // border: "1px solid blue",
    // },


});
