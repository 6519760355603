import React, { Component, useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Button, DevBlock, Heading, Icon, Loader } from 'Common/components'
import { Row, Col, Popover, Drawer, message, Tabs, Modal, Alert } from 'antd';
import FilterManager from './components/FilterManager'
import { updatePageSettings } from 'Store/ep_admin.actions.js'
import { connect } from "react-redux";
import { loader } from 'graphql.macro';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper';
import FilterEditor from './containers/FilterEditor';
import { removeFromArray, updateToArray, addToArray } from 'Common/scripts/query_helpers'
import FilterInfoForm from './components/FilterInfoForm';
import './styles.scss'
import { mongoToDate } from 'Common/scripts/Functions';

const RECORDS = loader('src/Graphql/filtered_views/filteredViews.graphql');
const RECORD_EDIT = loader('src/Graphql/filtered_views/editFilteredView.graphql');
const UPDATE_FILTER = loader('src/Graphql/filtered_views/updateFilteredViewFilter.graphql');
const RECORD_ADD = loader('src/Graphql/filtered_views/addFilteredView.graphql');
const RECORD_DELETE = loader('src/Graphql/filtered_views/deleteFilteredView.graphql');


class FilteredViews extends Component {
    state = { 
        activeTab: null, // "all-records", 
        showViewManager: false, 
        showViewCreator: false, 
        showEditView:false,
        busy: false, 
        editorSection: null,
        
        initialValues: null,
        filterToEdit:null,
    }
    constructor(props){
        super(props);
        this.onTabClick = this.onTabClick.bind(this);
        this.toggleViewManager = this.toggleViewManager.bind(this);
        this.toggleViewCreator = this.toggleViewCreator.bind(this);
        this.onEditFilterClick = this.onEditFilterClick.bind(this);
        this.saveSorting = this.saveSorting.bind(this);
        this.deleteFilteredView = this.deleteFilteredView.bind(this);
        this.addFilteredView = this.addFilteredView.bind(this);
        this.editFilteredView = this.editFilteredView.bind(this);
        this.doUpdateFilter = this.doUpdateFilter.bind(this);
        this.doUpdateView = this.doUpdateView.bind(this);
        this.onEditViewClick = this.onEditViewClick.bind(this);
    }

    onTabClick(key){
        let thisFilter = this?.props?.filteredViews.find(o => String(o._id) == String(key)) || {};

        this.setState({ filterToEdit: thisFilter, activeTab: key }, ()=>{
            this.props.onTabClick(thisFilter)
        })
    }

    toggleViewManager () {
        this.setState({ showViewManager: !this.state.showViewManager })
    }

    saveSorting (selection) {
        this.props.updatePageSettings({
            page: 'schedule_views',
            values: {
                selected_filters: selection
            }
        })
    }

    // Filter View Functions
    deleteFilteredView = (rec) => {
        this.setState({ busy:true })

        return this.props.deleteFilteredView(rec._id).then(r => {
            this.setState({ busy: false });

            var response = r.data.deleteFilteredView;
            if (response.error) message.error(response.error.message);
            if (!response.error) removeFromArray(this.props, 'filteredViews', response._id, RECORDS, { filter: this.props.queryFilter });

            return response;
        }).catch(err => {
            this.setState({ busy: false });

            console.log(__error("Query Error"), err);
            message.error("Query Error");
            return { error: { message: "Query Error" } }
        });
    }

    // addFilteredView (input){
    //     return this.props.addFilteredView(input).then(r => {
    //         const response = r.data.addFilteredView;
    //         if (!response.error) addToArray(this.props, 'filteredViews', response, RECORDS, { filter: JSON.stringify({ category: this.props.category }) });

    //         return response;
    //     }).catch(err => {
    //         console.log(__error("Query Error"))
    //         console.error(err);
    //         return { error: { message: "Query Error" } }
    //     })

    // }

    editFilteredView (input){
        return this.props.editFilteredView(input).then(r => {
            const response = r.data.editFilteredView;
            if (!response.error) updateToArray(this.props, 'filteredViews', response, RECORDS, { filter: this.props.queryFilter });

            return response;
        }).catch(err => {
            console.log(__error("Query Error"))
            console.error(err);
            return { error: { message: "Query Error" } }
        })
    }

    onEditViewClick(rec) {
        console.log("onEditViewClick()", rec);
        this.setState({ showEditView: rec })
    }

    onEditFilterClick(rec) {
        console.log("onEditFilterClick()", rec);
        this.setState({ filterToEdit: rec, showViewCreator: true, showViewManager: false, editorSection: 'filter' })
    }

    toggleViewCreator(args) {
        let { showViewCreator } = this.state;

        this.setState({ 
            filterToEdit: args && args.filterToEdit || null, 
            showViewCreator: !showViewCreator,
            editorSection: args && args.editorSection || 'new'
        });
    }

    async addFilteredView(input){
        let results = await this.props.addFilteredView(input).then(r => {
            const response = r.data.addFilteredView;
            if (!response.error) addToArray(this.props, 'filteredViews', response, RECORDS, { filter: this.props.queryFilter });

            return response;
        }).catch(err => {
            console.log(__error("Query Error"))
            console.log(err)
            return { error: { message: "Query Error" } }
        })

        if (results.error){
            message.error(results.message.error);
        }else{
            this.setState({ filterToEdit: results, editorSection:'filter' })
        }

        return results;

    }
    
    async doUpdateView(input){
        console.log("doUpdateView()")

        let results = await this.props.editFilteredView(input).then(r => {
            const response = r.data.editFilteredView;
            if (!response.error) updateToArray(this.props, 'filteredViews', response, RECORDS, { filter: this.props.queryFilter });

            return response;
        }).catch(err => {
            console.log(__error("Query Error"))
            console.log(err)
            return { error: { message: "Query Error" } }
        })

        if (results.error){
            message.error(results.message.error);
        }else{
            this.setState({ editorSection: null, showViewCreator:false })
            // this.setState({ filterToEdit: results, editorSection:null })
        }

        return results;

    }

    async doUpdateFilter(input){
        console.log("doUpdateFilter()")

        let results = await this.props.updateFilteredViewFilter(input).then(r => {
            const response = r.data.updateFilteredViewFilter;
            if (!response.error) updateToArray(this.props, 'filteredViews', response, RECORDS, { filter: this.props.queryFilter });

            return response;
        }).catch(err => {
            console.log(__error("Query Error"))
            console.log(err)
            return { error: { message: "Query Error" } }
        })

        if (results.error) {
            message.error(results.message.error);
        } else {
            this.setState({ filterToEdit: results, showViewCreator: true, editorSection:'filter' })
        }

        return results;

    }

    renderTabs(){
        const { filteredViews } = this.props;
        const { selected_filters } = this.props.schedule_views || {};

        let selected = !selected_filters ? [] : selected_filters.map(o => filteredViews.find(oo => oo._id == o._id)).filter(o=>(o!=null));
        
        let permanents = filteredViews.filter(o => {
            let exists = selected.find(oo=>oo._id==o._id);
            if (exists) return false;
            return o.permanent == 'yes';
        }) || [];

        selected = selected.concat(permanents)

        return selected?.map(thisFilter => {
            let title = thisFilter.title;
            
            // Adding month to title
            let _filter_str = JSON.parse(thisFilter.filter_str);
            let _second_salary_half = _filter_str && _filter_str?.find(o => 
                (o?.filter?.check_in?.farmula == "second_salary_half") || 
                (o?.filter?.check_in?.farmula == "first_salary_half") || 
                (o?.filter?.check_in?.farmula == "current_month")
                )
            if (_second_salary_half) title = thisFilter.title +" - "+ mongoToDate().format("MMM");

            if (!thisFilter) return null;
            return <Tabs.TabPane tab={<span className='tab-label'>{title}</span>} key={`${thisFilter._id}`} />
        })

    }

    render() {
        const { loading, left, right, filteredViews, category } = this.props;
        const { activeTab, filterToEdit, showViewManager, editorSection, showViewCreator, busy } = this.state;
        // const { selected_filters } = this.props.schedule_views || {};
        // console.log("selected_filters: ", selected_filters)

        if (loading) return <div className='tab-wrapper'><Loader loading={true} /></div>

        return (<>
            <div className='tab-wrapper'>
                <Row style={{ flexWrap: "nowrap" }} className="tabs-row" align="middle">
                    {left && <Col>{left}</Col>}

                    <Col style={{ overflow: "hidden" }} className="tabs-row-col" flex="auto">
                        <Tabs size="large" activeKey={activeTab} defaultActiveKey="all-records" tabPosition={"top"} onTabClick={this.onTabClick}
                            tabBarGutter={5}
                            tabBarExtraContent={<>
                                <Row gutter={[5]}>
                                    <Col><Button onClick={() => this.toggleViewCreator()} type="dashed" icon={<Icon icon="plus" />}>Add View</Button></Col>
                                    <Col><Button onClick={() => this.toggleViewManager()} type="dashed" icon={<Icon icon="cog" />}>Manage View</Button></Col>
                                    {/* <Col><a href='#' onClick={()=>this.toggleViewCreator()}><u><Icon icon="plus" /> Add View</u></a></Col> */}
                                    {/* <Col><a href='#' onClick={this.toggleViewManager}><u><Icon icon="cog" /> Manage</u></a></Col> */}
                                </Row>
                            </>}
                        >
                            {/* <Tabs.TabPane tab={<span className='tab-label'>All Records</span>} key={`all-records`} /> */}

                            {this.renderTabs()}

                            {/* {selected_filters && selected_filters?.map(o => {
                                let thisFilter = filteredViews && filteredViews.find(fil => fil._id == o._id);
                                if (!thisFilter) return null;
                                return <Tabs.TabPane tab={<span className='tab-label'>{thisFilter.title}</span>} key={`${thisFilter._id}`} />
                            })} */}
                        </Tabs>
                    </Col>

                    {right && <Col>{right}</Col>}
                </Row>
            </div>

            <FilterEditor
                debug={true}
                initialValues={filterToEdit}
                category={category}
                onClose={()=>this.toggleViewCreator(false)}
                showform={showViewCreator}
                section={editorSection}

                addFilteredView={this.addFilteredView}
                doUpdateView={this.doUpdateView}
                doUpdateFilter={this.doUpdateFilter}

                // addFilteredView={this.addFilteredView}
                // editFilteredView={this.editFilteredView}
            />


            <Modal title="Edit View" visible={!(this.state.showEditView == false)} footer={false} onCancel={() => this.setState({ showEditView:false })}>
                {this.state.showEditView && 
                    <FilterInfoForm 
                        initialValues={this.state.showEditView}
                        category={this.state.showEditView.category}
                        doUpdateView={this.doUpdateView}
                        onSave={() => this.setState({ showEditView:false })} 
                        onCancel={() => this.setState({ showEditView: false })} 
                    />
                }
            </Modal>

            {/* Manage Filter View */}
            <Drawer title="Manage Views" width="400px" placement="right" onClose={this.toggleViewManager} visible={showViewManager}>
                {showViewManager && <>
                    <FilterManager
                        loading={busy}
                        // type={props._key}
                        category={category}
                        filters={filteredViews}
                        selected_filters={this.props?.settings?.selected_filters}
                        onClose={this.toggleViewManager}
                        showDrawer={showViewManager}
                        onEditClick={this.onEditViewClick}
                        onEditFilterClick={this.onEditFilterClick}
                        deleteFilteredView={this.deleteFilteredView}
                        saveSorting={this.saveSorting}
                    />
                </>}
            </Drawer>

        </>)
    }
}
FilteredViews.propTypes = {
    category: PropTypes.string.isRequired,
    default_activeTab: PropTypes.string,
    left: PropTypes.oneOfType([ PropTypes.func, PropTypes.object ]),
    right: PropTypes.oneOfType([ PropTypes.func, PropTypes.object ]),
    onTabClick: PropTypes.func.isRequired,
    onfilterUpdate: PropTypes.func.isRequired,

    // settings: PropTypes.object.isRequired,
    // updateUserSettings: PropTypes.func.isRequired,
}


const WithApollo = compose(
    graphql(RECORDS, {
        options: ({ category, user }) => {
            let filter = { 
                category,
                $or:[
                    { owner: Number(user._id) },
                    { shared: 'yes' },
                ]
            }
            if (user.acc_type == "super-admin") delete filter.$or;

            return {
                variables: { filter: JSON.stringify(filter) },
                // fetchPolicy: "no-cache",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, filteredViews, error } = data;

            let filter = {
                category: ownProps.category,
                $or: [
                    { owner: Number(ownProps.user._id) },
                    { shared: 'yes' },
                ]
            }
            if (ownProps.user.acc_type == "super-admin") delete filter.$or;

            if (error) console.log(__error("error"), error);
            return { loading, filteredViews, queryErrors: error, queryFilter: JSON.stringify(filter) }
        },
    }),
    
    graphql(UPDATE_FILTER, {
        props: ({ mutate }) => ({
            updateFilteredViewFilter: (input) => mutate({
                variables: { input }
            }),
        })
    }),

    graphql(RECORD_EDIT, {
        props: ({ mutate }) => ({
            editFilteredView: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),

    graphql(RECORD_ADD, {
        props: ({ mutate, ownProps }) => {
            return {
                addFilteredView: (args) => mutate({
                    variables: { input: { ...args } },
                    // update: (cache, { data: { addFilteredView } }) => {
                    //     console.log("cache: ", cache)
                    //     console.log("addFilteredView: ", addFilteredView)

                    //     const data = cache.readQuery({ 
                    //         query: RECORDS,
                    //         variables: { filter: JSON.stringify({ category: ownProps.category }) }
                    //     });

                    //     console.log("before update: ", data)
                    //     // data.filteredViews = [...data.filteredViews, addFilteredView];
                    //     let _filteredViews = data.filteredViews ? data.filteredViews.slice() : [];
                    //     _filteredViews.push(addFilteredView);
                    //     console.log("after update: ", _filteredViews)

                    //     cache.writeQuery({ 
                    //         query: RECORDS, 
                    //         variables: { filter: JSON.stringify({ category: ownProps.category }) } 
                    //     }, {
                    //         data: {filteredViews: _filteredViews}
                    //     });
                    // }
                }),
            }
        }
    }),
    graphql(RECORD_DELETE, {
        props: ({ mutate, ownProps }) => ({
            deleteFilteredView: (id) => mutate({
                variables: { id },
            }),
        })
    }),

)(FilteredViews);

const Wrapper = props => {
    if (!props.category) return <Alert message="View Filters" description="missing category" type="error" showIcon />

    return <WithApollo {...props} />
}

const WithApolloClient = withApollo(Wrapper);

const mapStateToProps = ({ ep_admin }) => {
    // console.log("ep_admin?.pageSettings?.schedule_views: ", ep_admin?.pageSettings?.schedule_views)
    return { 
        schedule_views: ep_admin?.pageSettings?.schedule_views,
        user: ep_admin.user
    }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(WithApolloClient)

