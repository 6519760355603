import React, { Component, useState, useEffect } from 'react'
import { loader } from 'graphql.macro';
import { Popover, Alert, message, Row, Col, Breadcrumb, Tooltip, Space } from 'antd';
import { PlusOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Loader, DevBlock, Icon, DataRow, Avatar, ActionMenu, IconButton, Tabs } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import Notes, { NotesGroup } from 'Modules/Notes';
import { mongoToDate } from 'Common/scripts/Functions';
import { defaultDateFormat, uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import BasicProfile from '../components/BasicProfile';
import UserAvatar from '../components/UserAvatar';
import CompnayAssets from '../components/CompnayAssets';
import EmergencyContacts from '../components/EmergencyContacts';
import Availability from '../components/Availability';
import { verifyRole } from 'Common/scripts/Security';
// import ReportsList from 'Modules/FormsData/Components/FormsDataList';
import { Link, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { matchPath, withRouter } from "react-router";
import { ContentArea, E404 } from "Layout_v1";
import { useLazyQuery } from '@apollo/client';
import AllReports from 'Modules/FormsData/containers/all_reports';

const DETAIL = loader('src/Graphql/guard/guard.graphql');
// const STATUS_UPDATE = loader('src/Graphql/guard/editGuard.graphql');

const requiredFields = [
    { key: 'security_license_no', message:'Security License' },
    { key: 'security_license_expiry', message:'Security License Expiry' },
    { key: 'sin', message:'SIN Number' },
    { key: 'emergency_contact', message:'Emergency Contacts' },
    { key: 'company_assets', message:'Compnay assets / Uniform' },
]

const AvailabilityComp = ({ data, onUpdate }) => {
    return (<>
        <div className='preview_card'><Availability data={data} onUpdate={onUpdate} /></div>
        <div style={{ height: "12px" }} />

        <NotesGroup data={data} types={[
            { key: 'Guard_Information', title: "General Notes", permissionsReq: "general.notes" },
            { key: 'hr_guard', title: "HR Notes", permissionsReq: "hr.notes" },
        ]} />
    </>)
}

const GuardReports = ({ data, onUpdate }) => {
    return (<>
        <div className='preview_card'>
            <AllReports match={{ params: { guard_id: data._id } }} inline />
        </div>
    </>)
}


const TabsArea = ({ data, onUpdate, params, inline }) => {
    let isGuardHome = matchPath(params.path, { path: `${uriRoot}/guard/:guard_id`, exact: true, strict: true });
    let isReports = matchPath(params.path, { path: `${uriRoot}/guard/reports/:guard_id`, exact: true, strict: true });
    let isJds = matchPath(params.path, { path: `${uriRoot}/guard/jds/:guard_id`, exact: true, strict: true });

    const [activeTab, set_activeTab] = useState(isReports ? "guard/reports" : isJds ? "guard/jds" : "guard");
    let history = useHistory();

    return (<>
        <Tabs
            activeTab={activeTab}
            tabsArray={[
                { key: "guard", title: "Availability" },
                { key: "guard/reports", title: "Reports" },
            ]}
            onChange={(val) => {
                if (!inline) history.push(`${uriRoot}/${val}/${params.guard_id}`)
                set_activeTab(val)
            }}
            size="large"
        />

        {activeTab == 'guard' && <AvailabilityComp data={data} />}
        {activeTab == 'guard/reports' && <GuardReports data={data} />}

        {/* <Switch>
            <Route exact={true} path={`${uriRoot}/guard/:guard_id`} component={() => <AvailabilityComp data={data} />} />
            <Route exact={true} path={`${uriRoot}/guard/reports/:guard_id`} component={() => <GuardReports data={data} />} />
            <Route key={999} component={E404} />
        </Switch> */}
    </>)
}

const DetailCompWrapper = props => {
    const [state, setState] = useState({ loading: false, data: null, error: null, showTerminationProcess: false, activeTab: "profile" })
    const [params, set_params] = useState(null);

    // let _params = useParams();
    // let history = useHistory();

    const [getGuard, guard_results] = useLazyQuery(
        DETAIL, //{ variables: { id: params?.guard_id } }
    );

    useEffect(() => {
        if (params != null) return; 

        let _params = props.location.pathname.split("/");
        let segments = []
        for (let a = _params.length-1; a>0; a--){
            segments.push(_params[a]);
        }
        if (!isNaN(segments[0])){
            let __params = {
                path: props.location.pathname,
                guard_id: Number(segments[0]),
                segments
            }
            set_params(__params)
            fetchGuard(__params)
        }

    }, [params])

    const fetchGuard = async (__params) => {
        if (!__params?.guard_id) return;

        setState({ loading: true, error: null })

        let resutls = await getGuard({
            variables: { id: __params.guard_id }
        }).then(r => (r.data.guard)).catch(err=>{
            console.log(__error("Error: "), err);
            return { error:{message:"Invalid resutls"}}
        })

        if (!resutls || resutls.error){
            setState({ loading: false, error: (resutls && resutls?.error?.message) || "Guard not found!" })
            return;
        }

        setState({ loading: false, data: resutls })
    }

    const onUpdate = (values) => setState({ ...state, data: values })

    if (state.loading) return <Loader loading={true} />;
    if (state.error) return <Alert message="Error" description={state.error} type="error" showIcon />;
    if (!state.data) return <p>No data found</p>;

    let alerts = requiredFields.filter(o => {
        if (!state.data[o.key] || state.data[o.key].length < 1) return true;
        return false;
    })


    // if (props.inline) return <DetailsComp _id={state.data._id} onUpdate={onUpdate} data={state.data} />

    return (<>
        {!props.inline && 
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item href="#"><Link to={`${uriRoot}/guards`}><Icon icon="user-shield" size="1x" /> Guards</Link></Breadcrumb.Item>
                {/* <Breadcrumb.Item>Guard profile details</Breadcrumb.Item> */}
                {state?.data?.f_name && <Breadcrumb.Item>{state?.data?.f_name}{` `}{state?.data?.m_name}{` `}{state?.data?.l_name}</Breadcrumb.Item>}
            </Breadcrumb>
        }

        {(alerts && alerts.length > 0 && alerts[0].key) && <Alert type='warning' showIcon message={<>
            <div style={{ fontWeight: "bold" }}>Missing following infomration</div>
            <ul>
                {alerts.map((item, i) => (<li key={i}>{item.message}</li>))}
            </ul>
        </>} />}

        <ContentArea transparent>
            <div style={{ minWidth: "1000px" }}>
                <Row gutter={[20]}>
                    <Col flex="auto" />
                    {state.data.updated_at && <Col><span>Updated  {mongoToDate(state.data.updated_at).format(defaultDateFormat)}</span></Col>}
                    {state.data.created_at && <Col><span>Created  {mongoToDate(state.data.created_at).format(defaultDateFormat)}</span></Col>}
                </Row>

                <Row style={{ flexWrap: "nowrap" }} gutter={[12]}>
                    <Col flex="400px">
                        <div className='preview_card'>
                            <Row gutter={[5]}>
                                <Col flex="auto" />
                                <Col><Button size="small"><Link to={`${uriRoot}/guard/guard_reports/${params.guard_id}`}>Reports</Link></Button></Col>
                            </Row>
                            <div style={{ height: "5px" }} />
                            <UserAvatar data={state.data} onUpdate={onUpdate} />
                            <BasicProfile data={state.data} onUpdate={onUpdate} />
                        </div>
                        <div style={{ height: "12px" }} />

                        <div className='preview_card'><EmergencyContacts data={state.data} onUpdate={onUpdate} /></div>

                        <div style={{ height: "12px" }} />
                        <div className='preview_card'><CompnayAssets data={state.data} onUpdate={onUpdate} /></div>

                        {verifyRole('guard.sensitive-data') && <>
                            <div style={{ height: "12px" }} />
                            <div className='preview_card'>
                                <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="file-alt" /> Documents</div>
                                <Notes type="document" id={params.guard_id} formkey="guard_documents" />
                            </div>
                        </>}

                    </Col>

                    <Col flex="auto">
                        <TabsArea params={params} inline={props.inline} onUpdate={onUpdate} data={state.data} />
                    </Col>

                </Row>

            </div>
        </ContentArea>

    </>)

}

export const Detail = withApollo(withRouter(DetailCompWrapper))
export default Detail

