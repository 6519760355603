import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Popconfirm, Alert, message, Row, Col, Tooltip, Tabs } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';
import { mongoToDate } from 'Common/scripts/Functions';
import { Link } from 'react-router-dom';
import { __error } from 'Common/scripts/consoleHelper'
import { Table, Loader, Icon, IconButton, Button } from 'Common/components';
import { defaultPageSize, uriRoot, defaultDateFormat } from 'configs'

const DATA_LIST = loader('src/Graphql/FormsData/formsDataQuery.graphql');

class FormsDataListComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null,
            error: null,
            filter: this.props.filter || {},
            pagination: { current: 1, pageSize: defaultPageSize, total:0 },
        };
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData = async (args = {}) => {
        const { pagination } = this.state;

        let filter = { ...args.filter, ...this.props.filter }
        let _pagination = {
            current: args.current || pagination.current || 1,
            pageSize: args.pageSize || pagination.pageSize || defaultPageSize
        }

        const variables = {
            first: _pagination.pageSize, // number of rec to fetch
            after: (_pagination.pageSize) * (_pagination.current - 1),
            filter: JSON.stringify(filter)
        }

        this.setState({ loading: true })

        const resutls = await this.props.client.query({ query: DATA_LIST, variables }).then(r => (r.data.formsDataQuery))
        .catch(err => {
            console.log(__error("ERROR: "), err);
            return { error: "Request error!" }
        })

        if (resutls.error){
            return this.setState({ loading: false, error: resutls.error.message });
        }

        this.setState({
            loading: false,
            data: resutls,
            pagination: {
                ...this.state.pagination,
                current: _pagination.current,
                pageSize: _pagination.pageSize,
                total: resutls.totalCount
            }
        });


    }

    componentDidUpdate(prevProps, prevState){
        // console.log("componentDidUpdate: > this.props :", this.props)
        if (JSON.stringify(prevProps.permanent_filter) !== JSON.stringify(this.props.permanent_filter))
            this.fetchData()
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ current: pagination.current, pageSize: pagination.pageSize });
    };

    // searchList(value) {
    //     this.fetchData({
    //         // filter: { $or: [{ "personalinfo.f_name": value }, { "personalinfo.sin_no": value}]}
    //         filter: { search: { keywords: value } }
    //     });
    // }


    render() {
        const { pagination, loading, data, error } = this.state;

        return (<>
            {error && <Alert message="Error" description={error} type="error" showIcon />}

            <Table loading={loading} size="small"
                // this.props.tableHead
                // title={() => {
                //     return (<div>
                //         <Row>
                //             <Col>
                //                 <div style={{ fontSize: "18px", fontWeight: "bold" }}>{this.props.title}</div>
                //                 <div style={{ fontSize: "12px" }}>Total {((data && data.totalCount) || '0')} record(s)</div>
                //             </Col>
                //             <Col flex="auto" />
                //             <Col>
                //                 <FormDataSearchBar fetchData={(args) => this.fetchData(args)} />
                //             </Col>
                //         </Row>
                //     </div>)
                // }}
                columns={this.props.columns}
                dataSource={data ? data.edges : null}
                pagination={pagination}
                onChange={this.handleTableChange}
                rowClassName={(record => record.status == 'new' ? 'hilighted-table-row' : "")}
                // onChange={this.handleTableChange}
            />
        </>)
    }
}
FormsDataListComp.propTypes = {
    filter: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    tableHead: PropTypes.func,
}

export default withApollo(FormsDataListComp)
