import React, { Component, useCallback, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux"; 
import { message, Row, Col, Popconfirm, Checkbox, Space } from 'antd';
import { Drawer, Button, Loader, IconButton, Table, Icon } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
// import { dateToMongo, sleep } from 'Common/scripts/Functions';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';


const type = 'DraggableBodyRow';


const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);

    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) return {};
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        },
    });

    const [, drag] = useDrag({
        type,
        item: { index, },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drop(drag(ref));

    return (
        <tr ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{ cursor: 'move', ...style, }}
            {...restProps}
        />
    );
};


const FilterManager = props => {
    const [busy, setBusy] = React.useState(false);
    const [dirty, setDirty] = React.useState(false);
    const [selectedFilters, set_selectedFilters] = React.useState(props?.selected_filters?.map(o=>o._id) || []);
    // const [filters, set_filters] = React.useState(null);
    const { onClose, showDrawer, loading } = props;

    const [data, setData] = useState(null);

    useEffect(() => {
        // putting filtered items on top in order, at screen load
        // let selected = props.filters.filter(o => props?.selected_filters.findIndex(sf=>String(sf._id)==String(o._id))>-1)
        let selected = props?.selected_filters?.map(o => props.filters.find(fl=>fl._id==o._id)) || []
        let unselected = props?.filters?.filter(o => !(props?.selected_filters?.findIndex(sf => String(sf._id) == String(o._id)) > -1)) || []
            
        setData(selected.concat(unselected))

        //   return () => { };
    }, [props.filters])

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };

    const columns = [
        { title: '', dataIndex: 'drager', render: (__, rec) => <Icon style={{ fontSize:"20px" }} icon="bars" />, width:"35px" },
        { title: '', dataIndex: '_id', render: (__, rec) => {

            let checkboxProps = {
                onChange: (e) => onRawSelected(e, rec),
                // disabled: !(rec.permanent == 'yes'),
                checked: selectedFilters.indexOf(rec._id) > -1,
                value: rec._id
            }
            if (rec.permanent == 'yes') Object.assign(checkboxProps, { disabled: true, checked:true })

            return <Checkbox {...checkboxProps} />
        }, width: "35px" },
        { title: 'Filters List', dataIndex: 'title', key: 'title', render:(__, rec) => {
            return (<>
                {/* <div>{rec.permanent == 'yes' && <Icon icon="thumbtack" color="#999" />} {rec.title}</div> */}
                {rec.title}
                <Row style={{ fontSize: "12px", color: "#999" }}>
                    <Col flex="auto"><span>Created by: {rec?.ownerDetails?.name || "None"}</span></Col>
                    <Col>{rec.shared=='yes' ? 'shared' : null}</Col>
                </Row>
                
                {/* <Space>
                    {rec.permanent == 'yes' && <Icon icon="thumbtack" color="#999" />}
                    {rec.shared == 'yes' && <Icon icon="share-alt" color="#999" />}
                </Space> */}
            </>)
        } },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions', width: "85px",
            style:{border:"1px solid black"},
            render: (__, rec) => {
                if (String(rec.owner) != String(props.user._id) && props.user.acc_type!="super-admin") return null;

                return <Row style={{flexWrap:"nowrap"}}>
                    <Col><IconButton onClick={() => {
                        props.onEditClick(rec);
                        onClose();
                        }} icon="pen" /></Col>
                    <Col><IconButton onClick={() => props.onEditFilterClick(rec)} icon="filter" /></Col>
                    <Col>
                        <Popconfirm title="Sure to delete?" onConfirm={() => props.deleteFilteredView(rec)}>
                            <IconButton icon="trash-alt" />
                        </Popconfirm>
                    </Col>
                    {/* <Col><IconButton onClick={() => props.deleteFilteredView(rec)} icon="trash-alt" /></Col> */}
                </Row>
            }
        },
    ];

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = data[dragIndex];
            setData(
                update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );
            setDirty(true);
        },
        [data],
    );

    const saveSorting = async args => {
        // console.log({ data });
        // console.log({ selectedFilters });

        const userData = []
        for (let i = 0; i < data.length; i++){
            if (data[i]){
                if (selectedFilters.indexOf(data[i]._id)>-1){
                    userData.push({ _id: data[i]._id, title: data[i].title })
                }
            }
        }

        setBusy(true);
        await props.saveSorting(userData)
        // await sleep(1500);
        setBusy(false);
        onClose()
    }

    const onRawSelected = (e, rec) => {
        let _selectedFilters = selectedFilters ? selectedFilters.slice() : [];
        let checked = _selectedFilters.find(o => o == rec._id);

        if (!checked) _selectedFilters.push(e.target.value)
        else _selectedFilters = _selectedFilters.filter(o => o != rec._id)

        set_selectedFilters(_selectedFilters)
        setDirty(true)
    }

    return (<>
        <Drawer width={'600'} destroyOnClose maskClosable={false} placement="right"
            // loading={loading || busy}
            bodyStyle={{ padding: "10px" }}
            onClose={onClose}
            visible={showDrawer}
            // footer={false}
            footer={<>
                <span></span>
                <Button onClick={saveSorting} loading={busy} disabled={!dirty}>Save</Button>
                {/* <Button loading={busy} type="primary" onClick={() => {
                    document.getElementById('FilteredViewForm').dispatchEvent(new Event('submit', { cancelable: true }))
                }}>Save</Button> */}
            </>}
            title={`Manage Filtered Views - ${props.category}`}
        ><>
                <Loader loading={loading || busy}>
                    <DndProvider backend={HTML5Backend}>
                        <Table
                            pagination={false}
                            columns={columns}
                            dataSource={data && data.filter(o=>(o))}
                            components={components}
                            onRow={(_, index) => {
                                const attr = { index, moveRow, };
                                return attr;
                            }}
                        />
                    </DndProvider>
                </Loader>

        </></Drawer>
    </>)
}

FilterManager.propTypes = {
    filters: PropTypes.array.isRequired,
    selected_filters: PropTypes.array,
    onClose: PropTypes.func.isRequired,
}

// const WithApollo = compose(
//     graphql(RECORD, {
//         options: ({ fields }) => {
//             return {
//                 variables: { id: fields._id },
//                 fetchPolicy: "no-cache",
//             };
//         },
//         props: ({ ownProps, data }) => {
//             const { loading, filteredView, error } = data;
//             if (error) console.log(__error("error"), error);
//             return { loading, filteredView, queryErrors: error, }
//         },
//     }),
// )(WithApollo);


const mapStateToProps = ({ ep_admin }) => {
    return { 
        user: ep_admin.user,
        schedule_views: ep_admin?.pageSettings?.schedule_views,
        selected_filters: ep_admin?.pageSettings?.schedule_views?.selected_filters
    }
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export default connect(mapStateToProps)(FilterManager)
