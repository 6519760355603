import React from 'react'
import { Icon } from './Icon'
import { Col, Popover, Row } from 'antd'
import Button from './Button'
// import { LikeTwoTone } from '@ant-design/icons';
{/* <FrownTwoTone /><HeartTwoTone /><SmileTwoTone /> */}



const icons = "thumbs-down,thumbs-up,smile,tired,surprise,grin-wink,sad-cry,sad-tear,meh-rolling-eyes,meh-blank,grin-squint-tears,grin-hearts,flushed,angry,frown,grimace";

export const MoticonMenu = ({ onClick }) => {
  return (<>
      <Popover placement="bottom" title={false}
        content={<Row gutter={[10, 5]} style={{ width: "275px", fontSize: "24px" }}>
            {icons.split(",").map((o, i) => (<Col onClick={() => onClick(o)} key={i}><Icon className="moticon_button" icon={o} /></Col>))}
        </Row>}>
          <Button color="white" className="menu-btn"><Icon className="menu_icon" icon="smile" /></Button>
      </Popover>
  </>)
}

export const MoticonIcon = () => {

}
