import {
  ApolloClient, InMemoryCache,
  ApolloLink, HttpLink, from, split, execute,
} from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getMainDefinition } from 'apollo-utilities';
import { connect } from "react-redux";

const NETWORK_INTERFACE_URL = process.env.REACT_APP_GRAPHQL_PATH; //"http://localhost:4000/graphql";
const SUBSCRIPTION_CLIENT_URL = process.env.REACT_APP_SUBSCRIPTION_CLIENT_URL; //"ws://localhost:4000/subscriptions";


const ApolloWrapper = ({ children, user, token }) => {
  /******** Setting up apollo-link ********/
  const httpLink = new HttpLink({ uri: NETWORK_INTERFACE_URL }); // createHttpLink({ uri: NETWORK_INTERFACE_URL });
  
  const middlewareLink = setContext(() => ({
    headers: {
      'x-token': localStorage.getItem('token'),
      'x-refresh-token': localStorage.getItem('refreshToken'),
      authorization: `Bearer ${token || undefined}`
      // authorization: `Bearer ${localStorage.getItem('token')}`
      // authorization: `Bearer token-123456`
    },
  }));

  const afterwareLink = new ApolloLink((operation, forward) => {
    const { headers } = operation.getContext();

    if (headers) {
      const token = headers.get('x-token');
      const refreshToken = headers.get('x-refresh-token');

      if (token) localStorage.setItem('token', token);
      if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
    }

    return forward(operation);
  });

  const timeStartLink = new ApolloLink((operation, forward) => {
    operation.setContext({ start: new Date() });
    return forward(operation);
  });
  const logTimeLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      // data from a previous link
      const time = new Date() - operation.getContext().start;
      console.log(`APOLLO: operation ${operation.operationName} took ${time} to complete`);
      return data;
    })
  });
  const linkLogger = timeStartLink.concat(logTimeLink);

  const httpLinkWithMiddleware = linkLogger.concat(
    afterwareLink.concat(
      middlewareLink.concat(httpLink)
    )
  );

  const SubClient = new SubscriptionClient(SUBSCRIPTION_CLIENT_URL, {
    reconnect: true,
    inactivityTimeout: 0,
    // onConnected: ()=>{
    //   console.log(`Subscription Socket connectd on ${SUBSCRIPTION_CLIENT_URL}`);
    // },
    connectionCallback: (error) => {
      if (!error)
        console.log(`🚀 Subscription Socket connectd on ${SUBSCRIPTION_CLIENT_URL}`);
      else
        console.log("🚨 ERROR - Subscription Socket Connection Error: ", error);
    }
  });

  const wsLink = new WebSocketLink(SubClient);

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLinkWithMiddleware,
  );

  const ApolloDefaultOptions = {
    watchQuery: {
      fetchPolicy: 'network-only', //no-cache,network-only/
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  };

  const aClient = new ApolloClient({
    // uri: NETWORK_INTERFACE_URL,
    link,
    cache: new InMemoryCache(),
    onError: ({ networkError, graphQLErrors }) => {
      console.log('graphQLErrors', graphQLErrors)
      console.log('networkError', networkError)
    },
    errorPolicy: 'all',
    defaultOptions: ApolloDefaultOptions
  });

  return (<ApolloProvider client={aClient}>
    {children}
  </ApolloProvider>)

}

const mapStateToProps = ({ ep_admin }) => { 
  return {
    user: ep_admin.user ? ep_admin.user : false,
    token: ep_admin.token
  };
}
export default connect(mapStateToProps)(ApolloWrapper);

