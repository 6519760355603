import React, { Component } from 'react'
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup } from 'Common/components/Form'
import { OnChange } from "react-final-form-listeners";
import { DevBlock, Button, GuardSearch, SiteSearch, Icon } from 'Common/components'
import { Row, Col, message, Tooltip } from 'antd'
import { dateFarmulaOptions } from '../constants';
import { Form } from 'react-final-form';
import { defaultDateFormat } from 'configs';

export const customMutators = {
    resetField: (newValueArray, state, tools) => {
        newValueArray.forEach((field) => {
            tools.changeValue(state, field, () => undefined)
        })
    },
    setField: (newValueArray, state, tools) => {
        tools.changeValue(state, newValueArray[0], () => newValueArray[1]);
    }
}

const ddLabel = ({ title, description }) => {
    return <span>
        {description && <Tooltip title={description}><Icon icon="exclamation-circle" color="skyblue" /></Tooltip> }
        {` `}
        {title}
    </span>
    // return <span><div style={{ fontWeight: "bold" }}>{title}</div><div>{description}</div></span>
}

export const renderFields = (filter, values) => {

    switch (filter.item.type) {
        case 'keyword_search':
            return (<>
                <FormField type="single-radio" name="option" value="contains" label={<span style={{ fontSize: "16px" }}>contains</span>} />
                {values?.option == 'contains' &&
                    <FormField type="select" name={`${filter.item.field}.contains`} data={[]} mode="tags" placeholder="Add values" validate={rules.required} />
                }

                <FormField type="single-radio" name="option" value="not_contains" label={<span style={{ fontSize: "16px" }}>doesn't contain</span>} />
                {values?.option == 'not_contains' &&
                    <FormField type="select" name={`${filter.item.field}.not_contains`} data={[]} mode="tags" placeholder="Add values" validate={rules.required} />
                }

                {/* <FormField type="single-radio" name="option" value="exists" label={<span style={{ fontSize: "16px" }}>is known</span>} />
                <FormField type="single-radio" name="option" value="not_exists" label={<span style={{ fontSize: "16px" }}>is unknown</span>} /> */}
            </>)

            break;

        case 'guard':
            return (<>
                <FormField type="single-radio" name="option" value="eq" label={<span style={{ fontSize: "16px" }}>is</span>} />
                {values?.option == 'eq' &&
                    <GuardSearch name={`${filter.item.field}.eq`} placeholder="Guards" validate={rules.required} />
                }
            </>)

            break;

        case 'site':
            return (<>
                <FormField type="single-radio" name="option" value="eq" label={<span style={{ fontSize: "16px" }}>is</span>} />
                {values?.option == 'eq' &&
                    <SiteSearch name={`${filter.item.field}.eq`} placeholder="Guards" validate={rules.required} />
                }
            </>)

            break;

        case 'approval_status':
            return (<>
                <FormField type="single-radio" name="option" value="eq" label={<span style={{ fontSize: "16px" }}>is</span>} />
                {values?.option == 'eq' &&
                    <FormField 
                    data={[
                        { _id: 'new', title:'Not Approved' },
                        { _id: 'approved', title:'Approved' }
                    ]}
                    type="select" name={`${filter.item.field}.eq`} placeholder="Verification" validate={rules.required} />
                }
            </>)

            break;

        case 'date':
            return (<>
                <FormField label={<span style={{ fontSize: "16px" }}>is</span>} type="single-radio" name="option" value="farmula" />
                {values?.option == 'farmula' &&
                    <FormField type="select" showSearch
                        optionFilterProp="children"
                        inputProps={{
                            filterOption: (input, option) => {
                                // console.log({ input, option })
                                return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        }}
                        name={`${filter.item.field}.farmula`} data={dateFarmulaOptions}
                        itemRender={ddLabel}
                        validate={rules.required}
                    />
                }

                <FormField type="single-radio" name="option" label={<span style={{ fontSize: "16px" }}>is equal to</span>} value="eq" />
                {values?.option == 'eq' &&
                    <FormField type="date" name={`${filter.item.field}.eq`} placeholder="The date" validate={rules.required} />
                }

                <FormField type="single-radio" name="option" value="lt" label={<span style={{ fontSize: "16px" }}>is before</span>} />
                {values?.option == 'lt' &&
                    <FormField type="date" name={`${filter.item.field}.lt`} placeholder="The date" validate={rules.required} />
                }

                <FormField type="single-radio" name="option" value="gt" label={<span style={{ fontSize: "16px" }}>is after</span>} />
                {values?.option == 'gt' &&
                    <FormField type="date" name={`${filter.item.field}.gt`} placeholder="The date" validate={rules.required} />
                }

                <FormField type="single-radio" name="option" value="between" label={<span style={{ fontSize: "16px" }}>is between</span>} />
                {/* {values?.option == 'between' && <Row>
                    <Col><FormField type="date" name={`${filter.item.field}.between[0]`} validate={rules.required} /></Col>
                    <Col><FormField type="date" name={`${filter.item.field}.between[1]`} validate={rules.required} /></Col>
                </Row>} */}

                {values?.option == 'between' &&
                    <FormField 
                        type="date-range" 
                        format={defaultDateFormat}
                        name={`${filter.item.field}.between`} 
                        placeholder={["Start date", "End date"]} 
                        validate={rules.required} 
                            
                        />
                }

                {/* <FormField type="single-radio" name="option" value="exists" label={<span style={{ fontSize: "16px" }}>is known</span>} />
                <FormField type="single-radio" name="option" value="not_exists" label={<span style={{ fontSize: "16px" }}>is unknown</span>} /> */}

            </>)

            break;

        case 'number':
            return (<>
                <FormField type="single-radio" name="option" value="eq" label={<span style={{ fontSize: "16px" }}>is equal to</span>} />
                {values?.option == 'eq' &&
                    <FormField type="number" name={`${filter.item.field}.eq`} validate={rules.required} />
                }
                <FormField type="single-radio" name="option" value="ne" label={<span style={{ fontSize: "16px" }}>is not equal to</span>} />
                {values?.option == 'ne' &&
                    <FormField type="number" name={`${filter.item.field}.ne`} validate={rules.required} />
                }
                <FormField type="single-radio" name="option" value="gt" label={<span style={{ fontSize: "16px" }}>is greater than</span>} />
                {values?.option == 'gt' &&
                    <FormField type="number" name={`${filter.item.field}.gt`} validate={rules.required} />
                }
                <FormField type="single-radio" name="option" value="gte" label={<span style={{ fontSize: "16px" }}>is greater than or equal to</span>} />
                {values?.option == 'gte' &&
                    <FormField type="number" name={`${filter.item.field}.gte`} validate={rules.required} />
                }
                <FormField type="single-radio" name="option" value="lt" label={<span style={{ fontSize: "16px" }}>is less than</span>} />
                {values?.option == 'lt' &&
                    <FormField type="number" name={`${filter.item.field}.lt`} validate={rules.required} />
                }
                <FormField type="single-radio" name="option" value="lte" label={<span style={{ fontSize: "16px" }}>is less than or equal to</span>} />
                {values?.option == 'lte' &&
                    <FormField type="number" name={`${filter.item.field}.lte`} validate={rules.required} />
                }
                <FormField type="single-radio" name="option" value="between" label={<span style={{ fontSize: "16px" }}>is between</span>} />
                {values?.option == 'between' && <Row>
                    <Col><FormField type="number" name={`${filter.item.field}.between[0]`} validate={rules.required} /></Col>
                    <Col><FormField type="number" name={`${filter.item.field}.between[1]`} validate={rules.required} /></Col>
                </Row>}

                {/* <FormField type="single-radio" name="option" value="exists" label={<span style={{ fontSize: "16px" }}>is known</span>} />
                <FormField type="single-radio" name="option" value="not_exists" label={<span style={{ fontSize: "16px" }}>is unknown</span>} /> */}

            </>)

            break;

        default:
            return (<>
                <p>filter not defined!</p>
                <DevBlock obj={filter} />
            </>)

            break;
    }

}


export const FilterForm = ({ filter, onSubmit, onClear, hideTitle }) => {
    const onSubmitForm = values => {
        let data = { ...filter, filter: values }
        onSubmit(data)
    }

    return (<>
        {!hideTitle && <p style={{ fontWeight: "bold", fontSize: "16px" }}>{filter.item.title}</p>}

        <FormComponent onSubmit={onSubmitForm} id='SingleFilterForm' loading={false} 
            fields={(filter && filter.filter) ? filter.filter : {}} enterSubmit={false} 
            debug={false}
            mutators={{ resetField: customMutators.resetField, setField: customMutators.setField, }}
            form_render={({ values, form, dirty, invalid }) => {

                return (<>
                    <OnChange name="option">{(value, previous) => {
                        form.mutators.resetField(`${filter.item.field}`);
                        if (value == 'exists') form.mutators.setField(`${filter.item.field}.exists`, "true");
                        else if (value == 'not_exists') form.mutators.setField(`${filter.item.field}.exists`, "false");
                    }}</OnChange>

                    {renderFields(filter, values)}

                    <Row>
                        {onClear && <Col flex="auto"><Button onClick={() => onClear(filter)} disabled={!values.option} type="default" style={{ marginTop: "20px", marginLeft: "10px" }}>Clear</Button></Col>}
                        <Col><Button type="primary" htmlType="submit" disabled={!dirty || invalid} style={{ marginTop: "20px", marginLeft: "10px" }}>Apply</Button></Col>
                    </Row>                    

                </>)
            }}
        />

    </>)

}
export default FilterForm;