import React, { useState } from 'react'
import { Button, DataRow, IconButton, Loader } from 'Common/components';
import moment from 'moment';
import { defaultDateFormat } from 'configs';
import { Alert, Col, Modal, Row, Space } from 'antd';
import BasicProfileForm from './BasicProfileForm';
import { verifyRole } from 'Common/scripts/Security';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'

const UPDATE_STATUS_NOTE = loader('src/Graphql/guard/updateGuardStatusNotes.graphql');

const StatusNotesForm = ({data, onUpdate}) => {
    const [updateGuardStatusNotes, status_details] = useMutation(UPDATE_STATUS_NOTE);

    const onSubmit = async (values) => {
        await updateGuardStatusNotes({ variables: { id: data._id, status_notes: values.status_notes } })
            .then(r => onUpdate({ status_notes: r?.data?.updateGuardStatusNotes?.status_notes || "" }))
        return false;
    }

    return (<>
        <FormComponent onSubmit={onSubmit} id='GuardStatusNotesForm' debug={false}
            form_render={formProps => {
                const { handleSubmit, submitting, values, form, invalid, dirty, valid } = formProps;

                return (<>
                    <FormField size="medium" name="status_notes" validate={rules.required} type="textarea" label="Status Notes" placeholder="Please type in the status update notes" compact />
    
                    <div style={{ height: "20px" }} />
                    <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} loading={submitting} >Save</Button>
                </>)
            }}
        />

    </>)
}

const StatusNotes = ({ data, onUpdate }) => {
    const [mode, setMode] = useState(null)
    const [busy, setBusy] = useState(false)

    const [updateGuardStatusNotes, status_details] = useMutation(UPDATE_STATUS_NOTE);

    const deleteNotes = async (values) => {
        setBusy(true)
        await updateGuardStatusNotes({ variables: { id: data._id, status_notes: 'none' } }).then(r => {
            setBusy(false)
            onUpdate({ ...data, status_notes: r?.data?.updateGuardStatusNotes?.status_notes || "" })
        })
    }

    const onStatusUpdates = (vals) => {
        setMode(null)
        onUpdate({ ...data, ...vals })
    }


    if(busy) return <Loader loading={true} />

    return (<>
        {(data && data.status_notes) && <div style={{ marginTop:"10px" }}>
            <Alert message={<>
                <Space size={0}>
                    <IconButton disabled={busy} onClick={() => setMode('edit')} icon="pen" />
                    <IconButton disabled={busy} onClick={()=>deleteNotes()} icon="trash-alt" />
                </Space>
                {data.status_notes}
            </>} type='warning' />
        </div>}

        {!(data && data.status_notes) && <div style={{ padding: "10px" }} align="center">
            <Button onClick={() => setMode('edit')} size="small">Add Status Notes</Button>
        </div>}

        <Modal visible={mode == 'edit'} onCancel={() => setMode(null)} title="Status Notes" footer={false}>
            {mode == 'edit' && <><StatusNotesForm onUpdate={onStatusUpdates} data={data} /></>}
        </Modal>
        
    </>)
    
}

const BasicProfile = ({ data, onUpdate }) => {
    // console.log("BasicProfile()", data)
    const [edit, set_edit] = useState(false);

    const renderReadOnly = () => {
        return (<>
            <DataRow col1={'Contact Email'} col2={data.email} inline size={['142px']} />
            <DataRow col1={'Security License #'}
                col2={`${data.security_license_no || ""} 
                                            (${data.security_license_expiry ? moment(data.security_license_expiry).format(defaultDateFormat) : ""})`}
                inline size={['142px']} />
            {verifyRole('guard.sensitive-data') &&
                <DataRow col1={'SIN #'} col2={data.sin} inline size={['142px']} />
            }
            {/* <DataRow col1={'Expiry'} col2={moment(mongoToDate(data.security_license_expiry)).format(defaultDateFormat)} inline size={['142px']} /> */}

            <DataRow col1={'Covid Vaccinated'} col2={data.covid_vaccinated} inline size={['142px']} />
            <DataRow col1={'Have PPE'} col2={data.ppe} inline size={['142px']} />
            <DataRow col1={'Skills'} col2={data.skills ? data.skills.toString().replaceAll(",", ", ") : ""} inline size={['142px']} />
            <DataRow col1={'Driving License'} col2={`${data.driving_license || ''}
                (${data.driving_license_expiry ? moment(data.driving_license_expiry).format(defaultDateFormat) : ""})
            `} inline size={['142px']} />
            <DataRow col1={'Have Vehicle'} col2={data.vehicle} inline size={['142px']} />
            <DataRow col1={'Home Address'} col2={data.home_address} inline vertical />
        </>)
    }

    return (<>
        {/* {data.status_notes && <Alert message={data.status_notes} type='warning' />} */}
        <StatusNotes data={data} onUpdate={onUpdate} />

        <Row align="top" gutter={[25]}>
            <Col flex="auto"><hr /></Col>
            <Col><IconButton onClick={() => set_edit(!edit)} style={{ position: "absolute", top: 0, right: 0 }} icon={edit ? "times" : "pen"} /></Col>
        </Row>

        {edit && <BasicProfileForm data={data} onUpdate={onUpdate} onCloseClick={() => set_edit(false)} />}
        {!edit && renderReadOnly()}
    </>)
}
export default BasicProfile;