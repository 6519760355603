import React, { useState, useEffect } from 'react'
import { withApollo } from '@apollo/client/react/hoc';
import { Button, Col, Row, message } from 'antd';
import { gql, useLazyQuery } from '@apollo/client';
import { DevBlock, Icon, Loader } from 'Common/components';
import { CSVLink, CSVDownload } from "react-csv";

const LIST_DATA = gql`query guards($filter: String){
    guards(filter: $filter){
        _id
        f_name
        m_name
        l_name
        email
        phone
        security_license_no
        status
    }
}`


const ExportGuardsAsCSV = ({ onCancel }) => {
    const [busy, set_busy] = useState(false)
    const [initlized, set_initlized] = useState(false)
    const [csvData, setCSVData] = useState(null)

    const [getGuards, guards_results] = useLazyQuery(
        LIST_DATA, { variables: { filter: JSON.stringify({ }) } }
    );

    useEffect(() => {
        if (initlized) return;
        set_initlized(true)
        fetchData();
    }, [])

    const fetchData = async() => {
        set_busy(true)
        let results = await getGuards({ variables: { filter: JSON.stringify({}) } }).then(r => (r?.data?.guards))
        set_busy(false)

        if (!results || results.error){
            message.error(results && results.error.message || "No data found!")
            return false;
        }

        let _csvData = [
            ["firstname", "middlename", "lastname", "email", "phone", "security_license_no", "status"],
        ]
        _csvData = _csvData.concat(results.map(o => ([o.f_name || "", o.m_name || "", o.l_name || "", o.email, o.phone || "", o.security_license_no || "", o.status])));
        setCSVData(_csvData);
    }

    {busy && <Loader loading={true}>Preparing data...</Loader>}

    return (<>
        <Row>
            <Col flex="auto">
                    {csvData && <>
                        <p>{csvData.length-1} Guard available for export</p>
                        <CSVLink data={csvData} filename='guards_list.csv'><span style={{ fontSize: '16px', padding: "10px 5px", border: "1px solid #EEE", borderRadius: "5px" }}>Download CSV <Icon icon="file-csv" size="1x" /></span></CSVLink>
                    </>}
            </Col>
            <Col><Button onClick={onCancel}>Cancel</Button></Col>
        </Row>
    </>)
}
export default withApollo(ExportGuardsAsCSV);
