import React, { Component } from 'react'
// import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'
import { Popconfirm, Alert, message, Checkbox, Select, Option } from 'antd';
import { Icon, Button, IconButton, Table, DevBlock, Loader } from 'Common/components';
import { UserRightsByCat } from 'configs';

const LIST_ROLES = loader('src/Graphql/user_role/userRoles.graphql');
const USER_ROLE = loader('src/Graphql/user_role/userRole.graphql');
const EDIT_USER_ROLE = loader('src/Graphql/user_role/editUserRole.graphql');
const VALUES = loader('src/Graphql/value_pairs/valuePairs.graphql');

const columns = [
    { title: 'Rights Category', dataIndex: 'title' },
];


class RolesManagementList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            firstRun: 1,
            loading: true,
            loading_values:false,
            selected_account_type: null, selected_rights: [],
            UserList: null,
            userRightsByCat: null,
        };
        this.mounted = false;
        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.getUserRole = this.getUserRole.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }

    componentDidMount(){
        this.manipulateRigts();
    }

    componentWillReceiveProps(nextProps) {
        // console.log("----------------------------------------------------------componentWillReceiveProps()", nextProps);
        const { loading, userRoles } = nextProps;
        if (!loading && userRoles && this.state.firstRun === 1) {
            this.setState({
                loading: false,
                firstRun: 0,
                UserList: userRoles,
                selected_account_type: userRoles[0]._id,
                selected_rights: userRoles[0].rights ? userRoles[0].rights.split(',') : []
            })
        }
    }

    expandedRowRender = (row, index, indent, expanded) => {
        if (!expanded) return null;
        const { selected_rights } = this.state;

        // let _rules = UserRightsByCat.find(el => el.type==row.acc_type);
        let _rules = UserRightsByCat[index].rules;
        let _id = UserRightsByCat[index]._id;

        let inner_columns = [
            {
                title: '', width: '30px',
                render: (txt, rec, i) => {
                    return (rec.heading) ? null : <Checkbox checked={selected_rights.indexOf(rec.key) > -1} onChange={this.onCheckboxChange.bind(this, rec)} />;
                }, 
            },
            { title: "Title", dataIndex: "title", dataIndex: 'title', render: (text, rec) => {
                return (!rec.heading) ? rec.title : <div style={{ fontSize: "16px", fontWeight: "bold" }}>{rec.heading}</div>;
            } },
        ];

        return <Table size="small" showHeader={false}
            columns={inner_columns}
            dataSource={_rules}
            pagination={false}
            rowKey={ (data) => _id++ }
        />;
    };

    onCheckboxChange = (rec, e) => {
        let isChecked = e.target.checked;
        let selected_rights = this.state.selected_rights;
        let arrindex = selected_rights.indexOf(rec.key);
        if (isChecked) selected_rights.push(rec.key)
        if (!isChecked) selected_rights.splice(arrindex, 1);
        this.setState({ selected_rights: selected_rights })
    }

    onSaveClicked() {
        if (!this.state.selected_account_type || this.state.selected_account_type == null) return;
        this.setState({ loading: true });

        let _allright = []
        UserRightsByCat.forEach(_right_type => _right_type.rules.forEach(row => _allright.push(row.key)));
        let final_rights = this.state.selected_rights.filter(r => _allright.indexOf(r) > -1);

        this.props.editUserRole({
            _id: this.state.selected_account_type,
            rights: final_rights.join(), // this.state.selected_rights.join(),
            // rights:JSON.stringify(this.state.selected_rights||[]),
        }).then(r => {
            message.success("Records updated!")
            this.setState({ loading: false });
        })
    }

    getUserRole(selected_account_type) {
        this.setState({ loading: true, selected_rights: [], selected_account_type: selected_account_type });

        // console.log(`getUserRole(selected_account_type)`);
        // console.log(this.props);

        this.props.client.query({ query: USER_ROLE, variables: { _id: selected_account_type } }).then(e => {
            const response = e?.data?.userRole;

            if (!response || response.error) {
                message.error(!response ? "Invalid response!" : response.error);
                this.setState({ loading: false });
                return;
            }

            // this.setState({ loading:false });
            let rights = response.rights ? response.rights.split(',') : [];

            this.setState({ loading: false, selected_rights: rights });
            
        }).catch(err => {
            message.error("Response Error!");
            console.log(__error("API Call ERROR: USER_ROLE : "), err);
            this.setState({ loading: false })
        })

    }

    renderHeader() {
        const { selected_account_type, UserList } = this.state;
        if (!UserList) return null;

        return (
            <Select onChange={this.getUserRole.bind(this)} value={selected_account_type} style={{ width: '200px' }}>
                {UserList.map((item, i) => {
                    return <Select.Option key={i} value={item._id}>{item.title}</Select.Option>
                })}
            </Select>
        )
    }

    async manipulateRigts(){
        // UserRightsByCat
        console.log("UserRightsByCat: ", UserRightsByCat)
        const noticeboard = UserRightsByCat.find(o => o.key == "noticeboard");
        
        
        let rules = noticeboard.rules.slice();
        // Loading Noticeboard categories
        this.setState({ loading_values: true });

        const values = await this.props.client.query({ query: VALUES, variables: { filter: JSON.stringify({
                key:'noticeboard_categories'
            }) } }).then(e => {
                const response = e?.data?.valuePairs;

                if (!response || response.error) {
                    console.log(__error("ERROR: "), e);
                    return { error: { message: !response ? "Unable to fetch Noticeboard categories!" : response.error.message }};
                }

                return response;

            }).catch(err => {
                console.log(__error("API Call ERROR: Values : "), err);
                message.error("Request ERROR");
                return { error: { message:'Request ERROR'}}
            })

        if (values.error){
            this.setState({ loading_values: false, error: values.error.message })
            return;
        }
        //


        rules = rules.concat(values.map(o => ({ title: `Access ${o.title} category`, key: `noticeboard.category.${o.values}` })))
        Object.assign(noticeboard, { rules });

        const _UserRightsByCat = UserRightsByCat.map(o => o.key == 'noticeboard' ? noticeboard : o)


        this.setState({ userRightsByCat: _UserRightsByCat, loading_values:false })

    }


    render() {
        const { userRoles, queryErrors } = this.props;
        const { loading, loading_values, error, userRightsByCat } = this.state;
        
        if (loading_values) return <Loader loading={loading_values} />
        if (!userRightsByCat) return <p>No rights found!</p>

        
        return (<>
            { error && <Alert message="Error" type="error" description={error} onClose={() => this.setState({ error: false })} showIcon closable /> }

            <Table
                title={() => this.renderHeader()}
                footer={() => <Button loading={loading} onClick={this.onSaveClicked}>Save</Button>}
                loading={loading}
                columns={columns}
                dataSource={userRightsByCat || null}
                pagination={false}
                expandedRowRender={this.expandedRowRender}
            />

            {/* <DevBlock obj={{ account:this.state.selected_account_type, rights: this.state.selected_rights}} /> */}

        </>)
    }
}

RolesManagementList.propTypes = {
    // prop: PropTypes.type.isRequired
}

const WithApollo = compose(
    graphql(LIST_ROLES, {
        options: props => {
            return { variables: {} };
        },
        props: ({ ownProps, data }) => {
            const { loading, userRoles, error } = data;
            if (error) console.log(__error("error"), error);

            return { loading, userRoles, queryErrors: error }
        }
    }),

    graphql(EDIT_USER_ROLE, {
        props: ({ mutate }) => ({
            editUserRole: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),

)(RolesManagementList);

// export default WithApollo;
export default withApollo(WithApollo);