/* eslint-disable eqeqeq */
import React from 'react';
import { Result } from 'antd'

export * from './Header';
export * from './headings';
export * from './RenderField';
export * from './UserInfoForm';


export const SuccessScreen = ({ success_message }) => {
    // return (<SuccessScreen success_message={<>
    //     <p stye={{ textAlign: "center" }}><span style={{ fontSize: "24px" }}>Thank you! your input has been saved.</span></p>
    // </>} />)

    return (<div style={{ backgroundColor: '#FFF', flex: 1, justifyContent: "center", alignItems: "center", display: 'flex' }}>
        <Result
            status="success"
            title={<img width="350px" src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/blue-logo-with-name.png`} />}
            // extra={<span style={{ color: 'black' }}>Test Saved Successfully!</span>}
            extra={<span style={{ color: 'black' }}>{success_message}</span>}
        />
    </div>)
}


export const styles = {
    li: { fontSize: '18px', paddingBottom: '20px', display: 'block' },
    inline_input: { border: "0", fontSize: "16px", borderBottom: "1px solid red" },
}
