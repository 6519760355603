import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Breadcrumb, Popconfirm, Alert, message, Row, Col, Input, Drawer } from 'antd';
import { ContentArea } from "Layout_v1";
import { Table, Loader, Icon, Button, IconButton } from 'Common/components';
import { PlusOutlined, HomeOutlined } from '@ant-design/icons';
import CoruseForm from './components/CoruseForm';
import SearchBar from './components/SearchBar'
import { defaultPageSize, uriRoot } from 'configs';
import { Link } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import { getmodulePermissions } from 'Common/scripts/Security';
const modulePermissions = getmodulePermissions('course-mg');

const COURSE_LIST = loader('src/Graphql/Courses/coursesQuery.graphql');
const DELETE_COURSE = loader('src/Graphql/Courses/deleteCourse.graphql');


class CoursesComp extends Component {
    state = {
        loading: false,
        busy: false,
        data: null,
        error: null,
        visible: false,
        pagination: { current: 1, pageSize: defaultPageSize },
        editNode: null,
    }


    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this)
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ current: pagination.current, pageSize: pagination.pageSize });
    };

    fetchData = async (args = {}) => {
        const variables = {
            first: args.pageSize || this.state.pagination.pageSize, // number of rec to fetch
            after: (args.pageSize || this.state.pagination.pageSize) * ((args.current || this.state.pagination.current) - 1),
            filter: JSON.stringify(args.filter || {}),
            others: JSON.stringify({ sort: { title: 0 } })
        }

        this.setState({ loading: true })

        this.props.client.query({ query: COURSE_LIST, variables }).then(r => {
            const response = r.data.coursesQuery;

            if (response.error) {
                this.setState({ loading: false, error: response.error.message });
                return;
            }

            this.setState({
                loading: false,
                data: response,
                pagination: {
                    ...this.state.pagination,
                    current: args.current || 1,
                    pageSize: args.pageSize || this.state.pagination.pageSize,
                    total: response.totalCount
                }
            });

        }).catch(err => {
            console.log("ERROR: ", err);
            this.setState({ busy: false, error: "Query Error" });
        })
    }

    delete(_id){
        if (!modulePermissions['course-mg.delete']) return;

        this.props.client.mutate({ mutation: DELETE_COURSE, variables: { id: _id } }).then(r => {
            let response = r.data.deleteCourse;

            if (response.error) {
                this.setState({ loading: false, error: response.error.message });
                return;
            }
            message.success("Course Removed");

            const __data = this.state.data.edges ? this.state.data.edges.slice() : [];
            const index = __data.findIndex(data => data._id === response._id)
            __data.splice(index, 1);
            const __totalCount = this.state.data.totalCount - 1;
            this.setState({ busy: false, data: { edges: __data, totalCount: __totalCount } })

        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            message.error("Failed to process the request!");
        })

    }

    toggeleDrawer(editNode=null){
        this.setState({ editNode })
    }

    columns = [
        { title: 'ID', dataIndex: '_id', width: "100px" },
        { title: 'Course Name', dataIndex:"title", render:(__, rec)=>{
            return <Link to={`courses/id/${rec._id}`}>{rec.title}</Link>
        } },
        {
            title: '', render: (___, rec) => {
                return <div>
                    {modulePermissions['course-mg.edit'] &&
                        <IconButton icon="pen" onClick={() => this.toggeleDrawer(rec)} />
                    }
                    {modulePermissions['course-mg.delete'] && 
                        <Popconfirm onConfirm={() => this.delete(rec?._id)} title="Are you sure？">
                            <span><IconButton icon="trash-alt" /></span>
                        </Popconfirm>
                    }
                </div>
            },
            width: '100px', align:"center"
        },

    ];

    componentDidMount() {
        // this.fetchData()
    }




    render() {
        const { loading, error, data, pagination, editNode } = this.state;

        if (error) return <Alert message="Error" description={error} type="error" showIcon />
        return (
            <>
                <Breadcrumb style={{ padding: "10px" }}>
                    <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                    <Breadcrumb.Item><b> Courses</b></Breadcrumb.Item>
                </Breadcrumb>
                
                <ContentArea>
                    {modulePermissions['course-mg.add'] && <CoruseForm editNode={editNode} onClose={()=>this.toggeleDrawer()} /> }

                    <div style={{ marginTop: "10px", padding: "0 10px 0 10px", border: "1px solid #EEE", borderTopRightRadius: "5px", borderTopLeftRadius: "5px", backgroundColor: "rgba(0, 0, 0, 0.02)" }}>
                        <SearchBar fetchData={(args) => this.fetchData(args)} />
                    </div>

                    <Table loading={loading}
                        title={() => <b>Total {((data && data.totalCount) || '0')} record(s) found </b>}
                        columns={this.columns}
                        dataSource={data ? data.edges : null}
                        pagination={pagination}
                        onChange={this.handleTableChange}
                    />
                </ContentArea>

            </>
        )
    }
}

export const Courses = withApollo(CoursesComp)
export default Courses;