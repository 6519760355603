import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Popconfirm, Alert, message, Row, Col, Tag } from 'antd';
import { Table, Loader, Icon, IconButton, Button, DeleteButton, Drawer, DevBlock, UsersSearch, Tabs } from 'Common/components';
import { Breadcrumb } from "antd";
import { ContentArea } from "Layout_v1";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { DorFilter, DorForm } from './components';
import { verifyRole } from 'Common/scripts/Security';
import { defaultDateFormat, defaultDateTimeFormat, defaultPageSize, uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import { ListHeader } from 'Common/components/Typography';
import { Link } from 'react-router-dom';
import { mongoToDate } from 'Common/scripts/Functions';
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper';

const LIST_DATA = loader('src/Graphql/dors/dorsQuery.graphql');
const DELETE = loader('src/Graphql/dors/deleteDor.graphql');

class DorsComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null,
            error: null,
            kw:null,
            pagination: { current: 1, pageSize: defaultPageSize },
            variables: null,
            showClientDetails: false,
            activeTab: 'mydor',
            showDorForm:false, initialData:null
        };
        // this.searchList = this.searchList.bind(this)
        this.onDeleteClick = this.onDeleteClick.bind(this)
        this.onSearch = this.onSearch.bind(this)
        this.onTabChange = this.onTabChange.bind(this)
    }

    componentDidMount(){
        this.fetchData()
    }
    
    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ current: pagination.current, pageSize: pagination.pageSize });
    };

    fetchData = async (args = {}) => {
        let filter = { ...args.filter }
        if (this.state.activeTab == 'mydor') {
            Object.assign(filter, {
                "user._id": Number(this?.props?.user?._id)
            })
        }else{

        }

        const variables = {
            first: args.pageSize || this.state.pagination.pageSize, // number of rec to fetch
            after: (args.pageSize || this.state.pagination.pageSize) * ((args.current || this.state.pagination.current) - 1),
            filter: JSON.stringify(filter),
            others: JSON.stringify({ sort: { _id: -1 } }),
        }

        this.setState({ loading: true, variables })

        let results = await this.props.client.query({ query: LIST_DATA, variables }).then(r => (r.data.dorsQuery))
            .catch(err => {
                console.log(__error("Error: "), JSON.stringify(err, 0, 2));
                return { error: { message: "Unable to complete your request at the moment!" }}
                // this.setState({ loading: false, error: "Unable to complete your request at the moment!" });
                // return false;
            })

        if (results && results.error) {
            this.setState({ loading: false, error: results.error.message });
        }else{
            this.setState({
                loading: false,
                data: results,
                pagination: {
                    ...this.state.pagination,
                    current: args.current || 1,
                    pageSize: args.pageSize || this.state.pagination.pageSize,
                    total: results.totalCount
                }
            });
        }


    }

    onTabChange(activeTab){
        // let filter = { ...this.state.filter }
        // if (activeTab == 'mydor') Object.assign(filter, { "user._id": Number(this?.props?.user?._id) })
        // else {}

        this.setState({ activeTab }, () => this.fetchData())
    }

    async onSearch(vals){
        await this.fetchData({ filter: vals });
        return true;
    }

    // searchList(value) {
    //     this.fetchData({
    //         filter: { search: { keywords: value }}
    //     });
    // }
    
    columns = () => {
        let _cols = [
            { title: 'Record Date', dataIndex: 'record_date', render: (txt, rec) => <Link to={`/admin/dors/${rec._id}`}>{mongoToDate(txt).format(defaultDateFormat)}</Link>, width: 120 },
            {
                title: 'Author Name', dataIndex: 'user.name', render: (__, rec) => (<>
                    <Link to={`/admin/dors/${rec._id}`} style={{ lineHeight:1 }}>{rec?.user?.name}</Link>
                    <span className='hidden-menu'>
                        <Button size="small"><Link to={`/admin/dors/${rec._id}`}>open</Link></Button>
                        {/* {modulePermissions['dors.edit'] && <IconButton className="item" icon="pen" onClick={() => this.toggleDorForm(rec)} />} */}
                        {verifyRole('allow.delete.dors') && <DeleteButton className="item" onClick={() => this.onDeleteClick(rec?._id)} />}
                    </span>
                </>)
            },
            { title: 'Created', dataIndex: 'created_at', render: (txt, rec) => mongoToDate(txt).format(defaultDateTimeFormat), width: 180, align: 'center' },
            { title: 'Last Updated', dataIndex: 'updated_at', render: (txt, rec) => mongoToDate(txt).format(defaultDateTimeFormat), width: 180, align: 'center' },
            { title: 'Status', dataIndex: 'locked', render: (__, rec) => <Tag color={rec.locked ? "red" : "green"}>{rec.locked ? "Locked" : "Open"}</Tag>, width: 90, align: 'center' },
        ]

        return _cols;
    }

    async onDeleteClick(_id) {
        console.log("onDeleteClick()")
        // if (!modulePermissions['allow.delete.dors']) return;

        let resutls = await this.props.client.mutate({
            mutation: DELETE, variables: { id: _id }
        }).then(r => (r?.data?.deleteDor))
        .catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            this.setState({ loading: false, error: "Unable to delete at the moment!" });
        })

        if (resutls?.error?.message){
            this.setState({ loading: false });
            message.error(resutls.error.message)
            return;
        }

        const delIndex = this.state.data.edges.findIndex(data => data._id == resutls._id)
        const __data = this.state.data.edges ? this.state.data.edges.slice() : [];

        __data.splice(delIndex, 1)
        this.setState({ data: { edges: __data } })
        message.success("Record is successfully deleted!")

    }

    toggleDorForm(initialData = null) {
        this.setState({ showDorForm: !this.state.showDorForm, initialData })
    }

    onUpdate(){
        this.fetchData()
        this.toggleDorForm()
    }

    render() {
        const { loading, data, error, activeTab, pagination, showDorForm, initialData } = this.state;

        if (error) return <ContentArea><Alert message="Error" description={error} type="error" showIcon /></ContentArea>

        return (<>
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                <Breadcrumb.Item><Icon icon="user-tie" size="1x" /> Dors</Breadcrumb.Item>
            </Breadcrumb>

            {verifyRole('access.all.dors') && <div style={{ margin: "15px 15px 0 15px" }}><Tabs size="large" activeTab={activeTab}
                tabsArray={[
                    { key: "mydor", title: "My Reports (Dor)" },
                    { key: "alldor", title: "All Reports (Dor)" },
                ]}
                onChange={this.onTabChange}
            /></div>}

            <ContentArea style={{ marginTop:0 }}>
                <Row>
                    <Col><ListHeader totalCount={(data && data.totalCount) || '0'} title='Dors' /></Col>
                    <Col flex="auto" />
                    {/* {activeTab == 'mydor' && <Col><Button type="primary" onClick={() => this.toggleDorForm()} icon={<Icon icon='plus' />}>Create New Dor</Button></Col>} */}
                </Row>
                
                {activeTab == 'alldor' && <div className='table-header'><DorFilter onSearch={this.onSearch} /></div>}

                <Table loading={loading}
                    columns={this.columns()}
                    dataSource={data ? data.edges : null}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                />
            </ContentArea>

            <DorForm 
                open={showDorForm} 
                initialValues={{}} 
                onUpdate={() => this.onUpdate()}
                onClose={() => this.toggleDorForm()}
            />

        </>)
    }
}

const mapStateToProps = ({ ep_admin }, ownProps) => {
    return ({ user: ep_admin.user });
}
const WithRedux = connect(mapStateToProps)(withApollo(DorsComp));

export const Dors = withApollo(WithRedux)
export default Dors;
