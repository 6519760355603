import React from 'react'
import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';
// import { Link } from 'react-router-dom';
import { Icon, Button, ToggleButton } from 'Common/components';
import { LayoutFooter, SideMenu, LayoutHeader, ContentArea } from './';
import LocalStorage from 'Common/scripts/LocalStorage';
const { Header, Sider, Content, Footer } = Layout;



export const LayoutWrapper = props => {
    const left_menu_collaps = LocalStorage.getJson('left_menu_collaps');
    // console.log("left_menu_collaps: ", left_menu_collaps);
    
    const [collapsed, setNavCollaps] = React.useState(left_menu_collaps);
    const [collapsedWidth, setCollapsedWidth] = React.useState(undefined);

    // const renderMenu = args => {
    //     if (props.menu) {
    //         return (
    //             <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
    //                 {props.menu.map((item, i) => {
    //                     return <Menu.Item key={i} icon={<Icon icon={item.icon} />}><Link to={item.to}>{item.title}</Link></Menu.Item>
    //                 })}
    //             </Menu>
    //         )
    //     }

    //     return (
    //         <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
    //             <Menu.Item key="1" icon={<Icon icon='bell' />}><Link to={`/`}>Home</Link></Menu.Item>
    //             <Menu.Item key="2" icon={<Icon icon='bell' />}><Link to={`/template/tables`}>Link 2</Link></Menu.Item>
    //             <Menu.Item key="3" icon={<Icon icon='bell' />}><Link to={`/template/avatars`}>Link 3</Link></Menu.Item>
    //         </Menu>
    //     )
    // }


    return <Layout className="site-layout">
        <SideMenu menu={props.menu}
            collapsed={collapsed}
            setNavCollaps={(val)=>{
                console.log("setNavCollaps()");
                console.trace()
                
                LocalStorage.setJson('left_menu_collaps', val);
                setNavCollaps(val);
            }}
            setCollapsedWidth={setCollapsedWidth} collapsedWidth={collapsedWidth}
        />
        {/* <Sider trigger={null} collapsible breakpoint="lg"
            onBreakpoint={broken => {
                setCollapsedWidth(broken ? 0 : undefined)
                setNavCollaps(broken);
            }}
            collapsedWidth={collapsedWidth}
            collapsed={collapsed} className="site-sidebar">
            <div className="logo">Logo</div>
            {renderMenu()}
        </Sider> */}

        <Layout className="site-layout-content">

            <LayoutHeader menu={props.topMenu} collapsed={collapsed} 
            // onToggleClick={() => setNavCollaps(!collapsed)} 
            />

            {/* <Header className="site-layout-header">
                <ToggleButton toggle={collapsed} onClick={() => setNavCollaps(!collapsed)}
                    labelA={<Icon icon="caret-left" />}
                    labelB={<Icon icon="caret-right" />} />
                <TopMenu collapsed={collapsed} onToggleClick={() => setNavCollaps(!collapsed)} />
                <Button href='/logout'>Log out</Button>
            </Header> */}

            {props.skipContentArea && props.children}
            {!props.skipContentArea && 
                <ContentArea>{props.children}</ContentArea>
                // <Content className="site-content">{props.children}</Content>
            }

            <LayoutFooter />

        </Layout>

    </Layout>
}

export default LayoutWrapper;

LayoutWrapper.propTypes = {
    menu: PropTypes.array,
    topMenu: PropTypes.array,
    skipContentArea: PropTypes.bool,
    // labelA: PropTypes.oneOfType([
    //             PropTypes.string,
    //             PropTypes.object
    //         ]).isRequired,
}
