import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Drawer, Space, Alert, message, Row, Col } from 'antd';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Button } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import { withApollo } from '@apollo/client/react/hoc';
import { updateModule } from '../InternalStore/actions'
import { connect } from 'react-redux'
import { getmodulePermissions } from 'Common/scripts/Security';
const modulePermissions = getmodulePermissions('course-mg');

const ADD_SCREEN = loader('src/Graphql/Courses/addModuleScreen.graphql');
const EDIT_SCREEN = loader('src/Graphql/Courses/editModuleScreen.graphql');

const ScreenForm = ({ onSubmit, fields, busy }) => {
    return (<>
        <FormComponent onSubmit={onSubmit} id={`ModuleScreenForm`} debug={false} loading={busy}
            fields={{ ...fields }}
            mutators={{
                updateBody: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    tools.changeValue(state, 'body', () => node);
                },
            }}
            form_render={formProps => {
                const { values, form, invalid, dirty, valid, pristine, submitting } = formProps;

                return (<>
                    <FormField label="Screen Title" name="title" type="text" compact validate={rules.required} />
                    <FormField label="Body Text" name="title" type="html" compact validate={rules.required} onUpdate={form.mutators.updateBody}
                        data={(values && values.body) ? values.body : null}
                        editorStyle={{ minHeight: "calc(100vh - 210px)" }}
                        toolbar={{ options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove', 'history',] }}
                    />
                    {/* <Button type="primary" size="medium" htmlType='submit' disabled={!dirty || !valid} >Save</Button> */}
                </>)

            }}
        />

    </>)
}

const ScreenFormWrapper = ({ formData, showForm, editNode, client, onClose, r_updateModule }) => {
    console.log("editNode: ", editNode)
    const [busy, setBusy] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        // if (isEditing) setShowForm(true);
        return () => {
            setBusy(false)
            setError(null)
        };
    }, [showForm])

    const onSubmit = (values) => {
        setError(null);

        const input = {
            // _id: String
            course_id: formData._id,
            module_id: editNode.module._id,
            title: values.title,
            body: values.body,
            // attachments: [CourseModuleScreenAttachment_input]
        }

        if (editNode && editNode.screen && editNode.screen._id) {
            Object.assign(input, { _id: editNode.screen._id });
            updateRecord(input);
        } else {
            addRecord(input);
        }

    }

    const addRecord = (input) => {
        if (!modulePermissions['course-mg.add']) return;

        setBusy(true);

        client.mutate({ mutation: ADD_SCREEN, variables: { input } }).then(r => {
            let response = r.data.addModuleScreen;

            if (response.error) {
                setBusy(false);
                // setError(response.error.message);
                message.error(response.error.message)
                return;
            }
            message.success("Screen Added!");
            r_updateModule(response)
            onClose()

        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            message.error("Failed to process the request!");
            setError("Failed to process the request!")
            setBusy(false);
        })

    }

    const updateRecord = (input) => {
        if (!modulePermissions['course-mg.edit']) return;

        setBusy(true);

        client.mutate({ mutation: EDIT_SCREEN, variables: { input } }).then(r => {
            let response = r.data.editModuleScreen;

            if (response.error) {
                setBusy(false);
                setError(response.error.message);
                return;
            }
            message.success("Saved!");
            r_updateModule(response)
            onClose()

        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            message.error("Failed to process the request!");
            setBusy(false);
        })
    }


    return (<>
        <Drawer
            height={"100%"} placement="top" onClose={onClose} visible={showForm}
            title={editNode && editNode.screen ? "Update Screen" : "Create New Screen"}
            // bodyStyle={{ paddingBottom: 80 }}
            // extra={<Button onClick={() => toggleModule()}>Cancel</Button>}
            footer={<Row>
                {/* <Col flex="auto"><Button type="button" onClick={form.reset} disabled={submitting || pristine}>Reset</Button></Col> */}
                <Col>
                    <Button loading={busy} disabled={false} type="primary" onClick={() => {
                        document.getElementById(`ModuleScreenForm`).dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
                    }}>Save Screen</Button>
                </Col>
            </Row>}
            destroyOnClose={true}
        ><>
                {error && <Alert message="Error" description={error} type="error" showIcon />}
                {!formData._id && <Alert message="Error" description="Course ID not found!" type="error" showIcon />}

                {showForm && <ScreenForm busy={busy} onSubmit={onSubmit} fields={{ ...editNode?.screen }} />}
            </></Drawer>
    </>)
}
ScreenFormWrapper.propTypes = {
    editNode: PropTypes.object,
    showForm: PropTypes.bool,
    // course_id: PropTypes.oneOfType([
    //     PropTypes.number,
    //     PropTypes.string,
    // ]),
    onClose: PropTypes.func,
}


const mapStateToProps = ({ formData }) => {
    return { formData };
};
const mapDispatchToProps = dispatch => ({
    r_updateModule: (payload) => dispatch(updateModule(payload)),
});
const Wrapper = connect(mapStateToProps, mapDispatchToProps)(ScreenFormWrapper)

export default withApollo(Wrapper)