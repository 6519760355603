import React, { useState } from 'react'
import { loader } from 'graphql.macro';
import { Drawer, Alert, message } from 'antd';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Button } from 'Common/components';
import { tagsTypeArray } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import { __error } from 'Common/scripts/consoleHelper';

const ADD_TAG = loader('src/Graphql/tags/addTag.graphql');
const EDIT_TAG = loader('src/Graphql/tags/updateTag.graphql');


const TagsForm = props => {
    const [error, setError] = useState(null);

    const onSubmit = async (values) => {
        const input = { title: values.title, type: values.type }

        var results;
        if (props?.fields?._id){
            Object.assign(input, { _id: props?.fields?._id })
            results = await props?.client?.mutate({ mutation: EDIT_TAG, variables: { input } }).then(r => (r?.data?.updateTag)).catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })
        }
        else{
            results = await props.client.mutate({ mutation: ADD_TAG, variables: { input } }).then(r => (r?.data?.addTag)).catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })

            if (results && !results?.error?.message) message.success("Record Added!");
        }

        if (!results || results?.error) {
            setError((results && results?.error?.message) || "Invalid response");
            return false;
        }

        if (props.onSuccess) props.onSuccess(results);
        return results;

    }

    if (error) return <Alert message="Error" description={error} type="error" showIcon />

    return(<>
        <FormComponent onSubmit={onSubmit} id='TagsForm' fields={{ type: props.type || tagsTypeArray[0]._id, ...props.fields }} debug={true}
            form_render={formProps => {
                const { values, form, invalid, dirty, valid, submitting } = formProps;

                return (<>
                    <FormField label="Title" name="title" type="text" validate={rules.required} />
                    {!props.type && <FormField label="Type" name="type" type="select" data={tagsTypeArray} validate={rules.required} />}
                    
                    <div style={{ padding:"10px" }}><Button type="primary" size="medium" block loading={submitting} htmlType='submit' disabled={!dirty || !valid} >Submit </Button></div>

                </>)
            }}
        />

    </>)
}

class TagsFormDrawer extends React.Component{
    constructor(props) {
        super(props);
        this.state = { error: null, mounted: false, };
    }

    componentDidMount() {
        this.setState({ mounted:true })
    }
    componentWillUnmount(){
        this.setState({ mounted: false })
    }  

    render(){
        const { visible, onClose } = this.props;
        const { mounted } = this.state;

        if (this.state.error) return <Alert message="Error" description={this.state.error} type="error" showIcon />

        return(<>
            <Drawer
                title={this.props.fields ? "Edit Tag" : "Add New Tag"}
                width={600}
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
                extra={<Button onClick={onClose}>Cancel</Button>}
                destroyOnClose={true}
            ><>
                {mounted && <TagsForm {...this.props} />}
            </></Drawer>
        </>)
    }

}

export default withApollo(TagsFormDrawer)