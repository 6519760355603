import React, { Component, useState, useEffect } from 'react'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Button, DevBlock, Icon, ValuePairsSearch } from 'Common/components'
import { message, Row, Col } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
// import moment from 'moment'
// import { Form } from 'react-final-form';
import { connect } from "react-redux";
import { updatePageSettings } from 'Store/ep_admin.actions.js'
import { guard_status } from 'configs';
// import { GUARD_APPLICATION_FORM, CLIENT_INFO_FORM } from '../constants'

// const ref_keys = [
//     // { _id: GUARD_APPLICATION_FORM, title:'Guard Applications' },
//     // { _id: CLIENT_INFO_FORM, title:'Client Information' },
// ];


const SearchBar = props => {
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);

    const filter = (!props?.filter_config?.skipSettings) ? props?.ep_admin?.pageSettings?.guard_searchBar : {};

    useEffect(() => {
        onSubmitSearch(filter || {})
    }, [])

    const onSubmitSearch = async (values, redux) => {

        // Creating query variables
        const queryVars = {
            search: values?.search, // 'search.keywords': values?.search?.keywords,
            status: values.status,
            company_assets: undefined
        }
        if (values.company_assets && values.company_assets.length>0) Object.assign(queryVars, { company_assets: { $in: values.company_assets.map(o => Number(o)) } })

        setBusy(true);
        props.fetchData({ filter: queryVars }).then(r => {
            setBusy(false);
        });


        // Setting up data for settings
        const settingsVars = { }
        let keys = Object.keys(values);
        keys.forEach(key => {
            let value = values[key];
            if (!(!value || value == null || value == "")) Object.assign(settingsVars, { [key]: value })
            // if ((value || value != null) && value.length > 0) Object.assign(settingsVars, { [key]: value })
        });

        if (!props?.filter_config?.skipSettings) {
            props.updatePageSettings({
                page: 'guard_searchBar',
                values: settingsVars
            })
        }


        return;
        /*

        let _filter = { ...filter }
        
        let keys = Object.keys(values);
        keys.forEach(key => {
            let value = values[key];
            if ((value || value != null) && value.length>0) Object.assign(_filter, { [key]: value })
        });

        setBusy(true);

        // create saperate query filter so mongo kw not disturb raw filter in redux
        let queryFilter = { ..._filter }
        if (queryFilter.company_assets) queryFilter.company_assets = { $in: _filter.company_assets.map(o => Number(o)) }

        console.log("queryFilter: ", queryFilter)

        props.fetchData({ filter: queryFilter }).then(r => {
            setBusy(false);
        });

        if(!props?.filter_config?.skipSettings){
            props.updatePageSettings({
                page: 'guard_searchBar',
                values: _filter
            })
        }
        */

    }


    return (<>
        <FormComponent onSubmit={onSubmitSearch} id='GuardSearch' loading={busy} debug={false}
            fields={{ ...filter }}
            form_render={formProps => {
                const { values, form, invalid, dirty, valid } = formProps;

                return (<>
                    <Row align="top">
                        <Col flex='300px'>
                            <FormField label="Keyword" name="search.keywords" placeholder={"_id ,sin, security license, name, phone, email"} size="medium" type="text" allowClear />
                        </Col>
                        {props?.filter_config?.status != 'hide' &&
                            <Col flex='250px'>
                                <FormField label="Account Status" name="status" placeholder="Status" size="medium" type="select" data={guard_status} allowClear />
                            </Col>
                        }
                        
                        <Col style={{minWidth:"200px"}}>
                            <ValuePairsSearch preload key_value="company_assets" name="company_assets" label="Guards with assets" multiselect style={{ width: "100%" }} />
                        </Col>

                        <Col><Button style={{ marginTop: "32px" }} type="primary" htmlType='submit'><Icon icon="search" /></Button></Col>
                    </Row>
                </>)
            }}
        />

    </>)


}
const mapStateToProps = ({ ep_admin }) => {
    return ({ 
        ep_admin,
        filter: ep_admin?.pageSettings?.guard_searchBar
    });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)

