import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Popconfirm, Alert, message, Row, Col, Menu, Drawer } from 'antd';
import { Table, Loader, Icon, IconButton, Button } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import { Breadcrumb } from "antd";
import { ContentArea } from "Layout_v1";
import { HomeOutlined } from '@ant-design/icons';
import TestSearchBar from '../Components/TestSearchBar'
import { connect } from "react-redux";
import { updatePageSettings } from 'Store/ep_admin.actions.js'
// import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { useSubscription, gql } from "@apollo/client";
import { defaultPageSize, uriRoot } from 'configs'
import { TestFormDrawer } from './TestForm'
import { withApollo } from '@apollo/client/react/hoc';
import { ROOT } from '../constants'

const LIST_DATA = loader('src/Graphql/TestForms/testFormsQuery.graphql');
const DELETE = loader('src/Graphql/TestForms/deleteTestForm.graphql');
const QUERY_SUBSCRIPTION = loader('src/Graphql/TestForms/sub_testFormsUpdated.graphql');

function Subscriber({ QUERY, callback, filter }) {
  let variables = {};

  if (filter) {
    Object.assign(variables, {
      ref_key: filter.ref_key
    });
  }

  const { data, loading, error } = useSubscription(
    QUERY_SUBSCRIPTION,
    {
      // subscription: QUERY,
      variables: { filter: JSON.stringify(variables) },
      // fetchPolicy: "network-only" // cache-first, cache-only, cache-and-network, network-only, no-cache, standby
      onSubscriptionData: ({ client, subscriptionData }) => {
        const { data, error, loading, variables } = subscriptionData
        if (callback) callback(subscriptionData)
      },
      shouldResubscribe: false, // Determines if your subscription should be unsubscribed and subscribed again
    }
  );

  if (loading) {
    console.log("Receiving subscription on ....", variables);
    return null;
  }

  if (data) {
    console.log('Subscription received: ', data);
    // const { mutation, node } = data.testFormsUpdated;
    // if (callback) callback({ mutation, node })
  }

  return null;

}

class TestFormList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      error: null,
      kw: null,
      pagination: { current: 1, pageSize: defaultPageSize },

      editNode: null,
    };
    // this.onSearch = this.onSearch.bind(this)
    this.searchList = this.searchList.bind(this)
    this.delete = this.delete.bind(this)
    this.onSubscriptionReceived = this.onSubscriptionReceived.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
  }

  componentDidMount() {
    // this.fetchData()

    // const { loading, error, data } = useSubscription(QUERY_SUBSCRIPTION);
  }

  fetchData = async (args = {}) => {
    const variables = {
      first: args.pageSize || this.state.pagination.pageSize, // number of rec to fetch
      after: (args.pageSize || this.state.pagination.pageSize) * ((args.current || this.state.pagination.current) - 1),
      filter: JSON.stringify(args.filter || this.props.ep_admin?.pageSettings?.testform_searchBar || {})
    }

    this.setState({ loading: true })

    return this.props.client.query({ query: LIST_DATA, variables }).then(r => {
      let response = r.data?.testFormsQuery;

      if (!response) {
        this.setState({ loading: false, error: "Invalid response from server" }); return;
      }
      if (response.error) {
        this.setState({ loading: false, error: response.message }); return;
      }

      this.setState({
        loading: false,
        data: response,
        pagination: {
          ...this.state.pagination,
          current: args.current || 1,
          pageSize: args.pageSize || this.state.pagination.pageSize,
          total: response.totalCount
        }
      });

      return response;

    }).catch(err => {
      console.log(__error("ERROR: "), JSON.stringify(err, 0, 2));
      this.setState({ loading: false, error: "Unable to complete your request at the moment!" });
      return false;
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetchData({ current: pagination.current, pageSize: pagination.pageSize });
  };

  searchList(value) {
    this.fetchData({
      // filter: { $or: [{ "personalinfo.f_name": value }, { "personalinfo.sin_no": value}]}
      filter: { search: { keywords: value } }
    });
  }

  onSuccess(){
    this.fetchData()
  }

  delete(_id) {
    this.setState({ loading: true });

    this.props.client.mutate({
      mutation: DELETE, variables: { id: _id }
    }).then(r => {
      const response = r.data.deleteTestForm;

      if (!response) { this.setState({ loading: false, error: "Invalid response!" }); return; }
      if (response.error) {
        message.error(response.error.message);
        this.setState({ loading: false });
        return;
      }

      this.setState({ loading: false });

      message.success("Record successfully deleted!")
      return response;
    }).catch(err => {
      console.log("ERROR: ", err);
      this.setState({ loading: false, error: "Unable to delete at the moment!" });
      return false;
    })


  }

  toggeleDrawer(editNode = null) {
    this.setState({ editNode })
  }

  onSubscriptionReceived({ data, error, loading, variables }) {
    const { mutation, node } = data.testFormsUpdated;

    let _data = this.state.data.edges ? this.state.data.edges.slice() : [];

    if (mutation == "DELETED") {
      _data = _data.filter(o => {
        return o._id != node._id;
      });
    }

    else if (mutation == "UPDATED") {
      // let data = this.state.data ? this.state.data.slice() : [];
      _data = _data.map(o => {
        if (o._id == node._id) return { ...node }
        return o;
      })
    }

    else if (mutation == "CREATED") {
      // let data = this.state.data ? this.state.data.slice() : [];
      _data.push(node);
    }

    else {
      console.log(__error("Invalid Subscription received: ", data));
      return;
    }

    this.setState({ data: { ...this.state.data, edges: _data } })
  }

  columns = [
    { title: 'ID', dataIndex: '_id', width: "100px" },
    {
      title: 'Title', dataIndex: 'title', render: (___, rec) => {
        return (<><Button type="link" onClick={() => this.toggeleDrawer({_id:rec._id})}>{rec.title}</Button></>)
        // return (<><Link to={`./FormsData/view/${rec._id}`}>{rec.title}</Link></>)
      }
    },
    { title: 'Status', dataIndex: 'status', width: '150px' },
    { title: 'Sections', dataIndex: 'sections', width: '150px', render: (___, rec) => (rec?.sections?.length || '0') },
    {
      title: '', width: "50px", align: 'center', render: (___, rec) => {
        return <div>
          <IconButton onClick={() => this.toggeleDrawer(rec)} icon={{ icon: 'pen' }}></IconButton>
          <Popconfirm onConfirm={() => this.delete(rec?._id)} title="Are you sure？">
            <IconButton icon={{ icon: 'trash-alt' }}></IconButton>
          </Popconfirm>
        </div>
      }
    },
  ];


  render() {
    const { loading, data, error, editNode, pagination } = this.state;

    if (error) return <ContentArea><Alert message="Error" description={error} type="error" showIcon /></ContentArea>

    return(<>
      <Subscriber QUERY={QUERY_SUBSCRIPTION} callback={this.onSubscriptionReceived} variables={{}} filter={this.props.ep_admin?.pageSettings?.testform_searchBar} />

      <Breadcrumb style={{ padding: "10px" }}>
        <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item>
        <Breadcrumb.Item href={`${uriRoot}${ROOT}/tests`}><span>Tests</span></Breadcrumb.Item>
        {/* <Breadcrumb.Item>Test Creator</Breadcrumb.Item> */}
      </Breadcrumb>

      <ContentArea>
        <Row>
          <Col flex="auto" />
          <Col><TestFormDrawer onSuccess={this.onSuccess} editNode={editNode} onClose={() => this.setState({ editNode:null })} /></Col>
          {/* <Col><Button onClick={() => this.setState({ showCreateTestForm: true })}>Create A New Test</Button></Col> */}
        </Row>

        <div style={{ marginTop: "10px", padding: "0 10px 5px 10px", border: "1px solid #EEE", borderTopRightRadius: "5px", borderTopLeftRadius: "5px", backgroundColor: "rgba(0, 0, 0, 0.02)" }}>
          <TestSearchBar fetchData={(args) => this.fetchData(args)} />
        </div>

        <Table loading={loading}
          title={() => <b>Total {((data && data.totalCount) || '0')} record(s) found </b>}
          columns={this.columns}
          dataSource={data ? data.edges : null}
          pagination={pagination}
          onChange={this.handleTableChange}
          rowClassName={(record => record.status == 'new' ? 'hilighted-table-row' : "")}
        // onChange={this.handleTableChange}
        />
      </ContentArea>
    </>)

  }
}

const mapStateToProps = ({ ep_admin }) => {
  return ({ ep_admin });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)( withApollo(TestFormList))
