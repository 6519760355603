import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const UPDATE_NAV = 'UPDATE_NAV';
export const SET_NAV = 'SET_NAV';
export const FLUSH_NAV = 'FLUSH_NAV';

export const updateNav = payload => ({ type: UPDATE_NAV, payload });
export const setNav = payload => ({ type: SET_NAV, payload });
export const flushNav = () => ({ type: FLUSH_NAV });

const default_state = { organization:false, branch:false };

export const reducer = ( state=default_state, {type, payload} ) => {
  switch (type) {
    case UPDATE_NAV:
      return Object.assign({...state}, {...payload});
      // return payload;
      break;
    case SET_NAV:
      return payload;
      break;
    case FLUSH_NAV:
      return default_state;
      break;
    default:
      return state;
  }
}

export default { nav: reducer };

// const authPersistConfig = {
//   key: 'redux_nav',
//   storage: storage,
//   blacklist: ['somethingTemporary'],
//   // whitelist: [],
// }
// export default persistReducer(authPersistConfig, reducer);
