import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Pagination as _Pagination } from 'antd';
import { useHistory, useParams, useLocation } from "react-router-dom";


export const Pagination = props => {
  let history = useHistory();
//   let allParams = useParams();
  let location = useLocation();

  const onChange = (page, pageSize) => {
    if (props.updateHistory) {
        let loc = String(location.pathname);
        let searchVal = /\/page\/[0-9]+/i;
        if (loc.search(searchVal) > -1) {
          loc = loc.replace(searchVal, `/page/${page}`)
          history.push(loc)
        }
    }
    
    if (props.onChange) props.onChange(page, pageSize)
  }

  return <_Pagination {...props} onChange={onChange} />
}

export default Pagination;