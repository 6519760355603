/* eslint-disable eqeqeq */
import React, { Component, useContext } from 'react';
import { Row, Col, message, Result, Divider, Upload, Alert, Steps, Card } from 'antd'
import { InboxOutlined } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import SignaturePad from 'react-signature-canvas'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import axios from 'axios'
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { Button, DataRow, Icon, IconButton } from 'Common/components'
import Header from '../Components/Header';
import { __error } from 'Common/scripts/consoleHelper';
import { GUARD_APPLICATION_FORM } from '../../constants';
import { withApollo } from '@apollo/client/react/hoc';
import moment from 'moment';
import { defaultDateFormat } from 'configs';
import { removeSpecialChar, sleep } from 'Common/scripts/Functions';

const env = process.env;
const ADD_DATA = loader('src/Graphql/FormsData/addFormsData.graphql');
const ADD_ATTACHMENTS = loader('src/Graphql/FormsData/addFormsDataAttachments.graphql');
const MAX_FILES_UPOLOAD = 4;
export const FORM_KEY = GUARD_APPLICATION_FORM;


const SuccessScreen = () => {
    return (<div style={{ backgroundColor: '#001529', flex: 1, justifyContent: "center", alignItems: "center", display: 'flex' }}>
        <Result
            status="success"
            title={<span style={{ color: 'white' }}>Security Guard Job Application Submitted Successfully!</span>}
            extra={<img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/logo-light.png`} />}
        // extra={<img src="https://sp-ao.shortpixel.ai/client/q_glossy,ret_img/https://alistsecurity.com/wp-content/uploads/2021/03/Website-Logo-Dark-Ver.-2.png" />}
        />
    </div>)
}

const testData = () => {
    if (env.NODE_ENV != 'development') return {};

    return {
        "f_name": "Faheem",
        "m_name": "Ullah",
        "l_name": "Hashmi",
        // "appt_no": "12",
        "street": "Some street",
        "province": "alberta",
        "city": "calgary",
        "postal_code": "12345",
        "home_phone": "123456789",
        "mobile_phone": "123456789",
        "email_address": "mail@mail.com",
        "d_o_b": "2023-01-01T04:58:56.097Z",
        "gender": "male",
        "position_applied_for": "petrol_guard",
        "license_no": "123456789",
        "license_expiry": "2023-01-01T04:59:07.581Z",
        "currently_licensed": true,
        "worked": true,
        "applied": true,
        "question_current": true,
        "older_than_18": true,
        "legally_entitled": true,
        "convicted_of_crime": true,
        "previously_worked_date": "2023-01-01T04:59:13.530Z",
        "previously_applied_date": "2023-01-01T04:59:15.246Z",
        "current_company_name": "Some company name",
        "crime": "Some description about the crime I did.",
        "available_to_work": "part_time",
        "available_start_date": "2023-01-01T05:00:02.552Z",
        "morning_mon": true,
        "afternoon_tue": true,
        "night_wed": true,
        "afternoon_thu": true,
        "morning_fri": true,
        "afternoon_sat": true,
        "night_sun": true,
        "true_availability": true,
        "availability_changes": true,
        "valid_driving_license": true,
        "vehicle_owner": true,
        "vehicle_make": "Toyota",
        "vehicle_model": "Corola",
        "vehicle_license_plate_no": "123ABC",
        "vehicle_color": "white",
        "experience": [
            {
                "firstName": "",
                "lastName": "",
                "company_name": "Some company name",
                "supervisor_name": "Jhon Mark",
                "supervisor_work_number": "12345",
                "hourly_rate": "12",
                "work_location": "some work location",
                "work_site_property_name": "some site",
                "category": "condo",
                "work_start_date": "2023-01-01T05:01:34.686Z",
                "work_end_date": "2023-01-31T05:01:36.800Z",
                "work_end_season": "some work end reason"
            }
        ],
        "highest_level_of_education": "Some highest education",
        "secondary_school_diploma": "yes",
        "college_university_techicalschool": "yes",
        "security_training_course": true,
        "training_courses_detail": "some course i did",
        refrences: [
            {
                "fullname": "Name 1",
                "number": "111111111",
                "position": "some position 1",
                "company": "some company 1",
            },
            {
                "fullname": "Name 2",
                "number": "222222",
                "position": "some position 2",
                "company": "some company 2",
            },
        ],
        "emergency_contact": [
            {
                "fullname": "Emergency contact name 1",
                "number": "111111111",
                "relation": "relation 1"
            },
            {
                "fullname": "Emergency contact name 2",
                "number": "222222222",
                "relation": "relation 2"
            }
        ]

    }
}

const defaultValue = {
    ...testData()
}

const DataPreview = props => {
    // console.log("props.fileList: ", props.fileList)

    return (<>
        <p style={{ width: "100%", fontSize: "30px", textAlign: "center", textTransform: "capitalize", color: "#2F2F2F", fontWeight: "bold" }}>{props.f_name} {props.m_name} {props.l_name}</p>

        <div className='preview_card'>
            <Row gutter={[15]}>
                <Col flex="50%" style={{ borderRight: "1px solid #EEE" }}>
                    <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="address-book" /> Contact</div>
                    <DataRow col1='Email address' col2={props.email_address} />
                    <DataRow col1='Home phone' col2={props.home_phone} />
                    <DataRow col1='Mobile phone' col2={props.mobile_phone} />
                    <DataRow col1='Address: ' col2={`${props.street}, ${props.province}, ${props.city}, ${props.postal_code}`} />
                </Col>
                <Col flex="auto">
                    <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="user-alt" /> Personal Information</div>
                    <DataRow col1='DOB: ' col2={moment(props.d_o_b).format(defaultDateFormat)} />
                    {/* <DataRow col1='Residential status: ' col2={props.residential_status} /> */}
                    <DataRow col1='Position applied for: ' col2={props.position_applied_for} />
                    <DataRow col1='Security guard license #: ' col2={props.license_no} />
                    <DataRow col1='Security license expiry #: ' col2={moment(props.license_expiry).format(defaultDateFormat)} />
                </Col>
            </Row>
            <hr style={{ margin: "50px" }} />

            <Row gutter={[15]}>
                <Col flex="50%" style={{ borderRight: "1px solid #EEE" }}>
                    <DataRow col1='Do you have a valid Alberta Security License?: ' col2={<Icon icon={props.currently_licensed ? "check" : "times"} size={"1x"} color={props.currently_licensed ? "green" : "red"} />} />
                    <DataRow col1='Are you 18 years of age or older?: ' col2={<Icon icon={props.older_than_18 ? "check" : "times"} size={"1x"} color={props.older_than_18 ? "green" : "red"} />} />
                    <DataRow col1='Are you legally entitled to work in Canada?: ' col2={<Icon icon={props.legally_entitled ? "check" : "times"} size={"1x"} color={props.legally_entitled ? "green" : "red"} />} />
                    <DataRow col1='Have you ever been convicted of a crime?: ' col2={<Icon icon={props.convicted_of_crime ? "check" : "times"} size={"1x"} color={props.convicted_of_crime ? "green" : "red"} />} />
                </Col>
                <Col flex="auto">
                    <DataRow col1='Have you previously worked with us?: ' col2={<Icon icon={props.worked ? "check" : "times"} size={"1x"} color={props.worked ? "green" : "red"} />} />
                    {props.worked &&
                        <DataRow col1='Previous working date: ' col2={moment(props.previously_worked_date).format(defaultDateFormat)} />
                    }
                    <DataRow col1='Have you previously applied with us?: ' col2={<Icon icon={props.applied ? "check" : "times"} size={"1x"} color={props.applied ? "green" : "red"} />} />
                    {props.applied &&
                        <DataRow col1='Previous working date: ' col2={moment(props.previously_applied_date).format(defaultDateFormat)} />
                    }
                    <DataRow col1='Are you currently working for another company?: ' col2={<Icon icon={props.question_current ? "check" : "times"} size={"1x"} color={props.question_current ? "green" : "red"} />} />
                    {props.question_current &&
                        <DataRow col1='Compnay name: ' col2={props.current_company_name} />
                    }
                </Col>
            </Row>
            <hr style={{ margin: "50px" }} />


            <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="clock" /> Availability</div>
            <Row>
                <Col flex="50%"><DataRow col1="Are you available to work?" col2={props.available_to_work} inline /></Col>
                <Col flex="auto"><DataRow col1="Available start date?" col2={moment(props.available_start_date).format(defaultDateFormat)} inline /></Col>
            </Row>

            <Row align="middle" style={{ color: "#060079" }} className='dark'>
                {['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
                    return <Col flex="12.5%" align="center" style={{ borderBottom: "1px solid #EEE", paddingTop: "10px", borderRight: "1px solid #EEE", fontWeight: "bold" }} key={index}>{day}</Col>
                })}
            </Row>

            {["Morning", "Afternoon", "Night"].map((daypart, index) => (
                <Row align="middle" key={index}>
                    <Col flex="12.5%" style={{ height: "40px", paddingTop: "10px" }}>{daypart}</Col>
                    {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                        return <Col key={i} flex="12.5%" align="center" style={{ height: "40px", padding: "10px", borderBottom: "1px solid #EEE", borderRight: "1px solid #EEE" }}>
                            <>
                                {props[`${String(daypart).toLowerCase()}_${day}`] ?
                                    <Icon icon="check" size={"1x"} color="green" /> :
                                    <Icon icon="times" size={"1x"} color="#EEE" />
                                }
                            </>
                        </Col>
                    })}
                </Row>
            ))}

            <hr style={{ margin: "50px" }} />
            <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="car-side" /> Driving Information</div>
            <div className='dark'>
                <DataRow col1='Do you have a valid driving license?: ' col2={<Icon icon={props.valid_driving_license ? "check" : "times"} size={"1x"} color={props.valid_driving_license ? "green" : "red"} />} />
            </div>
            <div className='simple'>
                <DataRow col1='Do you own or have regular access to a vehicle to get back and forth to and from work?: ' col2={<Icon icon={props.vehicle_owner ? "check" : "times"} size={"1x"} color={props.vehicle_owner ? "green" : "red"} />} />
                {props.vehicle_owner && <Row gutter={[12]}>
                    <Col flex="25%"><DataRow col1="Make" col2={props.vehicle_make} inline /></Col>
                    <Col flex="25%"><DataRow col1="Model" col2={props.vehicle_model} inline /></Col>
                    <Col flex="25%"><DataRow col1="License plate no." col2={props.vehicle_license_plate_no} inline /></Col>
                    <Col flex="25%"><DataRow col1="Color" col2={props.vehicle_color} inline /></Col>
                </Row>}
            </div>

            <hr style={{ margin: "50px" }} />
            <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="user-graduate" /> Education</div>
            <div className='dark'><DataRow col1="The highest level of education completed?" col2={props.highest_level_of_education} /></div>
            <div className='simple'><DataRow col1="Do you have a secondary school diploma?" col2={<Icon icon={props.secondary_school_diploma ? "check" : "times"} size={"1x"} color={props.secondary_school_diploma ? "green" : "red"} />} /></div>
            <div className='dark'><DataRow col1="Have you completed a college, university or technical school program?" col2={<Icon icon={props.college_university_techicalschool ? "check" : "times"} size={"1x"} color={props.college_university_techicalschool ? "green" : "red"} />} /></div>
            {props.training_courses_detail && <div className='simple'><DataRow col1="Training courses details" col2={props.training_courses_detail} /></div>}


            <hr style={{ margin: "50px" }} />
            <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="lock" /> Security Experience</div>
            {(!props.experience || props.experience.length < 1) && <p style={{ marginLeft: "30px" }}>No security experience</p>}
            {(props.experience && props.experience.length > 0) && props.experience.map((row, i) => {
                return (<div key={i}>
                    <Row>
                        <Col flex="auto"><DataRow col1="Security company name" col2={row.company_name} /></Col>
                        <Col flex="33%"><DataRow col1="Supervisor name" col2={row.supervisor_name} /></Col>
                        <Col flex="33%"><DataRow col1="Supervisor work number" col2={row.supervisor_work_number} /></Col>
                    </Row>

                    <Row>
                        <Col flex="150px"><DataRow col1="Hourly rate" col2={row.hourly_rate} /></Col>
                        <Col flex="auto"><DataRow col1="Work location / site address(where security guard duty is/was performed)" col2={row.work_location} /></Col>
                    </Row>

                    <Row>
                        <Col flex="50%"><DataRow col1="Work / site / property name" col2={row.work_site_property_name} /></Col>
                        <Col flex="auto"><DataRow col1="Category (condo, truck yard etc)" col2={row.category} /></Col>
                    </Row>
                    <Row>
                        <Col flex="50%"><DataRow col1="Work duration" col2={<>{moment(row.work_start_date).format(defaultDateFormat)} till <br /> {moment(row.work_end_date).format(defaultDateFormat)}</>} /></Col>
                        <Col flex="auto"><DataRow col1="Work end reason" col2={row.work_end_season} /></Col>
                    </Row>
                </div>)
            })}


            <hr style={{ margin: "50px" }} />
            <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="address-card" /> References</div>
            {(!props.refrences || props.refrences.length < 1) && <p style={{ marginLeft: "30px" }}>No References provided</p>}
            {(props.refrences && props.refrences.length > 0) && props.refrences.map((row, i) => {
                return (<div key={i}>
                    <Divider orientation="left">Refrence # {i}</Divider>
                    <Row>
                        <Col flex="250px"><DataRow col1="Name" col2={row.fullname} /></Col>
                        <Col flex="250px"><DataRow col1="Contact number" col2={row.number} /></Col>
                        <Col flex="250px"><DataRow col1="Position" col2={row.position} /></Col>
                        <Col flex="250px"><DataRow col1="Company Name" col2={row.company} /></Col>
                    </Row>
                </div>)
            })}

            <hr style={{ margin: "50px" }} />
            <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="address-book" /> Emergency Contacts</div>
            <ol>
                {(props.emergency_contact && props.emergency_contact.length > 0) ? props.emergency_contact.map((contact, i) => (
                    <li key={i}>
                        <Icon icon="phone" /> {contact.number} - {contact.fullname} - ({contact.relation})
                    </li>
                )) : <p >No emergency contacts</p>}
            </ol>


            <hr style={{ margin: "50px" }} />
            <div>
                <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="file-alt" /> Attachment(s)</div>
                <Row gutter={[10, 10]}>
                    {props.fileList.map((item, i) => {
                        return <Col key={i}>{item.name}</Col>
                    })}
                </Row>
            </div>



            {/* <hr style={{ margin: "50px" }} />
      <Row style={{ flexWrap: "nowrap" }}>
        <Col flex="auto">
          <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="handshake" /> Signature</div>
          <div className='dark'>
            <DataRow col1={props.certify ? <Icon icon="check" size={"1x"} color="green" /> : <Icon icon="times" size={"1x"} color="red" />} 
              col2={"I certify that my answers are true and complete to the best of my knowledge. If this application leads to employment, I understand that false or misleading information in my application or interview may result in my release"} />
          </div>
        </Col>
        <Col>
          <img src={props.signatureDataURL} alt="" style={{ margin: "10px auto" }} />
        </Col>
      </Row>

      <DataRow col1="How did you hear about this job?" col2={props.job_info_source} />
      <DataRow col1="Extra Notes (optional)" col2={props.note} /> */}

        </div>

    </>)
}

const stepsArray = [
    { title: "Personal Information" },
    { title: "Availability" },
    { title: "Knowledgebase" },
    { title: "Competencies" },
    { title: "Skills" },
    { title: "Acknowledgement" },
]
const knowledgebase_quizQuestions = [
    { question:"What is the primary role of a security guard?", 
        options: [ "Enforce company policies", "Observe and report", "Engage in active combat", "Provide customer service" ],
        answer: 1
    },
    { question:"If you find an unattended bag in a busy area, what is your first course of action?", 
        options: ["Ignore it, as it's probably nothing", "Evacuate the area immediately", "Observe it from a safe distance and report it", "Approach and open it to check its contents" ],
        answer: 2
    },
    { question:"As a security guard, you have the authority to", 
        options: ["Make arrests like a police officer", "Detain individuals indefinitely", "Use any force necessary in any situation", "Act within the scope of company policy and local laws" ],
        answer: 3
    },
    { question:"How often should you patrol an assigned area?", 
        options: ["Once at the beginning and end of your shift", "Only when you suspect there is a problem", "At random but regular intervals", "Only when instructed by a supervisor" ],
        answer: 2
    },
    { question:"What is your main priority during a fire alarm?", 
        options: ["To secure valuable company assets", "To immediately investigate the cause of the alarm", "To assist in the evacuation of the premises", "To wait for emergency services to arrive" ],
        answer: 2
    },
    { question:"If you witness a colleague behaving unethically, what should you do?", 
        options: ["Confront them directly", "Ignore it; it's not your concern", "Report it to a supervisor or manager", "Participate to maintain good relations" ],
        answer: 2
    },
    { question:"What should you do if an individual becomes aggressive and confrontational?", 
        options: ["Use physical force immediately", "Try to de-escalate the situation verbally", "Walk away and ignore the individual", "Threaten them with arrest" ],
        answer: 1
    },
    { question:"In the event of a medical emergency, your first step should be to", 
        options: ["Move the victim to another location", "Administer first aid immediately", "Call for medical help", "Wait for others to take action" ],
        answer: 2
    },
    { question:"Which of the following is a vital skill for a security guard?", 
        options: ["The ability to run long distances", "Advanced combat skills", "Effective communication", "Technical hacking skills" ],
        answer: 2
    },
    { question:"How would you handle finding a colleague asleep on the job?", 
        options: ["Ignore it and continue with your duties", "Wake them and cover for them", "Report the incident to a supervisor", "Take a photo for evidence" ],
        answer: 2
    },

    { question:"What is the best way to handle sensitive information you might encounter on the job?", 
        options: ["Share it with trusted colleagues", "Keep it to yourself and forget it", "Report it to your supervisor", "Use it for personal gain" ],
        answer: 2
    },
    { question:"CCTV systems are primarily used for", 
        options: ["Monitoring employee productivity", "Deterrence and observation", "Entertainment", "Recording private conversations" ],
        answer: 1
    },
    { question:"A visitor refuses to show ID at the entrance. You should", 
        options: ["Let them in, not wanting to cause a scene", "Deny access and inform them of the policy", "Call the police immediately", "Allow them in but follow them around" ],
        answer: 1
    },
    { question:"The most effective way to patrol is", 
        options: ["Quickly, to cover more ground", "Slowly and without paying much attention", "In a predictable pattern", "Randomly, to increase unpredictability" ],
        answer: 3
    },
    { question:"If a bomb threat is called in, your first action should be to", 
        options: ["Search for the bomb yourself", "Evacuate the building immediately", "Notify your supervisor and follow protocol", "Ignore it as a probable prank" ],
        answer: 2
    },
    { question:"During a high-stress situation, it is important to", 
        options: ["Stay calm and follow training", "Act quickly on instinct", "Wait for others to act first", "Take charge and give orders" ],
        answer: 0
    },
    { question:"When writing a report, it's important to", 
        options: ["Include your opinions and feelings", "Use complex language to impress", "Be concise and factual", "Leave out minor details to save time" ],
        answer: 2
    },
    { question:"When communicating with the public, you should", 
        options: ["Use technical jargon to establish authority", "Be polite and professional", "Avoid eye contact to remain distant", "Only speak when spoken to" ],
        answer: 1
    },
    { question:"Physical force should be used", 
        options: ["As a first resort", "Only when all other options have failed", "Frequently, to show authority", "To intimidate when necessary" ],
        answer: 1
    },
    { question:"Your duty as a security guard includes", 
        options: ["Enforcing personal morals", "Protecting property and people", "Making legal arrests regularly", "Entertaining guests and visitors" ],
        answer: 1
    },
    
    { question:"When you're unsure about a particular law or regulation, you should", 
        options: ["Make an assumption based on past experience", "Consult with a colleague", "Look up the information or ask a supervisor", "Ignore it and do what seems best" ],
        answer: 2
    },
    { question:"The use of surveillance equipment is primarily to", 
        options: ["Spy on employees", "Protect property and deter crime", "Monitor staff breaks and activities", "Entertain security staff" ],
        answer: 1
    },
    { question:"In the event of a suspicious object or package, you should", 
        options: ["Move it to a safe location", "Ignore it unless it smells or makes a noise", "Evacuate the area and contact the appropriate authorities", "Open it to check its contents" ],
        answer: 2
    },
    { question:"When dealing with a difficult person, the best approach is usually to", 
        options: ["Threaten them with arrest", "Use force to show dominance", "Remain calm and try to de-escalate the situation", "Call for backup immediately" ],
        answer: 2
    },
    { question:"The most important aspect of teamwork in security is", 
        options: ["Competing to show who is the best guard", "Sharing gossip about incidents", "Communication and support", "Working alone to prove independence" ],
        answer: 2
    },
    { question:"If you are the first to arrive at the scene of an accident, you should", 
        options: ["Move any injured individuals", "Secure the scene and provide first aid if trained", "Wait for others to arrive and take action", "Collect evidence immediately" ],
        answer: 1
    },
    { question:"The proper way to use a walkie-talkie or radio is to", 
        options: ["Hold down the talk button at all times", "Speak as loudly as possible", "Use code language or slang", "Speak clearly and release the button when finished" ],
        answer: 3
    },
    { question:"If you notice another guard violating company policy, you should", 
        options: ["Join in to fit in with the team", "Ignore it; it's not your problem", "Report it to a supervisor or the appropriate authority", "Blackmail them for personal benefit" ],
        answer: 2
    },
    { question:"The purpose of a fire drill is to", 
        options: ["Disrupt the workday for a break", "Test the fire alarms and equipment", "Ensure everyone knows the evacuation procedure", "Determine the fastest individuals in the office" ],
        answer: 2
    },
    { question:"When checking identification, you should", 
        options: ["Glance at it and hand it back quickly", "Verify the photo matches the person and check expiration date", "Ask them to confirm their details verbally instead", "Not bother; it's usually a waste of time" ],
        answer: 1
    },
    { question:"When checking identification, you should", 
        options: ["Glance at it and hand it back quickly", "Verify the photo matches the person and check expiration date", "Ask them to confirm their details verbally instead", "Not bother; it's usually a waste of time" ],
        answer: 1
    },
]

const competencies_quizQuestions = [
    { heading: "Vandalism Incident",
        question: "Imagine you are on duty and discover graffiti and signs of vandalism in a restricted area of the building. Describe how you would document this incident in your report. Include details on the time of discovery, a description of the vandalism, any potential suspects or witnesses, and the actions you took upon finding the scene.",
    },
    { heading: "Theft Report",
        question: "You receive a report from an employee that their personal belongings have been stolen from their workspace while they were in a meeting. Write a report detailing the information you would collect about the incident, how you would investigate the theft, and any immediate measures you took to address the situation.",
    },
    { heading: "Slip and Fall Accident",
        question: "During your patrol, a visitor slips on a wet floor where the sign was not visible and sustains a minor injury. Write a detailed incident report including your immediate response, the assistance you provided, how you secured the area, and any witness statements you collected.",
    },
    { heading: "Unauthorized Access Attempt",
        question: "You notice an individual trying to enter the premises through a staff-only entrance without proper identification. They leave quickly when they see you approaching. Write a report on this incident, describing the person, their behavior, the time and location of the attempt, and how you responded to the situation.",
    },
    { heading: "Fire Alarm and Evacuation",
        question: "A fire alarm goes off in your building, and you help to manage the evacuation. After the incident, it's determined to have been caused by a faulty kitchen appliance. Write a report detailing the sequence of events from the alarm to the all-clear, including how staff and visitors were evacuated, the coordination with emergency services, and any issues or challenges encountered during the incident.",
    },
]

const skills_quizQuestions = [
    { heading: "General Questions",
        questions: [
            "Can you tell us about your previous experience in the security industry?", 
            "Why are you interested in this security guard position specifically in Calgary?",
            "How do you stay informed about changes in security regulations and laws, particularly those in Alberta?"
        ]
    },
    { heading: "Scenario-Based",
        questions: [
            "If you noticed someone acting suspiciously on the property, what steps would you take?",
            "Can you describe a time when you successfully managed a difficult or dangerous situation?",
            "If you found a door unlocked that should have been secured, what would be your course of action?"
        ]
    },
    { heading: "Skills and Knowledge",
        questions: [
            "What are the most important qualities a security guard should possess?",
            "Are you familiar with the legal authority that security guards have in Alberta? How does it affect your actions on the job?",
            "How do you handle situations where you need to communicate with people who are aggressive or non-compliant?"
        ]
    },
    { heading: "Physical and Mental Readiness",
        questions: [
            "This job can require long hours of standing and patrolling. How do you maintain your focus and physical readiness during your shift?",
            "Security work can be stressful and sometimes monotonous. How do you manage stress and stay alert?",
        ]
    },
    { heading: "Technical Skills and Certification",
        questions: [
            "Are you trained in first aid and CPR? Can you provide valid certification?",
            "What security technology and systems are you familiar with? (e.g., surveillance cameras, alarm systems)",
        ]
    },
    { heading: "Situational Awareness and Ethics",
        questions: [
            "Describe a situation where you had to make a quick decision to prevent a security breach. What was the outcome?",
            "How would you handle a situation where you witnessed a fellow security guard acting unethically or illegally?",
        ]
    },
]

class GuardEvaluationFormComp extends Component {
    signaturePad = {}

    constructor(props) {
        super(props)
        this.state = {
            loading: false, data: null, success: false, signatureDataURL: null, fileList: [],
            activeStep: 0,
            // max_file_upload: MAX_FILES_UPOLOAD
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onStepClick = this.onStepClick.bind(this);
    }

    clearSignature = (event) => {
        event.preventDefault()
        this.signaturePad.clear()
        if (this.state.signatureDataURL !== null) this.setState({ signatureDataURL: null })
    }

    trimSignature = (event) => {
        event.preventDefault()

        const signatureDataURL = this.signaturePad.getTrimmedCanvas().toDataURL('image/png');

        this.setState({ signatureDataURL }, () => {
            console.log("this is the url og sign pad", this.state.signatureDataURL)
        })
    }

    async handleUpload(_id) {
        const { fileList } = this.state;
        if (!fileList || fileList.length < 1) return false;

        var _files = fileList.slice()
        if (_files.length > 6) _files = _files.slice(0, 6)
        // else _files = this.state.fileList

        const formData = new FormData();
        formData.append('action', "form_data.attachments");
        formData.append('_id', _id);
        _files.forEach(file => {
            formData.append('files[]', file);
        });

        const uploadResults = await axios.post(process.env.REACT_APP_API_URL + '/upload/form_data', formData)
            .then(({ data }) => {
                // console.log("data: ", data)
                // const { result, status, success, error } = data;
                return data;
            })
            .catch(function (error) {
                console.log(error);
                return { error: { message: "Request Error!" } };
            });

        return uploadResults;
    };


    async onSubmit(values) {
        const { activeStep } = this.state;

        if (activeStep < stepsArray.length-1) {
            let next = activeStep + 1; // this.onStepClick(activeStep + 1, values);
            this.setState({ activeStep: next })
            return false;
        }

        const input = { ref_key: FORM_KEY, title: `${values.f_name} ${values.m_name} ${values.l_name}`, }

        const fields = [
            { name: 'f_name', label: 'First name', value: values.f_name },
            { name: 'm_name', label: 'Middle name', value: values.m_name },
            { name: 'l_name', label: 'Last name', value: values.l_name },
            {
                name: 'availability', label: 'Availability', value: JSON.stringify({
                    mon: [(values.morning_mon ? "morning" : ""), (values.afternoon_mon ? "afternoon" : ""), (values.night_mon ? "night" : "")],
                    tue: [(values.morning_tue ? "morning" : ""), (values.afternoon_tue ? "afternoon" : ""), (values.night_tue ? "night" : "")],
                    wed: [(values.morning_wed ? "morning" : ""), (values.afternoon_wed ? "afternoon" : ""), (values.night_wed ? "night" : "")],
                    thu: [(values.morning_thu ? "morning" : ""), (values.afternoon_thu ? "afternoon" : ""), (values.night_thu ? "night" : "")],
                    fri: [(values.morning_fri ? "morning" : ""), (values.afternoon_fri ? "afternoon" : ""), (values.night_fri ? "night" : "")],
                    sat: [(values.morning_sat ? "morning" : ""), (values.afternoon_sat ? "afternoon" : ""), (values.night_sat ? "night" : "")],
                    sun: [(values.morning_sun ? "morning" : ""), (values.afternoon_sun ? "afternoon" : ""), (values.night_sun ? "night" : "")],
                })
            },

            { name: 'signatureDataURL', label: "Signatures", value: this.state.signatureDataURL },
            { name: 'note', label: "Extra Notes (optional)", value: values.note },

        ]
        Object.assign(input, { fields });

        if (!this.state.signatureDataURL) {
            alert("Oops! you forgot to sign your application!")
            return;
        }

        return false;
        
        this.setState({ loading: true, error: null });

        const added = await this.props.client.mutate({ mutation: ADD_DATA, variables: { input } }).then(r => (r.data.addFormsData)).catch(err => {
            console.log(__error("ERROR"), err);
            return { error: { message: "Failed to process the request!" } };
        });

        if (added.error) {
            message.error(added.error.message);
            this.setState({ loading: false, error: added.error.message });
            return false;
        }

        const attachments = await this.handleUpload(added._id);

        if (!attachments) console.log("No attachments found")
        else if ((attachments && attachments.error) || !attachments.result) message.error("Unable to upload your documents, please submit them in person.")
        else if (attachments && attachments.result && attachments.success) {
            await this.props.client.mutate({ mutation: ADD_ATTACHMENTS, variables: { attachments: attachments.result, id: added._id } })
                .then(r => (r.data.addFormsDataAttachments))
                .catch(err => {
                    console.log(__error("ERROR"), err);
                    message.error("Unable to save uploaded data");
                });

            message.success("Submission successfull!")
        }

        this.setState({ loading: false, success: added });

        return true;

    }

    onStepClick(val, values) {
        if (this.state.activeStep < val && !this.validateStep(values)) return false;
        this.setState({ activeStep: val })
    }

    validateStep(values) {
        // if (this.state.activeStep == 4 && (this.state.fileList.length < (values.max_file_upload || 0))) {
        //     message.error("Please provide the required document(s)");
        //     return false;
        // }
        return true;
    }

    render() {
        const { busy, error, success, activeStep } = this.state;
        const filterString = (e, onChange) => onChange(removeSpecialChar(e.target.value, { filter: { space: true } }));

        if (success) return <SuccessScreen />

        return (<>
            <div style={{ backgroundColor: "#f8f9fa" }} className="form-data">
                <Header title="Security Guard Evaluation" />

                <div style={{ display: 'flex', justifyContent: "center" }}>
                    <div style={{ maxWidth: "1200px", width: "100%", margin: "20px" }}>

                        <FormComponent onSubmit={this.onSubmit} id='GuardInfoForm' loading={busy} debug={true} fields={defaultValue} moveOnError
                            style={{ width: "100%", padding: "0", margin: "0" }}
                            mutators={{
                                ...arrayMutators,
                                updateMaxUplpads: (newValueArray, state, tools) => {
                                    let node = newValueArray[0];
                                    tools.changeValue(state, 'max_file_upload', () => node);
                                },
                            }}
                            form_render={formProps => {
                                const { values, form, submitting, invalid, dirty, valid } = formProps;
                                // if (formProps.errors && formProps.submitFailed) this.moveToErrorPosition(formProps.errors);
                                let avilabilitySetup = values.morning_mon || values.afternoon_mon || values.night_mon || values.morning_tue || values.afternoon_tue || values.night_tue || values.morning_wed || values.afternoon_wed || values.night_wed || values.morning_thu || values.afternoon_thu || values.night_thu || values.morning_fri || values.afternoon_fri || values.night_fri || values.morning_sat || values.afternoon_sat || values.night_sat || values.morning_sun || values.afternoon_sun || values.night_sun;

                                return (<>
                                    <div style={{ margin: "0 20px 20px 20px" }}>
                                        <Steps current={activeStep} direction="horizontal" size="small" labelPlacement="vertical">
                                            {stepsArray.map((item, i) => (<Steps.Step title={<span style={activeStep == i ? { color: "black", fontWeight: "bold" } : { }}>{item.title}</span>} key={i} />))}
                                        </Steps>
                                    </div>

                                    {activeStep == 0 && <>
                                        <div className='card'>
                                            <span style={{ fontSize: "24px", color: "#001529" }}><Icon icon="user-alt" color={"#001529"} style={{ marginLeft: "10px" }} /> Basic Information</span>

                                            <Row>
                                                <Col span={24} sm={12} md={12} lg={10}>
                                                    <Row style={{ flexWrap: "nowrap" }}>
                                                        <Col flex="100px"><FormField name="gender" type="select" size="medium" label="Gender" validate={rules.required} placeholder="SELECT" data={[
                                                            { _id: "male", title: "Male" }, { _id: "female", title: "Female" }, { _id: "x", title: "X" },
                                                        ]} /></Col>
                                                        <Col flex="auto"><FormField id="f_name" label="First name" name="f_name" size="medium" type="text" validate={rules.required} /></Col>
                                                    </Row>
                                                </Col>
                                                <Col span={24} sm={12} md={7} lg={7}><FormField label="Middle name" name="m_name" size="medium" type="text" validate={rules.required} /></Col>
                                                <Col span={24} sm={12} md={7} lg={7}><FormField label="Last name" name="l_name" size="medium" type="text" validate={rules.required} /></Col>
                                            </Row>

                                            <Row>
                                                <Col span={24} sm={12} md={12}><FormField name="street" type="text" size="medium" label="Street address" validate={rules.required} /></Col>
                                                <Col span={12} sm={6} md={4}>
                                                    <FormField name="province" type="select" size="medium" placeholder="SELECT" validate={rules.required} label="Province" data={[
                                                        { _id: "alberta", title: "Alberta" }
                                                    ]} />
                                                </Col>
                                                <Col span={12} sm={6} md={4}>
                                                    <FormField name="city" type="select" size="medium" placeholder="SELECT" label="City" validate={rules.required} data={[
                                                        { _id: "calgary", title: "Calgary" }, { _id: "airdrie", title: "Airdrie" }, { _id: "toronto", title: "Toronto" }
                                                    ]} />
                                                </Col>
                                                <Col span={12} sm={6} md={4}>
                                                    <FormField name="postal_code" size="medium" type="text" label="Postal code" validate={rules.required} />
                                                </Col>

                                                <Col span={24} sm={8} lg={6} md={6}><FormField name="mobile_phone" type="text" size="medium" label="Mobile phone " placeholder="FORMAT: XXXXXXXXXX"
                                                    onChange={filterString} validate={rules.required} /></Col>
                                                <Col span={24} sm={8} lg={6} md={6}><FormField name="email_address" type="email" size="medium" label="Email address" validate={rules.required} /></Col>
                                                <Col span={24} sm={8} lg={6} md={6}><FormField name="d_o_b" size="medium" type="date" label="Date of birth" placeholder={defaultDateFormat}
                                                    validate={rules.required} /></Col>

                                                <Col span={24} sm={12} md={8}><FormField name="sin_no" type="text" size="medium" placeholder='XXXXXX' label="Social security number(SIN) #"
                                                    onChange={filterString} validate={rules.required} maxLength={9}
                                                /></Col>
                                                
                                                <Col span={24} sm={12} md={8}><FormField name="license_no" type="text" size="medium" placeholder='WXXXXXX' label="Security guard license #"
                                                    onChange={filterString}
                                                    validate={rules.required} maxLength={9}
                                                /></Col>
                                                <Col span={24} sm={12} md={6}><FormField name="license_expiry" type="date" size="medium" label="License expiry" placeholder={defaultDateFormat} validate={rules.required} /></Col>
                                            </Row>

                                            {/* <hr />
                                            <span style={{ fontSize: "24px", color: "#001529" }}><Icon icon="user-alt" color={"#001529"} style={{ marginLeft: "10px" }} /> Position</span> */}


                                            {/* <Col span={24} sm={12} md={8}>
                                                <FormField name="position_applied_for" validate={rules.required} type="select" label="Position applied for" size="medium" placeholder="SELECT" data={[
                                                    { _id: "petrol_guard", title: "Petrol Guard" }, { _id: "security_guard", title: "Security guard" },
                                                ]} />
                                            </Col> */}


                                            {/* <div className='dark'>
                                                <FormField type="checkbox" name="currently_licensed" style={{ padding: "0px", margin: "0px" }} validate={rules.required}>
                                                    Do you have a valid Alberta Security License?
                                                </FormField>
                                            </div>

                                            <div className='simple'>
                                                <Row align="middle" gutter={[10, 10]}>
                                                    <Col flex="auto">
                                                        <FormField type="checkbox" name="worked" style={{ padding: "0px", margin: "0px" }}>
                                                            Have you previously worked with us?
                                                        </FormField>
                                                    </Col>
                                                    <Col>{values.worked &&
                                                        <FormField name="previously_worked_date" size="medium" compact type="date" placeholder={defaultDateFormat} validate={rules.required} />
                                                    }</Col>
                                                </Row>
                                            </div>

                                            <div className='dark'>
                                                <Row align="middle" gutter={[10, 10]}>
                                                    <Col flex="auto">
                                                        <FormField type="checkbox" name="applied" style={{ margin: "0px", padding: "0px" }}>
                                                            Have you previously applied with us?
                                                        </FormField>
                                                    </Col>
                                                    <Col>{values.applied &&
                                                        <FormField name="previously_applied_date" size="medium" compact type="date" placeholder="YY-MM-DD" validate={rules.required} />
                                                    }</Col>
                                                </Row>
                                            </div>

                                            <div className='simple'>
                                                <Row align="middle" gutter={[10, 10]}>
                                                    <Col flex="auto">
                                                        <FormField name="question_current" type="checkbox" style={{ margin: "0px", padding: "0px" }}>
                                                            Are you currently working for another company?
                                                        </FormField>
                                                    </Col>
                                                    <Col flex="350px">{values.question_current &&
                                                        <FormField name="current_company_name" size="medium" compact type="text" placeholder="Please enter the company name" validate={rules.required} />
                                                    }</Col>
                                                </Row>
                                            </div>

                                            <div className='dark'>
                                                <FormField type="checkbox" name="legally_entitled" style={{ margin: "0px", padding: "0px" }}>
                                                    Are you legally entitled to work in Canada?
                                                </FormField>
                                            </div>

                                            <div className='simple'>
                                                <FormField name="convicted_of_crime" type="checkbox" style={{ margin: "0px", padding: "0px" }}>
                                                    Have you ever been convicted of a crime?
                                                </FormField>
                                            </div>

                                            {values.convicted_of_crime &&
                                                <FormField align="left" rows={2} name="crime" size="medium" type="textarea" label="Describe your crime" validate={rules.required} />
                                            }

                                            <div className='simple'>
                                                <FormField type="checkbox" name="older_than_18" style={{ margin: "0px", padding: "0px" }} validate={rules.required}>
                                                    I confirm  that I am 18 at least 18 years old
                                                </FormField>
                                            </div> */}


                                        </div>
                                    </>}

                                    {activeStep == 1 && <>
                                        <div className='card' style={{ maxWidth: "1200px", overflowX: "auto" }}>
                                            <span style={{ fontSize: "28px", color: "#001529" }} > <Icon color={"#001529"} icon="clock" style={{ marginLeft: "10px" }} /> Position & Availability</span>


                                            <Divider style={{ lineHeight: "1", marginBottom:"0" }}>Looking for a position as <small>(Check all that apply)</small></Divider>
                                            <Row gutter={[10]}>
                                                <Col><FormField type="checkbox" name="position.mobile">Mobile Patrol</FormField></Col>
                                                <Col><FormField type="checkbox" name="position.static">Static Guard</FormField></Col>
                                            </Row>

                                            <Divider style={{ lineHeight: "1", marginBottom: "0" }}>Availability to work <small>(Check all that apply)</small></Divider>
                                            <Row>
                                                <Col><FormField type="checkbox" name="availability.fulltime">Full Time</FormField></Col>
                                                <Col><FormField type="checkbox" name="availability.parttime">Part Time</FormField></Col>
                                                <Col><FormField type="checkbox" name="availability.oncall">On Call</FormField></Col>
                                            </Row>

                                            <Divider style={{ lineHeight: "1", marginBottom: "0" }}>Availability for Shifts</Divider>
                                            <FormField name="availability.start_date" size="medium" placeholder={defaultDateFormat} type="date" label="Date available to start" validate={rules.required} />

                                            <div style={{ minWidth: "800px" }}>
                                                <Row className='dark' align="middle">
                                                    {['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
                                                        return <Col flex="12.5%" className="bold" key={index}>{day}</Col>
                                                    })}
                                                </Row>
                                                <Row className='simple' align="middle">
                                                    {['Morning', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                                                        if (i == 0) return <Col flex="12.5%" className="bold" key={i}>{day}</Col>;
                                                        return <Col flex="12.5%" key={i}><FormField compact name={`availability.morning_${day}`} type='switch' /></Col>
                                                    })}
                                                </Row>
                                                <Row className='dark' align="middle">
                                                    {['Afternoon', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                                                        if (i == 0) return <Col flex="12.5%" className="bold" key={i}>{day}</Col>;
                                                        return <Col flex="12.5%" key={i}><FormField compact name={`availability.afternoon_${day}`} type='switch' /></Col>
                                                    })}
                                                </Row>
                                                <Row className='simple' align="middle">
                                                    {['Night', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                                                        if (i == 0) return <Col flex="12.5% " className="bold" key={i}>{day}</Col>;
                                                        return <Col flex="12.5%" key={i}><FormField compact name={`availability.night_${day}`} type='switch' /></Col>
                                                    })}
                                                </Row>
                                            </div>
                                            <FormField label="Any special Notes regarding availability?" type="textarea" name="availability.notes" />
                                        </div>


                                        {values?.position?.mobile && <>
                                            <div style={{ height: '20px' }} />
                                            <div className='card'>
                                                <span style={{ fontSize: "28px", color: "#001529" }} > <Icon color={"#001529"} icon="car-side" style={{ marginLeft: "10px", marginRight: "5px" }} />Driving Information</span>

                                                <Row gutter={[0]} align="bottom">
                                                    <Col><FormField name="driver.lc_no" label="Driver's License No." type='text' validate={rules.required} /></Col>
                                                    <Col flex="230px"><FormField name="driver.class_name" type="select" size="medium" label="Driving License Class" validate={rules.required} placeholder="SELECT" data={[
                                                        { _id: "Class 1", title: "Class 1" }, 
                                                        { _id: "Class 2", title: "Class 2" }, 
                                                        { _id: "Class 3", title: "Class 3" }, 
                                                        { _id: "Class 4", title: "Class 4" }, 
                                                        { _id: "Class 5 Basic GDL", title: "Class 5 Basic GDL" }, 
                                                        { _id: "Class 5 Advanced GDL", title: "Class 5 Advanced GDL" }, 
                                                        { _id: "Class 5 (non-GDL)", title: "Class 5 (non-GDL)" }, 
                                                        { _id: "Class 6", title: "Class 6" }, 
                                                        { _id: "Class 7", title: "Class 7" }, 
                                                    ]} /></Col>
                                                    <Col><FormField name="driver.lc_expiry" label="Driving license Expiry" type='date' validate={rules.required} /></Col>
                                                </Row>
                                                
                                                {values?.driver?.lc_no && <Row className='simple'>
                                                    <Col flex="100%"><FormField name="driver.vehicle_owner" label="Do you own or have regular access to a vehicle to get back and forth to and from work?" type='switch' /></Col>
                                                </Row>}

                                                {(values?.driver?.vehicle_owner && values?.driver?.lc_no) && <Row>
                                                    <Col span={24} sm={12} md={6}><FormField name="vehicle_make" size="medium" type="text" label="Make" /></Col>
                                                    <Col span={24} sm={12} md={6}><FormField name="vehicle_model" type="text" size="medium" label="Model" /></Col>
                                                    <Col span={24} sm={12} md={6}><FormField name="vehicle_license_plate_no" type="text" size="medium" label="License plate no." /></Col>
                                                    <Col span={24} sm={12} md={6}><FormField name="vehicle_color" type="text" label="Color" size="medium" /></Col>
                                                </Row>}
                                            </div>
                                        </>}

                                    </>}

                                    {activeStep == 2 && <>
                                        {/* <span style={{ fontSize: "28px", color: "#001529" }} ><Icon color={"#001529"} icon="address-card" style={{ marginLeft: "10px", marginRight: "5px" }} />Quiz Part 1</span> */}
                                        {knowledgebase_quizQuestions.map((item, i) => {
                                            let qNum = (i+1);
                                            return (<Card key={i}>
                                                <Divider orientation="left"><b>{qNum}: </b> {item.question}</Divider>
                                                <FormField data={item.options.map(itm => ({ label: itm }))} name={`quiz.knowledgebase_quiz[${i}]`} type="radio" />
                                            </Card>)
                                        })}
                                    </>}

                                    {activeStep == 3 && <>
                                        {/* <span style={{ fontSize: "28px", color: "#001529" }} ><Icon color={"#001529"} icon="address-card" style={{ marginLeft: "10px", marginRight: "5px" }} />Quiz Part 1</span> */}
                                        <h3 style={{ marginBottom:"0" }}>Guidelines for Writing the Report</h3>
                                        <p><b><i>Please include the following elements in your reports</i></b></p>
                                        <ul>
                                            <li>Clear and concise language:** The report should be easy to read and understand</li>
                                            <li>Chronological order:** Events should be documented in the order they occurred</li>
                                            <li>Specific details:** Include times, dates, locations, and descriptions of people and the environment</li>
                                            <li>Actions taken:** Describe what steps were taken in response to the incident</li>
                                            <li>Follow-up actions:** Note any recommendations for preventing future occurrences or any further investigation that might be needed</li>
                                        </ul>

                                        {competencies_quizQuestions.map((item, i) => {
                                            let qNum = (i+1);
                                            return (<Card key={i}>
                                                <Divider orientation="left"><b>{qNum}: </b> {item.heading}</Divider>
                                                <p>{item.question}</p>
                                                <FormField label="Answer" name={`quiz.competencies_quiz[${i}]`} type="textarea" />
                                            </Card>)
                                        })}
                                    </>}

                                    {activeStep == 4 && <>
                                        {skills_quizQuestions.map((item, i) => {
                                            let qNum = (i+1);
                                            return (<Card key={i}>
                                                <Divider orientation="left"><b>{qNum}: </b> {item.heading}</Divider>
                                                {item.questions.map((itm, ii) => {
                                                    return (<div key={`${i}_${ii}`}>
                                                        <p>{itm}</p>
                                                        <FormField label="Answer" name={`quiz.skills_quizQuestions[${i}]`} type="textarea" />
                                                    </div>)
                                                })}
                                            </Card>)
                                        })}
                                    </>}


                                    {activeStep == 5 && <>
                                        {/* <DataPreview {...values} fileList={this.state.fileList} /> */}
                                        <span style={{ fontSize: "28px", color: "#001529" }} > <Icon color={"#001529"} icon="handshake" style={{ marginLeft: "10px", marginRight: "5px" }} />Acknowledgement</span>
                                        <div className='card'>
                                            <div className='dark'>
                                                <FormField name="certify" type="checkbox" validate={rules.required}>
                                                    I certify that my answers are true and complete to the best of my knowledge.
                                                    If this application leads to employment,
                                                    I understand that false or misleading information in my application or interview may result in my release
                                                </FormField>
                                            </div>

                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "20px auto" }}>
                                                <div style={{ display: "flex", flexDirection: "column", position: "relative" }} >
                                                    <span className="cyan bold uppercase">Please sign the agreement:</span>
                                                    <span style={{ border: "1px solid #CCC", borderRadius: "5px" }}><SignaturePad
                                                        dotSize={1}
                                                        throttle={50}
                                                        velocityFilterWeight={0.1}
                                                        onEnd={this.trimSignature}
                                                        backgroundColor="#FFF"
                                                        canvasProps={{ width: 500, height: 200 }}
                                                        required ref={(ref) => { this.signaturePad = ref }} /></span>
                                                    <IconButton onClick={this.clearSignature} icon="sync-alt" color="green" style={{ position: "absolute", top: '28px', right: "5px" }} size="2x" />
                                                </div>
                                            </div>

                                            <FormField name="note" size="medium" type="textarea" label="Extra Notes (optional)" placeholder={"Provide any extra information"} />

                                        </div>
                                        <div style={{ height: '20px' }} />

                                    </>}

                                    {/* {(!valid && dirty) && <Alert message="Please check your form fields, seems like you forgot some information!" type="warning" showIcon />} */}

                                    {error && <div style={{ padding: "20px" }}><Alert title={<b>Error!</b>} description={error} type='error' showIcon /></div>}

                                    <Row style={{ margin: "20px" }}>
                                        <Col flex="auto"><Button type="primary" onClick={() => this.onStepClick(activeStep - 1, values)} disabled={activeStep == 0} size="large">Previous</Button></Col>
                                        <Col><Button type="primary" htmlType='submit' size="large" style={{ marginBottom: "50px" }} loading={submitting}>{activeStep < 5 ? "Next" : "Submit Application"}</Button></Col>
                                    </Row>

                                </>)
                            }}
                        />

                    </div>
                </div>
            </div>

            
        </>)

    }
}

export const GuardEvaluationForm = withApollo(GuardEvaluationFormComp)
export default GuardEvaluationForm;
