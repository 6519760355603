import { Tooltip } from 'antd'
import React, { Component } from 'react'

export const CustomIcon = ({ children, title }) => {
    let theIcon = <span style={{
        cursor: "pointer",
        margin: '3px', backgroundColor: '#001529', justifyContent: 'center', alignItems: 'center',
        display: 'inline-flex',
        // width: '30px', height: '20px', 
        padding: "0px 3px",
        color: 'white', borderRadius: '5px',
        fontSize: "12px",
        fontWeight: 'bold'
    }}>{children}</span>

    if (!title) return theIcon;
   
    return <Tooltip title={title || undefined}>{theIcon}</Tooltip>
}