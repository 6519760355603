import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { getmodulePermissions } from 'Common/scripts/Security';
import RolesManagement from './containers/RolesManagement';
import TypeManagement from './containers/TypeManagement';
import { ROOT, MODULE } from './constants'

export const modulePermissions = getmodulePermissions('account-role-management');



export const SubMenu = props => ([
  { path: `${ROOT}manage_rights`, exact: true, title: 'Manage Rights', component: RolesManagement },
  { path: `${ROOT}manage_types`, exact: true, title: 'Manage Types', component: TypeManagement },
])

if (modulePermissions['account-role.update']) {
  // let menuItems = SubMenu();
  addModule(
    {
      path: `/user-right-management`, component: Home, icon: 'user-shield', title: "User Rights Management", exact: 0, leftNav: true,
      subMenu: SubMenu()
    },
  )
}






// if (modulePermissions['account-role.update']){
//   addModule(
//     // { path: `${ROOT}`, component: Home, icon: 'user-shield', title:"User Rights Management", exact:0, hideNav:0 },
//     { path: `${ROOT}`, component: Home, icon: 'user-shield', title: "User Rights Management", exact: 0, leftNav: true },
//   )
// }
