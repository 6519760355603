import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux"; 
import { __error } from 'Common/scripts/consoleHelper'
import { isSuperAdmin } from 'Common/scripts/Security';

import { ValuePairsSearch } from './ValuePairsSearch';

const _NoticeboardCategoriesSearch = props => {

    const filter = { ...props.filter }

    if(!isSuperAdmin()){
        const allowedCategories = props.permissions.filter(o => o.indexOf('noticeboard.category') > -1).map(o => o.replace("noticeboard.category.", ""));
        if (!allowedCategories || allowedCategories.length<1) return <p>No permissions for noticeboard</p>

        Object.assign(filter, { values: { '$in': allowedCategories } })
    }

    // return <ValuePairsSearch {...props} filter={{ values: { '$in': allowedCategories } }} />
    return <ValuePairsSearch {...props} filter={filter} />

}

const mapStateToProps = ({ ep_admin }) => {
    // console.log("ep_admin.user: ", ep_admin.user.acc_type =='super-admin')
    return { 
        permissions: ep_admin.user.permissions ? ep_admin.user.permissions.split(",") : [] 
    }
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })


export const NoticeboardCategoriesSearch = connect(mapStateToProps)(_NoticeboardCategoriesSearch);
export default NoticeboardCategoriesSearch;

