import React, { Component } from 'react'
import { useSubscription, gql } from "@apollo/client";

export function Subscriber({ QUERY, callback, filter }) {
    let variables = {};
    if (filter) Object.assign(variables, { ...filter });

    const { data, loading, error } = useSubscription(
        QUERY,
        {
            // subscription: QUERY,
            variables: { filter: JSON.stringify(variables) },
            // fetchPolicy: "network-only" // cache-first, cache-only, cache-and-network, network-only, no-cache, standby
            onSubscriptionData: ({ client, subscriptionData }) => {
                const { data, error, loading, variables } = subscriptionData
                console.log({ subscriptionData })
                if (callback) callback(subscriptionData)
            },
            shouldResubscribe: false, // Determines if your subscription should be unsubscribed and subscribed again
        }
    );

    if (loading) {
        console.log("Receiving subscription on ....", variables);
        return null;
    }

    if (data) {
        console.log('Subscription received: ', data);
        // const { mutation, node } = data.formsDatasUpdated;
        // if (callback) callback({ mutation, node })
    }

    return null;

}

export default Subscriber;