import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Alert, Breadcrumb } from 'antd';
import { ContentArea } from "Layout_v1";
import { Icon } from 'Common/components';
import { connect } from "react-redux";
import { HomeOutlined } from '@ant-design/icons';
import { uriRoot } from 'configs';
import SMSForm from './components/sms_form'
import SMSList from './components/sms_list'
import { Link } from 'react-router-dom';


class SMSConversation extends Component {
    render() {
        console.log("this.props: ", this.props.match.params.phone)


        return (<>
            <Breadcrumb style={{ padding: "10px" }}>
                <Breadcrumb.Item><Link to={uriRoot}><HomeOutlined /></Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to={`${uriRoot}/sms`}>SMS</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Icon icon="sms" size="1x" /><b> Conversation ({this.props?.match?.params?.phone})</b></Breadcrumb.Item>
            </Breadcrumb>

            {!this.props?.match?.params?.phone && <Alert message="No target phone found" showIcon type='error' />}

            {this.props?.match?.params?.phone && <>
                <ContentArea transparent style={{padding:"0"}}>
                    <SMSForm />
                    <SMSList title={`SMS Conversation`} 
                        sender_number={this.props?.match?.params?.phone}
                        filter={{
                            $or:[
                                { from: this.props?.match?.params?.phone },
                                { to: this.props?.match?.params?.phone }
                            ]
                        }}
                    />
                </ContentArea>
            </>}
        </>)
    }
}


const mapStateToProps = ({ ep_admin }) => {
    return { filter: ep_admin?.pageSettings?.guard_searchBar }
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export default connect(mapStateToProps)(SMSConversation)

