import React, { useState, useEffect } from 'react'
import { Alert, Col, message, Row, Modal } from 'antd'
import { Button, Icon, IconButton } from 'Common/components'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { loader } from 'graphql.macro';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { __error } from 'Common/scripts/consoleHelper'
import arrayMutators from 'final-form-arrays'
import { modulePermissions } from '..'

const UPDATE_GUARD = loader('src/Graphql/guard/editGuard.graphql');

function getAvailabilityObj(arr) {
    return {
        morning: arr && arr.indexOf('morning') > -1 ? true : false,
        afternoon: arr && arr.indexOf('afternoon') > -1 ? true : false,
        night: arr && arr.indexOf('night') > -1 ? true : false,
    }
}

function setAvailabilityArray(_day) {
    let data = []
    if (!_day) return data;

    if (_day.morning) data.push('morning');
    if (_day.afternoon) data.push('afternoon');
    if (_day.night) data.push('night');

    return data;
}

const AvailabilityForm = ({ client, data, onUpdate, onClose }) => {
    const [guard, setGuard] = useState(false);

    useEffect(() => {
        if (!data || !data._id) return;

        if (data && data._id) {
            setGuard({
                // ...data,
                guard_availability: {
                    mon: { ...getAvailabilityObj(data?.guard_availability?.mon) },
                    tue: { ...getAvailabilityObj(data?.guard_availability?.tue) },
                    wed: { ...getAvailabilityObj(data?.guard_availability?.wed) },
                    thu: { ...getAvailabilityObj(data?.guard_availability?.thu) },
                    fri: { ...getAvailabilityObj(data?.guard_availability?.fri) },
                    sat: { ...getAvailabilityObj(data?.guard_availability?.sat) },
                    sun: { ...getAvailabilityObj(data?.guard_availability?.sun) },
                }
            })
        }

        return () => { setGuard({}) };

    }, [data])

    const onSubmitGuard = async (values) => {
        if (!modulePermissions['guard.update']) return;

        const { guard_availability } = values;

        const input = {
            _id: data._id,
            guard_availability: {
                mon: setAvailabilityArray(guard_availability?.mon),
                tue: setAvailabilityArray(guard_availability?.tue),
                wed: setAvailabilityArray(guard_availability?.wed),
                thu: setAvailabilityArray(guard_availability?.thu),
                fri: setAvailabilityArray(guard_availability?.fri),
                sat: setAvailabilityArray(guard_availability?.sat),
                sun: setAvailabilityArray(guard_availability?.sun)
            },
        }

        const results = await client.mutate({ mutation: UPDATE_GUARD, variables: { input } }).then(r => (r.data.editGuard))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })

        if (results.error) {
            message.error(results.error.message);
            return false;
        }

        onUpdate(results);
        onClose();

        return results;
    }

    if (!guard) return null;

    return (<>
        <FormComponent onSubmit={onSubmitGuard} id='GuardAvailabilityForm' fields={{ ...guard }} debug={false}
            mutators={{ ...arrayMutators }}
            form_render={formProps => {
                const { handleSubmit, submitting, values, form, invalid, dirty, valid } = formProps;

                return (<>
                    <div style={{ width: "100%", alignItems: "center" }} >
                        <Row className='dark' align="middle">
                            {['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
                                return <Col flex="12.5%" className="bold" key={index}>{day}</Col>
                            })}
                        </Row>
                        <Row className='simple' align="middle">
                            {['Morning', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                                if (i == 0) return <Col flex="12.5%" className="bold" key={i}>{day}</Col>;
                                return <Col flex="12.5%" key={i}><FormField name={`guard_availability.${day}.morning`} type='switch' /></Col>
                            })}
                        </Row>
                        <Row className='dark' align="middle">
                            {['Afternoon', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                                if (i == 0) return <Col flex="12.5%" className="bold" key={i}>{day}</Col>;
                                return <Col flex="12.5%" key={i}><FormField name={`guard_availability.${day}.afternoon`} type='switch' /></Col>
                            })}
                        </Row>
                        <Row className='simple' align="middle">
                            {['Night', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                                if (i == 0) return <Col flex="12.5% " className="bold" key={i}>{day}</Col>;
                                return <Col flex="12.5%" key={i}><FormField name={`guard_availability.${day}.night`} type='switch' /></Col>
                            })}
                        </Row>
                    </div>

                    <div style={{ height: "20px" }} />
                    <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} loading={submitting}>Update</Button>

                </>)
            }}
        />

    </>)

}


const Availability = ({ data, onUpdate, client }) => {
    const [showForm, setShowForm] = useState(false)

    return (<>
        <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="clock" /> Availability <IconButton onClick={() => setShowForm(true)} icon={"pen"} /></div>
        {data.guard_availability && <>
            <Row align="middle" style={{ color: "#060079" }} className='dark'>
                {['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
                    return <Col flex="12.5%" align="center" style={{ borderBottom: "1px solid #EEE", paddingTop: "10px", borderRight: "1px solid #EEE", fontWeight: "bold" }} key={index}>{day}</Col>
                })}
            </Row>

            {["Morning", "Afternoon", "Night"].map((daypart, index) => (
                <Row align="middle" key={index}>
                    <Col flex="12.5%" style={{ height: "40px", paddingTop: "10px" }}>{daypart}</Col>
                    {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                        return <Col key={i} flex="12.5%" align="center" style={{ height: "40px", padding: "10px", borderBottom: "1px solid #EEE", borderRight: "1px solid #EEE" }}>
                            <>
                                {data.guard_availability[day].indexOf(daypart.toLowerCase()) > -1 ?
                                    <Icon icon="check" size={"1x"} color="green" /> :
                                    <Icon icon="times" size={"1x"} color="#EEE" />
                                }
                            </>
                        </Col>
                    })}
                </Row>
            ))}
        </>}

        <Modal title={"Update guard availability"} width="1000px" visible={showForm} footer={false} onCancel={() => setShowForm(false)}>
            {showForm && <AvailabilityForm data={data} onUpdate={onUpdate} onClose={() => setShowForm(false)} client={client} />}
        </Modal>

    </>)
}
export default withApollo(Availability);