import React, { Component, useState } from 'react'
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { Loader, ClientSearch, Button, Icon, IconButton } from 'Common/components';
import { connect } from "react-redux";
import { loader } from 'graphql.macro';
import { Drawer, Space, Alert, message, Row, Col, Popover, Input, Modal } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import axios from 'axios';
import ContactsMiniList from 'Modules/Addressbooks/contacts_mini_list';
import { withApollo } from '@apollo/client/react/hoc';

// const AUTO_ADD = loader('src/Graphql/addressbooks/autoAddAddressbookItem.graphql');
const ADD_ITEM = loader('src/Graphql/addressbooks/addAddressbookItem.graphql');


class SMSFormComp extends Component {
    state = { error: null, busy:false, kw:null };

    constructor(props){
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.addToAddressbook = this.addToAddressbook.bind(this);
    }

    popContents({ onItemClick }){
        return (<>
            <ContactsMiniList onItemClick={onItemClick} />
        </>)
    }

    addToAddressbook(values){
        const input = { 
            phones: [values.phone], 
            name: values.name 
        };

        return this.props.client.mutate({ mutation: ADD_ITEM, variables: { input } }).then(r => {
            // const response = r.data.autoAddAddressbookItem;
            // if (response.error) message.error(response.error.message)
            // if (response.warning) message.warning(response.warning.message)
            // if (response.success) message.warning(response.success.message)
        }).catch(err => {
            console.log(__error("ERROR: "), err);
            return { error:{message:"Query Error!"}}
        })

        // this.props.client.mutate({ mutation: AUTO_ADD, variables: { input } }).then(r => {
        //     const response = r.data.autoAddAddressbookItem;
        //     if (response.error) message.error(response.error.message)
        //     if (response.warning) message.warning(response.warning.message)
        //     // if (response.success) message.warning(response.success.message)

        // }).catch(err => {
        //     console.log(__error("ERROR: "), err);
        // })

    }
    
    async onSubmit(values){
        const formData = new FormData();
        formData.append('action', 'send_sms');
        formData.append("phone", values.phone);
        formData.append("message", values.message);
        formData.append("user_id", this.props.ep_admin.user._id);

        this.setState({ busy: true, error:null })

        const results = await axios.post(`${process.env.REACT_APP_API_URL}/sms/send`, formData, {
            // withCredentials,
            // headers,
            // onUploadProgress: ({ total, loaded }) => {
            //     let percent = Math.round(loaded / total * 100).toFixed(2);
            //     // let percent = Math.round((loaded/2) / total * 100).toFixed(2);
            //     // if (percent == 100) return;
            //     onProgress({ percent: percent }, file);
            //     // onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) }, file);
            // },
        })
            .then((response) => (response.data))
            .catch(err=>{
                console.log(__error("ERROR: "), err)
                return { error: { message:"Unable to send SMS"}}
            });

        if (results.error){
            message.error(results.error.message);
            this.setState({ busy: false, error: results.error.message });
        }else{
            message.success("SMS Sent");
            this.addToAddressbook({ phone: values.phone, name: values.name });
            this.setState({ busy: false })
        }

        if (this.props.onComplete) this.props.onComplete(results)

        // if (!results?.error?.message) this.addToAddressbook(values);
        // if (!results?.error?.message) await this.addToAddressbook({ phone: values.phone, name: values.name })

        // this.setState({ busy: false, error: results?.error?.message || null });

        // if (this.props.onComplete) this.props.onComplete(results)

        // return {
        //     abort() {
        //         console.log('sms progress is aborted.');
        //         message.info("sms progress is aborted")
        //     },
        // };


    }

    render() {
        const { error, busy } = this.state;

        return (<>
            { error && <Alert message="Error" description={error} type="error" showIcon /> }

            <FormComponent onSubmit={this.onSubmit} id='SMsFormComp' style={{marginBottom:"10px"}} debug={false} 
                mutators={{
                    onItemClick: (newValueArray, state, tools) => {
                        let node = newValueArray[0];
                        
                        tools.changeValue(state, 'name', () => node.name);
                        tools.changeValue(state, 'phone', () => node.phone);
                    },
                }}

                fields={{ 
                    name: this.props.name || "",
                    phone: this.props.phone || "", // '+16478481480',
                    // message:"Test message from faheem"
                }}
                form_render={formProps => {
                    const { values, form, invalid, dirty, valid, submitting } = formProps;

                    return (<>
                        <Row align="middle" gutter={[12]}>
                            <Col flex="175px">
                                <Popover trigger="click" content={this.popContents({ onItemClick: form.mutators.onItemClick })} 
                                    overlayClassName='addressbook-pop-overlay'
                                    title={false} 
                                    placement="bottom">
                                    <div><FormField compact
                                        disabled={busy}
                                        // phone
                                        // placeholder="+1 XXX XXX XXXX"
                                        mask="+\1 999-999-9999"
                                        label="Mobile/Phone Number"
                                        name="phone"
                                        type="mask"
                                        validate={composeValidators(
                                            rules.required,
                                            rules.minChar(12, (val) => val.replace(/_/, "")),
                                            rules.maxChar(12, (val) => val.replace(/_/, "")),
                                        )}
                                    /></div>
                                </Popover>
                            </Col>
                            <Col><FormField disabled={busy} compact label="Receiver Name" name="name" type="text" validate={composeValidators(rules.required, rules.minChar(4))} /></Col>
                            <Col flex="auto"><FormField rows={1} disabled={busy} compact label="Message" name="message" type="textarea" validate={composeValidators(rules.required, rules.minChar(3))} /></Col>
                            <Col><Button type="primary" block style={{marginTop:"22px"}} htmlType='submit' disabled={!dirty || !valid || submitting} size="medium"><Icon icon="paper-plane" /></Button></Col>
                        </Row>
                        
                    </>)
                }}
            />

        </>)
    }
}


const mapStateToProps = ({ ep_admin }) => {
    return ({ ep_admin });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
const WithRedux = connect(mapStateToProps)(SMSFormComp);

const SMSForm = withApollo(WithRedux); 
export default SMSForm;


export const SMSFormModal = ({ name, phone }) => {
    const [showSMSForm, set_showSMSForm] = useState(false);

    return (<>
        {/* <span><IconButton onClick={() => set_showSMSForm(true)} icon="sms" /> {phone}</span> */}
        <span onClick={() => set_showSMSForm(true)} className='a'>{phone}</span>

        <Modal title={`Send SMS to ${name}`} visible={showSMSForm} footer={false} onCancel={() => set_showSMSForm(false)}>
            {showSMSForm && <>
                <SMSForm name={name} phone={phone} onComplete={() => set_showSMSForm(false)} />
            </>}
        </Modal>
    </>)

}