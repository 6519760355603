import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { ContentArea } from 'Layout_v1'
import { ListHeader } from 'Common/components/Typography'
import { Alert, Breadcrumb, Card, Col, Modal, Row, Space, message } from 'antd'
import { PlusOutlined, HomeOutlined } from '@ant-design/icons';
import { uriRoot, defaultDateFormat, pageInfoStatusArray, pageInfoTypesArray } from 'configs';
import { Button, DeleteButton, DevBlock, Drawer, Icon, IconButton, Table, Tabs } from 'Common/components';
import { loader } from 'graphql.macro';
import { __error, __yellow } from 'Common/scripts/consoleHelper'
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { parseJson, sleep, timestamp } from 'Common/scripts/Functions';


const KEY_VALUE = "site_pages";

const GET_SITES = gql`query sites($filter: String){
    sites(filter: $filter){
        _id name
    }
}`
// const ADD_PAGE = loader('src/Graphql/InfoPages/addInfoPage.graphql');
// const EDIT_PAGE = loader('src/Graphql/InfoPages/editInfoPage.graphql');
// const DELETE_PAGE = loader('src/Graphql/InfoPages/deleteInfoPage.graphql');

const ADD_BULK_PAGES = loader('src/Graphql/InfoPages/addBulkInfoPage.graphql');
// const EDIT_BULK_PAGES = loader('src/Graphql/InfoPages/editBulkInfoPage.graphql');
const DELETE_BULK_PAGES = loader('src/Graphql/InfoPages/deleteBulkInfoPage.graphql');
const UPDATE_PAGE_SORTING = loader('src/Graphql/InfoPages/updateInfoPageSorting.graphql');

const ADD_VALUE = loader('src/Graphql/value_pairs/addValuePairs.graphql');
const DELETE_VALUE = loader('src/Graphql/value_pairs/deleteValuePairs.graphql');
const EDIT_VALUE = loader('src/Graphql/value_pairs/editValuePairs.graphql');
const ALL_PAIRS = loader('src/Graphql/value_pairs/valuePairs.graphql');
const UPDATE_SORTING = loader('src/Graphql/value_pairs/updateValuePairsSorting.graphql');



const defaultSitePages = ({ parent_id, author }) => {
  const input = [
    {
      order: 1,
      version: 1,
      status: "active",
      title: "Info",
      parent_id,
      category: "site",
      author,
      imgAttachments: {
        allowed: true,
        types: ".pdf,.jpeg,.jpg,.png,.gif"
      },
      fields: [
        { label: "Basic Site Information", value: "input data", type: "text" }
      ],
    },
    {
      order: 3,
      version: 5,
      status: "active",
      title: "JDs",
      parent_id,
      category: "site",
      author,
      fields: [
        { label: "Basic Guard Duries", value: "input data", type: "text" }
      ],
    },
    {
      order: 4,
      version: 1,
      status: "active",
      title: "Contacts",
      parent_id,
      category: "site",
      author,
      fields: [
        { label: "Site Manager", value: "input data", type: "text" },
        { label: "Emergency", value: "input data", type: "text" },
        { label: "After Hours", value: "input data", type: "text" }
      ],
    },
    {
      order: 0,
      version: 1,
      status: "active",
      title: "Reports",
      parent_id,
      category: "site",
      author,
      fields: [
        { label: "Reports", value: "SiteReports", type: "component" }
      ],
    },
    {
      order: 2,
      version: 1,
      status: "active",
      title: "Facilities",
      parent_id,
      category: "site",
      author,
      fields: [
        { label: "Toilet", value: "input data", type: "text" },
        { label: "Lunch Room", value: "input data", type: "text" },
        { label: "Guard Parking", value: "input data", type: "text" },
        { label: "Guard Sitting Area", value: "input data", type: "text" }
      ],
    },
    {
      order: 5,
      version: 1,
      status: "active",
      title: "Knowledge",
      parent_id,
      category: "site",
      author,
      "fields": [
        { label: "Knowledge Base", value: "SiteKnowledgeBase", type: "component" }
      ],
    },
    {
      order: 6,
      version: 1,
      status: "active",
      title: "Instructions",
      parent_id,
      category: "site",
      author,
      fields: [
        { label: "Basic Dispatch Instructions", value: "input data", type: "text" }
      ],
    },
  ]

  return input;
}

const InfoPageForm = ({ onSuccess, onCancel, initialValues }) => {
  const [error, setError] = useState(false)

  const [addValuePairs, add_details] = useMutation(ADD_VALUE);
  const [editValuePairs, edit_details] = useMutation(EDIT_VALUE);
  
  // const [addBulkInfoPage, add_pages_details] = useMutation(ADD_BULK_PAGES);
  // const [editBulkInfoPage, edit_pages_details] = useMutation(EDIT_BULK_PAGES);

  const [sites, sites_details] = useLazyQuery( GET_SITES, /*{ variables: { filter: JSON.stringify({ ...filter }), others: JSON.stringify({ sort: { order: 1 } }) } }*/);

  // const _createSitePages = async(_resutls) => {
  //   if (!_resutls || _resutls.error) return;

  //   let sitesArray = await sites().then(r => (r.data.sites)).catch(err => {
  //     console.log(__error("Error: "), err)
  //     return { error: { message: "Unable to fetch sites" } }
  //   })

  //   let resutls = {
  //     ..._resutls,
  //     values: parseJson(_resutls.values),
  //   }

  //   // console.log("sitesArray: ", sitesArray)
  //   let added = addBulkInfoPage({
  //     variables: {
  //       input: sitesArray.map(site => ({
  //         title: resutls.values.title,
  //         page_key: resutls.key,
  //         parent_id: site._id,
  //         category: resutls.values.category,
  //         // fields: resutls.values.fields,
  //         // version: 1,
  //         // status: String
  //         imgAttachments: resutls.values.imgAttachments
  //       }))
  //     }
  //   }).then(r => (r.data.addBulkInfoPage))
  //   .catch(err=>{
  //     console.log(__error("Error: "), err)
  //     return { error:{message:"Unable to Add Site Pages"}}
  //   })

  //   if (added && added.error) setError(added.error.message)
  //   return added;    

  // }

  // const _updateSitePages = async(_resutls) => {
  //   if (!_resutls || _resutls.error) return;

  //   let resutls = {
  //     ..._resutls,
  //     values: parseJson(_resutls.values),
  //   }

  //   let variables = {
  //     filter: JSON.stringify({ page_key: resutls.key }),
  //     input: {
  //       title: resutls.values.title,
  //       // fields: resutls.values.fields,
  //       // status: resutls.values.status,
  //       imgAttachments: resutls.values.imgAttachments,        
  //     }
  //   }

  //   let updates = editBulkInfoPage({ variables }).then(r => (r.data.editBulkInfoPage))
  //   .catch(err=>{
  //     console.log(__error("Error: "), err)
  //     return { error: { message: "Unable to Update Site Pages" } }
  //   })

  //   if (updates && updates.error) setError(updates.error.message)
  //   return updates;
  // }


  const onSubmit = async (values) => {
    setError(null)

    var values_input = {
      ...values,
      category: KEY_VALUE,
      imgAttachments: {
        allowed: values?.imgAttachments?.allowed === true,
        types: values?.imgAttachments?.types
      },
    };

    var resutls;
    var input = {
      title: 'Site Info Pages',
      key: initialValues.key, // `page_${timestamp()}`,
      value_key: KEY_VALUE,
      value_type: 'array',
      values: JSON.stringify(values_input),
      status: 'published',
      order: initialValues.order || 0,
    }


    if (initialValues && initialValues._id) {
      Object.assign(input, { _id: initialValues._id });

      resutls = await editValuePairs({ variables: { input } }).then(r => (r?.data?.editValuePairs))
        .catch(err=>{
          console.log(__error("Error: "), err)
          return { error: { message: "Query Error!" } }
        })

      // await _updateSitePages(resutls)
    }
    else {
      Object.assign(input, { key: `page_${timestamp()}`, });

      resutls = await addValuePairs({ variables: { input } }).then(r => (r?.data?.addValuePairs))
        .catch(err => {
          console.log(__error("Error: "), err)
          return { error: { message: "Query Error" } }
        })

      // await _createSitePages(resutls)
    }

    if (!resutls || resutls.error) {
      setError((resutls && resutls?.error?.message) || "Invalid Response!");
      return true;
    }


    message.success("Saved");
    onSuccess(resutls)
    return true;
  }


  return (<>
    <FormComponent onSubmit={onSubmit} id='PageInfoForm' fields={initialValues && initialValues.values}
      mutators={{
        // updateDorDate: (newValueArray, state, tools) => {
        //     let record_date = newValueArray[0];
        //     tools.changeValue(state, 'record_date', () => record_date);
        // },
      }}

      form_render={formProps => {
        const { values, form, submitting, invalid, dirty, valid } = formProps;

        return (<>
          {error && <Alert message={error} type='error' showIcon />}

          <Row gutter={[5, 10]}>
            <Col span={18}><FormField label="Title" name="title" type="text" compact /></Col>
            <Col span={6}><FormField label="Status" name="status" type="select" data={pageInfoStatusArray} compact /></Col>
            <Col span={24}><Row>
              <Col span={12}><FormField defaultChecked={false} name="imgAttachments.allowed" type="checkbox">Allow image attachments</FormField></Col>
              <Col span={12}>{values?.imgAttachments?.allowed && <FormField placeholder="Types: .jpeg,.jpg,.png,.gif" name="imgAttachments.types" type="text" compact />}</Col>
            </Row></Col>

            <Col span={12} align="center">
              <Button size="medium" loading={submitting} onClick={onCancel}>Cancel</Button>
            </Col>
            <Col span={12} align="center">
              <Button type="primary" style={{ width: "200px" }} color="orange" size="medium" htmlType='submit' loading={submitting} disabled={!valid}>Save</Button>
            </Col>
          </Row>

          {/* <DevBlock obj={values} /> */}
        </>)
      }}
    />

  </>)
}

const FieldManagerform = ({ initialValues, onUpdate, onCancel }) => {
  const [error, setError] = useState(false)

  const [editValuePairs, edit_details] = useMutation(EDIT_VALUE);
  
  // const [editBulkInfoPage, edit_pages_details] = useMutation(EDIT_BULK_PAGES);
  // const _updateSitePages = async (_resutls) => {
  //   console.log(__yellow("_updateSitePages()"))
  //   if (!_resutls || _resutls.error) return;

  //   let resutls = {
  //     ..._resutls,
  //     values: parseJson(_resutls.values),
  //   }

  //   let variables = {
  //     filter: JSON.stringify({ page_key: resutls.key }),
  //     input: {
  //       // title: resutls.values.title,
  //       fields: resutls.values.fields,
  //       // status: resutls.values.status,
  //       // imgAttachments: resutls.values.imgAttachments,
  //     }
  //   }

  //   let updates = editBulkInfoPage({ variables }).then(r => (r?.data?.editBulkInfoPage))
  //     .catch(err => {
  //       console.log(__error("Error: "), err)
  //       return { error: { message: "Unable to Update Site Pages" } }
  //     })

  //   if (updates && updates.error) setError(updates.error.message)
  //   return updates;
  // }


  const onSubmit = async (values) => {
    setError(null)

    var input = {
      _id: initialValues._id,
      title: initialValues.title,
      key: initialValues.key,
      value_type: initialValues.value_type,
      values: JSON.stringify(values),
    }

    const resutls = await editValuePairs({ variables: { input } }).then(r => (r?.data?.editValuePairs))
      .catch(err => {
        console.log(__error("Error: "), err)
        return { error: { message: "Query Error!" } }
      })


    if (!resutls || resutls.error) {
      setError((resutls && resutls?.error?.message) || "Invalid Response!");
      return true;
    }

    // await _updateSitePages(resutls)

    message.success("Saved");
    onUpdate(resutls)
    return true;
  }

  
  return (<>
    <FormComponent onSubmit={onSubmit} id='FieldManagerform' fields={initialValues.values}
      mutators={{
        ...arrayMutators,
        // updateDorDate: (newValueArray, state, tools) => {
        //     let record_date = newValueArray[0];
        //     tools.changeValue(state, 'record_date', () => record_date);
        // },
      }}

      form_render={formProps => {
        const { values, form, submitting, invalid, dirty, valid } = formProps;

        return (<>
          {error && <Alert message="Error!" description={error} type="error" showIcon />}

          <FieldArray name="fields">
            {({ fields }) => (<>
              {fields.map((name, index) => {
                {/* let thisField = values.fields[index] */}

                return (<Row key={index} style={{ borderBottom: "1px solid #EEE", paddingBottom: "5px" }} align="middle" gutter={[0]}>
                  <Col flex="auto">
                    <Row gutter={[5]}>
                      <Col flex="auto"><FormField label="label" placeholder="label" name={`${name}.label`} type="text" validate={rules.required} compact /></Col>
                      <Col flex="150px"><FormField label="type" placeholder="type" name={`${name}.type`} data={pageInfoTypesArray} type="select" validate={rules.required} compact /></Col>
                      <Col span={24}><FormField label="Value" placeholder="Value" name={`${name}.value`} type="text" validate={rules.required} compact /></Col>
                    </Row>
                  </Col>
                  <Col><Space>
                    <DeleteButton placement="topRight" message='Any data associated to this field will be deleted forever, across all sites!' onClick={() => fields.remove(index)} />
                    {/* <IconButton icon="trash-alt" onClick={() => fields.remove(index)} /> */}
                  </Space>
                  </Col>
                  {/* <Col spa={24}></Col>
                  <Col span={24}><FormField label='Value' placeholder="value" name={`${name}.value`} type="textarea" compact /></Col> */}
                </Row>)

              })}

              <div align="center"><IconButton icon="plus" onClick={() => fields.push({ label: "", type: "text", value: "", name:`fk_${timestamp()}` })} /></div>

            </>)}
          </FieldArray>

          <hr />

          <Row gutter={[10]}>
            <Col span={12} align="center"><Button onClick={onCancel} style={{ width: "100px" }} color="lightgrey" size="medium">Cancel</Button></Col>
            <Col span={12} align="center"><Button type="primary" style={{ width: "100px" }} color="orange" size="medium" htmlType='submit' loading={submitting} disabled={!valid}>Save</Button></Col>
          </Row>

        </>)
      }}
    />

  </>)
}


function InfoPages() {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(null);
  const [showForm, set_showForm] = useState(false);
  const [pageData, set_pageData] = useState(null);
  const [openFieldEditor, set_openFieldEditor] = useState(false);
  
  const [deleteValuePairs, delete_details] = useMutation(DELETE_VALUE); 
  const [updateValuePairsSorting, sorting_Details] = useMutation(UPDATE_SORTING); 

  const [valuePairs, { called, loading, data }] = useLazyQuery(
    ALL_PAIRS, // { variables: { filter: JSON.stringify({ ...filter }), others: JSON.stringify({ sort: { order: 1 } }) } }
  );

  useEffect(() => {
    if (called || loading) return;
    fetchData();
  }, [])

  const fetchData = async () => {
    let resutls = await valuePairs({
      variables: {
        filter: JSON.stringify({ value_key: KEY_VALUE }),
        others: JSON.stringify({ sort: { order: 1 } })
      }
    }).then(r => (r.data.valuePairs))
      .catch(err => {
        console.log(__error("Error: "), err)
        return { error: { message: "Query Error!" } }
      })

    if (resutls && resutls?.error?.message) return setError(resutls.error.message);
      

    if (resutls && resutls.length > 0) {
      resutls = resutls.map(o => ({
        ...o,
        values: parseJson(o.values)
      }))
    }
    
    set_pageData(resutls);
  }

  const onDeltePage = async (_id) => {
    setError(null);

    setBusy(true)
    let resutls = await deleteValuePairs({ variables: { id: _id } })
      .then(r => (r?.data?.deleteValuePairs))
      .catch(err => {
        console.log(__error("Error: "), err)
        return { error: { message: "Query Error!" } }
      })
    setBusy(false)

    if (resutls && resutls.error) {
      setError(resutls.error.message)
      return false;
    }

    fetchData();
  }

  const saveSorting = async (new_data) => {
    let input = new_data.map((o, i) => ({ _id: o._id, order: i }))
    await updateValuePairsSorting({ variables: { input } })
    fetchData();
    return;
  }

  const columns = [
    // { title: 'ID', dataIndex: '_id', key:'_id' },
    // { title: 'Order', dataIndex: ['order'], key: 'order', width: 50, align: "center" },
    {
      title: 'Ttile', dataIndex: 'title', key: 'title', className:'show-menu-on-hover', render: (__, rec) => {
        return (<>
          <span>{rec.values.title}</span>
          <span className='hidden-menu'>
            <Button onClick={() => set_openFieldEditor(rec)} size='small'>Edit Fields</Button>
            <IconButton className="item" icon="pen" onClick={() => set_showForm(rec)} />
            <DeleteButton className="item" onClick={() => onDeltePage(rec?._id)} />
          </span>
        </>)
      }
    },
    {
      title: 'Attachments', dataIndex: ['values', 'imgAttachments', 'allowed'], key: 'imgAttachments', render: (allowed, rec) => {
        return (<div>{allowed && `(${rec?.values?.imgAttachments?.types})`}</div>)
    } },
    // { title: 'Version', dataIndex: 'version', key:'version' },
    { title: 'Status', dataIndex: ['values', 'status'], key: 'status', width: 100 },
  ]

  return (<>

    <div style={{ padding:"10px" }}>
      {error && <Alert message={error} type="error" showIcon />}

      <Table isSotrable loading={loading || busy}
        title={() => (<Row gutter={[10, 0]}>
          <Col flex="auto">
            <div style={{ fontWeight: "bold", fontSize:"18px" }}>Site Info Pages</div>
          </Col>
          <Col>
            <Button size="small" onClick={() => set_showForm({
              values: { imgAttachments: showForm.imgAttachments || { allowed: false } }
            })}>Add New Page</Button>
          </Col>
        </Row>)}
        columns={columns}
        dataSource={pageData}
        saveSorting={saveSorting}
      />
    </div>

    <Drawer title={`Field Manager`} width={600} visible={openFieldEditor !== false} onClose={() => set_openFieldEditor(false)}>
      {openFieldEditor && <>
        <FieldManagerform initialValues={openFieldEditor} onUpdate={()=>{
          set_openFieldEditor(false)
          fetchData();
        }} onCancel={() => set_openFieldEditor(false)} />
      </>}
    </Drawer>

    <Modal title={'Manage Info Tab'} footer={false} width={'600px'} visible={showForm !== false} onCancel={() => set_showForm(false)} destroyOnClose>
      {showForm && <InfoPageForm 
        onSuccess={()=>{
          set_showForm(false)
          fetchData()
        }}
        onCancel={() => set_showForm(false)}
        initialValues={showForm}
      />}
    </Modal>

  </>)
}



export default function SiteSettings() {
  return (<>
    <Breadcrumb style={{ padding: "10px" }}>
      <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
      <Breadcrumb.Item><Icon icon="map-marker-alt" size="1x" /><b> Sites</b></Breadcrumb.Item>
    </Breadcrumb>

    <div style={{ padding:"10px" }}><ListHeader title={'Site Settings'} /></div>
    <InfoPages />

    {/* <ContentArea style={{ marginTop: 0, }} transparent></ContentArea> */}
  </>
  )
}
