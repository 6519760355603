import React from 'react'
import PropTypes from 'prop-types';
import { TimePicker as _TimePicker } from 'antd';
import { Field } from 'react-final-form'
import { Label } from './FormField'
// import debounce from 'lodash/debounce';


/*************************
    <TimePicker showTime={{ format: 'HH:mm' }} format="HH:mm" onChange={console.log} />
    <TimePicker.RangePicker
        showTime={{ format: 'HH:mm' }}
        format="HH:mm"
        onChange={(e) => console.log("onchnage", e)}
        onOk={(e) => console.log("on OK", e)}
    />
 */

export const TimeField = _props => {
    let props = { ..._props };
    delete props.label;

    let fieldProps = {
        showTime:{format: props.format || 'HH:mm'},
        format: props.format || "HH:mm",
        // onChange:{ console.log }
    }
    if (props.onOk) fieldProps = Object.assign(fieldProps, { onOk: props.onOk });

    return (
        <Field {...props}>
            {({ input, meta }) => {

                const onChange = (_mom, _str) => {
                    if (_props.onChange) _props.onChange(_mom.startOf('minutes'), _str);
                    if (_props.returnAs == 'moment') input.onChange(_mom.startOf('minutes'), _str);
                    else input.onChange(_mom ? _mom.startOf('minutes').format() : _mom);
                }

                let className = `form-field ${props.validate && 'validate'} ${(meta.error && meta.touched) && 'has-errors'} ${!props.compact && "field-margins"} ${props.className}`;

                return (
                    <div className={`${className} date`}>
                        {_props.label && <label>{_props.label}</label>}
                        <div><_TimePicker {...fieldProps} {...input} onChange={onChange} /></div>
                        {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    </div>
                )
            }}
        </Field>
    )

}
TimeField.propTypes = {
    name: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
    returnAs: PropTypes.string,
}
export default TimeField;









export const TimeRangeField = _props => {
    let props = { ..._props };
    delete props.label;
    delete props.returnAs;

    let fieldProps = {
        showTime: { format: props.format || 'HH:mm' },
        format: props.format || "HH:mm",
    }
    if (props.onOk) fieldProps = Object.assign(fieldProps, { onOk: props.onOk });

    return (
        <Field {...props}>
            {({ input, meta }) => {

                const onChange = (dates, dateStrings, info) => {
                    {/* function(dates: [moment, moment], dateStrings: [string, string], info: { range: start | end }) */ }

                    if (_props.returnAs == 'moment') input.onChange(dates.map(o => (o.startOf('minutes'))), dateStrings, info)
                    else input.onChange(dates.map(d => (d ? d.startOf('minutes').format() : d)), dateStrings, info)
                }

                return (
                    <div className={`form-field ${!props.compact && "field-margins"} date`}>
                        {_props.label && <label>{_props.label}</label>}
                        <div><_TimePicker.RangePicker {...fieldProps} {...input} onChange={onChange} /></div>
                        {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    </div>
                )
            }}
        </Field>
    )

}
TimeRangeField.propTypes = {
    name: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
    returnAs: PropTypes.string,
}









export const TimePicker = props => {
    let _props = { ...props }
    
    let style = { width: "100%", ...props.style }
    if (props.width) style = Object.assign(style, { width: props.width })
    
    delete _props.label
    delete _props.style
    
    return (<div className="simple-field">
        {props.label && <Label>{props.label}</Label>}
        <TimePicker style={{...style}} {..._props} />
    </div>)
}