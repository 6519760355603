import React, { useState, useEffect } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import GuardForm from 'Modules/Guards/components/GuardForm';
import { rules, FormField } from 'Common/components/Form';
import { Form } from 'react-final-form'
import { loader } from 'graphql.macro';
import { Col, message, Modal, Row } from 'antd';
import { Button } from 'Common/components';
import { mongoToDate } from 'Common/scripts/Functions';
import { __error } from 'Common/scripts/consoleHelper';

const CHANGE_STATUS = loader('src/Graphql/FormsData/editFormsDataStatus.graphql');


const HireGuardProcess = ({ client, showDrawer, addGuardData, onClose, application_id }) => {
    const [statusData, set_statusData] = useState(null);

    useEffect(() => {
        return () => {
            set_statusData(null);
        };
    }, [showDrawer])

    const onStatusSubmit = values => set_statusData(values);

    const updateStatus = async values => {
        const input = {
            _id: addGuardData._id,
            status: 'hired',
            note: statusData.note,
            ref_key: addGuardData.ref_key,
        };

        const updated = await client.mutate({ mutation: CHANGE_STATUS, variables: { input } }).then(r => {
            return r.data.editFormsDataStatus;
        }).catch(err => {
            console.log(__error("ERROR"), err);
            return { error: { message: "Failed to process the request!" } };
        });

        if (updated.error) return message.error(updated.error, message);

        return input;
    }

    const onCloseGaurdForm = async (values) => {
        if (!values || values.error) return;
        let resutls = await updateStatus()        
        
        set_statusData(false);
        onClose(values)
    }

    const parse_addGuardData = () => {
        let input = {}
        if (addGuardData?.fields) {
            Object.assign(input, {
                f_name: addGuardData?.fields.find(o => o.name == 'f_name')?.value,
                m_name: addGuardData?.fields.find(o => o.name == 'm_name')?.value,
                l_name: addGuardData?.fields.find(o => o.name == 'l_name')?.value,
                email: addGuardData?.fields.find(o => o.name == 'email_address')?.value,
                phone: addGuardData?.fields.find(o => o.name == 'mobile_phone')?.value,
                // driving_license: addGuardData?.fields.find(o => o.name == 'driving_license').value,
                sin: addGuardData?.fields.find(o => o.name == 'sin_no')?.value,
                security_license_no: addGuardData?.fields.find(o => o.name == 'license_no')?.value,
                security_license_expiry: mongoToDate(addGuardData?.fields.find(o => o.name == 'license_expiry')?.value),
            })
        }
        return input;
    }


    let showStatusModal = statusData == null && showDrawer;
    let showGuardForm = statusData != null && showDrawer;
    
    return (<>
        <Modal title="Application Status Update" visible={showStatusModal} footer={false} onCancel={() => onClose(false)}>
            {(showStatusModal) && <>
                <Form onSubmit={onStatusSubmit} initialValues={{  }}>
                    {({ handleSubmit, submitting, form, values, invalid, dirty, valid }) => (<>
                        <form className="form_component" onSubmit={handleSubmit}>
                            <p>Status of this application will be changed to <b>Hired</b>, please provide the status update note to proceed</p>

                            <FormField name="note" type="textarea" size="small" placeholder="Please provide the reason of status change" label="Notes" validate={rules.required} />

                            <div style={{ height: "20px" }} />
                            <Row>
                                <Col flex="auto"><Button onClick={() => onClose(false)} type="default">Cancel</Button></Col>
                                <Col><Button type="primary" size="medium" htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Proceed to create guard account</Button></Col>
                            </Row>
                        </form>
                    </>)}
                </Form>

            </>}
        </Modal>

        <GuardForm
            application_id={application_id}
            showDrawer={showGuardForm}
            addGuardData={parse_addGuardData(addGuardData)}
            onClose={onCloseGaurdForm}
            status_notes={statusData && statusData.note}
        />
    </>)


    // return (<>
    //     <GuardForm
    //         showDrawer={showAddguard != false}
    //         addGuardData={showAddguard}
    //         onClose={(res) => set_showAddguard(false)}
    //     />
    // </>)
}

export default withApollo(HireGuardProcess);