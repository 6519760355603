import React from 'react';
import { addModule, addReducers } from '../connector';
import { getmodulePermissions } from 'Common/scripts/Security';
// import { uriRoot } from 'configs';
// import ProductsAndServices from './value_pairs/products_and_services';
import CompanyAssets from './value_pairs/company_assets';
import NoticeboardCategories from './value_pairs/noticeboard_categories';
import SystemSettings from './value_pairs/system_settings';
import EmployeeSkills from './value_pairs/employee_skills';
import LeadServiceType from './value_pairs/lead_service_type';
import ShiftTypes from './value_pairs/shift_types';
import ReportTypes from './value_pairs/report_types';
import IncidentTypes from './value_pairs/incident_types';
import SiteSettings from './SiteSettings';
import Home from './Home';
import { ROOT, MODULE } from './constants'

// export const ROOT = `${uriRoot}/settings/`;
// export const MODULE = 'SETTINGS';
export const modulePermissions = getmodulePermissions('system-settings');

export const SubMenu = props => ([
    { path: `${ROOT}system_settings`, exact: true, title: 'System Settings', component: SystemSettings },
    // { path: `${ROOT}prod_and_services`, exact: true, title: 'Products & Services', component: ProductsAndServices },
    { path: `${ROOT}company_assets`, exact: true, title: 'Company Assets', component: CompanyAssets },
    { path: `${ROOT}employee_skills`, exact: true, title: 'Employee Skills', component: EmployeeSkills },
    { path: `${ROOT}noticeboard_categories`, exact: true, title: 'Noticeboard Categories', component: NoticeboardCategories },
    { path: `${ROOT}lead_service_type`, exact: true, title: 'Lead Service Type', component: LeadServiceType },
    { path: `${ROOT}shift_types`, exact: true, title: 'Shift Types', component: ShiftTypes },
    { path: `${ROOT}report_types`, exact: true, title: 'Report Types', component: ReportTypes },
    { path: `${ROOT}incident_types`, exact: true, title: 'Incident Types', component: IncidentTypes },
    { path: `${ROOT}site_settings`, exact: true, title: 'Site Settings', component: SiteSettings },
])

if (modulePermissions['system-settings.manage-value-pairs']){
    // let menuItems = SubMenu();
    addModule(
        {
            path: `/settings`, component: Home, icon: 'cogs', title: "Settings", exact: 0, leftNav: true,
            subMenu: SubMenu()
        },
    )
}
