import React, { Component, useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import _ from 'lodash'
import { Drawer, Button, BackButton, Loader, DevBlock, Icon, List, ListItem } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import FilterSelection from '../components/FilterSelection';
import ExplainSelectedFilters from '../components/ExplainSelectedFilters';
import FilterForm from '../components/FilterForm';
import FilterInfoForm from '../components/FilterInfoForm';

import { loader } from 'graphql.macro';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';

import { Alert, message } from 'antd';
import { sleep } from 'Common/scripts/Functions';
import { updateState } from 'Store/ep_admin.actions';


const RECORDS = loader('src/Graphql/filtered_views/filteredView.graphql');
const UPDATE_FILTER = loader('src/Graphql/filtered_views/updateFilteredViewFilter.graphql');

const env = process.env;

class FilterEditor extends Component {
    state = {
        busy: false,
        selectedFilters: this.props?.initialValues?.filter || [],
        activeSection: this.props.initialValues ? 'selected_filters' : false,
        filterToApply: null,
        activeFilter: this.props.initialValues || null,
    }

    constructor(props){
        super(props);

        this.selectFilter = this.selectFilter.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.onEditFilterClick = this.onEditFilterClick.bind(this);
        this.onRemoveFilterClick = this.onRemoveFilterClick.bind(this);
        this.onUpdarteFilterPressed = this.onUpdarteFilterPressed.bind(this);
    }

    selectFilter ({ item, group_key }) {
        this.setState({
            filterToApply: { item, group_key },
            activeSection: 'apply_filter'
        });
    }

    async applyFilter (filter) {
        var selectedFilters = this.state.selectedFilters.slice();
        //#### update filter => remove previous entry of same filter and dd again
        selectedFilters = this.onRemoveFilterClick(filter, 'skip-stateupdate');
        selectedFilters.push(filter);

        this.setState({ activeSection: "selected_filters", selectedFilters }, ()=>{
            this.onUpdarteFilterPressed()
        });
    }

    onEditFilterClick (filter) {
        this.setState({ activeSection: 'apply_filter', filterToApply: filter })
    }

    onRemoveFilterClick (filter, action) {
        let selectedFilters = this.state.selectedFilters.filter(o => !(o.group_key == filter.group_key && o.item.field == filter.item.field));
        if (action != "skip-stateupdate") this.setState({ selectedFilters }, () => this.onUpdarteFilterPressed())

        return selectedFilters
    }

    async onUpdarteFilterPressed () {
        const input = {
            _id: this.state.activeFilter._id,
            filter_str: JSON.stringify(this.state.selectedFilters)
        }

        const results = await this.props.doUpdateFilter(input);
    }

    render() {
        const { activeSection, selectedFilters, selectFilter, filterToApply, initialValues } = this.state;

        return (<>
            {activeSection == 'selected_filters' && <>
                {/* <p>Selected filter list with values</p> */}
                <ExplainSelectedFilters
                    filters={selectedFilters || []}
                    onEditClick={this.onEditFilterClick}
                    onRemoveClick={this.onRemoveFilterClick}
                />
                <Button onClick={() => this.setState({ activeSection: "search_filter_list" })}>Add</Button>
            </>}

            {activeSection == 'search_filter_list' && <>
                <BackButton onClick={() => this.setState({ activeSection: 'selected_filters' })} style={{ marginLeft: "-15px" }} />
                <p style={{ fontWeight: "bold", fontSize: "18px" }}>Schedule Propperties</p>
                <FilterSelection onClick={this.selectFilter} exclude={selectedFilters} />
            </>}

            {activeSection == 'apply_filter' && <>
                <BackButton onClick={() => this.setState({ activeSection: 'search_filter_list' })} style={{ marginLeft: "-15px" }} />
                <FilterForm filter={filterToApply} onSubmit={this.applyFilter} />
            </>}

            {/* {this.props.debug && <>
                <p>activeSection : {activeSection}</p>
                <DevBlock title="FilterEditor > selectedFilters" obj={selectedFilters} />
                <DevBlock title="FilterEditor > initialValues" obj={initialValues} />
            </>} */}

        </>)
    }
}


const __FilterEditor = props => {
    const [state, updateState] = useState({
        busy: false,
        selectedFilters: props?.initialValues?.filter || [],
        activeSection: props.initialValues ? 'selected_filters' : false,
        filterToApply: null, 
        activeFilter: props.initialValues || null,
    })

    const selectFilter = ({ item, group_key }) => {
        setState({
            ...state,
            filterToApply: { item, group_key },
            activeSection: 'apply_filter'
        });
    }

    const setState = args => {
        updateState({ ...state, ...args })
    }

    const applyFilter = (filter) => {
        var selectedFilters = state.selectedFilters.slice();
        //#### update filter => remove previous entry of same filter and dd again
        selectedFilters = onRemoveFilterClick(filter, 'skip-stateupdate');
        selectedFilters.push(filter);

        setState({ activeSection: "selected_filters", selectedFilters });
        onUpdarteFilterPressed()
    }

    const onEditFilterClick = (filter) => {
        setState({ activeSection: 'apply_filter', filterToApply: filter })
    }

    const onRemoveFilterClick = (filter, action) => {
        let selectedFilters = state.selectedFilters.filter(o => !(o.group_key == filter.group_key && o.item.field == filter.item.field));
        if (action != "skip-stateupdate") setState({ selectedFilters }, () => onUpdarteFilterPressed())

        return selectedFilters
    }

    const onUpdarteFilterPressed = async () => {
        // setState({ busy:true })

        const input = {
            _id: state.activeFilter._id,
            filter_str: JSON.stringify(state.selectedFilters)
        }

        const results = await props.doUpdateFilter(input);

        // const results = await props.client.mutate({ mutation: UPDATE_FILTER, variables: { input } }).then(e => {
        //     const response = e.data.updateFilteredViewFilter;
        //     if (response.error) console.log(__error("ERROR "), e);
        //     return response;
        // }).catch(err => {
        //     console.log(__error("Request Error: "), err);
        //     return { error: { message: "Request ERROR" } }
        // })

        if (!results.error) message.success("Filter saved")


        // setState({ busy: false })
        
    }

    const onClose = () => {
        setState({ activeFilter: null });
        props.onClose();
    }

    return (<>
        {state.activeSection == 'selected_filters' && <>
            {/* <p>Selected filter list with values</p> */}
            <ExplainSelectedFilters
                filters={state.selectedFilters || []}
                onEditClick={onEditFilterClick}
                onRemoveClick={onRemoveFilterClick}
            />
            <Button onClick={() => setState({ activeSection: "search_filter_list" })}>Add</Button>
        </>}

        {state.activeSection == 'search_filter_list' && <>
            <BackButton onClick={() => setState({ ...state, activeSection: 'selected_filters' })} style={{ marginLeft: "-15px" }} />
            <p style={{ fontWeight: "bold", fontSize: "18px" }}>Schedule Propperties</p>
            <FilterSelection onClick={selectFilter} exclude={state.selectedFilters} />
        </>}

        {state.activeSection == 'apply_filter' && <>
            <BackButton onClick={() => setState({ activeSection: 'search_filter_list' })} style={{ marginLeft: "-15px" }} />
            <FilterForm filter={state.filterToApply} onSubmit={applyFilter} />
        </>}

        {/* {props.debug && <>
            <p>activeSection : {state.activeSection}</p>
            <DevBlock title="FilterEditor > selectedFilters" obj={state.selectedFilters} />
            <DevBlock title="FilterEditor > initialValues" obj={props.initialValues} />
        </>} */}

    </>)
}
FilterEditor.propTypes = {
    // activeFilter: PropTypes.object,
    initialValues: PropTypes.object,
    // onFilterInfoSaved: PropTypes.func.isRequired,
    category: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    // onClear: PropTypes.func.isRequired,
    // onSaveFilter: PropTypes.func.isRequired,
    // deleteFilteredView: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
}



const FilterEditorWrapper = props => {
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);
    const [filteredView, setFilteredView] = useState(null);
    const [section, setSection] = useState('new');

    useEffect(() => {
        if (props.showform){
            setError(null);
            setBusy(false);
            setSection(props.initialValues ? 'filter' : 'new');
            if (props.initialValues) getFilter()
        }
    }, [props.showform])


    const getFilter = async () => {
        if (!props.initialValues || !props.showform) return;
        
        if (error!==null) setError(null);
        if (filteredView!==null) setFilteredView(null)
        setBusy(true);

        const results = await props.client.query({ query: RECORDS, variables: { id: props.initialValues._id } }).then(e => {
            const response = _.cloneDeep(e.data.filteredView);
            if (response.error) console.log(__error("ERROR "), e);
            else Object.assign(response, { filter: response.filter_str ? JSON.parse(response.filter_str) : [] })

            return response;
        }).catch(err => {
            console.log(__error("Request Error: "), err);
            return { error: { message:"Request ERROR" } }
        })

        if (results.error) return setError(results.error.message);

        setSection('filter')
        setFilteredView(results);
        setBusy(false);

    }

    const onClose = () => {
        console.log("onClose()")
        setFilteredView(null);

        props.onClose();
    }

    const renderFilterForm = () => {
        if (!props?.category) return <><Alert message="Missing configuration" description="Filter category is undefined!" type="error" showIcon /></>

        if (props.category == 'schedule'){
            if (busy) return <Loader loading={true} />
            
            return (<>
                <FilterEditor
                    {...props}
                    onClose={onClose}
                    initialValues={filteredView}
                    // onFilterInfoSaved={setFilteredView}
                />
            </>)
        }
        else return <Alert message="Missing configuration" description={`Invalid filter category (${props.category})`} type="error" showIcon />
    }

    const onFilterInfoSaveClicked = filter => {
        console.log("onFilterInfoSaveClicked() ", filter)

        setFilteredView(filter);
        setSection('filter');
        setBusy(false);
    }

    const renderTitle = () => {
        if(busy) return <Loader loading={true} />

        if (filteredView) return <div style={{ lineHeight: "12px" }}>
            <b>Edit </b> {filteredView.title} 
            <div style={{ fontSize: "10px" }}>{props.category}</div>
        </div>

        return <div style={{ lineHeight: "12px" }}>
            <b>Add New </b> Filtered View 
            <div style={{ fontSize: "10px" }}>{props.category}</div>
        </div>

        // if (props.initialValues) return <div style={{ lineHeight: "12px" }}><b>Edit </b> {!busy ? filteredView?.title : <Loader loading={true} />} <div style={{ fontSize: "10px" }}>{props.category}</div></div>
        // return <div style={{ lineHeight: "12px" }}><b>Add New </b> Filtered View <div style={{ fontSize: "10px" }}>{props.category}</div></div>
        // props.initialValues ? <b>Edit</b> + ` ${filteredView ? filteredView.title : <Loader loading={true} />} - ${props.category}` : `Add New Filtered View - ${props.category}`,
    }
    const drawerOptions = {
        width: "600", // env.NODE_ENV == 'development' ? '100%' : '500px',
        destroyOnClose: true,
        maskClosable: false,
        placement: "right",
        loading: busy,
        // bodyStyle:{ padding: "10px" },
        onClose: onClose,
        visible: props.showform,
        title: renderTitle(),
        footer: false,
        // footer:<>
        //         <span></span>
        //         <Button loading={busy} type="primary" onClick={() => {
        //             document.getElementById('FilteredViewForm').dispatchEvent(new Event('submit', { cancelable: true }))
        //         }}>Save</Button>
        //     </>
    }


    return (<>
        <Drawer {...drawerOptions}><>
            {!props.section && <p>props.section: {props.section}</p>}

            {(props.section == 'edit-basic' || props.section == 'new') && <>
                <FilterInfoForm 
                    debug={props.debug}
                    addFilteredView={props.addFilteredView}
                    doUpdateView={props.doUpdateView}
                    initialValues={section == 'edit-basic' ? filteredView : undefined} 
                    onSave={onFilterInfoSaveClicked} 
                    category={props.category} 
                />

                <DevBlock obj={filteredView} />
            </>}

            {(props.section == 'filter' && filteredView) && <>{renderFilterForm()}</>}
        </></Drawer>
    </>)
}
FilterEditorWrapper.propTypes = {
    section: PropTypes.string,
    debug: PropTypes.bool,
    initialValues: PropTypes.object,
    // activeFilter: PropTypes.object,
    category: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    // onSaveFilter: PropTypes.func.isRequired,
    // deleteFilteredView: PropTypes.func.isRequired,
    showform: PropTypes.bool.isRequired,
    // addFilteredView: PropTypes.func.isRequired,
    // editFilteredView: PropTypes.func.isRequired,


    addFilteredView: PropTypes.func.isRequired,
    doUpdateView: PropTypes.func.isRequired,
    doUpdateFilter: PropTypes.func.isRequired,
}

export default  withApollo(FilterEditorWrapper);