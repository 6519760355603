import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT, MODULE } from './constants'

export const modulePermissions = getmodulePermissions('system-updates');

// addReducers({ admins: reducers });
if (modulePermissions["run-updates"]) {
  addModule(
    { path: `${ROOT}`, component: Home, icon:'circle', title:"System Updates", exact:1, leftNav:true },
  )
}
