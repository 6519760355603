import React, { useState } from 'react'
import { loader } from 'graphql.macro';
import { Drawer, Space, Alert, message, Row, Col } from 'antd';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Loader, ClientSearch, Button, Heading } from 'Common/components';
import { siteStatusArray, site_types } from 'configs';
import { modulePermissions } from '..';
import { withApollo } from '@apollo/client/react/hoc';
import { verifyRole } from 'Common/scripts/Security';

const ADD_SITE = loader('src/Graphql/site/addSite.graphql');
const EDIT_SITE = loader('src/Graphql/site/editSite.graphql');


const SiteForm = props => {
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);

    const updateRecord = (input) => {
        if (!modulePermissions['site.update']) return;

        setBusy(true);

        props.client.mutate({ mutation: EDIT_SITE, variables: { input } }).then(r => {
            let response = r.data.editSite;
            if (response.error) {
                setBusy(false);
                setError(response.error.message);
                return;
            }
            message.success("Site Updated!");
            if (props.onUpdate) props.onUpdate(response)
            props.onClose()

        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            message.error("Failed to process the request!");
            setBusy(false);
        })
    }

    const addRecord = (input) => {
        if (!modulePermissions['site.add']) return;

        setBusy(true);

        props.client.mutate({ mutation: ADD_SITE, variables: { input } }).then(r => {
            let response = r.data.addSite;

            if (response.error) {
                setBusy(false);
                setError(response.error.message);
                return;
            }

            message.success("Site Added!");
            if (props.onAdd) props.onAdd(response)
            props.onClose()

        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            message.error("Failed to process the request!");
            setBusy(false);
        })

    }

    const onSubmitSite = (values) => {
        if (!values.client || !values.client._id) {
            alert("Please select a client");
            return false;
        }

        const filteredData = {
            name: values.name,
            address: values.address,
            site_type: values.site_type,
            client_id: values.client._id,
            note: values.note,
            status: values.status,
        }
        if (verifyRole("site.bill")){
            Object.assign(filteredData, {
                billing_name: values.billing_name,
                billing_email: values.billing_email,
                billing_phone: values.billing_phone,
                billing_add: values.billing_add,
            })
        }

        if (props.initialData && props.initialData._id) {
            Object.assign(filteredData, { _id: props.initialData._id });
            updateRecord(filteredData);
        } else {
            addRecord(filteredData);
        }

    }

    if (error) return <Alert message="Error" description={error} type="error" showIcon />

    return(<>

        <FormComponent onSubmit={onSubmitSite} id='SiteForm' loading={busy} fields={{ ...props.initialData }} debug={true}
            mutators={{
                selectClient: (newValueArray, state, tools) => {
                    let node = newValueArray[0];
                    // let drivers = state.formState.values.drivers || [];
                    // drivers.push({ _id: node.value, name: node.children })
                    let _client = { _id: node.value, name: node.children };

                    tools.changeValue(state, 'client', () => _client);
                },
                de_selectClient: (newValueArray, state, tools) => {
                    // let drivers = state.formState.values.drivers || [];
                    // let drivers_ids = state.formState.values.drivers_ids || [];
                    // drivers = drivers.filter(o => (drivers_ids.includes(o._id)))

                    tools.changeValue(state, 'client', () => undefined)
                },
            }}
            form_render={formProps => {
                const { values, form, invalid, dirty, valid } = formProps;

                return (<>
                    <Row>
                        <Col flex="auto">
                            <FormField allowClear validate={rules.required} label="Site Type" name="site_type" type="select" placeholder="Site Type" showSearch
                                optionFilterProp="children"
                                data={site_types}
                            />
                        </Col>
                        <Col flex="120px"><FormField label="Site Status" name="status" data={siteStatusArray} type="select" validate={rules.required} /></Col>
                    </Row>

                    <FormField label="Site Name" name="name" type="text" validate={rules.required} />
                    <FormField label="Site Address" name="address" type="text" validate={rules.required} />

                    <ClientSearch label="Client" name='client._id'
                        onSelect={(txt, node) => form.mutators.selectClient(node)}
                        onDeselect={(txt, node) => form.mutators.de_selectClient(node)}
                        // defaultValues={values.client ? values.client.map(o => ({ _id: o._id, title: o.name })) : []}
                        defaultValues={!values.client ? null : [ { _id: values.client._id, title: values.client.name } ]}
                    />

                    <FormField name="note" size="medium" type="textarea" label="Notes" />

                    {verifyRole("site.bill") && <>
                        <Heading>Billing info</Heading>
                        <FormField label="Billing Name" name="billing_name" type="text" />
                        <FormField label="Billing Email" name="billing_email" type="text" />
                        <FormField label="Billing Phone" name="billing_phone" type="text" />
                        <FormField label="Billing Address" name="billing_add" type="text" />
                    </>}


                    <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} >Submit </Button>

                </>)
            }}
        />

    </>)
}

class SiteFormDrawer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            busy: null,
            mounted: false,
        };
        // this.updateRecord = this.updateRecord.bind(this)
    }

    // updateRecord(input){
    //     this.setState({ busy: true });

    //     this.props.client.mutate({ mutation: EDIT_SITE, variables: { input } }).then(r => {
    //         let response = r.data.editSite;

    //         if (response.error) {
    //             this.setState({ busy: false, error: response.error.message });
    //             return;
    //         }
    //         message.success("Site Updated!");
    //         this.props.onClose()

    //     }).catch(err => {
    //         console.log("ERROR: ", JSON.stringify(err, 0, 2));
    //         message.error("Failed to process the request!");
    //         this.setState({ busy: false });
    //     })

    // }

    // addRecord(input){
    //     this.setState({ busy: true });

    //     this.props.client.mutate({ mutation: ADD_SITE, variables: { input } }).then(r => {
    //         let response = r.data.addSite;

    //         if (response.error) {
    //             this.setState({ busy: false, error: response.error.message });
    //             return;
    //         }
    //         message.success("Site Added!");
    //         this.props.onClose()

    //     }).catch(err => {
    //         console.log("ERROR: ", JSON.stringify(err, 0, 2));
    //         message.error("Failed to process the request!");
    //         this.setState({ busy: false });
    //     })

    // }

    componentDidMount() {
        // this.fetchSites()
        this.setState({ mounted:true })
    }
    componentWillUnmount(){
        this.setState({ mounted: false })
    }  

    render(){
        const { showDrawer, onClose, initialData } = this.props;
        const { busy, mounted } = this.state;

        if (this.state.error) return <Alert message="Error" description={this.state.error} type="error" showIcon />

        return(<>
            <Drawer
                title={this.props.initialData ? "Edit Site" : "Add New Site"}
                width={600}
                onClose={onClose}
                visible={showDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                extra={<Button onClick={onClose}>Cancel</Button>}
                destroyOnClose={true}
            ><>
                {mounted && <SiteForm 
                    client={this.props.client} initialData={this.props.initialData} onClose={this.props.onClose}
                    onUpdate={this.props.onUpdate} onAdd={this.props.onAdd} 
                />}
            </></Drawer>
        </>)
    }

}

export default withApollo(SiteFormDrawer)