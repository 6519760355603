import React from 'react'
import PropTypes from 'prop-types';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Loader, Button, UsersSearch, ChannelSearch, Icon, IconButton } from 'Common/components';
import { loader } from 'graphql.macro';
import { Drawer, Space, Alert, message, Row, Col, Modal, Upload } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';
import { __error } from 'Common/scripts/consoleHelper';
import { thread_priority_array, ticket_status_array } from '../../constants';
import axios from 'axios'

const CREATE_CHANNEL = loader('src/Graphql/threads/createThreadsTicket.graphql');
const EDIT_CHANNEL = loader('src/Graphql/threads/editThreadsTicket.graphql');
const ATTACHMENT_LIMIT = 4;

const defaultValues = {
    priority: thread_priority_array[0]._id,
}


const TicketForm = ({ onSuccess, onCancel, client, initialValues }) => {

    async function updateRecords(input){
        let _input = {...input}
        delete _input.message;

        return client.mutate({ mutation: EDIT_CHANNEL, variables: { input: _input } }).then(r => (r?.data?.editThreadsTicket))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })
    }
    
    async function addRecords(input){
        return client.mutate({ mutation: CREATE_CHANNEL, variables: { input } }).then(r => (r?.data?.createThreadsTicket))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request!" } }
            })
    }
    
    const onTicketSubmit = async (values) => {

        const input = {
            subject: values.subject,
            members: values.members && values?.members?.map(o=>({ _id:o._id, name:o.name })),
            message : values.message,
            priority: values.priority,
            channel: values.channel && { _id: values.channel._id, title: values.channel.title },// && values?.channel?.map(o => ({ _id: o._id, ttile: o.title })),
        }
        if (values.members) Object.assign(input, { members: values.members.map(o=>({ _id:o._id, name:o.name })) })
        if (values.status) Object.assign(input, { status: values.status })
        if (initialValues && initialValues._id) Object.assign(input, { _id: Number(initialValues._id) })

        let results;
        if (input._id) results = await updateRecords(input)
        else results = await addRecords(input);

        if (results.error){
            message.error(results.error.message);
            return false;
        }


        var upload_results = await handleUpload({ fileList: values.attachments, _id: results._id }).catch(err => {
            console.log(__error("ERROR: ", err));
            return { error: { message: 'Unable to complete upload request' } }
        });
        if (upload_results && upload_results.error) {
            message.error(upload_results.error.message);
            // return upload_results;
        }

        onSuccess();
        return "reset"
    }

    const handleUpload = async ({ fileList, _id }) => {
        if (!fileList || fileList.length < 1) return; // { error: { message: "No file to upload!!" } }
        if (fileList.length > ATTACHMENT_LIMIT) return { error: { message: `You can upload max of ${ATTACHMENT_LIMIT} files only!` } }
        if (!_id) return { error: { message: `Missing ticket ID for upload` } }

        const hide = message.loading('Uploading..', 0);

        const formData = new FormData();
        formData.append('action', "ticket.attachments");
        formData.append('ticket_id', _id);
        fileList.forEach(file => {
            formData.append('files[]', file);
        });

        const results = await axios.post(process.env.REACT_APP_API_URL + '/thread_upload/upload', formData)
            .catch((error) => {
                console.log(__error("Error: "), JSON.stringify(error, 0, 2));
                return { error: { message: "Upload request fail!" } };
            });

        hide();
        return results;
    };



    return (<>
        <FormComponent onSubmit={onTicketSubmit} id='TicketForm' fields={{ ...defaultValues, ...initialValues }} debug={true} 
            mutators={{
                selectChannel: (newValueArray, state, tools) => {
                    let node = newValueArray[0];

                    let members = node?.members;
                    let members_ids = node?.members?.map(o => (o._id));

                    tools.changeValue(state, 'channel', () => ({ _id:node._id, title: node.title }));
                    tools.changeValue(state, 'members', () => members);
                    tools.changeValue(state, 'members_ids', () => members_ids);
                },
                de_selectChannel: (newValueArray, state, tools) => {
                    tools.changeValue(state, 'channel', () => undefined);
                    tools.changeValue(state, 'members', () => undefined);
                    tools.changeValue(state, 'members_ids', () => undefined);
                },

                selectUser: (newValueArray, state, tools) => {
                    let node = newValueArray[0];

                    let _dataArray = state.formState.values.members || [];
                    _dataArray.push({ _id: node._id, name: node.name })

                    tools.changeValue(state, 'members', () => _dataArray);
                },
                de_selectUser: (newValueArray, state, tools) => {
                    let _dataArray = state.formState.values.members || [];
                    let _ids = state.formState.values.members_ids || [];

                    _dataArray = _dataArray.filter(o => (_ids.includes(o._id)))

                    tools.changeValue(state, 'members', () => _dataArray)
                },

                selectFiles: (newValueArray, state, tools) => {
                    let file = newValueArray[0];
                    let action = newValueArray[1];
                    let attachments = state.formState.values.attachments || []

                    if (action == 'add') {
                        if (attachments.length >= ATTACHMENT_LIMIT) return;
                        attachments.push(file);
                    }
                    if (action == 'remove') attachments = attachments.filter(o => o.uid != file.uid);

                    tools.changeValue(state, 'attachments', () => attachments);
                },
            }}
            form_render={formProps => {
                const { values, submitting, form, invalid, dirty, valid } = formProps;

                return (<>
                    {values._id && <Row>
                        <Col flex="auto" />
                        <Col><FormField label="Status" name="status" width={"100px"} data={ticket_status_array} type="select" validate={rules.required} /></Col>
                    </Row>}

                    <Row>
                        <Col flex="auto"><FormField label="Subject" name="subject" type="text" validate={rules.required} /></Col>
                        <Col flex="120px">
                            <FormField label="Priority" name="priority" type="select" data={thread_priority_array} validate={rules.required} />
                        </Col>
                    </Row>

                    <ChannelSearch label="Channel" name='channel._id'
                        preload disableSearch
                        onSelect={(___, opt, raw) => form.mutators.selectChannel(raw)}
                        onDeselect={(txt, node) => form.mutators.de_selectChannel(node)}
                        defaultValues={values.members ? values.members.map(o => ({ ...o, title: o.name })) : []}
                        validate={rules.required}
                    />
                    
                    <UsersSearch mode="multiple" label="Members" name='members_ids'
                        filter={{ status:"enabled" }}
                        preload disableSearch
                        disabled={!values.channel || values.channel.length<1}
                        onSelect={(___, opt, raw) => form.mutators.selectUser(raw)}
                        onDeselect={(txt, node) => form.mutators.de_selectUser(node)}
                        defaultValues={values.members ? values.members.map(o => ({ ...o, title: o.name })) : []}
                        validate={rules.required}
                    />

                    {!values._id && <FormField label="Message" name="message" type="textarea" validate={rules.required} />}
                    
                    <div style={{ marginBottom:"10px" }}>
                        <Upload
                            fileList={values?.attachments}
                            disabled={values?.attachments?.length >= ATTACHMENT_LIMIT}
                            onRemove={(file) => {
                                form.mutators.selectFiles(file, "remove");
                            }}
                            beforeUpload={(file) => {
                                form.mutators.selectFiles(file, "add");
                                return false;
                            }}
                            accept=".jpg,.jpeg,.png,.pdf,.doc.,docx,.xls,.xlsx,.csv"
                            multiple={true}
                            maxCount={ATTACHMENT_LIMIT}
                            showUploadList={false}
                        >
                            <span className='a' style={{ fontSize: "12px", padding: "2px 5px" }}><Icon icon="paperclip" /> add attachments ({values?.attachments?.length || 0}/{ATTACHMENT_LIMIT})</span>
                        </Upload>

                        {values?.attachments?.map((item, i) => {
                            return <Row key={i} className='upload-item-list' align="middle">
                                <Col flex="auto">{item.name}</Col>
                                <Col style={{ marginLeft: "10px" }}><IconButton onClick={() => form.mutators.selectFiles(item, "remove")} icon="trash-alt" /></Col>
                            </Row>
                        })}

                    </div>

                    
                    <Row gutter={[10]}>
                        <Col flex="auto" />
                        <Col><Button onClick={onCancel} color="white">Cancel</Button></Col>
                        <Col><Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!dirty || !valid} >{values._id ? "Update" : "Create"} Ticket</Button></Col>
                    </Row>                    

                </>)
            }}
        />

    </>)
}
const WithApollo = withApollo(TicketForm)

export default function TicketFormModal({ initialValues, visible, onCancel, onSuccess }) {
    const handel_onSuccess = (values) => {
        if (onSuccess) onSuccess()
        else onCancel()
    }

    let members_ids = initialValues && initialValues?.members?.map(o=>(o._id));

    return (<>
        <Modal title="Ticket" visible={visible} width="700px" onCancel={onCancel} footer={false} closable centered destroyOnClose>
            {visible && <WithApollo onCancel={onCancel} initialValues={{ ...initialValues, members_ids }} onSuccess={handel_onSuccess} />}
        </Modal>
    </>)
}
TicketFormModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    initialValues: PropTypes.object,
}

