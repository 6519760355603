/* eslint-disable eqeqeq */
import React from 'react';
// import PropTypes from 'prop-types';
// import { Button } from 'Common/components';
import { Route, Switch } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";
// import { modulePermissions } from '.'

// import RolesManagement from './containers/RolesManagement';
// import TypeManagement from './containers/TypeManagement';

// import { ROOT } from './'; 
import { ROOT, SubMenu } from './';
export const Home = props => {
  let routs = SubMenu();

  return (
    <Switch>
      {routs.map((item, i) => {
        if (!item.component) return null;
        return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
      })}
      <Route key={999} component={E404} />
    </Switch>
  )
}







// export const menuArray = props => {
//   return ([
//     { path: `/admin${ROOT}`, exact: true, title: 'Roles Management', component: RolesManagement },
//     { path: `/admin${ROOT}/type-management`, exact: true, title: 'Type Management', component: TypeManagement },
//   ])
// }


/**
 * Home
 */
// export const Home = props => {
//   let routs = menuArray(); 
//   if (!modulePermissions['account-role.update']) return null;

//   return (
//       <Switch>
//         {routs.map((item, i) => {
//           if (!item.component) return null;
//           return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
//         })}
//         <Route key={999} component={E404} />
//       </Switch>
//   )

// }


Home.propTypes = {
  // login: PropTypes.object.isRequired,
}

export default Home;