import React, { Component, useState } from 'react'
import { Breadcrumb, Col, Drawer, Row, Affix } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Button, DevBlock, Icon, IconButton } from 'Common/components';
import { getmodulePermissions } from 'Common/scripts/Security';
import { uriRoot, flagsArray } from 'configs';
import _ from 'lodash'
import FilteredViews from 'Common/FilteredViews';
import { ContentArea } from 'Layout_v1';
import SearchBar from './components/SearchBar';
import ScheduledHours from './containers/ScheduledHours';
import ScheduleForm from './components/ScheduleForm';
import { __yellow } from 'Common/scripts/consoleHelper';
// import { getWindowDimensions } from 'Common/scripts/Functions';
// import { connect } from "react-redux";


const modulePermissions = getmodulePermissions('hours-schedule-management');

export default class Schedule extends Component {
  state = {
    filter: null,
    activeFilter: null,
    // activeFilter: [
    //   {
    //     "group_key": "schedule_info",
    //     "item": {
    //       "title": "Check-In Date",
    //       "field": "check_in",
    //       "type": "date"
    //     },
    //     "filter": {
    //       "option": "farmula",
    //       "check_in": {
    //         "farmula": "previous_30_days"
    //       }
    //     }
    //   },
    //   // { approval_status: { $eq: 'new' } }
    //   {
    //     "group_key": "schedule_info",
    //     "item": {
    //       "title": "Verification",
    //       "field": "approval_status",
    //       "type": "approval_status"
    //     },
    //     "filter": {
    //       "option": "eq",
    //       "approval_status": {
    //         "eq": "new"
    //       }
    //     }
    //   }
    // ],
    showAddForm: false,
    edit: null, add: false,
  }

  constructor(props){
    super(props);
    this.toggleAddForm = this.toggleAddForm.bind(this);
    this.onViewChnaged = this.onViewChnaged.bind(this);
    this.updateActiveFilter = this.updateActiveFilter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  onViewChnaged = async (filter) => {
    console.log(__yellow("onViewChnaged()"))
    this.setState({ activeFilter: filter })
    return false;
  }

  toggleAddForm() {
    this.setState({ add: !this.state.add, edit: null })
  }

  updateActiveFilter(val) {
    var activeFilter = this.state?.activeFilter?.slice() || [];
    let exists = activeFilter.find(o => o?.item?.field == val?.item?.field);

    if (exists) {
      activeFilter = activeFilter.map(o => {
        return (o?.item?.field == val?.item?.field) ? val : o
      })
    }
    else {
      activeFilter.push(val);
    }

    // console.log("activeFilter: ", activeFilter)
    this.setState({ activeFilter });
  }

  clearFilter(val) {
    // console.log("clearFilter()", val);
    var activeFilter = this.state?.activeFilter?.slice() || [];
    // console.log("BEFORE activeFilter: ", activeFilter) 
    // let exists = activeFilter.find(o => o.item.field == val.item.field && o.filter.option == val.filter.option);
    // console.log("exists: ", exists)
    
    activeFilter = activeFilter.filter(o => !(o.item.field == val.item.field && o.filter.option == val.filter.option))
    // console.log("activeFilter: ", activeFilter)
    this.setState({ activeFilter })

    return;

    /*
    var activeFilter = this.state?.activeFilter?.slice() || [];
    let exists = activeFilter.find(o => o.item.field == val.item.field);
    if (!exists) return;

    activeFilter = activeFilter.filter(o => (o.item.field != val.item.field))

    this.setState({ activeFilter })
    */

  }



  render() {
    const { activeFilter, edit, add } = this.state;

    return (<>
      <Row>
        <Col flex="auto">
          <Breadcrumb style={{ padding: "10px", display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
            <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
            <Breadcrumb.Item ><Icon icon="calendar-alt" size="1x" /> Schedule</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* {modulePermissions['hours-schedule.add'] &&
          <Col style={{ padding: '10px' }}>
            <Icon onClick={() => this.toggleAddForm()} icon={this.state.add ? 'times' : 'plus'} size="2x" />
          </Col>
        } */}
      </Row>

      {modulePermissions['hours-schedule.add'] && <Affix offsetTop={-120}>
        <div style={{ position:"absolute", top:140, right: 20, textAlign: 'right' }}>
          <IconButton
            style={{ boxShadow: '0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)' }}
            size="large"
            type="danger"
            // disabled={!modulePermissions['noticeboard.note.add']}
            onClick={() => this.toggleAddForm()}
            icon="plus"
          />
        </div>
      </Affix>}

      <FilteredViews
        category="schedule"
        onTabClick={({ filter_str, _id, shared }) => {
          let filter = _.isString(filter_str) ? JSON.parse(filter_str) : filter_str;
          this.setState({ filter: filter || {}, activeFilter: filter });
        }}
        activeFilter={activeFilter}
        onfilterUpdate={(v) => this.onViewChnaged(v)}
      />

      {/* <DevBlock obj={activeFilter} title="activeFilter" /> */}

      <ContentArea style={{ marginTop: "0" }}>
        <div style={{ marginRight:"50px" }}>
          <SearchBar
            onClear={(o) => this.clearFilter(o)} 
            onSearch={(o) => this.updateActiveFilter(o)} 
            activeFilter={activeFilter} />
        </div>
        
        <div style={{ height: "5px" }} />

        {(activeFilter && activeFilter.length > 0) && 
          <ScheduledHours allowExport
            filter={this.state.filter} 
            activeFilter={this.state.activeFilter} 
          />
        }

      </ContentArea>
      
      <Drawer
        title={"Add New Guard Hours"}
        height={'500px'}
        placement="top"
        width={'100%'}
        onClose={this.toggleAddForm}
        visible={add ? true : false}
        bodyStyle={{ paddingBottom: 0 }}
        extra={<Button onClick={this.toggleAddForm}>Cancel</Button>}
      >
        {add && <ScheduleForm editNode={edit} onClose={() => this.setState({ add: false, edit: null })} />}
      </Drawer>


    </>)
  }
}


// const mapStateToProps = ({ ep_admin }) => {
//   return { schedule_views: ep_admin?.pageSettings?.schedule_views }
// }
// // const mapDispatchToProps = (dispatch, ownProps) => ({
// //   updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// // })
// export default connect(mapStateToProps)(Schedule)

