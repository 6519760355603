/* eslint-disable eqeqeq */
import React, { Component, useEffect } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { ContentArea, E404 } from "Layout_v1";
import { Breadcrumb, Col, Row, message } from 'antd'
import { Icon } from 'Common/components';
import { HomeOutlined } from '@ant-design/icons';
import { ROOT_PATH } from './constants';
import { uriRoot } from 'configs';
// import { loader } from 'graphql.macro';
// import { useSubscription, gql } from "@apollo/client";
// import { __error } from 'Common/scripts/consoleHelper';
import { connect } from "react-redux";
// import { showNotification } from 'Common/scripts/Functions';

import ThreadsLeftMenu from './components/left_menu';
import ThreadsDashboard from './containers/ThreadsDashboard';
import TicketDisplay from './containers/tickets/TicketDisplay';
import TicketHistory from './containers/tickets/TicketHistory';

import ChannelsHistory from './containers/channels/ChannelsHistory';
import ChannelDetails from './containers/channels/ChannelDetails';

import TodoDetails from './containers/todos/TodoDetails';
import TodoHistory from './containers/todos/TodoHistory';

// const TICKETE_SUBSCRIPTION = loader('src/Graphql/threads/sub_threadsTicketsUpdated.graphql');

// function Subscriber({ query, callback, variables }) {
//   // let variables = {};
//   // if (filter) Object.assign(variables, { ...filter });

//   const { data, loading, error } = useSubscription(
//     query,
//     {
//       // subscription: QUERY,
//       variables,
//       // fetchPolicy: "network-only" // cache-first, cache-only, cache-and-network, network-only, no-cache, standby
//       onSubscriptionData: ({ client, subscriptionData }) => {
//         const { data, error, loading, variables } = subscriptionData
//         console.log({ subscriptionData })
//         if (callback) callback(subscriptionData)
//       },
//       shouldResubscribe: false, // Determines if your subscription should be unsubscribed and subscribed again
//     }
//   );

//   if (error) console.log(__error("Subscription Error: "), error)
//   if (loading) {
//     console.log("Receiving subscription on ....", variables);
//     return null;
//   }

//   if (data) {
//     console.log('Subscription received: ', data);
//     // const { mutation, node } = data.formsDatasUpdated;
//     // if (callback) callback({ mutation, node })
//   }

//   return null;

// }

// document.body.addEventListener('my-set-lang', this.handleLangChange.bind(this));



const ThreadsHome = ({ user }) => {

  // useEffect(() => {
  //   if (!("Notification" in window)) {
  //     alert("Browser does not support desktop notification");
  //   } else {
  //     if (Notification.permission === "granted") return;
  //     Notification.requestPermission();
  //   }
  //   // return () => {};
  // }, [])


  // const onSubscriptionReceived = ({ data: { threadsTicketsUpdated } }) => {
  //   // console.log("onSubscriptionReceived: ", threadsTicketsUpdated)

  //   if (threadsTicketsUpdated.mutation == "TICKET_CREATED") {
  //     if (threadsTicketsUpdated.ticket.author._id != user._id){
  //       // message.success(`${threadsTicketsUpdated.ticket.author.name} has created a new ticket`)
  //       showNotification(`New ticket by: ${threadsTicketsUpdated?.ticket?.author?.name}`, { body: threadsTicketsUpdated?.ticket?.subject })
  //     }

  //     document.body.dispatchEvent(new CustomEvent("ticket-event", { 
  //       detail: { ticket: threadsTicketsUpdated.ticket, mutation: threadsTicketsUpdated.mutation }
  //      }));

  //     // let _messagesArray = (messagesArray && messagesArray.slice()) || [];
  //     // _messagesArray.push(threadsTicketsUpdated.node);
  //     // set_setMessagesArray(_messagesArray)
  //   }

  //   if (threadsTicketsUpdated.mutation == "TICKET_UPDATED") {

  //     if (threadsTicketsUpdated.ticket.author._id != user._id){
  //       // message.success(`${threadsTicketsUpdated.ticket.author.name} has updated a ticket`);
  //       showNotification(`New update by: ${threadsTicketsUpdated?.ticket?.author?.name}`, { body: threadsTicketsUpdated?.ticket?.subject })
  //     }


  //     document.body.dispatchEvent(new CustomEvent("ticket-event", { 
  //       detail: { ticket: threadsTicketsUpdated.ticket, mutation: threadsTicketsUpdated.mutation }
  //      }));
  //   }

  //   if (threadsTicketsUpdated.mutation == "TICKET_DELETED") {
  //     document.body.dispatchEvent(new CustomEvent("ticket-event", { 
  //       detail: { ticket: threadsTicketsUpdated.ticket, mutation: threadsTicketsUpdated.mutation }
  //     }));
  //   }

  //   if (threadsTicketsUpdated.mutation == "TICKET_ARCHIVED") {
  //     document.body.dispatchEvent(new CustomEvent("ticket-event", { 
  //       detail: { ticket: threadsTicketsUpdated.ticket, mutation: threadsTicketsUpdated.mutation }
  //      }));
  //   }

  //   if (threadsTicketsUpdated.mutation == "TICKET_CLOSED") {
  //     if (threadsTicketsUpdated.ticket.author._id != user._id){
  //       // message.success(`${threadsTicketsUpdated.ticket.author.name} has closed a ticket`);
  //       showNotification(`Ticket closed by: ${threadsTicketsUpdated?.ticket?.author?.name}`, { body: threadsTicketsUpdated?.ticket?.subject })
  //     }

  //     document.body.dispatchEvent(new CustomEvent("ticket-event", { 
  //       detail: { ticket: threadsTicketsUpdated.ticket, mutation: threadsTicketsUpdated.mutation }
  //      }));
  //   }



  //   if (threadsTicketsUpdated.mutation == "MESSAGE_ADDED") {
  //     if (threadsTicketsUpdated.message.author._id != user._id) {
  //       // message.success(`${threadsTicketsUpdated.message.author.name} has responded to a ticket`)
  //       showNotification(`${threadsTicketsUpdated.message.author.name} has responded to a ticket`, { body: threadsTicketsUpdated.message.body })
  //     }

  //     document.body.dispatchEvent(new CustomEvent("message-event", { 
  //       detail: { message: threadsTicketsUpdated.message, mutation: threadsTicketsUpdated.mutation }
  //      }));
  //   }

  //   if (threadsTicketsUpdated.mutation == "MESSAGE_UPDATED") {
  //     if (threadsTicketsUpdated.message.author._id != user._id) {
  //       showNotification(`${threadsTicketsUpdated.message.author.name} has updated the response`, { body: threadsTicketsUpdated.message.body })
  //     }

  //     document.body.dispatchEvent(new CustomEvent("message-event", { 
  //       detail: { message: threadsTicketsUpdated.message, mutation: threadsTicketsUpdated.mutation }
  //      }));
  //   }

  //   if (threadsTicketsUpdated.mutation == "MESSAGE_DELETED") {
  //     document.body.dispatchEvent(new CustomEvent("message-event", { 
  //       detail: { message: threadsTicketsUpdated.message, mutation: threadsTicketsUpdated.mutation }
  //      }));
  //   }

  //   if (threadsTicketsUpdated.mutation == "MESSAGE_DISCARDED") {
  //     document.body.dispatchEvent(new CustomEvent("message-event", { 
  //       detail: { message: threadsTicketsUpdated.message, mutation: threadsTicketsUpdated.mutation }
  //      }));
  //   }

  // }

  return (<ContentArea transparent style={{ padding:0 }}>
    {/* <Subscriber query={TICKETE_SUBSCRIPTION} callback={onSubscriptionReceived} variables={{ }} /> */}

    <Breadcrumb style={{ padding: "10px" }}>
      <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
      {/* <Breadcrumb.Item href="/templates"><span>Templates</span></Breadcrumb.Item> */}
      <Breadcrumb.Item><Link className='a' to={ROOT_PATH}><Icon icon="chalkboard" size="1x" /><b> Threads</b></Link></Breadcrumb.Item>
    </Breadcrumb>

    <Row className='nowrap'>
        <Col><ThreadsLeftMenu /></Col>
        <Col flex="auto">
          <Switch>
            <Route exact={true} path={`${ROOT_PATH}`} component={ThreadsDashboard} />
            <Route exact={true} path={`${ROOT_PATH}/tickets/history`} component={TicketHistory} />
            <Route exact={true} path={`${ROOT_PATH}/ticket/:id`} component={TicketDisplay} />

            <Route exact={true} path={`${ROOT_PATH}/channels/history`} component={ChannelsHistory} />
            <Route exact={true} path={`${ROOT_PATH}/channel/:id`} component={ChannelDetails} />

            <Route exact={true} path={`${ROOT_PATH}/todo/history`} component={TodoHistory} />
            <Route exact={true} path={`${ROOT_PATH}/todo/:id`} component={TodoDetails} />

            <Route key={999} component={E404} />
          </Switch>
        </Col>
    </Row>
  </ContentArea>)
}

const mapStateToProps = ({ ep_admin: { user } }) => {
  return ({ user });
}
export default connect(mapStateToProps, null)(ThreadsHome)
