import React, { useState } from 'react'
import { Button, DataRow, Heading, IconButton } from 'Common/components'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Alert, Col, Row, message } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper';
import { verifyRole } from 'Common/scripts/Security';

const EDIT_CLIENT = loader('src/Graphql/client/editClient.graphql');

// verifyRole('guard.sensitive-data')


const BillingInfoForm = withApollo(({ initialValues, onUpdate, onCloseClick, client }) => {
    const [error, seteError] = useState(false);

    const onSubmit = async (values) => {
        let input = {
            _id: initialValues._id,
            billing_name: values.billing_name,
            billing_email: values.billing_email,
            billing_phone: values.billing_phone,
            billing_add: values.billing_add,
            billing_city: values.billing_city,
            billing_state: values.billing_state,
            billing_zip: values.billing_zip,
            billing_country: values.billing_country,

        }
        // console.log({ input })

        let resutls = await client.mutate({ mutation: EDIT_CLIENT, variables: { input } }).then(r => (r.data.editClient))
            .catch(err => {
                console.log(__error("ERROR: "), err);
                return { error: { message: "Failed to process the request at the moment!" } }
            })

        if (resutls.error) {
            message.error(resutls.error.message);
            return false;
        }

        onUpdate(resutls);
        return false;
    }

    return (<>
        <FormComponent onSubmit={onSubmit} id='ClientForm' fields={initialValues} debug={false}
            form_render={formProps => {
                const { values, form, submitting, invalid, dirty, valid } = formProps;

                return (<>
                    {error && <Alert message="Error!" description={error} type="error" showIcon />}

                    <FormField label="Name" type="text" name="billing_name" size="medium" />
                    <FormField label="Email" type="text" name="billing_email" size="medium" />
                    <FormField label="Phone" type="text" name="billing_phone" size="medium" />
                    <FormField label="Address" type="text" name="billing_add" size="medium" />
                    <FormField label="City" type="text" name="billing_city" size="medium" />
                    <FormField label="State" type="text" name="billing_state" size="medium" />
                    <FormField label="Zip" type="text" name="billing_zip" size="medium" />
                    <FormField label="Country" type="text" name="billing_country" size="medium" />

                    <Row style={{ marginTop: '20px', marginRight: '10px' }}>
                        <Col flex="auto"></Col>
                        <Col>
                            <Button type="primary" size="medium" block htmlType='submit' loading={submitting} disabled={!valid}>Save</Button>
                        </Col>
                    </Row>

                </>)
            }}
        />

    </>)
});

const ReadOnlyBillingInfo = ({ data }) => {
    return (<>
        {data.billing_name && <DataRow col1="Name" col2={data.billing_name} inline size={['150px']} />}
        {data.billing_email && <DataRow col1="Email" col2={data.billing_email} inline size={['150px']} />}
        {data.billing_phone && <DataRow col1="Phone" col2={data.billing_phone} inline size={['150px']} />}
        {data.billing_add && <DataRow col1="Address" col2={data.billing_add} inline size={['150px']} />}
        {data.billing_city && <DataRow col1="City" col2={data.billing_city} inline size={['150px']} />}
        {data.billing_state && <DataRow col1="State" col2={data.billing_state} inline size={['150px']} />}
        {data.billing_zip && <DataRow col1="Zip" col2={data.billing_zip} inline size={['150px']} />}
        {data.billing_country && <DataRow col1="Country" col2={data.billing_country} inline size={['150px']} />}
    </>)
}

export default function BillingInfo({ data, onUpdate }) {
    const [edit, set_edit] = useState(false);

    const onDataUpdate = (values) => {
        onUpdate(values);
        set_edit(false);
    }

    return (<>
        {/* <h3 style={{ marginBottom: "0px" }}>Billing Info <IconButton onClick={() => set_edit(!edit)} icon={edit ? "times" : "pen"} /></h3> */}
        <Heading>Billing Info <IconButton onClick={() => set_edit(!edit)} icon={edit ? "times" : "pen"} /></Heading>

        {edit && <BillingInfoForm initialValues={data} onUpdate={onDataUpdate} onCloseClick={() => set_edit(false)} />}
        {!edit && <ReadOnlyBillingInfo data={data} />}

    </>)
}
