import React, { Component } from 'react'
import { Popconfirm, Alert, message, Row, Col, Collapse, Tag } from 'antd';
import _ from 'lodash';
import { loader } from 'graphql.macro';
import { testStatus } from 'configs'
import { Form, Field } from 'react-final-form'
import { FormField } from 'Common/components/Form';
import { Button, Loader, Icon, DevBlock } from 'Common/components';
import ReactToPrint from 'react-to-print';
import { ContentArea } from "Layout_v1";
import { mongoToDate, parseJson, parseNewLine } from 'Common/scripts/Functions';
import Notes from 'Modules/Notes';
import { withApollo } from '@apollo/client/react/hoc';

const { Panel } = Collapse;
const fieldKey = "::INPUT_FIELD::";

const UPDATE_STATUS = loader('src/Graphql/TestResults/updateTestResultStatus.graphql');


const FormsDataView = ({ data, client }) => {
    if (!data?._id) return <p>Missing Result ID</p>;
    if (!data?.test_form?._id) return <p>Missing Form ID</p>;

    const ref = React.createRef();

    const onStatusSubmit = (values) => {
        const variables = {
            id: data._id,
            status: values.status
        }

        client.mutate({ mutation: UPDATE_STATUS, variables }).then(e => {
            const response = e.data.updateTestResultStatus;
            if (response.error) return message.error(response.error.message);
            message.success(`Status updatd to ${values.status}`)
        }).catch(err => {
            console.log("ERROR: ", err, "\n", err);
            message.error("Request Error!");
            // this.setState({ busy: false, error: "Query Error" });
        })
    }

    const renderSteps = args => {
        return data.test_form.sections.map((section, i) => {
            let thisResult = data.steps.find(o => o._id == section._id);

            var fill_options = []
            let num = 0;

            return (<div key={i}>
                <h3 style={{ marginTop: "30px", marginBottom: "0px" }}>
                    {section.title}
                    <span style={{ fontSize: "14px", marginTop: "5px" }}>
                        {thisResult && thisResult.status != 'incomplete' &&
                            <div>{thisResult.score}/{thisResult.total} -- {Math.round(thisResult.percent)}% <b style={{ color: thisResult.status == 'pass' ? 'green' : 'red', textTransform: "uppercase" }}>{thisResult.status}</b></div>
                        }
                        {/* Passing score: {section.passing_percent}% */}
                        {/* Score: {step.score}/{step.total} <span style={{ margin: "0 20px" }}>{step.percent}%</span> */}
                        {/* <span style={{fontSize:'12px', color:'black'}}>(time: {step.time_spent})</span> */}
                        {/* <span style={{ marginLeft: "20px", color: 'red' }}>Fail Attempts: {step.attempts.length}</span> */}
                    </span>
                </h3>

                {section.fields.map((field, ii) => {
                    let thisFieldResult = thisResult && thisResult.fields.find(o => o._id == field._id);

                    if (field.type == 'heading') return <Row align="center" key={ii}><Col align="center"><h3 key={ii} style={{ color: "black" }}>{field.title}</h3></Col></Row>
                    if (field.type == 'text') return <div key={ii}>{parseNewLine(field.title)}</div>
                    if (field.type == 'link') return <p key={ii}><a href={field.title} target="_blank">{field.title} <Icon icon="external-link-alt" /></a></p>

                    if (field.type == 'text-input' || field.type == 'long-text-input' || field.type == 'radio' || field.type == 'fill-in-the-blanks') {
                        num++;
                    }

                    if (field.type == 'text-input') {
                        return <div key={ii} style={{ marginBottom: "20px" }}>
                            <div style={{ fontSize: "16px", fontWeight: "bold" }}>Q {num}: {thisFieldResult ? thisFieldResult.label : ""}</div>
                            <div>{thisFieldResult ? thisFieldResult.value : ""}</div>
                        </div>
                    }
                    if (field.type == 'long-text-input') {
                        return <div key={ii} style={{ marginBottom: "20px" }}>
                            <div style={{ fontSize: "16px", fontWeight: "bold" }}>Q {num}: {thisFieldResult ? thisFieldResult.label : ""}</div>
                            <div>{thisFieldResult ? thisFieldResult.value : ""}</div>
                        </div>

                    }

                    let question = field.question;
                    let question_parts = []
                    if (question.indexOf(fieldKey) > -1) question_parts = question.split(fieldKey);

                    let correct_answer = [];
                    if (field.type == 'fill-in-the-blanks' && thisFieldResult) {
                        // create array for options text, this will be used in the option table at bottom
                        fill_options = fill_options.concat(field.options.map(o => {
                            if (fill_options.findIndex(_o => (_o.text == o.text)) > -1) return null;
                            return { text: o.text, _id: o._id, q: ii }
                        })).filter(o => o != null)

                        correct_answer.push({ qnum: ii, text: field.options[field.correct_answer].text })
                    }

                    let isCorrect = thisFieldResult && thisFieldResult.isCorrect == 'yes'; //thisFieldResult.value == opt.text && defaultCorrect;

                    return <div key={ii} style={{ marginBottom: "20px" }}>
                        {(field.type == 'radio') &&
                            <div style={{ fontSize: "16px", fontWeight: "bold" }}>Q {num}: {question}</div>
                        }
                        {(field.type == 'fill-in-the-blanks') &&
                            <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                                Q {num}: <span>{question_parts[0]}</span> <span style={{ padding: "0 20px", borderBottom: "2px solid #999", color: isCorrect ? 'green' : 'red' }}>{thisFieldResult && thisFieldResult.value}</span> <span>{question_parts[1]}</span>
                            </div>
                        }

                        {(field.type == 'radio') && field.options.map((opt, oi) => {
                            let defaultCorrect = (field.correct_answer == oi);
                            let isSelected = thisFieldResult && thisFieldResult.value == opt.text;
                            let isThisCorrect = thisFieldResult && thisFieldResult.isCorrect == 'yes';

                            return (<div key={oi}>
                                <Row key={oi} gutter={[8]} style={{ marginBottom:"5px" }}>
                                    <Col style={{ border: `2px solid ${defaultCorrect ? 'green' : 'white'}`, borderRadius:"50%" } }>
                                        <Icon icon={isSelected ? 'check-circle' : 'circle'} color={(isThisCorrect && isSelected) ? 'green' : isSelected ? 'red' : '#DDD'} />
                                    </Col>
                                    <Col>{opt.text}</Col>
                                </Row>
                            </div>)
                        })}

                    </div>
                })}


                {/* Fill in the blanks options table */}
                {(fill_options && fill_options.length > 0) &&
                    <Row style={{ margin: "10px 0 20px 0" }}>
                        {fill_options.map((opt, i) => {

                            let qnum = -1;
                            section.fields.find((o, index) => {
                                if (o.type == "fill-in-the-blanks" && o.options[o.correct_answer].text == opt.text) {
                                    {/* console.log(`Question: ${index + 1} : ${opt.text}`) */ }
                                    qnum = index + 1;
                                }
                            })

                            return <Col flex="25%" key={i} style={{ border: '1px solid #999', padding: "5px", textAlign: "center" }}>
                                {opt.text}
                                {qnum > -1 && <span style={{ color: "blue" }}> (Q{qnum})</span>}
                            </Col>
                        })}
                    </Row>
                }



            </div>)
        })

    }

    const renderSummary = () => {
        return (<>
            {data.steps.map((step, i) => {
                let thisSection = data.test_form.sections ? data.test_form.sections.find(o => {
                    return o._id == step._id
                }) : {}

                if (!thisSection) return <p key={i}>Section not found! {step._id}</p>;

                return (<div style={{ border: "1px solid #EEE", margin: "0", padding: "10px" }} key={i}>
                    <div style={{ fontWeight: "bold", fontSize: "16px" }}>{i + 1}) {thisSection.title}</div>
                    <Row>
                        <Col flex="33%"><div>Score: {step.score}/{step.total} <span style={{ margin: "0 20px" }}>{step.percent}%</span></div></Col>
                        <Col flex="auto" align="center">
                            {/* <div style={{ fontSize: '12px', color: 'black' }}>(time: {step.time_spent})</div> */}
                        </Col>
                        <Col flex="33%" align="right">
                            <div>Fail Attempts: {step.attempts.length}</div>
                            <div>Status: <span style={{ fontWeight: "bold", color: step.status != 'fail' ? "green" : "red" }}>{step.status}</span></div>
                        </Col>
                    </Row>
                </div>)


            })}

        </>)
    }

    // const renderAttendanceSheet = () => {
    //     return (<div style={{ border: "0px solid green" }}>
    //         <AttendanceForm preview formData={{ ...data }} role="admin" />
    //     </div>)
    // }

    const test_totals = () => {
        let total_score = 0;
        let total_numbers = 0;

        data.steps.forEach(step => {
            total_numbers += step.total;
            total_score += step.score;
        });

        let total_percent = Math.ceil((total_score / total_numbers) * 100);

        return <>{total_score}/{total_numbers} | {total_percent}%</>
    }

    return (<>
        <Row style={{ flexWrap: "nowrap" }}>
            <Col flex="auto">
                <ContentArea>
                    <Row>
                        <Col flex="auto"><b>Test Results</b></Col>
                        <Col style={{ color: '#999' }} align="right">
                            <div>ID: {data._id}</div>
                            {mongoToDate(data.created_at).format('Do MMM YYYY - HH:mm')}
                        </Col>
                    </Row>

                    <h1 style={{ marginBottom: 0, marginTop: '0px', fontSize: "28px" }}>
                        <span>{data.test_form.title}</span>
                        {/* <div style={{ color: data.test_result == 'pass' ? "green" : "red", fontWeight: "bold", fontSize:"18px", paddingTop:"10px" }}>{data.test_result}</div> */}
                    </h1>

                    <Row>
                        <Col flex="auto">
                            {data.candiateInfo && <>
                                <div><b>Name:</b> {data.candiateInfo.name}</div>
                                <div><b>Phone No.:</b> {data.candiateInfo.phone_number}</div>
                                <div><b>License No.:</b> {data.candiateInfo.lc_number}</div>
                            </>}
                            {String(data.test_result).toLowerCase() != 'none' &&
                                <div style={{ color: data.test_result == 'pass' ? "green" : "red", fontWeight: "bold", fontSize: "18px", paddingTop: "10px", textTransform: "capitalize" }}>{data.test_result}</div>
                            }
                        </Col>
                        <Col>
                            {data?.attendanceSheet?.student_signature &&
                                <span style={{ border: "1px solid #EEE", display: "inline-block" }}><img src={data.attendanceSheet.student_signature} width="200px" height="100px" /></span>
                            }
                        </Col>
                    </Row>

                    <Collapse accordion defaultActiveKey={['1']}>
                        <Panel header="Summary" key="1">
                            {renderSummary()}
                        </Panel>
                        <Panel header="Test Details" key="2">
                            <Row>
                                <Col flex="auto" />
                                <Col>
                                    <ReactToPrint
                                        trigger={() => {
                                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                            // to the root node of the returned component as it will be overwritten.
                                            return <a href="#" style={{ border: "1px solid blue", borderRadius: "5px", padding: "5px 10px" }}><Icon icon="print" /> Print this out!</a>;
                                        }}
                                        content={() => ref.current}
                                    />
                                </Col>
                            </Row>
                            <div style={{ height: "20px" }} />

                            <div ref={ref} className="printPage">
                                {/* {renderAttendanceSheet()} */}
                                {/* <div style={{ height: "50px" }} /> */}

                                <Row>
                                    <Col flex="auto">
                                        {data.candiateInfo && <>
                                            <div style={{ fontWeight: "bold", fontSize: "22px" }}>Test: {data.test_form.title}</div>
                                            <div><b>Name:</b> {data.candiateInfo.name}</div>
                                            <div><b>Phone No.:</b> {data.candiateInfo.phone_number}</div>
                                            <div><b>License No.:</b> {data.candiateInfo.lc_number}</div>
                                        </>}
                                        {String(data.test_result).toLowerCase() != 'none' &&
                                            <div>
                                                <span style={{ color: data.test_result == 'pass' ? "green" : "red", fontWeight: "bold", fontSize: "18px", textTransform: "capitalize" }}>{data.test_result}</span>
                                                <span style={{ marginLeft: "10px", paddingLeft: "10px", borderLeft: "1px solid #999" }}>{test_totals()}</span>
                                            </div>
                                        }
                                    </Col>
                                    <Col>
                                        {data?.attendanceSheet?.student_signature && <>
                                            <span style={{ borderBottom: "1px solid #EEE", display: "inline-block" }}><img src={data.attendanceSheet.student_signature} width="200px" height="100px" /></span>
                                            <div style={{ textAlign: "center" }}>Student Signature</div>
                                        </>}
                                    </Col>
                                </Row>

                                <hr color="#EEE" />
                                {renderSteps()}

                            </div>

                        </Panel>
                    </Collapse>


                </ContentArea>

            </Col>
            <Col flex="30%" style={{ maxWidth: '350px' }}>
                <ContentArea style={{ minHeight: "10px" }}>

                    <Form onSubmit={onStatusSubmit} initialValues={{ status: data.status }} validate={(__values) => (false)}>
                        {({ handleSubmit, submitting, form, values, invalid, dirty, valid }) => (<>
                            <form className="form_component" onSubmit={handleSubmit}>
                                <Row align="bottom">
                                    <Col flex="auto"><FormField name="status" type="select" size="medium" placeholder="SELECT" label="Status" data={testStatus} /></Col>
                                    <Col style={{ paddingBottom: "10px" }}><Button type="primary" size="medium" htmlType='submit' disabled={!dirty || !valid} >Save </Button></Col>
                                </Row>
                            </form>
                        </>)}
                    </Form>

                </ContentArea>
                <ContentArea style={{ minHeight: "10px" }}><Notes id={data._id} formkey={'test'} /></ContentArea>

            </Col>

        </Row>
    </>)

}
export default withApollo(FormsDataView);