import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Alert, Row, Col, Popconfirm } from 'antd';
import { DevBlock, IconButton, Loader, Table } from 'Common/components';
import { withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper'
import Search from 'antd/lib/input/Search';
// import { connect } from "react-redux";


const LIST_DATA = loader('src/Graphql/FormsData_BlackList/formsData_BlackLists.graphql');
const DELETE = loader('src/Graphql/FormsData_BlackList/deleteFormsData_BlackList.graphql');


class FormDataBlacklistComp extends Component {
    state = { loading: false, error: null, data: null, kw:null };
    constructor(props){
        super(props);
        this.onDelete = this.onDelete.bind(this);
        this.setKw = this.setKw.bind(this);
    }

    componentDidMount(){
        const { ref_key } = this.props;
        if (!ref_key) return this.setState({ error:"Missing ref_key" });

        let filter = { ref_key }

        this.props.client.query({ query: LIST_DATA, variables: { filter: JSON.stringify(filter) } }).then(r => {
            const response = r.data.formsData_BlackLists;

            if (!response || response.error) {
                this.setState({ loading: false, error: !response ? "Invalid response" : response.error.message });
                return;
            }
            this.setState({ loading: false, data: response });

        }).catch(err => {
            console.log(__error("ERROR: "), err);
            this.setState({ loading: false, error: "Unable to process request at the moment!" });
        })


    }

    async onDelete(id){
        const resutls = await this.props.client.mutate({ mutation: DELETE, variables: { id } }).then(r => {
            return r.data.deleteFormsData_BlackList;
        }).catch(err => {
            console.log(__error("ERROR: "), err);
            this.setState({ loading: false, error: "Unable to process request at the moment!" });
        })

        if (!resutls || resutls.error){
            return this.setState({ loading: false, error: !resutls ? "Invalid response" : resutls.error.message });
        }

        let data = this?.state?.data?.slice() || [];
            data = data.filter(o => o._id != id);

        this.setState({ loading: false, data });

    }

    setKw(e){
        let kw = e.target.value;
        this.setState({ kw });
    }

    columns = [
        { title: 'Title', dataIndex: 'title' },
        { title: 'Record type', dataIndex: 'field', render:(__, rec)=> rec.field.replaceAll("_", " "), width:"150px" },
        { title: 'Value', dataIndex: 'value' },
        {
            title: '', width: "50px", align: 'center', render: (___, rec) => {
                return <>
                    <Popconfirm onConfirm={() => this.onDelete(rec?._id)} title="Are you sure？">
                        <IconButton icon={{ icon: 'trash-alt' }}></IconButton>
                    </Popconfirm>
                </>
            }
        },
    ];


    render() {
        const { ref_key } = this.props;
        const { loading, error, data, kw } = this.state;

        if (error) return <Alert message={error} type="error" />

        // filter records
        let filteredData = kw ? data.filter(o=>{
            let found = false;
            if (o.title.indexOf(kw) > -1) found = true;
            if (o.value.indexOf(kw) > -1) found = true;

            return found;
        }) : data;

        return (<>
            <Table loading={loading}
                title={() => {
                    return (<div>
                        <Row align="middle">
                            <Col>
                                <div style={{ fontSize: "18px", fontWeight: "bold" }}>Guard Black List</div>
                                <div style={{ fontSize: "12px" }}>Total {((data && data.length) || '0')} record(s)</div>
                            </Col>
                            <Col flex="auto" />
                            <Col>
                                <Search placeholder="input search text"
                                    // onSearch={console.log}
                                    onChange={this.setKw}
                                    style={{ width: 200, }}
                                />
                            </Col>
                        </Row>
                    </div>)
                }}
                columns={this.columns}
                dataSource={filteredData || null}
                pagination={false}
                // onChange={this.handleTableChange}
                // rowClassName={(record => record.status == 'new' ? 'hilighted-table-row' : "")}
            />
        </>)
    }
}
FormDataBlacklistComp.propTypes = {
    ref_key: PropTypes.string.isRequired,
}

export const FormDataBlacklist = withApollo(FormDataBlacklistComp);
export default FormDataBlacklist;

