import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
import debounce from 'lodash/debounce';
import { FormField } from './Form'
import { __error } from 'Common/scripts/consoleHelper'
import { withApollo } from '@apollo/client/react/hoc';

const LEADS = loader('src/Graphql/leads/leads.graphql');


const LeadSearchComp = props => {
    const [list, setList] = React.useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = React.useState({});
    const [__filter, setFilter] = React.useState(null);

    useEffect(() => {
        _fetchData(true);
    }, [props.preload, props.filter])

    const _fetchData = async (value) => {
        if ((!value || value.length < 1) && !(value === true)) return;

        var filter = { };

        if (value === true) {
            // setState({ kw: null, loading: true })
        }
        else{
            Object.assign(filter, { search: { keywords: value } })
            // setState({ kw: value, loading: true })
        }
        filter = JSON.stringify({ ...filter, ...props.filter });

        if (__filter == filter) return;
        setFilter(filter)
        setState({ kw: (value === true) ? null : value, loading: true })
    
        const resutls = await props.client.query({ query: LEADS, variables: { filter } }).then(e => {
            if (e.error || e.errors) {
                console.log(__error("ERROR "), e);
                return { error: { message: (e.error && e.error.message) || (e.errors && e.errors[0].message) }}
            }
            return e.data.leads;
        }).catch(err => {
            console.log(__error("Request ERROR: "), err);
            return { error: { message:"Request ERROR" } }
        })

        if (resutls.error){
            message.error(resutls.error.message);
            setState({ kw: value, loading: false });
            return;
        }
        setList(resutls.map(o => ({ _id: o._id, title: o.title, raw:o })));
        setState({ loading: false });

    }
    const fetchData = debounce(_fetchData, 800);


    return (<>
        <FormField 
            width={props.width}
            allowClear
            validate={props.validate}
            // type={props.multi ? 'select-multiple' : "select" }
            type={props.mode == 'multiple' ? 'select-multiple' : 'select'}
            name={props.name}
            label={props.label}
            data={list}
            placeholder="Search leads"
            addNull={props.addNull || false}
            keyMap={props.keyMap} //"value=>text"
            disabled={props.disabled}
            onSelect={props.onSelect}
            // onChange={handelChange}
            // defaultValue={props.defaultValue && props.defaultValue._id}
            inputProps={{
                // onSelect: props.onSelect || undefined, // console.log,
                onDeselect: props.onDeselect || undefined, // console.log,
                loading:state.loading,
                // mode: props.mode || undefined, // tags, multiple
                showSearch: true,
                defaultActiveFirstOption: false,
                showArrow: true,
                notFoundContent: state.loading ? <Spin size="small" /> : null,
                filterOption: false,
                onSearch: fetchData,
                // onChange:handelChange,
            }}
        />
    </>)
}
LeadSearchComp.propTypes = {
    mode: PropTypes.string,
    filter: PropTypes.object,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValues: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    disabled: PropTypes.bool,
}

export const LeadSearch = withApollo(LeadSearchComp);
export default LeadSearch;