import React from 'react';
import { addModule, addReducers } from '../connector';
import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT } from './constants'
import { __error, __success } from 'Common/scripts/consoleHelper';
import Home from './Home';
// import Users from './containers/Users';
// import UsersDetails from './containers/UsersDetails';
// import Attendance from './containers/Attendance';

export const modulePermissions = getmodulePermissions('zkt_att');

if (modulePermissions['zkt_att.access']) {
  // console.log(__success("zkt_att.access"))
  addModule(
    { path: `${ROOT}`, component: Home, icon: 'calendar-alt', title: "Attendance", exact: false, leftNav: true },
  )
  // addModule({ path: `${ROOT}/users`, component: Users, title: "Users", exact: true, leftNav: false })
  // addModule({ path: `${ROOT}/users/:id`, component: UsersDetails, title: "UsersDetails", exact: true, leftNav: false })
  // addModule({ path: `${ROOT}/attendance`, component: Attendance, title: "Attendance", exact: true, leftNav: false })
  // addModule(
  //   { path: `${ROOT}/conversation/:phone`, component: SMSConversation, title: "SMS Conversation", exact: true, leftNav: false },
  // )
}else{
  // console.log(__error("zkt_att.access"))
}
