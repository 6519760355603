import React, { Component, useState, useEffect } from 'react'
// import { graphql, Subscription, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Popconfirm, Alert, message, Row, Col, Modal, Breadcrumb } from 'antd';
import { Loader, Icon, Button, IconButton, Table, Avatar, DevBlock } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper';
import { dateToMongo, mongoToDate } from 'Common/scripts/Functions';
import { HomeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { defaultDateFormat, defaultDateTimeFormat, uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import { useSubscription } from '@apollo/client';
import { ContentArea } from 'Layout_v1';


const GET_UPDATES = loader('src/Graphql/system_updates/getSystemUpdates.graphql');
const START_UPDATES = loader('src/Graphql/system_updates/startSystemUpdates.graphql');
const QUERY_SUBSCRIPTION = loader('src/Graphql/system_updates/subscription.graphql');
// systemUpdatesUpdated: { mutation, node, message }

const DataRow = ({ title, date, onClick, data }) => {
    let diff = data.start_date ? mongoToDate(data.end_date).diff(mongoToDate(data.start_date), 'minutes') : '0';

    return <div style={{ margin: "20px 0", padding: "0 10px", border: "1px solid #EEE", borderRadius: "5px" }}>
        <Row gutter={[10]} align="middle">
            <Col style={{ fontWeight: "bold", fontSize: "16px", minWidth: "400px" }}>{(data && data.title) || title}</Col>
            <Col style={{ padding:"5px" }}><Button onClick={onClick}>Start Update</Button></Col>
            <Col>{(data && data._id && `${mongoToDate(data.start_date).format(defaultDateTimeFormat)} ~ ${mongoToDate(data.end_date).format(defaultDateTimeFormat) }`) || date}</Col>
            <Col style={{ fontWeight:"bold" }}>{diff} min</Col>
        </Row>
    </div>
}

const SystemUpdates = ({ client }) => {
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [notes, setNotes] = useState(null);

    const startSystemUpdates = async (type) => {
        setBusy(true);
        setNotes(null);
        setError(null);
        var start_date = moment();

        const resutls = await client.mutate({ mutation: START_UPDATES, variables: { type }, fetchPolicy: "no-cache" })
            .then(r => {
                return r.data.startSystemUpdates;
            }).catch(err => {
                console.log(__error("Query ERROR: basket release : "), err);
                return { error: { message: "Request ERROR" } }
            })

        let sec_diff = moment().diff(start_date, 'seconds');
        let min_diff = moment().diff(start_date, 'minutes');
        let hrs_diff = moment().diff(start_date, 'hours');

        let diff = ""
        if (hrs_diff > 0) diff = `${hrs_diff} hrs`
        else if (min_diff > 0) diff = `${min_diff} min`
        else diff = `${sec_diff} sec`

        // message.info(`Query completed in ${diff}`);
        setNotes(`Query completed in ${diff}`)

        setBusy(false);
        if (resutls.error) {
            setError(resutls.error.message);
            console.log(__error("ERROR "), resutls)
            message.error(resutls.error.message);
            return;
        }

        message.success(`${type} Completed`)

    }

    const getSystemUpdates = async args => {
        setBusy(true);
        setError(null);

        const resutls = await client.query({ query: GET_UPDATES }).then(r => {
            return r.data.getSystemUpdates;
        }).catch(err => {
            console.log(__error("Request ERROR: "), err);
            return { error: { message: "Request ERROR" } }
        })

        setBusy(false);
        if (resutls.error) {
            setError(resutls.error.message);
            console.log(__error("ERROR "), resutls)
            message.error(resutls.error.message);
            return;
        }

        setData(resutls);

    }

    useEffect(() => {
        getSystemUpdates()
    }, [client])

    return (<Loader loading={busy}>
        {notes && <Alert message={notes} type='info' showIcon />}
        {error && <Alert message={error} type='error' showIcon />}
     
        <DataRow
            data={(data && data?.find(o => o.type == 'flush-guard-applications')) || { title: "Flush Guard Applications", date: defaultDateTimeFormat }}
            onClick={() => startSystemUpdates('flush-guard-applications')}
        />
        <DataRow
            data={(data && data?.find(o => o.type == 'migrate-guard-applications')) || { title: "Migrate Guard Applications", date: defaultDateTimeFormat }}
            onClick={() => startSystemUpdates('migrate-guard-applications')}
        />
        <DataRow
            data={(data && data?.find(o => o.type == 'mark-duplicate-guard-applications')) || { title: "Mark Duplicate Guard Applications", date: defaultDateTimeFormat }}
            onClick={() => startSystemUpdates('mark-duplicate-guard-applications')}
        />
        <DataRow
            data={(data && data?.find(o => o.type == 'fix-invalid-sin')) || { title: "Ffix Invalid SIN", date: defaultDateTimeFormat }}
            onClick={() => startSystemUpdates('fix-invalid-sin')}
        />
        <DataRow
            data={(data && data?.find(o => o.type == 'reset-super-admin-permissions')) || { title: "Reset Super Admin Permissions", date: defaultDateTimeFormat }}
            onClick={() => startSystemUpdates('reset-super-admin-permissions')}
        />

    </Loader>)

}



const SystemUpdatesWrapper = ({ client }) => {
    const { data, error, loading, variables } = useSubscription(
        QUERY_SUBSCRIPTION,
        { variables: { } }
    );

    if (!loading){
        console.log("Subscription response received...", { data, error, loading, variables })
    }
    if (!data && !error) console.log("Subscription is ready");
    else if (error) {
        console.log(__error("Subscription error: "), error)
        return <Alert message={error.message} type="error" showIcon />
    }
    else if (data) {
        if (data?.systemUpdatesUpdated?.message) {
            console.log("data.systemUpdatesUpdated.message: ", data.systemUpdatesUpdated.message)
            // return <Alert message={data.systemUpdatesUpdated.message} type="info" showIcon closable />
        }

        console.log("{JSON.stringify({ data })}: ", JSON.stringify({ data }))
    }


    return (<>
        <Breadcrumb style={{ padding: "10px" }}>
            <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
            <Breadcrumb.Item><Icon icon="map-marker-alt" size="1x" /><b> System Updates</b></Breadcrumb.Item>
        </Breadcrumb>

        <ContentArea>
            {/* {loading && <Loader loading={true}>checking subscription</Loader>} */}
            <SystemUpdates client={client} />
        </ContentArea>

    </>)
}


export default withApollo(SystemUpdatesWrapper);