import React, { Component, useState, useEffect } from 'react'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Button, Icon, SiteSearch, GuardSearch } from 'Common/components'
import { message, Row, Col } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { testStatus, testResults } from 'configs'
import { connect } from "react-redux";
import { updatePageSettings } from 'Store/ep_admin.actions.js'
import { GUARD_APPLICATION_FORM, CLIENT_INFO_FORM } from '../constants'
import { formArray } from '..';
import { object } from 'prop-types';

// const ref_keys = formArray.map(o => ({ _id: o.key, title: o.menuTitle }));


const FormDataSearchBar = props => {
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);

    // const defaultFilter = { ...props?.ep_admin?.pageSettings?.formdata_searchBar };

    useEffect(() => {
        onSubmitSearch({ ...props?.ep_admin?.pageSettings?.formdata_searchBar })
    }, [])


    const onSubmitSearch = (values, redux) => {
        const filter = { ...values, ...props.defaultFilter }

        setBusy(true);

        props.fetchData({ filter }).then(r => {
            setBusy(false);
        });

        props.updatePageSettings({
            page: 'formdata_searchBar',
            values: {
                ...values
            }
        })
    }

    if(props.hide) return null;

    return (<>

        <FormComponent onSubmit={onSubmitSearch} id='FormDataSearch' loading={busy} debug={false}
            fields={{ ...props.ep_admin?.pageSettings?.formdata_searchBar }}
            form_render={formProps => {
                const { values, form, invalid, dirty, valid } = formProps;

                return (<Row align="top">
                    {/* <Col flex="250px"><FormField label="Record Category" name="ref_key" placeholder={"Select Record Category"} size="medium" type="select" 
                        data={formArray.map(o => ({ _id: o.key, title: o.menuTitle }))} 
                        compact allowClear /></Col> */}

                    <Col><FormField label="Keyword" name="search.keywords" placeholder={"Keyword search.."} size="medium" type="text" allowClear compact /></Col>

                    {/* {(!props.exclude || props.exclude.indexOf("status")<0) &&
                        <Col flex="120px"><FormField label="Status" name="status" size="medium" type="select" 
                            data={testStatus.filter(o=>{
                                if (o._id != 'archived' && o._id != 'hired' && o._id != 'training') return true;
                                return false;
                            })} 
                            allowClear compact /></Col>
                    } */}
                    
                    {(!props.exclude || props.exclude.indexOf("test_result")<0) &&
                        <Col flex="120px"><FormField label="Result" name="test_result" size="medium" type="select" data={testResults} allowClear compact /></Col>
                    }

                    <Col><Button style={{ marginTop: "16px" }} type="primary" htmlType='submit'><Icon icon="search" /></Button></Col>
                </Row>)
            }}
        />

    </>)


}
const mapStateToProps = ({ ep_admin }) => {
    // console.log("ep_admin: ", ep_admin.pageSettings.formdata_searchBar)
    return ({ ep_admin });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormDataSearchBar)

