import React from 'react';
import { addModule, addReducers } from '../connector';
import { isSuperAdmin } from 'Common/scripts/Security';
import { ROOT } from './constants'
import { uriRoot } from 'configs';

import NotificationTest from './NotificationTest'
import Home from './Home';


var subMenuArray = [
  { path: `${uriRoot}${ROOT}/notification`, exact: true, title: 'Notification Test', component: NotificationTest },
  // { path: `${uriRoot}${ROOT}/pdf`, exact: true, title: 'PDF', component: PDFPage },
]
export const SubMenu = props => subMenuArray;


// if (isSuperAdmin()){
//   addModule(
//     {
//       path: `${ROOT}`, component: Home, icon: 'test', title: "TEST", exact: false, leftNav: true,
//       subMenu: SubMenu()
//     },
//   )
// }

