import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Breadcrumb, Col, Row } from 'antd';
import { Icon } from 'Common/components';
import { connect } from "react-redux";
import { HomeOutlined } from '@ant-design/icons';
import { uriRoot } from 'configs';
import { ROOT } from './constants';
import { Route, Switch } from 'react-router-dom';
import { E404 } from "Layout_v1";
import AttendanceDashboard from './containers/AttendanceDashboard';


class ZKTHome extends Component {
    render() {
        return (<>
            <Row>
                <Col flex="auto">
                    <Breadcrumb style={{ padding: "10px" }}>
                        <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                        <Breadcrumb.Item><Icon icon="calendar-alt" size="1x" /><b> ZKT Attenance</b></Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                {/* <Col style={{ padding: "10px" }}>
                    <Row gutter={[10, 10]}>
                        <Col><Link to={`${uriRoot}${ROOT}`}>Home</Link></Col>
                        <Col><Link to={`${uriRoot}${ROOT}/users`}>Users</Link></Col>
                        <Col><Link to={`${uriRoot}${ROOT}/attendance`}>Attendance Logs</Link></Col>
                    </Row>
                </Col> */}
            </Row>

            <Switch>
                <Route exact={true} path={`${uriRoot}${ROOT}`} component={AttendanceDashboard} />
                {/* <Route exact={true} path={`${uriRoot}${ROOT}/users`} component={Users} />
                <Route exact={true} path={`${uriRoot}${ROOT}/users/:id`} component={UsersDetails} />
                <Route exact={true} path={`${uriRoot}${ROOT}/attendance`} component={Attendance} /> */}
                <Route key={999} component={E404} />
            </Switch>


            {/* <ContentArea transparent style={{padding:"0"}}>
                <Row gutter={[10, 10]}>
                    <Col><Link to={`${ROOT}`}>Home</Link></Col>
                    <Col><Link to={`${ROOT}/users`}>Users</Link></Col>
                    <Col><Link to={`${ROOT}/attendance`}>Attendance logs</Link></Col>
                </Row>

            </ContentArea> */}
        </>)
    }
}


const mapStateToProps = ({ ep_admin }) => {
    return { filter: ep_admin?.pageSettings?.guard_searchBar }
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export default connect(mapStateToProps)(ZKTHome)

