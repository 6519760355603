import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Popconfirm, Alert, message, Row, Col, Menu } from 'antd';
import { Table, Loader, Icon, IconButton, Button, Drawer, TestFormSearch, TestCourseSearch } from 'Common/components';
import { rules, composeValidators, FormField, FormComponent } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
import { loader } from 'graphql.macro';
import { withApollo } from '@apollo/client/react/hoc';

const CREATE_INVITATION = loader('src/Graphql/TestResults/createInvitation.graphql');


const InvitationForm = ({ showDrawer, onClose, client, onSuccess }) => {
    const [busy, setBusy] = React.useState(false);

    const onSubmit = async values => {
        setBusy(true);

        const input = {
            test_id: values.form_id,
            course_id: values.course_id,
            name: values.name || undefined,
            phone_number: values.phone_number || undefined,
            lc_number: values.lc_number || undefined,
            pwd_protect: values.pwd_protect  ? "yes" : 'no',
            password: values.password,
        }

        const results = await client.mutate({ mutation: CREATE_INVITATION, variables: { input } }).then(e => {
            return e.data.createInvitation;
        }).catch(err => {
            console.log(__error("API Call ERROR: clients : "), err);
            // message.error("Request ERROR");
            return { error: { message:"Request ERROR" } }
        })

        setBusy(false);

        if (results.error){
            console.log(__error("ERROR: "), results);
            message.error(results.error.message);
            return;
        }

        onSuccess();
        onClose();
    }
    
    return (<>
        <Drawer width={"600px"} destroyOnClose maskClosable={false} placement="right"
            onClose={() => onClose()}
            visible={showDrawer}
            bodyStyle={{}}
            footer={<>
                <Button block loading={busy} type="primary" onClick={() => {
                    document.getElementById('InvitationCreationForm').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
                }}>Create Test Invitation</Button>
            </>}
            title={'Create Test Invitation'}
        ><>
            <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                <FormComponent onSubmit={onSubmit} id='InvitationCreationForm' loading={false} debug={false} fields={{}} moveOnError
                    mutators={{
                        updateSignature: (newValueArray, state, tools) => {
                            let data = newValueArray[0];
                            tools.changeValue(state, data.name, () => data.action == "add" ? data.data : undefined);
                        },
                    }}

                    form_render={formProps => {
                        const { values, form, invalid, dirty, valid } = formProps;

                        return (<div style={{ flex: 1, display: "flex", flexDirection: "column", width: "100%" }}>
                            <Row gutter={[5]}>
                                <Col span={12}><TestFormSearch name="form_id" label="Select Test" size="large" validate={rules.required} preload /></Col>
                                <Col span={12}><TestCourseSearch name="course_id" label="Select Course (optional)" size="large" preload /></Col>
                                <Col span={12}><FormField label="Candiate Name" name="name" size="medium" type="text" /></Col>
                                <Col span={12}><FormField label="License Number" name="lc_number" size="medium" type="text" /></Col>
                                <Col span={24}><FormField name="pwd_protect" type="checkbox">Password Protect</FormField></Col>
                                {values.pwd_protect && <Col span={12}><FormField label="Password" name="password" type="text" /></Col>}
                            </Row>

                            {/* <Button type="primary" block htmlType='submit' size="large" loading={false}>Save</Button> */}
                        </div>)
                    }}
                />
            </div>
        </></Drawer>

    </>)
}

export default withApollo(InvitationForm)