import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
import debounce from 'lodash/debounce';
import { FormField } from './Form'
import { __error } from 'Common/scripts/consoleHelper'
import _ from 'lodash'
import { withApollo } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';

const CHANNELS = gql`
    query threadsChannels($filter:String, $others:String){
        threadsChannels(filter:$filter, others:$others){
            _id
            title
            # status
            members { _id name }
            # author: UserReff
            # created_at: Date
            # updated_at: Date
        }
    }   
    `
const ChannelSearchComp = props => {
    const [list, setList] = useState(props.defaultValues || []);  // [{_id, title}]
    const [state, setState] = useState({});
    const [changer, setChanged] = useState("");
    const [local_kw, set_local_kw] = useState(null);

    useEffect(() => {
        let changer_val = JSON.stringify([props.preload, props.filter]);
        if (changer_val == changer) return;

        setChanged(changer_val)
        _fetchData(props.preload === true ? undefined : props.preload);
    }, [props.preload, props.filter])


    const _fetchData = async (value) => {
        if ((!value || value.length < 1) && !props.filter && !props.preload) return;

        let filter = { status: 'active' };

        if (value === false || !_.isString(value)) {
            setState({ loading: true })
            if (!_.isString(value)) Object.assign(filter, { ...value })
        } else {
            if (!value || value.length < 1) return;
            setState({ kw: value || undefined, loading: true })
            Object.assign(filter, { search: { keywords: value } })
        }


        filter = JSON.stringify({ ...filter, ...props.filter }); // JSON.stringify({ search: { keywords: value }, ...props.filter });

        const resutls = await props.client.query({ query: CHANNELS, variables: { filter } }).then(e => (e.data.threadsChannels))
        .catch(err => {
            console.log(__error("API Call ERROR: users : "), err);
            return { error: { message:"Request ERROR" } }
        })

        if (resutls.error){
            setState({ loading: false })
            message.error("Request ERROR");
            return;
        }
        else{
            setState({ kw: value, loading: false })
            setList(resutls.map(o => ({ _id: o._id, title: o.title, raw: o })))
        }

    }
    const fetchData = debounce(_fetchData, 800);

    function filterLocalSearch(val) {
        if (!local_kw) return list;
        return list && list?.filter(o => (o.title ?? '').toLowerCase().includes(local_kw.toLowerCase()))
        // return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    }


    return (<>
        <FormField 
            allowClear
            validate={props.validate}
            type={props.mode == 'multiple' ? 'select-multiple' : 'select'}
            name={props.name}
            label={props.label}
            data={filterLocalSearch()} // data={list}
            placeholder="Search Channels"
            addNull={props.addNull || false}
            onSelect={props.onSelect}
            // keyMap="value=>text"
            // onChange={handelChange}
            // defaultValue={props.defaultValue && props.defaultValue._id}
            inputProps={{
                // onSelect: props.onSelect || undefined, // console.log,
                onDeselect: props.onDeselect || undefined, // console.log,
                loading:state.loading,
                mode: props.mode || undefined, // tags, multiple
                showSearch: true,
                defaultActiveFirstOption: false,
                showArrow: true,
                notFoundContent: state.loading ? <Spin size="small" /> : null,
                filterOption: !props.disableSearch, // ? false : filterOption,
                onSearch: props.disableSearch ? set_local_kw : fetchData,
                // onChange:handelChange,
            }}
        />
    </>)
}
ChannelSearchComp.propTypes = {
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValues: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    preload: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    filter: PropTypes.object,
}

export const ChannelSearch = withApollo(ChannelSearchComp);
export default ChannelSearch;