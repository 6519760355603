import React from 'react';
import { addModule, addReducers } from '../connector';
// import FormsDataList from './FormsDataList';
// import FormsDataView from './Forms/ClientQueryForm/preview';
import DataPreview from './Forms/DataPreview';
import GuardApplicationList from './containers/GuardApplicationList'
import ClientInfoList from './containers/ClientInfoList'
import GuardInterviewList from './containers/GuardInterviewList'
import ReportsList from './containers/ReportsList'
import { getmodulePermissions } from 'Common/scripts/Security';
import Home from './Home'
import GuardApplicationForm from './Forms/GuardApplicationForm';
import ClientQueryform from './Forms/ClientQueryForm';
import SecurityGuardInterview from './Forms/SecurityGuardInterview';
import FirewatchLogDoc from './Forms/FirewatchLogDoc';
import GuardEvaluationReport from './Forms/GuardEvaluationReport';

import { ROOT, CLIENT_INFO_FORM, GUARD_APPLICATION_FORM, SECURITY_GUARD_INTERVIEW, FIREWATCH_LOG_DOC, GUARD_EVALUATION } from './constants';

export const formArray = [
  { key: GUARD_APPLICATION_FORM, component: GuardApplicationForm, menuTitle:"Guard Application Form" },
  { key: CLIENT_INFO_FORM, component: ClientQueryform, menuTitle:"Client Query Form" },
  { key: SECURITY_GUARD_INTERVIEW, component: SecurityGuardInterview, menuTitle:"Security Guard Interview Form" },
  { key: FIREWATCH_LOG_DOC, component: FirewatchLogDoc, menuTitle:"Firewatch Log Form" },
  { key: GUARD_EVALUATION, component: GuardEvaluationReport, menuTitle:"Guard Evaluation Form" },
]

export const modulePermissions = getmodulePermissions('form-data-management');


// addReducers({ admins: reducers });
if (modulePermissions['form-data.access']) {
  // addModule(
  //   { path: `${ROOT}`, component: FormsDataList, icon: 'clipboard-list', title:"Forms Data", exact:1, leftNav:true },
  // )
  addModule({ path: `${ROOT}`, component: Home, icon: 'clipboard-list', title:"Applications Input", exact:1, leftNav:true })
  addModule({ path: `${ROOT}/reports`, component: ReportsList, icon: 'clipboard-list', title: "Reports", exact:0, leftNav:true })
  

  addModule({ path: `${ROOT}/guard_application_form`, component: GuardApplicationList, exact:1, leftNav:false })
  addModule({ path: `${ROOT}/client_info_form`, component: ClientInfoList, exact:1, leftNav:false })
  addModule({ path: `${ROOT}/security_guard_interview`, component: GuardInterviewList, exact:1, leftNav:false })

  addModule(
    { path: `${ROOT}/view/:id`, component: DataPreview, exact: true, leftNav: false },
  )
}
