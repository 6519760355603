import React, { Component, useState, useEffect } from 'react'
import { mongoToDate, min2Hrs } from 'Common/scripts/Functions';
import moment from 'moment';
import { Col, Row, Alert } from 'antd';
import { CSVLink, CSVDownload } from "react-csv";
import { Button, Icon } from 'Common/components';
import { defaultDateFormat } from 'configs';


const exportSettings = [
    { title: `DATE (${defaultDateFormat})`, value: "", func: (v) => moment(v.date).format(defaultDateFormat) },
    { title: "Shift Schedule (HH:mm)", value: "", func: (v) => `${v.totals.total_assignedHrs.hrs}:${v.totals.total_assignedHrs.min}` },
    { title: "Performed In/Out (HH:mm)", value: "", func: (v) => `${v.totals.total_workedHrs.hrs}:${v.totals.total_workedHrs.min}` },
    { title: "Decimal Hours", value: "", func: (v) => v.totals.total_decimalHrs.decimalHrs.toFixed(2) },
]
const colStyle = { width: "200px", backgroundColor: "white", margin: "1px", padding: "5px" };

const DailyBreakdownView = ({ calculateHoursTotal, data }) => {
    const [csvData, setCsvData] = useState(null);
    const [tableData, setTableData] = useState(null);

    useEffect(() => {
        if (!prepareData) return;
        prepareData();
        // createCSVData()
        //   return () => {
        //     effect
        //   };
    }, [data])

    const createCSVData = (_data) => {
        let _csvData = []
        _csvData.push(_data.headers);
        _csvData = _csvData.concat(_data.rows)

        _csvData.push(_data.footer)

        setCsvData(_csvData)
        return _csvData;

    }

    const prepareData = () => {
        if (!data || data.length < 1) return;

        let _tableData = {}
        let unique_dates = []
        let total_assignedHrs = 0;
        let total_workedHrs = 0;
        let total_decimalHrs = 0;

        // setting up Header
        let headers = exportSettings.map(o => o.title); // [];
        Object.assign(_tableData, { headers:headers })


        // making unique records
        data.forEach(o => {
            if (o.approval_status == "new") return;

            let scheduled_shift_start = mongoToDate(o.scheduled_shift_start);
            let scheduled_shift_end = mongoToDate(o.scheduled_shift_end);

            let date_str = scheduled_shift_start.startOf('day').format();

            //   let exists = unique_dates.find(d => d.date == date_str);
            let index = unique_dates.findIndex(d => d.date == date_str);
            if (index < 0) {
                unique_dates.push({
                    date: date_str,
                    data: [o]
                })
            } else {
                unique_dates[index].data.push(o)
            }
        });

        // assembling table data
        let rows = unique_dates.map((o, i) => {
            let totals = calculateHoursTotal(o.data);
            total_assignedHrs += totals.total_assignedHrs.raw;
            total_workedHrs += totals.total_workedHrs.raw;
            total_decimalHrs += totals.total_decimalHrs.raw;

            return exportSettings.map((col, ii) => {
                return col.func({ ...o, totals })
            })
        })
        Object.assign(_tableData, { rows })

        Object.assign(_tableData, {
            footer: [
                "Totals",
                `${min2Hrs(total_assignedHrs).hrs}:${min2Hrs(total_assignedHrs).min}`,
                `${min2Hrs(total_workedHrs).hrs}:${min2Hrs(total_workedHrs).min}`,
                `${min2Hrs(total_decimalHrs).decimalHrs.toFixed(2)}`
            ]
        })

        setTableData(_tableData)
        createCSVData(_tableData)
        return _tableData;

    }

    if (!data || data.length<1) return <p>No data available</p>
    if (!tableData) return <p>No table data found!</p>
    
    let unapprovedCount = data.filter(o => o.approval_status != "aproved")?.length || 0;
    
    return (<>
        <h4>Daily Breakdown View
            {unapprovedCount > 0 && <Alert message={`${unapprovedCount} unapproved record(s) excluded`} type='warning' showIcon />}
        </h4>


        <Row>
            <Col flex="auto" align="right">
                {!csvData && <Button onClick={() => this.createCSVData()}>Create CSV</Button>}
                {csvData && <span style={{ border: '1px solid blue', borderRadius: '5px', padding: '4px 10px', display: 'inline-block' }}>
                    <CSVLink data={csvData} filename='daily_breakdown.csv'><span style={{ fontSize: '12px' }}>Download CSV </span> <Icon icon="file-csv" size="1x" /></CSVLink>
                </span>}
            </Col>
        </Row>

        <div style={{ backgroundColor: "#CCC" }}>
            <Row style={{ fontWeight: "bold", backgroundColor: "#CCC" }}>
                {tableData.headers.map((o, i) => {
                    return <Col key={i} flex={i == 0 ? "auto" : undefined} style={{ ...colStyle, backgroundColor: "#EEE" }}>{o}</Col>
                })}
            </Row>

            {tableData.rows.map((row, i) => {
                return <Row style={{}} key={i}>
                    {row.map((col,ii)=>{
                        return <Col key={`${i}_${ii}`} flex={ii == 0 ? "auto" : undefined} style={colStyle}>{col}</Col>
                    })}
                </Row>
            })}

            <Row style={{ fontWeight: "bold", backgroundColor: "#CCC" }}>
                {tableData.footer.map((col, i) => {
                    return <Col key={`${i}`} flex={i == 0 ? "auto" : undefined} style={{ ...colStyle, backgroundColor: "#EEE" }}>{col}</Col>
                })}
            </Row>

        </div>

    </>)
  
}

export default DailyBreakdownView