import React, { Component, useState, useEffect } from 'react'
import { loader } from 'graphql.macro';
import { Alert, message, Row, Col, Button } from 'antd';
import { Loader, IconButton, Icon, ValuePairsSearch, DevBlock } from 'Common/components';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
import compose from 'lodash.flowright';
import _ from 'lodash'
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { Drawer } from 'antd';
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { defaultDateFormat, driving_license_classes, guard_status, uriRoot } from 'configs'
import { dateToCanada, mongoToDate, dateToMongo } from 'Common/scripts/Functions';
import { getmodulePermissions } from 'Common/scripts/Security';
import { useHistory } from 'react-router-dom';
export const modulePermissions = getmodulePermissions('guard-account-management');

const GUARD = loader('src/Graphql/guard/guard.graphql');
const ADD_GUARD = loader('src/Graphql/guard/addGuard.graphql');
const UPDATE_GUARD = loader('src/Graphql/guard/editGuard.graphql');

function getAvailabilityObj(arr){
    return{
        morning: arr && arr.indexOf('morning') > -1 ? true : false,
        afternoon: arr && arr.indexOf('afternoon') > -1 ? true : false,
        night: arr && arr.indexOf('night') > -1 ? true : false,
    }
}

function setAvailabilityArray(_day){
    let data = []
    if (!_day) return data;

    if (_day.morning) data.push('morning');
    if (_day.afternoon) data.push('afternoon');
    if (_day.night) data.push('night');

    return data;
}

const defaultValues = { status: guard_status[0]._id }

const GuardForm = props => {
    const [busy, setBusy] = useState(false);
    const [guard, setGuard] = useState({});
    const history = useHistory()
    const _id = props?.guard?._id;

    useEffect(() => {
        if (!props.guard || !props.guard._id) return;

        if (props.guard && props.guard._id){
            // let _company_assets = props.guard.company_assets && _.isString(props.guard.company_assets) ? [] : props.guard.company_assets.map(o=>o._id);
            setGuard({
                ...props.guard,
                company_assets: props.guard.company_assets, //props.guard.company_assets ? props.guard.company_assets.split(',') : undefined,
                security_license_expiry: mongoToDate(props.guard.security_license_expiry),
                guard_availability: {
                    mon: { ...getAvailabilityObj(props?.guard?.guard_availability?.mon) },
                    tue: { ...getAvailabilityObj(props?.guard?.guard_availability?.tue) },
                    wed: { ...getAvailabilityObj(props?.guard?.guard_availability?.wed) },
                    thu: { ...getAvailabilityObj(props?.guard?.guard_availability?.thu) },
                    fri: { ...getAvailabilityObj(props?.guard?.guard_availability?.fri) },
                    sat: { ...getAvailabilityObj(props?.guard?.guard_availability?.sat) },
                    sun: { ...getAvailabilityObj(props?.guard?.guard_availability?.sun) },
                },
                covid_vaccinated: props.guard.covid_vaccinated == 'yes',
                vehicle: props.guard.vehicle == 'yes',
                ppe: props.guard.ppe == 'yes',
            })
        }

        return () => {
            setGuard({})
        };

    }, [props.guard])
    
    const onSubmitGuard = async (values) => {
        if (guard && guard._id && !modulePermissions['guard.update']) return;
        else if (!modulePermissions['guard.add']) return;

        const { guard_availability } = values;

        const data = {
            application_id: props.application_id, 
            company_assets: values.company_assets, // ? values.company_assets.toString() : undefined,
            covid_vaccinated: values.covid_vaccinated === true ? "yes" : "no",
            
            driving_license: values.driving_license,
            driving_license_expiry: values.driving_license_expiry ? dateToMongo(values.driving_license_expiry) : undefined,

            status: values.status,
            status_notes: values.status_notes, // values.status !='active' ? values.status_notes : null,
            email: values.email,
            f_name: values.f_name,
            m_name: values.m_name,
            l_name: values.l_name,

            phone: values.phone,
            ppe: values.ppe === true ? "yes" : "no",
            
            security_license_no: values.security_license_no,
            security_license_expiry: values.security_license_expiry ? dateToMongo(values.security_license_expiry) : undefined,
            // security_license_expiry: dateToMongo(dateToCanada(values.security_license_expiry)),

            sin: values.sin,
            skills: values.skills,
            vehicle: values.vehicle === true ? "yes" : "no",
            whatsapp: values.whatsapp,
            guard_availability: {
                mon: setAvailabilityArray(guard_availability?.mon),
                tue: setAvailabilityArray(guard_availability?.tue),
                wed: setAvailabilityArray(guard_availability?.wed),
                thu: setAvailabilityArray(guard_availability?.thu),
                fri: setAvailabilityArray(guard_availability?.fri),
                sat: setAvailabilityArray(guard_availability?.sat),
                sun: setAvailabilityArray(guard_availability?.sun)
            },
            emergency_contact: !values.emergency_contact ? undefined : values.emergency_contact.map(({ ename, ephone }) => ({ ename, ephone })),
        }
        
        setBusy(true);
        let result = false;
        if (guard && guard._id){
            Object.assign(data, { _id: guard._id });
            result = await props.updateRecord(data).then(r=>{
                if (!r) setBusy(false);
                return r;
            })
        }
        else{
            result = await props.addRecord(data).then(r=>{
                if (!r) setBusy(false);
                return r;
            })
            if (result && !result.error) history.push(`${uriRoot}/guard/${result._id}`);
        }

        return result;
       
    }

    if(props.loading) return <Loader loading={true} />
    if(_id && !guard?._id) return <Alert message="guard not found!" />

    return (<>
        <FormComponent onSubmit={onSubmitGuard} id='GuardApplicationForm' loading={busy} fields={{ ...props.addGuardData, ...defaultValues, ...guard }} debug={false}
            mutators={{
                // selectClient: (newValueArray, state, tools) => {
                //     let node = newValueArray[0];
                //     let _client = { _id: node.value, name: node.children };
                //     tools.changeValue(state, 'client', () => _client);
                // },
                ...arrayMutators,
            }}
            form_render={formProps => {
                const { handleSubmit, submitting, values, form, invalid, dirty, valid } = formProps;

                return (<>
                    <Row>
                        <Col flex="200px"><FormField size="medium" name="status" validate={rules.required} type="select" label="Status" data={guard_status} /></Col>
                        {(values.status != "active") && <Col flex={"auto"}><FormField size="medium" name="status_notes" validate={rules.required} type="text" label="Status Notes" /></Col>}
                    </Row>

                    <Row>
                        <Col flex={"33%"}><FormField label="First name *" name="f_name" type="text" validate={rules.required} /></Col>
                        <Col flex={"auto"}><FormField label="Middle name (optional)" name="m_name" type="text" /></Col>
                        <Col flex={"33%"}><FormField label="Last name *" name="l_name" type="text" validate={rules.required} /></Col>
                    </Row>

                    <Row>
                        <Col flex={"50%"}><FormField label="Email Address" name="email" type="email" validate={rules.required} /> </Col>
                        <Col flex={"50%"}><FormField label="Phone" name="phone" type="text" validate={rules.required} /></Col>

                        <Col flex={"50%"}><FormField label="Whatsapp" name="whatsapp" type="text" /></Col>
                        <Col flex={"50%"}><FormField label="Skills" name="skills" type="text" /></Col>

                        <Col flex={"50%"}><FormField label="Security license no " name="security_license_no" type="text" validate={rules.required} /></Col>
                        <Col flex={"50%"}> <FormField style={{ width: "100%" }} name="security_license_expiry" type="date" size="medium" label="Security license expiry" placeholder={defaultDateFormat} validate={rules.required} /></Col>

                        <Col flex={"50%"}><FormField label="Social insurance no (SIN)" name="sin" type="text" validate={rules.required} /></Col>
                        <Col flex={"50%"}> <FormField style={{ width: "100%" }} name="sin_expiry" type="date" size="medium" label="SIN expiry" placeholder={defaultDateFormat} validate={rules.required} /></Col>

                        <Col flex={"50%"}><FormField size="medium" name="driving_license" type="select" label="Driving License" placeholder="SELECT" allowClear
                            data={driving_license_classes.map(o => ({ _id: o, title: o }))}
                        /></Col>
                        <Col flex={"50%"}> <FormField style={{ width: "100%" }} name="driving_license_expiry" type="date" size="medium" label="Driving license expiry" placeholder={defaultDateFormat} validate={values.driving_license ? rules.required : undefined} /></Col>
                    </Row>

                    <ValuePairsSearch 
                        keyMap="values=>title"
                        multiselect 
                        preload key_value="company_assets" 
                        name="company_assets" 
                        label="Please select the assets that are provided to guard by compnay" 
                        style={{ width: "100%" }} 
                    />
                    <div style={{height:"20px"}} />

                    <FieldArray name="emergency_contact">
                        {({ fields }) => (
                            <div >
                                <Row style={{ justifyContent: "space-around" }}>
                                    <Col flex="auto"><span style={{ fontSize: "18px", color: "#001529" }} >Emergency Contacts</span></Col>
                                    <Col><Button onClick={() => fields.push({ firstName: '', lastName: '' })}>Add</Button></Col>
                                </Row>

                                {fields.map((name, index) => (
                                    <Row key={index} style={{ marginBottom: "2px" }}>
                                        <Col flex="auto">
                                            <Row key={index} style={{ flexWrap: "nowrap", alignItems: "center" }}>
                                                <IconButton style={{ marginTop: "15px", marginLeft: "5px", }} icon="trash-alt" onClick={() => fields.remove(index)} />
                                                <Col flex="auto"><FormField name={`${name}.ename`} label="Emergency contact name" size="medium" type="text" validate={rules.required} /></Col>
                                                <Col flex="auto"><FormField name={`${name}.ephone`} label="Emergency contact number" type="text" size="medium" validate={rules.required} /></Col>
                                            </Row>

                                        </Col>
                                    </Row>

                                ))}
                            </div>
                        )}
                    </FieldArray>

                    <div style={{ borderBottom: "1px solid #EEE", margin:"10px 0" }} />

                    <div style={{ width: "100%", alignItems: "center" }} >
                        <Row className='dark' align="middle">
                            {['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
                                return <Col flex="12.5%" className="bold" key={index}>{day}</Col>
                            })}
                        </Row>
                        <Row className='simple' align="middle">
                            {['Morning', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                                if (i == 0) return <Col flex="12.5%" className="bold" key={i}>{day}</Col>;
                                return <Col flex="12.5%" key={i}><FormField name={`guard_availability.${day}.morning`} type='switch' /></Col>
                            })}
                        </Row>
                        <Row className='dark' align="middle">
                            {['Afternoon', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                                if (i == 0) return <Col flex="12.5%" className="bold" key={i}>{day}</Col>;
                                return <Col flex="12.5%" key={i}><FormField name={`guard_availability.${day}.afternoon`} type='switch' /></Col>
                            })}
                        </Row>
                        <Row className='simple' align="middle">
                            {['Night', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day, i) => {
                                if (i == 0) return <Col flex="12.5% " className="bold" key={i}>{day}</Col>;
                                return <Col flex="12.5%" key={i}><FormField name={`guard_availability.${day}.night`} type='switch' /></Col>
                            })}
                        </Row>
                    </div>

                    <Row>
                        <Col flex={"auto"}><FormField name="covid_vaccinated" checked={values.covid_vaccinated} type="checkbox">Covid Vaccinated</FormField></Col>
                        <Col flex={"30%"}><FormField name="vehicle" checked={values.vehicle} type="checkbox">Have vehicle</FormField></Col>
                        <Col flex={"30%"}><FormField name="ppe" checked={values.ppe} type="checkbox">PPE</FormField></Col>
                    </Row>

                    <div style={{ padding:"20px" }}>
                        <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} loading={submitting} >{values._id ? 'Update' : 'Create Guard'}</Button>
                    </div>

                </>)
            }}
        />

    </>)

}

const EditWrapper = compose(
    graphql(GUARD, {
        options: ({ fields }) => {
            return {
                variables: { id: fields._id },
                fetchPolicy: "no-cache",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, guard, error } = data;
            if (error) console.log(__error("error"), error);
            return { loading, guard, queryErrors: error, }
        },
    }),
)(GuardForm);

const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <GuardForm {...props} />


class Guard extends Component {
    constructor(props){
        super(props);
        this.state = { busy: false, error:null }
        
        this.addRecord = this.addRecord.bind(this);
        this.updateRecord = this.updateRecord.bind(this);
    }

    async addRecord(values){
        // return this.props.onClose(values)

        if (!modulePermissions['guard.add']) return;
        this.setState({ busy: true, error:false });

        let input = {
            ...values,
            status_notes: values.status_notes || this?.props?.status_notes,
        }

        const results = await this.props.client.mutate({ mutation: ADD_GUARD, variables: { input } }).then(r => {
            return r.data.addGuard;
        }).catch(err => {
            console.log(__error("ERROR: "), err);
            return { error: { message:"Failed to process the request!"}}
        })

        if (results.error){
            message.error(results.error.message);
            return this.setState({ busy: false, error: results.error.message });
        }

        this.setState({ busy: false });
        this.props.onClose(results);
        return results;
    }
    
    async updateRecord(values){
        if (!modulePermissions['guard.update']) return;
        this.setState({ busy: true, error: false });

        const results = await this.props.client.mutate({ mutation: UPDATE_GUARD, variables: { input: values } }).then(r => {
            return r.data.editGuard;
        }).catch(err => {
            console.log(__error("ERROR: "), err);
            return { error: { message:"Failed to process the request!"}}
        })

        if (results.error){
            message.error(results.error.message);
            return this.setState({ busy: false, error: results.error.message });
        }

        this.setState({ busy: false });
        this.props.onClose(results)
        return results;

    }
    
    render() {
        const { showDrawer, onClose, initialData, addGuardData, status_notes } = this.props;
        const { error } = this.state;

        return (<>
            <Drawer
                autoFocus={true} destroyOnClose={true} maskClosable={false}
                title={this.props.initialData ? "Update Guard" : "Add New Guard"}
                width={800}
                onClose={onClose}
                visible={showDrawer}
                // bodyStyle={{ paddingBottom: 80 }}
                // extra={<Button size="small" onClick={onClose}>Cancel</Button>}
            >
                {error && <Alert type='error' message={error} />}
                {showDrawer ? <Wrapper {...this.props} fields={initialData} addRecord={this.addRecord} updateRecord={this.updateRecord} /> : null}
            </Drawer>
        </>)
    }
}

export default withApollo(Guard);