import React from 'react';
import { addModule, addReducers } from '../connector';
import Guards from './Guards';
import Detail from './containers/Detail';
import { getmodulePermissions } from 'Common/scripts/Security';
import { ROOT } from './constants';
import AllReports from 'Modules/FormsData/containers/all_reports';

export const modulePermissions = getmodulePermissions('guard-account-management');

// addReducers({ admins: reducers });

if (modulePermissions['guard.view']) {
  addModule(
    { path: `${ROOT}`, component: Guards, icon:'user-shield', title:"Guards", exact:1, leftNav:true },
  )
  addModule(
    { path: `/guard/guard_reports/:guard_id`, component: AllReports, title: "All Guard Reports", exact: 1, leftNav: false },
  )
  // addModule(
  //   // { path: `/guard/(*)?/:guard_id`, component: Detail, title: "Guard Details", exact: 0, leftNav: false },
  //   // { path: `/guard/(reports)?`, component: Detail, title: "Guard Details", exact: 0, leftNav: false },
  //   // { path: `/guard/(reports)?/(jds)?/:guard_id`, component: Detail, title: "Guard Details", exact: 0, leftNav: false },
  //   { path: [`/guard/:guard_id`, `/guard/*/:guard_id`], component: Detail, title: "Guard Details", exact: 1, leftNav: false },
  // )
  addModule(
    { path: `/guard`, component: Detail, title: "Guard Details", exact: 0, leftNav: false },
    // { path: `/guard/(reports)?`, component: Detail, title: "Guard Details", exact: 0, leftNav: false },
    // { path: `/guard/(reports)?/(jds)?/:guard_id`, component: Detail, title: "Guard Details", exact: 0, leftNav: false },
    // { path: `/guard/:guard_id`, component: Detail, title: "Guard Details", exact: 1, leftNav: false },
  )

}
