import React, { Component, useState, useEffect } from 'react'
import { rules, FormField, FormComponent } from 'Common/components/Form'
import { Button, Icon, SiteSearch, GuardSearch } from 'Common/components'
import { message, Row, Col } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { publishStatus } from 'configs'
import { connect } from "react-redux";
import { updatePageSettings } from 'Store/ep_admin.actions.js'

// const ref_keys = formArray.map(o => ({ _id: o.key, title: o.menuTitle }));


const TestSearchBar = props => {
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState(null);

    // const defaultFilter = { ...props?.ep_admin?.pageSettings?.formdata_searchBar };

    useEffect(() => {
        onSubmitSearch({ ...props?.ep_admin?.pageSettings?.test_searchBar })
    }, [])


    const onSubmitSearch = (values, redux) => {
        const filter = { ...values, ...props.defaultFilter }

        setBusy(true);

        props.fetchData({ filter }).then(r => {
            setBusy(false);
        });

        props.updatePageSettings({
            page: 'formdata_searchBar',
            values: {
                ...values
            }
        })
    }



    return (<>

        <FormComponent onSubmit={onSubmitSearch} id='FormDataSearch' loading={busy} debug={false}
            fields={{ ...props.ep_admin?.pageSettings?.formdata_searchBar }}
            form_render={formProps => {
                const { values, form, invalid, dirty, valid } = formProps;

                return (<Row align="top">
                    {/* <Col flex="250px"><FormField label="Record Category" name="ref_key" placeholder={"Select Record Category"} size="medium" type="select" 
                        data={formArray.map(o => ({ _id: o.key, title: o.menuTitle }))} 
                        compact allowClear /></Col> */}

                    <Col><FormField label="Keyword" name="search.keywords" placeholder={"Keyword search.."} size="medium" type="text" allowClear compact /></Col>

                    {(!props.exclude || props.exclude.indexOf("status")<0) &&
                        <Col flex="120px"><FormField label="Status" name="status" size="medium" type="select" data={publishStatus} allowClear compact /></Col>
                    }
                    
                    <Col><Button style={{ marginTop: "19px" }} type="primary" htmlType='submit'><Icon icon="search" /></Button></Col>
                </Row>)
            }}
        />

    </>)


}
const mapStateToProps = ({ ep_admin }) => {
    // console.log("ep_admin: ", ep_admin.pageSettings.formdata_searchBar)
    return ({ ep_admin });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TestSearchBar)

