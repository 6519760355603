import React, { Component } from 'react'
import PropTypes from 'prop-types'; 
import { rules, composeValidators, FormField, FormComponent, FormFieldGroup } from 'Common/components/Form'
import { DevBlock, Button } from 'Common/components'
import { Row, Col, message } from 'antd'
import { __error } from 'Common/scripts/consoleHelper';
import { connect } from "react-redux"; 


const FilterInfoForm = props => {
    const { initialValues, user } = props;

    const onSubmit = async values => {
        const input = { 
            title: values.title, 
            note: values.note, 
            shared: values.shared ? 'yes' : 'no',
            permanent: values.permanent ? 'yes' : 'no',
        }

        var results;
        if (values._id){
            results = await props.doUpdateView({ ...input, _id: values._id }).then(r=>(r)).catch(err=>{
                console.log(__error("Query Error"))
                console.error(err);
                return {error:{message:"Query Error"}}
            })
        }
        else{
            Object.assign(input, { category: props.category })
            results = await props.addFilteredView(input).then(r=>(r)).catch(err => {
                console.log(__error("Query Error"))
                console.error(err);
                return { error: { message: "Query Error" } }
            })
        }

        if (results.error) message.error(results.error.message);
        else if (props.onSave) props.onSave(results)

        return true;

    }

    return (<>
        <FormComponent onSubmit={onSubmit} id='FilterInfoForm' 
            fields={{ 
                ...initialValues, 
                shared: initialValues ? initialValues.shared == 'yes' : false, 
                permanent: initialValues ? initialValues.permanent == 'yes' : false
            }} 
            debug={true} 
            form_render={formProps => {
                const { values, form, dirty } = formProps;
                return (<>
                    <FormField type="text" name='title' label="Name" validate={composeValidators(rules.required)} />
                    <FormField type="checkbox" name='shared' checked={values.shared}>Shared</FormField>
                    <FormField type="checkbox" name='permanent' checked={values.permanent}>Permanent</FormField>

                    <Button type="primary" htmlType="submit" disabled={!dirty} style={{ marginTop: "20px", marginLeft: "10px" }}>{values._id ? 'Save' : 'Create'} filter</Button>

                </>)
            }}
        />
 
    </>)
}
FilterInfoForm.propTypes = {
    initialValues: PropTypes.object,
    onSave: PropTypes.func,
    category: PropTypes.string.isRequired,
    addFilteredView: PropTypes.func,
    doUpdateView: PropTypes.func,
}

const mapStateToProps = ({ ep_admin }) => {
    return {
        user: ep_admin?.user,
    }
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export default connect(mapStateToProps)(FilterInfoForm)
