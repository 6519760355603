import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { loader } from 'graphql.macro';
import { Popconfirm, Alert, message, Row, Col } from 'antd';
import { Table, Loader, Icon, IconButton, Button } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper'
import { Breadcrumb } from "antd";
import { ContentArea } from "Layout_v1";
import { HomeOutlined } from '@ant-design/icons';
import AddressBookForm from './addressbook_form';
import { modulePermissions } from '.'
import SearchBar from './components/SearchBar'
import { defaultPageSize, uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import { ListHeader } from 'Common/components/Typography';
import { VALUE_KEY } from './constants';

const LIST_DATA = loader('src/Graphql/addressbooks/addressbooksQuery.graphql');
const DELETE = loader('src/Graphql/addressbooks/deleteAddressbookItem.graphql');

class AddressbooksComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null,
            error: null,
            kw:null,
            pagination: { current: 1, pageSize: defaultPageSize },

            showDrawer:false, initialData:null
        };
        // this.onSearch = this.onSearch.bind(this)
        this.searchList = this.searchList.bind(this)
        this.delete = this.delete.bind(this)
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.fetchData({ current: pagination.current, pageSize: pagination.pageSize });
    };

    fetchData = async (args = {}) => {
        const variables = {
            first: args.pageSize || this.state.pagination.pageSize, // number of rec to fetch
            after: (args.pageSize || this.state.pagination.pageSize) * ((args.current || this.state.pagination.current) - 1),
            filter: JSON.stringify({ ...args?.filter, category: VALUE_KEY })
        }

        this.setState({ loading: true })

        return this.props.client.query({ query: LIST_DATA, variables }).then(r => {
            const response = r.data.addressbooksQuery;

            if (response.error) {
                this.setState({ loading: false, error: response.error.message });
                return;
            }

            this.setState({
                loading: false,
                data: response,
                pagination: {
                    ...this.state.pagination,
                    current: args.current || 1,
                    pageSize: args.pageSize || this.state.pagination.pageSize,
                    total: response.totalCount
                }
            });

            return response;

        }).catch(err => {
            console.log("ERROR: ", JSON.stringify(err, 0, 2));
            this.setState({ loading: false, error: "Unable to complete your request at the moment!" });
            return false;
        })
    }

    searchList(value) {
        this.fetchData({
            // filter: { $or: [{ "personalinfo.f_name": value }, { "personalinfo.sin_no": value}]}
            filter: { search: { keywords: value }}
        });
    }
      
    columns = [
        { title: 'ID', dataIndex: '_id', width: 100 },
        { title: 'Name',  render: (__, rec)=>{
            // return (<Link to={`/admin/client_details/${rec?._id}`} >{rec?.name}</Link>)
            return rec.name;
        }
        },
        { title: 'Phones', dataIndex: 'phones', width:250, render:(__, rec)=>{
            return <>{rec.phones.toString().replaceAll(",", ", ")}</>
        } },
        {
            title: '', width:100, align:'center', render: (___, rec) => {
                return <div>
                    {modulePermissions['addressbook.edit'] && 
                        <IconButton onClick={() => this.toggeleDrawer(rec)} icon={{icon:'pen'}}></IconButton>
                    }
                    {modulePermissions['addressbook.delete'] &&
                        <Popconfirm onConfirm={() => this.delete(rec?._id)} title="Are you sure？">
                            <IconButton icon={{ icon: 'trash-alt' }}></IconButton>
                        </Popconfirm>
                    }
                </div>
            }
        },
    ];

    delete(_id) {
        if (!modulePermissions['addressbook.delete']) return;

        this.props.client.mutate({ mutation: DELETE, variables: { id: _id } }).then(r => {
            const response = r.data.deleteAddressbookItem;
            if (response.error) {
                this.setState({ loading: false, error: response.error.message });
                return;
            }

            const delIndex = this.state.data.edges.findIndex(data => data._id == response._id)
            const __data = this.state.data.edges ? this.state.data.edges.slice() : [];

            __data.splice(delIndex, 1)
            this.setState({ data: { edges: __data } })
            message.success("Record is successfully deleted!")
        }).catch(err => {
            console.log(__error("ERROR: "), err);
            this.setState({ loading: false, error: "Unable to delete at the moment!" });
        })

    }

    toggeleDrawer(initialData = null) {
        this.setState({ showDrawer: !this.state.showDrawer, initialData })
    }

    onUpdate(val){
        const edges = this.state.data.edges.map(o=>o._id==val._id ? val : o)
        this.setState({ data: { edges } })
    }

    onAdd(val){
        const edges = this?.state?.data?.edges?.slice() || [];
        edges.unshift(val);
        this.setState({ data: { edges } })
    }


    render() {
        const { pagination, showDrawer, initialData, loading, data, error } = this.state;
        const { inlineView } = this.props;

        if (error) return <ContentArea><Alert message="Error" description={error} type="error" showIcon /></ContentArea>

        return (<>
            {!inlineView &&
                <Breadcrumb style={{ padding: "10px" }}>
                    <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
                    <Breadcrumb.Item><Icon icon="address-book" size="1x" /> Addressbook</Breadcrumb.Item>
                </Breadcrumb>
            }

            <AddressBookForm 
                showDrawer={showDrawer} 
                initialData={initialData} 
                onClose={() => this.toggeleDrawer()} 
                onUpdate={(v) => this.onUpdate(v)}
                onAdd={(v) => this.onAdd(v)}
            />

            <ContentArea>
                {/* {modulePermissions['addressbook.add'] &&
                    <Row>
                        <Col flex="auto"></Col>
                        <Col><Button type="primary" onClick={() => this.toggeleDrawer()} icon={<Icon icon='plus' />}>Add Contact</Button></Col>
                    </Row>
                } */}

                <Row>
                    <Col flex="auto">
                        <ListHeader title={"Contacts"} totalCount={(data && data.totalCount) || '0'} />
                    </Col>
                    {modulePermissions['addressbook.add'] &&
                        <Col><Button type="primary" onClick={() => this.toggeleDrawer()} icon={<Icon icon='plus' />}>Add Contact</Button></Col>
                    }
                </Row>
                
                <div style={{ marginTop: "10px", padding: "0 10px 0 10px", border: "1px solid #EEE", borderTopRightRadius: "5px", borderTopLeftRadius: "5px", backgroundColor: "rgba(0, 0, 0, 0.02)" }}>
                    <SearchBar fetchData={(args) => this.fetchData(args)} />
                </div>

                <Table loading={loading}
                    // title={() => <ListHeader title={"Contacts"} totalCount={(data && data.totalCount) || '0'} />}
                    // title={() => <b>Total {((data && data.totalCount) || '0')} record(s) found </b>}
                    columns={this.columns}
                    dataSource={data ? data.edges : null}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    // expandable={{
                    //     expandedRowRender: record => {
                    //         console.log("record.sites: ", record.sites)
                    //         return(
                    //             <div>
                    //                 <Row>
                    //                     {record.sites.map((site, i) => <Col flex="500px" key={i} style={{border:'1px solid #EEE', borderRadius:'5px', margin:'5px', padding:'5px'}}>{site.name}</Col>)}
                    //                 </Row>
                    //             </div>
                    //         )
                    //     },
                    //     // rowExpandable: record => record.comments && record.comments.length > 0,
                    // }}

                    // onChange={this.handleTableChange}
                />
            </ContentArea>
        </>)
    }
}

export const Addressbooks = withApollo(AddressbooksComp);
export default Addressbooks;