import React, { useState } from 'react'
import { Row, Col, Select, Input } from 'antd';
import { __error } from 'Common/scripts/consoleHelper'
import { removeSpecialChar } from 'Common/scripts/Functions'
import { tagsTypeArray } from 'configs';



const SearchBar = ({ onSubmit, busy, defaultValues }) => {
    const [filter, setFilter] = useState({ type: (defaultValues && defaultValues.type) })

    const submitSearch = (values) => {
        // document.getElementById('TagsSearch').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        let _filter = { ...filter, ...values }
        if (!_filter.kw || _filter.kw.length < 1) delete _filter.kw;

        setFilter(_filter);
        onSubmit(_filter)
    }

    return (<>
        {/* <DevBlock obj={filter} /> */}

        <div className='table-header' style={{ padding: "10px" }}>
            <Row align="middle" gutter={[5]}>
                <Col><Select
                    defaultValue={defaultValues && defaultValues.type} //{tagsTypeArray[0]._id}
                    style={{ width: 200 }}
                    onChange={(v) => submitSearch({ type:v })}
                    options={tagsTypeArray.map(o => ({ value: o._id, label: o.title }))}
                /></Col>
                <Col><Input.Search 
                    onSearch={(kw) => submitSearch({ kw: removeSpecialChar(kw, { filter:{space:true}}) })} 
                    placeholder="Keyword search.." style={{ width: 200 }} enterButton={false} allowClear /></Col>
            </Row>
        </div>
    </>)


}

export default SearchBar;
