import React from 'react'
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Col, Row, message } from 'antd';
import { Button } from 'Common/components';
import { connect } from "react-redux";
import { rules, FormField, FormComponent } from 'Common/components/Form'

const CLOSE_TICKET = loader('src/Graphql/threads/closeThreadsTicket.graphql');


const TicketClosingFormComp = ({ ticket, onSuccess, user }) => {
    const [closeThreadsTicket, { data, loading, error }] = useMutation(CLOSE_TICKET);

    const onSubmit = async (values) => {
        let input = {
            threadsTicket_id: Number(ticket._id),
            body: values.body,
        }

        let results = await closeThreadsTicket({ variables: { input } }).then(r => (r.data.closeThreadsTicket));

        if (results.error) {
            message.error(results.error.message);
            return false;
        }

        if (onSuccess) onSuccess(results);
        message.success("Ticket closed")
        return "reset";
    }

    return (<>
        {/* <DevBlock obj={ticket} /> */}
        <FormComponent onSubmit={onSubmit} id='ClosingForm' debug={false}
            form_render={formProps => {
                const { values, submitting, form, invalid, dirty, valid } = formProps;

                return (<>
                    <FormField placeholder={`type in your closing remarks...`} name="body" type="textarea" validate={rules.required} compact />
                    <Col align="right" style={{ marginTop: "10px" }}>
                        <Button color="red" size="medium" shape="round" htmlType='submit' loading={submitting} disabled={!dirty || !valid} >Submit & Close</Button>
                    </Col>
                </>)
            }}
        />
    </>)
}


const mapStateToProps = ({ ep_admin }) => {
    return ({ user: ep_admin.user });
    // return ({ permissions: ep_admin.user.permissions });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     on_updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export const TicketClosingForm = connect(mapStateToProps, null)(TicketClosingFormComp)
