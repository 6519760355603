import React from 'react'
import PropTypes from 'prop-types';
import { DatePicker as _DatePicker } from 'antd';
import { Field } from 'react-final-form'
import { Label } from './FormField'
// import debounce from 'lodash/debounce';
import moment from 'moment';
import _ from 'lodash';
import { defaultDateFormat } from 'configs';
import { RenderError } from './RenderError';

const { RangePicker } = _DatePicker;

export const DateRangePicker = props => {
    let _props = { ...props }

    let style = { width: "100%", ...props.style }
    if (props.width) style = Object.assign(style, { width: props.width })

    delete _props.label
    delete _props.style
    delete _props.onChange

    const onOk = (value) => {
        if (props.onChange) props.onChange(value);

        // props.onChange = (_mom, _str) => {
        //     if (_props.onChange) _props.onChange(_mom, _str);
        //     if (_props.returnAs == 'moment') input.onChange(_mom, _str);
        //     else input.onChange(_mom ? _mom.format() : _mom);
        // }

    }

    if (_props.value && (_.isString(_props.value[0]) || _.isString(_props.value[1]))){
        return <div className="simple-field"><span style={{ backgroundColor: 'red', color: 'yellow' }}>Date values should be a moment object</span></div>;
    }

    return (<div className="simple-field">
        {props.label && <Label>{props.label}</Label>}
        <RangePicker
            placeholder={["Start Date", "End Date"]}
            showTime={props.showTime} // || { format: 'HH:mm' }}
            format={props.format || `${defaultDateFormat} HH:mm`}
            style={{ ...style }}
            {..._props}
            onOk={onOk}
        />
    </div>)
}
DateRangePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    format: PropTypes.string,
    showTime: PropTypes.string,
    placeholder: PropTypes.arrayOf(PropTypes.string),
}



export const DateRangeField = _props => {
    let props = { ..._props };
        delete props.label;
        delete props.returnAs;

    let fieldProps = {..._props}
        delete fieldProps.format;
        delete fieldProps.onChange;

    return (
        <Field {...fieldProps}>
            {({ input, meta }) => {

                let value = input.value;
                if (input.value && _.isString(input.value[0])) value = [moment(value[0]), moment(value[1])]

                const onChange = (dates, dateStrings, info) => {
                    {/* function(dates: [moment, moment], dateStrings: [string, string], info: { range: start | end }) */}

                    if (_props.returnAs == 'moment') input.onChange(dates, dateStrings, info)
                    else input.onChange(!dates ? dates : dates.map(d => (d ? d.startOf('minutes').format() : d)), dateStrings, info)
                }

                let className = `form-field ${_props.type} ${props.validate ? 'validate' : ''} ${(meta.error && meta.touched) ? 'has-errors' : ''} ${!props.compact ? 'field-margins' : ''} ${props.className || ''}`;

                return (
                    <div className={`${className} date`}>
                        {_props.label && <label>{_props.label}</label>}
                        <div style={{ position: "relative" }}>
                            <RangePicker
                                {...props}
                                {...input}
                                onChange={onChange}
                                format={props.format || `${defaultDateFormat} HH:mm`}
                                showTime={props.showTime}
                                value={value}
                            />
                            <RenderError meta={meta} showIcon />
                        </div>

                        {/* {meta.error && meta.touched && <div className="field-error">{meta.error}</div>} */}
                    </div>
                )

            }}
        </Field>
    )

}
DateRangeField.propTypes = {
    name: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
    returnAs: PropTypes.string,
}

export default DateRangeField;

