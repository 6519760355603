import React from 'react';
import _ from 'lodash'
import { Route, Switch } from 'react-router-dom';
import { __success } from 'Common/scripts/consoleHelper';
import { uriRoot } from 'configs';

export const whitelist = []; // whitelist keys for redix-persistant
export const blacklist = []; // blacklist keys for redix-persistant
export const reducers = {};
export const routes = [];
export const navItems = []; // label:"Admin", path

export const addRoutes = (...routesArg) => {
  routes.push(...routesArg.map((item, i) =>
    (item.path) ? <Route exact={item.exact ? true : false} path={uriRoot+item.path} component={item.component} key={routes.length + '_' + i} /> : null
    // React.cloneElement(component, { key: idx + routes.length })
  ));
};

// export const addRoutes = (...routesArg) => {
//   routes.push(...routesArg.map((item, i) =>
//     (item.path) ? <Route exact={item.exact?true:false} path={item.path} component={item.component} key={routes.length+'_'+i} /> : null
//     // React.cloneElement(component, { key: idx + routes.length })
//   ));
//   // routes.push(...routesArg.map((component, idx) =>
//   //   React.cloneElement(component, { key: idx + routes.length })
//   // ));
// };


/********
Adding dropdown nav item
*/
// const Dummy = props =>  <div style={{padding: '20px'}}>{JSON.stringify(props, 0, 2)}</div>
// const More = [
//   { path:`/link1`, component:()=>Dummy({location:'link1'}), label:"Link 1" },
//   { path:`/link2`, component:()=>Dummy({location:'link2'}), label:"Link 2" },
//   { path:`/link3`, component:()=>Dummy({location:'link3'}), label:"Link 3" },
//   { path:`/link4`, component:()=>Dummy({location:'link4'}), label:"Link 4 Link 4 Link 4 Link 4 Link 4 Link 4 Link 4 Link 4" },
// ]
// { label:"More", children:More },

export const addNavItems = (...navItemsArg) => {
  navItems.push(...navItemsArg.map(o => ({ 
    ...o, 
    path: uriRoot + o.path, 
    menuPath: o.menuPath ? (uriRoot + o.menuPath) : undefined 
  } )))
  // navItems.push(...navItemsArg.map((component, idx) =>
  //   React.cloneElement(component, { key: idx + navItems.length })
  // ));
};


// export const addMoreNavItems = (...navItemsArg) => {
//   more_navItems.push(...navItemsArg)
// };


// export const addReducers = reducersArg => {
//   Object.assign(reducers, reducersArg);
// };

// export const addModule = (args) => {
//   console.log(`addModule(): ${args.label}: `, args.path);
//   addRoutes(args);
//   addNavItems(args);
// }


export const addReducers = (reducer, whiteOrBlack = false) => {
  if (whiteOrBlack.whitelist) {
    whiteOrBlack.whitelist.forEach(element => {
      whitelist.push(element)
    });
  }

  if (whiteOrBlack.blacklist) {
    whiteOrBlack.blacklist.forEach(element => {
      blacklist.push(element)
    });
  }

  Object.assign(reducers, reducer);
};

export const addModule = (args) => {
  if (_.isArray(args)) {
    args.forEach(module => {
      if (process.env.NODE_ENV == 'development')  console.log(`addModule(): ${module.label}: `, module.path);
      addRoutes(module);
      addNavItems(module);
    });
    return;
  }

  if (process.env.NODE_ENV == 'development')  console.log(`addModule(): ${args.label}: `, args.path);
  addRoutes(args);
  addNavItems(args);
}



// export const addMoreModule = (...args) => {
//   addRoutes(...args);
//   addMoreNavItems(...args);
// }
