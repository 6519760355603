import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Row, Col, Alert } from 'antd';
import { CSVLink, CSVDownload } from "react-csv";
// import { loader } from 'graphql.macro';
import { min2Hrs, getTimeDiff } from 'Common/scripts/Functions'
import { Icon, Loader } from 'Common/components';


function groupByGuardId(data){
    let __data = []
    data.forEach(row => {
        if (row.approval_status == "new") return;
        
        // row.guard_id
        let li = data.filter(oo => oo.guard_id == row.guard_id)
        // if (__data.length < 1 || __data.guard_id !== row.guard_id) __data.push({ guard_id: row.guard_id, rows: li });
        if (!__data.find(o => o.guard_id == row.guard_id)) __data.push({ guard_id: row.guard_id, rows: li });
    });

    return __data;
}

function calculateGroupHours(group){
    let data = group.map(t => {
        let difference = getTimeDiff(t.check_in, t.check_out, 'split');
        let decimalHrs = Number(difference.decimalHrs).toFixed(2);

        return {
            ...difference,
            // in: t.check_in,
            // out: t.check_out,
            decimalHrs,
            stat: t.stat
        }
    })
    // calculate regular min
    let regularMin = 0;
    data.forEach(row => {
        regularMin += row.minDiff;
    });

    let hrs = min2Hrs(regularMin);
    return hrs;

}

export default class PaygridPreview extends Component {
    state = { data: null, csvData:null, loading:false };
    
    async prepareData(data) {
        if (!data || data.length<1) return;

        // Group data by Guard ID
        let __data = groupByGuardId(data);

        __data = __data.map(group=>{
            const { guard } = group.rows[0];

            // process regular hrs
            let regularTimes = group.rows.filter(o=>o.stat=='no');
            regularTimes = calculateGroupHours(regularTimes);


            // process stat hrs
            let statTimes = group.rows.filter(o => o.stat == 'yes');
            statTimes = calculateGroupHours(statTimes);


            return {
                "Number *": guard.security_license_no,// "Security License number number",
                // "Name*": guard.name, //"Guard Name",
                "Name*": `${guard.l_name}, ${guard.f_name}`, //"Guard Name",
                "Department*": "", // empty
                "Rate*": "", // empty
                "Reg. Hrs": regularTimes.decimalHrs.toFixed(2),//  regularTimes.decimalHrs,//"Regular hrs excluding stat hrs and overtime",
                "Overtime (E)": "", // empty
                "Stat Pay @1.0 (E)": "", // empty
                "Stat Pay @1.5 (E)": statTimes?.decimalHrs.toFixed(2) || 0,  //"stats hrs @1.5",
                "Reimbursement (E)": "", // empty
                "Uniform Reimbursement (E)": "", // empty
                "Gas Reimbursement (E)": "", // empty
                "Regular Salary (E)": "", // empty
                "Traffic Infraction (D)": "", // empty
                "Uniform (D)": "", // empty
                "Other (D)": "", // empty
                "Net Pay Deduction (D)": "", // empty
                "Message": "", // empty
            }
        })

        this.setState({ data: __data, loading:false })
        this.createCSVData(__data);
        
    }

    componentDidMount(){
        this.prepareData(this.props.data)
    }

    createCSVData(data){
        // const { data } = this.state;
        if (!data || data.length<1) return;

        let csvData = []

        // const csvData = [
        //     ["firstname", "lastname", "email"],
        //     ["Ahmed", "Tomi", "ah@smthing.co.com"],
        //     ["Raed", "Labes", "rl@smthing.co.com"],
        //     ["Yezzi", "Min l3b", "ymin@cocococo.com"]
        // ];

        // setting up Header
        let csv_header = [];
        Object.keys(data[0]).map((o, i) => {
            csv_header.push(o);
        })
        csvData.push(csv_header);


        // setting up Body
        data.map((row, i) => {
            let _row = []
            Object.keys(row).map((col, ii) => {
                _row.push(row[col]);
            })
            csvData.push(_row);
        })

        this.setState({ csvData });

    }


    render() {
        const { data, csvData, loading } = this.state;
        if (loading) return <Loader loading={loading} />
        if (!data) return <div>Processing data <Loader loading={true} /></div>
        if (data.length<1) return <div>No data available</div>

        console.log("data: ", data)

        let unapprovedCount = this.props.data.filter(o => o.approval_status != "aproved")?.length || 0;

        return (<>
            <h4>Paygrid Data
                {unapprovedCount > 0 && <Alert message={`${unapprovedCount} unapproved record(s) excluded`} type='warning' showIcon />}
            </h4>

            <Row>
                <Col flex="auto" align="right">
                    {csvData && 
                        <span style={{ border: '1px solid blue', borderRadius: '5px', padding: '4px 10px', display: 'inline-block' }}>
                            <CSVLink data={csvData} filename='Paygrid_hours.csv'><span style={{ fontSize: '12px' }}>Download CSV </span> <Icon icon="file-csv" size="1x" /></CSVLink>
                        </span>
                    }
                    {/* {csvData && <span style={{ border: '1px solid blue', borderRadius:'5px', padding:'5px 10px', display: 'inline-block' }}><CSVLink data={csvData} filename='Paygrid_hours.csv'><span style={{ fontSize: '24px' }}>Download</span> <Icon icon="file-csv" size="2x" /></CSVLink></span>} */}
                </Col>
            </Row>

            <table className='dataTable'>
                <thead>
                    <tr>
                        {data && Object.keys(data[0]).map((o, i) => {
                            return <th key={i}>{o}</th>
                        })}
                    </tr>
                </thead>

                <tbody>
                    {data.map((row, i)=>{
                        return <tr key={i}>{
                            Object.keys(row).map((col, ii) => {
                                return <td key={ii}>{row[col]}</td>
                            })
                        }</tr>
                    })}
                </tbody>
            </table>
        </>)
        
    }
}




PaygridPreview.propTypes = {
    data: PropTypes.array.isRequired,
}